import isEqual from 'lodash/isEqual';
import { useLayoutEffect, useRef, useState } from 'react';

export function useGetNodeSize<T extends HTMLElement = HTMLDivElement>() {
  const nodeRef = useRef<T>(null);
  const [size, setSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    if (nodeRef.current == null) {
      return;
    }

    const { width, height } = nodeRef.current.getBoundingClientRect();

    if (!isEqual(size, { width, height })) {
      setSize({ width, height });
    }
  });

  return { nodeRef, size };
}
