import { ze } from '@tokamakjs/common';
import gql from 'fraql';
import { z } from 'zod';

import { useMosaicQuery } from '~/utils';

import { Advert } from '../domain';
import { Advantages, EligibilityCertificate, QualityCertificate } from '../types';

const LoadAdvertQuery = gql`
  query LoadAdvertQuery($slug: String!) {
    project(slug: $slug) {
      id
      advert {
        id
        images {
          alt
          contentType
          filename
          id
          position
          url
        }
        documents {
          alt
          contentType
          filename
          id
          position
          url
        }
        qualityCertificates
        eligibilityCertificates
        advantages
        progressStatus
        city
        countryCode
        deliveryDate
        description
        region
        street
        streetNumber
        title
        videoUrl
        zipCode
        unitIds
        listingAttributes {
          availability
          bathrooms
          bedrooms
          rooms
          surface
          terraceSurface
        }
        displaySoldUnits
      }
    }
  }
`;

const LoadAdvertQueryDataSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  project: z.object({
    id: ze.id(),
    // @ts-ignore Type instantiation is excessively deep and possibly infinite.
    advert: ze.optional(
      z.object({
        id: ze.id(),
        title: ze.optional(z.string()),
        qualityCertificates: ze.defaults(z.array(z.nativeEnum(QualityCertificate)), []),
        eligibilityCertificates: ze.defaults(z.array(z.nativeEnum(EligibilityCertificate)), []),
        advantages: ze.defaults(z.array(z.nativeEnum(Advantages)), []),
        progressStatus: ze.optional(z.string()),
        city: ze.optional(z.string()),
        countryCode: ze.optional(z.string()),
        deliveryDate: ze.optional(z.string()),
        description: ze.optional(z.string()),
        region: ze.optional(z.string()),
        street: ze.optional(z.string()),
        streetNumber: ze.optional(z.string()),
        videoUrl: ze.optional(z.string()),
        zipCode: ze.optional(z.string()),
        unitIds: ze.defaults(z.array(z.string()), []),
        listingAttributes: ze.defaults(
          z.object({
            availability: ze.defaults(z.boolean(), false),
            bathrooms: ze.defaults(z.boolean(), false),
            bedrooms: ze.defaults(z.boolean(), false),
            rooms: ze.defaults(z.boolean(), false),
            surface: ze.defaults(z.boolean(), false),
            terraceSurface: ze.defaults(z.boolean(), false),
          }),
          {
            availability: false,
            bathrooms: false,
            bedrooms: false,
            rooms: false,
            surface: false,
            terraceSurface: false,
          },
        ),
        displaySoldUnits: ze.defaults(z.boolean(), false),
        documents: ze.defaults(
          z.array(
            z.object({
              alt: ze.optional(z.string()),
              contentType: ze.optional(z.string()),
              filename: z
                .string()
                .optional()
                .nullable()
                .transform((v?: string | null) => v ?? ''),
              id: ze.id(),
              position: ze.optional(z.number()),
              url: ze.defaults(z.string(), ''),
            }),
          ),
          [],
        ),
        images: ze.defaults(
          z.array(
            z.object({
              alt: ze.optional(z.string()),
              contentType: ze.optional(z.string()),
              filename: z
                .string()
                .optional()
                .nullable()
                .transform((v?: string | null) => v ?? ''),
              id: ze.id(),
              position: ze.optional(z.number()),
              url: ze.defaults(z.string(), ''),
            }),
          ),
          [],
        ),
      }),
    ),
  }),
});

interface UseLoadAdvertReturnType {
  isLoadingAdvert: boolean;
  advert?: Advert;
  refreshAdvert: () => Promise<void>;
}

export function useLoadAdvert(projectSlug: string): UseLoadAdvertReturnType {
  const res = useMosaicQuery({
    query: LoadAdvertQuery,
    variables: { slug: projectSlug },
  });

  if (res.isLoading) {
    return { isLoadingAdvert: true, advert: undefined, refreshAdvert: async () => {} };
  }

  const data = LoadAdvertQueryDataSchema.parse(res.data);

  const advert =
    data.project.advert != null
      ? Advert.fromData(projectSlug, {
          ...data.project.advert,
          documents: data.project.advert.documents,
          images: data.project.advert.images,
        })
      : undefined;

  return {
    isLoadingAdvert: false,
    advert,
    refreshAdvert: async () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
