import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  IconType,
  Padding,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  countBox: css`
    font-size: 0.85em;
    border: 1px solid ${sv.neutralLight};
    border-radius: ${sv.defaultBorderRadius};
    background: ${sv.white};
    display: inline-block;
    color: ${sv.colorSecondary};
  `,
};

interface CountBoxProps {
  children: ReactNode;
  icon: IconType;
}

export const CountBox = ({ children, icon }: CountBoxProps) => {
  return (
    <div className={styles.countBox}>
      <Padding size={Size.EXTRA_SMALL}>
        <Flex>
          <FlexItem>
            <Icon name={icon} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>{children}</FlexItem>
        </Flex>
      </Padding>
    </div>
  );
};
