import { weightedPick } from './weighted-pick';

export function pickBrowser(): string {
  const demoBrowsers: Array<string> = [
    'Chrome',
    'Safari',
    'Firefox',
    'Internet Explorer',
    'Others',
  ];

  const demoBrowsersWeights: Record<string, number> = {
    Chrome: 0.45,
    Safari: 0.25,
    Firefox: 0.15,
    'Internet Explorer': 0.1,
    Others: 0.05,
  };

  return weightedPick(demoBrowsers, demoBrowsersWeights);
}
