import { Query } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';
import { omit } from 'lodash';
import { map } from 'rxjs/operators';

import { ID } from '~/types';

import { WebsiteApi } from '../api';
import { AnyWebsiteSectionData, AssetData, UpdateLinkData, Website } from '../api/domain';
import { WebsiteState, WebsiteStore } from '../stores';

@Injectable()
export class WebsiteService extends Query<WebsiteState> {
  private readonly _website$ = this.select('website');

  get website$() {
    return this._website$.pipe(map((w) => (w != null ? Website.fromData(w) : undefined)));
  }

  constructor(protected readonly _store: WebsiteStore, private readonly _websiteApi: WebsiteApi) {
    super(_store);
  }

  public async loadWebsite(projectId: string): Promise<Website> {
    let website = this._store.getWebsite();

    if (website != null && website.id === projectId) {
      return website!;
    }

    website = await this._websiteApi.fetchWebsite(projectId);
    this._store.setWebsite(website);

    return website;
  }

  public async updateWebsite(id: string, data: Partial<Website>): Promise<void> {
    // Omit customDomain becauseMosaicMutation
    await this._websiteApi.updateWebsite(id, omit(data, 'customDomain')); // Don't need to await, optimistic approach
    return this._store.updateWebsite(data);
  }

  public async updateSection(id: string, data: Partial<AnyWebsiteSectionData>): Promise<void> {
    await this._websiteApi.updateSection(id, data); // Don't need to await, optimistic approach
    return this._store.updateSection(id, data);
  }

  public async incrementSection(id: ID): Promise<void> {
    await this._websiteApi.incrementSection(id);
    return this._store.incrementSection(id);
  }

  public async decrementSection(id: ID): Promise<void> {
    await this._websiteApi.decrementSection(id);
    return this._store.decrementSection(id);
  }

  public async updateLink(id: string, data: UpdateLinkData): Promise<void> {
    await this._websiteApi.updateLink(id, data);
    return this._store.updateLink(id, data);
  }

  public async updateSectionAssets(sectionId: string, data: Array<AssetData>): Promise<void> {
    const assets = await this._websiteApi.updateSectionAsset(sectionId, data);
    this._store.updateSectionAssets(sectionId, assets);
  }

  public async updateWebsiteLogo(id: string, signedBlobId: string): Promise<void> {
    const logo = await this._websiteApi.updateWebsiteLogo(id, signedBlobId);
    this._store.updateWebsite({ logo });
  }

  public async updateWebsiteFavicon(id: string, signedBlobId: string): Promise<void> {
    const favicon = await this._websiteApi.updateWebsiteFavicon(id, signedBlobId);
    this._store.updateWebsite({ favicon });
  }

  public async updateWebsiteOpenGraphAttachment(id: string, signedBlobId: string): Promise<void> {
    const openGraphAttachment = await this._websiteApi.updateWebsiteOpenGraphAttachment(
      id,
      signedBlobId,
    );
    this._store.updateWebsite({ openGraphAttachment });
  }
}
