import { InsightsSession, Visits, isUnitEntity } from '../types';

export function extractVisits(sessions: Array<InsightsSession>): Visits {
  return sessions.reduce((memo: Visits, session) => {
    const path = session.path ?? [];
    return [
      ...memo,
      ...path.reduce((memo: Visits, entity) => {
        if (!isUnitEntity(entity)) {
          return memo;
        }
        return [...memo, entity];
      }, []),
    ];
  }, []);
}
