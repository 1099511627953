import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';

export const ListingSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.LISTING),
  title: ze.optional(z.string()),
  active: ze.defaults(z.boolean(), false),
  position: z.number(),
});

export type ListingSectionData = z.infer<typeof ListingSectionSchema>;

export class ListingSection extends ze.ClassFrom(ListingSectionSchema) {
  public static fromData(data: ListingSectionData): ListingSection {
    return ze.validate(data, ListingSection);
  }

  public static is(section: any): section is ListingSection {
    return section instanceof this;
  }

  get isActive() {
    return this.active;
  }

  public toData(): ListingSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
