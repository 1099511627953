import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FormGroup,
  Input,
  Label,
  LoadingPlaceholder,
  Panel,
  PanelBody,
  PanelFooter,
  Size,
  Spinner,
  Text,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { gql } from 'graphql-tag';
import React, { ReactNode, useEffect } from 'react';

import { SettingsSection } from '~/components';
import { OrganisationFromCore } from '~/pods/agency/types';
import { OrganisationRole } from '~/types';
import { createTranslate, isNullOrEmpty, useAuth, useMosaicMutation } from '~/utils';

import { AgencyLogoUploader } from './components';

interface UpdateAgencyVariables {
  organisation: {
    id?: string;
    name?: string;
  };
}

interface UpdateAgencyResult {
  updateOrganisation: {
    organisation: {
      id?: string;
      name?: string;
    };
  };
}

interface AgencyForm {
  name: string;
}

const tt = createTranslate('pods.agency.routes');

const updateAgencyMutation = gql`
  mutation UpdateOrganisation($organisation: UpdateOrganisationInput!) {
    updateOrganisation(input: $organisation) {
      organisation {
        id
        name
        logo {
          id
          url
        }
      }
    }
  }
`;

interface AgencyDetailsLayoutProps {
  uploader: ReactNode;
  footerButton: ReactNode;
  inputField: ReactNode;
}

const AgencyDetailsLayout = ({ uploader, footerButton, inputField }: AgencyDetailsLayoutProps) => {
  return (
    <SettingsSection
      left={uploader}
      right={
        <Panel
          body={
            <PanelBody>
              <FormGroup
                style={{ width: '50%' }}
                label={<Label>{tt('agency_name_input_label')}</Label>}
                input={inputField}
              />
            </PanelBody>
          }
          footer={
            footerButton != null ? (
              <PanelFooter>
                <Flex justify={FlexJustify.END}>
                  <FlexItem>{footerButton}</FlexItem>
                </Flex>
              </PanelFooter>
            ) : undefined
          }
        />
      }
    />
  );
};

export const AgencyDetails = ({
  organisationFromCore,
  isOrganisationLoading,
  refreshOrganisationFromCore,
}: {
  organisationFromCore?: OrganisationFromCore;
  isOrganisationLoading: boolean;
  refreshOrganisationFromCore: VoidFunction;
}) => {
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const {
    res: { fetching: isUpdatingOrganisation },
    executeMutation: updateAgency,
  } = useMosaicMutation<UpdateAgencyResult, UpdateAgencyVariables>(updateAgencyMutation);
  const agencyForm = useForm<AgencyForm>();

  const handleSaveChanges = async () => {
    if (!organisationFromCore) {
      throw new Error();
    }

    const { error } = await updateAgency({
      organisation: {
        id: organisationFromCore.id,
        name: agencyForm.values.name,
      },
    });
    if (error != null) {
      showAlert({
        text: tt('error_updating_agency'),
        category: Category.DANGER,
      });
    } else {
      refreshOrganisationFromCore();
    }
  };

  useEffect(() => {
    if (organisationFromCore) {
      agencyForm.set(organisationFromCore.name ?? '', 'name');
    }
  }, [JSON.stringify(organisationFromCore)]);

  const nameInputError =
    /*
      Avoid flashing required field error
      while the agency name isn't incorporated into the form
      in the upper useEffect
    */
    agencyForm.values.name === '' ? tt('agency_name_cannot_be_blank') : undefined;

  const nameWasChanged = (organisationFromCore?.name ?? '') !== agencyForm.values.name;

  if (isOrganisationLoading || organisationFromCore == null) {
    return (
      <AgencyDetailsLayout
        uploader={<AgencyLogoUploader.Loading />}
        inputField={<LoadingPlaceholder height={40} width={'100%'} />}
        footerButton={<LoadingPlaceholder height={40} width={100} />}
      />
    );
  }

  return (
    <AgencyDetailsLayout
      uploader={
        <AgencyLogoUploader
          organisation={organisationFromCore}
          refreshOrganisation={refreshOrganisationFromCore}
        />
      }
      inputField={
        user?.role === OrganisationRole.AGENCY_SALES_MANAGER ? (
          <Input
            error={nameInputError}
            loading={isOrganisationLoading}
            name="name"
            value={agencyForm.values.name ?? organisationFromCore.name}
            onChange={agencyForm.set}
          />
        ) : (
          <Text size={Size.LARGE}>{agencyForm.values.name ?? ''}</Text>
        )
      }
      footerButton={
        user?.role === OrganisationRole.AGENCY_SALES_MANAGER ? (
          <Button
            disabled={
              isNullOrEmpty(agencyForm.values.name) ||
              isUpdatingOrganisation ||
              nameInputError != null ||
              !nameWasChanged
            }
            onClick={handleSaveChanges}
            category={Category.INFO}
            tier={Tier.PRIMARY}
            trailing={isUpdatingOrganisation ? <Spinner inversed size={Size.SMALL} /> : undefined}>
            {tt('update')}
          </Button>
        ) : undefined
      }
    />
  );
};
