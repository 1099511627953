import { Title as DTitle, TitleProps as DTitleProps } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css, cx } from 'emotion';
import React from 'react';

import { Theme, ThemeDefinition } from '../../../types';

const styles = {
  h1: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.extraLarge};
    line-height: ${theme.fonts.extraLargeSpacing};
  `,
  h2: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.large};
    line-height: ${theme.fonts.largeSpacing};
  `,
  h3: (theme: ThemeDefinition) => css`
    color: ${theme.colors.secondary};
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.extraSmall};
    line-height: ${theme.fonts.extraSmallSpacing};
    font-weight: 600;
    letter-spacing: 1.5px;
  `,
  luxuriousH1: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.extraLarge};
    line-height: ${theme.fonts.extraLargeSpacing};
  `,
  luxuriousH2: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.large};
    line-height: ${theme.fonts.largeSpacing};
  `,
  luxuriousH3: (theme: ThemeDefinition) => css`
    color: ${theme.colors.secondary};
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.extraSmall};
    line-height: ${theme.fonts.extraSmallSpacing};
    font-weight: 600;
    letter-spacing: 1.5px;
  `,
  minimalistH1: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.extraLarge};
    line-height: ${theme.fonts.extraLargeSpacing};
    font-weight: 500;
  `,
  minimalistH2: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral1};
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.large};
    line-height: ${theme.fonts.smallSpacing};
    font-weight: 400;
  `,
  minimalistH3: (theme: ThemeDefinition) => css`
    color: ${theme.colors.secondary};
    text-transform: uppercase;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.extraSmall};
    line-height: ${theme.fonts.smallSpacing};
    font-weight: 600;
    letter-spacing: 1.5px;
  `,
};

interface TitleProps extends DTitleProps {}

export const Title = ({ size = 1, ...props }: TitleProps) => {
  const theme = useTheme<ThemeDefinition>();
  return (
    <DTitle
      {...props}
      size={size}
      className={cx(
        {
          [styles.h1(theme)]: theme.id === Theme.TRADITIONAL && size === 1,
          [styles.h2(theme)]: theme.id === Theme.TRADITIONAL && size === 2,
          [styles.h3(theme)]: theme.id === Theme.TRADITIONAL && size === 3,
        },
        {
          [styles.luxuriousH1(theme)]: theme.id === Theme.LUXURIOUS && size === 1,
          [styles.luxuriousH2(theme)]: theme.id === Theme.LUXURIOUS && size === 2,
          [styles.luxuriousH3(theme)]: theme.id === Theme.LUXURIOUS && size === 3,
        },
        {
          [styles.minimalistH1(theme)]: theme.id === Theme.MINIMALIST && size === 1,
          [styles.minimalistH2(theme)]: theme.id === Theme.MINIMALIST && size === 2,
          [styles.minimalistH3(theme)]: theme.id === Theme.MINIMALIST && size === 3,
        },
      )}
    />
  );
};
