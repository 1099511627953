/**
 * Loads the resources that an agent has access to, namely the projects
 * he is assigned to, and the organisations that own them.
 */

import gql from 'fraql';
import { isEqual, uniqWith } from 'lodash';

import { Id, Organisation, Project, UrlString } from '~/types';
import { useMosaicQuery } from '~/utils';

import { useAuth } from './use-auth';

interface AgentResourcesQueryData {
  user?: {
    assignedProjects: Array<{
      id: Id;
      publicToken: Id;
      name: string;
      organisation: {
        id: Id;
        name: string;
        publicToken: Id;
        logo: {
          id: Id;
          filename: string;
          url: UrlString;
        };
      };
    }>;
  };
}

const AgentResourcesQuery = gql`
  query AgentResourcesQuery {
    user {
      assignedProjects {
        id
        publicToken
        name
        organisation {
          id
          name
          publicToken
          logo {
            id
            filename
            url
          }
        }
      }
    }
  }
`;

interface FetchAgencyResources {
  isLoadingAgentResources: boolean;
  assignedProjects: Array<Project>;
  clientOrgs: Array<Omit<Organisation, 'isAgency'>>;
}

export function useLoadAgentResources(): FetchAgencyResources {
  const { user } = useAuth();
  const res = useMosaicQuery<AgentResourcesQueryData, null>({
    pause: !user?.isAgent ?? true,
    query: AgentResourcesQuery,
  });

  if (user != null && !user.organisation?.isAgency) {
    return {
      isLoadingAgentResources: false,
      assignedProjects: [],
      clientOrgs: [],
    };
  }

  const projects = res.data?.user?.assignedProjects;

  const clientOrgs = uniqWith(
    projects?.map((project) => project.organisation),
    isEqual,
  );

  return {
    isLoadingAgentResources: res.isLoading,
    assignedProjects: projects ?? [],
    clientOrgs,
  };
}
