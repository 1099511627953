import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import placeholderImage from '~/images/placeholders/line-chart.svg';

import { StatTile } from './StatTile';

const styles = {
  container: css`
    position: relative;
    height: 100%;
  `,
  placeholder: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  `,
};

interface ErrorTileProps {
  message?: string;
  title?: string;
  background?: ReactNode;
}

export const ErrorTile = ({ message, title, background }: ErrorTileProps) => {
  return (
    <StatTile title={title}>
      <div className={styles.container}>
        {background}
        <div className={styles.placeholder}>
          <Flex
            justify={FlexJustify.CENTER}
            align={FlexAlign.CENTER}
            direction={FlexDirection.VERTICAL}
            style={{ height: '100%' }}>
            <FlexItem>
              <img src={placeholderImage} />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            {message != null ? (
              <FlexItem>
                <Text shade={Shade.LIGHT}>{message}</Text>
              </FlexItem>
            ) : null}
          </Flex>
        </div>
      </div>
    </StatTile>
  );
};
