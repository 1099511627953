import {
  Category,
  Panel,
  PanelBody,
  PanelHeader,
  TextArea,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';

import { createTranslate, useDebounce, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.fiscal_simulator.components.disclaimer_panel');

interface UpdateDisclaimerVariables {
  id: string;
  disclaimer: string;
}

interface UpdateDisclaimerResult {
  updateDisclaimer: {
    project: { costSimulatorDisclaimer: string };
  };
}

const updateDisclaimerMutation = gql`
  mutation UpdateDisclaimer($id: ID!, $disclaimer: String) {
    updateProject(input: { id: $id, costSimulatorDisclaimer: $disclaimer }) {
      project {
        costSimulatorDisclaimer
      }
    }
  }
`;

interface DisclaimerPanelProps {
  projectId: string;
  value: string;
  toggleSaving: VoidFunction;
}

export const DisclaimerPanel = ({
  value: initialValue,
  toggleSaving,
  projectId,
}: DisclaimerPanelProps) => {
  const { showAlert } = useAlert();
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, 500);

  const { executeMutation: updateDisclaimer } = useMosaicMutation<
    UpdateDisclaimerResult,
    UpdateDisclaimerVariables
  >(updateDisclaimerMutation);

  const handleUpdateDisclaimer = async (disclaimer: string) => {
    toggleSaving();
    const res = await updateDisclaimer({ id: projectId, disclaimer });
    if (res.error != null) {
      showAlert({
        text: tt('something_went_wrong'),
        category: Category.DANGER,
      });
    }
    toggleSaving();
  };

  useEffect(() => {
    handleUpdateDisclaimer(value);
  }, [debouncedValue]);

  return (
    <Panel
      header={
        <PanelHeader>
          <Title size={4} noMargin>
            {tt('disclaimer')}
          </Title>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <TextArea
            rows={4}
            onChange={(v) => setValue(v as string)}
            value={value}
            error={value.length > 200 ? tt('max_chars', { max: value.length }) : undefined}
            hint={tt('max_chars', { max: value.length })}
          />
        </PanelBody>
      }
    />
  );
};
