import {
  CheckboxFilter,
  Flex,
  FlexItem,
  FlexSpacer,
  Panel,
  PanelBody,
  PanelHeader,
  SearchInput,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import { useLocation, useNavigate } from '@tokamakjs/react';
import React, { useState } from 'react';

import { PresentationStatus } from '~/pods/meetings/types';
import { useFetchPresentations } from '~/pods/meetings/utils';
import { createTranslate, useAuth } from '~/utils';

import { PresentationsTable } from './PresentationsTable';

const tt = createTranslate('pods.marketing_suite.routes.management.components.presentations_panel');
const ta = createTranslate('pods.marketing_suite');

export const PresentationsPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const { isLoading, presentations, error } = useFetchPresentations({});
  const [searchValue, setSearchValue] = useState('');

  const { status } = useFilters(location, navigate, ['status'], { persistenceKey: `${user?.id}:presentations` });

  const selectedStatuses = status.values ? (status.values as Array<PresentationStatus>) : [];

  const filteredPresentations = (presentations ?? [])
    .filter((presentation) => presentation.name.toLowerCase().includes(searchValue.toLowerCase()))
    .filter((presentation) => {
      if (selectedStatuses.length === 0) {
        return true;
      }

      return selectedStatuses.includes(presentation.status);
    });

  return (
    <Panel
      header={
        <PanelHeader>
          <Flex>
            <FlexItem flex>
              <Title noMargin size={4}>
                {tt('presentations')}
              </Title>
            </FlexItem>
            <FlexItem>
              <SearchInput
                minimal
                value={searchValue}
                onChange={setSearchValue}
                placeholder={tt('search')}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <CheckboxFilter
                label={tt('status')}
                onChange={(statuses) => {
                  if (statuses.length === 0) {
                    status.set(null);
                  } else {
                    status.set(statuses);
                  }
                }}
                values={selectedStatuses}
                options={[
                  {
                    label: ta('presentation_status.published'),
                    value: PresentationStatus.PUBLISHED,
                  },
                  {
                    label: ta('presentation_status.unpublished'),
                    value: PresentationStatus.UNPUBLISHED,
                  },
                ]}
              />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody noPadding>
          <PresentationsTable
            isLoading={isLoading}
            presentations={[...filteredPresentations].sort((a, b) =>
              b.createdAt.localeCompare(a.createdAt),
            )}
            error={error}
          />
        </PanelBody>
      }
    />
  );
};
