import { ze } from '@tokamakjs/common';
import gql from 'fraql';
import { z } from 'zod';

import { useMosaicQuery } from '~/utils';

import { Portal } from '../domain';

const StaticPortalsQuery = gql`
  query StaticPortalsQuery {
    staticPortals {
      name
      slug
      logoUrl
    }
  }
`;

const ActivePortalsQuery = gql`
  query ActivePortalsQuery {
    organisation {
      activePortals {
        id
        slug
        ubiflowPortalCode
      }
    }
  }
`;

const staticPortalsDataSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  staticPortals: z.array(
    z.object({
      name: z.string(),
      slug: z.string(),
      logoUrl: ze.optional(z.string()),
    }),
  ),
});

const activePortalsDataSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  organisation: z.object({
    activePortals: z.array(
      z.object({
        id: ze.id(),
        slug: z.string(),
        ubiflowPortalCode: z.string(),
      }),
    ),
  }),
});

type UseLoadPortalsReturn =
  | {
      isLoading: true;
      portals: [];
    }
  | {
      isLoading: false;
      portals: Array<Portal>;
    };

export function useLoadPortals(): UseLoadPortalsReturn {
  const staticPortalsRes = useMosaicQuery({ query: StaticPortalsQuery });
  const activePortalsRes = useMosaicQuery({ query: ActivePortalsQuery });

  if (staticPortalsRes.isLoading || activePortalsRes.isLoading) {
    return { isLoading: true, portals: [] };
  }

  const { staticPortals } = staticPortalsDataSchema.parse(staticPortalsRes.data);
  const { activePortals } = activePortalsDataSchema.parse(activePortalsRes.data).organisation;

  const portals = staticPortals.map((p) => {
    const active = activePortals.find((ap) => p.slug === ap.slug);

    if (active != null) {
      return Portal.fromData({ ...p, ...active });
    }

    return Portal.fromData(p);
  });

  return { isLoading: false, portals };
}
