import { Text, Tier } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.insights.components.insufficient_data');

export const InsufficientData = () => {
  return <Text tier={Tier.SECONDARY}>{tt('insufficient_data')}</Text>;
};
