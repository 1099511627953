import sv from '@drawbotics/drylus-style-vars';
import dayjs from 'dayjs';
import { omit } from 'lodash';

import { DateRange, InsightsEstate } from '../../types';
import { isDateInRange } from '..';

const COLORS = [sv.green, sv.blue, sv.orange, '#8B53F4', '#F2586A', sv.grey300];

function _mergeExtraTypologies(
  sortedKeys: Array<string>,
  visitsByTypology: Record<string, number>,
) {
  if (sortedKeys.length <= 6) return { sortedKeys, visitsByTypology };

  const keysToCompress = sortedKeys.slice(5);
  const visitsForCompressedKeys = keysToCompress.reduce(
    (memo: number, key: string) => memo + visitsByTypology[key],
    0,
  );

  const withKeysRemoved = omit(visitsByTypology, keysToCompress);

  return {
    sortedKeys: [...sortedKeys.slice(0, 5), 'other'],
    visitsByTypology: { ...withKeysRemoved, other: visitsForCompressedKeys },
  };
}

function _sortAndCompressTypologies(units: InsightsEstate['units'], range: DateRange) {
  const visitsByTypology = units.reduce((memo: Record<string, number>, u) => {
    const visitsInRange = u.visits.filter((v) => isDateInRange(dayjs(v), range));
    return {
      ...memo,
      [u.typology]: (memo[u.typology] ?? 0) + visitsInRange.length,
    };
  }, {});

  const sortedKeys = Object.keys(visitsByTypology).sort(
    (a, b) => visitsByTypology[b] - visitsByTypology[a],
  );

  return _mergeExtraTypologies(sortedKeys, visitsByTypology);
}

interface PieChartTypology {
  id: string;
  label: string;
  value: number;
  color: string;
}

export function useLoadPercentageByTypology(
  units: InsightsEstate['units'],
  range: DateRange,
): Array<PieChartTypology> | undefined {
  const { visitsByTypology, sortedKeys } = _sortAndCompressTypologies(units, range);

  const totalVisits = Object.keys(visitsByTypology).reduce(
    (memo: number, key: string) => memo + visitsByTypology[key],
    0,
  );

  if (totalVisits === 0) return undefined;

  return sortedKeys.map((key, index) => ({
    id: key,
    label: key,
    value: visitsByTypology[key] / totalVisits,
    color: COLORS[index],
  }));
}
