import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  Separator,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import { range } from 'lodash';
import React, { Fragment } from 'react';

const styles = {
  dottedLine: css`
    width: 100%;
    height: 6px;
    border-bottom: 2px dotted ${sv.grey200};
  `,
};

interface LoadingChartProps {
  ticksAmount: number;
}

const LoadingChart = ({ ticksAmount }: LoadingChartProps) => {
  return (
    <Flex
      style={{ height: '100%' }}
      justify={FlexJustify.SPACE_BETWEEN}
      align={FlexAlign.START}
      direction={FlexDirection.VERTICAL}>
      <FlexItem style={{ width: '100%' }}>
        <LoadingPlaceholder width={120} height={22} />
      </FlexItem>
      {range(0, ticksAmount).map((i) => (
        <FlexItem key={i} style={{ width: '100%' }}>
          <LoadingPlaceholder width={40} height={22} />
          <div className={styles.dottedLine} />
        </FlexItem>
      ))}
    </Flex>
  );
};

export const MarketingCampaignsLoadingPlaceholder = () => {
  return (
    <Fragment>
      <Panel
        body={
          <PanelBody>
            <Flex justify={FlexJustify.END} style={{ width: '100%' }}>
              <FlexItem>
                <LoadingPlaceholder width={150} height={40} />
              </FlexItem>
            </Flex>
            <Margin size={Size.DEFAULT} />
            <Flex style={{ height: '380px' }}>
              {range(0, 3).map((i) => (
                <FlexItem key={i} style={{ height: '100%' }} flex>
                  <LoadingChart ticksAmount={5} />
                </FlexItem>
              ))}
            </Flex>
            <Margin size={Size.DEFAULT}>
              <Separator />
            </Margin>
            <Flex wrap>
              {range(0, 7).map((i) => (
                <FlexItem key={i}>
                  <Margin size={{ right: Size.DEFAULT }}>
                    <LoadingPlaceholder height={22} width={110} />
                  </Margin>
                </FlexItem>
              ))}
            </Flex>
          </PanelBody>
        }
      />
      <Margin size={Size.DEFAULT} />
      <Panel
        body={
          <PanelBody>
            <Flex justify={FlexJustify.END} style={{ width: '100%' }}>
              <FlexItem>
                <LoadingPlaceholder width={150} height={40} />
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem>
                <LoadingPlaceholder width={250} height={40} />
              </FlexItem>
            </Flex>
            <Margin size={Size.DEFAULT} />
            <div style={{ height: '380px' }}>
              <LoadingChart ticksAmount={6} />
            </div>
            <Margin size={Size.DEFAULT}>
              <Separator />
            </Margin>
            <Flex wrap>
              {range(0, 7).map((i) => (
                <FlexItem key={i}>
                  <Margin size={{ right: Size.DEFAULT }}>
                    <LoadingPlaceholder height={22} width={110} />
                  </Margin>
                </FlexItem>
              ))}
            </Flex>
          </PanelBody>
        }
      />
    </Fragment>
  );
};
