import {
  ButtonLink,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Panel,
  PanelBody,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import coin from '~/pods/insights/images/coin.svg';
import { createTranslate, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.insights.routes.deals');

interface MissingPricesPanelProps {}

export const MissingPricesPanel = ({}: MissingPricesPanelProps) => {
  const { getUrlInProject } = useMosaicNavigation();

  return (
    <Panel
      style={{ height: '70vh' }}
      body={
        <PanelBody style={{ height: '100%' }}>
          <Flex
            style={{ height: '100%' }}
            justify={FlexJustify.CENTER}
            direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <img src={coin} />
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            <FlexItem>
              <Text>
                {tt('missing_prices_1')}
                <br />
                {tt('missing_prices_2')}
              </Text>
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            <FlexItem>
              <Link to={getUrlInProject('/listing')}>
                <ButtonLink color={Color.BLUE}>{tt('to_listing')}</ButtonLink>
              </Link>
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};
