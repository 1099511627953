import { weightedPick } from './weighted-pick';

export function pickCampaign(): string {
  const demoCampaigns: Array<string> = [
    '',
    'CAMP1111',
    'CAMP2222',
    'CAMP3333',
    'CAMP4444',
    'CAMP5555',
  ];

  const demoCampaignsWeights: Record<string, number> = {
    '': 0.1,
    CAMP1111: 0.2,
    CAMP2222: 0.3,
    CAMP3333: 0.14,
    CAMP4444: 0.1,
    CAMP5555: 0.16,
  };

  return weightedPick(demoCampaigns, demoCampaignsWeights);
}
