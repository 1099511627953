import {
  FormGroup,
  Label,
  MultiSelect,
  Panel,
  PanelBody,
} from '@drawbotics/react-drylus';
import React, { useEffect } from 'react';
import { useState } from 'react';

import { createTranslate, isValidEmail } from '~/utils';

const tt = createTranslate('pods.project_settings.routes.components.email_notification_panel');

export const LeadEmailNotificationSettingsPanel = (props: {
  emails: string[];
  onChange: (emails: string[]) => void;
  disabled: undefined | boolean;
  isSaving: boolean | undefined;
}) => {
  const [emailsError, setEmailsError] = useState<string>();

  const validateOptions = () => {
    const firstInvalidEmail = props.emails.find((email) => !isValidEmail(email));

    if (firstInvalidEmail) {
      return tt('error.email_invalid', { newMail: firstInvalidEmail });
    }

    const duplicateEmail = props.emails.find((email) => {
      return props.emails.filter((e) => e === email).length > 1;
    });

    if (duplicateEmail) {
      return tt('error.email_duplicate', { newMail: duplicateEmail });
    }
  };

  useEffect(() => {
    const error = validateOptions();

    if (error != null) {
      setEmailsError(error);
    }
  }, [JSON.stringify(props.emails)]);

  return (
    <Panel
      body={
        <PanelBody>
          <FormGroup
            input={
              <MultiSelect
                error={emailsError}
                disabled={props.disabled}
                onChange={(values) => {
                  setEmailsError(undefined);
                  props.onChange(values);
                }}
                values={props.emails}
                placeholder={tt('placehoder.send_to_more')}
                hint={tt('label.hint')}
              />
            }
            label={<Label>{tt('label.send_to')}</Label>}
          />
        </PanelBody>
      }
    />
  );
};
