import { LoadingPlaceholder, Margin, Panel, PanelBody, Separator, Size, } from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';
import { SettingsSection } from '~/components';

const SettingsLoaderEntry = () => {
  return <Fragment>

    <SettingsSection
      left={
        <Fragment>
          <LoadingPlaceholder />
          <Margin size={{ top: Size.DEFAULT }}>
            <LoadingPlaceholder />
          </Margin>
        </Fragment>
      }
      right={
        <Panel
          body={
            <PanelBody>
              <LoadingPlaceholder />
              <LoadingPlaceholder />

            </PanelBody>
          }
        />
      }
    />

    <Margin size={{ vertical: Size.DEFAULT }}>
      <Separator />
    </Margin>
  </Fragment>


}

export const SettingsLoader = () => {
  return (
    <Fragment>
      <SettingsLoaderEntry />
      <SettingsLoaderEntry />
      <SettingsLoaderEntry />
      <SettingsLoaderEntry />
    </Fragment>



  );
};
