import { DiContainer } from '@tokamakjs/injection';

import { AppModule } from '~/app.module';

import { setupDayjs } from './setup-dayjs';
import { setupIsoCountriesLocale } from './setup-iso-countries-locale';
import { setupLocaleSystem } from './setup-locale-system';
import setupLokalise from './setup-lokalise';

export async function bootstrap(next: Function) {
  setupDayjs();
  setupLocaleSystem(next);
  setupLokalise();
  setupIsoCountriesLocale();

  const diContainer = await DiContainer.from(AppModule);

  next(diContainer);
}
