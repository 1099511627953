import {
  Button,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexDirection,
  FlexItem,
} from '@drawbotics/react-drylus';
import React, { ReactNode, createContext, useContext, useState } from 'react';

import { BasicNavbar, NavbarLayout } from '~/components';

const CriticalErrorContext = createContext({
  criticalError: false,
  triggerCriticalError: () => {},
});

export function useTriggerCriticalError() {
  const { triggerCriticalError } = useContext(CriticalErrorContext);
  return triggerCriticalError;
}

export const CriticalErrorProvider = ({ children }: { children: ReactNode }) => {
  const [criticalError, setCriticalError] = useState(false);

  if (criticalError) {
    document.title = 'Mosaic - Critical Error';
    return (
      <NavbarLayout navbar={<BasicNavbar />}>
        <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <EmptyState
              title="Critical Error"
              description={`
              We're sorry, the application has encountered an error it could not recover from.
              Our engineers have been notified of the problem and are working on it. 
              You may go back to the last page you visited, or return to the home page.
            `}
              variation={EmptyStateVariation.FAILED}>
              <Button onClick={() => (window.location.href = '/')} category={Category.INFO}>
                Return to home page
              </Button>
            </EmptyState>
          </FlexItem>
        </Flex>
      </NavbarLayout>
    );
  }

  return (
    <CriticalErrorContext.Provider
      value={{
        criticalError,
        triggerCriticalError: () => setCriticalError(true),
      }}>
      {children}
    </CriticalErrorContext.Provider>
  );
};
