import { Content, Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { createTranslate } from '~/utils';

import { NewLeadForm } from './components';

const tt = createTranslate('pods.crm.routes.new_lead.new_lead');

export const NewLead = () => {
  return (
    // Fix visual bug: white space at bottom of page when scrolling
    <Content fullHeight style={{ paddingBottom: 100 }}>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('new_lead')}</MosaicPageTitle>
        <NewLeadForm />
      </Padding>
    </Content>
  );
};
