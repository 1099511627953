import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  Padding,
  ShowDateTime,
  Size,
  Text,
  formatDate,
} from '@drawbotics/react-drylus';
import { ComputedSerie, Datum, Point, ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { css } from 'emotion';
import React, { Fragment, useState } from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { Timescale, timescaleToDays } from '~/pods/insights/constants';
import { DateRange, InsightsLead, InsightsSession, LineData } from '~/pods/insights/types';
import {
  KeyOfType,
  LineTheme,
  WithDayjs,
  getEmptyDates,
  getGranularity,
  toDataPoints,
} from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { ChartHeader } from './ChartHeader';
import { VisitorsTopRow } from './VisitorsTopRow';

const tt = createTranslate('pods.insights.routes.traction.visitors_chart');

function _getTickValues(timescale: string): 'every 1 days' | 'every 1 month' | 'every 1 weeks' {
  if (timescale === Timescale.WEEK) {
    return 'every 1 days';
  } else {
    return timescale === Timescale.ALL_TIME ? 'every 1 month' : 'every 1 weeks';
  }
}

export enum TractionMetricEnum {
  VISITORS = 'visitors',
  LEADS = 'leads',
}
export type TractionMetricType = 'visitors' | 'leads';

const styles = {
  chartContainer: css`
    width: 100%;
    height: 350px;
  `,
  tooltipBox: css`
    background: ${sv.neutralDarkest};
    border-radius: ${sv.defaultBorderRadius};
    box-shadow: ${sv.elevation3};
  `,
  tooltipLegend: css`
    width: 18px;
    margin-right: ${sv.marginExtraSmall};
    display: block;
    align-content: center;
  `,
};

interface TractionOvertimeTooltipProps {
  input: SliceTooltipProps['slice'];
  timeScale: string;
  isShowingVisitors: boolean;
}

const TractionOverTimeTooltip = ({
  input,
  timeScale,
  isShowingVisitors,
}: TractionOvertimeTooltipProps) => {
  return (
    <div className={styles.tooltipBox}>
      {input.points
        .map((point: Point) => {
          const finalDate =
            point.serieId === 'current_line'
              ? point.data.x
              : dayjs(point.data.xFormatted).subtract(timescaleToDays[timeScale], 'day').toDate();

          return (
            <Padding size={{ horizontal: Size.SMALL, vertical: Size.EXTRA_SMALL }} key={point.id}>
              <Flex justify={FlexJustify.START}>
                <FlexItem>
                  <div
                    className={styles.tooltipLegend}
                    style={{
                      borderBottom: `4px ${point.serieId === 'current_line' ? 'solid' : 'dotted'} ${
                        point.serieColor
                      }`,
                    }}
                  />
                </FlexItem>
                <FlexItem>
                  <Text inversed>
                    {formatDate({
                      date: new Date(finalDate),
                      options: {
                        showTime: ShowDateTime.NEVER,
                        format: timeScale === Timescale.ALL_TIME ? 'MMM' : 'MMM DD',
                      },
                    })}
                    :
                  </Text>
                </FlexItem>
                <FlexItem>
                  <Text bold inversed style={{ margin: '0px 5px' }}>
                    {point.data.y} {isShowingVisitors ? tt('tooltip_visits') : tt('tooltip_leads')}
                  </Text>
                </FlexItem>
              </Flex>
            </Padding>
          );
        })
        .reverse()}
    </div>
  );
};

const customLine = {
  default: {
    strokeDasharray: 0,
    strokeWidth: 2,
  },
  previous: {
    strokeDasharray: '3 4',
    strokeWidth: 2,
  },
};

interface CustomLinesProps {
  series: Array<ComputedSerie>;
  lineGenerator: (data: Datum[]) => string;
  xScale: (value: string | number | Date) => number;
  yScale: (value: string | number | Date) => number;
}

const CustomLines = ({ series, lineGenerator, xScale, yScale }: CustomLinesProps) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x!),
          y: yScale(d.data.y!),
        })),
      )}
      fill="none"
      stroke={color}
      style={id === 'current_line' ? customLine.default : customLine.previous}
    />
  ));
};

function _generateLineChartData<T extends WithDayjs<T>>(
  range: DateRange,
  dataset: Array<T>,
  key: KeyOfType<T, Dayjs | null>,
  granularity: OpUnitType = 'day',
): LineData {
  const emptyDates = getEmptyDates(range, granularity, 'YYYY-MM-DD');
  const dataByDay = dataset.reduce((memo, d) => {
    const date = d[key].startOf(granularity).format('YYYY-MM-DD');
    return { ...memo, [date]: (memo[date] ?? 0) + 1 };
  }, emptyDates);

  return toDataPoints(dataByDay);
}

interface VisitorsChartProps {
  visitors: Array<InsightsSession>;
  previousVisitors: Array<InsightsSession>;
  leads: Array<InsightsLead>;
  previousLeads: Array<InsightsLead>;
  timescale: string;
  range: DateRange;
  previousRange: DateRange;
}

export const VisitorsChart = ({
  visitors,
  previousVisitors,
  leads,
  previousLeads,
  timescale,
  range,
  previousRange,
}: VisitorsChartProps) => {
  const [selectedMetric, setSelectedMetric] = useState<TractionMetricType>(
    TractionMetricEnum.VISITORS,
  );
  const isShowingVisitors = selectedMetric === 'visitors';
  const granularity = getGranularity(timescaleToDays[timescale]);
  const visitorsData = _generateLineChartData(range, visitors, 'createdAt', granularity);
  //Ternaries below are to avoid unnecessary data processing when All Time is selected
  const previousVisitorsData =
    timescale !== Timescale.ALL_TIME
      ? _generateLineChartData(previousRange, previousVisitors, 'createdAt', granularity)
      : [];
  const previousVisitorsDataTranslated =
    timescale !== Timescale.ALL_TIME
      ? visitorsData.map((p, i) => ({
          x: p.x,
          y: (previousVisitorsData![i] ?? { y: 0 }).y,
        }))
      : [];

  const leadsData = _generateLineChartData(range, leads, 'createdAt', granularity);
  const previousLeadsData =
    timescale !== Timescale.ALL_TIME
      ? _generateLineChartData(previousRange, previousLeads, 'createdAt', granularity)
      : [];
  const previousLeadsDataTranslated =
    timescale !== Timescale.ALL_TIME
      ? leadsData.map((p, i) => ({
          x: p.x,
          y: (previousLeadsData![i] ?? { y: 0 }).y,
        }))
      : [];

  return (
    <Padding>
      <VisitorsTopRow selectedMetric={selectedMetric} onChangeSelectedMetric={setSelectedMetric} />
      <Margin size={{ top: Size.DEFAULT }}>
        {(selectedMetric === TractionMetricEnum.VISITORS && visitors.length === 0) ||
        (selectedMetric === TractionMetricEnum.LEADS && leads.length === 0) ? (
          <Fragment>
            <ChartHeader
              label={`${isShowingVisitors ? tt('visitors_over_time') : tt('leads_over_time')}`}
            />
            <LineChartPlaceholder message={tt('no_data')} />
          </Fragment>
        ) : (
          <Fragment>
            <ChartHeader
              label={`${isShowingVisitors ? tt('visitors_over_time') : tt('leads_over_time')}`}
            />
            <div className={styles.chartContainer}>
              <ResponsiveLine
                data={[
                  {
                    color: sv.blue,
                    id: 'current_line',
                    data: isShowingVisitors ? visitorsData : leadsData,
                  },
                  ...(timescale === Timescale.ALL_TIME
                    ? []
                    : [
                        {
                          color: sv.blueLight,
                          id: 'previous_line',
                          data: isShowingVisitors
                            ? previousVisitorsDataTranslated
                            : previousLeadsDataTranslated,
                        },
                      ]),
                ]}
                margin={{ left: 40, top: 20, bottom: 30, right: 35 }}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: false,
                  precision: 'day',
                }}
                yScale={{
                  type: 'linear',
                  stacked: false,
                }}
                axisBottom={{
                  format: '%b %d',
                  tickSize: 0,
                  tickValues: _getTickValues(timescale),
                }}
                axisLeft={{
                  tickSize: 0,
                  format: (val) => (typeof val !== 'number' || !Number.isInteger(val) ? '' : val),
                }}
                isInteractive={true}
                enableSlices="x"
                sliceTooltip={(data) => {
                  return (
                    <TractionOverTimeTooltip
                      input={data.slice}
                      timeScale={timescale}
                      isShowingVisitors={isShowingVisitors}
                    />
                  );
                }}
                theme={LineTheme}
                yFormat=".0f"
                enableGridX={false}
                enableArea
                areaOpacity={0.1}
                pointSize={9}
                pointBorderWidth={3}
                pointBorderColor={{ from: 'serieColor' }}
                colors={{ datum: 'color' }}
                pointColor="white"
                defs={[
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: '#FFFFFF',
                    color: '#FFFFFF',
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'previous_line',
                    },
                    id: 'lines',
                  },
                ]}
                layers={[
                  'areas',
                  'axes',
                  'crosshair',
                  'grid',
                  'legends',
                  CustomLines,
                  'markers',
                  'mesh',
                  'points',
                  'slices',
                ]}
              />
            </div>
          </Fragment>
        )}
      </Margin>
    </Padding>
  );
};
