import dayjs from 'dayjs';
import gql from 'fraql';

const Lead = {
  typeName: 'Lead',
  fields: {
    fullName: {
      dependencies: gql`
        fragment _ on Lead {
          firstName
          lastName
        }
      `,
      resolver: (lead: any) => {
        if (lead.firstName != null && lead.lastName != null) {
          return `${lead.firstName} ${lead.lastName}`;
        }
        return lead.firstName ?? lead.lastName;
      },
    },
    createdAt: {
      dependencies: gql`
        fragment _ on Lead {
          rawCreatedAt: createdAt
        }
      `,
      resolver: (lead: any) => {
        return dayjs(lead.rawCreatedAt);
      },
    },
  },
};

export default Lead;
