import {
  Button,
  Category,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Modal,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { useEffect, useState } from 'react';

import { createTranslate, useMosaicMutation, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.dashboard.routes.dashboard.components.new_project_modal');

const CreateProjectMutation = gql`
  mutation createProject($project: CreateProjectInput!) {
    createProject(input: $project) {
      project {
        id
        publicToken
      }
    }
  }
`;

interface NewProjectModalProps {
  isVisible: boolean;
  onClickClose: VoidFunction;
}

export const NewProjectModal = ({ isVisible, onClickClose }: NewProjectModalProps) => {
  const { navigateToProject } = useMosaicNavigation();
  const [projectName, setProjectName] = useState('');
  const { showAlert } = useAlert();
  const { res: createProjectResult, executeMutation: createProject } = useMosaicMutation<
    { createProject: { project: { id: string; publicToken: string } } },
    { project: { name: string } }
  >(CreateProjectMutation);

  useEffect(() => {
    if (!isVisible) {
      setProjectName('');
    }
  }, [isVisible]);

  const handleClickCreate = async () => {
    const res = await createProject({ project: { name: projectName } });
    if (res.error != null || res.data?.createProject.project.publicToken == null) {
      showAlert({
        text: tt('create_project_error'),
        category: Category.DANGER,
      });
    } else {
      navigateToProject(res.data?.createProject.project.publicToken);
    }
  };

  return (
    <Modal
      title={tt('create_new_project')}
      onClickClose={onClickClose}
      visible={isVisible}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={onClickClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer />
          <FlexItem>
            <Button
              leading={createProjectResult.fetching ? <Spinner /> : null}
              disabled={projectName === '' || createProjectResult.fetching}
              color={Color.BLUE}
              onClick={handleClickCreate}>
              {tt('create')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <FormGroup
        label={<Label>{tt('project_name')}</Label>}
        input={
          <Input
            placeholder={tt('name_of_project')}
            onChange={(val) => setProjectName(String(val))}
            value={projectName}
          />
        }
      />
    </Modal>
  );
};
