import dayjs from 'dayjs';
import morphey, { fromKey, fromValue } from 'morphey';

import { InsightsUnitFromServer, TokenData, TokenDataFromServer } from '../../../types';

const unitFromServer = (unit: InsightsUnitFromServer) => ({
  id: fromKey('id'),
  name: fromKey('reference'),
  availability: fromKey('availability'),
  soldAt: fromKey('soldAt').using((v?: string) => (v != null ? dayjs(v) : null)),
  bedrooms: fromKey('bedrooms'),
  typology: fromValue(`T${unit.bedrooms + 1}`),
  orientation: fromKey('orientation'),
  price: fromKey('price'),
});

export function transformUnits(units: TokenDataFromServer['resource']['estate']['units']) {
  return units.map((unit) => morphey(unit, unitFromServer));
}

const estateFromServer = (estate: TokenDataFromServer['resource']['estate']) => ({
  id: fromKey('id'),
  slug: fromKey('slug'),
  name: fromKey('name'),
  authToken: fromKey('authToken'),
  units: fromValue(transformUnits(estate.units)),
});

export function transformEstate(
  estate: TokenDataFromServer['resource']['estate'],
): TokenData['estate'] {
  return morphey(estate, estateFromServer);
}
