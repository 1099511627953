import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import React from 'react';

import { OrganisationOverview, OrganisationOverviewStatus } from '~/pods/meetings/types';
import { createTranslate, useMosaicMutation } from '~/utils';

import { StatusButton } from './StatusButton';

const tt = createTranslate('pods.marketing_suite.routes.presentations');

interface UpdateOverviewStatusVariables {
  status: OrganisationOverviewStatus;
}

interface UpdateOverviewStatusResult {
  updateOverview: {
    overview: OrganisationOverview;
  };
}

const updateOverviewStatusMutation = gql`
  mutation updateOverviewStatus($status: OverviewStatusEnum) {
    updateOverview(input: { status: $status }) {
      overview {
        status
      }
    }
  }
`;

interface OverviewStatusButtonProps {
  overview: OrganisationOverview;
  disabled: boolean;
}

export const OverviewStatusButton = ({ overview, disabled }: OverviewStatusButtonProps) => {
  const { res: updateOverviewStatusResult, executeMutation: updateOverviewStatus } =
    useMosaicMutation<UpdateOverviewStatusResult, UpdateOverviewStatusVariables>(
      updateOverviewStatusMutation,
    );
  const { showAlert } = useAlert();

  const handleUpdatePresentation = async () => {
    if (overview.status != null) {
      const { status } = overview;
      const { error } = await updateOverviewStatus({
        status:
          status === OrganisationOverviewStatus.PUBLISHED
            ? OrganisationOverviewStatus.UNPUBLISHED
            : OrganisationOverviewStatus.PUBLISHED,
      });
      if (error != null) {
        showAlert({
          text: tt('generic_error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text:
            overview.status === OrganisationOverviewStatus.PUBLISHED
              ? tt('generic_success_unpublish')
              : tt('generic_success'),
          category: Category.SUCCESS,
        });
      }
    }
  };

  return (
    <StatusButton
      align="right"
      title={tt(`organization_overview_status.${overview.status.toLowerCase()}`)}
      isPublished={overview.status === OrganisationOverviewStatus.PUBLISHED}
      onClick={handleUpdatePresentation}
      loading={updateOverviewStatusResult.fetching}
      disabled={disabled}
    />
  );
};
