import { ID } from '~/types';

export interface PortalData {
  id?: ID;
  name?: string;
  slug: string;
  logoUrl?: string;
  ubiflowPortalCode?: string;
}

export class Portal {
  public static fromData(data: PortalData): Portal {
    return new Portal(
      data.name ?? data.slug,
      data.slug,
      data.id,
      data.logoUrl,
      data.ubiflowPortalCode,
    );
  }

  get isLocked() {
    return this.id == null;
  }

  constructor(
    public readonly name: string,
    public readonly slug: string,
    public readonly id?: string,
    public readonly logoUrl?: string,
    public readonly ubiflowPortalCode?: string,
  ) {}

  public toData(): PortalData {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      logoUrl: this.logoUrl,
      ubiflowPortalCode: this.ubiflowPortalCode,
    };
  }
}
