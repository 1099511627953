import {
  Align,
  Button,
  Category,
  DateInput,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Label,
  Modal,
  MultiSelect,
  Size,
  Spinner,
  Tier,
  dateToObject,
  objectToDate,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';

import { ID } from '~/types';
import { createTranslate, isNullOrEmpty } from '~/utils';

import { Diffusion, Portal } from '../domain';
import { useDiffuse, useUpdateDiffusion } from '../hooks';
import { dateToUtcDate } from '../utils';

const tt = createTranslate('pods.multidiffusion.components.diffuse_modal');

interface DiffuseModalProps {
  isVisible: boolean;
  portals: Array<Portal>;
  advertId?: ID;
  onClickClose: VoidFunction;
  diffusion?: Diffusion;
  refresh?: () => Promise<void>;
}

export const DiffuseModal = ({
  isVisible,
  portals,
  advertId,
  onClickClose,
  diffusion,
  refresh,
}: DiffuseModalProps) => {
  const form = useForm({
    portals: [] as Array<string>,
    startingDate:
      diffusion?.startingDate != null ? dateToObject(dayjs(diffusion.startingDate).toDate()) : '',
    endingDate:
      diffusion?.endingDate != null ? dateToObject(dayjs(diffusion.endingDate).toDate()) : '',
  });
  const { isDiffusing, diffuse } = useDiffuse(advertId, refresh);
  const { isUpdatingDiffusion, updateDiffusion } = useUpdateDiffusion();

  useEffect(() => {
    // update default values
    form.reset();
  }, [diffusion?.id]);

  const _handleOnClickDiffuse = async () => {
    if (diffusion == null) {
      // create a new diffusion
      await diffuse(
        form.get('portals'),
        dateToUtcDate(objectToDate(form.get('startingDate'))),
        dateToUtcDate(objectToDate(form.get('endingDate'))),
      );
    } else {
      // edit a diffusion
      await updateDiffusion({
        id: diffusion.id,
        startDiffusionDate: dateToUtcDate(objectToDate(form.get('startingDate'))),
        endDiffusionDate: dateToUtcDate(objectToDate(form.get('endingDate'))),
      });
    }

    onClickClose();
  };

  return (
    <Modal
      visible={isVisible}
      title="Diffuse on a new portal"
      onClickClose={onClickClose}
      footer={
        <Flex align={FlexAlign.END} justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} category={Category.INFO} onClick={onClickClose}>
              {tt('buttons.cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              category={Category.INFO}
              leading={isDiffusing || isUpdatingDiffusion ? <Spinner /> : null}
              onClick={_handleOnClickDiffuse}>
              {tt('buttons.diffuse')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
        {diffusion == null ? (
          <Fragment>
            <FlexItem>
              <FormGroup
                label={<Label>{tt('form.portals.label')}</Label>}
                input={
                  <MultiSelect
                    name="portals"
                    values={form.get}
                    onChange={form.set}
                    options={portals.map((p) => ({
                      label: p.name,
                      value: p.slug,
                    }))}
                  />
                }
              />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
          </Fragment>
        ) : null}
        <FlexItem>
          <FormGroup
            label={<Label>{tt('form.starting_date.label')}</Label>}
            input={
              <DateInput
                minDate={dateToObject(new Date())}
                maxDate={isNullOrEmpty(form.get('endingDate')) ? undefined : form.get('endingDate')}
                align={Align.LEFT}
                name="startingDate"
                value={form.get}
                onChange={form.set}
              />
            }
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <FormGroup
            label={<Label>{tt('form.ending_date.label')}</Label>}
            input={
              <DateInput
                minDate={
                  isNullOrEmpty(form.get('startingDate'))
                    ? dateToObject(new Date())
                    : form.get('startingDate')
                }
                align={Align.LEFT}
                name="endingDate"
                value={form.get}
                onChange={form.set}
              />
            }
          />
        </FlexItem>
      </Flex>
    </Modal>
  );
};
