import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { Presentation } from './Presentation';

export const PresentationRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<Presentation />} />
    </RoutesWithNotFound>
  );
};
