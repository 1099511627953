import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  InlineEdit,
  Input,
  Size,
  TextArea,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import { css, cx } from 'emotion';
import { flow, reverse, size, slice, takeWhile } from 'lodash/fp';
import React from 'react';

import { AssetTile } from '~/components';
import { SectionIdentifier } from '~/pods/website-builder/types';
import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale, useMosaicMutation } from '~/utils';

import { AnyWebsiteSectionData, AssetData, TextImageSection, Website } from '../../../api/domain';
import { LoremIpsum } from '../../../utils';
import { ControlBracket } from '../ControlBracket';
import { Themed } from '../Themed';

const styles = {
  container: css`
    margin-top: ${sv.marginSmall};
    position: relative;
  `,
  inactive: css`
    opacity: 0.4;
  `,
  controls: css`
    position: absolute;
    height: 100%;
    right: ${sv.defaultMargin};
  `,
  textImage: css`
    /* outline: 2px dashed ${sv.brand};
    outline-offset: ${sv.marginExtraSmall}; */
    position: relative;
    margin: ${sv.marginExtraHuge} ${sv.marginMassive};
  `,
  image: css`
    min-height: 380px;
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
};

interface TextImageLineProps {
  textImage: TextImageSection;
  reversed: boolean;
  onChange: (id: string, data: Partial<TextImageSection>) => void;
  onChangeAsset: (sectionId: string, data: AssetData) => void;
  language: LocaleCode;
}

export const TextImageLine = ({
  textImage,
  reversed,
  onChangeAsset,
  onChange,
  language,
}: TextImageLineProps) => {
  const form = useForm({
    title: textImage.title ?? '',
    description: textImage.description ?? '',
  });
  const { showAlert } = useAlert();

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const image = textImage.assets[0] ?? undefined;

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (
    sectionId: ID,
    data: Pick<TextImageSection, 'title' | 'description'>,
  ) => {
    if (data.title != null) {
      data.title.length <= 200 ? onChange(sectionId, data) : _handleTextLengthError();
    }
    if (data.description != null) {
      data.description.length <= 800 ? onChange(sectionId, data) : _handleTextLengthError();
    }
  };

  const Paragraph = (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
      <FlexItem>
        <InlineEdit
          onCancel={() => form.set(textImage.title ?? '', 'title')}
          onClickConfirm={() => _handleUpdateSection(textImage.id, { title: form.get('title') })}
          edit={
            <Input
              style={{ fontSize: '1rem' }}
              name="textImageTitle"
              placeholder={tl('title_placeholder')}
              value={form.get('title')}
              onChange={(v) => form.set(v.toString(), 'title')}
              error={form.get('title').length > 200 && tl('max_200_characters_error')}
            />
          }>
          <Themed.Title
            size={2}
            align={Align.LEFT}
            style={{
              opacity: textImage.title != null && textImage.title !== '' ? '1' : '0.5',
            }}>
            {textImage.title != null && textImage.title !== ''
              ? textImage.title
              : LoremIpsum.MEDIUM}
          </Themed.Title>
        </InlineEdit>
      </FlexItem>
      <FlexItem>
        <InlineEdit
          onCancel={() => form.set(textImage.description ?? '', 'description')}
          onClickConfirm={() =>
            _handleUpdateSection(textImage.id, { description: form.get('description') })
          }
          edit={
            <TextArea
              className={styles.textarea}
              name="textImageDescription"
              placeholder={tl('project_intro_placeholder')}
              value={form.get('description')}
              onChange={(v) => form.set(v.toString(), 'description')}
              error={form.get('description').length > 800 && tl('max_800_characters_error')}
            />
          }>
          <Themed.Paragraph
            align={Align.LEFT}
            style={{
              opacity: textImage.description != null && textImage.description !== '' ? '1' : '0.5',
            }}>
            {textImage.description != null && textImage.description !== ''
              ? textImage.description
              : LoremIpsum.LONG}
          </Themed.Paragraph>
        </InlineEdit>
      </FlexItem>
    </Flex>
  );

  const Image = (
    <AssetTile
      asset={image as any}
      onFinishUpload={(asset) =>
        onChangeAsset(textImage.id, {
          assetId: image?.assetId,
          signedBlobId: asset.signedBlobId,
        })
      }
      mutationTargetType={useMosaicMutation}
    />
  );

  return (
    <Flex align={FlexAlign.STRETCH} style={{ marginTop: sv.marginExtraHuge }}>
      <FlexItem flex>{reversed ? Paragraph : Image}</FlexItem>
      <FlexSpacer size={Size.HUGE} />
      <FlexItem flex>{reversed ? Image : Paragraph}</FlexItem>
    </Flex>
  );
};

interface TextImageProps {
  textImage: TextImageSection;
  onChangeSection: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAssets: (sectionId: string, data: Array<AssetData>) => void;
  language: LocaleCode;
  sections: Website['sections'];
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const TextImage = ({
  textImage,
  onChangeSection,
  onChangeAssets,
  language,
  sections,
  onIncrement,
  onDecrement,
}: TextImageProps) => {
  const thisSectionIndex = sections.findIndex((section) => section.id == textImage.id);

  const isReversed = flow(
    slice(0, thisSectionIndex),
    reverse,
    takeWhile(
      (section: AnyWebsiteSectionData) => section.identifier === SectionIdentifier.TEXT_IMAGE,
    ),
    size,
    (val) => val % 2 === 0,
  )(sections);

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <ControlBracket
          sectionId={textImage.id}
          onToggleVisibility={() => onChangeSection(textImage.id, { active: !textImage?.isActive })}
          isActive={textImage?.isActive}
          onIncrement={() => onIncrement(textImage.id)}
          onDecrement={() => onDecrement(textImage.id)}
        />
      </div>
      <div className={cx(styles.textImage, { [styles.inactive]: !textImage.isActive })}>
        <TextImageLine
          key={textImage.id}
          textImage={textImage}
          reversed={isReversed}
          onChange={onChangeSection}
          onChangeAsset={(id, asset) => onChangeAssets(id, [asset])}
          language={language}
        />
      </div>
    </div>
  );
};
