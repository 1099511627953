import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  ButtonLink,
  Category,
  Color,
  Content,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Icon,
  Label,
  Margin,
  NumberInput,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Spinner,
  Tag,
  Tier,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import { css } from 'emotion';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { createTranslate } from '~/utils';

import { Banner, CostsPanel, DisclaimerPanel } from '../components';
import bannerSVG from '../images/banner.svg';
import { useFetchProject, useFetchSimulatorData } from '../utils';

const tt = createTranslate('pods.fiscal_simulator.routes.fiscal_simulator');

const styles = {
  icon: css`
    padding-right: ${sv.paddingExtraSmall};
  `,
};

export const FiscalSimulator = () => {
  const { showAlert } = useAlert();
  const { projectSlug } = useParams();
  const { data, isLoading, refetch } = useFetchSimulatorData(projectSlug!);
  const { project, isLoading: isProjectLoading } = useFetchProject(projectSlug!);

  const [amount, setAmount] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  if ((isLoading || isProjectLoading) && data == null) {
    return <Spinner fullSize />;
  }

  if (project == null || data == null) {
    return (
      <Margin size={{ top: Size.MASSIVE }}>
        <EmptyState
          variation={EmptyStateVariation.FAILED}
          title={tt('something_went_wrong')}
          description={tt('try_refreshing')}
        />
      </Margin>
    );
  }

  const toggleSaving = () => {
    setIsSaving((isSaving) => !isSaving);
  };

  return (
    <Content fullHeight>
      <Padding size={Size.LARGE}>
        <Flex>
          <FlexItem>
            <Title size={3}>{tt('cost_simulator')}</Title>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            {isSaving ? (
              <Tag color={Color.ORANGE}>{tt('saving')}</Tag>
            ) : (
              <Tag color={Color.GREEN}>{tt('saved')}</Tag>
            )}
          </FlexItem>
          <FlexSpacer flex />
          <FlexItem>
            <Button
              onClick={() => {
                copyToClipboard(`${location.origin}/cost-simulator/${projectSlug}`);
                showAlert({
                  text: tt('link_copied'),
                  category: Category.SUCCESS,
                });
              }}
              category={Category.INFO}>
              <Icon name="link-2" className={styles.icon} />
              {tt('simulator_link')}
            </Button>
          </FlexItem>
        </Flex>
        <Flex>
          <FlexItem flex>
            <Banner
              title={tt('make_fiscability')}
              description={tt('avoid_surprises')}
              imgSource={bannerSVG}
            />
          </FlexItem>
        </Flex>
        <Grid columns={10} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
          <GridItem span={7}>
            <CostsPanel
              refetch={refetch}
              projectId={project.id}
              toggleSaving={toggleSaving}
              taxes={data?.taxes ?? []}
              additionalCosts={data?.additionalFees ?? []}
            />
          </GridItem>
          <GridItem span={3}>
            <Margin size={{ bottom: Size.DEFAULT }}>
              <Panel
                header={
                  <PanelHeader>
                    <Flex justify={FlexJustify.SPACE_BETWEEN}>
                      <FlexItem>
                        <Title size={4} noMargin>
                          {tt('simulator')}
                        </Title>
                      </FlexItem>
                      <FlexItem>
                        <ButtonLink
                          tier={Tier.SECONDARY}
                          onClick={() => {
                            window.open(
                              `${location.origin}/cost-simulator/${projectSlug}?value=${amount}`,
                              '_blank',
                            );
                          }}>
                          {tt('try_it_out')}
                        </ButtonLink>
                      </FlexItem>
                    </Flex>
                  </PanelHeader>
                }
                body={
                  <PanelBody>
                    <FormGroup
                      label={<Label>{tt('unit_price')}</Label>}
                      input={
                        <NumberInput
                          value={amount}
                          onClick={(e: Event) => (e.target as HTMLInputElement).select()}
                          onChange={(v) => setAmount(Number(v))}
                          withCounter={false}
                        />
                      }
                    />
                  </PanelBody>
                }
              />
            </Margin>
            <DisclaimerPanel
              projectId={project.id}
              toggleSaving={toggleSaving}
              value={data?.costSimulatorDisclaimer ?? ''}
            />
          </GridItem>
        </Grid>
      </Padding>
    </Content>
  );
};
