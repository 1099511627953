import {
  Button,
  Category,
  CheckboxFilter,
  Content,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  Padding,
  SearchInput,
  Size,
} from '@drawbotics/react-drylus';
import React, { useState } from 'react';

import { ErrorPlaceholder, InviteModal, MosaicPageTitle } from '~/components';
import { Invitation, User } from '~/pods/settings/types';
import { useFetchOrganisation } from '~/pods/settings/utils';
import { InvitationStatus } from '~/types';
import { createTranslate } from '~/utils';

import { Member, TeamTable } from './components';

const tt = createTranslate('pods.settings.routes.company_team.company_team');

enum UserStatus {
  ACTIVE = 'ACTIVE',
  AWAITING_VALIDATION = 'AWAITING_VALIDATION',
  DISABLED = 'DISABLED',
}

export const CompanyTeam = () => {
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [statuses, setStatuses] = useState<UserStatus[]>([UserStatus.ACTIVE]);

  const {
    isLoading: isOrganisationLoading,
    organisation,
    refetch,
    error: organisationError,
  } = useFetchOrganisation();

  const invitedMembers: Array<Member> =
    organisation?.invitations == null
      ? []
      : organisation?.invitations
        .filter((invitation: Invitation) => invitation.status !== InvitationStatus.ACCEPTED)
        .map((invitation: Invitation) => ({
          id: invitation.id,
          uniqueId: `${invitation.id}-inv`,
          firstName: invitation.firstName,
          lastName: invitation.lastName,
          fullName: invitation.fullName,
          email: invitation.email,
          role: invitation.role,
          status: invitation.status,
          type: 'invitation',
          invitedOn: invitation.emailSentAt,
        }));

  const members: Array<Member> =
    organisation?.allUsers == null
      ? []
      : organisation?.allUsers.map((user: User) => ({
        id: user.id,
        uniqueId: `${user.id}-usr`,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        role: user.role,
        email: user.email,
        type: 'member',
        memberSince: user.createdAt,
        deactivatedAt: user.deactivatedAt,
      }));

  const allMembers = [...members, ...invitedMembers];
  const filteredMembers = allMembers
    .filter((member) => {
      if (searchTerm) {
        return (
          member.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        return true;
      }
    })
    .filter((member) => {
      if (statuses.length === 0) {
        return true;
      }

      let matchesFilter = false;

      if (statuses.includes(UserStatus.DISABLED)) {
        matchesFilter = Boolean(member.deactivatedAt);
      }

      if (statuses.includes(UserStatus.AWAITING_VALIDATION) && !matchesFilter) {
        matchesFilter = (
          member.status === InvitationStatus.SENT || member.status === InvitationStatus.INITIATED
        );
      }

      if (statuses.includes(UserStatus.ACTIVE) && !matchesFilter) {
        matchesFilter = !member.deactivatedAt && !(member.status === InvitationStatus.SENT || member.status === InvitationStatus.INITIATED);
      }

      return matchesFilter
    });

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <Flex>
          <FlexItem flex>
            <MosaicPageTitle>{tt('company_team')}</MosaicPageTitle>
          </FlexItem>
          <FlexItem>
            <CheckboxFilter
              options={[
                {
                  value: UserStatus.ACTIVE,
                  label: tt(`status_filter.option_labels.${UserStatus.ACTIVE.toLowerCase()}`),
                },
                {
                  value: UserStatus.AWAITING_VALIDATION,
                  label: tt(
                    `status_filter.option_labels.${UserStatus.AWAITING_VALIDATION.toLowerCase()}`,
                  ),
                },
                {
                  value: UserStatus.DISABLED,
                  label: tt(`status_filter.option_labels.${UserStatus.DISABLED.toLowerCase()}`),
                },
              ]}
              onChange={setStatuses}
              values={statuses}
              clearLabel={tt('status_filter.clear')}
              onClear={() => setStatuses([])}
              label={tt('status_filter.label')}
            />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <SearchInput
              minimal
              value={searchTerm ?? ''}
              placeholder={tt('search')}
              onChange={setSearchTerm}
            />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              disabled={organisationError != null}
              onClick={() => setIsInviteModalVisible(true)}
              category={Category.INFO}
              leading={<Icon name="mail" />}>
              {tt('invite_by_mail')}
            </Button>
          </FlexItem>
        </Flex>
        {organisationError != null ? (
          <ErrorPlaceholder error={organisationError} />
        ) : (
          <TeamTable
            members={organisation == null ? undefined : filteredMembers}
            isLoading={isOrganisationLoading}
            onSuccessfulMutation={refetch}
          />
        )}
      </Padding>
      <InviteModal
        title={tt('modal_title')}
        visible={isInviteModalVisible}
        onClickInvite={refetch}
        onClickClose={() => setIsInviteModalVisible(false)}
      />
    </Content>
  );
};
