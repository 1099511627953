import { TargetDomain, getJwt, getLatestJwt } from '@drawbotics/auth';
import { isEqual } from 'lodash';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import urlJoin from 'url-join';
import { useClient } from 'urql';

import { Attachment, User } from '~/types';

import { decodeUser } from '../decode-user';
import { useLogout } from './use-logout';

const UserQuery = `
  query User {
    user {
      profilePicture {
        id
        url
      }
    }
  }
`;

const AuthContext = React.createContext<{
  user: User | undefined;
  decodedUser: User | undefined;
  refreshUser: () => Promise<void>;
}>({
  user: undefined,
  decodedUser: undefined,
  refreshUser: () =>
    new Promise((resolve) => {
      resolve();
    }),
});

export const AuthProvider = (props: { children: ReactNode }) => {
  const [jwt, setJwt] = useState(getJwt());
  const [user, setUser] = useState<User>();
  const client = useClient();
  const logout = useLogout();

  const getUserPhoto = async (): Promise<Attachment | undefined> => {
    const userFromCore = await client
      .query(UserQuery, undefined, { url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql') })
      .toPromise();
    return userFromCore.data?.user?.profilePicture;
  };

  const setEnhancedUser = async () => {
    if (user != null) {
      const profilePicture = await getUserPhoto();
      const enhancedUser = { ...user, profilePicture };
      setUser(enhancedUser);
    }
  };

  const refreshUser = async () => {
    const token = await getLatestJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC);
    const decodedUser = token != null ? decodeUser(token) : user;
    if (token != null && decodedUser != null) {
      const profilePicture = await getUserPhoto();
      setUser({ ...decodedUser, profilePicture });
    }
  };

  useEffect(() => {
    setEnhancedUser();
  }, [jwt]);

  useEffect(() => {
    const _getLatestJwt = async () => {
      const latestJwt = await getLatestJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC);
      if (latestJwt != null) {
        setJwt(latestJwt);
      } else {
        setJwt(undefined);
      }
    };

    _getLatestJwt();
  }, []);

  if (jwt == null) {
    return (
      <AuthContext.Provider value={{ user: undefined, decodedUser: undefined, refreshUser }}>
        {props.children}
      </AuthContext.Provider>
    );
  }

  const decodedUser = decodeUser(jwt);
  const enhancedUser = decodedUser
    ? { ...decodedUser, profilePicture: user?.profilePicture }
    : undefined;

  if (decodedUser == null) {
    logout();
  } else if (!isEqual(user, enhancedUser)) {
    setUser(enhancedUser);
  }

  return (
    <AuthContext.Provider value={{ user: user ?? enhancedUser, decodedUser, refreshUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
