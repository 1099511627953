import { Color, Tag } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

import { DiffusionStatus } from '../types';

const tt = createTranslate('pods.multidiffusion.components.status_tag');

interface StatusTagProps {
  status: DiffusionStatus;
}

export const StatusTag = ({ status }: StatusTagProps) => {
  if (status === DiffusionStatus.PUBLISHED) {
    return <Tag color={Color.GREEN}>{tt('status.published')}</Tag>;
  } else if (status === DiffusionStatus.PENDING) {
    return <Tag color={Color.ORANGE}>{tt('status.pending_publication')}</Tag>;
  } else {
    return <Tag>{tt('status.unpublished')}</Tag>;
  }
};
