import gql from 'fraql';

import { LeadsQueryFilters } from '~/pods/crm/utils';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

export interface LeadCountQueryData {
  leadCount: {
    ownedLeadCount: number;
    totalLeadCount: number;
  };
}

export interface LeadCountQueryVariables {
  filter?: LeadsQueryFilters;
}

const LeadCountQuery = gql`
  query LeadCountQuery($filter: LeadFilterInput!) {
    leadCount(filter: $filter) {
      ownedLeadCount
      # Absolute total count, not affected by the filters or pagination
      totalLeadCount
    }
  }
`;

export function useFetchLeadCount(filters: LeadsQueryFilters): {
  isLoading: boolean;
  leadCount?: { ownedLeadCount: number; totalLeadCount: number };
  error?: ErrorType;
} {
  const filteredCountResult = useMosaicQuery<LeadCountQueryData, LeadCountQueryVariables>({
    query: LeadCountQuery,
    variables: { filter: filters ?? {} },
    requestPolicy: 'cache-and-network',
  });

  return {
    isLoading: filteredCountResult.isLoading,
    leadCount: filteredCountResult.data?.leadCount,
    error: buildError(filteredCountResult.error),
  };
}
