import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Content,
  EmptyState,
  Flex,
  FlexDirection,
  FlexItem,
  Padding,
  Panel,
  PanelBody,
  Separator,
  Shade,
  Size,
  TBody,
  TCell,
  THead,
  TRow,
  Table,
  Text,
  Tier,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import dayjs from 'dayjs';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment } from 'react';

import { MosaicPageTitle, SettingsSection } from '~/components';
import { ID, Subscription, UserLocale } from '~/types';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
  useAuth,
  useIntercom,
  useMosaicMutation,
} from '~/utils';

import { AvailabilityRow, PersonalScheduling, SSOCalendarLink } from './components';
import availabilitiesPlaceholder from './images/availabilities.svg';
import { AvailabilityAddress, Day, Timeslot } from './types';
import { useFetchAvailabilities } from './utils';

const tt = createTranslate('pods.marketing_suite.routes.availabilities');

const styles = {
  title: css`
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: ${sv.marginSmall};
  `,
};

const createTimeslotMutation = gql`
  mutation createAvailability($timeslot: CreateAvailabilityInput!) {
    createAvailability(input: $timeslot) {
      availability {
        id
      }
    }
  }
`;

const updateTimeslotMutation = gql`
  mutation updateInterval($timeslot: UpdateIntervalInput!) {
    updateInterval(input: $timeslot) {
      availability {
        id
      }
    }
  }
`;

const deleteTimeslotMutation = gql`
  mutation destroyInterval($timeslot: DestroyIntervalInput!) {
    destroyInterval(input: $timeslot) {
      availability {
        id
      }
    }
  }
`;

const updateAvailabilityMutation = gql`
  mutation updateAvailability($availability: UpdateAvailabilityInput!) {
    updateAvailability(input: $availability) {
      availabilities {
        id
      }
    }
  }
`;

interface UpdateAvailabilityInput {
  day: Day;
  addressId: ID | null;
  active?: boolean;
  remote?: boolean;
}

export const Availabilities = () => {
  const { addresses, availabilities, isLoading, error, refetchAvailabilities, schedulingToken } =
    useFetchAvailabilities();
  const { showAlert } = useAlert();
  const { showIntercom } = useIntercom();
  const { user } = useAuth();

  const { executeMutation: createTimeslot } = useMosaicMutation<
    { id: ID },
    { timeslot: { day: Day; startTime: string; endTime: string; timezone: string; addressId?: ID } }
  >(createTimeslotMutation);

  const { executeMutation: updateTimeslot } = useMosaicMutation<
    { id: ID },
    { timeslot: { id: ID; startTime?: string; endTime?: string } }
  >(updateTimeslotMutation);

  const { executeMutation: deleteTimeslot } = useMosaicMutation<
    { id: ID },
    { timeslot: { id: ID } }
  >(deleteTimeslotMutation);

  const { executeMutation: updateAvailability } = useMosaicMutation<
    { id: ID },
    { availability: UpdateAvailabilityInput }
  >(updateAvailabilityMutation);

  const handleChangeTimeslot = async (timeslot: Partial<Timeslot>, isNew: boolean, day: Day) => {
    let result;
    if (isNew) {
      if (timeslot.start == null || timeslot.end == null) {
        console.error('Expected fields `start` and `end` to be present in createTimeslot');
        return;
      }

      const res = await createTimeslot({
        timeslot: {
          day,
          startTime: timeslot.start,
          endTime: timeslot.end,
          timezone: dayjs.tz.guess(),
        },
      });

      if (res.error != null) {
        showAlert({
          text: tt('create_timeslot_error'),
          category: Category.DANGER,
        });
        return;
      }

      result = res.data?.id;
    } else {
      if (timeslot.id == null) {
        console.error('Expected field `id` to be present in updateTimeslot');
        return;
      }

      const { id, start, end } = timeslot;
      const res = await updateTimeslot({ timeslot: { id, startTime: start, endTime: end } });

      if (res.error != null) {
        showAlert({ text: tt('update_timeslot_error'), category: Category.SUCCESS });
        return;
      }

      result = res.data?.id;
    }
    refetchAvailabilities();
    showAlert({ text: tt('update_timeslot_success'), category: Category.SUCCESS });

    return result;
  };

  const handleDeleteTimeslot = async (id: ID) => {
    const res = await deleteTimeslot({ timeslot: { id } });

    if (res.error != null) {
      showAlert({ text: tt('delete_timeslot_error'), category: Category.DANGER });
      return;
    }

    refetchAvailabilities();
    showAlert({ text: tt('delete_timeslot_success'), category: Category.SUCCESS });
  };

  const handleUpdateAvailability = async ({
    day,
    addressId,
    isActive,
    remote,
  }: {
    day: Day;
    addressId?: AvailabilityAddress;
    isActive?: boolean;
    remote?: boolean;
  }) => {
    const updatedAvailability: Partial<UpdateAvailabilityInput> = {
      day,
      remote,
      addressId,
      active: isActive,
    };

    const res = await updateAvailability({
      availability: updatedAvailability as UpdateAvailabilityInput,
    });

    if (res.error != null) {
      showAlert({
        text: tt('update_availabilities_error'),
        category: Category.DANGER,
      });
      return;
    }

    showAlert({
      text: tt('update_availabilities_success'),
      category: Category.SUCCESS,
    });
    refetchAvailabilities();
  };

  return (
    <SettingsSection
      left={
        <Fragment>
          <Title size={4}>{tt('availabilities')}</Title>
          <Text tier={Tier.SECONDARY}>{tt('side_description')}</Text>
        </Fragment>
      }
      right={run(() => {
        if (user?.organisation?.subscription === Subscription.ESSENTIALS) {
          return (
            <Content fullHeight fullWidth>
              <Padding size={Size.EXTRA_LARGE}>
                <MosaicPageTitle>{tt('availabilities')}</MosaicPageTitle>
                <Panel
                  body={
                    <PanelBody>
                      <Padding size={{ vertical: Size.HUGE }}>
                        <EmptyState
                          image={availabilitiesPlaceholder}
                          title={tt('availabilities_will_show')}
                          description={tt('upgrade_to_growth')}>
                          <Button category={Category.INFO} onClick={showIntercom}>
                            {tt('contact_us')}
                          </Button>
                        </EmptyState>
                      </Padding>
                    </PanelBody>
                  }
                />
              </Padding>
            </Content>
          );
        }

        if (error != null) {
          return (
            <Flex direction={FlexDirection.VERTICAL} style={{ height: '100%' }}>
              <FlexItem>
                <EmptyState
                  title={getTitleFromErrorType(error)}
                  variation={getEmptyStateVariationFromErrorType(error)}
                  description={getMessageFromErrorType(error)}
                />
              </FlexItem>
            </Flex>
          );
        }

        return (
          <Panel
            body={
              <Fragment>
                <PanelBody noPadding>
                  <Table
                    header={[
                      { label: tt('table.days'), value: 'days' },
                      { label: tt('table.times'), value: 'times' },
                      { label: tt('table.location'), value: 'location' },
                    ]}
                    isLoading={isLoading && availabilities.length === 0}
                    loadingRows={7}>
                    <THead>
                      <TCell>{tt('table.days')}</TCell>
                      <TCell>{tt('table.times')}</TCell>
                      <TCell>{tt('table.location')}</TCell>
                    </THead>
                    <TBody>
                      {availabilities.map((day) => (
                        <TRow style={{ verticalAlign: 'top' }} key={day.name}>
                          <AvailabilityRow
                            day={day}
                            addresses={addresses}
                            onChangeTimeslot={async (timeslot, isNew) =>
                              await handleChangeTimeslot(timeslot, isNew, day.name)
                            }
                            onDeleteTimeslot={handleDeleteTimeslot}
                            onChangeRow={handleUpdateAvailability}
                          />
                        </TRow>
                      ))}
                    </TBody>
                  </Table>
                </PanelBody>
                <Padding size={Size.DEFAULT}>
                  <FlexItem>
                    <SSOCalendarLink />
                  </FlexItem>
                </Padding>
                <Separator />
                {user?.organisation?.isAgency ? null : (
                  <Padding size={Size.DEFAULT}>
                    <FlexItem>
                      <div>
                        <Text className={styles.title} shade={Shade.MEDIUM}>
                          {tt('personal_scheduling_title')}
                        </Text>
                        <Text shade={Shade.MEDIUM}>{tt('personal_scheduling_description')}</Text>
                      </div>
                      <PersonalScheduling
                        locale={user?.locale as UserLocale}
                        schedulingToken={schedulingToken}
                        organisation={user?.organisation}
                      />
                    </FlexItem>
                  </Padding>
                )}
              </Fragment>
            }
          />
        );
      })}
    />
  );
};
