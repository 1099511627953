import gql from 'fraql';

import { User } from '~/pods/crm/types';
import { useMosaicQuery } from '~/utils/hooks';

export interface UsersQueryData {
  users: Array<User>;
}

const UsersQuery = gql`
  query UsersQuery {
    users {
      id
      firstName
      lastName
      fullName @computed(type: User)
      email
    }
  }
`;

export function useFetchUsers(): { isUsersLoading: boolean; users?: Array<User> } {
  const res = useMosaicQuery<UsersQueryData, null>({
    query: UsersQuery,
  });

  return {
    isUsersLoading: res.isLoading,
    users: res.data?.users,
  };
}
