import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  ListTile,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelSection,
  Position,
  Separator,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import gql from 'graphql-tag';
import React, { Fragment, useEffect, useState } from 'react';

import { createTranslate, useDebounce, useMosaicMutation } from '~/utils';

import { Fee, FeeType, FiscalSimulator, Tax } from '../types';

const tt = createTranslate('pods.fiscal_simulator.components.costs_panel');

interface CreateFeeVariables {
  input: {
    projectId: string;
    reference: string;
    coefficient: number;
    unitDistribution?: number;
    costType: FeeType;
  };
}

interface CreateFeeResult {
  createCostSimulatorFee: {
    costSimulatorFee: Fee | Tax;
  };
}

const createFeeMutation = gql`
  mutation createFee($input: CreateCostSimulatorFeeInput!) {
    createCostSimulatorFee(input: $input) {
      costSimulatorFee {
        id
      }
    }
  }
`;

interface DeleteFeeVariables {
  id: string;
}

interface DeleteFeeResult {
  deleteCostSimulatorFee: {
    costSimulatorFee: { id: string };
  };
}

const deleteFeeMutation = gql`
  mutation deleteFee($id: ID!) {
    deleteCostSimulatorFee(input: { id: $id }) {
      costSimulatorFee {
        id
      }
    }
  }
`;

interface EditFeeVariables {
  input: {
    id: string;
    reference?: string;
    coefficient?: number;
    unitDistribution?: number;
  };
}

interface EditFeeResult {
  updateCostSimulatorFee: {
    costSimulatorFee: { id: string };
  };
}

const editFeeMutation = gql`
  mutation editFee($input: UpdateCostSimulatorFeeInput!) {
    updateCostSimulatorFee(input: $input) {
      costSimulatorFee {
        id
      }
    }
  }
`;

function _isTax(cost: Tax | Fee): cost is Tax {
  return 'unitDistribution' in cost;
}

interface CostForm {
  reference: string;
  coefficient: number;
  unitDistribution?: number;
}

interface CostRowProps {
  onChange: (values: Partial<Tax | Fee> & { id: string }) => void;
  onClickDelete?: VoidFunction;
  isDeleting?: boolean;
  cost: Tax | Fee;
}

const CostRow = ({ onClickDelete, isDeleting, cost, onChange }: CostRowProps) => {
  const costForm = useForm<CostForm>(cost);
  const debouncedFormValues = useDebounce(costForm.values, 500);
  const [hasError, setHasError] = useState(false);

  const handleOnChange = (value: string | number, name?: keyof CostForm) => {
    const numericalValue = Number(value);
    if (numericalValue === 0 || numericalValue) {
      costForm.set(numericalValue, name);
    }
  };

  useEffect(() => {
    setHasError(
      (costForm.values.reference === '-' || costForm.values.reference === '') &&
        costForm.values.coefficient !== 0,
    );
    onChange({ id: cost.id, ...debouncedFormValues });
  }, [debouncedFormValues]);

  const withDistribution = _isTax(cost);

  return (
    <Padding size={{ bottom: Size.SMALL }}>
      <Flex align={FlexAlign.START}>
        <FlexItem flex>
          <FormGroup
            label={<Label>{tt('reference')}</Label>}
            input={
              <Input
                name="reference"
                error={hasError ? tt('reference_cannot_be_empty') : false}
                placeholder="e.g. VAT"
                value={costForm.values.reference === '-' ? '' : costForm.values.reference!}
                onChange={costForm.set}
              />
            }
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem flex={onClickDelete && !withDistribution ? 5 / 12 : 1}>
          <FormGroup
            label={<Label>{tt('coefficient')}</Label>}
            input={
              <Input
                name="coefficient"
                trailing="%"
                value={costForm.get}
                onChange={handleOnChange}
              />
            }
          />
        </FlexItem>
        {withDistribution ? <FlexSpacer size={Size.SMALL} /> : null}
        {_isTax(cost) ? (
          <FlexItem flex={onClickDelete ? 1 / 4 : 1}>
            <FormGroup
              label={
                <ListTile
                  title={<Label>{tt('unit_distribution')}</Label>}
                  trailing={
                    <Tooltip side={Position.RIGHT} content={tt('ratio_of_a_unit')}>
                      <Icon name="info" shade={Shade.MEDIUM} />
                    </Tooltip>
                  }
                />
              }
              input={
                <Input
                  name="unitDistribution"
                  trailing="%"
                  value={costForm.get}
                  onChange={handleOnChange}
                />
              }
            />
          </FlexItem>
        ) : null}
        {onClickDelete != null ? (
          <FlexItem style={{ display: 'flex' }}>
            <Margin size={{ left: Size.SMALL, top: Size.DEFAULT }}>
              <Button
                tabIndex="-1"
                onClick={onClickDelete}
                leading={isDeleting ? <Spinner size={Size.SMALL} /> : <Icon name="trash" />}
                tier={Tier.SECONDARY}
              />
            </Margin>
          </FlexItem>
        ) : null}
      </Flex>
    </Padding>
  );
};

interface CostsPanelProps {
  projectId: string;
  toggleSaving: VoidFunction;
  taxes: FiscalSimulator['taxes'];
  additionalCosts: FiscalSimulator['additionalFees'];
  refetch: VoidFunction;
}

export const CostsPanel = ({
  projectId,
  taxes,
  additionalCosts,
  refetch,
  toggleSaving,
}: CostsPanelProps) => {
  const [isCreating, setIsCreating] = useState(0);
  const [isDeleting, setIsDeleting] = useState<string>();
  const { showAlert } = useAlert();

  const { executeMutation: createFee } = useMosaicMutation<CreateFeeResult, CreateFeeVariables>(
    createFeeMutation,
  );
  const { executeMutation: deleteFee } = useMosaicMutation<DeleteFeeResult, DeleteFeeVariables>(
    deleteFeeMutation,
  );
  const { executeMutation: editFee } = useMosaicMutation<EditFeeResult, EditFeeVariables>(
    editFeeMutation,
  );

  const handleCreateFee = async (costType: FeeType) => {
    setIsCreating(costType === FeeType.TAX ? 1 : 2);
    const res = await createFee({ input: { projectId, reference: '-', coefficient: 0, costType } });
    setIsCreating(0);

    if (res.error != null) {
      showAlert({ text: tt('something_went_wrong_create'), category: Category.DANGER });
    }
  };

  const handleDeleteFee = async (id: string) => {
    setIsDeleting(id);
    const res = await deleteFee({ id });
    setIsDeleting(undefined);

    if (res.error != null) {
      showAlert({ text: tt('something_went_wrong_delete'), category: Category.DANGER });
    }
  };

  const handleEditFee = async (fee: Partial<Tax | Fee> & { id: string }) => {
    toggleSaving();
    const res = await editFee({ input: fee });
    toggleSaving();

    if (res.error != null) {
      showAlert({ text: tt('something_went_wrong_edit'), category: Category.DANGER });
    }
  };

  return (
    <Panel
      body={
        <PanelBody>
          <PanelSection title={tt('taxes')}>
            {taxes.length === 0 ? (
              <Button
                category={Category.INFO}
                trailing={isCreating === 1 ? <Spinner size={Size.SMALL} inversed /> : null}
                onClick={async () => {
                  await handleCreateFee(FeeType.TAX);
                  refetch();
                }}>
                {tt('add_a_tax')}
              </Button>
            ) : (
              <Fragment>
                {[...taxes]
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map((tax) => (
                    <CostRow
                      onChange={handleEditFee}
                      cost={tax}
                      key={tax.id}
                      isDeleting={isDeleting === tax.id}
                      onClickDelete={() => handleDeleteFee(tax.id)}
                    />
                  ))}
                <Button
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  size={Size.SMALL}
                  tier={Tier.TERTIARY}
                  leading="+"
                  trailing={
                    isCreating === 1 ? <Spinner size={Size.SMALL} color={Color.BLUE} /> : null
                  }
                  onClick={() => handleCreateFee(FeeType.TAX)}>
                  {tt('add_tax')}
                </Button>
                {taxes.reduce((memo, t) => t.unitDistribution + memo, 0) > 100 ? (
                  <Text style={{ marginTop: -sv.defaultMargin }} category={Category.DANGER}>
                    {tt('unit_distribution_cannot_exceed')}
                  </Text>
                ) : null}
              </Fragment>
            )}
          </PanelSection>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Separator />
          </Margin>
          <PanelSection title={tt('additional_fees')}>
            {additionalCosts.length === 0 ? (
              <Button
                category={Category.INFO}
                trailing={isCreating === 2 ? <Spinner size={Size.SMALL} inversed /> : null}
                onClick={async () => {
                  await handleCreateFee(FeeType.ADDITIONAL_FEE);
                  refetch();
                }}>
                {tt('add_a_fee')}
              </Button>
            ) : (
              <Fragment>
                {[...additionalCosts]
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map((ac) => (
                    <CostRow
                      key={ac.id}
                      onChange={handleEditFee}
                      cost={ac}
                      isDeleting={isDeleting === ac.id}
                      onClickDelete={() => handleDeleteFee(ac.id)}
                    />
                  ))}
                <Button
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  size={Size.SMALL}
                  tier={Tier.TERTIARY}
                  onClick={() => handleCreateFee(FeeType.ADDITIONAL_FEE)}
                  trailing={
                    isCreating === 2 ? <Spinner size={Size.SMALL} color={Color.BLUE} /> : null
                  }
                  leading="+">
                  {tt('add_a_fee')}
                </Button>
              </Fragment>
            )}
          </PanelSection>
        </PanelBody>
      }
    />
  );
};
