import {
  Button,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Modal,
  Select,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { useEffect } from 'react';

import { Optional, ShowroomAddress } from '~/types';
import { createTranslate, getCountryOptions, isNullOrEmpty } from '~/utils';

interface CreateAndEditAddressModalProps {
  isVisible: boolean;
  address?: ShowroomAddress;
  onClickClose: VoidFunction;
  onSave: (address: Optional<ShowroomAddress, 'id'>) => Promise<void>;
  isFetching: boolean;
}

const tt = createTranslate('pods.settings.routes.basic_info');

export const CreateAndEditAddressModal = ({
  isVisible,
  address,
  onClickClose,
  onSave,
  isFetching,
}: CreateAndEditAddressModalProps) => {
  const isEditingMode = address != null;
  const addressForm = useForm<ShowroomAddress>({
    city: '',
    zipCode: '',
    countryCode: undefined,
    street: '',
  });

  useEffect(() => {
    if (address != null) {
      Object.entries(address).forEach(([key, value]) => {
        addressForm.set(value, key as keyof ShowroomAddress);
      });
    } else {
      addressForm.reset();
    }
  }, [JSON.stringify(address), isVisible]);

  useEffect(() => {
    if (!isVisible) {
      addressForm.reset();
    }
  }, [isVisible]);

  const handleSave = async () => {
    await onSave({ ...address, ...addressForm.values } as ShowroomAddress);
    onClickClose();
  };

  const isSaveDisabled =
    isFetching ||
    Object.values(addressForm.values).some((val) => isNullOrEmpty(val)) ||
    (isEditingMode &&
      Object.entries(addressForm.values).every(
        ([key, value]) => value === address?.[key as keyof ShowroomAddress],
      ));

  return (
    <Modal
      title={isEditingMode ? tt('edit_address') : tt('create_address')}
      visible={isVisible}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END} style={{ width: '100%' }}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={onClickClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer />
          <FlexItem>
            <Button
              color={Color.BLUE}
              disabled={isSaveDisabled}
              onClick={handleSave}
              leading={isFetching ? <Spinner /> : null}>
              {tt('save')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Flex>
        <FlexItem flex={1}>
          <FormGroup
            label={<Label>{tt('street')}</Label>}
            input={<Input name="street" value={addressForm.get} onChange={addressForm.set} />}
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem flex={0.25}>
          <FormGroup
            label={<Label>{tt('zip_code')}</Label>}
            input={<Input name="zipCode" value={addressForm.get} onChange={addressForm.set} />}
          />
        </FlexItem>
      </Flex>
      <FlexSpacer size={Size.SMALL} />

      <FormGroup
        label={<Label>{tt('city')}</Label>}
        input={<Input name="city" value={addressForm.get} onChange={addressForm.set} />}
      />
      <FlexSpacer size={Size.SMALL} />

      <FormGroup
        label={<Label>{tt('country')}</Label>}
        input={
          <Select
            onChange={addressForm.set}
            name="countryCode"
            value={addressForm.get}
            options={getCountryOptions()}
          />
        }
      />
    </Modal>
  );
};
