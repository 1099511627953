import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Dot,
  Flex,
  FlexItem,
  FlexJustify,
  Grid,
  GridItem,
  Icon,
  Margin,
  Shade,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { range } from 'lodash';
import React, { useState } from 'react';

import { ID } from '~/types';
import { createTranslate } from '~/utils';

import { AnyWebsiteSectionData, AssetData, GallerySection } from '../../../api/domain';
import { ControlButtons } from '../ControlButtons';
import { UploadGalleryModal } from '../UploadGalleryModal';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const styles = {
  container: css`
    margin: 0px ${sv.marginSmall};
    position: relative;
  `,
  inactive: css`
    opacity: 0.4;
  `,
  controls: css`
    position: absolute;
    top: -50px;
    right: 0px;
  `,
  gallery: css`
    outline: 2px dashed ${sv.blue};
    outline-offset: ${sv.marginExtraSmall};
    position: relative;

    > [data-element='upload-overlay'] {
      opacity: 0;
    }

    &:hover {
      > i {
        display: none;
      }

      > [data-element='upload-overlay'] {
        opacity: 1;
      }
    }
  `,
  tile: css`
    height: 250px;
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${sv.darkOverlay};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: ${sv.transitionShort};
    color: ${sv.colorPrimaryInverse};
    z-index: 3;
  `,
  empty: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${sv.neutralLight};
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
};

interface GalleryProps {
  gallery: GallerySection;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAssets: (sectionId: string, data: Array<AssetData>) => void;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Gallery = ({
  gallery,
  onChange,
  onChangeAssets,
  onIncrement,
  onDecrement,
}: GalleryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <ControlButtons
          sectionId={gallery.id}
          horizontal
          onClickEdit={() => setIsModalOpen(true)}
          onToggleVisibility={() => onChange(gallery.id, { active: !gallery.isActive })}
          isActive={gallery.isActive}
          onIncrement={() => onIncrement(gallery.id)}
          onDecrement={() => onDecrement(gallery.id)}
        />
      </div>
      <div className={cx(styles.gallery, { [styles.inactive]: !gallery.isActive })}>
        <div className={styles.overlay} data-element="upload-overlay">
          <Button
            onClick={() => setIsModalOpen(true)}
            leading={<Icon name="upload" />}
            inversed
            tier={Tier.SECONDARY}>
            {tt('upload_images')}
          </Button>
        </div>
        <Grid columns={4} vGutters={Size.EXTRA_SMALL}>
          {gallery.assets.length === 0
            ? range(4).map((index) => (
                <GridItem key={index}>
                  <div className={cx(styles.tile, styles.empty)}>
                    <Icon shade={Shade.MEDIUM} name="image" />
                  </div>
                </GridItem>
              ))
            : gallery.assets
                .slice()
                .sort((a, b) => a.position! - b.position!)
                .slice(0, 4)
                .map((asset) => (
                  <GridItem key={asset.assetId}>
                    <div className={styles.tile}>
                      <img className={styles.image} src={asset.url} />
                    </div>
                  </GridItem>
                ))}
        </Grid>
      </div>
      <Margin size={Size.LARGE}>
        <Flex justify={FlexJustify.SPACE_BETWEEN}>
          <FlexItem>
            <Icon shade={Shade.LIGHT} name="arrow-left" />
          </FlexItem>
          <FlexItem>
            <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ width: '100px' }}>
              {range(4).map((index) => (
                <FlexItem key={index}>
                  <Dot color={Shade.LIGHT} />
                </FlexItem>
              ))}
            </Flex>
          </FlexItem>
          <FlexItem>
            <Icon shade={Shade.LIGHT} name="arrow-right" />
          </FlexItem>
        </Flex>
      </Margin>
      <UploadGalleryModal
        assets={gallery.assets}
        visible={isModalOpen}
        onClickClose={() => setIsModalOpen(false)}
        onChangeAssets={(assets) => onChangeAssets(gallery.id, assets)}
      />
    </div>
  );
};
