import gql from 'fraql';

import { Id } from '~/types';
import { useMosaicQuery } from '~/utils';

interface FavoredProjectsQueryData {
  user: {
    favoredProjects: Id[];
  };
}

const FavoredProjectsQuery = gql`
  query FavoredProjects {
    user {
      favoredProjects
    }
  }
`;

export function useFetchFavoredProjects() {
  const res = useMosaicQuery<FavoredProjectsQueryData, undefined>({
    query: FavoredProjectsQuery,
  });

  return res;
}
