import sv from '@drawbotics/drylus-style-vars';
import {
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  InlineEdit,
  Input,
  Margin,
  Paragraph,
  Shade,
  Size,
  Spinner,
  Text,
  TextArea,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React from 'react';

import { AssetTile } from '~/components';
import { OrganisationOverview } from '~/pods/meetings/types';
import { isFieldEmptyString } from '~/pods/meetings/utils';
import { AttachmentAsset, HexColor } from '~/types';
import { createTranslate, extractGQLErrorMessages, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  paragraphLine: css`
    width: 3px;
    height: calc(100% - ${sv.marginSmall} * 2);
    margin-top: ${sv.marginSmall};
    background: ${sv.colorSecondary};
  `,
};

interface UpdateOverviewVariables {
  overview: {
    title?: string;
    subtitle?: string;
    description?: string;
    mainAsset?: {
      assetId?: string;
      signedBlobId?: string;
    };
  };
}

interface UpdateOverviewResult {
  updateOverview: {
    overview: OrganisationOverview;
  };
}

const updateOverviewMutation = gql`
  mutation updateOverview($overview: UpdateOverviewInput!) {
    updateOverview(input: $overview) {
      overview {
        title
        subtitle
        description
        mainAsset {
          id
          url
          ... on AttachmentAsset {
            filename
          }
        }
      }
    }
  }
`;

interface OverviewForm {
  title: string;
  subtitle: string;
  description: string;
}

const paragraphPlaceholder = `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.

Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`;

interface OverviewProps {
  overview: OrganisationOverview;
  primaryColor?: HexColor;
  readOnly: boolean;
}

export const Overview = ({ overview, primaryColor, readOnly }: OverviewProps) => {
  const overviewForm = useForm<OverviewForm>({
    title: overview.title ?? '',
    subtitle: overview.subtitle ?? '',
    description: overview.description ?? '',
  });
  const { showAlert } = useAlert();

  const { res: updateOverviewResult, executeMutation: updateOverview } = useMosaicMutation<
    UpdateOverviewResult,
    UpdateOverviewVariables
  >(updateOverviewMutation);

  const handleResetValue = (name: keyof OverviewForm) => {
    overviewForm.set(overview[name] ?? '', name);
  };

  const handleSaveValue = async (name: keyof OverviewForm) => {
    const res = await updateOverview({ overview: { [name]: overviewForm.values[name] } });
    if (res.error != null) {
      const message = extractGQLErrorMessages(res.error);
      showAlert({
        text:
          message.message != null
            ? tt(`errors.${message.message}`)
            : tt('could_not_save', { name: tt(name) }),
        category: Category.DANGER,
      });
      handleResetValue(name);
    }
  };

  return (
    <Flex align={FlexAlign.START}>
      <FlexItem flex style={{ position: 'relative' }}>
        <Margin size={{ top: Size.LARGE }} />
        {updateOverviewResult.fetching ? (
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <Spinner />
          </div>
        ) : null}
        {readOnly ? (
          <Text
            shade={overview.subtitle == null ? Shade.MEDIUM : undefined}
            style={{ textTransform: 'uppercase' }}>
            {isFieldEmptyString(overviewForm.values.subtitle)
              ? 'Lorem Ipsum'
              : overviewForm.values.subtitle}
          </Text>
        ) : (
          <InlineEdit
            onCancel={() => handleResetValue('subtitle')}
            onClickConfirm={() => handleSaveValue('subtitle')}
            edit={
              <Input
                name="subtitle"
                size={Size.SMALL}
                placeholder="Lorem Ipsum"
                value={overviewForm.get}
                onChange={overviewForm.set}
              />
            }>
            <Text
              shade={overview.subtitle == null ? Shade.MEDIUM : undefined}
              style={{ textTransform: 'uppercase' }}>
              {isFieldEmptyString(overviewForm.values.subtitle)
                ? 'Lorem Ipsum'
                : overviewForm.values.subtitle}
            </Text>
          </InlineEdit>
        )}
        <Margin size={{ vertical: Size.DEFAULT }}>
          {readOnly ? (
            <Title
              noMargin
              style={{ color: overview.title == null ? sv.colorSecondary : undefined }}
              size={3}>
              {isFieldEmptyString(overviewForm.values.title)
                ? 'Lorem ipsum dolor sit amet consectitur'
                : overviewForm.values.title}
            </Title>
          ) : (
            <InlineEdit
              onCancel={() => handleResetValue('title')}
              onClickConfirm={() => handleSaveValue('title')}
              edit={
                <TextArea
                  name="title"
                  disabled={readOnly}
                  cols={300}
                  rows={2}
                  size={Size.SMALL}
                  placeholder="Lorem ipsum dolor sit amet consectitur"
                  value={overviewForm.get}
                  onChange={overviewForm.set}
                />
              }>
              <Title
                noMargin
                style={{ color: overview.title == null ? sv.colorSecondary : undefined }}
                size={3}>
                {isFieldEmptyString(overviewForm.values.title)
                  ? 'Lorem ipsum dolor sit amet consectitur'
                  : overviewForm.values.title}
              </Title>
            </InlineEdit>
          )}
        </Margin>
        <Flex align={FlexAlign.STRETCH}>
          <FlexItem>
            <div style={{ backgroundColor: primaryColor }} className={styles.paragraphLine} />
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex>
            <Text size={Size.SMALL}>
              {readOnly ? (
                <Paragraph
                  style={{
                    whiteSpace: 'pre-line',
                    color: overview.description == null ? sv.colorSecondary : undefined,
                  }}>
                  {isFieldEmptyString(overviewForm.values.description)
                    ? paragraphPlaceholder
                    : overviewForm.values.description}
                </Paragraph>
              ) : (
                <InlineEdit
                  onCancel={() => handleResetValue('description')}
                  onClickConfirm={() => handleSaveValue('description')}
                  edit={
                    <TextArea
                      name="description"
                      disabled={readOnly}
                      cols={300}
                      rows={15}
                      size={Size.SMALL}
                      placeholder={paragraphPlaceholder}
                      value={overviewForm.get}
                      onChange={overviewForm.set}
                    />
                  }>
                  <Paragraph
                    style={{
                      whiteSpace: 'pre-line',
                      color: overview.description == null ? sv.colorSecondary : undefined,
                    }}>
                    {isFieldEmptyString(overviewForm.values.description)
                      ? paragraphPlaceholder
                      : overviewForm.values.description}
                  </Paragraph>
                </InlineEdit>
              )}
            </Text>
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_LARGE} />
      <FlexItem style={{ height: 400 }} flex>
        <AssetTile
          asset={overview.mainAsset as AttachmentAsset}
          readOnly={readOnly}
          onFinishUpload={async (uploadedFile) => {
            const res = await updateOverview({
              overview: {
                mainAsset: {
                  signedBlobId: uploadedFile.signedBlobId,
                  assetId: overview.mainAsset?.id,
                },
              },
            });
            if (res.error != null) {
              showAlert({ text: tt('could_not_upload'), category: Category.DANGER });
            }
            return res.error == null;
          }}
        />
      </FlexItem>
    </Flex>
  );
};
