import {
  Checkbox,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { LeadsFilterTypes } from '~/pods/crm/types';
import { PortalData } from '~/pods/multidiffusion/domain';

interface PortalFilterProps {
  filter: LeadsFilterTypes['portalSlugs'];
  portals: Array<PortalData>;
}

export const PortalFilter = ({ filter, portals }: PortalFilterProps) => {
  const _toggleSelection = (value: string): void => {
    if (filter.value == null) {
      filter.set([value]);
    } else if (Array.isArray(filter.value)) {
      if (filter.value.includes(value)) {
        filter.set(filter.value.filter((userId) => userId !== value));
      } else {
        filter.set([...filter.value, value]);
      }
    } else {
      if (value === filter.value) {
        filter.set(null);
      } else {
        filter.set([filter.value, value]);
      }
    }
  };

  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
      {portals.map((portal) => (
        <FlexItem key={portal.slug}>
          <Margin size={{ vertical: Size.EXTRA_SMALL }}>
            <Checkbox
              onChange={() => _toggleSelection(portal.slug)}
              value={
                Array.isArray(filter.value)
                  ? filter.values?.some((v) => v.toLowerCase() === portal.slug.toLowerCase())
                  : String(filter.value).toLowerCase() === portal.slug.toLowerCase()
              }>
              {portal.name}
            </Checkbox>
          </Margin>
        </FlexItem>
      ))}
    </Flex>
  );
};
