import {
  Flex,
  FlexItem,
  FlexSpacer,
  LoadingPlaceholder,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

export const EditorLoader = () => {
  return (
    <Flex>
      <FlexItem flex>
        <LoadingPlaceholder />
        <Margin size={{ vertical: Size.DEFAULT }}>
          <LoadingPlaceholder height={40} width="90%" />
        </Margin>
        <LoadingPlaceholder height={300} width="100%" />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_LARGE} />
      <FlexItem style={{ height: 400 }} flex>
        <LoadingPlaceholder height="100%" width="100%" />
      </FlexItem>
    </Flex>
  );
};
