import { Size, Text } from '@drawbotics/react-drylus';
import React from 'react';

import { CountBox } from '~/components';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.leads.components.leads_table_header');

interface LeadsCounterProps {
  ownedLeadCount: number;
  totalLeadCount: number;
}

export const LeadsCounter = ({ ownedLeadCount, totalLeadCount }: LeadsCounterProps) => {
  return (
    <CountBox icon="users">
      <Text size={Size.SMALL}>
        {ownedLeadCount} {tt('your_leads')}{' '}
      </Text>
      {tt('out_of', { total: totalLeadCount })}
    </CountBox>
  );
};
