import gql from 'fraql';
import { useMemo } from 'react';
import urlJoin from 'url-join';
import { CombinedError } from 'urql';

import { computedShowcaseData, shouldUseShowcaseData } from '~/pods/insights/showcase';
import { fetchFakeData } from '~/pods/insights/showcase/generate';
import { DATASETS } from '~/pods/insights/showcase/sets';
import {
  InsightsEstate,
  InsightsLead,
  InsightsSession,
  InsightsUnit,
  Project,
  ProjectFromServer,
} from '~/pods/insights/types';
import { ID, Id, TypologyFormat } from '~/types';
import { createTranslate } from '~/utils';
import { useBackofficeQuery, useMosaicNavigation, useMosaicQuery } from '~/utils/hooks';

import { useFetchData } from '../use-fetch-data';
import { transformProject, transformSessions } from './translators';
import { mergeIntoEstate } from './utils';

const tBedrooms = createTranslate('pods.insights.bedrooms');

interface EstateQueryVariables {
  projectToken: string;
}

interface ListingQueryVariables {
  publicToken: string;
}

interface EstateQueryData {
  estate: InsightsEstate;
}

interface ListingQueryData {
  listing: {
    units: Array<InsightsUnit>;
  };
}

const EstateQuery = gql`
  query InsightsEstateQuery($projectToken: String) {
    estate(estateSlug: $projectToken) {
      id
      name
      slug
      authToken
    }
  }
`;

const ListingQuery = gql`
  query InsightsListingQuery($publicToken: ID!) {
    listing(publicToken: $publicToken) {
      units {
        id
        name: reference
        availability
        soldAt
        bedrooms
        orientation
        price @computed(type: Unit)
      }
    }
  }
`;

const ProjectCoreQuery = gql`
  query ProjectCoreQuery($projectToken: String!) {
    project(slug: $projectToken) {
      id
      dealsGoalStartDate
      dealsGoalEndDate
      leads {
        id
        campaignToken
        createdAt @computed(type: Lead)
      }
      organisation {
        typologyFormat
      }
    }
  }
`;

function _buildError(
  projectError: CombinedError | undefined,
  estateError: CombinedError | undefined,
  sessionsError: { status: number | null },
) {
  const isNotFound =
    String(estateError?.response?.status).includes('40') ||
    String(projectError?.response?.status).includes('40');

  const isCritical =
    estateError?.response?.status != null ||
    projectError?.response?.status != null ||
    sessionsError?.status === 500;

  const error: { isCritical: boolean; isNotFound: boolean } = {
    isCritical: false,
    isNotFound: false,
  };
  error.isCritical = isCritical;
  error.isNotFound = isNotFound;

  return error;
}

function _getTypologyFormatted(bedrooms: number, isFrenchTypologyFormat: boolean) {
  if (isFrenchTypologyFormat) {
    return `T${bedrooms + 1}`;
  } else if (bedrooms === 0 || bedrooms === 1) {
    return tBedrooms(bedrooms.toString());
  } else {
    return tBedrooms('more', { count: bedrooms });
  }
}

function _getEstateWithTypology(
  estate: InsightsEstate,
  units: Array<InsightsUnit>,
  typologyFormat: TypologyFormat | undefined,
) {
  const isFrenchTypologyFormat = typologyFormat === TypologyFormat.FRENCH;
  return {
    ...estate,
    units:
      units.map((unit: any) => ({
        ...unit,
        typology: _getTypologyFormatted(unit.bedrooms, isFrenchTypologyFormat),
      })) || [],
  };
}

const sessionsCache: Record<Id, any> = {};

function useFetchSessions(
  projectToken: ID,
  rawEstate: InsightsEstate | undefined,
  options?: { pause?: boolean },
) {
  if (process.env.APP_ENV === 'demo') {
    const { sessions: showcaseSessionsData } = fetchFakeData();

    return {
      isLoadingSessions: false,
      sessions: showcaseSessionsData,
      sessionsError: null,
    };
  }

  const jakteUrl = urlJoin(process.env.ANALYTICS_HOST, '/api/v1/sessions', projectToken);
  const isSessionsCacheHit = sessionsCache[jakteUrl] != null;

  const [isLoadingSessions, sessions, sessionsError] = useFetchData(jakteUrl, {
    pause: rawEstate?.authToken == null || isSessionsCacheHit || options?.pause,
    config: {
      headers: {
        Authorization:
          process.env.NODE_ENV === 'development'
            ? (process.env.MAGIC_TOKEN as string)
            : `Token ${rawEstate?.authToken}`,
      },
    },
    transform: transformSessions,
  });

  if (!isLoadingSessions && sessions != null) {
    sessionsCache[projectToken] = sessions;
  }

  const finalSessions = isSessionsCacheHit ? sessionsCache[jakteUrl] : sessions;
  const finalIsLoading = isSessionsCacheHit ? false : isLoadingSessions;

  return { isLoadingSessions: finalIsLoading, sessions: finalSessions, sessionsError };
}

export interface UseLoadInsightsOutput {
  isLoading: boolean;
  error: { isCritical: boolean; isNotFound: boolean };
  estate?: InsightsEstate;
  project?: Project;
  leads?: Array<InsightsLead>;
  sessions?: Array<InsightsSession>;
  refetchProject: VoidFunction;
  typologyFormat?: TypologyFormat;
}

export function useLoadInsights(
  useGenerator: boolean,
  selectedSet: keyof typeof DATASETS,
): UseLoadInsightsOutput {
  const { projectToken } = useMosaicNavigation();

  if (projectToken == null) {
    throw new Error('No `projectToken` provided in the URL; How did you get here?');
  }

  if (shouldUseShowcaseData(projectToken)) {
    if (useGenerator) {
      return computedShowcaseData;
    } else {
      return DATASETS[selectedSet];
    }
  }
  const {
    isLoading: isLoadingProject,
    data: projectData,
    error: projectError,
    refetch: refetchProject,
  } = useMosaicQuery<{ project: ProjectFromServer }, { projectToken: string }>({
    query: ProjectCoreQuery,
    variables: { projectToken },
  });

  const project = projectData?.project;

  const estateResult = useBackofficeQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { projectToken },
  });

  const listingResult = useMosaicQuery<ListingQueryData, ListingQueryVariables>({
    query: ListingQuery,
    variables: { publicToken: projectToken },
  });

  const estate =
    estateResult.data && listingResult.data
      ? _getEstateWithTypology(
          estateResult.data.estate,
          listingResult.data.listing.units,
          project?.organisation.typologyFormat,
        )
      : undefined;

  const { isLoadingSessions, sessions, sessionsError } = useFetchSessions(projectToken, estate, {
    pause: projectToken !== estate?.slug,
  });

  const estateWithSessions = useMemo(
    () => mergeIntoEstate(estate, listingResult.data?.listing.units, sessions),
    [estate?.id, sessions],
  );

  const isLoading = isLoadingSessions || estateResult.isLoading || isLoadingProject;

  return {
    isLoading,
    estate: estateWithSessions,
    project: transformProject(project),
    error: _buildError(projectError, estateResult.error, sessionsError),
    sessions: sessions,
    leads: project?.leads ?? [],
    refetchProject,
    typologyFormat: project?.organisation?.typologyFormat,
  };
}
