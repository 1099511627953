import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexDirection,
  FlexItem,
  ListTile,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  PanelSection,
  Size,
  Text,
  Title,
  formatPrice,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment, ReactNode } from 'react';

import { PieChart } from '~/pods/insights/components';
import { Availability, InsightsEstate } from '~/pods/insights/types';
import { createTranslate } from '~/utils';

import { CustomLabelTooltip } from '../CustomLabelTooltip';

const tt = createTranslate('pods.insights.routes.deals');

const styles = {
  chartPercentage: css`
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100px;
    width: 100px;
    margin-left: -50px;
    margin-top: -50px;
  `,
};

interface GoalPanelLayoutProps {
  text: ReactNode;
  chart: ReactNode;
  title: string;
}

const GoalPanelLayout = ({ text, chart, title }: GoalPanelLayoutProps) => {
  return (
    <Panel
      style={{ height: '100%' }}
      body={
        <PanelBody style={{ height: '100%' }}>
          <PanelSection style={{ height: '100%' }} title={title}>
            <Margin size={{ vertical: Size.SMALL }}>{text}</Margin>
            <div style={{ width: '200px', height: '200px', margin: 'auto' }}>{chart}</div>
          </PanelSection>
        </PanelBody>
      }
    />
  );
};

const GoalPanelLoadingState = () => {
  return (
    <GoalPanelLayout
      title={tt('unit_goal')}
      text={
        <ListTile
          leading={<LoadingPlaceholder height={30} width={45} />}
          title={<LoadingPlaceholder height={20} width={80} />}
        />
      }
      chart={<LoadingPlaceholder width="100%" height="100%" />}
    />
  );
};

interface UnitGoalProps {
  units: InsightsEstate['units'];
}

const UnitGoal = ({ units }: UnitGoalProps) => {
  const soldAmount = units.filter((u) => u.availability === Availability.BOOKED).length;
  const optionedAmount = units.filter((u) => u.availability === Availability.OPTION).length;
  const availableAmount = units.filter((u) => u.availability === Availability.AVAILABLE).length;

  // Sum the sold, optioned and available units to exclude unavailable units
  const totalUnitAmount = soldAmount + optionedAmount + availableAmount;

  return (
    <GoalPanelLayout
      title={tt('unit_goal')}
      text={
        <ListTile
          leading={
            <Title noMargin size={3}>
              {totalUnitAmount}
            </Title>
          }
          title={tt('units_in_total')}
        />
      }
      chart={
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <div className={styles.chartPercentage}>
            <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <Title noMargin style={{ textAlign: 'center' }} size={1}>{`${Math.round(
                  (soldAmount / totalUnitAmount) * 100,
                )}%`}</Title>
              </FlexItem>
              <FlexItem style={{ textAlign: 'center' }}>
                <Text>{tt('units_sold')}</Text>
              </FlexItem>
            </Flex>
          </div>
          <PieChart
            data={[
              {
                id: 'sold',
                value: soldAmount,
                color: sv.blue,
                label: tt('unit_goal_label_booked'),
              },
              {
                id: 'option',
                value: optionedAmount,
                color: sv.blueLight,
                label: tt('unit_goal_label_option'),
              },
              {
                id: 'available',
                value: availableAmount,
                color: sv.neutralLighter,
                label: tt('unit_goal_label_available'),
              },
            ]}
            tooltip={({ value, label }) => {
              return (
                <CustomLabelTooltip>
                  <Fragment>
                    <Text inversed>{`${label}: `}</Text>
                    <Text inversed bold>
                      {`${value} ${tt('units', { count: value })}`}
                    </Text>
                  </Fragment>
                </CustomLabelTooltip>
              );
            }}
            theme={{
              tooltip: {
                container: {
                  backgroundColor: 'transparent',
                  boxShadow: '',
                },
              },
            }}
          />
        </div>
      }
    />
  );
};

interface RevenueGoalProps {
  units: InsightsEstate['units'];
}

const RevenueGoal = ({ units }: RevenueGoalProps) => {
  const soldAmount = units.reduce((memo, unit) => {
    return unit.availability === Availability.BOOKED ? memo + unit.price.value : memo;
  }, 0);

  const optionedAmount = units.reduce((memo, unit) => {
    return unit.availability === Availability.OPTION ? memo + unit.price.value : memo;
  }, 0);

  const availableAmount = units.reduce((memo, unit) => {
    return unit.availability === Availability.AVAILABLE ? memo + unit.price.value : memo;
  }, 0);

  // Sum the sold, optioned and available units to exclude unavailable units
  const totalAmount = soldAmount + optionedAmount + availableAmount;

  return (
    <GoalPanelLayout
      title={tt('revenue_goal')}
      text={
        <ListTile
          leading={
            <Title noMargin size={3}>
              {formatPrice({
                price: { value: totalAmount, currency: units[0].price.currency },
                options: { notation: 'compact' },
              })}
            </Title>
          }
          title={tt('overall_target')}
        />
      }
      chart={
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <div className={styles.chartPercentage}>
            <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <Title noMargin style={{ textAlign: 'center' }} size={1}>{`${Math.round(
                  (soldAmount / totalAmount) * 100,
                )}%`}</Title>
              </FlexItem>
              <FlexItem>
                <Text>{tt('completed')}</Text>
              </FlexItem>
            </Flex>
          </div>
          <PieChart
            data={[
              {
                id: 'sold',
                value: soldAmount,
                color: sv.blue,
                label: tt('revenue_goal_label_booked'),
              },
              {
                id: 'option',
                value: optionedAmount,
                color: sv.blueLight,
                label: tt('revenue_goal_label_option'),
              },
              {
                id: 'available',
                value: availableAmount,
                color: sv.neutralLighter,
                label: tt('revenue_goal_label_available'),
              },
            ]}
            tooltip={({ value, label }) => {
              return (
                <CustomLabelTooltip>
                  <Fragment>
                    <Text inversed>{`${label}: `}</Text>
                    <Text inversed bold>
                      {formatPrice({
                        price: { value, currency: units[0].price.currency },
                      })}
                    </Text>
                  </Fragment>
                </CustomLabelTooltip>
              );
            }}
            theme={{
              tooltip: {
                container: {
                  backgroundColor: 'transparent',
                  boxShadow: '',
                },
              },
            }}
          />
        </div>
      }
    />
  );
};

interface GoalPanelProps {
  isLoading?: boolean;
  units?: InsightsEstate['units'];
  metric?: 'units' | 'revenue';
}

export const UnitGoalPanel = ({ isLoading, units, metric }: GoalPanelProps) => {
  if (isLoading) {
    return <GoalPanelLoadingState />;
  }

  if (units == null) {
    console.error('Invariant violated: `units` is null even though loading has completed');
    return null;
  }

  if (metric === 'units') {
    return <UnitGoal units={units} />;
  } else {
    return <RevenueGoal units={units} />;
  }
};
