import { Margin, Panel, PanelProps, Size, Text } from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

interface PanelWithTitleProps extends PanelProps {
  title: string;
}

export const PanelWithTitle = ({ title, ...rest }: PanelWithTitleProps) => {
  return (
    <Fragment>
      <Margin size={{ top: Size.LARGE, bottom: Size.SMALL }}>
        <Text>{title}</Text>
      </Margin>
      <Panel {...rest} />
    </Fragment>
  );
};
