import {
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Panel,
  PanelBody,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React from 'react';

import { useIntercom } from '~/utils';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.insights.routes.dashboard');

interface PlaceholderCardProps {
  description: string;
  image: string;
}

export const PlaceholderCard = ({ description, image }: PlaceholderCardProps) => {
  const { showIntercom } = useIntercom();
  return (
    <Panel
      style={{ width: '100%', overflow: 'hidden' }}
      body={
        <PanelBody style={{ height: '245px' }}>
          <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <img src={image} />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Text shade={Shade.LIGHT}>{description}</Text>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Button onClick={showIntercom} size={Size.SMALL} category={Category.INFO}>
                {tt('contact_us')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};
