import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Project } from '../../types';

interface ProjectQueryData {
  project: Project;
}

interface ProjectFromCRMQueryData {
  project: {
    leadAggregationEmail: null | string;
  };
}

interface ProjectQueryVariables {
  projectSlug: string;
}

const ProjectQuery = gql`
  query ProjectQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      name
      slug
      emailCtaLinks {
        activated,
        category,
        label,
        linkType,
        ... on DownloadLink {
          attachment {
            id
            filename
            url
          }
        }
        ... on SchedulingLink {
          includeInterests
        }
        ... on UrlLink {
          url
        }
      }
    }
  }
`;

const ProjectFromCRMQuery = gql`
  query ProjectFromCRMQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      leadAggregationEmail
    }
  }
`;

// TODO watch out, this is used for insights
export function useFetchProject(projectSlug: string): {
  isLoading: boolean;
  project?: Project;
  error?: ErrorType;
} {
  const res = useMosaicQuery<ProjectQueryData, ProjectQueryVariables>({
    query: ProjectQuery,
    variables: { projectSlug },
  });

  return { isLoading: res.isLoading, project: res.data?.project, error: buildError(res.error) };
}

export function useFetchProjectFromCRM(projectSlug: string): {
  isLoading: boolean;
  project?: ProjectFromCRMQueryData;
  error?: ErrorType;
} {
  const res = useMosaicQuery<ProjectFromCRMQueryData, ProjectQueryVariables>({
    query: ProjectFromCRMQuery,
    variables: { projectSlug },
  });

  return { isLoading: res.isLoading, project: res.data, error: buildError(res.error) };
}
