import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  Position,
  Size,
  Spinner,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  control: css`
    position: absolute;
    top: -${sv.marginSmall};
    right: -${sv.marginLarge};
    height: calc(100% + ${sv.marginLarge});
    border-left: 2px solid ${sv.neutral};
    transform: translateX(100%);

    &::after,
    &::before {
      content: ' ';
      position: absolute;
      left: -8px;
      width: 8px;
      height: 2px;
      background: ${sv.neutral};
    }

    &::after {
      top: 0;
    }

    &::before {
      bottom: 0;
    }
  `,
};

interface HideShowControlProps {
  visible: boolean;
  onClick: VoidFunction;
  loading: boolean;
}

export const HideShowControl = ({ visible, onClick, loading }: HideShowControlProps) => {
  return (
    <div className={styles.control}>
      <Flex style={{ height: '100%' }}>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Tooltip content={visible ? tt('hide') : tt('show')} side={Position.RIGHT}>
            <Button
              onClick={onClick}
              leading={
                loading ? (
                  <Spinner size={Size.SMALL} />
                ) : (
                  <Icon name={visible ? 'eye-off' : 'eye'} />
                )
              }
            />
          </Tooltip>
        </FlexItem>
      </Flex>
    </div>
  );
};
