import { Content, Padding, Size } from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { uniq } from 'lodash';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';
import { useTriggerCriticalError, useTriggerNotFound } from '~/utils/hooks';

import { DEV_PANEL_DEFAULTS, DevPanel, DevPanelForm } from '../components';
import { useLoadInsights } from '../utils/hooks';
import { Dashboard } from './Dashboard';
import { Deals } from './Deals';
import { MarketingCampaigns } from './MarketingCampaigns';
import { ReportsIndex } from './ReportsIndex';
import { Traction } from './Traction';

export const Insights = () => {
  const triggerNotFound = useTriggerNotFound();
  const triggerCritical = useTriggerCriticalError();

  const devPanelForm = useForm<DevPanelForm>(DEV_PANEL_DEFAULTS);
  const { overrideLoading, useGenerator, selectedSet } = devPanelForm.values;

  const {
    isLoading: _isLoading,
    leads,
    sessions,
    estate,
    error,
    project,
    refetchProject,
    typologyFormat,
  } = useLoadInsights(Boolean(useGenerator), selectedSet!);

  const isLoading = _isLoading || Boolean(overrideLoading);

  if (error.isNotFound) {
    triggerNotFound();
    return null;
  } else if (error.isCritical) {
    triggerCritical();
    return null;
  }

  const availableTypologies = uniq(estate?.units.map((u) => u.typology)).sort();

  return (
    <Fragment>
      <Content fullHeight>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <RoutesWithNotFound>
            <Route
              index
              element={
                <Dashboard
                  isLoading={isLoading}
                  leads={leads}
                  sessions={sessions}
                  estate={estate}
                  project={project}
                />
              }
            />
            <Route
              path="deals"
              element={
                <Deals
                  isLoading={isLoading}
                  estate={estate}
                  availableTypologies={availableTypologies}
                  project={project}
                  refetchProject={refetchProject}
                  typologyFormat={typologyFormat}
                />
              }
            />
            <Route
              path="traction"
              element={
                <Traction
                  isLoading={isLoading}
                  units={sessions?.length === 0 || estate == null ? [] : estate.units}
                  project={project}
                  sessions={sessions}
                  leads={leads}
                  availableTypologies={availableTypologies}
                />
              }
            />
            <Route
              path="campaigns"
              element={<MarketingCampaigns project={project} leads={leads} sessions={sessions} />}
            />
            <Route path="reports" element={<ReportsIndex />} />
          </RoutesWithNotFound>
        </Padding>
      </Content>
      {['development', 'staging'].includes(process.env.APP_ENV) ? (
        <div style={{ position: 'absolute' }}>
          <DevPanel form={devPanelForm} />
        </div>
      ) : null}
    </Fragment>
  );
};
