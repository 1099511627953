import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Project } from '../../types/project';

interface ProjectsQueryData {
  organisation?: { projects: Array<Project> };
}

const ProjectsQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      projects {
        id
        name
        assignedAgencies {
          id
          name
        }
      }
    }
  }
`;

interface FetchProjects {
  isLoadingProjects: boolean;
  projects: Array<Project>;
  error?: ErrorType;
  refetchProjects: VoidFunction;
}

export function useLoadProjects(): FetchProjects {
  const res = useMosaicQuery<ProjectsQueryData, null>({
    query: ProjectsQuery,
  });

  return {
    isLoadingProjects: res.isLoading,
    projects: res.data?.organisation?.projects ?? [],
    error: buildError(res.error),
    refetchProjects: res.refetch,
  };
}
