import { Currency } from '~/types';

import { Step } from './organisation';
import { ProjectInterest } from './project';
import { User } from './user';

export enum EngagementLevelEnum {
  HOT = 'HOT',
  MEDIUM = 'MEDIUM',
  COLD = 'COLD',
}

export enum PurchaseGoal {
  LIVE = 'PLACE_TO_LIVE',
  INVEST = 'INVESTMENT',
  RENTAL = 'RENTAL',
  OTHER = 'OTHER',
}

export enum UnitFeature {
  TERRACE = 'TERRACE',
  GARDEN = 'GARDEN',
}

export enum LeadOrigin {
  AGENT = 'AGENT',
  SEARCH_ENGINE = 'SEARCH_ENGINE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  PRESS = 'PRESS',
  REFERRAL = 'WORD_OF_MOUTH',
  REAL_ESTATE_PLATFORM = 'REAL_ESTATE_PLATFORM',
  OTHER = 'OTHER',
}

export enum LeadSource {
  REVO = 'REVO',
  WEBSITE = 'WEBSITE',
  MANUAL = 'MANUAL',
  REACH = 'REACH',
  REAL_ESTATE_PLATFORM = 'REAL_ESTATE_PLATFORM',
}

export interface Address {
  city?: string;
  countryCode?: string;
  street?: string;
  zipCode?: string;
  countryName?: string;
  fullAddress: string;
}

//TODO change back unitReference, when backend is updated
export interface InterestType {
  projectId: string;
  unitInterests?: Array<{ unitId: string; unitReference?: string }>;
  destroy?: boolean;
}

export interface Lead {
  id: string;
  createdAt?: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  phoneNumber?: string;
  email?: string;
  address?: Address;
  typologies?: Array<number>;
  minimumBudget?: number;
  maximumBudget?: number;
  budgetCurrency?: Currency;
  minimumSurface?: number;
  maximumSurface?: number;
  surfaceUnit?: 'sqm' | 'sqft';
  unitFeatures?: Array<UnitFeature>;
  purchaseGoal?: PurchaseGoal;
  origin?: LeadOrigin;
  source?: LeadSource;
  step: Step;
  previousStep?: Step;
  user?: User;
  interestedIn?: Array<ProjectInterest>;
  updatedAt?: string;
  campaignToken?: string;
  engagementLevel?: EngagementLevelEnum;
  portalSlug?: string;
}
