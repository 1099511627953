import sv from '@drawbotics/drylus-style-vars';
import { Category, Padding, Size, Tile } from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsSession } from '~/pods/insights/types';
import { useLoadNumberOfVisitsOverRange } from '~/pods/insights/utils';
import { computeDeltaForRange } from '~/pods/insights/utils/compute-delta-for-range';
import { extractVisits } from '~/pods/insights/utils/extract-visits';
import { createTranslate } from '~/utils/translation';

import { Delta } from '../../../Dashboard/components/Delta';
import CircularChart from '../CircularChart';
import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.visits_chart');

interface VisitsChartProps {
  sessions: Array<InsightsSession>;
  dateRange: DateRange;
}

export const VisitsChart = ({ sessions, dateRange }: VisitsChartProps) => {
  const { visitsData } = useLoadNumberOfVisitsOverRange(sessions, dateRange);
  const delta = computeDeltaForRange(dateRange, extractVisits(sessions), 'timestamp');

  const totalVisits = visitsData.reduce((memo, point) => memo + point.y, 0);

  const isPositiveDelta = delta.startsWith('+');

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ top: Size.EXTRA_SMALL }}>
        <Text light style={{ fontSize: '22pt' }}>
          {totalVisits}
        </Text>
      </Padding>
      <div style={{ paddingBottom: 2 }}>
        <Delta
          iconName={isPositiveDelta ? 'arrow-up-right' : 'arrow-down-right'}
          category={isPositiveDelta ? Category.SUCCESS : Category.DANGER}
          text={t('delta', { delta })}
        />
      </div>
      <CircularChart color={sv.green} width="100%" height="28px" data={visitsData} />
    </Tile>
  );
};
