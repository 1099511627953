import {
  Button,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  Shade,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React, { useState } from 'react';
import { TextToClipboard } from '~/components';

import { useFetchProjectFromCRM } from '~/pods/project-settings/utils';
import { createTranslate } from '~/utils';

import { PortalsIndexModal } from './PortalsIndexModal';

const tt = createTranslate('pods.project_settings.project_settings');

interface LeadsAggregationPanelProps {
  projectSlug: string;
}

export const LeadsAggregationPanel = (props: LeadsAggregationPanelProps) => {
  const [showPortalsIndex, setShowPortalsIndex] = useState(false);

  const { isLoading, project } = useFetchProjectFromCRM(props.projectSlug);

  return (
    <Panel

      body={
        <PanelBody>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Text shade={Shade.MEDIUM}>{tt('lead_aggregation.description')}</Text>
          </Margin>
          <Flex justify={FlexJustify.START} align={FlexAlign.CENTER}>
            <FlexItem flex={1}>
              {isLoading ? (
                <LoadingPlaceholder />
              ) : (
                <TextToClipboard text={project?.project.leadAggregationEmail ?? ''} />
              )}
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>

              <Button
                tier={Tier.SECONDARY}
                onClick={() => {
                  setShowPortalsIndex(true);
                }}
                leading={<Icon name="list" />}>
                {tt('lead_aggregation.portals_index.title')}
              </Button>
            </FlexItem>
          </Flex>
          <PortalsIndexModal
            show={showPortalsIndex}
            onClickClose={() => setShowPortalsIndex(false)}
          />
        </PanelBody>
      }
    />
  );
};
