import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexDirection, FlexItem, FlexSpacer, Size } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { ControlButtons, ControlButtonsProps } from './ControlButtons';

const styles = {
  bracket: css`
    height: 100%;
    width: 15px;
    border: 2px solid ${sv.neutral};
    border-left: none;
    display: inline-block;
  `,
  horizontalBracket: css`
    height: 5px;
    border: 2px solid ${sv.neutral};
    border-bottom: none;
    margin: 0 ${sv.defaultMargin};
  `,
};

type ControlBracketProps = ControlButtonsProps;

export const ControlBracket = (props: ControlBracketProps) => {
  if (props.horizontal) {
    return (
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <ControlButtons {...props} />
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem style={{ width: '100%' }}>
          <div className={styles.horizontalBracket} />
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
      </Flex>
    );
  }

  return (
    <Flex style={{ height: '100%' }}>
      <FlexItem style={{ height: '100%' }}>
        <div className={styles.bracket} />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <ControlButtons {...props} />
      </FlexItem>
    </Flex>
  );
};
