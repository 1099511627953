import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CircularProgress,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  Icon,
  Margin,
  Size,
  Spinner,
  UploadBox,
  useAlert,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import { differenceBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { Attachment } from '~/types';
import {
  UploadedFile,
  createTranslate,
  isAttachment,
  translate as t,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { AssetChange } from './CategoryEditor';

const tt = createTranslate('pods.presentation.routes.presentation.components.gallery');

const styles = {
  thumbnail: css`
    position: relative;
    height: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > img {
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  `,
  delete: css`
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
  `,
};

interface GalleryProps {
  images: Array<Attachment>;
  editMode?: boolean;
  saving?: boolean;
  onClickSave: (changes: Array<AssetChange>) => void;
  onClickCancel: VoidFunction;
}

export const Gallery = ({ images, editMode, onClickSave, onClickCancel, saving }: GalleryProps) => {
  const [files, setFiles] = useState<Array<UploadedFile | Attachment>>(images);
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);
  const { showAlert } = useAlert();

  const handleUpload = async (fileList: FileList) => {
    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    setFiles([...files, ...validFiles]);
  };

  const handleRemoveFile = (file: Attachment | UploadedFile) => {
    if (isAttachment(file)) {
      setFiles(files.filter((f) => (isAttachment(f) ? f.id !== file.id : true)));
    } else {
      setFiles(
        files.filter((f) =>
          isAttachment(f) ? true : f.originalFile.name !== file.originalFile.name,
        ),
      );
    }
  };

  const handleSaveChanges = () => {
    const newFiles = files.filter((file) => !isAttachment(file)) as Array<UploadedFile>;
    const oldFiles = files.filter((file) => isAttachment(file)) as Array<Attachment>;
    const deletedFiles = differenceBy(images, oldFiles, 'id') as Array<Attachment>;
    const changes = [
      ...newFiles.map((f) => ({ signedBlobId: f.signedBlobId })),
      ...deletedFiles.map((f) => ({ assetId: f.id, destroy: true })),
    ];
    onClickSave(changes);
  };

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: t('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  useEffect(() => {
    setFiles(images);
  }, [images.map((i) => i.id).join('')]);

  return (
    <Fragment>
      <Grid hGutters={Size.EXTRA_SMALL} vGutters={Size.EXTRA_SMALL} columns={3}>
        {files.map((attachment) => (
          <GridItem key={isAttachment(attachment) ? attachment.id : attachment.originalFile.name}>
            <div className={styles.thumbnail}>
              {editMode ? (
                <div className={styles.delete}>
                  <Button
                    onClick={() => handleRemoveFile(attachment)}
                    category={Category.DANGER}
                    size={Size.SMALL}
                    style={{ borderRadius: 0 }}
                    leading={<Icon name="x" />}
                  />
                </div>
              ) : null}
              <img src={attachment.url} />
            </div>
          </GridItem>
        ))}
        {isFileUploading
          ? info.map((fileUpload) => (
              <GridItem key={fileUpload.filename}>
                <div className={styles.thumbnail}>
                  <CircularProgress
                    size={Size.SMALL}
                    style={{ zIndex: 2 }}
                    percentage={fileUpload.progress ?? 0}
                    color={Color.GREEN}
                  />
                  <img style={{ opacity: 0.5 }} src={fileUpload.url} />
                </div>
              </GridItem>
            ))
          : null}
        {editMode ? (
          <GridItem>
            <UploadBox
              style={{ padding: sv.paddingExtraSmall, height: '100%' }}
              label={tt('drag_and_drop')}
              onUploadFiles={handleUpload}
              multiple
              allowedFileFormats=".png,.jpg"
            />
          </GridItem>
        ) : null}
      </Grid>
      {editMode ? (
        <Margin size={{ top: Size.DEFAULT }}>
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button
                size={Size.SMALL}
                onClick={() => {
                  setFiles(images);
                  onClickCancel();
                }}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                disabled={isFileUploading || saving}
                trailing={saving ? <Spinner inversed size={Size.SMALL} /> : null}
                size={Size.SMALL}
                onClick={handleSaveChanges}
                category={Category.INFO}>
                {tt('save_changes')}
              </Button>
            </FlexItem>
          </Flex>
        </Margin>
      ) : null}
    </Fragment>
  );
};
