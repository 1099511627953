import { APP_CONTEXT, AppContext, AuthModule, TargetDomain } from '@drawbotics/auth';
import { Color, Layout, Position } from '@drawbotics/react-drylus';
import { TokamakApp } from '@tokamakjs/react';
import { css, cx } from 'emotion';
import React, { Fragment, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  AuthRoutesWrapper,
  Authenticated,
  ContextSidebar,
  ImpersonationRoute,
  Navbar,
  RoutesWithNotFound,
} from './components';
import { AgencyRoute } from './pods/agency';
import { CRMRoute } from './pods/crm';
import { DashboardRoute } from './pods/dashboard';
import { DevRoute } from './pods/dev';
import { FiscalSimulatorClient, FiscalSimulatorRoute } from './pods/fiscal-simulator';
import { InsightsReportsRoute, InsightsRoute } from './pods/insights';
import { ListingView } from './pods/listing-manager/routes/listing/listing.view';
import { DemodeskLoginRoute, MeetingsRoute } from './pods/meetings';
import { MultidiffusionRoute } from './pods/multidiffusion';
import { PresentationRoute } from './pods/presentation';
import { ProductStatsRoute } from './pods/product-stats';
import { ProfileRoute } from './pods/profile';
import { ProjectSettingsRoute } from './pods/project-settings';
import { SettingsRoute } from './pods/settings';
import { WebsiteBuilderRoute } from './pods/website-builder';
import { EditView } from './pods/website-builder/routes/edit';
import { useAuth, useMosaicNavigation } from './utils';

const styles = {
  navbar: css`
    > [data-element='layout-bar'] {
      position: relative;
      z-index: 1;
    }
    > [data-element='layout-content'] {
      overflow: initial;
    }
  `,
};

const AuthRoutes = () => {
  useEffect(() => {
    (async () => {
      const context: AppContext = {
        color: Color.BLUE,
        targetDomain: TargetDomain.MOSAIC,
        env: {
          AUTH_HOST: process.env.AUTH_HOST,
          GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
          AZURE_CLIENT_ID: process.env.AZURE_CLIENT_ID,
        },
        onFinish: () => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const next = urlSearchParams.get('next');
          window.location.href = decodeURIComponent(next ?? '/');
        },
      };
      const app = await TokamakApp.create(AuthModule, {
        basePath: '/auth',
        globalProviders: [{ provide: APP_CONTEXT, useValue: context }],
      });

      app.render('#auth-routes-root', context);
    })();
  }, []);

  return <div id="auth-routes-root" />;
};

const OrgRoutes = () => {
  const { organisationToken } = useMosaicNavigation();
  const { user } = useAuth();

  const isAgency = user?.organisation?.isAgency;
  const isNormalOrg = !isAgency;

  if (user == null) return null;

  return (
    <Layout
      style={{ height: '100vh' }}
      bar={<ContextSidebar />}
      position={Position.LEFT}
      fixed
      barScrollable={false}
      className={cx(styles.navbar)}>
      <RoutesWithNotFound>
        <Route
          index
          // Conventional organisations are sent to the dashboard; Agencies are sent to
          // the CRM as their default landing page
          element={
            isNormalOrg ? (
              <DashboardRoute />
            ) : (
              <Navigate to={`/organisation/${organisationToken}/crm`} />
            )
          }
        />
        <Route path="crm/*" element={<CRMRoute />} />
        <Route path="meetings/*" element={<MeetingsRoute />} />
        {isNormalOrg && (
          <Fragment>
            <Route path="website" element={<WebsiteBuilderRoute />} />
            <Route path="settings/*" element={<SettingsRoute />} />
            <Route path="stats" element={<ProductStatsRoute />} />
          </Fragment>
        )}
      </RoutesWithNotFound>
    </Layout>
  );
};

const ProjectRoutes = () => {
  const { getUrlInProject } = useMosaicNavigation();
  const { user } = useAuth();

  const isAgency = user?.organisation?.isAgency;
  const isNormalOrg = !isAgency;

  if (user == null) return null;

  return (
    <Layout
      style={{ height: '100vh' }}
      bar={<ContextSidebar />}
      position={Position.LEFT}
      fixed
      barScrollable={false}
      className={cx(styles.navbar)}>
      <Routes>
        <Route
          index
          element={isNormalOrg ? <InsightsRoute /> : <Navigate to={getUrlInProject('/listing')} />}
        />
        <Route path="listing" element={<ListingView />} />
        {isNormalOrg && (
          <Fragment>
            <Route path="crm/*" element={<CRMRoute />} />
            <Route path="website/*" element={<WebsiteBuilderRoute />} />
            <Route path="presentation" element={<PresentationRoute />} />
            <Route path="analytics/*" element={<InsightsRoute />} />
            <Route path="settings/*" element={<ProjectSettingsRoute />} />
            <Route path="fiscal-simulator/*" element={<FiscalSimulatorRoute />} />
            <Route path="multidiffusion/*" element={<MultidiffusionRoute />} />
          </Fragment>
        )}
      </Routes>
    </Layout>
  );
};

const AuthenticatedRoutes = () => {
  const { user } = useAuth();
  const { getHomepageUrl } = useMosaicNavigation();

  if (user == null) return null;

  const isAgency = user?.organisation?.isAgency;
  const isNormalOrg = !isAgency;

  const baseRedirectUrl = (() => {
    if (user == null) return '/auth/login';
    else return getHomepageUrl();
  })();

  return (
    <RoutesWithNotFound>
      {/* Mounted here because though it is part of the website builder mounted below, it should
      not be wrapped by the navbar and context bar like the rest of the app. */}
      {isNormalOrg && (
        <Route
          path="organisation/:organisationToken/project/:projectSlug/website/edit"
          element={<EditView />}
        />
      )}

      {process.env.APP_ENV === 'development' && <Route path="dev" element={<DevRoute />} />}

      <Route
        path="*"
        element={
          <Layout bar={<Navbar />} position={Position.LEFT} barScrollable={false} fixed>
            <RoutesWithNotFound>
              <Route index element={<Navigate to={baseRedirectUrl} />} />

              {/* Legacy route redirect */}
              <Route path="/projects" element={<Navigate to={baseRedirectUrl} />} />

              {/* Agency-specific route */}
              {isAgency && <Route path="agency/*" element={<AgencyRoute />} />}

              {/* Profile Route  */}
              <Route path="profile" element={<ProfileRoute />} />

              {/* Organisation context routes */}
              <Route path="organisation/:organisationToken/*" element={<OrgRoutes />} />

              {/* Project context routes */}
              <Route
                path="organisation/:organisationToken/project/:projectSlug/*"
                element={<ProjectRoutes />}
              />
            </RoutesWithNotFound>
          </Layout>
        }
      />
    </RoutesWithNotFound>
  );
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/auth/*"
        element={
          <AuthRoutesWrapper>
            <AuthRoutes />
          </AuthRoutesWrapper>
        }
      />
      <Route
        path="*"
        element={
          <Authenticated>
            <AuthenticatedRoutes />
          </Authenticated>
        }
      />

      {/* Special route, only visited by a lambda function that generates a PDF from it */}
      <Route path="/insights-reports/*" element={<InsightsReportsRoute />} />

      {/* Utility route, accessed from the backoffice to swap the JWT, then redirects to root */}
      <Route path="/impersonation" element={<ImpersonationRoute />} />

      {/* Gateway route to demodesk meeting environment. Performs DD login on our side, then redirects to demodesk */}
      <Route path="/meet/:token" element={<DemodeskLoginRoute />} />

      {/* Mounted here because provided as a publicly shareable free service */}
      <Route path="/cost-simulator/:token" element={<FiscalSimulatorClient />} />
    </Routes>
  );
};
