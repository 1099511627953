import {
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  NumberInputProps,
  Padding,
  RangeInput,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React from 'react';

import { Range } from '../types';

interface EnhancedRangeInputProps {
  isLoading?: boolean;
  min: number;
  max: number;
  onChange: (values: Range) => void;
  values: Range;
  step: number;
  renderValue: NumberInputProps['renderValue'];
  name?: string;
}

export const EnhancedRangeInput = ({
  isLoading,
  min,
  max,
  onChange,
  values,
  step,
  renderValue,
  name,
}: EnhancedRangeInputProps) => {
  return (
    <Padding size={{ vertical: Size.EXTRA_SMALL }}>
      <Margin size={{ bottom: Size.SMALL }}>
        <RangeInput
          name={name}
          hideLabels
          min={min}
          max={max}
          onChange={onChange}
          step={step}
          value={values}
          renderValue={renderValue}
        />
      </Margin>
      <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ whiteSpace: 'nowrap' }}>
        <FlexItem>
          <Text size={Size.SMALL} shade={Shade.LIGHT}>
            Min:&nbsp;
          </Text>
          <Text size={Size.SMALL}>{isLoading ? '...' : renderValue?.(values[0])}</Text>
        </FlexItem>
        <FlexItem>
          <Text size={Size.SMALL} shade={Shade.LIGHT}>
            Max:&nbsp;
          </Text>
          <Text size={Size.SMALL}>{isLoading ? '...' : renderValue?.(values[1])}</Text>
        </FlexItem>
      </Flex>
    </Padding>
  );
};
