import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.insights');

const HorizontalBarChartPlaceholder = () => {
  return (
    <div style={{ height: '274px' }}>
      <Padding size={Size.SMALL}>
        <Margin size={{ vertical: Size.SMALL, horizontal: Size.SMALL }}>
          <LoadingPlaceholder width={120} height={21} />
        </Margin>
        <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
          <FlexItem>
            <LoadingPlaceholder width={170} height={30} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <LoadingPlaceholder width={150} height={30} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <LoadingPlaceholder width={120} height={30} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <LoadingPlaceholder width={80} height={30} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <LoadingPlaceholder width={40} height={30} />
          </FlexItem>
        </Flex>
      </Padding>
    </div>
  );
};

const BannerFiguresPlaceholder = () => {
  return (
    <Flex direction={FlexDirection.VERTICAL}>
      <FlexItem>
        <LoadingPlaceholder width={210} height={21} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={210} height={64} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={210} height={21} />
      </FlexItem>
    </Flex>
  );
};

export const TractionPlaceholder = () => {
  return (
    <Fragment>
      <Flex justify={FlexJustify.START}>
        <FlexItem flex>
          <Title size={2}>{tt('routes.traction.traction')}</Title>
        </FlexItem>
      </Flex>
      <Margin size={{ vertical: Size.SMALL }}>
        <Panel
          body={
            <PanelBody>
              <Grid columns={4}>
                <GridItem>
                  <BannerFiguresPlaceholder />
                </GridItem>
                <GridItem>
                  <BannerFiguresPlaceholder />
                </GridItem>
                <GridItem>
                  <BannerFiguresPlaceholder />
                </GridItem>
                <GridItem>
                  <BannerFiguresPlaceholder />
                </GridItem>
              </Grid>
            </PanelBody>
          }
        />
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Panel
          body={
            <PanelBody>
              <LineChartPlaceholder height={460} message={tt('loading')} />
            </PanelBody>
          }
        />
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={4} vGutters={Size.SMALL}>
          <GridItem span={2}>
            <Panel
              body={
                <PanelBody>
                  <LineChartPlaceholder height={232} message={tt('loading')} />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
        </Grid>
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={2} vGutters={Size.SMALL}>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
        </Grid>
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={3} vGutters={Size.SMALL}>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalBarChartPlaceholder />
                </PanelBody>
              }
            />
          </GridItem>
        </Grid>
      </Margin>
    </Fragment>
  );
};
