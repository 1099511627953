import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  Input,
  LinkUnderlined,
  ListTile,
  Margin,
  Size,
  Spinner,
  TextLink,
  Tier,
} from '@drawbotics/react-drylus';
import { differenceBy, uniqBy } from 'lodash';
import React, { Fragment, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Asset } from '~/pods/presentation/types';
import { isValidLink } from '~/pods/presentation/utils';
import { createTranslate } from '~/utils';

import { AssetChange } from './CategoryEditor';

const tt = createTranslate('pods.presentation.routes.presentation.components.links');

interface LinkItem {
  id: string;
  url: string;
  invalid?: boolean;
}

interface LinksProps {
  urls: Array<Asset>;
  editMode?: boolean;
  onClickSave: (links: Array<AssetChange>) => void;
  onClickCancel: VoidFunction;
  saving?: boolean;
}

export const Links = ({ urls, editMode, onClickSave, onClickCancel, saving }: LinksProps) => {
  const [links, setLinks] = useState<Array<LinkItem>>(
    urls.length === 0 ? [{ url: '', id: uuidv4() }] : urls,
  );

  const handleOnChange = (id: string, _url: string) => {
    const url = _url.trim();
    const newLink = links.find((link) => link.id === id);
    if (newLink != null) {
      const wasLinkInvalid = newLink.invalid;
      setLinks(
        links.map((link) =>
          link.id === id ? { id, url, invalid: wasLinkInvalid ? !isValidLink(url) : false } : link,
        ),
      );
    }
  };

  const handleClickSave = () => {
    const linksWithValidation = links.map((link) => ({
      ...link,
      invalid: !isValidLink(link.url),
    }));

    if (linksWithValidation.some((link) => link.invalid)) {
      setLinks(linksWithValidation);
    } else {
      const linksToDelete = differenceBy(urls, links, 'id').filter((link) =>
        urls.find((url) => url.id === link.id),
      );
      onClickSave(
        uniqBy(
          [
            ...linksToDelete.map((link) => ({ url: link.url, assetId: link.id, destroy: true })),
            ...links.map((link) => ({ url: link.url, assetId: link.id })),
          ],
          'assetId',
        ),
      );
    }
  };

  return (
    <Fragment>
      {links.map(({ url, id, invalid }, i) => (
        <Fragment key={id}>
          {i !== 0 ? <Margin size={{ bottom: Size.SMALL }} /> : null}
          {editMode ? (
            <Input
              error={invalid ? tt('invalid_url') : undefined}
              value={url}
              placeholder="e.g. https://www..."
              onChange={(v) => handleOnChange(id, v as string)}
              trailing={
                i !== 0 ? (
                  <Button
                    category={Category.DANGER}
                    leading={<Icon name="x" />}
                    onClick={() => setLinks(links.filter((link) => link.id !== id))}
                  />
                ) : undefined
              }
            />
          ) : (
            <ListTile
              title={
                <a href={url} target="_blank" rel="noreferrer noopener">
                  <TextLink style={{ wordBreak: 'break-all' }} underlined={LinkUnderlined.HOVER}>
                    {url}
                  </TextLink>
                </a>
              }
              leading={<Icon name="link" />}
            />
          )}
        </Fragment>
      ))}
      {editMode ? (
        <Margin size={{ top: Size.DEFAULT }}>
          <Flex>
            <FlexItem flex>
              <Button
                onClick={() => setLinks([...links, { url: '', id: uuidv4() }])}
                size={Size.SMALL}
                tier={Tier.SECONDARY}>
                {tt('add_link')}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button
                size={Size.SMALL}
                onClick={() => {
                  setLinks(urls.length === 0 ? [{ url: '', id: uuidv4() }] : urls);
                  onClickCancel();
                }}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                disabled={saving}
                trailing={saving ? <Spinner inversed size={Size.SMALL} /> : null}
                size={Size.SMALL}
                onClick={handleClickSave}
                category={Category.INFO}>
                {tt('save_changes')}
              </Button>
            </FlexItem>
          </Flex>
        </Margin>
      ) : null}
    </Fragment>
  );
};
