import sv from '@drawbotics/drylus-style-vars';
import {
  Dot,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  ListTile,
  Margin,
  Padding,
  PanelBody,
  ShowDateTime,
  Size,
  Text,
  Tier,
  formatDate,
} from '@drawbotics/react-drylus';
import { Point, ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import dayjs from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { Timescale, timescaleToDays } from '../../../../constants';
import { DateRange, InsightsSession } from '../../../../types';
import { LineTheme, getDataInRange } from '../../../../utils';
import { PanelWithTitle } from '../PanelWithTitle';

const tt = createTranslate('pods.insights.routes.dashboard');

function _getBottomTickFrequencyAndFormat(days: number): { values: string; format: string } {
  if (days <= 7) return { values: 'every day', format: '%a %d' };
  else if (days > 7 && days <= 31) return { values: 'every 2 days', format: '%b %d' };
  else if (days > 31 && days <= 92) return { values: 'every 2 weeks', format: '%b %d' };
  else return { values: 'every month', format: '%b %y' };
}

const styles = {
  tooltipBox: css`
    background: ${sv.white};
    border-radius: 2px;
    box-shadow: ${sv.elevation3};
  `,
  tooltipLegend: css`
    width: 18px;
    height: 4px;
    border-radius: 100px;
    margin-right: ${sv.marginExtraSmall};
    display: block;
    align-content: center;
  `,
};

interface NivoSliceTooltipProps {
  input: SliceTooltipProps['slice'];
  timeScale: string;
}

const NivoSliceTooltip = ({ input, timeScale }: NivoSliceTooltipProps) => {
  return (
    <div className={styles.tooltipBox}>
      {input.points.map((point: Point) => {
        const finalDate =
          point.serieId === 'Current period'
            ? point.data.x
            : dayjs(point.data.xFormatted).subtract(timescaleToDays[timeScale], 'day').toDate();

        return (
          <Padding size={{ horizontal: Size.SMALL, vertical: Size.EXTRA_SMALL }} key={point.id}>
            <Flex justify={FlexJustify.START}>
              <FlexItem>
                <div
                  className={styles.tooltipLegend}
                  style={{ backgroundColor: point.serieColor }}
                />
              </FlexItem>
              <FlexItem>
                <Text>
                  {formatDate({
                    date: new Date(finalDate),
                    options: { showTime: ShowDateTime.NEVER, format: 'MMM DD' },
                  })}{' '}
                  :
                </Text>
              </FlexItem>
              <FlexItem>
                <Text bold style={{ margin: '0px 5px' }}>
                  {point.data.y} visits
                </Text>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
            </Flex>
          </Padding>
        );
      })}
    </div>
  );
};

interface VisitsPerDayCardProps {
  sessions: Array<InsightsSession>;
  range: DateRange;
  daysAmount: number;
  timescale: string;
}
export const VisitsPerDayCard = ({
  sessions,
  range,
  daysAmount,
  timescale,
}: VisitsPerDayCardProps) => {
  const showPrevPeriod = timescale !== Timescale.ALL_TIME;
  const sessionsData = getDataInRange(range, sessions, 'createdAt');

  const prevRange = showPrevPeriod
    ? {
        start: range.start.subtract(daysAmount + 1, 'day'),
        end: range.start.subtract(1, 'day'),
      }
    : null;

  const prevSessionsData = showPrevPeriod
    ? getDataInRange(prevRange!, sessions, 'createdAt')
    : null;

  const prevSessionsDataTranslated = showPrevPeriod
    ? sessionsData.map((p, i) => ({
        x: p.x,
        y: (prevSessionsData![i] ?? { y: 0 }).y,
      }))
    : null;

  const { values: bottomTickValues, format: bottomTickFormat } = _getBottomTickFrequencyAndFormat(
    daysAmount,
  );

  return (
    <PanelWithTitle
      title={tt('how_much_traction')}
      style={{ width: '100%', height: '340px' }}
      body={
        <PanelBody style={{ height: '100%' }}>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <Label>{tt('visits_per_day')}</Label>
              </FlexItem>
              <FlexItem>
                {showPrevPeriod ? (
                  <ListTile
                    leading={<Dot color={sv.blueLighter} />}
                    title={<Text tier={Tier.SECONDARY}>{tt('prev_period')}</Text>}
                  />
                ) : null}
                <Padding size={{ left: Size.SMALL }} style={{ display: 'inline-block' }}>
                  <ListTile
                    leading={<Dot color={sv.blue} />}
                    title={<Text tier={Tier.SECONDARY}>{tt('current_period')}</Text>}
                  />
                </Padding>
              </FlexItem>
            </Flex>
          </Margin>
          <div style={{ width: '100%', height: `calc(100% - ${sv.marginLarge})` }}>
            <ResponsiveLine
              margin={{ top: 25, left: 25, bottom: 25, right: 25 }}
              curve="monotoneX"
              xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
              enableSlices="x"
              sliceTooltip={(data) => {
                return <NivoSliceTooltip input={data.slice} timeScale={timescale} />;
              }}
              axisBottom={{
                format: bottomTickFormat,
                tickValues: bottomTickValues,
              }}
              colors={{ datum: 'color' }}
              enablePoints={false}
              theme={LineTheme}
              enableGridX={false}
              isInteractive={true}
              data={[
                ...(prevSessionsDataTranslated != null
                  ? [
                      {
                        label: tt('prev_period'),
                        color: sv.blueLight,
                        id: tt('prev_period'),
                        data: prevSessionsDataTranslated,
                      },
                    ]
                  : []),
                {
                  label: tt('current_period'),
                  color: sv.blue,
                  id: tt('current_period'),
                  data: sessionsData,
                },
              ]}
            />
          </div>
        </PanelBody>
      }
    />
  );
};
