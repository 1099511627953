import React from 'react';

import { InsightsSession } from '~/pods/insights/types';
import { generateBarChartData } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { BarSizes, HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction');

interface BrowserChartProps {
  sessions: Array<InsightsSession>;
}

export const BrowserChart = ({ sessions }: BrowserChartProps) => {
  const filtereData = sessions.filter(
    (s) => s.userAgent != null && s.userAgent.browser.name !== '',
  );
  const browserData = generateBarChartData(
    filtereData.map((s) => s.userAgent?.browser ?? {}),
    'name',
  );

  return (
    <HorizontalTractionBarChart
      label={tt('browsers')}
      data={browserData}
      barSize={BarSizes.THIRD}
    />
  );
};
