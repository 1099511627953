import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexJustify, Margin, Size } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { useLoadStatusBreakdownByTypology } from '~/pods/insights/utils';
import { createTranslate } from '~/utils/translation';

import { PieChart } from '../../../../components/PieChart';
import { LineLabel } from '../';
import { Text } from '../Text';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts');

const styles = {
  legend: css`
    position: absolute;
    top: 20px;
    right: 20px;
  `,
};

interface StatusByTypologyProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const StatusByTypology = ({ units, dateRange }: StatusByTypologyProps) => {
  const breakdownForChart = useLoadStatusBreakdownByTypology(units, dateRange.end);

  return (
    <ChartTile>
      <ChartTitle>{t('status_by_typology.title')}</ChartTitle>
      <div className={styles.legend}>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <LineLabel circular color={sv.green} label={t('sold')} />
          </FlexItem>
          <FlexItem>
            <LineLabel circular color={sv.blue} label={t('option')} />
          </FlexItem>
          <FlexItem>
            <LineLabel circular color={sv.neutral} label={t('for_sale')} />
          </FlexItem>
        </Flex>
      </div>
      <Flex justify={FlexJustify.SPACE_AROUND}>
        {Object.keys(breakdownForChart)
          .sort()
          .map((key) => {
            return (
              <FlexItem key={key}>
                <Margin size={{ top: Size.EXTRA_SMALL }}>
                  <Flex>
                    <FlexItem>
                      <Text style={{ width: '100%', textAlign: 'center' }} light>
                        {key === 'other' ? t('other') : key}
                      </Text>
                    </FlexItem>
                  </Flex>
                </Margin>
                <div style={{ width: '110px', height: '110px' }}>
                  <PieChart
                    data={breakdownForChart[key]}
                    enableLabels
                    isInteractive={false}
                    margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                  />
                </div>
              </FlexItem>
            );
          })}
      </Flex>
    </ChartTile>
  );
};
