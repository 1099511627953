import sv from '@drawbotics/drylus-style-vars';
import { css } from 'emotion';
import React from 'react';

import { BaseFooter, BaseFooterProps } from '../BaseFooter';

const styles = {
  footer: css`
    background-color: ${sv.white};
  `,
};

interface FooterProps extends BaseFooterProps {}

export const Footer = (props: FooterProps) => {
  return (
    <div className={styles.footer}>
      <BaseFooter {...props} />
    </div>
  );
};
