import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Separator,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import logo from '~/images/mosaic-color.svg';

import { LangSwitcher } from './LangSwitcher';
import { NAVBAR_WIDTH } from './Navbar';

const styles = {
  navbar: css`
    width: ${NAVBAR_WIDTH}px;
    height: 100%;
    background: ${sv.neutralDarkest};
    padding-bottom: ${sv.defaultPadding};
  `,
};

export const BasicNavbar = () => {
  return (
    <div className={styles.navbar}>
      <Flex direction={FlexDirection.VERTICAL} style={{ height: '100%' }}>
        <FlexItem style={{ height: NAVBAR_WIDTH, width: '100%' }}>
          <a href="/">
            <Flex style={{ height: '100%' }}>
              <FlexItem>
                <img src={logo} height="35" />
              </FlexItem>
            </Flex>
          </a>
        </FlexItem>
        <FlexItem>
          <Separator style={{ width: `${NAVBAR_WIDTH}px`, opacity: 0.3 }} />
        </FlexItem>
        <FlexSpacer flex />
        <FlexItem>
          <LangSwitcher reloadOnChange />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
      </Flex>
    </div>
  );
};
