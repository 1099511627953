import * as sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexItem,
  Modal,
  Padding,
  Size,
  Text,
  FlexJustify,
  Margin,
  Button,
  Category
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';
import portalsIndexIllustration from '../../../images/portals-index.svg'

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.project_settings.project_settings');

const styles = {
  root: css`
    div[class*='Modal__content'] {
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -24px;
      margin-bottom: -24px;
    }
  `,
  evenRow: css`
    background-color: ${sv.neutralLighter}
  `
};

interface PortalsIndexModalProps {
  show: boolean;
  onClickClose: () => void;
}

export const PortalsIndexModal = (props: PortalsIndexModalProps) => {
  return (
    <Modal
      title={tt('lead_aggregation.portals_index.title')}
      visible={props.show}
      onClickClose={props.onClickClose}
      className={styles.root}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button
              category={Category.INFO}
              onClick={props.onClickClose}
            >{tt('lead_aggregation.portals_index.close')}</Button>
          </FlexItem>
        </Flex>
      }
    >

      <Margin size={{ top: Size.DEFAULT, horizontal: Size.DEFAULT }}>

        <Flex justify={FlexJustify.CENTER}>
          <FlexItem>

            <img src={portalsIndexIllustration} height="112px" />
          </FlexItem>
        </Flex>
      </Margin>

      <Margin size={{ top: Size.SMALL, horizontal: Size.DEFAULT }}>

        <Text>
          {tt('lead_aggregation.portals_index.description')}
        </Text>
      </Margin>

      <Margin size={{ top: Size.DEFAULT }}>


        {([
          'Se Loger',
          'AtHome.lu'
        ]).map((portal, i) => {
          return (
            <Padding
              key={portal}
              className={(i % 2) === 0 ? styles.evenRow : undefined}
              size={{ vertical: Size.SMALL, horizontal: Size.DEFAULT }}>
              <Text>{portal}</Text>
            </Padding>
          );
        })}
      </Margin>
    </Modal>
  );
};
