import sv from '@drawbotics/drylus-style-vars';
import { Theme } from '@nivo/core';

export const LineTheme: Theme = {
  axis: {
    ticks: {
      line: {
        stroke: sv.colorTertiary,
      },
      text: {
        fontSize: '7pt',
        fontWeight: 'lighter',
        fill: sv.colorTertiary,
      },
    },
  },
  grid: {
    line: {
      strokeDasharray: '1 2',
    },
  },
};

export const BarTheme: Theme = LineTheme;
