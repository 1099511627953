import {
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Padding,
  ProgressBar,
  Size,
  Tile,
  formatPrice,
} from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { createTranslate } from '~/utils/translation';

import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.total_revenue');

interface TotalRevenueProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
  currency: string;
}

export const TotalRevenue = ({ units, dateRange, currency }: TotalRevenueProps) => {
  const potentialRevenue = units.reduce((memo, u) => u.price.value + memo, 0);
  const realRevenue = units
    .filter((u) => u.availability === 'booked' && u.soldAt?.isSameOrBefore(dateRange.end))
    .reduce((memo, u) => u.price.value + memo, 0);
  const percentage = realRevenue / potentialRevenue;

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{t('title')}</ChartTitle>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.EXTRA_SMALL }}>
            <Text light style={{ fontSize: '24pt' }}>
              {formatPrice({
                price: { value: realRevenue, currency },
                options: { notation: 'compact' },
              })}
            </Text>
          </Padding>
        </FlexItem>
        <FlexItem>
          <Text>
            {t('detailed', {
              total: formatPrice({
                price: { value: potentialRevenue, currency },
                options: { notation: 'compact' },
              }),
            })}
          </Text>
        </FlexItem>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.SMALL }}>
            <ProgressBar color={Color.BLUE} percentage={percentage} size={Size.SMALL} />
          </Padding>
        </FlexItem>
      </Flex>
    </Tile>
  );
};
