import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { useController } from '../../hooks';
import { WebsiteView } from '../website';
import { RootController } from './root.controller';

interface RootViewProps {}

export const RootView = ({}: RootViewProps) => {
  useController(RootController);
  //TODO: Replace by <Outlet /> after the app is migrated to tokamak
  return (
    <RoutesWithNotFound>
      <Route index element={<WebsiteView />} />
    </RoutesWithNotFound>
  );
};
