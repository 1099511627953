import { Tile } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  wrapper: css`
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 80%;
    }
  `,
  content: css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  `,
  contentWrapper: css`
    height: 100%;
    min-height: 0;
    flex: 1;
  `,
};

interface StatTileProps {
  lockRatio?: boolean;
  children: ReactNode;
  title?: string;
}

export const StatTile = ({ children, title, lockRatio = true }: StatTileProps) => {
  const content = (
    <Tile style={{ height: '100%', display: 'flex', flexDirection: 'column' }} title={title}>
      <div className={styles.contentWrapper}>{children}</div>
    </Tile>
  );
  if (lockRatio === false) {
    return content;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{content}</div>
    </div>
  );
};
