import React from 'react';
import { Route } from 'react-router';

import { RoutesWithNotFound } from '~/components';

import { BaseSettings } from './routes';

export const ProjectSettings = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<BaseSettings />} />
    </RoutesWithNotFound>
  );
};
