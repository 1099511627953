function _shuffle<T>(array: Array<T>): Array<T> {
  const arrayCopy = array.slice();
  let remainingElements = array.length;

  // While there remain elements to shuffle
  while (remainingElements > 0) {
    // Pick a remaining element
    const randomIndex = Math.floor(Math.random() * remainingElements--);
    // Swap it with the current element
    const aux = arrayCopy[remainingElements];
    arrayCopy[remainingElements] = arrayCopy[randomIndex];
    arrayCopy[randomIndex] = aux;
  }

  return arrayCopy;
}

export function weightedPick(items: Array<string>, weights: Record<string, number>): string {
  const weightsSum = Math.round(Object.values(weights).reduce((m, v) => m + v * 100, 0));

  if (weightsSum !== 100) {
    throw new Error(`Weights sum has to be 1. It was ${weightsSum / 100} instead.`);
  }

  const shuffledItems = _shuffle(items);
  let diceValue = Math.random();

  let result: string;

  for (let item of shuffledItems) {
    // The bigger the weight, the highest the posibility that diceValue gets to zero
    diceValue = diceValue - weights[item];
    if (diceValue <= 0) {
      result = item;
      break;
    }
  }

  return result!;
}
