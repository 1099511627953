import { OpUnitType } from 'dayjs';

export function getGranularity(daysOfData: number): OpUnitType {
  if (daysOfData <= 30) {
    return 'day';
  } else if (daysOfData <= 90) {
    return 'week';
  }
  return 'month';
}
