import {
  Button,
  Category,
  EmptyState as DrylusEmptyState,
  Flex,
  FlexDirection,
  FlexItem,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelSection,
  Size,
  useAlert,
} from '@drawbotics/react-drylus';
import React, { Fragment, useState } from 'react';

import { Campaign, InsightsLead, InsightsSession, Project } from '~/pods/insights/types';
import { Subscription } from '~/types';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
  useAuth,
  useIntercom,
} from '~/utils';

import marketingCampaignPlaceholder from '../../images/placeholders/marketing-campaign.svg';
import {
  CampaignDetails,
  CampaignDrawer,
  CampaignsOverTime,
  CampaignsOverview,
  EmptyState,
  Header,
  MarketingCampaignsLoadingPlaceholder,
} from './components';
import { useCreateCampaign, useLoadCampaigns, useUpdateCampaign } from './hooks';

const tt = createTranslate('pods.insights.routes.campaigns');

interface MarketingCampaignProps {
  project?: Project;
  leads?: Array<InsightsLead>;
  sessions?: Array<InsightsSession>;
}

export const MarketingCampaigns = ({ project, leads, sessions }: MarketingCampaignProps) => {
  const { campaigns, refetchCampaigns, isLoading, error } = useLoadCampaigns(
    leads,
    sessions,
    project?.id,
  );
  const { createCampaign, createCampaignResult } = useCreateCampaign();
  const { updateCampaign, updateCampaignResult } = useUpdateCampaign();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState<Campaign>();
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const { showIntercom } = useIntercom();

  if (isLoading || project?.id == null) {
    return (
      <Fragment>
        <Header onClickCreateCampaign={() => setIsDrawerVisible(true)} />
        <MarketingCampaignsLoadingPlaceholder />
      </Fragment>
    );
  }

  if (error != null) {
    return (
      <Flex direction={FlexDirection.VERTICAL} style={{ height: '100%' }}>
        <FlexItem>
          <DrylusEmptyState
            title={getTitleFromErrorType(error)}
            variation={getEmptyStateVariationFromErrorType(error)}
            description={getMessageFromErrorType(error)}
          />
        </FlexItem>
      </Flex>
    );
  }

  if (user?.organisation?.subscription === Subscription.ESSENTIALS) {
    return (
      <Fragment>
        <Header />
        <Panel
          body={
            <PanelBody>
              <Padding size={{ vertical: Size.HUGE }}>
                <DrylusEmptyState
                  image={marketingCampaignPlaceholder}
                  title={tt('will_show_here')}
                  description={tt('upgrade_to_growth')}>
                  <Button category={Category.INFO} onClick={showIntercom}>
                    {tt('contact_us')}
                  </Button>
                </DrylusEmptyState>
              </Padding>
            </PanelBody>
          }
        />
      </Fragment>
    );
  }

  const handleCreateCampaign = async (name: string, destinationUrl: string, source: string) => {
    const { error, data } = await createCampaign({
      campaign: {
        projectId: project.id,
        name,
        destinationUrl,
        source,
      },
    });

    if (error != null) {
      showAlert({
        text: tt('error_creating_campaign'),
        category: Category.DANGER,
      });
    } else {
      refetchCampaigns();
    }

    return data?.createCampaign.campaign;
  };

  const handleUpdateCampaign = async (token: string, name: string, source: string) => {
    const { error } = await updateCampaign({ campaign: { token, name, source } });

    if (error != null) {
      showAlert({
        text: tt('error_creating_campaign'),
        category: Category.DANGER,
      });
    } else {
      setCampaignToEdit(undefined);
      refetchCampaigns();
    }
  };

  const handleClickEditCampaign = (campaign: Campaign) => {
    setCampaignToEdit(campaign);
    setIsDrawerVisible(true);
  };

  return (
    <Fragment>
      {campaigns.length === 0 ? (
        <Fragment>
          <Header onClickCreateCampaign={() => setIsDrawerVisible(true)} />
          <EmptyState onClickCreateCampaign={() => setIsDrawerVisible(true)} />
        </Fragment>
      ) : (
        <Fragment>
          <Header onClickCreateCampaign={() => setIsDrawerVisible(true)} />
          <Panel
            body={
              <PanelBody>
                <CampaignsOverview campaigns={campaigns} />
              </PanelBody>
            }
          />
          <Margin size={Size.DEFAULT} />
          <Panel
            body={
              <PanelBody>
                <CampaignsOverTime campaigns={campaigns} />
              </PanelBody>
            }
          />
          <Margin size={Size.DEFAULT} />
          <Panel
            body={
              <PanelBody>
                <PanelSection title={tt('campaign_details')}>
                  <CampaignDetails
                    campaigns={campaigns}
                    onClickEditCampaign={handleClickEditCampaign}
                  />
                </PanelSection>
              </PanelBody>
            }
          />
        </Fragment>
      )}
      <CampaignDrawer
        isVisible={isDrawerVisible}
        onClickClose={() => setIsDrawerVisible(false)}
        onClickCreate={handleCreateCampaign}
        onClickSave={handleUpdateCampaign}
        campaign={campaignToEdit}
        isLoading={createCampaignResult.fetching || updateCampaignResult.fetching}
      />
    </Fragment>
  );
};
