import {
  Button,
  ButtonLink,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FormGroup,
  Grid,
  GridItem,
  Input,
  InputGroup,
  Label,
  Margin,
  MultiSelect,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  PhoneNumberInput,
  Select,
  Separator,
  Size,
  Spinner,
  Text,
  Tier,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import { Form, useForm } from '@drawbotics/use-form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { omit } from 'lodash';
import { flatMapDeep, flow, map, uniqBy } from 'lodash/fp';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AddressSearch } from '~/pods/crm/components';
import {
  Address,
  InterestType,
  LeadOrigin,
  Project,
  PurchaseGoal,
  UnitFeature,
} from '~/pods/crm/types';
import {
  getProjectPriceOptions,
  getProjectSurfaceOptions,
  projectPriceRange,
  projectSurfaceRange,
  unitTypeOptions as unitTypes,
  useCreateLead,
  useFetchOrganisation,
  useFetchUsers,
} from '~/pods/crm/utils';
import { ID } from '~/types';
import { extractGQLErrorMessages, isNullOrEmpty, useAuth, useMosaicNavigation } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { AddUnitModal } from './AddUnitModal';

const te = createTranslate('pods.crm');
const tt = createTranslate('pods.crm.routes.new_lead.new_lead_form');

interface NewLeadFormFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  assignedTo?: string;
  minimumBudget: number;
  maximumBudget: number;
  minimumSurface: number;
  maximumSurface: number;
  interests: Array<InterestType>;
  typologies: Array<number>;
  purchaseGoal: PurchaseGoal;
  heardFrom: LeadOrigin;
  unitFeatures: Array<UnitFeature>;
  surfaceUnit?: string;
  address: Address;
}

function _checkForm(newLeadForm: Form<NewLeadFormFields>, validatePhoneNumber: boolean) {
  const {
    lastName,
    email,
    minimumBudget,
    maximumBudget,
    minimumSurface,
    maximumSurface,
    phoneNumber,
  } = newLeadForm.values;
  const isValidName = lastName != null && lastName !== '';
  const isContactValid = email != null && email !== '';
  const isBudgetRangeValid =
    minimumBudget == null || maximumBudget == null || Number(minimumBudget) < Number(maximumBudget);
  const isSurfaceRangeValid =
    minimumSurface == null ||
    maximumSurface == null ||
    Number(minimumSurface) < Number(maximumSurface);
  const isPhoneNUmberValid = validatePhoneNumber ? isValidPhoneNumber(phoneNumber!) : true;
  return (
    isValidName && isContactValid && isBudgetRangeValid && isSurfaceRangeValid && isPhoneNUmberValid
  );
}

interface NewLeadFormProps {}

export const NewLeadForm = ({}: NewLeadFormProps) => {
  const { user } = useAuth();
  const { shortLabels: unitTypeOptions } = unitTypes;
  const { projectToken, navigateInOrg, navigateInProject, getUrlInOrg, getUrlInProject } =
    useMosaicNavigation();
  const { showAlert } = useAlert();
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState<boolean>(false);
  const [shouldValidatePhoneNumber, setShouldValidatePhoneNumber] = useState(false);
  const newLeadForm = useForm<NewLeadFormFields>({
    typologies: [],
    unitFeatures: [],
    interests: [],
    phoneNumber: '',
  });

  const { isUsersLoading, users } = useFetchUsers();
  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisation();
  const { isLoading: isCreatingLead, createLead } = useCreateLead();

  useEffect(() => {
    if (users != null) {
      newLeadForm.set(users.find((u) => u.id === user?.id)?.id ?? undefined, 'assignedTo');
    }
  }, [JSON.stringify(users)]);

  const projects = organisation?.projects;
  const steps = organisation?.steps;
  const currency = organisation?.currency;
  const surfaceUnit = organisation?.leads?.[0]?.surfaceUnit;

  useEffect(() => {
    if (projects && projectToken && newLeadForm.values.interests?.length === 0) {
      newLeadForm.set(
        projects
          .filter((project) => project.publicToken === projectToken)
          .map((project) => ({
            projectId: project.id,
            unitInterests: [],
          })),
        'interests',
      );
    }
  }, [projects]);

  const { minimumBudget, maximumBudget, minimumSurface, maximumSurface, typologies, interests } =
    newLeadForm.values;

  const handleClickSave = async () => {
    const { address, assignedTo } = newLeadForm.values;
    const isAssigneeFromUsers = users?.find((u) => u.id === assignedTo) != null;

    const res = await createLead({
      ...omit(newLeadForm.values, [
        'minimumBudget',
        'maximumBudget',
        'minimumSurface',
        'surfaceMax',
        'surfaceUnit',
        'address',
        'assignedTo',
        'phoneNumber',
      ]),
      address:
        address == null
          ? undefined
          : omit(address, ['fullAddress', 'countryName', 'coordinates', 'id']),
      minimumBudget: Number(minimumBudget),
      maximumBudget: Number(maximumBudget),
      minimumSurface: Number(minimumSurface),
      maximumSurface: Number(maximumSurface),
      surfaceUnit: organisation?.leads?.[0]?.surfaceUnit?.toUpperCase() ?? 'SQM',
      interests,
      typologies,
      assignedUserId: isAssigneeFromUsers ? assignedTo : undefined,
      assignedOrganisationId: isAssigneeFromUsers ? undefined : assignedTo,
      phoneNumber: shouldValidatePhoneNumber
        ? newLeadForm.values.phoneNumber!.replace(' ', '')
        : undefined,
    });

    if (res.data?.createLead?.lead.id != null && res.error == null) {
      projectToken != null
        ? navigateInProject('/crm', res.data.createLead.lead.id)
        : navigateInOrg('/crm', res.data.createLead.lead.id);
    } else if (res.error != null) {
      const { message } = extractGQLErrorMessages(res.error);
      showAlert({
        text: tt('create.error') + ', ' + message,
        category: Category.DANGER,
      });
    }
  };

  if (user == null || organisation == null) return null;

  // All the agencies this lead can be assigned to, if any.
  const agencyOptions = !user.isAgent
    ? flow(
        flatMapDeep('assignedAgencies'),
        uniqBy((agency) => agency.id),
        map((agency: { id: ID; name: string }) => ({ label: agency.name, value: agency.id })),
      )(organisation.projects)
    : [];

  const isFormValid = _checkForm(newLeadForm, shouldValidatePhoneNumber);
  const phoneNumberError =
    shouldValidatePhoneNumber &&
    newLeadForm.values.phoneNumber != '' &&
    !isValidPhoneNumber(newLeadForm.values.phoneNumber ?? '')
      ? tt('invalid_phone_number')
      : undefined;

  return (
    <Fragment>
      <Panel
        body={
          <PanelBody>
            <Flex align={FlexAlign.START}>
              <FlexItem flex={1}>
                <Label>1. {tt('labels.about_label')}</Label>
              </FlexItem>
              <FlexItem flex={2}>
                <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
                  <GridItem>
                    <FormGroup
                      input={
                        <Input
                          name="firstName"
                          placeholder={tt('placeholders.first_name')}
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                        />
                      }
                      label={<Label>{tt('labels.first_name')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <Input
                          name="lastName"
                          placeholder={tt('placeholders.last_name')}
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                        />
                      }
                      label={<Label>{tt('labels.last_name')}*</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <Input
                          name="email"
                          placeholder={tt('placeholders.email')}
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                          type="email"
                        />
                      }
                      label={<Label>{tt('labels.email')}*</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <PhoneNumberInput
                          name="phoneNumber"
                          placeholder={tt('placeholders.phone')}
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                          onBlur={() => setShouldValidatePhoneNumber(true)}
                          error={phoneNumberError}
                        />
                      }
                      label={<Label>{tt('labels.phone')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <AddressSearch
                          name="address"
                          value={newLeadForm.values.address}
                          onPickAddress={newLeadForm.set}
                        />
                      }
                      label={<Label>{tt('labels.address')}</Label>}
                    />
                  </GridItem>
                </Grid>
              </FlexItem>
            </Flex>
            <Margin size={{ vertical: Size.SMALL }}>
              <Separator />
            </Margin>
            <Flex align={FlexAlign.START}>
              <FlexItem flex={1}>
                <Label>2. {tt('labels.lead_management')}</Label>
              </FlexItem>
              <FlexItem flex={2}>
                <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
                  <GridItem>
                    <FormGroup
                      input={
                        <Select
                          name="assignedTo"
                          loading={isUsersLoading}
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                          options={[
                            ...(agencyOptions.length > 0
                              ? [
                                  {
                                    label: `-- ${tt('labels.agencies')} --`,
                                    value: 'agencies',
                                    disabled: true,
                                  },
                                  ...agencyOptions,
                                  {
                                    label: `-- ${tt('labels.company_members')} --`,
                                    value: 'members',
                                    disabled: true,
                                  },
                                ]
                              : []),
                            ...(!user.isAgent
                              ? [{ label: te('status.not_assigned'), value: undefined }]
                              : []),
                            ...(users?.map((user) => ({
                              label: user.fullName,
                              value: user.id,
                            })) ?? []),
                          ]}
                          placeholder={tt('placeholders.assign_to')}
                        />
                      }
                      label={<Label>{tt('labels.assign_to')}</Label>}
                    />
                  </GridItem>
                </Grid>
              </FlexItem>
            </Flex>
            <Margin size={{ vertical: Size.SMALL }}>
              <Separator />
            </Margin>
            <Flex align={FlexAlign.START}>
              <FlexItem flex={1}>
                <Label>3. {tt('labels.interest')}</Label>
              </FlexItem>
              <FlexItem flex={2}>
                <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
                  <GridItem>
                    <FormGroup
                      input={
                        <Select
                          name="heardFrom"
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                          options={Object.values(LeadOrigin).map((value) => ({
                            label: te(`lead_origin.${value.toLowerCase()}`),
                            value,
                          }))}
                          placeholder={tt('placeholders.source')}
                        />
                      }
                      label={<Label>{tt('labels.source')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <Select
                          name="purchaseGoal"
                          onChange={newLeadForm.set}
                          value={newLeadForm.get}
                          options={Object.values(PurchaseGoal).map((value) => ({
                            label: te(`purchase_goal.${value.toLowerCase()}`),
                            value,
                          }))}
                          placeholder={tt('placeholders.goal')}
                        />
                      }
                      label={<Label>{tt('labels.goal')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <InputGroup horizontal>
                          <Select
                            name="minimumBudget"
                            onChange={(minBudget) => {
                              newLeadForm.set(minBudget, 'minimumBudget');
                              if (maximumBudget == null) {
                                newLeadForm.set(projectPriceRange[1], 'maximumBudget');
                              }
                            }}
                            value={newLeadForm.get}
                            placeholder="min"
                            options={
                              getProjectPriceOptions({
                                currency,
                                topLimit: maximumBudget ?? projectPriceRange[1],
                              }) ?? []
                            }
                          />
                          <Select
                            name="maximumBudget"
                            onChange={(maxBudget) => {
                              newLeadForm.set(maxBudget, 'maximumBudget');
                              if (minimumBudget == null) {
                                newLeadForm.set(projectPriceRange[0], 'minimumBudget');
                              }
                            }}
                            value={newLeadForm.get}
                            placeholder="max"
                            options={
                              getProjectPriceOptions({
                                currency,
                                bottomLimit: minimumBudget ?? projectPriceRange[0],
                              }) ?? []
                            }
                          />
                        </InputGroup>
                      }
                      label={<Label>{tt('labels.budget')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <InputGroup horizontal>
                          <Select
                            name="minimumSurface"
                            onChange={(minSurface) => {
                              newLeadForm.set(minSurface, 'minimumSurface');
                              if (maximumSurface == null) {
                                newLeadForm.set(projectSurfaceRange[1], 'maximumSurface');
                              }
                            }}
                            value={newLeadForm.get}
                            placeholder="min"
                            options={
                              getProjectSurfaceOptions({
                                surfaceUnit,
                                topLimit: maximumSurface ?? projectSurfaceRange[1],
                              }) ?? []
                            }
                          />
                          <Select
                            name="maximumSurface"
                            onChange={(maxSurface) => {
                              newLeadForm.set(maxSurface, 'maximumSurface');
                              if (minimumSurface == null) {
                                newLeadForm.set(projectSurfaceRange[0], 'minimumSurface');
                              }
                            }}
                            value={newLeadForm.get}
                            placeholder="max"
                            options={
                              getProjectSurfaceOptions({
                                surfaceUnit,
                                bottomLimit: minimumSurface ?? projectSurfaceRange[0],
                              }) ?? []
                            }
                          />
                        </InputGroup>
                      }
                      label={<Label>{tt('labels.surface')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <MultiSelect
                          name="typologies"
                          onChange={newLeadForm.set}
                          values={newLeadForm.get}
                          options={Object.keys(unitTypeOptions).map((unitType) => ({
                            label: te(`typologies.${unitTypeOptions[unitType]}`),
                            value: Number(unitType),
                          }))}
                          placeholder={tt('placeholders.typology')}
                        />
                      }
                      label={<Label>{tt('labels.typology')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <MultiSelect
                          name="unitFeatures"
                          onChange={newLeadForm.set}
                          values={newLeadForm.get}
                          options={Object.values(UnitFeature).map((value) => ({
                            label: te(`unit_feature.${value.toLowerCase()}`),
                            value,
                          }))}
                          placeholder={tt('placeholders.features')}
                        />
                      }
                      label={<Label>{tt('labels.features')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <MultiSelect
                          hint={organisationError != null ? tt('projects_load_fail') : undefined}
                          disabled={organisationError != null || isNullOrEmpty(projects)}
                          name="interests"
                          loading={isOrganisationLoading}
                          // todo: I'm massively broken
                          onChange={(projectIds) =>
                            newLeadForm.set(
                              projectIds.map((id) => ({ projectId: id })),
                              'interests',
                            )
                          }
                          values={interests?.map((interest) => interest.projectId) ?? []}
                          options={
                            projects != null
                              ? projects.map((project: Project) => ({
                                  value: project.id,
                                  label: project.name,
                                }))
                              : []
                          }
                          placeholder={tt('placeholders.projects')}
                        />
                      }
                      label={<Label>{tt('labels.projects')}</Label>}
                    />
                  </GridItem>
                  <GridItem>
                    <FormGroup
                      input={
                        <Button
                          disabled={interests?.length === 0}
                          onClick={() => setIsAddUnitModalOpen(true)}>
                          {/* // todo: never translated */}
                          {`Add Unit References (${interests?.reduce(
                            (memo, interest) => memo + (interest.unitInterests?.length ?? 0),
                            0,
                          )})`}
                        </Button>
                      }
                      label={<Label></Label>}
                    />
                  </GridItem>
                </Grid>
              </FlexItem>
            </Flex>
          </PanelBody>
        }
        footer={
          <PanelFooter>
            <Flex justify={FlexJustify.START}>
              <FlexItem flex>
                <Text tier={Tier.SECONDARY} size={Size.SMALL}>
                  *{tt('mandatory')}
                </Text>
              </FlexItem>
              <FlexItem>
                <Margin size={{ right: Size.SMALL }}>
                  <Link to={projectToken != null ? getUrlInProject('/crm') : getUrlInOrg('/crm')}>
                    <ButtonLink tier={Tier.SECONDARY}>{tt('cancel_button')}</ButtonLink>
                  </Link>
                </Margin>
              </FlexItem>
              <FlexItem>
                <Button
                  disabled={isCreatingLead || !isFormValid || steps == null || steps?.length === 0}
                  category={Category.INFO}
                  onClick={handleClickSave}
                  trailing={isCreatingLead ? <Spinner inversed /> : null}>
                  {tt('save_lead')}
                </Button>
              </FlexItem>
            </Flex>
          </PanelFooter>
        }
        header={
          <PanelHeader>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem flex>
                <Margin size={{ bottom: Size.SMALL }}>
                  <Title noMargin size={4}>
                    {tt('title')}
                  </Title>
                </Margin>
              </FlexItem>
            </Flex>
          </PanelHeader>
        }
      />
      <AddUnitModal
        projects={projects}
        interests={interests ?? []}
        onClickConfirm={(interests) => {
          newLeadForm.set(interests, 'interests');
          setIsAddUnitModalOpen(false);
        }}
        onClickClose={() => setIsAddUnitModalOpen(false)}
        visible={isAddUnitModalOpen}
      />
    </Fragment>
  );
};
