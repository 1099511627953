import {
  Button,
  Category,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  Label,
  LoadingPlaceholder,
  Margin,
  Position,
  Select,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import capitalize from 'lodash/capitalize';
import { flatMapDeep, flow, map, uniqBy } from 'lodash/fp';
import React, { Fragment, useRef, useState } from 'react';

import { MosaicPageTitle } from '~/components';
import { Status } from '~/pods/crm/components';
import { EngagementLevelEnum, Lead, Organisation, Step, StepType } from '~/pods/crm/types';
import { useFetchUsers, useUpdateLead } from '~/pods/crm/utils';
import { ID } from '~/types';
import { createTranslate, run, useAuth, useMosaicMutation } from '~/utils';

import { SalesAgreementModal } from '../SalesAgreementModal';
import { EngagementLevel } from './EngagementLevel';
import { StatusBumper } from './StatusBumper';
import { StatusWheel } from './StatusWheel';

const styles = {
  leadProfile: css`
    position: relative;
  `,
  editButton: css`
    position: absolute;
    right: 0;
    top: 0;
  `,
  engagement: css`
    position: absolute;
    margin-left: -20px;
    top: 5px;
  `,
};

const tt = createTranslate('pods.crm.routes.lead.components.lead_details.profile');

interface UpdateLeadStepVariables {
  leadId: string;
  stepId: string;
}

interface UpdateLeadStepResult {
  updateLeadFunnelStep: {
    lead: Lead;
  };
}

const updateLeadStepMutation = gql`
  mutation UpdateLeadStep($leadId: ID!, $stepId: ID!) {
    updateLeadFunnelStep(input: { leadId: $leadId, funnelStepId: $stepId }) {
      lead {
        id
        step: funnelStep {
          id
          name
        }
      }
    }
  }
`;

interface AssignLeadVariables {
  userId?: string;
  organisationId?: string;
  leadId: string;
}

interface AssignLeadResult {
  assignLead: {
    lead: Lead;
  };
}

const assignLeadMutation = gql`
  mutation AssignLeadMutation($leadId: ID!, $userId: ID, $organisationId: ID) {
    assignLead(input: { leadId: $leadId, userId: $userId, organisationId: $organisationId }) {
      lead {
        id
        user {
          id
          fullName @computed(type: User)
        }
      }
    }
  }
`;

interface LeadProfileForm {
  firstName: string;
  lastName: string;
  stepId: string;
  assignedId: string;
}

export const LeadProfilePlaceHolder = () => {
  return (
    <Flex direction={FlexDirection.VERTICAL}>
      <FlexItem flex>
        <LoadingPlaceholder width={100} height={110} />
      </FlexItem>
      <FlexSpacer size={Size.DEFAULT} />
      <FlexItem>
        <LoadingPlaceholder height={30} />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={100} />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={100} />
      </FlexItem>
    </Flex>
  );
};

interface LeadProfileProps {
  lead: Lead;
  steps: Array<Step>;
  reloadLogs: VoidFunction;
  reloadLead: VoidFunction;
  organisation: Organisation;
}

export const LeadProfile = ({
  lead,
  steps,
  reloadLogs,
  reloadLead,
  organisation,
}: LeadProfileProps) => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showAlert } = useAlert();
  const leadProfileForm = useForm<LeadProfileForm>({
    firstName: lead.firstName ?? '',
    lastName: lead.lastName ?? '',
    assignedId: lead.user?.id ?? '',
  });
  const userSelectRef = useRef<HTMLDivElement>(null);

  // FETCH
  const { isUsersLoading, users = [] } = useFetchUsers();

  // MUTATION HOOKS
  const { isLoading: isUpdating, updateLead } = useUpdateLead();
  const {
    res: { fetching: isUpdatingStep },
    executeMutation: updateLeadStep,
  } = useMosaicMutation<UpdateLeadStepResult, UpdateLeadStepVariables>(updateLeadStepMutation);
  const {
    res: { fetching: isAssigningLead },
    executeMutation: assignLead,
  } = useMosaicMutation<AssignLeadResult, AssignLeadVariables>(assignLeadMutation);

  // COMPUTED VALUES
  const lostStep = steps.find((step) => step.type === StepType.LOST);
  const sortedSteps = steps.slice().sort((a, b) => b.position - a.position);
  const assignableSteps = sortedSteps
    .filter(
      (step, i) =>
        step.type !== StepType.LOST &&
        sortedSteps.findIndex((step) => step.id === lead.step.id) <= i + 1,
    )
    .map((step) => ({
      value: step.id,
      label: capitalize(step.name.replace(/_/g, ' ')),
      disabled: step.type === StepType.INITIAL && lead.step.type !== StepType.INITIAL,
    }));

  // HANDLER FUNCTIONS
  const handleChangeStep = (stepId: string) => {
    const step = steps.find((step) => step.id === stepId);

    if (
      step?.type === StepType.ASSIGNED &&
      leadProfileForm.values.assignedId == null &&
      lead.user == null
    ) {
      showAlert({ text: tt('select_user'), category: Category.WARNING });
      const selectElement = userSelectRef.current?.querySelector('select');
      selectElement?.focus();
    } else {
      leadProfileForm.set(stepId, 'stepId');
    }
  };

  const handleSaveLeadInfo = async (nextStep?: Step) => {
    const { firstName, lastName, assignedId, stepId: formStepId } = leadProfileForm.values;
    const stepId = nextStep?.id ?? formStepId;
    const step = sortedSteps.find((step: Step) => step.id == stepId);

    // TODO handle going back to offer step, how do we prevent that?
    if (step?.type === StepType.OFFER) {
      setIsModalOpen(true);
      setIsEditing(false);
      return;
    }

    if (firstName != null || lastName != null) {
      await updateLead({ id: lead.id, firstName, lastName });
      reloadLogs();
    }

    if (assignedId != null) {
      const isIdFromUsers = users.find((u) => u.id === assignedId) != null;
      const { error, data } = await assignLead({
        leadId: lead.id,
        userId: isIdFromUsers ? assignedId : undefined,
        organisationId: isIdFromUsers ? undefined : assignedId,
      });

      if (error != null) {
        showAlert({
          text: tt('assign.error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('assign.success', { user: data?.assignLead.lead.user?.fullName ?? '' }),
          category: Category.SUCCESS,
        });
        reloadLogs();
      }
    }

    if (stepId != null) {
      const { error, data } = await updateLeadStep({ stepId, leadId: lead.id });
      if (error != null) {
        showAlert({
          text: tt('status_update.error'),
          category: Category.DANGER,
        });
      } else {
        leadProfileForm.set(stepId, 'stepId');
        showAlert({
          text: tt('status_update.success', {
            stepname:
              capitalize(data?.updateLeadFunnelStep.lead.step.name.replace(/_/g, ' ')) ?? '',
          }),
          category: Category.SUCCESS,
        });
        reloadLogs();
      }
    }
    setIsEditing(false);
  };

  const handleClickBumpStep = (nextStep: Step) => {
    if (lead.step.type === StepType.INITIAL && lead.user == null) {
      setIsEditing(true);
      showAlert({ text: tt('select_user'), category: Category.INFO });
    } else if (nextStep.type === StepType.OFFER) {
      setIsModalOpen(true);
    } else {
      handleSaveLeadInfo(nextStep);
    }
  };

  const handleUpdateEngagement = async (engagementLevel: EngagementLevelEnum | null) => {
    await updateLead({ id: lead.id, engagementLevel });
  };

  if (user == null) return null;

  const agencyOptions = !user.isAgent
    ? flow(
      flatMapDeep('assignedAgencies'),
      uniqBy((agency) => agency.id),
      map((agency: { id: ID; name: string }) => ({ label: agency.name, value: agency.id })),
    )(organisation.projects)
    : [];

  const selectValue = (() => {
    if (leadProfileForm.values.assignedId) return leadProfileForm.values.assignedId;
    else if (user.isAgent) return lead.user?.id;
    else if (lead.user?.organisation?.isAgency) return lead.user.organisation?.id;
    else return lead.user?.id;
  })();

  return (
    <div className={styles.leadProfile}>
      {!isEditing && (
        <div className={styles.editButton}>
          <Tooltip content={tt('title')} side={Position.BOTTOM}>
            <Button onClick={() => setIsEditing(true)} leading={<Icon name="edit-2" />} />
          </Tooltip>
        </div>
      )}
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <StatusWheel activeStep={lead.step} steps={steps} />
            <EngagementLevel
              engagementLevel={lead.engagementLevel}
              isLoading={isUpdating}
              onChangeEngagement={handleUpdateEngagement}
              className={styles.engagement}
            />
          </Margin>
        </FlexItem>
        {run(() => {
          if (isEditing) {
            return (
              <FlexItem>
                <Margin size={{ bottom: Size.SMALL }}>
                  <FormGroup
                    label={<Label>{tt('name')}</Label>}
                    input={
                      <InputGroup horizontal>
                        <Input
                          placeholder={tt('placeholder.first_name')}
                          name="firstName"
                          onChange={leadProfileForm.set}
                          value={leadProfileForm.get}
                        />
                        <Input
                          placeholder={tt('placeholder.last_name')}
                          name="lastName"
                          onChange={leadProfileForm.set}
                          value={leadProfileForm.get}
                        />
                      </InputGroup>
                    }
                  />
                </Margin>
                <Margin size={{ bottom: Size.SMALL }}>
                  <FormGroup
                    label={<Label>{tt('assigned_to')}</Label>}
                    input={
                      <div ref={userSelectRef}>
                        <Select
                          name="assignedId"
                          loading={isUsersLoading}
                          onChange={leadProfileForm.set}
                          options={[
                            ...(agencyOptions.length > 0
                              ? [
                                {
                                  label: `-- ${tt('agencies')} --`,
                                  value: 'agencies',
                                  disabled: true,
                                },
                                ...agencyOptions,
                              ]
                              : []),
                            {
                              label: `-- ${tt('company_members')} --`,
                              value: 'members',
                              disabled: true,
                            },
                            ...(users?.map((user) => ({
                              label: user.fullName,
                              value: user.id,
                            })) ?? []),
                          ]}
                          value={selectValue}
                        />
                      </div>
                    }
                  />
                </Margin>
                <Margin size={{ bottom: Size.SMALL }}>
                  <FormGroup
                    label={<Label>{tt('status')}</Label>}
                    input={
                      <Select
                        name="stepId"
                        onChange={lead.step.type !== StepType.LOST ? handleChangeStep : undefined}
                        value={leadProfileForm.values.stepId ?? lead.step.id}
                        options={[
                          ...assignableSteps,
                          { value: lostStep!.id, label: capitalize(lostStep!.name) },
                        ]}
                      />
                    }
                  />
                </Margin>
                {lead.step.type === StepType.LOST && lead.previousStep != null ? (
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    <Flex>
                      <FlexItem>
                        <Button
                          onClick={() => handleChangeStep(lead.previousStep!.id)}
                          leading={
                            <Icon
                              name={
                                leadProfileForm.values.stepId === lead.previousStep.id
                                  ? 'check'
                                  : 'rotate-ccw'
                              }
                            />
                          }
                          color={
                            leadProfileForm.values.stepId === lead.previousStep.id
                              ? Color.GREEN
                              : undefined
                          }
                          tier={Tier.TERTIARY}
                          size={Size.SMALL}>
                          {tt('revert_to')}
                        </Button>
                      </FlexItem>
                      <FlexSpacer size={Size.SMALL} />
                      <FlexItem>
                        <Status step={lead.previousStep} />
                      </FlexItem>
                    </Flex>
                  </Margin>
                ) : null}
                <Margin size={{ bottom: Size.EXTRA_SMALL }}>
                  <Button
                    size={Size.SMALL}
                    fullWidth
                    tier={Tier.TERTIARY}
                    onClick={() => {
                      setIsEditing(false);
                      leadProfileForm.reset();
                    }}>
                    {tt('cancel_button')}
                  </Button>
                </Margin>
                <Button
                  onClick={() => handleSaveLeadInfo()}
                  trailing={
                    isUpdating || isUpdatingStep || isAssigningLead ? (
                      <Spinner size={Size.SMALL} inversed />
                    ) : null
                  }
                  disabled={isUpdating || isUpdatingStep || isAssigningLead}
                  size={Size.SMALL}
                  fullWidth
                  category={Category.INFO}>
                  {tt('save_button')}
                </Button>
              </FlexItem>
            );
          } else {
            return (
              <Fragment>
                <FlexItem>
                  <MosaicPageTitle size={3} margin={Size.SMALL}>
                    {lead.fullName}
                  </MosaicPageTitle>
                </FlexItem>
                {(() => {
                  if (lead.user == null) return null;
                  else if (!(user.isAgent === lead.user.organisation?.isAgency)) {
                    return (
                      <FlexItem>
                        <Margin size={{ bottom: Size.SMALL }}>
                          <Text size={Size.SMALL} shade={Shade.MEDIUM}>
                            {tt('assigned_to_user', { user: lead.user.organisation?.name! })}
                          </Text>
                        </Margin>
                      </FlexItem>
                    );
                  } else {
                    return (
                      <FlexItem>
                        <Margin size={{ bottom: Size.SMALL }}>
                          <Text size={Size.SMALL} shade={Shade.MEDIUM}>
                            {tt('assigned_to_user', { user: lead.user.firstName })}
                          </Text>
                        </Margin>
                      </FlexItem>
                    );
                  }
                })()}
                <FlexItem>
                  <StatusBumper
                    key={lead.step.id}
                    loading={isUpdatingStep}
                    activeStep={lead.step}
                    steps={steps}
                    onBumpStep={handleClickBumpStep}
                  />
                </FlexItem>
              </Fragment>
            );
          }
        })}
      </Flex>
      <SalesAgreementModal
        lead={lead}
        visible={isModalOpen}
        onCreateOffer={() => {
          reloadLead();
          reloadLogs();
        }}
        onClickClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
