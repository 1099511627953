import { lighten } from '@drawbotics/drylus-style-vars';
import { injectGlobal } from 'emotion';

import { Theme, ThemeDefinition } from '../../types';

injectGlobal`
  @import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Josefin+Sans:wght@400;600;700&display=swap');
`;

const fonts = {
  primary: 'EB Garamond',
  secondary: 'Josefin Sans',
  extraLarge: '26px',
  large: '24px',
  small: '14px',
  extraSmall: '10px',
  extraLargeSpacing: '36px',
  largeSpacing: '30px',
  smallSpacing: '24px',
  extraSmallSpacing: '24px',
};

const colors = {
  primary: '#2F4E73',
  secondary: '#CAAE6F',
  neutral1: '#000000',
  neutral2: '#4F4F4F',
  neutral3: '#F2F2F2',
  neutral4: '#FFFFFF',
};

export const traditional: ThemeDefinition = {
  id: Theme.TRADITIONAL,
  fonts,
  colors: {
    ...colors,
    primaryLight: lighten(colors.primary, 20),
  },
};
