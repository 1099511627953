import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';
import { AssetSchema } from '../asset';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const GallerySectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.GALLERY),
  active: ze.defaults(z.boolean(), false),
  assets: z.array(AssetSchema).transform((a) => a.sort((a, b) => a.position! - b.position!)),
  position: z.number(),
});

export type GallerySectionData = z.infer<typeof GallerySectionSchema>;

export class GallerySection extends ze.ClassFrom(GallerySectionSchema) {
  public static fromData(data: GallerySectionData): GallerySection {
    return ze.validate(data, GallerySection);
  }

  get isActive() {
    return this.active;
  }

  public toData(): GallerySectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
