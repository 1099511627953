export const POINTS_PER_GRAPH = 12;

export type Metric = 'revenue' | 'units';

export const Timescale = {
  WEEK: 'This week',
  MONTH: 'This month',
  TRIMESTER: 'Last three months',
  ALL_TIME: 'All time',
};

export const timescaleToDays = {
  [Timescale.WEEK]: 7,
  [Timescale.MONTH]: 28,
  [Timescale.TRIMESTER]: 90,
};

export const ALL_TYPOLOGIES = 'all_typologies';
