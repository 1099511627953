import { gql } from "urql";
import { useMosaicMutation } from "~/utils";
import { LeadEmailActionType } from "../../types";

export type UpdateLeadEmailActionMutationVariables<Category extends 'PRIMARY' | 'SECONDARY'> = {
  activated: boolean;
  label?: string;
  category: Category,
  linkType?: LeadEmailActionType
  url?: string,
  signedBlobId?: string;
  includeInterests?: boolean;
}

export type UpdateLeadEmailActionsMutationVariables = {
  links: {
    projectId: string;
    emailCtaLinks: Array<
      UpdateLeadEmailActionMutationVariables<'PRIMARY' | 'SECONDARY'>
    >
  }
};

type LeadEmailActionsLinkMutationData = {
  activated: boolean;
  id: string;
  label: string;
  linkType: LeadEmailActionType,
  category: 'PRIMARY' | 'SECONDARY',
  attachment?: {
    id: string,
    filename: string,
    url: string
  },
  includeInterests?: boolean
  url?: string
}

type UpdateLeadEmailActionsMutationData = {
  links: Array<LeadEmailActionsLinkMutationData>
};

const UpdateLeadEmailActionsMutation = gql`
  mutation UpdateLeadEmailActions($links: UpdateLeadEmailActionsInput!) {
    updateLeadEmailActions(input: $links) {
      links {
        label,
        category,
        linkType,
        ... on DownloadLink {
          attachment {
            id,
            filename
            url
          }
        }
        ... on SchedulingLink {
          includeInterests
        }
        ... on UrlLink {
          url
        }
      }
    }
  }
`;

export function useLeadEmailActionsMutation() {
  const {
    res: { fetching: isUpdating },
    executeMutation: updateLeadEmailActions,
  } = useMosaicMutation<
    UpdateLeadEmailActionsMutationData,
    UpdateLeadEmailActionsMutationVariables
  >(UpdateLeadEmailActionsMutation);

  return {
    isUpdatingLeadEmailActions: isUpdating,
    updateLeadEmailActions
  }
}