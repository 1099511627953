import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Modal,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.presentation.routes.presentation.components.delete_section_modal');

interface DeleteSectionModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  onClickConfirm: VoidFunction;
}

export const DeleteSectionModal = ({
  visible,
  onClickClose,
  onClickConfirm,
}: DeleteSectionModalProps) => {
  return (
    <Modal
      title={tt('delete_section')}
      visible={visible}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              trailing={<Icon name="trash-2" />}
              category={Category.DANGER}
              onClick={onClickConfirm}>
              {tt('delete_section')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Text>{tt('confirm_text')}</Text>
    </Modal>
  );
};
