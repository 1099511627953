import { HexColor, UrlString } from '~/types';
import { Currency, SurfaceUnit } from '~/types';

export enum LinkType {
  URL = 'URL',
  DOWNLOAD = 'DOWNLOAD',
  SECTION = 'SECTION',
  CONTACT = 'CONTACT_FORM',
}

export enum DomainStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  VERIFIED = 'VERIFIED',
}

export enum Theme {
  TRADITIONAL = 'TRADITIONAL',
  LUXURIOUS = 'LUXURIOUS',
  MINIMALIST = 'MINIMALIST',
}

export enum WebsiteLanguage {
  EN = 'EN',
  FR = 'FR',
  NL = 'NL',
}

export enum SectionIdentifier {
  HERO = 'HERO',
  COVER = 'COVER',
  MAIN = 'MAIN',
  GALLERY = 'GALLERY',
  MAP = 'MAP',
  LISTING = 'LISTING',
  TEXT_IMAGE = 'TEXT_IMAGE',
}

export interface ThemeDefinition {
  id: Theme;
  fonts: {
    primary: string;
    secondary?: string;
    extraLarge: string;
    large: string;
    small: string;
    extraSmall: string;
    extraLargeSpacing: string;
    largeSpacing: string;
    smallSpacing: string;
    extraSmallSpacing: string;
  };
  colors: {
    primary: HexColor;
    primaryLight?: HexColor;
    secondary: HexColor;
    neutral1: HexColor;
    neutral2: HexColor;
    neutral3: HexColor;
    neutral4: HexColor;
    neutral5?: HexColor;
  };
}

export interface CustomDomain {
  id: string;
  cname: string;
  hostname: UrlString;
  status: DomainStatus;
}

export enum UnitAvailability {
  AVAILABLE = 'available',
  BOOKED = 'booked',
  OPTION = 'option',
  UNAVAILABLE = 'unavailable',
}

export interface BaseUnit {
  id: string;
  reference: string;
  availability: UnitAvailability;
  bedrooms: number;
  terraces: string;
  floorplan: string;
  surface: number;
  price: number;
}

export interface Unit {
  id: string;
  reference: string;
  availability: UnitAvailability;
  bedrooms: number;
  terrace: boolean;
  floorplan: string;
  surface: {
    value: number;
    unit: SurfaceUnit;
  };
  price: {
    value: number;
    currency: Currency;
  };
}

export enum SocialMediaPlatform {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
}
