import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CircularProgress,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Modal,
  Paragraph,
  Position,
  Shade,
  Size,
  Spinner,
  Tier,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { AttachmentAsset, UrlAsset } from '~/types';
import {
  UploadedFile,
  createTranslate,
  useBackofficeMutation,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  uploadTile: css`
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    > [data-element='upload-overlay'] {
      opacity: 0;
    }

    &:hover {
      > i {
        display: none;
      }

      > [data-element='upload-overlay'] {
        opacity: 1;
      }
    }
  `,
  empty: css`
    background: ${sv.neutralLight};
    outline: 2px dashed ${sv.blue};
    outline-offset: ${sv.marginExtraSmall};
    width: calc(100% - ${sv.marginSmall});
    height: calc(100% - ${sv.marginSmall});
    margin-left: ${sv.marginExtraSmall};
    margin-top: ${sv.marginExtraSmall};
  `,
  imageIconContainer: css`
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  imageIconTop: css`
    justify-content: flex-start;
  `,
  assetContainer: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    z-index: 1;
    overflow: hidden;

    > iframe {
      position: absolute;
      top: 50%;
      left: 0;
      border: 0;
      width: 100%;
      transform: translateY(-50%);
    }
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${sv.darkOverlay};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${sv.transitionShort};
    color: ${sv.colorPrimaryInverse};
    z-index: 3;
    text-align: center;
  `,
  uploadButtonsContainer: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  uploadButtonsContainerTop: css`
    justify-content: flex-start;
    padding-top: ${sv.defaultPadding};
  `,
  progressOverlay: css`
    background: ${sv.neutralLight};
    z-index: 4;
  `,
  errorOverlay: css`
    background: ${sv.red};
    opacity: 0.8;
    pointer-events: none;
    z-index: 2;
  `,
};

function _isVimeoLink(url: string): boolean {
  return url.match(/(https:\/\/)vimeo.com\/(\d+)/) != null;
}

function _vimeoToPlayer(url: string): string {
  return url.replace(
    /(https:\/\/)vimeo.com\/(\d+)/,
    (_, $1, $2) =>
      `${$1}player.vimeo.com/video/${$2}?background=1&api=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&player_id=vvimeoVid`,
  );
}

function _isAttachmentAsset(asset: AttachmentAsset | UrlAsset): asset is AttachmentAsset {
  return 'filename' in asset;
}

interface AssetTileProps {
  asset?: AttachmentAsset | UrlAsset;
  onFinishUpload: (asset: UploadedFile) => Promise<boolean> | void;
  onSaveUrl?: (url: string) => Promise<boolean> | void;
  readOnly?: boolean;
  text?: {
    linkUrlButton: string;
    modalTitle: string;
    modalBody: string;
    modalPlaceholder: string;
  };
  mutationTargetType?: typeof useBackofficeMutation | typeof useMosaicMutation;

  uploadButtonPosition?: Position;
}

export const AssetTile = ({
  asset,
  onFinishUpload,
  onSaveUrl,
  readOnly = false,
  mutationTargetType,
  text,
  uploadButtonPosition,
}: AssetTileProps) => {
  const [hasError, setHasError] = useState(false);
  const [isUrlModalVisible, setIsUrlModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [videoScaleRatio, setVideoScaleRatio] = useState<number>();
  const {
    data,
    uploadFiles,
    info,
    isLoading: isFileUploading,
  } = useFileUpload(mutationTargetType ?? useMosaicMutation);

  const { linkUrlButton, modalTitle, modalBody, modalPlaceholder } = text ?? {};

  const handleUpload = async (fileList: FileList) => {
    setHasError(false);
    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    if (validFiles.length > 0) {
      const success = await onFinishUpload(validFiles[0]);
      setLinkUrl('');
      if (success === false) {
        setHasError(true);
      }
    }
  };

  const handleSaveLink = async () => {
    if (linkUrl != null) {
      setHasError(false);
      setIsLoading(true);
      const success = await onSaveUrl!(linkUrl);
      setIsLoading(false);
      if (success === false) {
        setHasError(true);
      } else {
        setIsUrlModalVisible(false);
      }
    }
  };

  const handleClickCancel = () => {
    setIsUrlModalVisible(false);
    setLinkUrl('');
  };

  const handleWindowResize = () => {
    const { height, width } = elementRef.current?.getBoundingClientRect() ?? {};
    if (height != null && width != null) {
      const defaultVideoRatio = 16 / 9;
      const ratio = width / height;
      if (defaultVideoRatio > ratio) {
        setVideoScaleRatio(height / (width / defaultVideoRatio));
      } else {
        setVideoScaleRatio(undefined);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (asset != null && !_isAttachmentAsset(asset)) {
      setLinkUrl(asset.url);
    }
  }, [asset?.url, isUrlModalVisible]);

  const assetUrl = data[0]?.url ?? asset?.url;

  return (
    <Fragment>
      <div className={cx(styles.uploadTile, { [styles.empty]: assetUrl == null })} ref={elementRef}>
        {assetUrl == null ? (
          <div
            className={cx(styles.imageIconContainer, {
              [styles.imageIconTop]: uploadButtonPosition === Position.TOP,
            })}>
            <Icon shade={Shade.MEDIUM} name="image" />
          </div>
        ) : null}
        {isFileUploading && info.length > 0 ? (
          <div className={cx(styles.overlay, styles.progressOverlay)}>
            <CircularProgress
              size={Size.SMALL}
              percentage={info[0].progress ?? 0}
              color={Color.GREEN}
            />
          </div>
        ) : null}
        {asset != null && _isAttachmentAsset(asset) ? (
          <div className={styles.assetContainer} style={{ backgroundImage: `url(${assetUrl})` }} />
        ) : null}
        {asset != null && !_isAttachmentAsset(asset) ? (
          <div className={styles.assetContainer}>
            <iframe
              style={
                videoScaleRatio != null
                  ? { transform: `translateY(-50%) scale(${videoScaleRatio})` }
                  : undefined
              }
              src={asset.url}
              height="1080"
              width="1920"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        ) : null}
        {hasError ? (
          <div className={cx(styles.overlay, styles.errorOverlay)}>
            <Icon name="x-circle" />
          </div>
        ) : null}
        <div data-element="upload-overlay" className={styles.overlay}>
          <div
            className={cx(styles.uploadButtonsContainer, {
              [styles.uploadButtonsContainerTop]: uploadButtonPosition === Position.TOP,
            })}>
            {!readOnly ? (
              <UploadHelper
                onUploadFiles={handleUpload}
                multiple={false}
                allowedFileFormats=".png,.jpg,.jpeg">
                <Button leading={<Icon name="upload" />} inversed tier={Tier.SECONDARY}>
                  {tt('choose_photo')}
                </Button>
              </UploadHelper>
            ) : null}
            {onSaveUrl ? (
              <Margin size={{ top: Size.DEFAULT }}>
                <Button
                  leading={<Icon name="link" />}
                  disabled={readOnly}
                  onClick={() => setIsUrlModalVisible(true)}
                  inversed
                  tier={Tier.SECONDARY}>
                  {linkUrlButton ?? tt('paste_video_link')}
                </Button>
              </Margin>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        visible={isUrlModalVisible}
        onClickClose={handleClickCancel}
        title={modalTitle ?? tt('link_to_video')}
        footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button onClick={handleClickCancel} tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Button
                leading={isLoading ? <Spinner size={Size.SMALL} inversed /> : null}
                category={Category.INFO}
                disabled={readOnly}
                onClick={handleSaveLink}>
                {tt('save')}
              </Button>
            </FlexItem>
          </Flex>
        }>
        <div style={{ width: 500 }}>
          <Paragraph>{modalBody ?? tt('find_url')}</Paragraph>
          <FormGroup
            input={
              <Input
                placeholder={modalPlaceholder ?? 'e.g. https://player.vimeo.com/video/12345678'}
                value={linkUrl}
                onChange={(v) => {
                  const url = _isVimeoLink(v as string) ? _vimeoToPlayer(v as string) : String(v);
                  setLinkUrl(url);
                }}
              />
            }
            label={<Label>{tt('link_url')}</Label>}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
