import sv from '@drawbotics/drylus-style-vars';
import { css } from 'emotion';
import React from 'react';
import { useAuth } from '~/utils';

export const IMPERSONATION_BANNER_HEIGHT = '40px';

const styles = {
  bar: css`
    background-color: ${sv.orange};
    height: ${IMPERSONATION_BANNER_HEIGHT};
    text-align: center;
    line-height: 40px;
    width: 100%;
    color: ${sv.white};
  `,
};

export const ImpersonationBanner = () => {
  const { user } = useAuth();
  return <div className={styles.bar}>{`Currently impersonating ${user?.fullName}`}</div>;
};
