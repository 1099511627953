import {
  ButtonLink,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Size,
  Text,
  TextLink,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import clockPlaceholder from '~/pods/product-stats/images/clock-placeholder.svg';
import clock from '~/pods/product-stats/images/clock.svg';
import { StatsData } from '~/pods/product-stats/utils';
import { createTranslate, useMosaicNavigation } from '~/utils';

import { EmptyTile } from '../EmptyTile';
import { ErrorTile } from '../ErrorTile';
import { StatTile } from '../StatTile';
import { LoadingPlaceholderTile } from './LoadingPlaceholderTile';

const tt = createTranslate('pods.product_stats.components.meeting_duration_tile');

interface MeetingDurationTileProps {
  isLoading: boolean;
  duration?: StatsData['medianMeetingDuration'];
}

export const MeetingDurationTile = ({ isLoading, duration }: MeetingDurationTileProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  if (isLoading) {
    return <LoadingPlaceholderTile />;
  }
  if (duration == null) {
    return <ErrorTile message={tt('could_not_load')} />;
  }
  if (duration === 0) {
    return (
      <EmptyTile
        image={clockPlaceholder}
        cta={
          <Link to={getUrlInOrg('/meetings')}>
            <ButtonLink category={Category.INFO}>{tt('schedule_meeting')}</ButtonLink>
          </Link>
        }
        text={
          <Text>
            {tt('use')}&nbsp;
            <a href="https://drawbotics.com/reach" target="_blank" rel="noopener noreferrer">
              <TextLink>Reach</TextLink>
            </a>
            &nbsp;{tt('to_schedule')}
          </Text>
        }
      />
    );
  }

  return (
    <StatTile title={tt('average_duration')}>
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={clock} width={150} />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Title noMargin size={3}>
            {tt('mins', { count: duration })}
          </Title>
        </FlexItem>
      </Flex>
    </StatTile>
  );
};
