import gql from 'fraql';

const Unit = {
  typeName: 'Unit',
  fields: {
    price: {
      dependencies: gql`
        fragment _ on Unit {
          rawPrice: price
          currency
        }
      `,
      resolver: (unit: any) => ({
        value: unit.rawPrice,
        currency: unit.currency,
      }),
    },
    surface: {
      dependencies: gql`
        fragment _ on Unit {
          rawSurface: surface
          surfaceUnit
        }
      `,
      resolver: (unit: any) => ({
        value: unit.rawSurface,
        unit: unit.surfaceUnit,
      }),
    },
  },
};

export default Unit;
