import { ID } from '~/types';

export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface Timeslot {
  id: ID;
  start: string;
  end: string;
}

export type AvailabilityAddress = ID | null | undefined;

export interface AvailabilityDay {
  name: Day;
  isActive: boolean;
  timeslots: Array<Timeslot>;
  address: AvailabilityAddress;
  remote: boolean;
}

export type Availabilities = Array<AvailabilityDay>;

export enum CalendarProvider {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}
