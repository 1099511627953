import sv from '@drawbotics/drylus-style-vars';
import { Shade, Text, Title } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

const styles = {
  banner: css`
    background-color: ${sv.neutralLight};
    border-radius: ${sv.borderRadiusLarge};
    display: flex;
    margin: ${sv.marginSmall} 0;
    padding: ${sv.paddingExtraLarge};
    position: relative;
  `,
  wrapperContent: css`
    width: 460px;
  `,
  text: css`
    line-height: 1.4;
  `,
  image: css`
    bottom: 0;
    right: 0;
    position: absolute;
  `,
};

interface BannerProps {
  title: string;
  description: string;
  imgSource: string;
}

export const Banner = ({ title, description, imgSource }: BannerProps) => {
  return (
    <div className={styles.banner}>
      <div className={styles.wrapperContent}>
        <Title size={4}>{title}</Title>
        <Text className={styles.text} shade={Shade.MEDIUM}>
          {description}
        </Text>
      </div>
      <img className={styles.image} src={imgSource} />
    </div>
  );
};
