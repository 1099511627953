import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Panel,
  PanelBody,
  PanelHeader,
  SearchInput,
  Size,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment, useState } from 'react';

import { CountBox } from '~/components';
import { LicenseCount } from '~/pods/meetings/types';
import { useFetchTeammates } from '~/pods/meetings/utils';
import { OrganisationRole, Subscription } from '~/types';
import { createTranslate, useAuth } from '~/utils';

import { AddTeammatesModal } from './AddTeammatesModal';
import { SalesTeamTable } from './SalesTeamTable';

const tt = createTranslate('pods.marketing_suite.routes.management.components.sales_team_panel');

interface SalesTeamPanelProps {
  licenses: LicenseCount;
  refetchLicenses: VoidFunction;
}

export const SalesTeamPanel = ({ licenses, refetchLicenses }: SalesTeamPanelProps) => {
  const {
    isLoading,
    teammates,
    error,
    refetch: refetchTeammates,
  } = useFetchTeammates({
    licensed: true,
  });
  const [searchValue, setSearchValue] = useState('');
  const [addModalVisible, setAddModalVisible] = useState(false);
  const { user } = useAuth();

  const filteredTeammates = (teammates ?? []).filter(
    (teammate) =>
      teammate.fullName.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      teammate.email.toLowerCase().includes(searchValue.toLocaleLowerCase()),
  );

  const { availableLicenseCount, totalLicenseCount } = licenses;
  const licensesUsed = totalLicenseCount - availableLicenseCount;

  return (
    <Fragment>
      <Panel
        header={
          <PanelHeader>
            <Flex>
              <FlexItem flex>
                <Flex justify={FlexJustify.START}>
                  <FlexItem>
                    <Title noMargin size={4}>
                      {tt('sales_team')}
                    </Title>
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>
                    {user?.organisation?.subscription === Subscription.REACH ? (
                      <CountBox icon="user">
                        <Text tier={Tier.SECONDARY}>{tt('seats_in_use')} </Text>
                        <Text>{tt('x_out_of_y', { x: licensesUsed, y: totalLicenseCount })}</Text>
                      </CountBox>
                    ) : null}
                  </FlexItem>
                </Flex>
              </FlexItem>
              <FlexItem>
                <SearchInput
                  minimal
                  value={searchValue}
                  onChange={setSearchValue}
                  placeholder={tt('search')}
                />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <Button
                  disabled={error != null || user?.role === OrganisationRole.MEMBER}
                  onClick={() => setAddModalVisible(true)}
                  category={Category.INFO}>
                  {tt('add_teammates')}
                </Button>
              </FlexItem>
            </Flex>
          </PanelHeader>
        }
        body={
          <PanelBody noPadding>
            <SalesTeamTable
              isLoading={isLoading}
              teammates={filteredTeammates}
              refetchLicenses={refetchLicenses}
              refetchTeammates={refetchTeammates}
              error={error}
              openAddModal={() => setAddModalVisible(true)}
            />
          </PanelBody>
        }
      />
      <AddTeammatesModal
        refetchTeammates={refetchTeammates}
        refetchLicenses={refetchLicenses}
        visible={addModalVisible}
        onClickClose={() => setAddModalVisible(false)}
        availableLicenseCount={availableLicenseCount}
      />
    </Fragment>
  );
};
