import gql from 'fraql';

import { Lead } from '~/pods/crm/types';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

export interface LeadQueryData {
  lead: Lead;
}

export interface LeadQueryVariables {
  leadId: string;
}

const LeadQuery = gql`
  query LeadQuery($leadId: ID!) {
    lead(id: $leadId) {
      id
      firstName
      lastName
      fullName @computed(type: Lead)
      email
      phoneNumber
      origin: heardFrom
      source
      typologies
      campaignToken
      engagementLevel
      address {
        id
        street
        countryCode
        countryName @computed(type: Address)
        city
        zipCode
        fullAddress @computed(type: Address)
      }
      typologies
      maximumBudget
      minimumBudget
      maximumSurface
      minimumSurface
      surfaceUnit
      purchaseGoal
      unitFeatures
      createdAt
      user {
        id
        firstName
        lastName
        fullName @computed(type: User)
        email
        organisation {
          id
          agency
          name
        }
      }
      step: funnelStep {
        id
        name
        slug
        position
        color
        type
      }
      previousStep: previousFunnelStep {
        id
        name
        slug
        color
        type
      }
      interestedIn: projectInterests {
        project {
          id
          name
          assignedAgencies {
            id
            name
          }
        }
        unitInterests {
          unitId
          unitReference
        }
      }
    }
  }
`;

export function useFetchLead(leadId: string): {
  isLoading: boolean;
  lead?: Lead;
  refetch: VoidFunction;
  error?: ErrorType;
} {
  const res = useMosaicQuery<LeadQueryData, LeadQueryVariables>({
    query: LeadQuery,
    variables: { leadId },
  });

  return {
    isLoading: res.isLoading,
    lead: res.data?.lead,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
