import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { FiscalSimulator } from '../types';

const FiscalSimulatorQuery = gql`
  query FiscalSimulatorQuery($slug: String!) {
    project(slug: $slug) {
      additionalFees {
        id
        coefficient
        reference
      }
      taxes {
        id
        coefficient
        reference
        unitDistribution
      }
      costSimulatorDisclaimer
    }
  }
`;

export function useFetchSimulatorData(publicToken: string): {
  isLoading: boolean;
  data?: FiscalSimulator;
  refetch: VoidFunction;
  error?: ErrorType;
} {
  const res = useMosaicQuery<{ project: FiscalSimulator }, { slug: string }>({
    query: FiscalSimulatorQuery,
    variables: { slug: publicToken },
  });

  return {
    isLoading: res.isLoading,
    data: res.data?.project,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
