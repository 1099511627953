import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  FormGroup,
  Hint,
  Icon,
  Input,
  Label,
  ListTile,
  Margin,
  Paragraph,
  Shade,
  Size,
  Spinner,
  Text,
  TextArea,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import React, { useEffect, useState } from 'react';
import urlJoin from 'url-join';

import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

import { Website } from '../api/domain';
import { OpenGraphPreview } from './OpenGraphPreview';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const MOSAIC_HOST = process.env.MOSAIC_HOST;

interface OpenGraphPanelProps {
  website: Website;
  onChange: (data: Partial<Website>) => void;
  onChangeAttachment: (signedBlobId: string) => void;
}

export const OpenGraphPanel = ({ website, onChange, onChangeAttachment }: OpenGraphPanelProps) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [isUploading, setIsUploading] = useState(false);
  const form = useForm({
    openGraphTitle: website.openGraphTitle,
    openGraphDescription: website.openGraphDescription,
  });

  const { uploadFiles } = useFileUpload(useMosaicMutation);

  useEffect(() => {
    clearTimeout(timeoutId!);

    const newTimeoutId = setTimeout(() => {
      if (form.values.openGraphTitle !== website.openGraphTitle) {
        onChange({ openGraphTitle: form.values.openGraphTitle });
      }

      if (form.values.openGraphDescription !== website.openGraphDescription) {
        onChange({ openGraphDescription: form.values.openGraphDescription });
      }
    }, 2000);

    setTimeoutId(newTimeoutId);
  }, [form.values.openGraphTitle, form.values.openGraphDescription]);

  const handleUpload = async (fileList: FileList) => {
    setIsUploading(true);

    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;

    if (validFiles.length > 0) {
      await onChangeAttachment(validFiles[0].signedBlobId!);
    }

    setIsUploading(false);
  };

  return (
    <Flex align={FlexAlign.START}>
      <FlexItem flex={0.55}>
        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
          <Label>{tt('og_image')}</Label>
        </Margin>
        <ListTile
          title={
            <Text size={Size.SMALL} shade={Shade.MEDIUM}>
              {tt('og_image_description')}
            </Text>
          }
          leading={
            <UploadHelper allowedFileFormats=".jpg, .jpeg, .png" onUploadFiles={handleUpload}>
              <Button
                leading={
                  isUploading ? <Spinner size={Size.SMALL} inversed /> : <Icon name="upload" />
                }
                category={Category.INFO}
                size={Size.SMALL}>
                {tt('upload')}
              </Button>
            </UploadHelper>
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('title_tag')}</Label>}
          input={
            <Input
              name="openGraphTitle"
              placeholder={tt('title_tag_placeholder')}
              value={form.get}
              onChange={(v) => form.set(String(v) ?? '', 'openGraphTitle')}
            />
          }
        />
        <Hint>{tt('meta_title_hint')}</Hint>
        <Margin size={{ top: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('meta_description')}</Label>}
          input={
            <TextArea
              name="openGraphDescription"
              placeholder={tt('meta_desc_placeholder')}
              value={form.get}
              onChange={(v) => form.set(String(v) ?? '', 'openGraphDescription')}
            />
          }
        />
        <Hint>{tt('meta_description_hint')}</Hint>
      </FlexItem>
      <FlexSpacer size={Size.DEFAULT} />
      <FlexItem flex>
        <OpenGraphPreview
          title={website.openGraphTitle}
          description={website.openGraphDescription}
          domain={urlJoin(MOSAIC_HOST, 'projects', website.identifier).replace('https://', '')}
          image={website.openGraphAttachment?.url}
        />
        <Paragraph>
          <Text shade={Shade.LIGHT}>{tt('og_desc')}</Text>
        </Paragraph>
      </FlexItem>
    </Flex>
  );
};
