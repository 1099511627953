import { Module } from '@tokamakjs/react';

import { ListingApi } from './api';
import { FiltersService, ListingService, RouterService } from './services';
import { ListingStore } from './stores';

@Module({
  providers: [RouterService, ListingApi, ListingService, ListingStore, FiltersService],
})
export class ListingManagerModule {}
