import sv from '@drawbotics/drylus-style-vars';
import {
  Banner,
  Button,
  Category,
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  Padding,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  PanelSection,
  Size,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import { css, cx } from 'emotion';
import LogRocket from 'logrocket';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ErrorPlaceholder, MosaicPageTitle } from '~/components';
import { Lead, SortBy, UnitFeature } from '~/pods/crm/types';
import {
  useFetchLeadCount,
  useFetchLeads,
  useFetchOrganisation,
  useFetchUsers,
} from '~/pods/crm/utils';
import { CreateAndEditMeetingModal } from '~/pods/meetings/components';
import { createTranslate, useAuth, useDebounce, useMosaicNavigation } from '~/utils';

import { LeadsQueryFilters } from '../../utils';
import { LogModal } from '../Lead/components';
import { LeadsFilterBar, LeadsTable, LeadsTableActions, LeadsTableHeader } from './components';

const tt = createTranslate('pods.crm.routes.leads');

const styles = {
  panelFooter: css`
    padding: ${sv.paddingExtraSmall} ${sv.paddingSmall};
  `,
};

const LEADS_PER_PAGE = 100;

export const Leads = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectToken } = useMosaicNavigation();
  const { user } = useAuth();

  const [selectedLeads, setSelectedLeads] = useState<Array<string>>([]);
  const [querySearchTerm, setQuerySearchTerm] = useState<string>();
  const [newMeetingLead, setNewMeetingLead] = useState<Lead>();
  const [newLogLead, setNewLogLead] = useState<Lead>();

  const { page, sortBy, searchTerm, ...filters } = useFilters<
    | 'projects'
    | 'assignedTo'
    | 'organisations'
    | 'status'
    | 'unitTypes'
    | 'perks'
    | 'purchaseGoal'
    | 'sources'
    | 'budget'
    | 'surface'
    | 'page'
    | 'sortBy'
    | 'searchTerm'
    | 'marketingCampaign'
    | 'engagementLevel'
    | 'portalSlugs'
  >(
    location,
    navigate,
    [
      'projects',
      'assignedTo',
      'organisations',
      'status',
      'unitTypes',
      'perks',
      'purchaseGoal',
      'sources',
      'budget',
      'surface',
      'page',
      'sortBy',
      'searchTerm',
      'marketingCampaign',
      'engagementLevel',
      'portalSlugs',
    ],
    {
      persistenceKey: `${user?.id}:crm`,
    },
  );
  const debouncedSearchTerm = useDebounce(searchTerm.value, 500);
  const { isLoading: isOrganisationLoading, organisation } = useFetchOrganisation();

  // default to page 1 when filters change to make sure page is OK for new results
  useEffect(() => page.set('1'), [JSON.stringify(filters)]);

  useEffect(() => {
    if (organisation?.projects != null && filters.projects.value == null && projectToken != null) {
      const activeProject = organisation?.projects?.find(
        (project) => project.publicToken === projectToken,
      );
      if (activeProject != null) {
        filters.projects.set([activeProject.id]);
      }
    }
    // todo: No need to watch `filters` once the useFilters library is fixed
  }, [organisation == null, JSON.stringify(filters)]);

  useEffect(() => {
    if (debouncedSearchTerm != null && debouncedSearchTerm !== querySearchTerm) {
      setQuerySearchTerm(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const currentPage = page.value != null ? Number(page.value) : 1;

  const {
    projects: projectsFilter,
    assignedTo,
    organisations,
    status,
    unitTypes,
    sources,
    purchaseGoal,
    budget,
    surface,
    perks,
    marketingCampaign,
    engagementLevel,
    portalSlugs,
  } = filters;

  const leadsQueryFilters: LeadsQueryFilters = {
    projectIds: projectsFilter.values,
    organisationIds: organisations.values,
    userIds: assignedTo.values,
    funnelStepIds: status.values,
    typologies: unitTypes.values?.map(Number),
    sources: sources.values,
    purchaseGoal: purchaseGoal.values,
    minimumBudget: budget.values?.[0] ? Number(budget.values?.[0]) : undefined,
    maximumBudget: budget.values?.[1] ? Number(budget.values?.[1]) : undefined,
    minimumSurface: surface.values?.[0] ? Number(surface.values?.[0]) : undefined,
    maximumSurface: surface.values?.[1] ? Number(surface.values?.[1]) : undefined,
    unitFeatures: perks.value as UnitFeature[] | undefined,
    campaignTokens: marketingCampaign.values,
    engagementLevel: engagementLevel.values,
    sortBy: sortBy.value as SortBy | undefined,
    searchTerm: querySearchTerm == null || querySearchTerm === '' ? undefined : querySearchTerm,
    limit: LEADS_PER_PAGE,
    offset: (currentPage - 1) * LEADS_PER_PAGE,
    portalSlugs: portalSlugs.values ?? undefined,
  };

  const { isLoading: isLeadsCountLoading, leadCount, refetchLeadsCount } = useFetchLeadCount(
    leadsQueryFilters,
    organisation?.steps,
  );
  const {
    isLoading: isLeadsLoading,
    leads,
    error: leadsError,
    refetchLeads
  } = useFetchLeads(leadsQueryFilters);
  const { isUsersLoading, users } = useFetchUsers();

  const totalPages = Math.ceil((leadCount?.ownedLeadCount ?? LEADS_PER_PAGE) / LEADS_PER_PAGE);
  const toggleLeadSelection = (id: string) => {
    if (!selectedLeads.includes(id)) {
      setSelectedLeads([...selectedLeads, id]);
    } else {
      setSelectedLeads([...selectedLeads.filter((leadId) => leadId !== id)]);
    }
  };

  const toggleAllLeadsSelection = () => {
    if (selectedLeads.length === 0) {
      setSelectedLeads((leads ?? []).map((lead) => lead.id));
    } else {
      setSelectedLeads([]);
    }
  };

  const unassignedStatus = organisation?.steps?.find((step) => step.slug === 'not_assigned');

  if (unassignedStatus == null) {
    LogRocket.captureMessage(
      "Couldn't find a step in this organisation equivalent to `not_assigned`",
    );
  }

  const handleRefetchLeads = () => {
    refetchLeads()
    refetchLeadsCount()
  }

  return (
    <Fragment>
      <Content fullHeight>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <Margin size={{ bottom: Size.EXTRA_SMALL }}>
            {leadsError == null && organisation != null ? (
              <Fragment>
                <LeadsTableHeader
                  title={tt('leads')}
                  searchTerm={searchTerm}
                  leadCount={leadCount}
                  isLoading={isLeadsCountLoading}
                  filters={leadsQueryFilters}
                />
                {leadCount?.unassignedCount !== 0 &&
                  leadCount?.unassignedCount != null &&
                  unassignedStatus?.id &&
                  !(status.values?.length === 1 && status.values?.includes(unassignedStatus?.id)) ? (
                  <Banner
                    category={Category.INFO}
                    trailing={
                      <Button onClick={() => status.set(unassignedStatus?.id ?? '')}>
                        {tt('filter_unassigned')}
                      </Button>
                    }>
                    {tt('unassigned_leads', { count: leadCount?.unassignedCount })}
                  </Banner>
                ) : null}
                <Margin size={{ vertical: Size.DEFAULT }}>
                  <Flex justify={FlexJustify.SPACE_BETWEEN}>
                    <FlexItem>
                      <LeadsTableActions
                        allLeads={selectedLeads.length === leads?.length}
                        selectedLeads={selectedLeads}
                        resetLeads={() => setSelectedLeads([])}
                        users={users}
                        isLoading={isUsersLoading}
                        projects={organisation.projects}
                      />
                    </FlexItem>
                    <FlexItem>
                      <LeadsFilterBar
                        isLoading={isOrganisationLoading}
                        organisation={organisation}
                        filters={filters}
                        leadCount={leadCount}
                        projects={organisation.projects}
                        portals={organisation.portals}
                      />
                    </FlexItem>
                  </Flex>
                </Margin>
              </Fragment>
            ) : (
              <MosaicPageTitle>{tt('leads')}</MosaicPageTitle>
            )}
          </Margin>
          {leadsError != null ? (
            <ErrorPlaceholder error={leadsError} />
          ) : (
            <Panel
              body={
                <PanelBody noPadding>
                  <PanelSection>
                    <LeadsTable
                      isLoading={isLeadsLoading || isLeadsCountLoading}
                      leads={leads}
                      loadingRows={LEADS_PER_PAGE}
                      selectedLeads={selectedLeads}
                      toggleAllLeadsSelection={toggleAllLeadsSelection}
                      toggleLeadSelection={toggleLeadSelection}
                      sortBy={sortBy}
                      onClickNewMeeting={(lead: Lead) => setNewMeetingLead(lead)}
                      onClickNewLog={(lead: Lead) => setNewLogLead(lead)}
                      refetchLeads={handleRefetchLeads}
                    />
                  </PanelSection>
                </PanelBody>
              }
              footer={
                <PanelFooter className={cx(styles.panelFooter)}>
                  <Flex justify={FlexJustify.END}>
                    <FlexItem>
                      <Pagination
                        pages={totalPages}
                        value={currentPage}
                        onChange={(number) => page.set(`${number}`)}
                      />
                    </FlexItem>
                  </Flex>
                </PanelFooter>
              }
            />
          )}
        </Padding>
      </Content>
      <CreateAndEditMeetingModal
        isVisible={newMeetingLead != null}
        lead={newMeetingLead}
        onClose={() => setNewMeetingLead(undefined)}
      />
      <LogModal
        visible={newLogLead != null}
        leadId={newLogLead?.id ?? ''}
        mode={'create'}
        onClickClose={() => setNewLogLead(undefined)}
      />
    </Fragment>
  );
};
