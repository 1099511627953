import {
  Dropdown,
  DropdownOption,
  DropdownSeparator,
  Position,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import React, { CSSProperties, ReactElement } from 'react';

import {
  EngagementLevelCold,
  EngagementLevelDefault,
  EngagementLevelHot,
  EngagementLevelMedium,
} from '~/components';
import { EngagementLevelEnum } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.lead.components.lead_details.engagement_level');

interface EngagementLevelIndicatorProps {
  size: number;
  inversed?: boolean;
  style?: CSSProperties;
  engagementLevel?: EngagementLevelEnum;
}

export const EngagementLevelIndicator = ({
  engagementLevel,
  size,
  style,
  inversed = false,
}: EngagementLevelIndicatorProps) => {
  const getIcon = (): ReactElement => {
    switch (engagementLevel) {
      case EngagementLevelEnum.HOT:
        return <EngagementLevelHot inversed={inversed} size={size} style={style} />;
      case EngagementLevelEnum.MEDIUM:
        return <EngagementLevelMedium inversed={inversed} size={size} style={style} />;
      case EngagementLevelEnum.COLD:
        return <EngagementLevelCold inversed={inversed} size={size} style={style} />;
      default:
        return <EngagementLevelDefault size={size} style={style} />;
    }
  };

  return getIcon();
};

interface EngagementLevelProps {
  size?: number;
  isLoading: boolean;
  engagementLevel?: EngagementLevelEnum;
  onChangeEngagement: (engagement: EngagementLevelEnum | null) => void;
  className?: string
  smallSpinner?: boolean
}

export const EngagementLevel = ({
  engagementLevel,
  onChangeEngagement,
  isLoading,
  size = 38,
  className,
  smallSpinner
}: EngagementLevelProps) => {
  return (
    <Dropdown
      className={className}
      side={Position.RIGHT}
      trigger={
        isLoading ? (
          <Spinner size={smallSpinner ? Size.SMALL : Size.LARGE} />
        ) : (
          <EngagementLevelIndicator engagementLevel={engagementLevel} size={size} />
        )
      }>
      <DropdownOption
        text={tt('hot_lead')}
        leading={<EngagementLevelHot inversed size={20} />}
        onClick={() => onChangeEngagement(EngagementLevelEnum.HOT)}
      />
      <DropdownOption
        text={tt('medium_lead')}
        leading={<EngagementLevelMedium inversed size={20} />}
        onClick={() => onChangeEngagement(EngagementLevelEnum.MEDIUM)}
      />
      <DropdownOption
        text={tt('cold_lead')}
        leading={<EngagementLevelCold inversed size={20} />}
        onClick={() => onChangeEngagement(EngagementLevelEnum.COLD)}
      />
      <DropdownSeparator />
      <DropdownOption text={tt('reset')} onClick={() => onChangeEngagement(null)} />
    </Dropdown>
  );
};
