import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem } from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React from 'react';

import { AssetTile } from '~/components';
import { ID } from '~/types';
// import { useUpdateWebsiteAsset } from '../../../utils';
import { createTranslate, useMosaicMutation } from '~/utils';

import { AnyWebsiteSectionData, AssetData, MainSection } from '../../../api/domain';
import { ControlBracket } from '../ControlBracket';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const styles = {
  container: css`
    padding: 0px ${sv.paddingMassive};
    margin-top: ${sv.marginSmall};
    position: relative;
  `,
  controls: css`
    position: absolute;
    height: 100%;
    right: ${sv.defaultMargin};
  `,
  main: css`
    height: 450px;
  `,
  inactive: css`
    opacity: 0.4;
  `,
};

interface MainSectionProps {
  main: MainSection;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAsset: (sectionId: string, data: AssetData) => void;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Main = ({
  main,
  onChange,
  onChangeAsset,
  onIncrement,
  onDecrement,
}: MainSectionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <ControlBracket
          sectionId={main.id}
          onToggleVisibility={() => onChange(main.id, { active: !main.isActive })}
          isActive={main.isActive}
          onIncrement={() => onIncrement(main.id)}
          onDecrement={() => onDecrement(main.id)}
        />
      </div>
      <div className={cx(styles.main, { [styles.inactive]: !main.isActive })}>
        <Flex style={{ height: '100%', width: '100%' }}>
          <FlexItem style={{ height: '100%', width: '100%' }}>
            <AssetTile
              mutationTargetType={useMosaicMutation}
              asset={main.assets[0] as any}
              text={{
                linkUrlButton: tt('main_modal_button'),
                modalTitle: tt('main_modal_title'),
                modalBody: tt('main_modal_body'),
                modalPlaceholder: tt('main_modal_placeholder'),
              }}
              onSaveUrl={(url) => onChangeAsset(main.id, { assetId: main.assets[0]?.assetId, url })}
              onFinishUpload={(asset) =>
                onChangeAsset(main.id, {
                  assetId: main.assets[0]?.assetId,
                  signedBlobId: asset.signedBlobId,
                })
              }
            />
          </FlexItem>
        </Flex>
      </div>
    </div>
  );
};
