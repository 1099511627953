import { Button, Category, EmptyState, Panel, PanelBody } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate, useIntercom } from '~/utils';

const tt = createTranslate(
  'pods.presentation.routes.presentation.components.presentation_placeholder',
);

export const PresentationPlaceholder = () => {
  const { showIntercom } = useIntercom();
  return (
    <Panel
      body={
        <PanelBody>
          <EmptyState title={tt('title')} description={tt('description')}>
            <Button category={Category.INFO} onClick={showIntercom}>
              {tt('contact_us')}
            </Button>
          </EmptyState>
        </PanelBody>
      }
    />
  );
};
