import { TargetDomain, getJwt, getLatestJwt, getRefreshToken, refreshJwt } from '@drawbotics/auth';
import { Operation, makeOperation } from '@urql/core';
import dayjs from 'dayjs';

import { decodeUser } from './decode-user';
import { logout } from './hooks';
import { clearTokens } from './tokens';

interface AuthState {
  token: string;
  refreshToken: string;
}

async function _logout() {
  const token = getJwt();
  const user = token == null ? null : decodeUser(token);
  if (user?.impersonation == null) {
    await logout();
  }
  clearTokens();
  // avoid flickering
  if (!location.pathname.includes('/auth/login')) {
    location.replace('/auth/login');
  }
}

export function willAuthError({ authState }: { authState: AuthState | null }): boolean {
  if (authState == null) {
    return true;
  }

  const decodedUser = decodeUser(authState.token);
  const isExpired = decodedUser ? dayjs.unix(decodedUser.exp).isSameOrBefore(dayjs()) : true;

  return isExpired;
}

export async function getAuth({
  authState,
}: {
  authState: AuthState | null;
}): Promise<AuthState | null> {
  // for initial launch, fetch the auth state from storage (local storage, async storage etc)
  if (authState == null) {
    const token = await getLatestJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC);
    const refreshToken = getRefreshToken();
    if (token != null && refreshToken != null) {
      const user = decodeUser(token);
      if (user?.domain !== TargetDomain.MOSAIC) {
        _logout();
      }

      return { token, refreshToken };
    }
    if (token == null && refreshToken != null) {
      // If refresh token is null we should log out
      _logout();
    }
    return null;
  }

  /**
   * the following code gets executed when an auth error has occurred
   * we should refresh the token if possible and return a new auth state
   * If refresh fails, we should log out
   **/

  const result = await refreshJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC);
  if (result != null) {
    return {
      ...authState,
      token: result,
    };
  }

  _logout();

  return null;
}

export function addAuthToOperation({
  authState,
  operation,
}: {
  authState: AuthState;
  operation: Operation;
}): Operation {
  // the token isn't in the auth state, return the operation without changes
  if (authState == null || authState.token == null) {
    return operation;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer ${authState.token}`,
      },
    },
  });
}
