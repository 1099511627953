// @ts-nocheck
import { z } from 'zod';

import { CurrencyCode, SurfaceUnitEnum } from '~/types';

import { UnitSchema } from './unit';

export const ListingSchema = z.object({
  id: z.string(),
  providerName: z
    .string()
    .nullable()
    .transform((v) => (v == null ? undefined : v)),
  name: z.string(),
  currency: z.nativeEnum(CurrencyCode),
  surfaceUnit: z.nativeEnum(SurfaceUnitEnum),
  units: z.array(UnitSchema),
});
