import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import { useEffect } from 'react';

import {
  Address,
  EngagementLevelEnum,
  InterestType,
  Lead,
  LeadOrigin,
  PurchaseGoal,
  UnitFeature,
} from '~/pods/crm/types';
import { createTranslate, extractGQLErrorMessages } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

const tt = createTranslate('pods.crm.utils.hooks.update_lead');

interface UpdateLeadVariables {
  lead: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    minimumBudget?: number;
    maximumBudget?: number;
    minimumSurface?: number;
    maximumSurface?: number;
    unitFeatures?: Array<UnitFeature>;
    purchaseGoal?: PurchaseGoal;
    typologies?: Array<number>;
    engagementLevel?: EngagementLevelEnum | null;
    address?: {
      zipCode?: Address['zipCode'];
      city: Address['city'];
      countryCode: Address['countryCode'];
      street: Address['street'];
    };
    unitIds?: Array<string>;
    heardFrom?: LeadOrigin;
    interests?: Array<InterestType>;
  };
}

interface UpdateLeadResult {
  updateLead: {
    lead: Lead;
  };
}

const updateLeadMutation = gql`
  mutation UpdateLead($lead: UpdateLeadInput!) {
    updateLead(input: $lead) {
      lead {
        id
        firstName
        lastName
        email
        phoneNumber
        minimumBudget
        maximumBudget
        minimumSurface
        maximumSurface
        unitFeatures
        purchaseGoal
        origin: heardFrom
        engagementLevel
        address {
          id
          street
          countryCode
          countryName @computed(type: Address)
          city
          zipCode
          fullAddress @computed(type: Address)
        }
        projectInterests {
          project {
            id
            name
          }
          unitInterests {
            unitId
            unitReference
          }
        }
        typologies
      }
    }
  }
`;

export function useUpdateLead() {
  const { res, executeMutation: updateLead } = useMosaicMutation<
    UpdateLeadResult,
    UpdateLeadVariables
  >(updateLeadMutation);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!res.fetching && res.error == null && res.data != null) {
      showAlert({ text: tt('success'), category: Category.SUCCESS });
    } else if (!res.fetching && res.error != null) {
      const { message } = extractGQLErrorMessages(res.error);
      const text = message ?? tt('error');
      // TODO translate: 'invalid_email', 'not_found', 'unauthorized'
      showAlert({ text, category: Category.DANGER });
    }
  }, [res.fetching]);

  return {
    isLoading: res.fetching,
    updateLead: (lead: UpdateLeadVariables['lead']) => updateLead({ lead }),
  };
}
