import { Text } from '@drawbotics/react-drylus';
import React from 'react';

import championsPlaceholder from '~/pods/product-stats/images/monthly-placeholder.svg';
import { createTranslate } from '~/utils';

import { EmptyTile } from './EmptyTile';

const tt = createTranslate('pods.product_stats.components.monthly_champions_tile');

export const MonthlyChampionsTile = () => {
  return (
    <EmptyTile
      title={tt('monthly_champions')}
      comingSoon
      image={championsPlaceholder}
      text={<Text>{tt('motivate_team')}</Text>}
    />
  );
};
