import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { Lead } from './Lead';
import { Leads } from './Leads';
import { NewLead } from './NewLead';

export const CRMRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<Leads />} />
      <Route path="/new" element={<NewLead />} />
      <Route path="/:leadId" element={<Lead />} />
    </RoutesWithNotFound>
  );
};
