import sv from '@drawbotics/drylus-style-vars';
import {
  BigRadio,
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Hint,
  Icon,
  ListTile,
  Margin,
  Padding,
  Separator,
  Size,
  Spinner,
  Text,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { TabMenu, TabMenuItem } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';

import { Thumbnail } from '~/components';
import { HexInputType } from '~/pods/settings/components';
import { HexInput } from '~/pods/settings/components';
import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

import { Website } from '../api/domain';
import lux from '../images/lux.png';
import min from '../images/minimal.png';
import trad from '../images/trad.png';
import { Theme } from '../types';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const styles = {
  toolbar: css`
    background: #fff;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: ${sv.elevation2};
  `,
  toolbarTabMenu: css`
    background: ${sv.neutralLighter};
  `,
  toolbarTab: css`
    flex: 1;
    justify-content: center;
  `,
  content: css`
    overflow: auto;
    flex: 1;
    min-height: 0;
  `,
};

interface WebsiteEditorSidebarProps {
  website: Website;
  onChange: (data: Partial<Website>) => void;
  onChangeLogo: (signedBlobId: string) => void;
}

export const WebsiteEditorSidebar = ({
  website,
  onChange,
  onChangeLogo,
}: WebsiteEditorSidebarProps) => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState(website.template);
  const [activeUploads, setCurrentUpload] = useState({ logo: false, favicon: false });

  const { uploadFiles } = useFileUpload(useMosaicMutation);

  const _handleChangeTheme = (theme: Theme) => {
    setSelectedTheme(theme);
    onChange({ template: theme });
  };

  const handleUpload = async (fileList: FileList) => {
    setCurrentUpload({ ...activeUploads, logo: true });
    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;

    if (validFiles.length > 0) {
      const { signedBlobId } = validFiles[0];

      if (signedBlobId == null) return;

      // TODO handle errors
      onChangeLogo(signedBlobId);
      setCurrentUpload({ ...activeUploads, logo: false });
    }
  };

  return (
    <div className={styles.toolbar}>
      <TabMenu className={styles.toolbarTabMenu}>
        <TabMenuItem
          className={styles.toolbarTab}
          active={activeMenu === 0}
          text="Theme"
          onClick={() => setActiveMenu(0)}
        />
        <TabMenuItem
          className={styles.toolbarTab}
          active={activeMenu === 1}
          text="Brand"
          onClick={() => setActiveMenu(1)}
        />
      </TabMenu>
      <div className={styles.content}>
        {activeMenu === 0 ? (
          <Padding size={{ horizontal: Size.LARGE }}>
            <Margin size={{ vertical: Size.LARGE }}>
              <Text>Pick a theme for your site and customize it.</Text>
            </Margin>
            <BigRadio
              checked={selectedTheme === Theme.TRADITIONAL}
              label="Traditional"
              onChange={() => _handleChangeTheme(Theme.TRADITIONAL)}
              value={Theme.TRADITIONAL}>
              <img src={trad} />
            </BigRadio>
            <Margin size={{ vertical: Size.DEFAULT }} />
            <BigRadio
              checked={selectedTheme === Theme.LUXURIOUS}
              label="Luxurious"
              onChange={() => _handleChangeTheme(Theme.LUXURIOUS)}
              value={Theme.LUXURIOUS}>
              <img src={lux} />
            </BigRadio>
            <Margin size={{ vertical: Size.DEFAULT }} />
            <BigRadio
              checked={selectedTheme === Theme.MINIMALIST}
              label="Minimalist"
              onChange={() => _handleChangeTheme(Theme.MINIMALIST)}
              value={Theme.MINIMALIST}>
              <img src={min} />
            </BigRadio>
            <Margin size={{ vertical: Size.DEFAULT }} />
          </Padding>
        ) : (
          <Padding size={{ horizontal: Size.LARGE }} style={{ height: '100%' }}>
            <Margin size={{ vertical: Size.DEFAULT }}>
              <Text size={Size.LARGE}>Brand colors</Text>
            </Margin>
            <Flex justify={FlexJustify.START}>
              <FlexItem>
                <Flex justify={FlexJustify.START}>
                  <FlexItem>
                    <HexInput
                      type={HexInputType.MINIMAL}
                      value={website.theme.colors.primary}
                      onChange={(val) => onChange({ primaryColor: val })}
                    />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>
                    <Text>Primary</Text>
                  </FlexItem>
                </Flex>
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem>
                <Flex justify={FlexJustify.START}>
                  <FlexItem>
                    <HexInput
                      type={HexInputType.MINIMAL}
                      value={website.theme.colors.secondary}
                      onChange={(val) => onChange({ secondaryColor: val })}
                    />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>
                    <Text>Secondary</Text>
                  </FlexItem>
                </Flex>
              </FlexItem>
            </Flex>
            <Margin size={{ vertical: Size.DEFAULT }}>
              <Separator />
            </Margin>
            <Margin size={{ vertical: Size.DEFAULT }}>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>Logo</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.START}>
                <FlexItem>
                  <Thumbnail image={website.logo?.url} style={{ width: 100, height: 80 }} />
                </FlexItem>
                <FlexSpacer size={Size.DEFAULT} />
                <FlexItem>
                  <ListTile
                    title={
                      <UploadHelper
                        allowedFileFormats=".jpg, .jpeg, .png, .svg"
                        onUploadFiles={(files) => handleUpload(files)}>
                        <Button
                          category={Category.INFO}
                          leading={<Icon name="upload" />}
                          size={Size.SMALL}>
                          {tt('upload')}
                        </Button>
                      </UploadHelper>
                    }
                    trailing={activeUploads.logo ? <Spinner size={Size.SMALL} /> : null}
                  />
                </FlexItem>
              </Flex>
            </Margin>
            <Margin size={{ vertical: Size.SMALL }}>
              <Hint>{tt('logo_hint')}</Hint>
            </Margin>
          </Padding>
        )}
      </div>
    </div>
  );
};
