import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';
import { AssetSchema } from '../asset';
import { LinkSchema } from '../link';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const HeroSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.HERO),
  title: ze.optional(z.string()),
  description: ze.optional(z.string()),
  tagline: ze.optional(z.string()),
  assets: z.array(AssetSchema),
  link: LinkSchema,
  position: z.number(),
});

export type HeroSectionData = z.infer<typeof HeroSectionSchema>;

export class HeroSection extends ze.ClassFrom(HeroSectionSchema) {
  public static fromData(data: HeroSectionData): HeroSection {
    return ze.validate(data, HeroSection);
  }

  public toData(): HeroSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
