import {
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Margin,
  Modal,
  Padding,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React, { Fragment, useState } from 'react';
import { gql } from 'urql';

import { Id, OrganisationRole } from '~/types';
import { createTranslate, useMosaicMutation } from '~/utils';

import RemoveTeamMember from '../../../images/RemoveTeamMember.svg';

export interface DeactivateUserMutationData {
  user: {
    id: Id;
    role: OrganisationRole;
    deactivatedAt: string;
  };
}

export interface DeactivateUserMutationVariables {
  input: {
    userId: Id;
  };
}

const DeactivateUserMutation = gql`
  mutation DeactivateUser($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      user {
        id
        role
        deactivatedAt
      }
    }
  }
`;

const tt = createTranslate(
  'pods.settings.routes.company_team.components.team_table.remove_team_member_modal',
);

export const RemoveTeamMemberModal = (props: {
  member: undefined | { fullName: string; id: Id };
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [wasASuccess, setWasASuccess] = useState(false);

  const {
    executeMutation: deactivateUser,
    res: { fetching: isDeactivatingUser },
  } = useMosaicMutation<DeactivateUserMutationData, DeactivateUserMutationVariables>(
    DeactivateUserMutation,
  );

  const handleClose = () => {
    setWasASuccess(false);
    props.onClose();
  };

  return (
    <Modal
      onClickClose={handleClose}
      visible={Boolean(props.member)}
      title={tt('title')}
      footer={
        wasASuccess ? null : (
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button onClick={handleClose} tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                onClick={async () => {
                  const res = await deactivateUser({
                    input: { userId: props.member?.id! },
                  });

                  if (!res.error) {
                    setWasASuccess(true);
                    props.onSuccess();
                  }
                }}
                disabled={isDeactivatingUser}
                leading={isDeactivatingUser ? <Spinner inversed /> : null}
                category={Category.DANGER}>
                {tt('remove')}
              </Button>
            </FlexItem>
          </Flex>
        )
      }>
      {wasASuccess ? (
        <Padding>
          <Flex direction={FlexDirection.VERTICAL} justify={FlexJustify.CENTER}>
            <FlexItem>
              <img src={RemoveTeamMember} />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              {props.member ? (
                <Text>{tt('success_message', { member: props.member.fullName! })}</Text>
              ) : null}
            </FlexItem>
          </Flex>
        </Padding>
      ) : props.member ? (
        <Fragment>
          <Text>{tt('body', { member: props.member.fullName! })}</Text>
          <Margin size={{ top: Size.DEFAULT }}>
            <Text>{tt('body_question')}</Text>
          </Margin>
        </Fragment>
      ) : null}
    </Modal>
  );
};
