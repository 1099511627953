import React, { Fragment } from 'react';
import { useLocation } from 'react-router';

import { useFetchProjects } from '~/pods/dashboard/utils';
import { createTranslate, useAuth, useMosaicNavigation } from '~/utils';

import { BaseSidebar, NavigationCategory, NavigationItem, SubNavigationItem } from './BaseSidebar';
import { ContextSwitcher } from './ContextSwitcher';

const tt = createTranslate('components.sidebar');

const EMPTY_PROJECT_FILTERS = {};

interface OrganisationNavigationProps {
  isAgency: boolean;
}

const OrganisationNavigation = ({ isAgency }: OrganisationNavigationProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  return (
    <Fragment>
      <NavigationItem isHidden={isAgency} icon="compass" to={getUrlInOrg()}>
        {tt('dashboard')}
      </NavigationItem>
      <NavigationItem icon="users" matchNested to={getUrlInOrg('/crm')}>
        {tt('leads')}
      </NavigationItem>
      <NavigationItem icon="calendar" matchNested to={getUrlInOrg('/meetings')}>
        {tt('meetings')}
      </NavigationItem>
      <NavigationCategory isHidden={isAgency} icon="presentation" label={tt('presentations')}>
        <SubNavigationItem to={getUrlInOrg('/meetings/presentations')}>
          {tt('presentations_index')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInOrg('/meetings/company-overview')}>
          {tt('company_overview')}
        </SubNavigationItem>
      </NavigationCategory>
      <NavigationItem isHidden={isAgency} icon="reports" to={getUrlInOrg('/stats')}>
        {tt('stats')}
      </NavigationItem>
      <NavigationCategory isHidden={isAgency} label={tt('settings')} icon="settings">
        <SubNavigationItem to={getUrlInOrg('/settings/basic-info')}>
          {tt('general')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInOrg('/settings/branding')}>{tt('brand')}</SubNavigationItem>
        <SubNavigationItem to={getUrlInOrg('/settings/company-team')}>
          {tt('team')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInOrg('/meetings/management')}>
          {tt('licenses')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInOrg('/settings/agencies')}>
          {tt('agencies')}
        </SubNavigationItem>
      </NavigationCategory>
    </Fragment>
  );
};

interface ProjectNavigationProps {
  isAgency: boolean;
}

const ProjectNavigation = ({ isAgency }: ProjectNavigationProps) => {
  const { getUrlInProject } = useMosaicNavigation();

  return (
    <Fragment>
      <NavigationItem isHidden={isAgency} icon="compass" to={getUrlInProject()}>
        {tt('dashboard')}
      </NavigationItem>
      <NavigationItem icon="clipboard" to={getUrlInProject('/listing')}>
        {tt('listing')}
      </NavigationItem>
      <NavigationItem icon="users" to={getUrlInProject('/crm')}>
        {tt('leads')}
      </NavigationItem>
      <NavigationItem isHidden={isAgency} icon="website" to={getUrlInProject('/website')}>
        {tt('project_website')}
      </NavigationItem>
      <NavigationItem isHidden={isAgency} icon="presentation" to={getUrlInProject('/presentation')}>
        {tt('presentation')}
      </NavigationItem>
      <NavigationItem
        isHidden={isAgency}
        icon="money-bag"
        to={getUrlInProject('/fiscal-simulator')}>
        {tt('cost_simulator')}
      </NavigationItem>
      <NavigationItem isHidden={isAgency} icon="share-2" to={getUrlInProject('/multidiffusion')}>
        {tt('multidiffusion')}
      </NavigationItem>
      <NavigationCategory isHidden={isAgency} label={tt('analytics')} icon="bar-chart">
        <SubNavigationItem to={getUrlInProject('/analytics/traction')}>
          {tt('traction')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInProject('/analytics/campaigns')}>
          {tt('campaigns')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInProject('/analytics/deals')}>
          {tt('deals')}
        </SubNavigationItem>
        <SubNavigationItem to={getUrlInProject('/analytics/reports')}>
          {tt('reports')}
        </SubNavigationItem>
      </NavigationCategory>
      <NavigationItem isHidden={isAgency} to={getUrlInProject('/settings')} icon="settings">
        {tt('settings')}
      </NavigationItem>
    </Fragment>
  );
};

export const ContextSidebar = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { projects } = useFetchProjects(EMPTY_PROJECT_FILTERS);

  if (user == null) return null;

  const { isAgency } = user.organisation ?? { isAgency: false };

  // Can't rely on useParams here because react-router doesn't let you access the URL
  // parameters of nested routers.
  const activeProject = projects.find((project) => pathname.includes(project.publicToken));

  return (
    <BaseSidebar switcher={<ContextSwitcher projects={projects} activeProject={activeProject} />}>
      {activeProject != null ? (
        <ProjectNavigation isAgency={isAgency} />
      ) : (
        <OrganisationNavigation isAgency={isAgency} />
      )}
    </BaseSidebar>
  );
};
