import {
  ButtonLink,
  Flex,
  FlexItem,
  FlexJustify,
  Label,
  ListTile,
  Margin,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.insights.routes.dashboard');

interface DashboardCardHeaderProps {
  icon: ReactNode;
  title: string;
  url: string;
}

export const DashboardCardHeader = ({ icon, title, url }: DashboardCardHeaderProps) => {
  return (
    <Margin size={{ bottom: Size.DEFAULT }}>
      <Flex justify={FlexJustify.SPACE_BETWEEN}>
        <FlexItem>
          <ListTile leading={icon} title={<Label>{title}</Label>} />
        </FlexItem>
        <FlexItem>
          <Link to={url}>
            <ButtonLink tier={Tier.SECONDARY} size={Size.SMALL}>
              {tt('see_detailed_view')}
            </ButtonLink>
          </Link>
        </FlexItem>
      </Flex>
    </Margin>
  );
};
