import jwtDecode from 'jwt-decode';
import { omit } from 'lodash';

import { OrganisationRole, User, UserFromToken } from '~/types';

import { LocaleCode } from '.';

function rawUserRoleToOrganisationRole(role: UserFromToken['role']): OrganisationRole {
  if (role === 'admin') return OrganisationRole.ADMIN;
  else if (role === 'agency_sales_agent') return OrganisationRole.AGENCY_SALES_AGENT;
  else if (role === 'agency_sales_manager') return OrganisationRole.AGENCY_SALES_MANAGER;
  else return OrganisationRole.MEMBER;
}

export function decodeUser(jwt: string): User | undefined {
  try {
    const rawUser = jwtDecode<UserFromToken>(jwt);
    return {
      id: rawUser.id,
      firstName: rawUser.first_name,
      lastName: rawUser.last_name,
      fullName: `${rawUser.first_name} ${rawUser.last_name}`,
      exp: rawUser.exp,
      email: rawUser.email,
      isAgent: rawUser.organisation?.agency ?? false,
      organisation: rawUser.organisation
        ? {
            ...omit(rawUser.organisation, 'public_token'),
            publicToken: rawUser.organisation.public_token,
            isAgency: rawUser.organisation.agency,
          }
        : undefined,
      role: rawUserRoleToOrganisationRole(rawUser.role),
      confirmed: rawUser.confirmed,
      intercomHash: rawUser.intercom_hash,
      domain: rawUser.target_domain,
      impersonation: rawUser.employee
        ? {
            id: rawUser.employee.id,
            firstName: rawUser.employee.first_name,
            lastName: rawUser.employee.last_name,
          }
        : undefined,
      locale: rawUser.locale.toLowerCase() as LocaleCode,
    };
  } catch (e) {
    console.error('Found a token but failed to decode it', e);
    return;
  }
}
