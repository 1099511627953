import { Layout, Position, SplashScreen } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import LogRocket from 'logrocket';
import React, { Fragment, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AuthProvider, identifyUserInPendo, isImpersonating, useAuth } from '~/utils';

import { IMPERSONATION_BANNER_HEIGHT, ImpersonationBanner } from './ImpersonationBanner';

const styles = {
  container: css`
    height: calc(100vh - ${IMPERSONATION_BANNER_HEIGHT});
  `,
};

const WithAuthContext = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { user, decodedUser } = useAuth();

  useEffect(() => {
    if (user != null) {
      LogRocket.identify(user.id, {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
      });
    }

    if (!isImpersonating()) {
      identifyUserInPendo(user);
    }
  }, [user?.id]);

  useEffect(() => {
    if (user == null) {
      if (search.includes('code')) {
        navigate(`/auth/sign-up${search}`);
      } else {
        navigate(`/auth/login?next=${pathname + search}`);
      }
    } else if (!user.confirmed) {
      navigate(
        `/auth/confirmation${
          pathname.includes('confirmation') ? search : `?next=${pathname + search}`
        }`,
      );
      // checking for object details due to backend issue
    } else if (user.organisation == null || user.organisation?.id == null) {
      navigate(
        `/auth/create-join-company${
          pathname.includes('create-join-company') ? search : `?next=${pathname + search}`
        }`,
      );
    }
  }, [JSON.stringify(decodedUser)]);

  if (user == null) {
    return <SplashScreen />;
  }

  if (isImpersonating()) {
    return (
      <Layout bar={<ImpersonationBanner />} position={Position.TOP} barScrollable={false} fixed>
        <div className={styles.container}>{children}</div>
      </Layout>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export const Authenticated = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider>
      <WithAuthContext>{children}</WithAuthContext>
    </AuthProvider>
  );
};
