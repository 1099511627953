import { getJwt } from '@drawbotics/auth';
import { gqlFetch } from '@tokamakjs/common';
import { Injectable } from '@tokamakjs/react';
import { omit, pick } from 'lodash';
import urlJoin from 'url-join';

import { ID } from '~/types';

import { AssetData, UpdateLinkData, UpdateSectionData, Website } from './domain';
import {
  DecrementSectionMutation,
  IncrementSectionMutation,
  UpdateActiveMutation,
  UpdateAssetsMutation,
  UpdateDescriptionMutation,
  UpdateLinkMutation,
  UpdateTaglineMutation,
  UpdateTitleMutation,
  UpdateWebsiteMutation,
} from './mutations';
import { WebsiteQuery } from './queries';

@Injectable()
export class WebsiteApi {
  public async fetchWebsite(projectId: string): Promise<Website> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: WebsiteQuery,
      variables: { projectId },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return Website.fromData(body.data.website);
  }

  public async updateWebsite(id: string, data: Partial<Website>): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateWebsiteMutation,
      variables: { data: { ...data, id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  public async incrementSection(id: ID): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: IncrementSectionMutation,
      variables: { data: { sectionId: id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  public async decrementSection(id: ID): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: DecrementSectionMutation,
      variables: { data: { sectionId: id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  public async updateLink(id: string, data: UpdateLinkData): Promise<void> {
    await this._updateLink(id, data);
  }

  public async updateSection(id: string, data: UpdateSectionData): Promise<void> {
    if (data.active != null) {
      await this._updateActive(id, data.active);
    }

    if (data.description != null) {
      await this._updateDescription(id, data.description);
    }

    if (data.tagline != null) {
      await this._updateTagline(id, data.tagline);
    }

    if (data.title != null) {
      await this._updateTitle(id, data.title);
    }
  }

  public async updateSectionAsset(
    sectionId: string,
    assets: Array<AssetData>,
  ): Promise<Array<AssetData>> {
    // Pick whitelisted properties in case something else makes its way here. Otherwise,
    // if encountering unwanted properties, the server will complain.
    //
    // Also, if signedBlobId is present, remove the url (or the server will complain).
    // Also, if destroy is present, then just send the assetId (or the server will complain).
    const sanitizedAssets = assets
      .map((a) => {
        return pick(a, ['alt', 'assetId', 'destroy', 'position', 'signedBlobId', 'url']);
      })
      .map((a) => {
        if (a.destroy) {
          return { destroy: a.destroy, assetId: a.assetId };
        }

        if (a.signedBlobId != null || a.alt != null) {
          return omit(a, ['url']);
        }

        return a;
      });

    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateAssetsMutation,
      variables: { data: { sectionId, assets: sanitizedAssets } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return body.data.updateAssets.assets;
  }

  public async updateWebsiteLogo(id: string, logo: string): Promise<Website['logo']> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateWebsiteMutation,
      variables: { data: { logo, id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return body.data.updateWebsite.website.logo;
  }

  public async updateWebsiteFavicon(id: string, favicon: string): Promise<Website['favicon']> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateWebsiteMutation,
      variables: { data: { favicon, id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return body.data.updateWebsite.website.favicon;
  }

  public async updateWebsiteOpenGraphAttachment(
    id: string,
    openGraphAttachment: string,
  ): Promise<Website['favicon']> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateWebsiteMutation,
      variables: { data: { openGraphAttachment, id } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return body.data.updateWebsite.website.openGraphAttachment;
  }

  private async _updateActive(sectionId: string, active: boolean): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateActiveMutation,
      variables: { data: { sectionId, active } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  private async _updateLink(sectionId: string, data: UpdateLinkData): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateLinkMutation,
      variables: { data: { sectionId, ...data } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  private async _updateDescription(sectionId: string, description: string): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateDescriptionMutation,
      variables: { data: { sectionId, description } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  private async _updateTagline(sectionId: string, tagline: string): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateTaglineMutation,
      variables: { data: { sectionId, tagline } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }

  private async _updateTitle(sectionId: string, title: string): Promise<void> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: UpdateTitleMutation,
      variables: { data: { sectionId, title } },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }
  }
}
