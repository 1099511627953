import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Section } from '../../types';

interface SectionQueryData {
  section: Section;
}

interface SectionQueryVariables {
  sectionId: string;
}

const SectionQuery = gql`
  query SectionQuery($sectionId: ID!) {
    section(id: $sectionId) {
      id
      position
      slug
      hidden
      name
      possibleCategories
      categories {
        id
        name
        slug
        position
        type
        ... on IframeCategory {
          assets {
            id
            url
          }
        }
        ... on ImageCategory {
          assets {
            id
            url
            ... on AttachmentAsset {
              filename
            }
          }
        }
        ... on PdfCategory {
          assets {
            id
            url
            ... on AttachmentAsset {
              filename
            }
          }
        }
      }
    }
  }
`;

export function useFetchSection(sectionId: string): {
  isLoading: boolean;
  section?: Section;
  error?: ErrorType;
  refetch: VoidFunction;
} {
  const res = useMosaicQuery<SectionQueryData, SectionQueryVariables>({
    query: SectionQuery,
    variables: { sectionId },
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    section: res.data?.section,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
