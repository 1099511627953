import { setJwt, setRefreshToken, TargetDomain } from '@drawbotics/auth';
import { useState } from 'react';
import join from 'url-join';

import { User } from '~/types';
import { decodeUser, useTriggerCriticalError } from '~/utils';

interface LoginData {
  email: string;
  password: string;
}

interface UseLoginOutput {
  isLoggingIn: boolean;
  login: (values: LoginData) => Promise<User | undefined>;
}

export function useLogin(): UseLoginOutput {
  const triggerCritical = useTriggerCriticalError();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const login = async (values: LoginData): Promise<User | undefined> => {
    setIsLoggingIn(true);
    const res = await fetch(join(process.env.AUTH_HOST, '/v1/login'), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'target-domain': TargetDomain.MOSAIC
      },
      body: JSON.stringify(values),
    });
    setIsLoggingIn(false);

    if (res.status === 500) {
      triggerCritical();
      return undefined;
    } else if (!res.ok) {
      return undefined;
    } else {
      const parsed = await res.json();
      setJwt(parsed.jwt);
      setRefreshToken(parsed.refresh_token);
      const user = decodeUser(parsed.jwt);

      if (user == null) {
        return undefined;
      }

      window.i18n.changeLocale(user.locale);
      return user;
    }
  };

  return { isLoggingIn, login };
}
