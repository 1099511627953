import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Color,
  Icon,
  LoadingPlaceholder,
  Padding,
  SearchInput,
  Separator,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React, { Fragment, MouseEvent, useEffect, useRef, useState } from 'react';

import { Project } from '~/types';
import {
  createTranslate,
  truncate,
  useAuth,
  useLoadAgentResources,
  useMosaicNavigation,
} from '~/utils';

const TRIGGER_BUTTON_HEIGHT = 48;

const tt = createTranslate('components.context_switcher');

const styles = {
  container: css`
    position: relative;
  `,
  triggerButton: css`
    width: 100%;
    height: ${TRIGGER_BUTTON_HEIGHT}px;
    padding: 0px ${sv.paddingSmall};
    background: ${sv.neutralLight};
    color: ${sv.colorPrimary};
    border-radius: ${sv.defaultBorderRadius};
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;
    justify-content: space-between;
    cursor: pointer;
  `,
  triggerButtonActive: css`
    background-color: ${sv.azure};
  `,
  dropdown: css`
    z-index: 1;
    width: 400px;
    box-shadow: ${sv.elevation3};
    position: absolute;
    top: -9999px;
    left: -9999px;
    background-color: ${sv.white};
    border-radius: ${sv.borderRadiusLarge};
  `,
  dropdownVisible: css`
    top: calc(${TRIGGER_BUTTON_HEIGHT}px + ${sv.marginExtraSmall});
    left: 0px;
  `,
  itemsContainer: css`
    max-height: 425px;
    overflow: auto;
    overflow-x: hidden;
  `,
  item: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: 1.2em;
    cursor: pointer;

    &:hover {
      background-color: ${sv.azureLight};
    }
  `,
  itemActive: css`
    font-weight: bold;
  `,
  bottomButton: css`
    padding: 0px ${sv.defaultPadding};
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    clip-path: inset(-15px 0px 0px 0px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
  `,
};

interface ContextSwitcherProps {
  projects?: Array<Project>;
  activeProject?: Project;
}

export const ContextSwitcher = ({ projects, activeProject }: ContextSwitcherProps) => {
  const { user } = useAuth();
  const { organisationToken, navigateToOrg, navigateToProject, switchProject, isProjectContext } =
    useMosaicNavigation();
  const { clientOrgs, isLoadingAgentResources } = useLoadAgentResources();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const switcherRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (e: Event) => {
    if (!switcherRef.current?.contains(e.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  if (projects == null) {
    return <LoadingPlaceholder width="100%" height={TRIGGER_BUTTON_HEIGHT} />;
  }

  const toggleDropdown = (e: MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpen((isOpen) => !isOpen);

    const searchInput = document.querySelector<HTMLElement>('.context-switcher-search-input input');

    searchInput?.focus();
  };

  // todo: refactor this to have global current org state
  const currentOrgName = (() => {
    if (organisationToken === user?.organisation?.publicToken) {
      return user?.organisation?.name ?? tt('my_company');
    } else if (isLoadingAgentResources) {
      return tt('loading');
    } else {
      return (
        clientOrgs.find((org) => org.publicToken === organisationToken)?.name || tt('my_company')
      );
    }
  })();

  const currentContextName = activeProject != null ? activeProject.name : currentOrgName;

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <Fragment>
      <div className={styles.container} ref={switcherRef}>
        <div
          className={cx(styles.triggerButton, { [styles.triggerButtonActive]: isDropdownOpen })}
          onClick={toggleDropdown}>
          {truncate(currentContextName, 15)}
          <Icon name="chevron-down" />
        </div>
        <div className={cx(styles.dropdown, { [styles.dropdownVisible]: isDropdownOpen })}>
          <Padding
            size={{
              top: Size.SMALL,
              horizontal: Size.SMALL,
            }}>
            {isLoadingAgentResources ? (
              <LoadingPlaceholder />
            ) : organisationToken ? (
              <Button
                onClick={() => {
                  setIsDropdownOpen(false);
                  navigateToOrg(organisationToken);
                }}
                style={{ width: '100%' }}
                color={Color.BLUE}
                leading={<Icon name="arrow-left" />}>
                {tt('back_to', { organisationName: truncate(currentOrgName, 15) })}
              </Button>
            ) : null}
          </Padding>
          <Padding
            size={{
              top: Size.EXTRA_SMALL,
              horizontal: Size.SMALL,
              bottom: Size.SMALL,
            }}>
            <SearchInput
              className="context-switcher-search-input"
              minimal
              size={Size.SMALL}
              value={searchInput}
              onChange={setSearchInput}
              placeholder={`Search projects`}
            />
          </Padding>

          <Separator />
          <div className={styles.itemsContainer}>
            <Padding size={{ vertical: Size.EXTRA_SMALL }}>
              {filteredProjects
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((project, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      (isProjectContext ? switchProject : navigateToProject)(project.publicToken);
                      setIsDropdownOpen(false);
                    }}
                    className={cx(styles.item, {
                      [styles.itemActive]: project.publicToken === activeProject?.publicToken,
                    })}>
                    <Padding size={{ vertical: Size.EXTRA_SMALL, horizontal: Size.SMALL }}>
                      <Text>{truncate(project.name, 28)}</Text>
                    </Padding>
                  </div>
                ))}
            </Padding>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
