import {
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  LoadingPlaceholder,
  Size,
  TextLink,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import { createTranslate, useMosaicNavigation } from '~/utils';

import { useFetchLeadCount } from '../hooks';

interface Props {
  portalSlug: string;
  projectId: string;
}

const tt = createTranslate('pods.multidiffusion.components.diffusion_panel');

export function DiffusionLeadsCount(props: Props) {
  const { getUrlInProject } = useMosaicNavigation();

  const { isLoading: isLeadsCountLoading, leadCount } = useFetchLeadCount({
    projectIds: [props.projectId],
    portalSlugs: [props.portalSlug],
  });

  if (isLeadsCountLoading) {
    return <LoadingPlaceholder />;
  }

  if (!leadCount) {
    return null;
  }

  return (
    <Link to={getUrlInProject(`/crm?portalSlugs=${props.portalSlug}`)}>
      <TextLink>
        <Flex align={FlexAlign.START} justify={FlexJustify.START}>
          <FlexItem>
            <Icon name="users" />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem> {tt('leads_counter', { count: leadCount?.ownedLeadCount })}</FlexItem>
        </Flex>
      </TextLink>
    </Link>
  );
}
