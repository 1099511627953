import { Padding, Size } from '@drawbotics/react-drylus';
import { createTranslate } from '~/utils/translation';
import React from 'react';

import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';
import { ComingSoonChart } from './ComingSoonChart';

const t = createTranslate('pods.insights_reports.components.charts.top_cities');

export const TopCities = () => {
  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={Size.EXTRA_SMALL}>
        <ComingSoonChart />
      </Padding>
    </ChartTile>
  );
};
