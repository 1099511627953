import {
  Button,
  Checkbox,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FormGroup,
  InputGroup,
  Label,
  Margin,
  Panel,
  PanelBody,
  PanelHeader,
  Select,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import { Form } from '@drawbotics/use-form';
import React from 'react';
import Draggable from 'react-draggable';

import { DATASETS } from '../showcase/sets';

export interface DevPanelForm {
  overrideLoading: boolean;
  useGenerator: boolean;
  isCollapsed: boolean;
  selectedSet: keyof typeof DATASETS;
}

export const DEV_PANEL_DEFAULTS = {
  overrideLoading: false,
  useGenerator: true,
  isCollapsed: false,
  selectedSet: 'no_units' as keyof typeof DATASETS,
};

interface DevPanelProps {
  form: Form<DevPanelForm>;
}

export const DevPanel = ({ form }: DevPanelProps) => {
  const { get, set, values } = form;
  const { isCollapsed, useGenerator } = values;

  return (
    <Draggable defaultPosition={{ x: 50, y: 700 }}>
      <div style={{ zIndex: 10, width: '400px' }}>
        <Panel
          style={{
            boxShadow: `0px 0px 10px red`,
            width: '400px',
          }}
          header={
            <PanelHeader>
              <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ width: '100%' }}>
                <FlexItem>
                  <Title noMargin size={4}>
                    Dev controls
                  </Title>
                </FlexItem>
                <FlexItem>
                  <Button
                    onClick={() => form.set(!isCollapsed, 'isCollapsed')}
                    size={Size.SMALL}
                    color={Color.BLUE}>
                    {isCollapsed ? 'expand' : 'Collapse'}
                  </Button>
                </FlexItem>
              </Flex>
            </PanelHeader>
          }
          // @ts-ignore It doesn't like null instead of PanelBody but it'll live
          body={
            isCollapsed ? null : (
              <PanelBody>
                <FormGroup
                  label={<Label>Loading</Label>}
                  input={
                    <Checkbox name="overrideLoading" value={get} onChange={set}>
                      Force loading
                    </Checkbox>
                  }
                />
                <Margin size={Size.DEFAULT} />
                <FormGroup
                  label={<Label>Dataset</Label>}
                  input={
                    <InputGroup>
                      <Checkbox name="useGenerator" value={get} onChange={set}>
                        Use generator
                      </Checkbox>
                      <Select
                        name="selectedSet"
                        disabled={useGenerator}
                        value={get}
                        onChange={set}
                        options={[
                          {
                            label: 'No units',
                            value: 'no_units',
                          },
                          {
                            label: 'One unit optioned',
                            value: 'one_unit_optioned',
                          },
                          {
                            label: 'Unavailable unit',
                            value: 'unavailable_unit',
                          },
                          {
                            label: 'One price missing',
                            value: 'one_price_missing',
                          },
                          {
                            label: 'Some missing prices',
                            value: 'some_missing_prices',
                          },
                          {
                            label: 'All prices missing',
                            value: 'all_prices_missing',
                          },
                          {
                            label: 'Nothing has happened yet',
                            value: 'no_activity',
                          },
                          {
                            label: 'Sales goals inside data range',
                            value: 'goals_inside_range',
                          },
                          {
                            label: 'Sales goals outside data range',
                            value: 'goals_outside_range',
                          },
                          {
                            label: 'Sales goals overlap data range pre',
                            value: 'goals_overlap_range_1',
                          },
                          {
                            label: 'Sales goals overlap data range post',
                            value: 'goals_overlap_range_2',
                          },
                        ]}
                      />
                    </InputGroup>
                  }
                />
              </PanelBody>
            )
          }
        />
      </div>
    </Draggable>
  );
};
