import gql from 'fraql';

import { Log, LogType } from '~/pods/crm/types';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

export interface LogsQueryData {
  logs: Array<Log>;
}

interface LogsQueryOptions {
  types?: Array<LogType> | null;
  limit?: number;
  offset?: number;
}

export interface LogsQueryVariables {
  filter: {
    leadId: string;
    types?: Array<LogType> | null;
    limit?: number;
    offset?: number;
  };
}

const LogsQuery = gql`
  query LogsQuery($filter: LogFilterInput!) {
    logs(filter: $filter) {
      id
      author {
        id
        fullName @computed(type: User)
      }
      pinned
      content
      date
      type
      lead {
        id
        fullName @computed(type: Lead)
      }
      projects {
        id
        name
      }
      ... on FirstLeadAssignmentLog {
        salesperson {
          id
          fullName @computed(type: User)
        }
      }
      ... on FunnelStepChangeLog {
        nextStep: nextFunnelStep {
          id
          type
          name
          slug
          color
        }
        previousStep: previousFunnelStep {
          id
          type
          name
          slug
          color
        }
      }
      ... on LeadAssignmentLog {
        nextSalesperson {
          id
          fullName @computed(type: User)
        }
        previousSalesperson {
          id
          fullName @computed(type: User)
        }
      }
      ... on OfferLog {
        offer {
          attachments {
            id
            filename
            url
          }
        }
      }
      ... on ReachMeetingLog {
        meeting {
          id
        }
      }
    }
  }
`;

export function useFetchLogs(
  leadId: string,
  options: LogsQueryOptions = {},
): { isLoading: boolean; logs?: Array<Log>; refetch: VoidFunction; error?: ErrorType } {
  const { types, limit, offset } = options;
  const res = useMosaicQuery<LogsQueryData, LogsQueryVariables>({
    query: LogsQuery,
    variables: { filter: { leadId, types, limit, offset } },
  });

  return {
    isLoading: res.isLoading,
    logs: res.data?.logs,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
