import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Category,
  InlineEdit,
  Input,
  Padding,
  Size,
  TextArea,
  useAlert,
} from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { useForm } from '@tokamakjs/common';
import { css, cx } from 'emotion';
import React from 'react';

import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { AnyWebsiteSectionData, CoverSection } from '../../../api/domain';
import { ThemeDefinition } from '../../../types';
import { LoremIpsum } from '../../../utils';
import { ControlBracket } from '../ControlBracket';
import { Themed } from '../Themed';

const styles = {
  container: css`
    margin-top: ${sv.marginSmall};
    position: relative;
  `,
  controls: css``,
  inactive: css`
    opacity: 0.4;
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
};

interface CoverProps {
  cover: CoverSection;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  language: LocaleCode;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Cover = ({ cover, onChange, language, onIncrement, onDecrement }: CoverProps) => {
  const form = useForm({ title: cover.title ?? '', description: cover.description ?? '' });
  const theme = useTheme<ThemeDefinition>();
  const { showAlert } = useAlert();

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (
    sectionId: ID,
    data: Pick<CoverSection, 'title' | 'description'>,
  ) => {
    if (data.title != null) {
      data.title.length <= 200 ? onChange(sectionId, data) : _handleTextLengthError();
    }
    if (data.description != null) {
      data.description.length <= 800 ? onChange(sectionId, data) : _handleTextLengthError();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <ControlBracket
          sectionId={cover.id}
          horizontal
          onToggleVisibility={() => onChange(cover.id, { active: !cover.isActive })}
          isActive={cover.isActive}
          onIncrement={() => onIncrement(cover.id)}
          onDecrement={() => onDecrement(cover.id)}
        />
      </div>
      <div
        style={{ backgroundColor: `${theme.colors.primary}` }}
        className={cx({ [styles.inactive]: !cover.isActive })}>
        <Padding
          size={{ vertical: Size.EXTRA_HUGE, horizontal: Size.EXTRA_HUGE }}
          style={{ width: '100%' }}>
          <InlineEdit
            onCancel={() => form.set(cover.title ?? '', 'title')}
            onClickConfirm={() => _handleUpdateSection(cover.id, { title: form.get('title') })}
            edit={
              <Input
                style={{ fontSize: '1rem', width: '500px' }}
                name="coverTagline"
                placeholder={tl('title_placeholder')}
                value={form.get('title')}
                onChange={(v) => form.set(v.toString(), 'title')}
                error={form.get('title').length > 200 && tl('max_200_characters_error')}
              />
            }>
            <Themed.Title
              style={{
                color: `${theme.colors.neutral4}`,
                opacity: cover.title != null && cover.title !== '' ? '1' : '0.5',
              }}
              size={1}
              align={Align.CENTER}>
              {cover.title != null && cover.title !== '' ? cover.title : LoremIpsum.MEDIUM}
            </Themed.Title>
          </InlineEdit>
          <InlineEdit
            onCancel={() => form.set(cover.description ?? '', 'description')}
            onClickConfirm={() =>
              _handleUpdateSection(cover.id, { description: form.get('description') })
            }
            edit={
              <TextArea
                className={styles.textarea}
                name="coverDescription"
                placeholder={tl('tagline_placeholder')}
                value={form.get('description')}
                onChange={(v) => form.set(v.toString(), 'description')}
                error={form.get('description').length > 800 && tl('max_800_characters_error')}
              />
            }>
            <Themed.Paragraph
              style={{
                color: `${theme.colors.neutral4}`,
                opacity: cover.description != null && cover.description !== '' ? '1' : '0.5',
              }}
              align={Align.CENTER}>
              {cover.description != null && cover.description !== ''
                ? cover.description
                : LoremIpsum.LONG}
            </Themed.Paragraph>
          </InlineEdit>
        </Padding>
      </div>
    </div>
  );
};
