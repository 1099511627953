import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FormGroup,
  Input,
  Label,
  Panel,
  PanelBody,
  PanelFooter,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'graphql-tag';
import React, { useState } from 'react';

import { Project } from '~/pods/project-settings/types';
import { ID } from '~/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.project_settings.project_settings');

const UpdateProjectNameMutation = gql`
  mutation UpdateProjectName($id: ID!, $name: String!) {
    projectResult: updateProject(input: { id: $id, name: $name }) {
      project {
        id
      }
    }
  }
`;

interface ProjectDetailsPanelProps {
  project: Project;
}

export const ProjectDetailsPanel = ({ project }: ProjectDetailsPanelProps) => {
  const [projectName, setProjectName] = useState(project.name);
  const { showAlert } = useAlert();

  const { executeMutation: updateProjectName, res: updateProjectNameResult } = useMosaicMutation<
    { project: { id: ID } },
    { id: ID; name: string }
  >(UpdateProjectNameMutation);

  const handleClickSave = async () => {
    const res = await updateProjectName({ id: project.id, name: projectName });
    if (res.error == null) {
      showAlert({
        text: tt('update_name_success'),
        category: Category.SUCCESS,
      });
    } else {
      showAlert({
        text: tt('update_name_error'),
        category: Category.DANGER,
      });
    }
  };

  return (
    <Panel
      body={
        <PanelBody>
          <FormGroup
            label={<Label>{tt('project_name_label')}</Label>}
            input={
              <Input
                onChange={(val) => setProjectName(String(val))}
                style={{ width: '500px' }}
                placeholder={tt('project_name_placeholder')}
                value={projectName}
              />
            }
          />
        </PanelBody>
      }
      footer={
        <PanelFooter>
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button
                trailing={updateProjectNameResult.fetching}
                disabled={projectName === project.name || projectName === ''}
                category={Category.INFO}
                onClick={handleClickSave}>
                {tt('update_details')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelFooter>
      }
    />
  );
};
