// @ts-nocheck
import sv from '@drawbotics/drylus-style-vars';
import { LineCustomLayer, LineCustomLayerProps } from '@nivo/line';
import { area } from 'd3-shape';
import React, { Fragment } from 'react';

export function createAreaLayer({
  start,
  height,
}: {
  start: Date;
  height: number;
}): LineCustomLayer {
  return ({ series, xScale, yScale, innerHeight }: LineCustomLayerProps) => {
    type AreaDatum = { data: { x: Date; y: number } };

    const areaGenerator = area<AreaDatum>()
      .x((d) => (d.data.x < start ? xScale(start) : xScale(d.data.x)))
      .y0(() => innerHeight)
      .y1(() => yScale(height));

    const line = area<AreaDatum>()
      .x(() => xScale(start))
      .y0(() => innerHeight)
      .y1(() => yScale(height));

    return (
      <Fragment>
        <path
          d={line(series[0].data) ?? undefined}
          fill={sv.neutralDark}
          fillOpacity={1}
          stroke={sv.neutralDark}
          strokeWidth={0.3}
        />
        <path
          d={areaGenerator(series[0].data) ?? undefined}
          fill={sv.neutralDark}
          fillOpacity={0.12}
          strokeWidth={0}
        />
      </Fragment>
    );
  };
}
