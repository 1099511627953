import { mergeEntities } from 'urql-computed-exchange';

import Address from './Address';
import Invitation from './Invitation';
import Lead from './Lead';
import Meeting from './Meeting';
import Report from './Report';
import Unit from './Unit';
import User from './User';

export const entities = mergeEntities(Unit, Lead, User, Address, Invitation, Report, Meeting);
