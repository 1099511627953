import sv from '@drawbotics/drylus-style-vars';
import {
  Category,
  InlineEdit,
  Input,
  Margin,
  Position,
  Size,
  TextArea,
  useAlert,
} from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { useForm } from '@tokamakjs/common';
import { css } from 'emotion';
import React, { useState } from 'react';

import { AssetTile } from '~/components/AssetTile';
import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale, useMosaicMutation } from '~/utils';

import {
  AnyWebsiteSectionData,
  AssetData,
  HeroSection,
  UpdateLinkData,
  Website,
} from '../../../api/domain';
import { ThemeDefinition } from '../../../types';
import { LoremIpsum } from '../../../utils';
import { EditableElementWrapper } from '../EditableElementWrapper';
import { LinkModal } from '../LinkModal';
import { Themed } from '../Themed';

const styles = {
  hero: css`
    height: 750px;
    width: 100%;
    position: relative;
    padding: ${sv.paddingExtraSmall};

    [class*='Hint__root'] {
      font-size: 0.8rem;
      text-align: left;
      font-weight: 400;
      text-transform: capitalize;
    }
  `,
  container: css`
    padding: ${sv.paddingExtraSmall};
    width: 100%;
    height: 100%;
  `,
  assetTileContainer: css`
    height: 100%;
    width: 100%;
    position: relative;
  `,
  content: css`
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  `,
  imageOverlay: css`
    width: 100%;
    height: 100%;
    background: ${sv.neutralDarkest};
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0.3;
    left: 0;
    pointer-events: none;
  `,
  tagline: css`
    text-align: center;
    width: 100%;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
      box-shadow: none;
      border-radius: ${sv.defaultBorderRadius};
      padding: 0 ${sv.paddingSmall};
    }
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
};

interface HeroProps {
  hero: HeroSection;
  language: LocaleCode;
  sections: Website['sections'];
  onChangeSection: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAsset: (sectionId: string, data: AssetData) => void;
  onChangeLink: (sectionId: string, data: UpdateLinkData) => void;
}

export const Hero = ({
  hero,
  language,
  onChangeSection,
  onChangeAsset,
  sections,
  onChangeLink,
}: HeroProps) => {
  const form = useForm({
    title: hero.title ?? '',
    description: hero.description ?? '',
    tagline: hero.tagline ?? '',
  });
  const { showAlert } = useAlert();
  const [ctaEditOpen, setCtaEditOpen] = useState(false);

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);
  const theme = useTheme<ThemeDefinition>();

  const isImagePresent = hero.assets[0]?.url != null;

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (
    sectionId: ID,
    data: Pick<HeroSection, 'title' | 'tagline' | 'description'>,
  ) => {
    if (data.tagline != null) {
      data.tagline.length <= 200 ? onChangeSection(sectionId, data) : _handleTextLengthError();
    }
    if (data.title != null) {
      data.title.length <= 200 ? onChangeSection(sectionId, data) : _handleTextLengthError();
    }
    if (data.description != null) {
      data.description.length <= 800 ? onChangeSection(sectionId, data) : _handleTextLengthError();
    }
  };

  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.assetTileContainer}>
          <AssetTile
            uploadButtonPosition={Position.TOP}
            asset={hero.assets[0] as any}
            onSaveUrl={(url) => onChangeAsset(hero.id, { assetId: hero.assets[0]?.assetId, url })}
            onFinishUpload={(asset) =>
              onChangeAsset(hero.id, {
                assetId: hero.assets[0]?.assetId,
                signedBlobId: asset.signedBlobId,
              })
            }
            mutationTargetType={useMosaicMutation}
          />
          {isImagePresent && <div className={styles.imageOverlay}></div>}
        </div>
      </div>
      <div className={styles.content}>
        <InlineEdit
          onCancel={() => form.set(hero.tagline ?? '', 'tagline')}
          onClickConfirm={() => _handleUpdateSection(hero.id, { tagline: form.get('tagline') })}
          edit={
            <Input
              style={{ fontSize: '1rem' }}
              name="heroTagline"
              placeholder={tl('subtitle_placeholder')}
              value={form.get('tagline')}
              onChange={(v) => form.set(v.toString(), 'tagline')}
              error={form.get('tagline').length > 200 && tl('max_200_characters_error')}
            />
          }>
          <Themed.Title
            size={3}
            style={{
              color: isImagePresent ? theme.colors.neutral2 : theme.colors.neutral1,
              opacity: hero.tagline != null && hero.tagline !== '' ? '1' : '0.5',
            }}>
            {hero.tagline != null && hero.tagline !== '' ? hero.tagline : LoremIpsum.SHORT}
          </Themed.Title>
        </InlineEdit>
        <InlineEdit
          onCancel={() => form.set(hero.title ?? '', 'title')}
          onClickConfirm={() => _handleUpdateSection(hero.id, { title: form.get('title') })}
          edit={
            <TextArea
              style={{ fontSize: '1rem' }}
              name="heroTitle"
              placeholder={tl('title_placeholder')}
              value={form.get('title')}
              onChange={(v) => form.set(v.toString(), 'title')}
              error={form.get('title').length > 200 && tl('max_200_characters_error')}
            />
          }>
          <Themed.Title
            className={styles.tagline}
            size={2}
            style={{
              color: isImagePresent ? theme.colors.neutral2 : theme.colors.neutral1,
              opacity: hero.title != null && hero.title !== '' ? '1' : '0.5',
            }}>
            {hero.title != null && hero.title !== '' ? hero.title : LoremIpsum.MEDIUM}
          </Themed.Title>
        </InlineEdit>
        <InlineEdit
          onCancel={() => form.set(hero.description ?? '', 'description')}
          onClickConfirm={() =>
            _handleUpdateSection(hero.id, { description: form.get('description') })
          }
          edit={
            <TextArea
              className={styles.textarea}
              name="heroDescription"
              placeholder={tl('project_intro_placeholder')}
              value={form.get('description')}
              onChange={(v) => form.set(v.toString(), 'description')}
              error={form.get('description').length > 800 && tl('max_800_characters_error')}
            />
          }>
          <Themed.Paragraph
            style={{
              color: isImagePresent ? theme.colors.neutral2 : theme.colors.neutral1,
              opacity: hero.description != null && hero.description !== '' ? '1' : '0.5',
            }}>
            {hero.description != null && hero.description !== ''
              ? hero.description
              : LoremIpsum.LONG}
          </Themed.Paragraph>
        </InlineEdit>
        <Margin size={{ top: Size.DEFAULT }}>
          <EditableElementWrapper onClick={() => setCtaEditOpen(true)}>
            <Themed.Button>{hero.link.label}</Themed.Button>
          </EditableElementWrapper>
        </Margin>
      </div>
      <LinkModal
        sections={sections}
        visible={ctaEditOpen}
        onClickClose={() => setCtaEditOpen(false)}
        link={hero.link}
        onChange={(data) => onChangeLink(hero.id, data)}
      />
    </div>
  );
};
