import countries from 'i18n-iso-countries';
import get from 'lodash/get';
import LogRocket from 'logrocket';

import { locales } from '~/locales';
import { Currency } from '~/types';

import { getCurrencyDetails } from './index';

export type LocaleCode = 'en' | 'fr' | 'nl';

interface PluralizedString {
  one: string;
  none?: string;
  other: string;
}

type TranslationValue = string | number | PluralizedString;

function isPluralizedString(maybePluralizedString: any): maybePluralizedString is PluralizedString {
  return (
    typeof maybePluralizedString === 'object' &&
    'one' in maybePluralizedString &&
    'other' in maybePluralizedString
  );
}

function _replaceData(input: TranslationValue, data?: Record<string, string | number>) {
  if (!input) {
    return null;
  }

  let string;
  if (isPluralizedString(input)) {
    // This means we have a pluralized string: `{one: '', none:'', other: ''}`
    const count = get(data, 'count');
    if (count == null) {
      console.error(
        "A pluralized string was passed, but no 'count' property was provided in the data object",
      );
      return '';
    }
    string = count === 1 ? get(input, 'one', '') : get(input, 'other', '');
  } else if (typeof input === 'number') {
    string = input.toString();
  } else {
    string = input;
  }

  // Split on patterns matching `%{key}`.
  // Because the regex has a capturing group, occurences
  // of the pattern will be included in the return array.
  return string
    .split(/(%\{[^}]*\})/)
    .map((fragment) => {
      // See if this substring contains the pattern and capture the key if it does
      const match = fragment.match(/%\{(.*)\}/);
      if (!match) {
        return fragment;
      }
      const key = match[1];
      if (data == null || data[key] == null) {
        console.error(`Key ${key} was not provided to the translation function.`);
        return '';
      }
      return data[key];
    })
    .join('');
}

function getTranslationValue(locale: LocaleCode, key: string) {
  return get(locales[locale], key) as TranslationValue;
}

function _getActiveLocale(): LocaleCode {
  const locale = get(window, 'i18n.locale', 'en');
  if (locale === 'fr') {
    return 'fr';
  }
  if (locale === 'nl') {
    return 'nl';
  }
  return 'en';
}

function _translate(key: string, data?: Record<string, string | number>, locale?: LocaleCode) {
  // When in a review environment, return the bare key for the lokalise in-context editor
  if (process.env.APP_ENV === 'review') {
    return `{.${key}.}`;
  }

  const targetLocale = locale ?? _getActiveLocale();
  const value = _replaceData(getTranslationValue(targetLocale, key), data);
  if (value == null) {
    const defaultValue = _replaceData(getTranslationValue('en', key), data);
    const error = `Translation key '${key}' not found`;
    console.error(error);
    LogRocket.captureMessage(error);
    return defaultValue ?? '';
  }
  return value;
}

/** Returns a translated string according to the active locale and the specified key */
export function translate(
  key: string,
  data?: Record<string, string | number>,
  base?: string,
  locale?: LocaleCode,
) {
  // Remove trailing from the base path `.` if any
  const baseStripped = base?.replace(/\.$/, '');
  const translationKey = base != null ? `${baseStripped}.${key}` : key;
  return _translate(translationKey, data, locale);
}

/** Creates an instance of the translate function with a fixed base */
export function createTranslate(base: string) {
  return (key: string, data?: Record<string, number | string>) => {
    return translate(key, data, base);
  };
}

/** Creates an instance of the translateWithLocale function with a fixed base */
export function createTranslateWithLocale(base: string, locale: LocaleCode) {
  return (key: string, data?: Record<string, number | string>) => {
    return translate(key, data, base, locale);
  };
}

export function addCurrency(amount: string | number, symbol: string) {
  return translate('price_with_currency', { price: amount, currency: symbol });
}

export function translatePrice(amount: number | string, code: Currency) {
  return addCurrency(amount.toLocaleString(), getCurrencyDetails(code).symbol);
}

export function getCountryName(code: string, locale?: LocaleCode): string {
  const activeLocale = locale ?? _getActiveLocale();
  return countries.getName(code, activeLocale);
}

export default translate;
