import {
  Content,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  ListTile,
  LoadingPlaceholder,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Table,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.multidiffusion.components.advert_panel');

export const AdvertPanelLoader = () => {
  return (
    <Panel
      header={
        <PanelHeader>
          <Flex>
            <FlexItem flex>
              <Title size={4} noMargin>
                <LoadingPlaceholder />
              </Title>
            </FlexItem>
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <Flex>
            <FlexItem flex style={{ width: '50%', flexShrink: 0 }}>
              <Flex align={FlexAlign.STRETCH}>
                <FlexItem flex>
                  <Flex
                    direction={FlexDirection.VERTICAL}
                    align={FlexAlign.STRETCH}
                    justify={FlexJustify.START}>
                    <FlexItem>
                      <ListTile
                        style={{ width: '100%' }}
                        title={<Label isPlaceholder>{tt('labels.name')}</Label>}
                        subtitle={<LoadingPlaceholder width="100%" />}
                      />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem>
                      <ListTile
                        style={{ width: '100%' }}
                        title={<Label isPlaceholder>{tt('labels.address')}</Label>}
                        subtitle={<LoadingPlaceholder width="100%" />}
                      />
                    </FlexItem>
                  </Flex>
                </FlexItem>
                <FlexSpacer size={Size.LARGE} />
                <FlexItem flex>
                  <Flex
                    direction={FlexDirection.VERTICAL}
                    align={FlexAlign.STRETCH}
                    justify={FlexJustify.START}>
                    <FlexItem>
                      <ListTile
                        style={{ width: '100%' }}
                        title={<Label isPlaceholder>{tt('labels.delivery_date')}</Label>}
                        subtitle={<LoadingPlaceholder width="100%" />}
                      />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem>
                      <ListTile
                        style={{ width: '100%' }}
                        title={<Label isPlaceholder>{tt('labels.progress_status')}</Label>}
                        subtitle={<LoadingPlaceholder width="100%" />}
                      />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem>
                      <ListTile
                        style={{ width: '100%' }}
                        title={<Label isPlaceholder>{tt('labels.units_displayed')}</Label>}
                        subtitle={<LoadingPlaceholder width="100%" />}
                      />
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem flex style={{ width: '50%', flexShrink: 0 }}>
              <LoadingPlaceholder width="100%" height={214} />
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};

export const DiffusionsPanelLoader = () => {
  return (
    <Panel
      header={
        <PanelHeader>
          <Flex>
            <FlexItem flex>
              <Title size={4} noMargin>
                <LoadingPlaceholder />
              </Title>
            </FlexItem>
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody noPadding>
          <Table
            header={[
              { label: <LoadingPlaceholder width={40} />, value: 'portal' },
              { label: <LoadingPlaceholder width={40} />, value: 'startingDate' },
              { label: <LoadingPlaceholder width={40} />, value: 'endingDate' },
              { label: <LoadingPlaceholder width={40} />, value: 'leads' },
              { label: <LoadingPlaceholder width={40} />, value: 'status' },
              { label: '', value: 'actions' },
            ]}
            isLoading
          />
        </PanelBody>
      }
    />
  );
};

interface LoaderProps {}

export const Loader = ({}: LoaderProps) => {
  return (
    <Fragment>
      <Content fullHeight>
        <Padding size={Size.LARGE}>
          <Flex>
            <FlexItem>
              <Title size={3}>
                <LoadingPlaceholder />
              </Title>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexSpacer flex />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
          </Flex>
          <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
            <FlexSpacer />
            <FlexItem>
              <AdvertPanelLoader />
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <DiffusionsPanelLoader />
            </FlexItem>
          </Flex>
        </Padding>
      </Content>
    </Fragment>
  );
};
