import { Content, Layout, Position } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import dashboardImage from '~/images/dashboard.png';
import { useNodeSize } from '~/utils/hooks';

import { BasicNavbar } from './BasicNavbar';
import { NavbarLayout } from './NavbarLayout';

export const dimensions = {
  width: 600,
};

const styles = {
  wrapper: css`
    position: relative;
    overflow: hidden;
    height: 100%;
  `,
  image: css`
    position: absolute;
    top: 140px;
    right: -100px;
    width: calc(100% + 50px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  sidebar: css`
    width: ${dimensions.width}px;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  container: css`
    width: 75%;
  `,
};

interface SidebarProps {
  children: ReactNode;
}

export const Sidebar = ({ children }: SidebarProps) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

interface MainProps {
  image: string;
}

const Main = ({ image }: MainProps) => {
  const { nodeRef, size } = useNodeSize<HTMLDivElement>();
  const isWide = size.height < size.width;
  return (
    <Content fullWidth fullHeight>
      <div className={styles.wrapper} ref={nodeRef}>
        <div className={styles.image}>
          <img
            src={image}
            style={{
              maxHeight: isWide ? size.height : undefined,
              maxWidth: isWide ? undefined : size.width,
            }}
          />
        </div>
      </div>
    </Content>
  );
};

export const AuthRoutesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <NavbarLayout navbar={<BasicNavbar />}>
      <Layout bar={<Sidebar>{children}</Sidebar>} position={Position.LEFT}>
        <Main image={dashboardImage} />
      </Layout>
    </NavbarLayout>
  );
};
