import { weightedPick } from './weighted-pick';

export function pickDevice(): string {
  const demoDevices: Array<string> = [
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...',
    'Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X)...',
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X)...',
  ];

  const demoDevicesWeights: Record<string, number> = {
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...': 0.2,
    'Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X)...': 0.3,
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X)...': 0.5,
  };

  return weightedPick(demoDevices, demoDevicesWeights);
}
