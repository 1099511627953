import gql from 'fraql';

import { CampaignFromServer } from '~/pods/insights/types';
import { useBackofficeMutation } from '~/utils';

interface CreateCampaignVariables {
  campaign: {
    projectId: string;
    name: string;
    destinationUrl: string;
    source: string;
  };
}

const createCampaignMutation = gql`
  mutation createCampaign($campaign: CreateCampaignInput!) {
    createCampaign(input: $campaign) {
      campaign {
        token
        name
        source
        destinationUrl
      }
    }
  }
`;

export function useCreateCampaign() {
  const { res: createCampaignResult, executeMutation: createCampaign } = useBackofficeMutation<
    { createCampaign: { campaign: CampaignFromServer } },
    CreateCampaignVariables
  >(createCampaignMutation);

  return { createCampaign, createCampaignResult };
}
