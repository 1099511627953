import {
  Flex,
  FlexItem,
  FlexJustify,
  LoadingPlaceholder,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import { useScreenSize } from '@drawbotics/use-screen-size';
import React, { Fragment } from 'react';

import { Listing } from '~/pods/listing-manager/types';
import { CurrencyCode, SurfaceUnitEnum } from '~/types';
import { run } from '~/utils';

import { ListingTable } from './ListingTable';

const placeholderListing: Listing = {
  id: '0',
  name: '',
  currency: CurrencyCode.EUR,
  surfaceUnit: SurfaceUnitEnum.SQM,
  units: [],
};

export const PlaceholderListing = () => {
  const { screenSize, ScreenSizes } = useScreenSize();
  return (
    <Fragment>
      <Margin size={{ vertical: Size.EXTRA_LARGE }}>
        {run(() => {
          if (screenSize < ScreenSizes.XL) {
            return (
              <Flex>
                <FlexItem flex>
                  <LoadingPlaceholder height={40} width="100%" />
                </FlexItem>
                <FlexItem>
                  <Margin size={{ left: Size.SMALL }}>
                    <LoadingPlaceholder height={40} width={50} />
                  </Margin>
                </FlexItem>
              </Flex>
            );
          } else {
            return (
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <LoadingPlaceholder height={30} width={300} responsive={{ XL: { width: 200 } }} />
                </FlexItem>
                <FlexItem>
                  <Flex>
                    <FlexItem>
                      <LoadingPlaceholder height={40} width={100} />
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ left: Size.EXTRA_SMALL }}>
                        <LoadingPlaceholder height={40} width={100} />
                      </Margin>
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ left: Size.EXTRA_SMALL }}>
                        <LoadingPlaceholder height={40} width={100} />
                      </Margin>
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ left: Size.EXTRA_SMALL }}>
                        <LoadingPlaceholder height={40} width={100} />
                      </Margin>
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ left: Size.EXTRA_SMALL }}>
                        <LoadingPlaceholder
                          height={40}
                          width={300}
                          responsive={{ XL: { width: 200 } }}
                        />
                      </Margin>
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>
            );
          }
        })}
      </Margin>
      <Margin size={{ bottom: Size.EXTRA_LARGE }}>
        <ListingTable
          isReadonly={false}
          onClickDeleteUnit={() => {}}
          listing={placeholderListing}
          page={1}
          isLoading
          units={[]}
          onClickRow={() => {}}
          onChangePage={() => {}}
          onClickAddUnits={() => {}}
        />
      </Margin>
    </Fragment>
  );
};
