import sv from '@drawbotics/drylus-style-vars';
import { Button, Category, Icon } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  wrapper: css`
    position: relative;
    display: flex;
    transform: translateX(24px);
    align-items: center;
    z-index: 1;

    &:hover {
      cursor: pointer;

      [data-element='bg'],
      [data-element='edit'] {
        opacity: 1;
      }
    }
  `,
  edit: css`
    margin-left: ${sv.marginExtraSmall};
    opacity: 0;
    transition: ${sv.transitionShort};
  `,
  background: css`
    position: absolute;
    top: -${sv.marginExtraSmall};
    left: -${sv.marginExtraSmall};
    width: calc(100% + ${sv.marginExtraSmall} * 2);
    height: calc(100% + ${sv.marginExtraSmall} * 2);
    background: ${sv.neutral};
    z-index: -1;
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    opacity: 0;
    pointer-events: none;
    transition: ${sv.transitionShort};
  `,
};

interface EditableElementWrapperProps {
  onClick: VoidFunction;
  children: ReactNode;
}

export const EditableElementWrapper = ({ onClick, children }: EditableElementWrapperProps) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      {children}
      <div data-element="bg" className={styles.background} />
      <div data-element="edit" className={styles.edit}>
        <Button category={Category.INFO} leading={<Icon name="edit-2" />} />
      </div>
    </div>
  );
};
