import { Layout, Position } from '@drawbotics/react-drylus';
import React from 'react';

import {
  EditNavbar as Navbar,
  WebsiteDesignPanel,
  WebsiteEditorLoader,
  WebsiteEditorSidebar,
} from '../../components';
import { useController } from '../../hooks';
import { EditController } from './edit.controller';

interface EditViewProps {}

export const EditView = ({}: EditViewProps) => {
  const ctrl = useController(EditController);

  if (ctrl.isLoading) {
    return <WebsiteEditorLoader />;
  }

  const project = ctrl.project!;
  const website = ctrl.website!;

  return (
    <Layout
      bar={<Navbar isSaving={ctrl.isSaving} projectName={project.name} website={website} />}
      position={Position.TOP}
      barScrollable={false}
      fixed>
      <Layout
        bar={
          <WebsiteEditorSidebar
            website={website}
            onChange={(data) => ctrl.updateWebsite(data)}
            onChangeLogo={(s) => ctrl.updateWebsiteLogo(s)}
          />
        }
        position={Position.LEFT}
        style={{ height: '100%' }}
        fixed>
        <WebsiteDesignPanel
          website={website}
          onIncrement={(id) => ctrl.incrementSection(id)}
          onDecrement={(id) => ctrl.decrementSection(id)}
          onChange={(data) => ctrl.updateWebsite(data)}
          onChangeLink={(id, data) => ctrl.updateLink(id, data)}
          onChangeSection={(id, s) => ctrl.updateSection(id, s)}
          onChangeAssets={(id, a) => ctrl.updateSectionAssets(id, a)}
        />
      </Layout>
    </Layout>
  );
};
