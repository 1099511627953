import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Button,
  Category,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  InlineEdit,
  Input,
  Margin,
  Size,
  Spinner,
  Tier,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React from 'react';

import { Figure as FigureType } from '~/pods/meetings/types';
import { isFieldEmptyString } from '~/pods/meetings/utils';
import { HexColor } from '~/types';
import { createTranslate, extractGQLErrorMessages, useMosaicMutation } from '~/utils';

import { Figure } from './Figure';
import { HideShowControl } from './HideShowControl';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  addFigureButton: css`
    position: absolute;
    left: ${sv.marginSmall};
    top: 50%;
    white-space: nowrap;
    transform: translateY(-50%);
  `,
  deleteButton: css`
    position: absolute;
    top: ${sv.marginExtraSmall};
    right: ${sv.marginExtraSmall};
    transform: translate(100%, -100%);
    display: none;
  `,
  figureWrapper: css`
    position: relative;

    &:hover {
      > [data-element='delete'] {
        display: block;
      }
    }
  `,
};

interface UpdateKeyFiguresVariables {
  overview: {
    figuresTitle?: string;
    showFigures?: boolean;
  };
}

interface UpdateKeyFiguresResult {
  updateOverview: {
    overview: {
      figuresTitle: string;
      showFigures: boolean;
    };
  };
}

const updateKeyFiguresMutation = gql`
  mutation updateOverview($overview: UpdateOverviewInput!) {
    updateOverview(input: $overview) {
      overview {
        figuresTitle
        showFigures
      }
    }
  }
`;

interface CreateKeyFigureVariables {
  figure: {
    value: string;
    superscript?: string;
    subtext: string;
  };
}

interface CreateKeyFigureResult {
  createFigure: {
    figure: {
      value: string;
      superscript?: string;
      subtext: string;
    };
  };
}

const createKeyFigureMutation = gql`
  mutation createFigure($figure: CreateFigureInput!) {
    createFigure(input: $figure) {
      figure {
        value
        superscript
        subtext
      }
    }
  }
`;

interface DeleteKeyFigureVariables {
  id: string;
}

interface DeleteKeyFigureResult {
  destroyFigure: {
    figure: {
      id: string;
    };
  };
}

const deleteKeyFigureMutation = gql`
  mutation deleteFigure($id: ID!) {
    destroyFigure(input: { id: $id }) {
      figure {
        id
      }
    }
  }
`;

interface FiguresForm {
  figuresTitle: string;
  showFigures: boolean;
}

const FIGURES_MAX_COUNT = 4;

interface KeyFiguresProps {
  primaryColor?: HexColor;
  figures: Array<FigureType>;
  title?: string;
  visible: boolean;
  refetchFigures: VoidFunction;
  readOnly: boolean;
}

export const KeyFigures = ({
  figures,
  visible,
  title,
  refetchFigures,
  primaryColor,
  readOnly,
}: KeyFiguresProps) => {
  const figuresForm = useForm<FiguresForm>({
    figuresTitle: title ?? '',
    showFigures: visible,
  });
  const { showAlert } = useAlert();

  const { res: updateFiguresResult, executeMutation: updateFigures } = useMosaicMutation<
    UpdateKeyFiguresResult,
    UpdateKeyFiguresVariables
  >(updateKeyFiguresMutation);

  const { res: createFigureResult, executeMutation: createFigure } = useMosaicMutation<
    CreateKeyFigureResult,
    CreateKeyFigureVariables
  >(createKeyFigureMutation);

  const { res: deleteFigureResult, executeMutation: deleteFigure } = useMosaicMutation<
    DeleteKeyFigureResult,
    DeleteKeyFigureVariables
  >(deleteKeyFigureMutation);

  const handleResetValue = (name: keyof FiguresForm) => {
    if (name === 'figuresTitle') {
      figuresForm.set(title ?? '', name);
    } else if (name === 'showFigures') {
      figuresForm.set(visible, name);
    }
  };

  const handleUpdateValue = async (name: keyof FiguresForm, value?: string | boolean) => {
    const res = await updateFigures({
      overview: { [name]: value != null ? value : figuresForm.values[name] },
    });
    if (res.error != null) {
      const message = extractGQLErrorMessages(res.error);
      showAlert({
        text:
          message.message != null
            ? tt(`errors.${message.message}`)
            : tt('could_not_update_section'),
        category: Category.DANGER,
      });
      handleResetValue(name);
    }
  };

  const handleCreateFigure = async () => {
    const res = await createFigure({
      figure: { value: '€25', superscript: 'bn', subtext: 'Lorem Ipsum' },
    });
    if (res.error) {
      showAlert({ text: tt('could_not_create_figure'), category: Category.DANGER });
    } else {
      refetchFigures();
    }
  };

  const handleDeleteFigure = async (id: string) => {
    const res = await deleteFigure({
      id,
    });
    if (res.error) {
      showAlert({ text: tt('could_not_delete_figure'), category: Category.DANGER });
    } else {
      if (figures.length === 0) {
        refetchFigures();
      }
    }
  };

  const isSectionVisible = figuresForm.values.showFigures!;
  return (
    <div style={{ position: 'relative' }}>
      {!readOnly ? (
        <HideShowControl
          loading={updateFiguresResult.fetching}
          onClick={() => {
            figuresForm.set(!isSectionVisible, 'showFigures');
            handleUpdateValue('showFigures', !isSectionVisible);
          }}
          visible={isSectionVisible}
        />
      ) : null}
      <div style={{ opacity: isSectionVisible ? 1 : 0.4 }}>
        <Margin size={{ vertical: Size.DEFAULT }}>
          {readOnly ? (
            <Title
              style={{
                color: isFieldEmptyString(figuresForm.values.figuresTitle)
                  ? sv.colorSecondary
                  : undefined,
              }}
              noMargin
              align={Align.CENTER}
              size={4}>
              {isFieldEmptyString(figuresForm.values.figuresTitle)
                ? tt('key_figures')
                : figuresForm.values.figuresTitle}
            </Title>
          ) : (
            <InlineEdit
              onCancel={() => handleResetValue('figuresTitle')}
              onClickConfirm={() => handleUpdateValue('figuresTitle')}
              edit={
                <Input
                  name="figuresTitle"
                  size={Size.SMALL}
                  placeholder={tt('key_figures')}
                  value={figuresForm.get}
                  onChange={figuresForm.set}
                />
              }>
              <Title
                style={{
                  color: isFieldEmptyString(figuresForm.values.figuresTitle)
                    ? sv.colorSecondary
                    : undefined,
                }}
                noMargin
                align={Align.CENTER}
                size={4}>
                {isFieldEmptyString(figuresForm.values.figuresTitle)
                  ? tt('key_figures')
                  : figuresForm.values.figuresTitle}
              </Title>
            </InlineEdit>
          )}
        </Margin>
        <Flex>
          {figures.map((figure, i) => [
            i !== 0 ? <FlexSpacer key={`_${figure.id}`} size={Size.EXTRA_LARGE} /> : null,
            <FlexItem key={figure.id}>
              <div className={styles.figureWrapper}>
                <div data-element="delete" className={styles.deleteButton}>
                  <Button
                    tier={Tier.TERTIARY}
                    onClick={() => handleDeleteFigure(figure.id)}
                    category={Category.DANGER}
                    size={Size.SMALL}
                    disabled={readOnly}
                    leading={
                      deleteFigureResult.fetching ? (
                        <Spinner size={Size.SMALL} />
                      ) : (
                        <Icon name="trash" />
                      )
                    }
                  />
                </div>
                <Figure figure={figure} primaryColor={primaryColor} readOnly={readOnly} />
              </div>
            </FlexItem>,
          ])}
          {figures.length < FIGURES_MAX_COUNT ? (
            <FlexItem style={{ position: 'relative', height: 20 }}>
              <div
                className={styles.addFigureButton}
                style={{
                  transform:
                    figures.length === 0
                      ? `translate(calc(-50% - ${sv.marginSmall}), -50%)`
                      : undefined,
                }}>
                <Button
                  onClick={handleCreateFigure}
                  disabled={readOnly}
                  leading={
                    createFigureResult.fetching ? (
                      <Spinner size={Size.SMALL} />
                    ) : (
                      <Icon name="plus-circle" />
                    )
                  }
                  tier={Tier.SECONDARY}>
                  {tt('add')}
                </Button>
              </div>
            </FlexItem>
          ) : null}
        </Flex>
      </div>
    </div>
  );
};
