import { AuthModule } from '@drawbotics/auth';
import { Module } from '@tokamakjs/react';

import { ListingManagerModule } from './pods/listing-manager';
import { WebsiteBuilderModule } from './pods/website-builder';

@Module({
  imports: [ListingManagerModule, WebsiteBuilderModule, AuthModule],
})
export class AppModule {}
