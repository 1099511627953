import dayjs, { Dayjs } from 'dayjs';
import faker from 'faker';
import produce from 'immer';
import { v4 } from 'uuid';

import { Availability, InsightsUnit } from '../types';
import { UseLoadInsightsOutput } from '../utils';
import { computedShowcaseData } from './showcase-data';

function _replaceUnits(
  input: UseLoadInsightsOutput,
  newUnits: Array<InsightsUnit> | ((units: Array<InsightsUnit>) => Array<InsightsUnit>),
) {
  return {
    ...input,
    estate: {
      ...input.estate,
      units: Array.isArray(newUnits) ? newUnits : newUnits(input.estate!.units),
    },
  } as UseLoadInsightsOutput;
}

function fakeUnit(): InsightsUnit {
  return {
    id: v4(),
    name: '',
    availability: Availability.AVAILABLE,
    bedrooms: 0,
    orientation: 'NORTH',
    typology: 'T1',
    price: {
      value: 100000,
      currency: 'EUR',
    },
    visits: [],
    timeVisited: 0,
    soldAt: null,
  };
}

function fakeSoldUnit(soldAt: Dayjs): InsightsUnit {
  return {
    id: v4(),
    name: '',
    availability: Availability.BOOKED,
    bedrooms: 0,
    orientation: 'NORTH',
    typology: 'T1',
    price: {
      value: 100000,
      currency: 'EUR',
    },
    visits: [],
    timeVisited: 0,
    soldAt,
  };
}

export const DATASETS = {
  no_units: _replaceUnits(computedShowcaseData, []),
  one_unit_optioned: _replaceUnits(computedShowcaseData, (units) => {
    let statusesWiped = units.map((u) => ({ ...u, availability: Availability.AVAILABLE }));
    statusesWiped[faker.random.number(statusesWiped.length - 1)].availability = Availability.OPTION;
    return statusesWiped;
  }),
  unavailable_unit: _replaceUnits(computedShowcaseData, (units) => {
    return produce(units, (units) => {
      units[faker.random.number(units.length - 1)].availability = Availability.UNAVAILABLE;
    });
  }),
  one_price_missing: _replaceUnits(computedShowcaseData, (units) => {
    return produce(units, (units) => {
      units[faker.random.number(units.length - 1)].price.value = 0;
    });
  }),
  some_missing_prices: _replaceUnits(computedShowcaseData, (units) => {
    return produce(units, (units) => {
      units[faker.random.number(units.length - 1)].price.value = 0;
      units[faker.random.number(units.length - 1)].price.value = 0;
    });
  }),
  all_prices_missing: _replaceUnits(computedShowcaseData, (units) => {
    return units.map((u) => ({ ...u, price: { ...u.price, value: 0 } }));
  }),
  no_activity: _replaceUnits(computedShowcaseData, (units) => {
    return units.map((u) => ({ ...u, availability: Availability.AVAILABLE }));
  }),
  goals_inside_range: {
    ..._replaceUnits(computedShowcaseData, [
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(2, 'day')),
      ...Array.from(Array(10), (_) => fakeUnit()),
    ]),
    project: {
      ...computedShowcaseData.project,
      dealsGoalStartDate: dayjs().subtract(8, 'day'),
      dealsGoalEndDate: dayjs().subtract(1, 'day'),
    },
  },
  goals_outside_range: {
    ..._replaceUnits(computedShowcaseData, [
      fakeSoldUnit(dayjs().subtract(5, 'day')),
      fakeSoldUnit(dayjs().subtract(4, 'day')),
      fakeSoldUnit(dayjs().subtract(3, 'day')),
      fakeSoldUnit(dayjs().subtract(3, 'day')),
      fakeSoldUnit(dayjs().subtract(3, 'day')),
      fakeSoldUnit(dayjs().subtract(1, 'day')),
      fakeSoldUnit(dayjs().subtract(1, 'day')),
      fakeSoldUnit(dayjs().subtract(1, 'day')),
      fakeSoldUnit(dayjs()),
      fakeSoldUnit(dayjs()),
      ...Array.from(Array(2), (_) => fakeUnit()),
    ]),
    project: {
      ...computedShowcaseData.project,
      dealsGoalStartDate: dayjs().subtract(8, 'day'),
      dealsGoalEndDate: dayjs().add(3, 'day'),
    },
  },
  goals_overlap_range_1: {
    ..._replaceUnits(computedShowcaseData, [
      fakeSoldUnit(dayjs().subtract(23, 'day')),
      fakeSoldUnit(dayjs().subtract(15, 'day')),
      fakeSoldUnit(dayjs().subtract(15, 'day')),
      fakeSoldUnit(dayjs().subtract(12, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(8, 'day')),
      fakeSoldUnit(dayjs().subtract(8, 'day')),
      fakeSoldUnit(dayjs().subtract(4, 'day')),
      fakeSoldUnit(dayjs().subtract(3, 'day')),
      fakeSoldUnit(dayjs().subtract(2, 'day')),
      ...Array.from(Array(20), (_) => fakeUnit()),
    ]),
    project: {
      ...computedShowcaseData.project,
      dealsGoalStartDate: dayjs().subtract(25, 'day'),
      dealsGoalEndDate: dayjs().subtract(3, 'day'),
    },
  },
  goals_overlap_range_2: {
    ..._replaceUnits(computedShowcaseData, [
      fakeSoldUnit(dayjs().subtract(23, 'day')),
      fakeSoldUnit(dayjs().subtract(15, 'day')),
      fakeSoldUnit(dayjs().subtract(15, 'day')),
      fakeSoldUnit(dayjs().subtract(12, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(10, 'day')),
      fakeSoldUnit(dayjs().subtract(8, 'day')),
      fakeSoldUnit(dayjs().subtract(8, 'day')),
      fakeSoldUnit(dayjs().subtract(4, 'day')),
      fakeSoldUnit(dayjs().subtract(3, 'day')),
      fakeSoldUnit(dayjs().subtract(2, 'day')),
      ...Array.from(Array(20), (_) => fakeUnit()),
    ]),
    project: {
      ...computedShowcaseData.project,
      dealsGoalStartDate: dayjs().subtract(15, 'day'),
      dealsGoalEndDate: dayjs().add(6, 'day'),
    },
  },
};
