import { Select, SelectOption, Size } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

interface TimeSelectProps {
  value: string | undefined;
  onChange: (val: string) => void;
  options: Array<SelectOption<string>>;
}

const styles = {
  select: css`
    width: 90px;
    [data-element='select'] {
      padding-right: 23px;
    }
  `,
};

export const TimeSelect = ({ value, onChange, options }: TimeSelectProps) => {
  return (
    <Select
      className={styles.select}
      onChange={onChange}
      options={options}
      value={value}
      size={Size.SMALL}
    />
  );
};
