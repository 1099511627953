import sv from '@drawbotics/drylus-style-vars';
import { css } from 'emotion';
import React from 'react';

const styles = {
  dashedLineVertical: css`
    height: 100%;
    width: 1px;
    border-left: 1px dashed ${sv.grey300};
  `,
  dashedLineHorizontal: css`
    height: 1px;
    width: 100%;
    border-top: 1px dashed ${sv.grey300};
  `,
};

interface DashedLineProps {
  horizontal?: boolean;
}

export const DashedLine = ({ horizontal }: DashedLineProps) => {
  return (
    <div
      className={horizontal === true ? styles.dashedLineHorizontal : styles.dashedLineVertical}
    />
  );
};
