import gql from 'fraql';
import { useState } from 'react';

import { ID } from '~/types';
import { useMosaicMutation } from '~/utils';

import { Diffusion } from '../domain';

const UnpublishDiffusionMutation = gql`
  mutation UnpublishDiffusionMutation($input: UnpublishDiffusionInput!) {
    unpublishDiffusion(input: $input) {
      diffusion {
        id
      }
    }
  }
`;

interface UnpublishDiffusionInput {
  diffusionId: ID;
}

interface UseUnpublishReturnType {
  isLoading: boolean;
  unpublish: (d: Diffusion) => Promise<void>;
}

export function useUnpublish(): UseUnpublishReturnType {
  const [isLoading, setIsLoading] = useState(false);
  const { executeMutation } = useMosaicMutation(UnpublishDiffusionMutation);

  const unpublish = async (d: Diffusion): Promise<void> => {
    setIsLoading(true);
    const input: UnpublishDiffusionInput = { diffusionId: d.id };
    const res = await executeMutation({ input });
    setIsLoading(false);

    if (res.error != null) {
      throw res.error;
    }
  };

  return { isLoading, unpublish };
}
