import { Attachment } from '~/types';

import { Lead } from './lead';
import { Step } from './organisation';
import { Project } from './project';
import { User } from './user';

export enum OfferOptions {
  GIVEN_PRICE = 'GIVEN_PRICE',
  WITHOUT_CONDITION = 'WITHOUT_CONDITION',
  NO_CREDIT_PRECEDENCE = 'NO_CREDIT_PRECEDENCE',
}
export enum LogType {
  MAIL = 'MAIL',
  MEETING = 'MEETING',
  REACH_MEETING = 'REACH_MEETING',
  REACH_CANCELLED_MEETING = 'REACH_CANCELLED_MEETING',
  NOTE = 'NOTE',
  PHONE_CALL = 'PHONE_CALL',
  FIRST_LEAD_ASSIGNMENT = 'FIRST_LEAD_ASSIGNMENT',
  STATUS_CHANGE = 'FUNNEL_STEP_CHANGE',
  LEAD_ARRIVAL = 'LEAD_ARRIVAL',
  LEAD_ASSIGNMENT = 'LEAD_ASSIGNMENT',
  LEAD_ADDITION = 'LEAD_ADDITION',
  DOCUMENT = 'OFFER',
}
export interface BaseLog {
  id: string;
  content?: string;
  date: string;
  pinned?: boolean;
  type: LogType;
  lead: Lead;
}
export interface DocumentLog extends BaseLog {
  type: LogType.DOCUMENT;
  author?: User;
  offer: {
    id: string;
    attachments: Array<Attachment>;
  };
  projects: Array<Project>;
}
export interface MailLog extends BaseLog {
  type: LogType.MAIL;
  projects: Array<Project>;
  author?: User;
}
export interface MeetingLog extends BaseLog {
  type: LogType.MEETING;
  projects: Array<Project>;
  author?: User;
}
export interface NoteLog extends BaseLog {
  type: LogType.NOTE;
  projects: Array<Project>;
  author?: User;
}
export interface PhoneCallLog extends BaseLog {
  type: LogType.PHONE_CALL;
  projects: Array<Project>;
  author?: User;
}
export interface FirstLeadAssignmentLog extends BaseLog {
  type: LogType.FIRST_LEAD_ASSIGNMENT;
  author?: User;
  salesperson: User;
}
export interface StepChangeLog extends BaseLog {
  type: LogType.STATUS_CHANGE;
  nextStep: Step;
  previousStep: Step;
  author?: User;
}
export interface LeadArrivalLog extends BaseLog {
  type: LogType.LEAD_ARRIVAL;
}
export interface LeadAssignmentLog extends BaseLog {
  type: LogType.LEAD_ASSIGNMENT;
  nextSalesperson: User;
  previousSalesperson: User;
  author?: User;
}
export interface LeadAdditionLog extends BaseLog {
  type: LogType.LEAD_ADDITION;
  author?: User;
}
export interface ReachMeetingLog extends BaseLog {
  type: LogType.REACH_MEETING;
  author?: User;
  projects: Array<Project>;
  meeting: {
    id: string
  };
}

export interface ReachCancelledMeetingLog extends BaseLog {
  type: LogType.REACH_CANCELLED_MEETING;
  author?: User;
  projects: Array<Project>;
}

export type ManualLogType = LogType.MEETING | LogType.NOTE | LogType.PHONE_CALL | LogType.MAIL;
export type Log =
  | DocumentLog
  | MailLog
  | MeetingLog
  | ReachMeetingLog
  | ReachCancelledMeetingLog
  | NoteLog
  | PhoneCallLog
  | FirstLeadAssignmentLog
  | StepChangeLog
  | LeadArrivalLog
  | LeadAssignmentLog
  | LeadAdditionLog;
