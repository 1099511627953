import { InlineEdit, Input, Size } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css } from 'emotion';
import React, { useState } from 'react';

import { run } from '~/utils';

import { Website } from '../../../api/domain';
import { SocialMediaPlatform, Theme, ThemeDefinition } from '../../../types';

const styles = {
  light: css`
    opacity: 0.3;
  `,
};

type SocialMedia = Required<Website>['socialMedia'];

interface SocialMediaIconProps<K extends keyof SocialMedia> {
  name: K;
  value: SocialMedia[K];
  placeholder: string;
  onCancel: (name: K) => void;
  onClickConfirm: (v: SocialMedia[K]) => void;
}

export const SocialMediaIcon = <K extends keyof SocialMedia>({
  name,
  value,
  placeholder,
  onCancel,
  onClickConfirm,
}: SocialMediaIconProps<K>) => {
  const theme = useTheme<ThemeDefinition>();
  const [newValue, setValue] = useState(value);
  return (
    <InlineEdit
      onCancel={() => onCancel(name)}
      onClickConfirm={() => onClickConfirm(newValue)}
      edit={
        <Input
          size={Size.SMALL}
          name={name}
          placeholder={placeholder}
          value={newValue ?? ''}
          onChange={(v) => setValue(v as string)}
        />
      }>
      <div>
        {run(() => {
          if (name === SocialMediaPlatform.FACEBOOK) {
            return (
              <FacebookIcon
                color={theme.id === Theme.TRADITIONAL ? '#000' : '#fff'}
                newValue={newValue}
              />
            );
          } else if (name === SocialMediaPlatform.INSTAGRAM) {
            return (
              <InstagramIcon
                color={theme.id === Theme.TRADITIONAL ? '#000' : '#fff'}
                newValue={newValue}
              />
            );
          } else if (name === SocialMediaPlatform.LINKEDIN) {
            return (
              <LinkedinIcon
                color={theme.id === Theme.TRADITIONAL ? '#000' : '#fff'}
                newValue={newValue}
              />
            );
          } else if (name === SocialMediaPlatform.TWITTER) {
            return (
              <TwitterIcon
                color={theme.id === Theme.TRADITIONAL ? '#000' : '#fff'}
                newValue={newValue}
              />
            );
          }
        })}
      </div>
    </InlineEdit>
  );
};

const FacebookIcon = ({ color, newValue }: any) => {
  return (
    <svg
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={[newValue == null || newValue === '' ? styles.light : ''].join(' ')}>
      <path d="M8.4787 10.6125L8.95772 7.51517H6.03569V5.48255C6.03569 4.61143 6.4668 3.7887 7.76017 3.7887H9.10143V1.12693C9.10143 1.12693 7.90387 0.93335 6.70632 0.93335C4.2633 0.93335 2.68253 2.43362 2.68253 5.09539V7.46678H0V10.5641H2.68253V18.0655H5.98778V10.6125H8.4787Z" />
    </svg>
  );
};

const InstagramIcon = ({ color, newValue }: any) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={[newValue == null || newValue === '' ? styles.light : ''].join(' ')}>
      <path d="M14.2911 3.54272C13.7188 3.54272 13.1986 4.01091 13.1986 4.63517C13.1986 5.2074 13.6668 5.72761 14.2911 5.72761C14.8633 5.72761 15.3835 5.25942 15.3835 4.63517C15.3835 4.06294 14.9153 3.54272 14.2911 3.54272Z" />
      <path d="M9.50509 4.94751C7.00807 4.94751 4.97925 6.97633 4.97925 9.47335C4.97925 11.9704 7.00807 13.9992 9.50509 13.9992C12.0021 13.9992 14.0309 11.9704 14.0309 9.47335C14.0309 6.97633 12.0021 4.94751 9.50509 4.94751ZM9.50509 12.3865C7.89243 12.3865 6.5919 11.086 6.5919 9.47335C6.5919 7.86069 7.89243 6.56016 9.50509 6.56016C11.1177 6.56016 12.4183 7.86069 12.4183 9.47335C12.4183 11.086 11.1177 12.3865 9.50509 12.3865Z" />
      <path d="M13.0946 18.6811H5.75961C2.69036 18.6811 0.245361 16.1841 0.245361 13.1669V5.83187C0.245361 2.76262 2.74238 0.317627 5.75961 0.317627H13.0946C16.1638 0.317627 18.6088 2.81464 18.6088 5.83187V13.1669C18.6609 16.1841 16.1638 18.6811 13.0946 18.6811ZM5.75961 2.03433C3.67876 2.03433 1.96206 3.75102 1.96206 5.83187V13.1669C1.96206 15.2477 3.67876 16.9644 5.75961 16.9644H13.0946C15.1754 16.9644 16.8921 15.2477 16.8921 13.1669V5.83187C16.8921 3.75102 15.1754 2.03433 13.0946 2.03433H5.75961Z" />
    </svg>
  );
};

const LinkedinIcon = ({ color, newValue }: any) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={[newValue == null || newValue === '' ? styles.light : ''].join(' ')}>
      <path d="M4.81669 6.48267H1.0592V18.7078H4.81669V6.48267Z" />
      <path d="M2.96444 4.8421C4.18166 4.8421 5.18719 3.83658 5.18719 2.56644C5.18719 1.2963 4.18166 0.290771 2.96444 0.290771C1.74722 0.290771 0.741699 1.2963 0.741699 2.56644C0.741699 3.83658 1.6943 4.8421 2.96444 4.8421Z" />
      <path d="M10.9029 12.2513C10.9029 10.5577 11.6967 9.49929 13.1785 9.49929C14.5545 9.49929 15.2425 10.5048 15.2425 12.2513C15.2425 13.9977 15.2425 18.6549 15.2425 18.6549H19C19 18.6549 19 14.2094 19 10.9282C19 7.647 17.1477 6.05933 14.5545 6.05933C11.9613 6.05933 10.9029 8.07038 10.9029 8.07038V6.42978H7.25122V18.6549H10.85C10.9029 18.6549 10.9029 14.1565 10.9029 12.2513Z" />
    </svg>
  );
};

const TwitterIcon = ({ color, newValue }: any) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={[newValue == null || newValue === '' ? styles.light : ''].join(' ')}>
      <path d="M21.0404 2.7776C20.3605 3.07753 19.632 3.27749 18.952 3.37747C19.292 3.32748 19.7777 2.67761 19.9719 2.42767C20.2633 2.02775 20.5062 1.57784 20.6519 1.12793C20.6519 1.07794 20.7004 1.02795 20.6519 1.02795C20.6033 1.02795 20.5547 1.02795 20.5547 1.02795C19.7777 1.47786 18.952 1.7778 18.0292 2.02775C17.9807 2.02775 17.8835 2.02775 17.835 1.97776C17.7378 1.87778 17.6893 1.82779 17.5921 1.72781C17.2036 1.42787 16.8151 1.12793 16.378 0.977964C15.7952 0.728015 15.1638 0.628035 14.5324 0.678025C13.901 0.728015 13.3182 0.877984 12.784 1.17792C12.2497 1.47786 11.7641 1.87778 11.3755 2.37768C10.987 2.87757 10.6956 3.47745 10.5499 4.12732C10.4042 4.7272 10.4042 5.37706 10.5013 5.97694C10.5013 6.07692 10.5013 6.07692 10.4042 6.07692C6.95592 5.52703 4.13902 4.27729 1.80779 1.57784C1.71065 1.47786 1.66209 1.47786 1.56495 1.57784C0.54504 3.12752 1.03071 5.62701 2.29346 6.87676C2.43916 7.02672 2.63343 7.22668 2.8277 7.37665C2.77913 7.37665 1.90492 7.27667 1.17642 6.87676C1.07928 6.82677 1.03071 6.87675 1.03071 7.02672C1.03071 7.17669 1.03071 7.32666 1.07928 7.52662C1.27355 9.12629 2.34203 10.576 3.79904 11.1259C3.99331 11.2259 4.18759 11.2759 4.33329 11.2759C3.99332 11.3758 3.65335 11.4258 2.682 11.3258C2.58487 11.3258 2.5363 11.3758 2.58486 11.4758C3.31337 13.4754 4.86752 14.0753 5.98457 14.4252C6.13027 14.4752 6.27597 14.4752 6.47024 14.4752C6.47024 14.4752 6.47024 14.4752 6.42168 14.5252C6.03314 15.1251 4.72183 15.575 4.09045 15.7749C2.9734 16.1748 1.71065 16.3748 0.545041 16.2248C0.350772 16.1749 0.302205 16.1749 0.253638 16.2248C0.205071 16.2748 0.253637 16.3248 0.302204 16.3748C0.545041 16.5248 0.787877 16.6747 1.03071 16.8247C1.80779 17.2246 2.58487 17.5746 3.41051 17.8245C7.68443 19.0243 12.4926 18.1245 15.6495 14.8751C18.175 12.3256 19.0492 8.77637 19.0492 5.22709C19.0492 5.07712 19.1949 5.02713 19.292 4.92715C19.9234 4.42726 20.4576 3.82738 20.9433 3.12752C21.0404 2.97755 21.0404 2.82758 21.0404 2.7776C21.1376 2.7776 21.1376 2.72761 21.0404 2.7776Z" />
    </svg>
  );
};
