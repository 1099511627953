import { Color, dateToObject } from '@drawbotics/react-drylus';
import * as ics from 'ics-browser';

import { createTranslate } from '~/utils';

import { Meeting, MeetingLocation, MeetingStatus } from '../types';
import { dateToTimeObject } from './time';

const tt = createTranslate('pods.marketing_suite.calendar_event');

export function isMeetingUnactionable(meeting: Meeting): boolean {
  return meeting.status !== MeetingStatus.PLANNED;
}

export function getColorForMeeting(meeting: Meeting): Color | undefined {
  switch (meeting.status) {
    case MeetingStatus.PLANNED:
      return Color.BLUE;
    case MeetingStatus.CANCELLED:
      return Color.ORANGE;
    case MeetingStatus.FINISHED:
    case MeetingStatus.ARCHIVED:
      return undefined;
  }
}

export function downloadICalFile(meeting: Meeting) {
  const dateObject = dateToObject(new Date(meeting.date));
  const timeObject = dateToTimeObject(new Date(meeting.date));

  const event = {
    start: [dateObject.year, dateObject.month, dateObject.day, timeObject.hour, timeObject.minute],
    duration: { hours: 1, minutes: 0 },
    title: tt('meeting_with', { organizer: meeting.user.fullName }),
    description: `${tt('description', { organizer: meeting.user.fullName })}
    
${
  meeting.location === MeetingLocation.PHYSICAL
    ? tt('meeting_at', { address: meeting.address! })
    : tt('join_with_link', { link: meeting.meetingLink! })
}`,
    location: meeting.location === MeetingLocation.PHYSICAL ? meeting.address : tt('remote'),
    status: 'CONFIRMED',
    url: meeting.meetingLink,
    organizer: { name: meeting.user.fullName, email: meeting.user.email },
    attendees: meeting.additionalParticipants?.map((email) => ({ email, name: email })) ?? [],
  };

  ics.createEvent(event, (error: Error, value: string) => {
    if (error != null) {
      console.error(error);
    } else {
      const withUnescaped = value.replace(/https\\:/gm, 'https:');
      const data = new Blob([withUnescaped], { type: 'text/ics' });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.download = 'meeting.ics';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  });
}
