import { CheckboxFilter } from '@drawbotics/react-drylus';
import { cx } from 'emotion';
import React from 'react';

import { EngagementLevelEnum, LeadsFilterTypes } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

import { filterStyles } from './filter-styles';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');
const te = createTranslate('pods.crm');

interface EngagementLevelFilterProps {
  filter: LeadsFilterTypes['engagementLevel'];
}

export const EngagementLevelFilter = ({ filter }: EngagementLevelFilterProps) => {
  const options = Object.values(EngagementLevelEnum).map((level) => ({
    value: level,
    label: te(`engagement_level.${level.toLowerCase()}`),
  }));

  const values: string[] = filter.values
    ? filter.values.filter((value): value is string => typeof value === 'string')
    : [];

  return (
    <CheckboxFilter
      label={tt('titles.engagement_level')}
      className={cx(filterStyles.filter)}
      fullWidth
      options={options}
      values={values}
      onChange={filter.set}
      onClear={() => filter.set(null)}
    />
  );
};
