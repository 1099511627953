import gql from 'fraql';

import { Organisation } from '~/pods/meetings/types';
import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface OrganisationQueryData {
  organisation: Organisation;
}

const OrganisationQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      primaryColor
    }
  }
`;

export function useFetchOrganisationBranding(): {
  isLoading: boolean;
  organisation?: Organisation;
  error?: ErrorType;
} {
  const res = useMosaicQuery<OrganisationQueryData, null>({
    query: OrganisationQuery,
  });

  return {
    isLoading: res.isLoading,
    organisation: res.data?.organisation,
    error: buildError(res.error),
  };
}
