import { ze } from '@tokamakjs/common';
import gql from 'fraql';
import { z } from 'zod';

import { ID } from '~/types';
import { useMosaicQuery } from '~/utils';

import { Diffusion } from '../domain';
import { DiffusionStatus } from '../types';

const LoadDiffusionsQuery = gql`
  query LoadDiffusionsQuery($advertId: ID!) {
    diffusions(advertId: $advertId) {
      id
      portal {
        id
        slug
        ubiflowPortalCode
        name
      }
      endDiffusionDate
      startDiffusionDate
      status
    }
  }
`;

const LoadDiffusionsDataSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  diffusions: z.array(
    z.object({
      id: ze.id(),
      endDiffusionDate: z.string(),
      startDiffusionDate: z.string(),
      status: z.nativeEnum(DiffusionStatus),
      portal: z.object({
        id: ze.id(),
        slug: z.string(),
        ubiflowPortalCode: z.string(),
        name: z.string(),
      }),
    }),
  ),
});

interface UseLoadDiffusionsReturnType {
  isLoadingDiffusions: boolean;
  diffusions: Array<Diffusion>;
  refreshDiffusions: () => Promise<void>;
}

export function useLoadDiffusions(advertId?: ID): UseLoadDiffusionsReturnType {
  const res = useMosaicQuery({
    query: LoadDiffusionsQuery,
    pause: advertId == null,
    variables: { advertId },
  });

  if (advertId == null) {
    return { isLoadingDiffusions: false, diffusions: [], refreshDiffusions: async () => {} };
  }

  if (res.isLoading || res.data == null) {
    return { isLoadingDiffusions: true, diffusions: [], refreshDiffusions: async () => {} };
  }

  const { diffusions } = LoadDiffusionsDataSchema.parse(res.data);

  return {
    isLoadingDiffusions: false,
    diffusions: diffusions.map((d) => Diffusion.fromData(d)),
    refreshDiffusions: async () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
