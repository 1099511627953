import {
  Button,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  Margin,
  Separator,
  Size,
  Table,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment } from 'react';

import { TextToClipboard } from '~/components';
import { Campaign } from '~/pods/insights/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.campaigns');

const styles = {
  container: css`
    width: 100%;
  `,
};

interface CampaignHeaderProps {
  campaign: Campaign;
  onClickEditCampaign: (campaign: Campaign) => void;
}

export const CampaignHeader = ({ campaign, onClickEditCampaign }: CampaignHeaderProps) => {
  const { launchedAt, name, source, destinationUrl } = campaign;

  return (
    <Fragment>
      <Label>{tt('created_on', { date: launchedAt.format('MMMM M, YYYY') })}</Label>
      <Margin size={{ vertical: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.START}>
          <FlexItem>
            <Title noMargin size={3}>
              {name}
            </Title>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              onClick={() => {
                onClickEditCampaign(campaign);
              }}
              size={Size.SMALL}
              tier={Tier.SECONDARY}>
              {tt('edit')}
            </Button>
          </FlexItem>
        </Flex>
      </Margin>
      <Text tier={Tier.TERTIARY}> {`${tt('broadcasting_channel')} - ${source}`}</Text>
      <Margin size={Size.EXTRA_SMALL} />
      <TextToClipboard
        style={{ width: '400px' }}
        size={Size.SMALL}
        text={destinationUrl.toString()}
      />
      <Margin size={Size.DEFAULT} />
      <Separator />
    </Fragment>
  );
};

interface CampaignComparisonProps {
  campaigns: Array<Campaign>;
  onClickEditCampaign: (campaign: Campaign) => void;
}

export const CampaignComparison = ({ campaigns, onClickEditCampaign }: CampaignComparisonProps) => {
  if (campaigns.length === 0) return null;

  return (
    <div className={styles.container}>
      {campaigns.length === 1 ? (
        <CampaignHeader campaign={campaigns[0]} onClickEditCampaign={onClickEditCampaign} />
      ) : null}
      <Table
        header={[
          { label: tt('name'), value: 'name' },
          { label: tt('visitors'), value: 'visitsCount' },
          { label: tt('leads'), value: 'leadsCount' },
          { label: tt('conversion_rate'), value: 'hConversionRate' },
        ]}
        data={campaigns}
      />
    </div>
  );
};
