import { Currency } from '~/types';
import { displaySurface, translatePrice } from '~/utils';

import {
  DocumentLog,
  Log,
  LogType,
  MailLog,
  ManualLogType,
  MeetingLog,
  NoteLog,
  PhoneCallLog,
  Range,
} from '../types';

export const projectPriceRange = [150000, 2000000];
export const getProjectPriceOptions = ({
  currency = 'EUR',
  bottomLimit,
  topLimit,
}: {
  currency?: Currency;
  bottomLimit?: number;
  topLimit?: number;
}) => {
  const baseSteps = [
    150000,
    200000,
    250000,
    300000,
    350000,
    400000,
    450000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    1500000,
    2000000,
  ];

  if (bottomLimit == null && topLimit == null) {
    return baseSteps.map((step) => ({
      label: translatePrice(step, currency),
      value: step,
    }));
  } else if (bottomLimit == null && topLimit != null) {
    return baseSteps
      .filter((step) => step < topLimit)
      .map((step) => ({
        label: translatePrice(step, currency),
        value: step,
      }));
  } else if (bottomLimit != null && topLimit == null) {
    return baseSteps
      .filter((step) => step > bottomLimit)
      .map((step) => ({
        label: translatePrice(step, currency),
        value: step,
      }));
  }
};

export const projectSurfaceRange = [25, 350];
export const getProjectSurfaceOptions = ({
  surfaceUnit = 'sqm',
  bottomLimit,
  topLimit,
}: {
  surfaceUnit?: 'sqm' | 'sqft';
  bottomLimit?: number;
  topLimit?: number;
}) => {
  const baseSteps = [25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350];

  if (bottomLimit == null && topLimit == null) {
    return baseSteps.map((step) => ({
      label: displaySurface(step, surfaceUnit),
      value: step,
    }));
  } else if (bottomLimit == null && topLimit != null) {
    return baseSteps
      .filter((step) => step < topLimit)
      .map((step) => ({
        label: displaySurface(step, surfaceUnit),
        value: step,
      }));
  } else if (bottomLimit != null && topLimit == null) {
    return baseSteps
      .filter((step) => step > bottomLimit)
      .map((step) => ({
        label: displaySurface(step, surfaceUnit),
        value: step,
      }));
  }
};

export const unitTypeOptions: {
  labels: Record<string, string>;
  shortLabels: Record<string, string>;
} = {
  labels: {
    1: 'studio_apartment',
    2: '1_bedroom_apartment',
    3: '2_bedrooms_apartment',
    4: '3_bedrooms_apartment',
    5: '4_bedrooms_apartment',
  },
  shortLabels: {
    1: 'studio',
    2: '1_bedroom',
    3: '2_bedrooms',
    4: '3_bedrooms',
    5: '4_bedrooms',
  },
};

export function getRangeStep(max: number, min: number, amount: number): number {
  const rawStep = Math.round((max - min) / amount);

  if (rawStep === 0) {
    return 1;
  }

  const magnitude = String(rawStep).length - 1;
  const number = Math.pow(10, magnitude);

  // https://stackoverflow.com/a/23917134/2137368
  return Math.round(rawStep / number) * number;
}

export function getClosestRange(max: number, min: number, step: number): Range {
  return [Math.floor(min / step) * step, Math.ceil(max / step) * step];
}

export function isManualLogType(type: LogType): type is ManualLogType {
  return (
    type === LogType.MAIL ||
    type === LogType.MEETING ||
    type === LogType.NOTE ||
    type === LogType.PHONE_CALL
  );
}

export function isLogWithProject(
  log: Log,
): log is DocumentLog | MailLog | MeetingLog | NoteLog | PhoneCallLog {
  return 'projects' in log;
}
