import {
  Button,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexDirection,
  FlexItem,
} from '@drawbotics/react-drylus';
import React, { ReactNode, createContext, useContext, useState } from 'react';

import { BasicNavbar, NavbarLayout } from '~/components';

const NotFoundContext = createContext({
  notFound: false,
  triggerNotFound: () => {},
});

export function useTriggerNotFound() {
  const { triggerNotFound } = useContext(NotFoundContext);
  return triggerNotFound;
}

export const NotFoundProvider = ({ children }: { children: ReactNode }) => {
  const [notFound, setNotFound] = useState(false);

  if (notFound) {
    document.title = 'Mosaic - 404';
    return (
      <NavbarLayout navbar={<BasicNavbar />}>
        <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <EmptyState
              title="404 - Page Not Found"
              description={`
              We're sorry, but the page you requested cannot be
              found, perhaps it has been moved, or is no longer available.
              You may go back to the last page you visited, or return to the home page.
            `}
              variation={EmptyStateVariation.NOT_FOUND}>
              <Button onClick={() => (window.location.href = '/')} category={Category.INFO}>
                Return to home page
              </Button>
            </EmptyState>
          </FlexItem>
        </Flex>
      </NavbarLayout>
    );
  }

  return (
    <NotFoundContext.Provider
      value={{
        notFound,
        triggerNotFound: () => setNotFound(true),
      }}>
      {children}
    </NotFoundContext.Provider>
  );
};
