import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  DropdownOption,
  EmptyState,
  Icon,
  ListTile,
  Padding,
  ShowDateTime,
  Size,
  Table,
  TableEntry,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';

import { CapitalFirst } from '~/components';
import { Status } from '~/pods/crm/components';
import { EngagementLevelEnum, Lead, LeadsFilterTypes, SortBy } from '~/pods/crm/types';
import { createTranslate, translate, useAuth, useMosaicNavigation } from '~/utils';

import { EngagementLevel } from '../../Lead/components';
import { useUpdateLead } from '~/pods/crm/utils';
import { Id } from '~/types';

const styles = {
  table: css`
    white-space: nowrap;

    table > tbody > tr > td:first-of-type {
      width: 30px;
    }
  `,
};

const tt = createTranslate('pods.crm.routes.leads.components.leads_table');

interface LeadsTableProps {
  isLoading: boolean;
  selectedLeads: Array<string>;
  toggleLeadSelection: (leadId: string) => void;
  toggleAllLeadsSelection: VoidFunction;
  leads: undefined | Array<Lead>;
  sortBy: LeadsFilterTypes['sortBy'];
  loadingRows: number;
  onClickNewMeeting: (lead: Lead) => void;
  onClickNewLog: (lead: Lead) => void;
  refetchLeads: () => void
}

export const LeadsTable = ({
  isLoading,
  leads,
  toggleLeadSelection,
  toggleAllLeadsSelection,
  selectedLeads,
  sortBy,
  loadingRows,
  onClickNewMeeting,
  onClickNewLog,
  refetchLeads
}: LeadsTableProps) => {
  const { user } = useAuth();
  const { navigateInOrg, getUrlInOrg, getUrlInProject, navigateInProject, projectToken } =
    useMosaicNavigation();

  const [leadsBeingUpdated, setLeadsBeingUpdated] = useState<string[]>([]);

  const { updateLead } = useUpdateLead();

  const handleUpdateEngagement = async (engagementLevel: EngagementLevelEnum | null, leadId: Id) => {
    setLeadsBeingUpdated((s) => ([...s, leadId]))

    await updateLead({ id: leadId, engagementLevel });

    setLeadsBeingUpdated((s) => s.filter((id) => id !== leadId))

    refetchLeads()
  };

  const data = leads?.map((lead) => ({
    id: lead.id,
    checkBox: (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          onChange={() => toggleLeadSelection(lead.id)}
          value={selectedLeads.includes(lead.id)}
        />
      </div>
    ),
    name: (
      <ListTile
        leading={
          <div id="engagement-level-selector" style={{ position: 'relative', zIndex: 1 }}>
            <Padding size={Size.EXTRA_SMALL}>
              <EngagementLevel isLoading={leadsBeingUpdated.includes(lead.id)} size={16} smallSpinner engagementLevel={lead.engagementLevel} onChangeEngagement={(engagement) => handleUpdateEngagement(engagement, lead.id)} />
            </Padding>
          </div>

        }
        title={<Text size={Size.SMALL}>{lead.fullName}</Text>}
      />
    ),
    assignedTo: (() => {
      if (lead.user == null) return null;
      else if (user?.isAgent === lead.user?.organisation?.isAgency) {
        return <Text size={Size.SMALL}>{lead.user?.fullName}</Text>;
      } else {
        return (
          <ListTile
            leading={
              <Avatar
                style={{ width: '1.4em', height: '1.4em' }}
                image={lead.user.organisation?.logo?.url}
                text={lead.user.organisation?.name}
              />
            }
            title={<Text size={Size.SMALL}>{lead.user.organisation?.name}</Text>}
          />
        );
      }
    })(),
    status: <Status step={lead.step} />,
    updatedAt:
      lead.updatedAt != null ? (
        <CapitalFirst>
          <Text size={Size.SMALL} dateOptions={{ showTime: ShowDateTime.NEVER }}>
            {new Date(lead.updatedAt)}
          </Text>
        </CapitalFirst>
      ) : null,
    actions: (
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown trigger={<Button tier={Tier.TERTIARY} leading={<Icon name="menu-2" />} />}>
          <DropdownOption
            text={translate('pods.crm.routes.lead.components.log_list.new_meeting_button')}
            onClick={() => onClickNewMeeting(lead)}
          />
          <DropdownOption
            text={translate('pods.crm.routes.lead.components.log_list.new_log_button')}
            onClick={() => onClickNewLog(lead)}
          />
        </Dropdown>
      </div>
    ),
  }));

  const noData = data?.length === 0;

  const handleClickHeader = (key: string) => {
    if (key === 'updatedAt') {
      if (sortBy.value === SortBy.UPDATED_AT_ASC) {
        sortBy.set(SortBy.UPDATED_AT_DESC);
      } else {
        sortBy.set(SortBy.UPDATED_AT_ASC);
      }
    }
  };

  const handleClickRow = (row: TableEntry, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isEngagementSelector = (e.target as HTMLElement).matches("#engagement-level-selector *")

    if (isEngagementSelector) {
      return
    }

    const { id } = row;
    if (id == null) return;

    if (e.metaKey || e.ctrlKey) {
      projectToken != null
        ? window.open(getUrlInProject('/crm', String(id)), '_blank')
        : window.open(getUrlInOrg('/crm', String(id)), '_blank');
    } else {
      projectToken != null
        ? navigateInProject('/crm', String(id))
        : navigateInOrg('/crm', String(id));
    }
  };

  return (
    <div className={styles.table}>
      <Table
        size={Size.SMALL}
        scrollable
        clickable
        highlighted={noData ? false : true}
        activeHeader={
          sortBy.value != null
            ? {
              key: 'updatedAt',
              direction: sortBy.value === SortBy.UPDATED_AT_ASC ? 'asc' : 'desc',
            }
            : undefined
        }
        isLoading={isLoading && data == null}
        data={data}
        loadingRows={loadingRows}
        onClickRow={handleClickRow}
        emptyContent={
          noData ? (
            <div style={{ textAlign: 'center', whiteSpace: 'normal' }}>
              <EmptyState description={tt('empty_state')} />
            </div>
          ) : null
        }
        header={[
          {
            label: (
              <Checkbox
                onChange={toggleAllLeadsSelection}
                value={selectedLeads.length > 0}
                indeterminate={selectedLeads.length !== leads?.length}
              />
            ),
            value: 'checkBox',
          },
          { label: tt('headers.name'), value: 'name' },
          { label: tt('headers.assigned_to'), value: 'assignedTo' },
          { label: tt('headers.status'), value: 'status' },
          { label: tt('headers.updated_at'), value: 'updatedAt' },
          { label: '', value: 'actions' },
        ]}
        sortableBy={['updatedAt']}
        onClickHeader={handleClickHeader}
      />
    </div>
  );
};
