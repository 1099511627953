export * from './showcase-data';
export * from './showcase-token-data';

import { EN_PROJECT_IDENTIFIER, FR_PROJECT_IDENTIFIER, SHOWCASE_IDENTIFIER } from '~/constants';

import { leads, sessions } from './generate';

const showcaseLeadsData = leads;
const showcaseSessionsData = sessions;

export { showcaseLeadsData, showcaseSessionsData };

export function shouldUseShowcaseData(slug: string) {
  return (
    process.env.APP_ENV !== 'production' &&
    (slug === SHOWCASE_IDENTIFIER ||
      slug === FR_PROJECT_IDENTIFIER ||
      slug === EN_PROJECT_IDENTIFIER)
  );
}
