import { DateRange, InsightsLead, LineData } from '../../types';
import { getDataInRange } from '../get-data-in-range';

export function useLoadNumberOfLeadsOverRange(
  leads: Array<InsightsLead>,
  range: DateRange,
): { data: LineData } {
  const data = getDataInRange(range, leads, 'createdAt');
  return { data };
}
