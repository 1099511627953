import {
  ButtonLink,
  Color,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  LoadingPlaceholder,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  PanelSection,
  Size,
  Table,
  Tag,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import { css } from 'emotion';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Report, ReportType } from '~/pods/insights/types';
import { createTranslate, run } from '~/utils';

const tt = createTranslate('pods.insights.routes.reports');

const styles = {
  reportsTable: css``,
};

const REPORTS_PER_PAGE = 10;

const tableHeader = [
  {
    label: tt('reports'),
    value: 'name',
  },
  {
    label: tt('date'),
    value: 'dateRange',
  },
  {
    label: tt('type'),
    value: 'type',
  },
  {
    label: tt('download'),
    value: 'downloadUrl',
  },
];

interface ReportsTableProps {
  isLoading: boolean;
  reports: Array<Report>;
}

export const ReportsTable = ({ isLoading, reports }: Readonly<ReportsTableProps>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = useFilters(location, navigate, ['page']);

  const totalPages = Math.ceil(reports.length / REPORTS_PER_PAGE) ?? 0;
  const currentPage =
    page.value != null && Number(page.value) > 0 && Number(page.value) <= totalPages
      ? Number(page.value)
      : 1;

  const tableData = reports
    .slice(
      (currentPage - 1) * REPORTS_PER_PAGE,
      (currentPage - 1) * REPORTS_PER_PAGE + REPORTS_PER_PAGE,
    )
    .map((report) => {
      return {
        name: report.name,
        dateRange: `${report.dateRange.start.format('MMM D')} - ${report.dateRange.end.format(
          'MMM D',
        )}`,
        type:
          report.type === ReportType.WEEKLY ? (
            <Tag color={Color.BLUE}>Weekly</Tag>
          ) : (
            <Tag color={Color.ORANGE}>Monthly</Tag>
          ),
        downloadUrl: (
          <a href={report.downloadUrl} target="_blank" rel="noopener noreferrer">
            <ButtonLink size={Size.SMALL} leading={<Icon name="download" />} />
          </a>
        ),
      };
    });
  return (
    <div className={styles.reportsTable}>
      <Panel
        body={
          <PanelBody noPadding>
            <PanelSection>
              <Table
                isLoading={isLoading}
                header={tableHeader}
                data={tableData}
                emptyContent={tableData.length > 0 ? undefined : <EmptyState />}
              />
            </PanelSection>
          </PanelBody>
        }
        footer={
          reports.length > 0 ? (
            <PanelFooter>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  {run(() => {
                    if (isLoading) {
                      return <LoadingPlaceholder height={30} width={200} />;
                    } else {
                      return (
                        <Pagination
                          value={currentPage}
                          pages={totalPages}
                          onChange={(v) => page.set(String(v))}
                        />
                      );
                    }
                  })}
                </FlexItem>
              </Flex>
            </PanelFooter>
          ) : undefined
        }
      />
    </div>
  );
};
