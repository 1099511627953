import {
  AttachmentBox,
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FormGroup,
  Input,
  Label,
  Margin,
  NumberInput,
  Select,
  Size,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { Form } from '@drawbotics/use-form';
import { truncate } from 'lodash';
import React, { Fragment } from 'react';

import { EditableUnitFields, Listing, UnitAvailability } from '~/pods/listing-manager/types';
import { currencyToSymbol, displaySurface, useFileUpload, useMosaicMutation } from '~/utils';
import { addCurrency, createTranslate } from '~/utils/translation';

import { AvailabilityInput } from './AvailabilityInput';

const tt = createTranslate('pods.listing_manager.components.unit_editor');

interface UnitEditorProps {
  form: Form<EditableUnitFields>;
  listing: Listing;
  floorplanUrl?: string;
  isAgent: boolean;
}

export const UnitEditor = ({ form, listing, floorplanUrl, isAgent }: UnitEditorProps) => {
  const { values } = form;
  const { data, info, isLoading, uploadFiles } = useFileUpload(useMosaicMutation);

  const _handleUploadFile = async (fileList: FileList) => {
    const res = await uploadFiles(fileList);
    const uploadedFile = res.data[0];
    form.set(uploadedFile?.signedBlobId, 'floorplan');
  };

  const finalFloorplanUrl = data[0]?.url ?? floorplanUrl;

  const allowEditingAllFields = !isAgent;

  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
      {allowEditingAllFields && (
        <Fragment>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <Input
                    autoFocus
                    name="reference"
                    value={form.get}
                    onChange={form.set}
                    placeholder="A01"
                  />
                }
                label={<Label>{tt('name')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="bedrooms"
                    min={0}
                    value={form.get}
                    onChange={form.set}
                    placeholder="0"
                  />
                }
                label={<Label>{tt('bedrooms')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="rooms"
                    min={0}
                    value={form.get}
                    onChange={form.set}
                    placeholder="0"
                  />
                }
                label={<Label>{tt('rooms')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="bathrooms"
                    min={0}
                    value={form.get}
                    onChange={form.set}
                    placeholder="0"
                  />
                }
                label={<Label>{tt('bathrooms')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="price"
                    placeholder="0"
                    renderValue={(v: number | '-' | '') =>
                      v ? addCurrency(v, currencyToSymbol[listing.currency]) : ''
                    }
                    withCounter={false}
                    value={form.get}
                    onChange={form.set}
                  />
                }
                label={<Label>{tt('price')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="surface"
                    placeholder={displaySurface(0, listing.surfaceUnit)}
                    renderValue={(v: number | '-' | '') => displaySurface(v, listing.surfaceUnit)}
                    withCounter={false}
                    value={form.get}
                    onChange={form.set}
                  />
                }
                label={<Label>{tt('surface')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <NumberInput
                    name="terraceSurface"
                    placeholder={displaySurface(0, listing.surfaceUnit)}
                    renderValue={(v: number | '-' | '') => displaySurface(v, listing.surfaceUnit)}
                    withCounter={false}
                    value={form.get}
                    onChange={form.set}
                  />
                }
                label={<Label>{tt('terrace_surface')}</Label>}
              />
            </Margin>
          </FlexItem>
        </Fragment>
      )}
      <FlexItem flex>
        <Margin size={{ vertical: Size.EXTRA_SMALL }}>
          <AvailabilityInput
            availability={values.availability ?? UnitAvailability.AVAILABLE}
            onChangeAvailability={(v: UnitAvailability) => form.set(v, 'availability')}
            soldAt={values.soldAt}
            onChangeSoldAt={(v: string) => form.set(v, 'soldAt')}
          />
        </Margin>
      </FlexItem>
      {allowEditingAllFields && (
        <Fragment>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <FormGroup
                input={
                  <Select
                    name="orientation"
                    // todo: extract and type
                    options={[
                      {
                        label: tt('north'),
                        value: 'NORTH',
                      },
                      {
                        label: tt('south'),
                        value: 'SOUTH',
                      },
                      {
                        label: tt('east'),
                        value: 'EAST',
                      },
                      {
                        label: tt('west'),
                        value: 'WEST',
                      },
                      {
                        label: tt('northeast'),
                        value: 'NORTHEAST',
                      },
                      {
                        label: tt('northwest'),
                        value: 'NORTHWEST',
                      },
                      {
                        label: tt('southeast'),
                        value: 'SOUTHEAST',
                      },
                      {
                        label: tt('southwest'),
                        value: 'SOUTHWEST',
                      },
                      {
                        label: tt('north_south'),
                        value: 'NORTH_SOUTH',
                      },
                      {
                        label: tt('east_west'),
                        value: 'WEST_EAST',
                      },
                      {
                        label: tt('northeast_southwest'),
                        value: 'NORTHEAST_SOUTHWEST',
                      },
                      {
                        label: tt('northwest_southeast'),
                        value: 'NORTHWEST_SOUTHEAST',
                      },
                    ]}
                    value={form.get}
                    onChange={form.set}
                  />
                }
                label={<Label>{tt('orientation')}</Label>}
              />
            </Margin>
          </FlexItem>
          <FlexItem flex>
            <Margin size={{ vertical: Size.EXTRA_SMALL }}>
              <Flex>
                <FlexItem>
                  <UploadHelper
                    onUploadFiles={_handleUploadFile}
                    multiple={false}
                    allowedFileFormats=".pdf">
                    <Button size={Size.SMALL} category={Category.INFO}>
                      {tt('upload_floorplan')}
                    </Button>
                  </UploadHelper>
                </FlexItem>
                <FlexItem flex>
                  {finalFloorplanUrl != null || isLoading ? (
                    <Margin size={{ left: Size.SMALL }}>
                      <AttachmentBox
                        fileName={info[0]?.filename ?? truncate(finalFloorplanUrl, { length: 30 })}
                        progress={info[0]?.progress}
                      />
                    </Margin>
                  ) : null}
                </FlexItem>
              </Flex>
            </Margin>
          </FlexItem>
        </Fragment>
      )}
    </Flex>
  );
};
