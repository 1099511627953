import { OrganisationRole } from './organisation';
import { User } from './user';

export enum InvitationStatus {
  INITIATED = 'INITIATED',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
}

export interface Invitation {
  id: string;
  firstName: string;
  lastName: string;
  role: OrganisationRole;
  email: string;
  sender: User;
}
