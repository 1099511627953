import { Color, ListTile, Tag, Text, Tier } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { UnitAvailability } from '../../../../types';

const tt = createTranslate('pods.listing_manager.components.filters.availabilities');

interface AvailabilityTagProps {
  availability: UnitAvailability;
  soldAt?: string;
}

export const AvailabilityTag = ({ availability, soldAt }: AvailabilityTagProps) => {
  if (availability === UnitAvailability.AVAILABLE) {
    return <Tag color={Color.GREEN}>{tt('available')}</Tag>;
  } else if (availability === UnitAvailability.OPTION) {
    return <Tag color={Color.RED}>{tt('option')}</Tag>;
  } else if (availability === UnitAvailability.UNAVAILABLE) {
    return <Tag>{tt('unavailable')}</Tag>;
  } else {
    if (soldAt == null) {
      return <Tag>{tt('booked')}</Tag>;
    }
    return (
      <ListTile
        leading={<Tag>{tt('booked')}</Tag>}
        title={
          <Text tier={Tier.SECONDARY}>
            {new Date(soldAt).toLocaleDateString(window.i18n.locale, {
              month: 'short',
              year: 'numeric',
              day: '2-digit',
            })}
          </Text>
        }
      />
    );
  }
};
