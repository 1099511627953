import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CircularProgress,
  Color,
  Content,
  EmptyState,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Hint,
  Label,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  Size,
  Text,
  Title,
  UploadBox,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect } from 'react';

import { MosaicPageTitle } from '~/components';
import { Attachment, HexColor } from '~/types';
import {
  UploadedFile,
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  isAttachment,
  run,
  translate as t,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { HexInput } from '../../components';
import { Organisation } from '../../types';
import { useFetchOrganisationBranding } from '../../utils';

const tt = createTranslate('pods.settings.routes.branding');

const styles = {
  imagePreview: css`
    width: 100%;
    height: 200px;
    padding: ${sv.paddingHuge};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${sv.neutralLight};
    position: relative;
    z-index: 1;

    > img {
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  `,
};

interface UpdateOrganisationVariables {
  organisation: {
    id: string;
    primaryColor?: HexColor;
    secondaryColor?: HexColor;
    logo?: string; // Signed Blob ID
  };
}

interface UpdateOrganisationResult {
  updateOrganisation: {
    organisation: Organisation;
  };
}

const updateOrganisationMutation = gql`
  mutation UpdateOrganisation($organisation: UpdateOrganisationInput!) {
    updateOrganisation(input: $organisation) {
      organisation {
        primaryColor
        secondaryColor
        logo {
          id
          url
        }
      }
    }
  }
`;

interface BrandingForm {
  primaryColor: HexColor;
  secondaryColor: HexColor;
  logo: UploadedFile | Attachment;
}

export const Branding = () => {
  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisationBranding();
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);
  const { showAlert } = useAlert();
  const brandingForm = useForm<BrandingForm>();

  const { executeMutation: updateOrganisation, res: updateOrganisationResult } = useMosaicMutation<
    UpdateOrganisationResult,
    UpdateOrganisationVariables
  >(updateOrganisationMutation);

  const handleUpload = async (files: FileList) => {
    if (files.length > 0) {
      const res = await uploadFiles(files);
      const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
      brandingForm.set(validFiles[0], 'logo');
    }
  };

  const handleClickSave = async () => {
    const { logo, ...rest } = brandingForm.values;
    const { error } = await updateOrganisation({
      organisation: {
        id: organisation!.id,
        logo: logo != null && !isAttachment(logo) ? logo.signedBlobId : undefined,
        ...rest,
      },
    });
    if (error != null) {
      showAlert({
        text: tt('update_error'),
        category: Category.DANGER,
      });
    } else {
      showAlert({
        text: tt('branding_saved'),
        category: Category.SUCCESS,
      });
    }
  };

  useEffect(() => {
    if (organisation != null) {
      brandingForm.set(organisation.primaryColor ?? '', 'primaryColor');
      brandingForm.set(organisation.secondaryColor ?? '', 'secondaryColor');
      brandingForm.set(organisation.logo, 'logo');
    }
  }, [isOrganisationLoading]);

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: t('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  const { logo } = brandingForm.values;

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('branding')}</MosaicPageTitle>
        <Panel
          header={
            <PanelHeader>
              <Title size={4} noMargin>
                {tt('company_branding')}
              </Title>
            </PanelHeader>
          }
          body={
            <PanelBody>
              {run(() => {
                if (isOrganisationLoading) {
                  return (
                    <Grid hGutters={Size.LARGE} vGutters={Size.DEFAULT} columns={2}>
                      <GridItem>
                        <LoadingPlaceholder width="100%" height={40} />
                      </GridItem>
                      <GridItem>
                        <LoadingPlaceholder width="100%" height={40} />
                      </GridItem>
                      <GridItem>
                        <LoadingPlaceholder width="100%" height={190} />
                      </GridItem>
                      <GridItem>
                        <LoadingPlaceholder width="100%" height={190} />
                      </GridItem>
                    </Grid>
                  );
                } else if (organisation != null) {
                  return (
                    <Fragment>
                      <Margin size={{ bottom: Size.DEFAULT }}>
                        <Flex>
                          <FlexItem flex>
                            <FormGroup
                              input={
                                <HexInput
                                  value={brandingForm.get}
                                  name="primaryColor"
                                  onChange={brandingForm.set}
                                />
                              }
                              label={<Label>{tt('primary_color')}</Label>}
                            />
                          </FlexItem>
                          <FlexSpacer size={Size.DEFAULT} />
                          <FlexItem flex>
                            <FormGroup
                              input={
                                <HexInput
                                  value={brandingForm.get}
                                  name="secondaryColor"
                                  onChange={brandingForm.set}
                                />
                              }
                              label={<Label>{tt('secondary_color')}</Label>}
                            />
                          </FlexItem>
                        </Flex>
                      </Margin>
                      <Margin size={{ bottom: Size.EXTRA_SMALL }}>
                        <Label>{tt('company_logo')}</Label>
                      </Margin>
                      <Flex align={FlexAlign.STRETCH}>
                        <FlexItem flex>
                          <UploadBox
                            fullHeight
                            fullWidth
                            onUploadFiles={handleUpload}
                            allowedFileFormats=".svg,.png,.gif"
                          />
                        </FlexItem>
                        <FlexSpacer size={Size.DEFAULT} />
                        <FlexItem flex>
                          <div className={styles.imagePreview}>
                            {run(() => {
                              if (isFileUploading) {
                                return info.map((fileUpload) => (
                                  <Fragment key={fileUpload.filename}>
                                    <CircularProgress
                                      size={Size.SMALL}
                                      style={{ zIndex: 2 }}
                                      percentage={fileUpload.progress ?? 0}
                                      color={Color.GREEN}
                                    />
                                    <img style={{ opacity: 0.5 }} src={fileUpload.url} />
                                  </Fragment>
                                ));
                              } else if (logo != null) {
                                return <img src={logo.url} />;
                              } else {
                                return <Text>{tt('no_logo_uploaded')}</Text>;
                              }
                            })}
                          </div>
                        </FlexItem>
                      </Flex>
                      {logo != null && !isAttachment(logo) ? (
                        <Flex justify={FlexJustify.END}>
                          <FlexItem>
                            <Hint>{tt('click_to_update')}</Hint>
                          </FlexItem>
                        </Flex>
                      ) : null}
                    </Fragment>
                  );
                } else if (organisationError != null) {
                  return (
                    <EmptyState
                      description={getMessageFromErrorType(organisationError)}
                      variation={getEmptyStateVariationFromErrorType(organisationError)}
                    />
                  );
                }
              })}
            </PanelBody>
          }
          footer={
            <PanelFooter>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  <Button
                    trailing={updateOrganisationResult.fetching}
                    disabled={updateOrganisationResult.fetching}
                    category={Category.INFO}
                    onClick={handleClickSave}>
                    {tt('update_branding')}
                  </Button>
                </FlexItem>
              </Flex>
            </PanelFooter>
          }
        />
      </Padding>
    </Content>
  );
};
