import gql from 'fraql';

import { ErrorType, Organisation } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { shouldUseShowcaseData, showcaseOrganisationData } from '../../showcase';

interface OrganisationQueryData {
  organisation: Organisation;
}

const OrganisationQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      name
      currency
      agency
    }
  }
`;

export function useFetchOrganisation(projectId?: string): {
  isLoading: boolean;
  organisation?: Organisation;
  refetch: VoidFunction;
  error?: ErrorType;
} {
  if (shouldUseShowcaseData(projectId ?? '')) return showcaseOrganisationData;

  const res = useMosaicQuery<OrganisationQueryData, null>({
    query: OrganisationQuery,
  });

  return {
    isLoading: res.isLoading,
    organisation: res.data?.organisation,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
