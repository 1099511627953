import {
  Button,
  Category,
  Content,
  EmptyState,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Icon,
  Padding,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';

import { MosaicPageTitle } from '~/components';
import { useFetchOrganisation } from '~/pods/crm/utils';
import { createTranslate, run } from '~/utils';

import { AdvertPanel, DiffusionPanel, PortalsModal } from '../components';
import { CreateAdvertModal } from '../components/CreateAdvertModal';
import { DiffuseModal } from '../components/DiffuseModal';
import { Loader } from '../components/Loader';
import { UnpublishModal } from '../components/UnpublishModal';
import { Diffusion } from '../domain';
import { useLoadAdvert, useLoadPortals, useLoadUnits } from '../hooks';
import { useLoadDiffusions } from '../hooks/use-load-diffusions';
import advertLogo from '../images/advert.svg';

const tt = createTranslate('pods.multidiffusion.routes.multidiffusion');

interface MultidiffusionRouteProps {}

export const MultidiffusionRoute = ({}: MultidiffusionRouteProps) => {
  const { projectSlug } = useParams();

  const [isPortalsModalVisible, setIsPortalsModalVisible] = useState(false);
  const [isCreateAdvertModalVisible, setIsCreateAdvertModalVisible] = useState(false);
  const [diffusionToEdit, setDiffusionToEdit] = useState<Diffusion>();
  const [diffusionToUnpublish, setDiffusionToUnpublish] = useState<Diffusion>();
  const [isDiffuseModalVisible, setIsDiffuseModalVisible] = useState(false);
  const { isLoading: isLoadingPortals, portals } = useLoadPortals();
  const { isLoadingUnits, units } = useLoadUnits(projectSlug!);
  const { isLoading: isOrganisationLoading, organisation } = useFetchOrganisation();

  const { isLoadingAdvert, advert, refreshAdvert } = useLoadAdvert(projectSlug!);
  const { isLoadingDiffusions, diffusions, refreshDiffusions } = useLoadDiffusions(advert?.id);

  if (isLoadingAdvert || isLoadingPortals || isLoadingDiffusions || isLoadingUnits) {
    return <Loader />;
  }

  const isPortalsEnabled = portals.some((p) => !p.isLocked);
  advert?.setUnits(units);

  return (
    <Fragment>
      <Content fullHeight>
        <Padding size={Size.LARGE}>
          <Flex>
            <FlexItem>
              <MosaicPageTitle size={3}>{tt('multidiffusion')}</MosaicPageTitle>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexSpacer flex />
            <FlexItem>
              <Button
                tier={Tier.TERTIARY}
                category={Category.INFO}
                leading={<Icon name="list" />}
                onClick={() => setIsPortalsModalVisible(true)}>
                {tt('display_portals')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Button
                category={Category.INFO}
                leading={<Icon name="share-2" />}
                disabled={advert == null || diffusions.length > 0}
                onClick={() => setIsDiffuseModalVisible(true)}>
                {tt('diffuse')}
              </Button>
            </FlexItem>
          </Flex>
          {run(() => {
            if (!isPortalsEnabled) {
              return (
                <Padding size={Size.EXTRA_HUGE}>
                  <EmptyState
                    image={advertLogo}
                    title={tt('empty.title')}
                    description={tt('empty.description')}>
                    <Button tier={Tier.SECONDARY} onClick={() => setIsPortalsModalVisible(true)}>
                      {tt('index')}
                    </Button>
                    <Button category={Category.INFO}>{tt('contact_us')}</Button>
                  </EmptyState>
                </Padding>
              );
            }

            return (
              <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
                <FlexSpacer />
                <FlexItem>
                  <AdvertPanel
                    advert={advert}
                    onClickCreateAdvert={() => setIsCreateAdvertModalVisible(true)}
                    onClickEdit={() => setIsCreateAdvertModalVisible(true)}
                  />
                </FlexItem>
                <FlexSpacer />
                <FlexItem>
                  {isOrganisationLoading ? (
                    <Spinner fullSize />
                  ) : organisation ? (
                    <DiffusionPanel
                      diffusions={diffusions}
                      onClickEdit={(d) => setDiffusionToEdit(d)}
                      onClickUnpublish={(d) => setDiffusionToUnpublish(d)}
                      projectId={
                        organisation.projects?.find((project) => project.slug === projectSlug)!.id!
                      }
                    />
                  ) : null}
                </FlexItem>
              </Flex>
            );
          })}
        </Padding>
      </Content>
      <PortalsModal
        isVisible={isPortalsModalVisible}
        onClickClose={() => setIsPortalsModalVisible(false)}
      />
      <CreateAdvertModal
        units={units}
        advert={advert}
        isVisible={isCreateAdvertModalVisible}
        projectId={projectSlug!}
        refresh={refreshAdvert}
        onClickClose={() => setIsCreateAdvertModalVisible(false)}
      />
      <DiffuseModal
        isVisible={isDiffuseModalVisible || diffusionToEdit != null}
        portals={portals.filter((p) => !p.isLocked)}
        advertId={advert?.id}
        diffusion={diffusionToEdit}
        refresh={refreshDiffusions}
        onClickClose={() => {
          setDiffusionToEdit(undefined);
          setIsDiffuseModalVisible(false);
        }}
      />
      <UnpublishModal
        diffusion={diffusionToUnpublish}
        onClickClose={() => setDiffusionToUnpublish(undefined)}
      />
    </Fragment>
  );
};
