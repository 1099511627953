import gql from 'fraql';

import { Id } from '~/types';
import { useMosaicMutation } from '~/utils';

interface FavoredProjectsMutationData {
  user: {
    favoredProjects: Id[];
  };
}

interface FavoredProjectsMutationVariables {
  projectPublicToken: string;
}

const UnfavorProjectMutation = gql`
  mutation UnfavorProject($projectPublicToken: String!) {
    unfavorProject(input: { projectPublicToken: $projectPublicToken }) {
      user {
        favoredProjects
      }
    }
  }
`;

const FavorProjectMutation = gql`
  mutation FavorProject($projectPublicToken: String!) {
    favorProject(input: { projectPublicToken: $projectPublicToken }) {
      user {
        favoredProjects
      }
    }
  }
`;

export function useMutateFavoredProjects() {
  const {
    res: { fetching: favoringProject },
    executeMutation: favorProject,
  } = useMosaicMutation<FavoredProjectsMutationData, FavoredProjectsMutationVariables>(
    FavorProjectMutation,
  );

  const {
    res: { fetching: unfavoringProject },
    executeMutation: unfavorProject,
  } = useMosaicMutation<FavoredProjectsMutationData, FavoredProjectsMutationVariables>(
    UnfavorProjectMutation,
  );

  return {
    isMutatingFavoredProjects: favoringProject || unfavoringProject,
    favorProject,
    unfavorProject,
  };
}
