import { Dayjs } from 'dayjs';

import { CampaignToken } from '.';

export enum Sources {
  WEBSITE = 'website',
  REVO = 'revo',
}

export enum EntityTypes {
  UNIT = 'unit',
  FLOOR = 'floor',
  BUILDING = 'building',
  PROJECT = 'project',
}

interface Language {
  code: string;
  name: string;
  native: string;
}

export interface IpData {
  ip: string;
  type: string;
  continent_code: string;
  continent_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: {
    geoname_id: number;
    capital: string;
    languages: Array<Language>;
    country_flag: string;
    country_flag_emoji: string;
    country_flag_emoji_unicode: string;
    calling_code: string;
    is_eu: boolean;
  };
}

interface Breadcrumb {
  id: string;
  type: EntityTypes;
  timestamp: string;
}

interface EntityTimer {
  id: string;
  time: number;
  type: EntityTypes;
}

interface Events {
  clicked: Array<string>;
  clicks: Map<string, number>;
}

interface Geolocation {
  latitude: number;
  longitude: number;
}

interface Revo {
  favorites: Map<string, number>;
  breadcrumbs?: Array<Breadcrumb>;
  time: {
    startAt: string;
    entities?: Array<EntityTimer>;
  };
}

export interface UserAgent {
  string: string;
  browser: {
    name: string;
    version: string;
  };
}

interface User {
  email: string;
  extra: object;
}

export interface SessionFromServer {
  deviceId: string;
  sessionId: string;
  projectId: string;
  campaign: {
    token: CampaignToken;
  };
  source: Sources;
  collected: {
    events: Events;
    geolocation: Geolocation;
    referrer: string;
    revo: Revo;
    userAgent: UserAgent;
  };
  ipData: IpData;
  createdAt: string;
  user: User;
  modifiedAt: string;
}

export interface InsightsSession {
  path: Array<{ id: string; timestamp: Dayjs; type: 'unit' | 'floor' | 'building' }>;
  time: Array<{ id: string; time: number }>;
  createdAt: Dayjs;
  campaignToken?: string;
  referrer: string;
  ipData: IpData;
  userAgent: UserAgent;
}
