import gql from 'fraql';

import { unitFragment } from '../fragments';

export const UpdateUnitMutation = gql`
  mutation UpdateUnit($unit: UpdateUnitInput!) {
    updatedUnit: updateUnit(input: $unit) {
      unit {
        ${unitFragment}
      }
    }
  }
`;
