import gql from 'fraql';

import { Lead, SortBy, UnitFeature } from '~/pods/crm/types';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

export interface LeadsQueryData {
  leads: Array<Lead>;
}

export interface LeadsQueryFilters {
  typologies?: Array<number> | null;
  minimumBudget?: number;
  maximumBudget?: number;
  minimumSurface?: number;
  maximumSurface?: number;
  unitFeatures?: Array<UnitFeature> | null;
  sortBy?: SortBy;
  limit?: number;
  offset?: number;
  projectIds?: string[] | null;
  organisationIds?: string[] | null;
  userIds?: string[] | null;
  funnelStepIds?: string[] | null;
  sources?: string[] | null;
  purchaseGoal?: string[] | null;
  campaignTokens?: string[] | null;
  engagementLevel?: string[] | null;
  searchTerm?: string;
  portalSlugs?: string[];
}

export interface LeadsQueryVariables {
  filter?: LeadsQueryFilters;
}

const LeadsQuery = gql`
  query LeadsQuery($filter: LeadFilterInput!) {
    leads(filter: $filter) {
      id
      firstName
      lastName
      fullName @computed(type: Lead)
      email
      phoneNumber
      typologies
      engagementLevel
      portalSlug
      user {
        id
        firstName
        lastName
        fullName @computed(type: User)
        organisation {
          isAgency: agency
          agency
          id
          name
          logo {
            url
          }
        }
      }
      step: funnelStep {
        id
        name
        slug
        position
        color
        type
      }
      interestedIn: projectInterests {
        id
        project {
          id
          name
        }
        unitInterests {
          unitId
          unitReference
        }
      }
      updatedAt
    }
  }
`;

export function useFetchLeads(filters: LeadsQueryFilters): {
  isLoading: boolean;
  leads?: Array<Lead>;
  error?: ErrorType;
  refetchLeads: () => void
} {
  const res = useMosaicQuery<LeadsQueryData, LeadsQueryVariables>({
    query: LeadsQuery,
    variables: { filter: filters },
    requestPolicy: 'network-only',
  });

  return {
    isLoading: res.isLoading,
    leads: res.data?.leads?.map((l) => ({ ...l, portalSlug: l.portalSlug?.toUpperCase() })),
    error: buildError(res.error),
    refetchLeads: () => res.refetch()
  };
}
