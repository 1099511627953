import { z } from 'zod';

import { UnitAvailability } from '../../types';

export const UnitSchema = z.object({
  id: z.string(),
  isLocked: z.boolean(),
  reference: z.string(),
  availability: z.nativeEnum(UnitAvailability),
  soldAt: z
    .string()
    .nullable()
    .optional()
    .transform((v) => (v == null ? undefined : v)),
  price: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  surface: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  terraceSurface: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  rooms: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  bedrooms: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  bathrooms: z.preprocess((v) => (v == null ? 0 : v), z.number()),
  orientation: z
    .string()
    .nullable()
    .optional()
    .transform((v) => (v == null ? undefined : v)),
  floorplan: z
    .string()
    .nullable()
    .optional()
    .transform((v) => (v == null ? undefined : v)),
});
