import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';
import { AssetSchema } from '../asset';
import { LinkSchema } from '../link';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const MapSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.MAP),
  title: ze.optional(z.string()),
  description: ze.optional(z.string()),
  assets: z.array(AssetSchema),
  link: LinkSchema,
  position: z.number(),
});

export type MapSectionData = z.infer<typeof MapSectionSchema>;

export class MapSection extends ze.ClassFrom(MapSectionSchema) {
  public static fromData(data: MapSectionData): MapSection {
    return ze.validate(data, MapSection);
  }

  public toData(): MapSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
