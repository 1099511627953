import { UrlString } from '~/types';

export enum MeetingLocation {
  REMOTE = 'REMOTE',
  PHYSICAL = 'PHYSICAL',
}

export enum MeetingStatus {
  PLANNED = 'PLANNED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
}

export interface Meeting {
  id: string;
  lead: {
    firstName: string;
    lastName: string;
    fullName: string; // computed
  }
  location: MeetingLocation;
  address?: string;
  date: string;
  meetingLink?: UrlString;
  additionalParticipants?: Array<string>;
}
