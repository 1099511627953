import {
  AttachmentBox,
  AttachmentList,
  Button,
  Category,
  FileIcon,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LinkUnderlined,
  ListTile,
  Margin,
  Size,
  Spinner,
  TextLink,
  UploadBox,
  useAlert,
} from '@drawbotics/react-drylus';
import { differenceBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { Attachment } from '~/types';
import {
  UploadedFile,
  createTranslate,
  isAttachment,
  translate as t,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { AssetChange } from './CategoryEditor';

const tt = createTranslate('pods.presentation.routes.presentation.components.pdfs');

interface PDFsProps {
  pdfs: Array<Attachment>;
  editMode?: boolean;
  onClickSave: (changes: Array<AssetChange>) => void;
  onClickCancel: VoidFunction;
  saving?: boolean;
}

export const PDFs = ({ pdfs, editMode, onClickSave, onClickCancel, saving }: PDFsProps) => {
  const [files, setFiles] = useState<Array<UploadedFile | Attachment>>(pdfs);
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);
  const { showAlert } = useAlert();

  const handleUpload = async (fileList: FileList) => {
    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    setFiles([...files, ...validFiles]);
  };

  const handleRemoveFile = (file: Attachment | UploadedFile) => {
    if (isAttachment(file)) {
      setFiles(files.filter((f) => (isAttachment(f) ? f.id !== file.id : true)));
    } else {
      setFiles(
        files.filter((f) =>
          isAttachment(f) ? true : f.originalFile.name !== file.originalFile.name,
        ),
      );
    }
  };

  const handleSaveChanges = () => {
    const newFiles = files.filter((file) => !isAttachment(file)) as Array<UploadedFile>;
    const oldFiles = files.filter((file) => isAttachment(file)) as Array<Attachment>;
    const deletedFiles = differenceBy(pdfs, oldFiles, 'id') as Array<Attachment>;
    const changes = [
      ...newFiles.map((f) => ({ signedBlobId: f.signedBlobId })),
      ...deletedFiles.map((f) => ({ assetId: f.id, destroy: true })),
    ];
    onClickSave(changes);
  };

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: t('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  useEffect(() => {
    setFiles(pdfs);
  }, [pdfs.map((i) => i.id).join('')]);

  const filesUploading = isFileUploading ? info : [];

  if (editMode) {
    return (
      <Fragment>
        <UploadBox multiple allowedFileFormats=".pdf" fullWidth onUploadFiles={handleUpload} />
        <Margin size={{ top: Size.SMALL, bottom: Size.DEFAULT }}>
          <AttachmentList>
            {files.map((file) => (
              <AttachmentBox
                key={isAttachment(file) ? file.id : file.originalFile.name}
                fileName={isAttachment(file) ? file.filename : file.originalFile.name}
                onClickClose={() => handleRemoveFile(file)}
                onClickDownload={() => window.open(file.url, '_blank')}
              />
            ))}
            {filesUploading.map((fileUpload) => (
              <AttachmentBox
                key={fileUpload.filename}
                progress={fileUpload.progress}
                fileName={fileUpload.filename}
              />
            ))}
          </AttachmentList>
        </Margin>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button
              size={Size.SMALL}
              onClick={() => {
                setFiles(pdfs);
                onClickCancel();
              }}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              disabled={isFileUploading || saving}
              trailing={saving ? <Spinner inversed size={Size.SMALL} /> : null}
              size={Size.SMALL}
              onClick={handleSaveChanges}
              category={Category.INFO}>
              {tt('save_changes')}
            </Button>
          </FlexItem>
        </Flex>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {files.map((file, i) => (
        <Fragment key={isAttachment(file) ? file.id : file.originalFile.name}>
          {i !== 0 ? <Margin size={{ bottom: Size.SMALL }} /> : null}
          <ListTile
            title={
              <a href={file.url} target="_blank" rel="noreferrer noopener">
                <TextLink style={{ wordBreak: 'break-all' }} underlined={LinkUnderlined.HOVER}>
                  {isAttachment(file) ? file.filename : file.originalFile.name}
                </TextLink>
              </a>
            }
            leading={<FileIcon type="pdf" />}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};
