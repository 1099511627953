import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CheckboxFilter,
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Padding,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  SearchInput,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import copyToClipboard from 'copy-to-clipboard';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MosaicPageTitle } from '~/components';
import { CreateAndEditMeetingModal } from '~/pods/meetings/components';
import { createTranslate, run, useAuth, useDebounce } from '~/utils';

import { MeetingStatus } from '../../types';
import { useFetchLicensedUsers, useFetchMeetingCount, useFetchMeetings } from '../../utils';
import { MeetingsTable } from './components';

const tt = createTranslate('pods.marketing_suite.routes.meetings');
const ta = createTranslate('pods.marketing_suite');

const MEETINGS_PER_PAGE = 15;

export const Meetings = () => {
  const { user: currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isCreateMeetingModalVisible, setIsCreateMeetingModalVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useAlert();
  const { page, ...filters } = useFilters(
    location,
    navigate,
    ['page', 'search', 'users', 'status', 'sortDirection'],
    {
      persistenceKey: `${currentUser?.id}:meetings`,
    },
  );
  const { search, status, sortDirection } = filters;
  const { isLoading: isUsersLoading, users: licensedUsers } = useFetchLicensedUsers();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const users =
    licensedUsers != null
      ? {
          set: filters.users.set,
          values: (() => {
            const existingUsers = filters.users.values
              ? filters.users.values.filter((userId) => {
                  return licensedUsers.some((user) => user.id === userId);
                })
              : [];

            return existingUsers.length === 0 ? undefined : existingUsers;
          })(),
        }
      : {
          set: filters.users.set,
          values: undefined,
        };

  const { isLoading: isMeetingsCountLoading, count } = useFetchMeetingCount(
    {
      userIds: users.values,
      statuses: status.values as MeetingStatus[] | undefined,
      leadFilter: search.value || undefined,
    },
    { pause: licensedUsers == null },
  );

  const currentPage = page.value != null ? Number(page.value) : 1;
  const { isLoading: isMeetingsLoading, meetings } = useFetchMeetings(
    {
      sortBy: 'DATETIME',
      sortingOrder:
        sortDirection.value != null
          ? (sortDirection.value.toUpperCase() as 'DESC' | 'ASC')
          : 'DESC',
      userIds: users.values,
      statuses: status.values as MeetingStatus[] | undefined,
      leadFilter: search.value || undefined,
    },
    {
      limit: MEETINGS_PER_PAGE,
      offset: (currentPage - 1) * MEETINGS_PER_PAGE,
    },
    licensedUsers == null,
  );

  const totalPages = Math.ceil((count ?? MEETINGS_PER_PAGE) / MEETINGS_PER_PAGE);
  const licensedUser =
    licensedUsers != null && currentUser != null
      ? licensedUsers.find((u) => u.email === currentUser.email)
      : undefined;
  const isUserLicensed = licensedUser != null;

  // default to page 1 when filters change to make sure page is OK for new results
  useEffect(() => page.set('1'), [JSON.stringify(filters)]);

  useEffect(() => {
    if (debouncedSearchTerm === '') {
      search.set(null);
    } else if (debouncedSearchTerm != null && debouncedSearchTerm !== search.value) {
      search.set(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (users.values == null && licensedUser != null) {
      const userId = licensedUser.id;
      if (userId != null) {
        users.set([userId]);
      }
    }
  }, [licensedUsers]);

  return (
    <Fragment>
      <Content fullHeight fullWidth>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <Flex>
            <FlexItem flex>
              <MosaicPageTitle>{ta('meetings')}</MosaicPageTitle>
            </FlexItem>
            <FlexItem>
              {isUserLicensed === true ? (
                <Button
                  onClick={() => {
                    if (currentUser != null) {
                      const grabToken = licensedUser!.grabToken;
                      copyToClipboard(
                        `${process.env.MOSAIC_HOST}/meetings/presentations/go/${currentUser.locale}/${grabToken}`,
                      );
                      showAlert({
                        text: tt('copy_link_success'),
                        category: Category.SUCCESS,
                      });
                    }
                  }}
                  leading={<Icon name="link-2" />}
                  style={{
                    background: sv.blueLighter,
                    color: sv.blue,
                  }}>
                  {tt('copy_link')}
                </Button>
              ) : (
                <Button
                  disabled={true}
                  onClick={() => {}}
                  leading={<Icon name="link-2" />}
                  tier={Tier.SECONDARY}>
                  {tt('copy_link')}
                </Button>
              )}
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                onClick={() => setIsCreateMeetingModalVisible(true)}
                category={Category.INFO}
                leading={<Icon name="plus" />}>
                {ta('new_meeting')}
              </Button>
            </FlexItem>
          </Flex>
          <Panel
            header={
              <PanelHeader>
                <Flex justify={FlexJustify.END}>
                  <FlexItem>
                    <SearchInput
                      loading={isUsersLoading}
                      minimal
                      value={searchTerm}
                      onChange={setSearchTerm}
                      placeholder={tt('search')}
                    />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>
                    {run(() => {
                      if (isUsersLoading) {
                        return <Spinner />;
                      } else if (licensedUsers != null) {
                        return (
                          <CheckboxFilter
                            options={licensedUsers.map((user) => ({
                              value: user.id,
                              label: user.fullName,
                            }))}
                            onChange={users.set}
                            values={users.values as any}
                            onClear={() => users.set(null)}
                            label={tt('sales')}
                          />
                        );
                      } else {
                        // No need to handle error, just don't show filter
                        return null;
                      }
                    })}
                  </FlexItem>
                </Flex>
              </PanelHeader>
            }
            body={
              <PanelBody noPadding>
                <MeetingsTable
                  onClickNew={() => setIsCreateMeetingModalVisible(true)}
                  loading={isUsersLoading || isMeetingsLoading || isMeetingsCountLoading}
                  meetings={meetings}
                  sortDirection={sortDirection}
                />
              </PanelBody>
            }
            footer={
              <PanelFooter>
                <Flex justify={FlexJustify.END}>
                  <FlexItem>
                    <Pagination
                      value={currentPage}
                      pages={totalPages}
                      onChange={(v) => page.set(v.toString())}
                    />
                  </FlexItem>
                </Flex>
              </PanelFooter>
            }
          />
        </Padding>
      </Content>
      <CreateAndEditMeetingModal
        onClose={() => setIsCreateMeetingModalVisible(false)}
        isVisible={isCreateMeetingModalVisible}
      />
    </Fragment>
  );
};
