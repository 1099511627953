import { Size, Tier } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import comingSoon from '../../../../images/coming-soon-chart.svg';
import { Text } from '../Text';

const t = createTranslate('pods.insights_reports.components.charts');

const styles = {
  comingSoonChart: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      height: 60px;
      margin-bottom: 12px;
    }
  `,
};

export const ComingSoonChart = () => {
  return (
    <div className={styles.comingSoonChart}>
      <img src={comingSoon} />
      <Text light size={Size.SMALL} tier={Tier.TERTIARY}>
        {t('coming_soon')}
      </Text>
    </div>
  );
};
