import { Category, Padding, Size, Tile } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { DateRange, InsightsEstate } from '../../../../types';
import { useLoadSortedUnitsByVisits } from '../../../../utils/hooks';
import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';
import { Row } from './Row';

const t = createTranslate('pods.insights_reports.components.charts.least_visited_units');

interface LeastVisitedUnitsProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const LeastVisitedUnits = ({ units, dateRange }: LeastVisitedUnitsProps) => {
  const unitsByVisits = useLoadSortedUnitsByVisits(units, dateRange).reverse();
  const bottomUnitsByVisits = unitsByVisits.slice(0, 5);
  const totalVisits = unitsByVisits.reduce((memo, u) => memo + u.visits.length, 0);

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ top: Size.SMALL }}>
        {bottomUnitsByVisits.map((u, index) => {
          return (
            <Row
              key={u.id}
              left={<Text>{u.name}</Text>}
              right={
                <Text bold category={Category.DANGER}>
                  {Math.ceil((u.visits.length / totalVisits) * 100)}%
                </Text>
              }
              showSeparator={index !== bottomUnitsByVisits.length - 1}
            />
          );
        })}
      </Padding>
    </Tile>
  );
};
