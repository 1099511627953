import sv from '@drawbotics/drylus-style-vars';
import { Button as DButton, ButtonProps as DButtonProps } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css, cx } from 'emotion';
import React from 'react';

import { Theme, ThemeDefinition } from '../../../types';

const styles = {
  traditionalPrimary: (theme: ThemeDefinition) => css`
    background: ${theme.colors.primary};
    color: ${theme.colors.neutral4};
    border-radius: 0;
    font-family: ${theme.fonts.secondary};
    font-weight: 700;
    font-size: 10px; /* exception to vars */
    text-transform: uppercase;
    padding: ${sv.marginSmall} ${sv.defaultMargin};

    &:hover {
      background: ${theme.colors.primaryLight};
    }
  `,
  traditionalSecondary: (theme: ThemeDefinition) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    border-radius: 0;
    background: transparent;
    color: ${theme.colors.neutral1};
    border-bottom: 2px solid ${theme.colors.neutral1};
    padding: 8px 2px 4px 2px;
    font-weight: 600;
    font-size: ${theme.fonts.extraSmall};

    &:hover {
      background: ${theme.colors.neutral1};
      color: ${theme.colors.neutral4};
    }
  `,
  luxuriousPrimary: (theme: ThemeDefinition) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.extraSmall};
    border: 1px solid ${theme.colors.primary};
    background: transparent;
    color: ${theme.colors.primary};
    font-weight: 600;
    padding: ${sv.paddingSmall};
    letter-spacing: 1.5px;

    &:hover {
      background: ${theme.colors.primary};
      color: ${theme.colors.neutral4};
    }
  `,
  luxuriousSecondary: (theme: ThemeDefinition) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.extraSmall};
    background: transparent;
    padding: 0;
    padding-bottom: 2px;
    color: ${theme.colors.secondary};
    border-radius: 0;
    font-weight: 600;

    &:hover {
      background: transparent;
      box-shadow: 0px 3px 0px -1px ${theme.colors.secondary};
    }
  `,
  minimalistPrimary: (theme: ThemeDefinition) => css`
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.small};
    background: ${theme.colors.secondary};
    color: ${theme.colors.neutral2};
    font-weight: 600;
    padding: ${sv.paddingSmall};
    letter-spacing: 1.5px;
    border-radius: 0;

    &:hover {
      background: ${sv.darken(theme.colors.secondary, 10)};
    }
  `,
  minimalistSecondary: (theme: ThemeDefinition) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.extraSmall};
    background: transparent;
    padding: 0;
    padding-bottom: 2px;
    color: ${theme.colors.primary};
    border-radius: 0;
    font-weight: 600;
    border-bottom: 2px solid ${theme.colors.primary};

    &:hover {
      background: transparent;
      font-weight: 800;
    }
  `,
};

interface ButtonProps extends DButtonProps {
  secondary?: boolean;
}

export const Button = ({ secondary, ...props }: ButtonProps) => {
  const theme = useTheme<ThemeDefinition>();
  return (
    <DButton
      {...props}
      className={cx(
        {
          [styles.traditionalPrimary(theme)]: theme.id === Theme.TRADITIONAL && !secondary,
          [styles.traditionalSecondary(theme)]: theme.id === Theme.TRADITIONAL && secondary,
          [styles.luxuriousPrimary(theme)]: theme.id === Theme.LUXURIOUS && !secondary,
          [styles.luxuriousSecondary(theme)]: theme.id === Theme.LUXURIOUS && secondary,
        },
        {
          [styles.minimalistPrimary(theme)]: theme.id === Theme.MINIMALIST && !secondary,
          [styles.minimalistSecondary(theme)]: theme.id === Theme.MINIMALIST && secondary,
        },
      )}
    />
  );
};
