import { CheckboxFilter } from '@drawbotics/react-drylus';
import { cx } from 'emotion';
import React from 'react';

import { LeadsFilterTypes } from '~/pods/crm/types';
import { unitTypeOptions as unitTypes } from '~/pods/crm/utils';
import { createTranslate } from '~/utils';

import { filterStyles } from './filter-styles';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');
const te = createTranslate('pods.crm.typologies');

interface UnitTypeFilterProps {
  filter: LeadsFilterTypes['unitTypes'];
}

const { labels: unitTypeOptions } = unitTypes;

export const UnitTypeFilter = ({ filter }: UnitTypeFilterProps) => {
  const options = Object.keys(unitTypeOptions).map((unitType) => ({
    value: unitType,
    label: te(unitTypeOptions[unitType]),
  }));

  const values: string[] = filter.values ?? [];

  return (
    <CheckboxFilter
      label={tt('titles.unit_types')}
      className={cx(filterStyles.filter)}
      options={options}
      values={values}
      onChange={filter.set}
      onClear={() => filter.set(null)}
    />
  );
};
