import { Category, Size, Text } from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate, run } from '~/utils';

import { DomainStatus } from '../types';

const tt = createTranslate('pods.website_builder.routes.website_builder');

interface DomainStatusInfoProps {
  status: DomainStatus;
}

export const DomainStatusInfo = ({ status }: DomainStatusInfoProps) => {
  return (
    <Text
      size={Size.SMALL}
      category={run(() => {
        if (status === DomainStatus.VERIFIED) {
          return Category.SUCCESS;
        } else if (status === DomainStatus.FAILED) {
          return Category.DANGER;
        } else return Category.WARNING;
      })}>
      {run(() => {
        if (status === DomainStatus.VERIFIED) {
          return tt('domain_status_connected');
        } else if (status === DomainStatus.FAILED) {
          return tt('domain_status_issue');
        } else return tt('domain_status_pending');
      })}
    </Text>
  );
};
