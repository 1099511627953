import { Margin, Size, Title, TitleProps } from '@drawbotics/react-drylus';
import React, { useEffect } from 'react';

interface MosaicPageTitleProps {
  children: string;
  size?: TitleProps['size'];
  margin?: Size;
}

export const MosaicPageTitle = ({
  children,
  size = 2,
  margin = Size.LARGE,
}: MosaicPageTitleProps) => {
  useEffect(() => {
    const env = process.env.APP_ENV;
    const envPrefix = env !== 'production' ? `[${env.charAt(0).toUpperCase()}] ` : '';
    document.title = `${envPrefix}Mosaic | ${children}`;

    return function cleanup() {
      document.title = `${envPrefix}Mosaic`;
    };
  }, [children]);

  return (
    <Margin size={{ vertical: margin }}>
      <Title noMargin size={size}>
        {children}
      </Title>
    </Margin>
  );
};
