import sv from '@drawbotics/drylus-style-vars';
import { Button, Icon, Margin, Position, Size, Spinner, Tooltip } from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { Status } from '~/pods/crm/components';
import { Step, StepType } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  `,
  nextStep: css`
    position: absolute;
    top: 50%;
    left: calc(100% + ${sv.marginExtraSmall});
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
  `,
  activeStep: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  `,
  visible: css`
    opacity: 0.5;
  `,
  statusWrapper: css`
    padding: 5px;
    font-size: 0.85rem;
    opacity: 1;
    pointer-events: none;
    display: inline-flex;
    text-transform: capitalize;
    opacity: 0;
  `,
  stepWrapper: css`
    position: relative;
  `,
};

const tt = createTranslate('pods.crm.routes.lead.components.lead_details');

const ANIMATION_DURATION = 0.3;
const EASING = 'easeOut';

interface StatusBumperProps {
  activeStep: Step;
  steps: Array<Step>;
  onBumpStep: (nextStep: Step) => void;
  loading: boolean;
}

export const StatusBumper = ({ activeStep, steps, onBumpStep, loading }: StatusBumperProps) => {
  const [nextVisible, setNextVisible] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  const bumpableSteps = steps
    .filter((step) => step.type !== StepType.LOST)
    .sort((a, b) => a.position - b.position);
  const nextStep = bumpableSteps[bumpableSteps.findIndex((step) => step.id === activeStep.id) + 1];

  const nextStepRef = useRef(nextStep);
  const activeStepRef = useRef(activeStep);

  const handleBumpStep = (nextStep: Step) => {
    setSwitching(true);
    onBumpStep(nextStep);
  };

  useEffect(() => {
    if (!loading && isAnimationCompleted) {
      nextStepRef.current = nextStep;
      activeStepRef.current = activeStep;

      if (nextStep != null) {
        setTimeout(() => {
          setSwitching(false);
        }, 50);
      }
      setIsAnimationCompleted(false);
    }
  }, [isAnimationCompleted]);

  return (
    <div className={styles.container}>
      <div className={styles.stepWrapper}>
        <motion.div
          onAnimationComplete={() => setIsAnimationCompleted(true)}
          transition={{ duration: switching ? ANIMATION_DURATION : 0, ease: EASING }}
          initial={false}
          animate={{ opacity: switching ? 0 : 1 }}
          className={styles.activeStep}>
          <Status step={activeStepRef.current} />
        </motion.div>
        <motion.div
          initial={false}
          layoutTransition={{ duration: ANIMATION_DURATION, ease: EASING }}
          className={styles.statusWrapper}>
          {activeStep.name}
        </motion.div>
      </div>
      <AnimatePresence>
        {nextStepRef.current != null && activeStep.type !== StepType.LOST ? (
          <div style={{ pointerEvents: switching ? 'none' : 'auto' }}>
            {nextStep != null ? (
              <Margin size={{ left: Size.EXTRA_SMALL }}>
                <Tooltip content={tt('next_step')} side={Position.BOTTOM}>
                  <motion.div
                    transition={{ duration: ANIMATION_DURATION, ease: EASING }}
                    exit={{ width: 0, opacity: 0 }}
                    layoutTransition={{ duration: ANIMATION_DURATION, ease: EASING }}
                    onMouseLeave={() => setNextVisible(false)}
                    onMouseEnter={() => setNextVisible(true)}>
                    <Button
                      onClick={() => handleBumpStep(nextStep)}
                      size={Size.SMALL}
                      leading={
                        loading ? <Spinner size={Size.SMALL} /> : <Icon name="chevron-right" />
                      }
                    />
                  </motion.div>
                </Tooltip>
              </Margin>
            ) : null}
            <motion.div
              initial={false}
              transition={{ duration: switching ? ANIMATION_DURATION : 0, ease: EASING }}
              animate={{ left: switching ? 0 : `calc(100% + ${sv.marginExtraSmall})` }}
              style={{ opacity: switching ? 1 : undefined }}
              className={cx(styles.nextStep, { [styles.visible]: nextVisible })}>
              <Status step={nextStepRef.current} />
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
