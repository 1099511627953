import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Dot,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  Icon,
  Shade,
  Tier,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { range } from 'lodash';
import React, { useState } from 'react';

import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { AnyWebsiteSectionData, AssetData, GallerySection } from '../../../api/domain';
import { ControlButtons } from '../ControlButtons';
import { UploadGalleryModal } from '../UploadGalleryModal';

const styles = {
  gallery: css`
    padding: ${sv.paddingLarge};
  `,
  container: css`
    padding: 0 ${sv.paddingHuge};
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
  controls: css`
    position: absolute;
    top: -${sv.marginExtraSmall};
    left: calc(50% - 30px);
    transform: translateX(-50%);
  `,
  inactive: css`
    opacity: 0.4;
  `,
  galleryContainer: css`
    width: 100%;
    position: relative;
    margin-top: ${sv.marginLarge};
    margin-bottom: ${sv.marginExtraLarge};
  `,
  tiles: css`
    margin-right: ${sv.marginHuge};
  `,
  tile: css`
    height: 250px;
    margin: ${sv.marginExtraSmall};
  `,
  small: css`
    height: 160px;
    width: 180px;
    position: absolute;
    bottom: -20px;
    right: 0;
    border: ${sv.paddingExtraSmall} solid ${sv.white};
  `,
  empty: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${sv.neutralLight};
    border: 2px dashed ${sv.brand};
    outline: 8px solid ${sv.white};
    box-shadow: 0px 0px 0px 4px inset ${sv.white};
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  galleryControls: css`
    margin-top: ${sv.marginSmall};
    width: calc(100% - 200px);
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 20px);
    width: 100%;
    background: ${sv.darkOverlay};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: ${sv.transitionShort};
    color: ${sv.colorPrimaryInverse};
    z-index: 3;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  `,
};

interface GalleryProps {
  gallery: GallerySection;
  language: LocaleCode;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAssets: (sectionId: string, data: Array<AssetData>) => void;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Gallery = ({
  gallery,
  language,
  onChange,
  onChangeAssets,
  onIncrement,
  onDecrement,
}: GalleryProps) => {
  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const displayAssets = gallery.assets.slice().sort((a, b) => a.position! - b.position!);
  const galleryAssets = new Array(4).fill(0).map((_, i) => displayAssets[i]);

  return (
    <div className={styles.gallery}>
      <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.STRETCH}>
        <FlexItem style={{ position: 'relative', width: '100%' }}>
          <div className={styles.controls}>
            <ControlButtons
              sectionId={gallery.id}
              horizontal
              onClickEdit={() => setIsModalOpen(true)}
              onToggleVisibility={() => onChange(gallery.id, { active: !gallery.isActive })}
              isActive={gallery.isActive}
              onIncrement={() => onIncrement(gallery.id)}
              onDecrement={() => onDecrement(gallery.id)}
            />
          </div>
          <div className={cx(styles.galleryContainer, { [styles.inactive]: !gallery.isActive })}>
            <div className={styles.overlay} data-element="upload-overlay">
              <Button
                onClick={() => setIsModalOpen(true)}
                leading={<Icon name="upload" />}
                inversed
                tier={Tier.SECONDARY}>
                {tl('upload_images')}
              </Button>
            </div>
            <Flex className={styles.tiles}>
              {galleryAssets.slice(0, 3).map((a, i) => (
                <FlexItem flex key={a == null ? i : a.url}>
                  <div className={cx(styles.tile, { [styles.empty]: a == null })}>
                    {a == null ? (
                      <Icon shade={Shade.MEDIUM} name="image" />
                    ) : (
                      <img className={styles.image} src={a.url} />
                    )}
                  </div>
                </FlexItem>
              ))}
            </Flex>
            <div className={styles.galleryControls}>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Icon shade={Shade.DARK} name="arrow-left" />
                </FlexItem>
                <FlexItem>
                  <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ width: '100px' }}>
                    {range(4).map((index) => (
                      <FlexItem key={index}>
                        <Dot color={Shade.LIGHT} />
                      </FlexItem>
                    ))}
                  </Flex>
                </FlexItem>
                <FlexItem>
                  <Icon shade={Shade.DARK} name="arrow-right" />
                </FlexItem>
              </Flex>
            </div>
            <div
              className={cx(styles.tile, styles.small, {
                [styles.empty]: galleryAssets[3] == null,
              })}>
              {galleryAssets[3] == null ? (
                <Icon shade={Shade.MEDIUM} name="image" />
              ) : (
                <img className={styles.image} src={galleryAssets[3]?.url} />
              )}
            </div>
          </div>
        </FlexItem>
      </Flex>
      <UploadGalleryModal
        assets={gallery.assets}
        visible={isModalOpen}
        onClickClose={() => setIsModalOpen(false)}
        onChangeAssets={(assets) => onChangeAssets(gallery.id, assets)}
      />
    </div>
  );
};
