import {
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle, ShowroomAddressEditor, SpacedSeparator } from '~/components';
import { OrganisationRole } from '~/types';
import { createTranslate, useAuth } from '~/utils';

import { useLoadLicenses } from '../../utils/hooks';
import { useFetchOrganisationFromCore } from '../../utils/hooks/use-fetch-organisation-from-core';
import { AgencyDetails, AgencyTeam } from './components';

const ttNavbar = createTranslate('components.navbar');
const tt = createTranslate('pods.agency.routes');

export const Agency = () => {
  const { user } = useAuth();
  const {
    isLoading: isOrganisationLoading,
    organisationFromCore,
    refreshOrganisationFromCore,
  } = useFetchOrganisationFromCore();

  const { licenses } = useLoadLicenses();

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{ttNavbar('my_agency')}</MosaicPageTitle>
        <AgencyDetails
          isOrganisationLoading={isOrganisationLoading && organisationFromCore == null}
          organisationFromCore={organisationFromCore}
          refreshOrganisationFromCore={refreshOrganisationFromCore}
        />
        <SpacedSeparator />
        <AgencyTeam
          isOrganisationLoading={isOrganisationLoading}
          organisation={organisationFromCore}
          licenses={licenses}
          refetchOrganisation={refreshOrganisationFromCore}
        />
        <SpacedSeparator />
        <Panel
          header={
            <PanelHeader>
              <Flex style={{ width: '100%' }} justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Title size={4}>{tt('showroom_addresses')}</Title>
                </FlexItem>
                {user?.role === OrganisationRole.AGENCY_SALES_MANAGER && (
                  <FlexItem>
                    <ShowroomAddressEditor.ButtonOutlet />
                  </FlexItem>
                )}
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody>
              <ShowroomAddressEditor />
            </PanelBody>
          }
        />
      </Padding>
    </Content>
  );
};
