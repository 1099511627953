import { Tag } from '@drawbotics/react-drylus';
import { upperFirst } from 'lodash';
import React from 'react';

import { createTranslate } from '~/utils';

import { Step, StepType } from '../types';

const tt = createTranslate('pods.crm.status');

interface StatusProps {
  step: Step;
}

export const Status = ({ step }: StatusProps) => {
  const color = step.type === StepType.INITIAL ? undefined : step.color;
  return <Tag color={color}>{upperFirst(tt(step.slug))}</Tag>;
};
