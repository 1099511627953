import {
  Avatar,
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Hint,
  Icon,
  Input,
  Label,
  ListTile,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  PanelHeader,
  Separator,
  Shade,
  Size,
  Text,
  TextLink,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { ShowDateTime } from '@drawbotics/react-drylus/lib/utils';
import copyToClipboard from 'copy-to-clipboard';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { Meeting, MeetingLocation } from '~/pods/meetings/types';
import { createTranslate } from '~/utils';

import physicalMeeting from '../../../images/physical-active.svg';
import remoteMeeting from '../../../images/remote-active.svg';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.overview_panel');

const LoadingOverviewPanel = () => {
  return (
    <Panel
      header={
        <PanelHeader>
          <Flex justify={FlexJustify.START}>
            <FlexItem>
              <LoadingPlaceholder height={30} />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <LoadingPlaceholder height={20} />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <Flex align={FlexAlign.START} direction={FlexDirection.HORIZONTAL}>
            <FlexItem>
              <LoadingPlaceholder width={130} height={100} />
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem flex={1}>
              <Flex
                direction={FlexDirection.HORIZONTAL}
                align={FlexAlign.STRETCH}
                justify={FlexJustify.SPACE_AROUND}
                style={{ height: '100%' }}>
                <FlexItem style={{ width: '30%' }}>
                  <Flex
                    style={{ width: '100%' }}
                    direction={FlexDirection.VERTICAL}
                    justify={FlexJustify.START}
                    align={FlexAlign.START}>
                    <FlexItem>
                      <Label>{tt('prospect')}</Label>
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                    <FlexItem style={{ width: '100%' }}>
                      <LoadingPlaceholder width="90%" height={14} />
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                    <FlexItem style={{ width: '100%' }}>
                      <LoadingPlaceholder width="90%" height={14} />
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                    <FlexItem style={{ width: '100%' }}>
                      <LoadingPlaceholder width="90%" height={14} />
                    </FlexItem>
                  </Flex>
                </FlexItem>
                <FlexItem style={{ width: '30%' }}>
                  <Flex
                    style={{ height: '100%' }}
                    align={FlexAlign.STRETCH}
                    justify={FlexJustify.START}
                    direction={FlexDirection.HORIZONTAL}>
                    <FlexItem>
                      <Separator vertical />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem style={{ width: '100%' }}>
                      <Flex
                        style={{ width: '100%' }}
                        align={FlexAlign.START}
                        direction={FlexDirection.VERTICAL}>
                        <FlexItem>
                          <Label>{tt('location')}</Label>
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem style={{ width: '100%' }}>
                          <LoadingPlaceholder width="90%" height={14} />
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem style={{ width: '100%' }}>
                          <LoadingPlaceholder width="90%" height={14} />
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem style={{ width: '100%' }}>
                          <LoadingPlaceholder width="90%" height={14} />
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                  </Flex>
                </FlexItem>
                <FlexItem style={{ height: '100%', width: '40%' }}>
                  <Flex
                    align={FlexAlign.STRETCH}
                    justify={FlexJustify.START}
                    direction={FlexDirection.HORIZONTAL}
                    style={{ height: '100%' }}>
                    <FlexItem>
                      <Separator vertical />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem style={{ width: '100%' }}>
                      <Flex
                        align={FlexAlign.START}
                        direction={FlexDirection.VERTICAL}
                        style={{ width: '100%' }}>
                        <FlexItem>
                          <Label>{tt('booklet_link')}</Label>
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem style={{ width: '100%' }}>
                          <LoadingPlaceholder width={'85%'} height={40} />
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};

interface OverviewPanelProps {
  isLoading: boolean;
  meeting?: Meeting;
}

export const OverviewPanel = ({ isLoading, meeting }: OverviewPanelProps) => {
  const [copied, setCopied] = useState(false);

  if (isLoading) return <LoadingOverviewPanel />;

  if (meeting == null) {
    throw new Error('Invariant violated: meeting is null even though loading is complete');
  }

  const { location, additionalParticipants, meetingLink, evolutiveBookletUrl } = meeting;

  const hasExtraParticipants = !isEmpty(additionalParticipants);
  const isPhysical = location === MeetingLocation.PHYSICAL;

  return (
    <Panel
      style={{ height: '100%' }}
      header={
        <PanelHeader>
          <Flex
            direction={FlexDirection.HORIZONTAL}
            align={FlexAlign.CENTER}
            justify={FlexJustify.START}>
            <FlexItem>
              <Title size={4} noMargin>
                {tt(isPhysical ? 'in_person' : 'remote')}
              </Title>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <ListTile
                leading={<Avatar text={meeting.user.firstName[0]} />}
                title={<Text>{meeting.user.fullName}</Text>}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem style={{ height: '18px' }}>
              <ListTile
                leading={<Icon shade={Shade.LIGHT} name="calendar" />}
                title={
                  <Text shade={Shade.LIGHT} dateOptions={{ showTime: ShowDateTime.ALWAYS }}>
                    {new Date(meeting.date)}
                  </Text>
                }
              />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <Flex direction={FlexDirection.HORIZONTAL}>
            <FlexItem>
              <img src={isPhysical ? physicalMeeting : remoteMeeting} width={130} />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem flex={1}>
              <Flex direction={FlexDirection.HORIZONTAL} align={FlexAlign.STRETCH}>
                <FlexItem style={{ width: '30%' }}>
                  <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
                    <FlexItem>
                      <Label>{tt('prospect')}</Label>
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ vertical: Size.SMALL }}>
                        <ListTile
                          title={<Text>{meeting.lead.fullName}</Text>}
                          subtitle={<Text shade={Shade.LIGHT}>{meeting.lead.email}</Text>}
                        />
                      </Margin>
                    </FlexItem>
                    {hasExtraParticipants ? (
                      <FlexItem>
                        <Text>{`+ ${additionalParticipants?.length} ${tt('extra_participants', {
                          count: additionalParticipants?.length!,
                        })}`}</Text>
                      </FlexItem>
                    ) : null}
                  </Flex>
                </FlexItem>
                <FlexItem style={{ width: '30%' }}>
                  <Flex
                    align={FlexAlign.STRETCH}
                    justify={FlexJustify.START}
                    style={{ height: '100%' }}>
                    <FlexItem>
                      <Separator vertical />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem>
                      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
                        <FlexItem>
                          <Label>{tt('location')}</Label>
                        </FlexItem>
                        <FlexItem>
                          <Margin size={{ vertical: Size.SMALL }}>
                            {isPhysical ? (
                              <Text>{meeting.address}</Text>
                            ) : (
                              <a href={meetingLink}>
                                <TextLink>
                                  <ListTile
                                    leading={<Icon name="external-link" />}
                                    title={<TextLink>{tt('go_to_remote')}</TextLink>}
                                  />
                                </TextLink>
                              </a>
                            )}
                          </Margin>
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                  </Flex>
                </FlexItem>
                <FlexItem style={{ width: '40%' }}>
                  <Flex
                    align={FlexAlign.STRETCH}
                    style={{ height: '100%' }}
                    justify={FlexJustify.START}>
                    <FlexItem>
                      <Separator vertical />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem flex={1}>
                      <Flex
                        direction={FlexDirection.VERTICAL}
                        align={FlexAlign.START}
                        style={{ width: '100%' }}>
                        <FlexItem>
                          <Label>{tt('booklet_link')}</Label>
                        </FlexItem>
                        <FlexItem style={{ width: '100%' }}>
                          <Margin size={{ vertical: Size.SMALL }} style={{ width: '100%' }}>
                            <Flex align={FlexAlign.START}>
                              <FlexItem flex={1}>
                                <Input
                                  style={{ width: '100%' }}
                                  size={Size.SMALL}
                                  placeholder={tt('loading')}
                                  value={evolutiveBookletUrl}
                                  // Should stay like this for style reasons
                                  onChange={() => {}}
                                />
                              </FlexItem>
                              <FlexSpacer size={Size.EXTRA_SMALL} />
                              <FlexItem>
                                <Button
                                  size={Size.SMALL}
                                  onClick={() => {
                                    if (evolutiveBookletUrl != null) {
                                      setCopied(true);
                                      copyToClipboard(evolutiveBookletUrl);
                                    }
                                  }}
                                  tier={Tier.TERTIARY}
                                  category={copied ? Category.SUCCESS : Category.INFO}
                                  leading={<Icon name={copied ? 'check' : 'copy'} />}>
                                  {copied ? 'copied' : 'copy'}
                                </Button>
                              </FlexItem>
                            </Flex>
                            <Hint>{tt('booklet_hint')}</Hint>
                          </Margin>
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};
