import {
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Padding,
  ProgressBar,
  Size,
  Tile,
  formatPrice,
} from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { createTranslate } from '~/utils/translation';

import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';

const tt = createTranslate('pods.insights_reports.components.charts');

interface OptionRevenueProps {
  units: InsightsEstate['units'];
  currency: string;
  dateRange: DateRange;
}

export const OptionRevenue = ({ units, currency, dateRange }: OptionRevenueProps) => {
  const remainingPotentialRevenue = units.reduce((memo, u) => {
    if (u.availability === 'available') {
      return memo + u.price.value;
    }
    // Also include the units that were sold after the range this report represents
    else if (u.availability === 'booked' && u.soldAt?.isSameOrAfter(dateRange.end)) {
      return memo + u.price.value;
    } else {
      return memo;
    }
  }, 0);

  const optionRevenue = units
    .filter((u) => u.availability === 'option')
    .reduce((memo, u) => u.price.value + memo, 0);
  const percentage = optionRevenue / remainingPotentialRevenue;

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{tt('option_revenue.title')}</ChartTitle>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.EXTRA_SMALL }}>
            <Text light style={{ fontSize: '24pt' }}>
              {formatPrice({
                price: { value: optionRevenue, currency: 'EUR' },
                options: { notation: 'compact' },
              })}
            </Text>
          </Padding>
        </FlexItem>
        <FlexItem>
          <Text>
            {tt('option_revenue.detailed', {
              total: formatPrice({
                price: { value: remainingPotentialRevenue, currency },
                options: { notation: 'compact' },
              }),
            })}
          </Text>
        </FlexItem>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.SMALL }}>
            <ProgressBar color={Color.BLUE} percentage={percentage} size={Size.SMALL} />
          </Padding>
        </FlexItem>
      </Flex>
    </Tile>
  );
};
