import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Modal,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import React, { useState } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.presentation.routes.presentation.components.custom_section_modal');

interface CustomSectionModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  onClickCreate: (name: string) => void;
  loading?: boolean;
}

export const CustomSectionModal = ({
  visible,
  onClickClose,
  onClickCreate,
  loading,
}: CustomSectionModalProps) => {
  const [name, setName] = useState('');

  return (
    <Modal
      title={tt('custom_section')}
      visible={visible}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button
              onClick={() => {
                setName('');
                onClickClose();
              }}
              tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              trailing={loading ? <Spinner inversed size={Size.SMALL} /> : null}
              disabled={name === '' || loading}
              category={Category.INFO}
              onClick={() => {
                onClickCreate(name);
                setName('');
              }}>
              {tt('create_section')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <FormGroup
        input={
          <Input
            value={name}
            placeholder={tt('placeholder_1')}
            onChange={(v) => setName(String(v))}
          />
        }
        label={<Label>{tt('section_name')}</Label>}
      />
    </Modal>
  );
};
