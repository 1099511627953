import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import React, { useEffect, useState } from 'react';

import { UnitInterestSelection } from '~/pods/crm/components';
import { InterestType, Project } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.new_lead.add_unit_modal');

interface AddUnitModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  onClickConfirm: (interests: Array<InterestType>) => void;
  projects?: Array<Project>;
  interests: Array<InterestType>;
}

export const AddUnitModal = ({
  visible,
  onClickClose,
  onClickConfirm,
  projects,
  interests,
}: AddUnitModalProps) => {
  const [tempInterests, setTempInterests] = useState(interests);
  const handleOnChangeInterests = (
    projectId: string,
    unitInterests: NonNullable<InterestType['unitInterests']>,
  ) => {
    setTempInterests([
      ...tempInterests.filter((interest) => interest.projectId !== projectId),
      { projectId, unitInterests },
    ]);
  };

  useEffect(() => {
    setTempInterests(interests);
  }, [interests.length]);

  return (
    <Modal
      onClickClose={onClickClose}
      visible={visible}
      title={tt('title')}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel_button')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button category={Category.INFO} onClick={() => onClickConfirm(tempInterests)}>
              {tt('save_button')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      {[...tempInterests]
        .sort((a, b) => a.projectId.localeCompare(b.projectId))
        .map((interest) => {
          const project = projects?.find((project) => project.id === interest.projectId);
          return project != null ? (
            <UnitInterestSelection
              onChange={(unitInterests) => handleOnChangeInterests(project.id, unitInterests)}
              key={interest.projectId}
              project={project}
              values={interest.unitInterests}
            />
          ) : undefined;
        })}
    </Modal>
  );
};
