import { TargetDomain } from '@drawbotics/auth';

import { LocaleCode } from '~/utils';

import { Attachment } from './attachment';
import { Organisation, OrganisationRole, Subscription } from './organisation';
import { Id } from '.';

export enum UserLocale {
  EN = 'EN',
  FR = 'FR',
  NL = 'NL',
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isAgent: boolean;
  exp: number;
  confirmed: boolean;
  role: OrganisationRole;
  organisation?: Organisation;
  locale: LocaleCode;
  profilePicture?: Attachment;
  intercomHash?: string;
  domain?: TargetDomain;
  impersonation?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface OrganisationFromToken {
  id: Id;
  public_token: Id;
  name: string;
  subscription: Subscription;
  agency: boolean;
}

export interface UserFromToken {
  id: Id;
  email: string;
  first_name: string;
  last_name: string;
  exp: number;
  confirmed: boolean;
  role: 'admin' | 'member' | 'agency_sales_manager' | 'agency_sales_agent';
  organisation?: OrganisationFromToken | null;
  locale: UserLocale;
  intercom_hash?: string;
  target_domain?: TargetDomain;
  employee?: {
    id: Id;
    first_name: string;
    last_name: string;
  };
}
