import {
  Banner,
  Button,
  Category,
  Content,
  EmptyState,
  Flex,
  FlexDirection,
  FlexItem,
  Margin,
  Padding,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { useFetchLicenseCount } from '~/pods/presentation/utils';
import { Subscription } from '~/types';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
  useAuth,
  useIntercom,
} from '~/utils';

import { Intro, SalesTeamPanel } from './components';

const tt = createTranslate('pods.marketing_suite.routes.management');
const ta = createTranslate('pods.marketing_suite');

export const Management = () => {
  const {
    licenses,
    error,
    isLoading: isLoadingLicenses,
    refetch: refetchLicenses,
  } = useFetchLicenseCount();
  const { user } = useAuth();

  const { showIntercom } = useIntercom();

  if (error != null) {
    return (
      <Flex direction={FlexDirection.VERTICAL} style={{ height: '100%' }}>
        <FlexItem>
          <EmptyState
            title={getTitleFromErrorType(error)}
            variation={getEmptyStateVariationFromErrorType(error)}
            description={getMessageFromErrorType(error)}
          />
        </FlexItem>
      </Flex>
    );
  } else if (isLoadingLicenses && licenses == null) {
    return (
      <Content fullHeight fullWidth>
        <Spinner fullSize size={Size.LARGE} />;
      </Content>
    );
  } else if (licenses != null) {
    return licenses.totalLicenseCount === 0 &&
      user?.organisation?.subscription === Subscription.REACH ? (
      <Intro />
    ) : (
      <Content fullHeight fullWidth>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <MosaicPageTitle>{ta('license_management')}</MosaicPageTitle>
          {user?.organisation?.subscription === Subscription.REACH &&
          licenses.availableLicenseCount === 0 ? (
            <Banner
              category={Category.INFO}
              trailing={<Button onClick={showIntercom}>{tt('contact_us')}</Button>}>
              {tt('all_seats_used')}
            </Banner>
          ) : null}
          <Margin size={{ top: Size.DEFAULT }} />
          <SalesTeamPanel licenses={licenses} refetchLicenses={refetchLicenses} />
        </Padding>
      </Content>
    );
  } else {
    return null;
  }
};
