import sv from '@drawbotics/drylus-style-vars';
import {
  BigRadio,
  Button,
  Category,
  Color,
  DateInput,
  DateObject,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Hint,
  Input,
  Label,
  Margin,
  Modal,
  MultiSelect,
  Padding,
  Select,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Title,
  dateToObject,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import dayjs from 'dayjs';
import { css } from 'emotion';
import { AnimatePresence, motion } from 'framer-motion';
import gql from 'fraql';
import { partition } from 'lodash';
import React, { useEffect, useState } from 'react';

import { ShowroomAddressSelector } from '~/components';
import {
  dateToTimeObject,
  isTimeObject,
  useFetchAddresses,
  useFetchPresentations,
  useFetchTeammates,
} from '~/pods/meetings/utils';
import { ID } from '~/types';
import {
  createTranslate,
  extractGQLErrorMessages,
  isNullOrEmpty,
  useAuth,
  useCreateLead,
  useMosaicMutation,
} from '~/utils';

import physicalMeetingActive from '../../images/physical-active.svg';
import physicalMeeting from '../../images/physical.svg';
import remoteMeetingActive from '../../images/remote-active.svg';
import remoteMeeting from '../../images/remote.svg';
import { NotificationModal } from '../../routes/Meeting/components/NotificationModal';
import { Meeting, MeetingLocation, Presentation, PresentationStatus } from '../../types';
import { ParticipantsEditor, TimeInput, TimeObject } from '../';
import success from './images/success.svg';

const tt = createTranslate('pods.marketing_suite');

const styles = {
  image: css`
    height: 100px;
  `,
  presentationsMultiSelect: css`
    div[data-value='yourLabelOption'],
    div[data-value='otherLabelOptions'] {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.88rem;
      color: ${sv.neutralDark};
    }
  `,
};

function _roundTimeObject(obj: TimeObject): TimeObject {
  const { minute } = obj;
  if (minute % 5 !== 0) {
    return {
      ...obj,
      minute: Math.min(Math.ceil(minute / 5) * 5, 55),
    };
  }
  return obj;
}

function _getClosestNextHour() {
  return dayjs().add(1, 'hour').set('minute', 0);
}

function _getPresentationOptions(
  assignedPresentations: Array<Presentation>,
  otherPresentations: Array<Presentation>,
) {
  const ownPresentationsOptions =
    assignedPresentations.length > 0
      ? [
          {
            value: 'yourLabelOption',
            label: tt('your_own_presentations'),
            disabled: true,
          },
          ...assignedPresentations.map((presentation) => ({
            value: presentation.id,
            label: presentation.name,
          })),
        ]
      : [];

  const otherPresentationsOptions =
    otherPresentations.length > 0
      ? [
          {
            value: 'otherLabelOptions',
            label: tt('presentations_not_assigned_to_you'),
            disabled: true,
          },
          ...otherPresentations.map((presentation) => ({
            value: presentation.id,
            label: presentation.name,
          })),
        ]
      : [];

  return [...ownPresentationsOptions, ...otherPresentationsOptions];
}

interface NewMeetingForm {
  salesPersonId: string;
  date: string;
  locationType: MeetingLocation;
  address: string;
  presentationIds: Array<string>;
  leadId: string;
  email: string;
  firstName: string;
  lastName: string;
  additionalParticipantEmails: Array<string>;
}

export type NewMeetingFormErrors = {
  [K in keyof NewMeetingForm]?: string;
};

interface CreateMeetingVariables {
  meeting: {
    userId: string;
    datetime: string;
    timezone: string;
    location: MeetingLocation;
    address?: string;
    presentationIds: Array<string>;
    leadId?: string;
    additionalParticipants?: Array<string>;
  };
}

interface CreateMeetingResult {
  createMeeting: {
    meeting: Meeting;
  };
}

const createMeetingMutation = gql`
  mutation createMeeting($meeting: CreateMeetingInput!) {
    createMeeting(input: $meeting) {
      meeting {
        id
        user {
          id
          fullName @computed(type: User)
        }
        lead {
          email
          firstName
          lastName
          id
        }
        location
        address
        date: datetime
        additionalParticipants
        presentations {
          id
          name
        }
      }
    }
  }
`;

interface UpdateMeetingVariables {
  meeting: {
    id: string;
    userId: string;
    datetime: string;
    location: MeetingLocation;
    address?: string;
    additionalParticipants?: Array<string>;
    presentationIds: Array<ID>;
  };
}

interface UpdateMeetingResult {
  editMeeting: {
    meeting: Meeting;
  };
}

const updateMeetingMutation = gql`
  mutation updateMeeting($meeting: UpdateMeetingInput!) {
    updateMeeting(input: $meeting) {
      meeting {
        id
        user {
          id
          fullName @computed(type: User)
        }
        location
        address
        date: datetime
        additionalParticipants
      }
    }
  }
`;

enum ModalState {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  SUCCESS = 'SUCCESS',
  RESEND_NOTIFICATION = 'RESEND_NOTIFICATION',
}

interface CreateAndEditMeetingModalProps {
  isVisible: boolean;
  onClose: VoidFunction;
  meeting?: Meeting;
  lead?: {
    id: ID;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
}

export const CreateAndEditMeetingModal = ({
  isVisible,
  onClose,
  meeting,
  lead,
}: CreateAndEditMeetingModalProps) => {
  const { teammates = [] } = useFetchTeammates();
  const { addresses } = useFetchAddresses();
  const { isLoading: isPresentationsLoading, presentations = [] } = useFetchPresentations({
    status: PresentationStatus.PUBLISHED,
  });

  const { user } = useAuth();
  const {
    get,
    set,
    values: formValues,
  } = useForm<NewMeetingForm>({
    date: _getClosestNextHour().toISOString(),
  });
  const [formErrors, setFormErrors] = useState<NewMeetingFormErrors>({});
  const [modalState, setModalState] = useState<ModalState>(
    meeting == null ? ModalState.CREATE : ModalState.EDIT,
  );
  const [previousLocationType, setPreviousLocationType] = useState<MeetingLocation>();
  const { showAlert } = useAlert();

  const { createLead, isLoading: isCreatingLead } = useCreateLead();

  const {
    executeMutation: createMeeting,
    res: { fetching: isCreateFetching },
  } = useMosaicMutation<CreateMeetingResult, CreateMeetingVariables>(createMeetingMutation);

  const {
    executeMutation: updateMeeting,
    res: { fetching: isUpdateFetching },
  } = useMosaicMutation<UpdateMeetingResult, UpdateMeetingVariables>(updateMeetingMutation);

  // Select the current user by default if possible
  useEffect(() => {
    if (teammates.length !== 0 && modalState !== ModalState.EDIT) {
      const maybeUser = teammates.find((u) => u.email === user?.email);
      if (maybeUser != null) {
        set(maybeUser.id, 'salesPersonId');
      }
    }
  }, [teammates.length]);

  const [assignedPresentations, otherPresentations] = partition(presentations, (pres) =>
    pres.assignedUsers.some((u) => u.email === user?.email),
  );

  useEffect(() => {
    if (assignedPresentations.length > 0 && modalState !== ModalState.EDIT) {
      const assignedPresentationsIds = assignedPresentations.map((p) => p.id);
      set(assignedPresentationsIds, 'presentationIds');
    }
  }, [assignedPresentations.length]);

  useEffect(() => {
    if (lead != null) {
      set(lead.id, 'leadId');
      set(lead.firstName, 'firstName');
      set(lead.lastName, 'lastName');
      set(lead.email, 'email');
    }
  }, [JSON.stringify(lead)]);

  const resetForm = () => {
    set(undefined, 'locationType');
    set(undefined, 'address');
    set(undefined, 'leadId');
    set(undefined, 'additionalParticipantEmails');
    if (lead == null) {
      set(undefined, 'email');
      set(undefined, 'leadId');
      set(undefined, 'firstName');
      set(undefined, 'lastName');
    }
  };

  useEffect(() => {
    if (!isVisible) {
      if (meeting == null) {
        resetForm();
      }
      setFormErrors({});
      setModalState(meeting == null ? ModalState.CREATE : ModalState.EDIT);
    }
  }, [isVisible]);

  useEffect(() => {
    if (meeting != null && modalState === ModalState.CREATE) {
      setModalState(ModalState.EDIT);
    }
  }, [JSON.stringify(meeting)]);

  useEffect(() => {
    if (modalState === ModalState.EDIT) {
      if (meeting == null) {
        throw new Error('Invariant violated: Modal state is `EDIT` but meeting was null');
      }
      setPreviousLocationType(meeting.location);
      set(meeting.user.id, 'salesPersonId');
      set(meeting.date, 'date');
      set(meeting.location, 'locationType');
      set(meeting.address, 'address');
      set(
        meeting.presentations.map((pres) => pres.id),
        'presentationIds',
      );
      set(meeting.lead.id, 'leadId');
      set(meeting.lead.email, 'email');
      set(meeting.lead.firstName, 'firstName');
      set(meeting.lead.lastName, 'lastName');
      set(meeting.additionalParticipants, 'additionalParticipantEmails');
    }
  }, [modalState]);

  const dateObject = formValues.date != null ? dateToObject(new Date(formValues.date)) : '';
  const timeObject =
    formValues.date != null ? dateToTimeObject(new Date(formValues.date)) : { hour: 0, minute: 0 };

  const hasNoPresentation = presentations.length === 0;
  const isPhysical = formValues.locationType === MeetingLocation.PHYSICAL;
  const isRemote = formValues.locationType === MeetingLocation.REMOTE;

  const setError = (key: keyof NewMeetingFormErrors, message: string) => {
    setFormErrors((state) => ({
      ...state,
      [key]: message,
    }));
  };

  const clearError = (key: keyof NewMeetingFormErrors) => {
    setFormErrors((state) => ({
      ...state,
      [key]: undefined,
    }));
  };

  const validate = () => {
    let isValid = true;
    const cannotBeEmpty: Array<keyof NewMeetingForm> = [
      'date',
      'salesPersonId',
      'locationType',
      'lastName',
      'presentationIds',
      'email',
    ];

    for (const key of cannotBeEmpty) {
      if (isNullOrEmpty(formValues[key])) {
        setError(key, tt('cannot_be_empty'));
        isValid = false;
      } else {
        clearError(key);
      }
    }

    if (isPhysical) {
      if (isNullOrEmpty(formValues['address'])) {
        setError('address', tt('cannot_be_empty'));
        isValid = false;
      } else {
        clearError('address');
      }
    }

    return isValid;
  };

  useEffect(() => {
    // Update the errors when the values change
    if (Object.values(formErrors).length > 0) {
      validate();
    }
  }, [JSON.stringify(formValues)]);

  const handleChangeDateTime = (value: TimeObject | DateObject, name?: 'date') => {
    const dateWithTime = isTimeObject(value)
      ? dayjs(formValues.date).set('hour', value.hour).set('minute', value.minute)
      : dayjs(formValues.date)
          .set('date', value.day)
          .set('month', value.month - 1)
          .set('year', value.year);
    set(dateWithTime.toISOString(), name);
  };

  const handleCreateMeeting = async () => {
    const isValid = validate();

    if (isValid) {
      const {
        salesPersonId,
        date,
        firstName,
        lastName,
        email,
        additionalParticipantEmails,
        locationType,
        ...rest
      } = formValues as NewMeetingForm;
      let leadId: string;

      if (!rest.leadId) {
        const newLeadRes = await createLead({
          email,
          firstName,
          lastName,
        });

        if (newLeadRes.error != null) {
          const errorMessage = extractGQLErrorMessages(newLeadRes.error).message;
          showAlert({
            text:
              errorMessage != null && errorMessage !== 'propagation_error'
                ? tt(`create_meeting_errors.${errorMessage}`)
                : tt('create_meeting_errors.generic_error'),
            category: Category.DANGER,
          });
          return;
        }

        leadId = newLeadRes.data!.createLead.lead.id;
      } else {
        leadId = rest.leadId;
      }

      const { error } = await createMeeting({
        meeting: {
          ...rest,
          location: locationType,
          userId: salesPersonId,
          datetime: dayjs(formValues.date).tz(dayjs.tz.guess()).format(), // Add timezone information to ISO
          timezone: dayjs.tz.guess(),
          leadId,
          additionalParticipants: additionalParticipantEmails,
        },
      });
      if (error != null) {
        const errorMessage = extractGQLErrorMessages(error).message;
        showAlert({
          text:
            errorMessage != null && errorMessage !== 'propagation_error'
              ? tt(`create_meeting_errors.${errorMessage}`)
              : tt('create_meeting_errors.generic_error'),
          category: Category.DANGER,
        });
      } else {
        setModalState(ModalState.SUCCESS);
      }
    }
  };

  const handleUpdateMeeting = async () => {
    if (meeting == null) {
      throw new Error('Invariant violated');
    }

    const {
      salesPersonId,
      date,
      locationType,
      address,
      additionalParticipantEmails,
      presentationIds,
    } = formValues as NonNullable<NewMeetingForm>;
    const { date: originalDate, location: originalLocation, address: originalAddress } = meeting;

    const { error } = await updateMeeting({
      meeting: {
        id: meeting!.id,
        userId: salesPersonId,
        datetime: dayjs(date).tz(dayjs.tz.guess()).format(), // Add timezone information to ISO
        address,
        location: locationType,
        additionalParticipants: additionalParticipantEmails,
        presentationIds,
      },
    });
    if (error != null) {
      const errorMessage = extractGQLErrorMessages(error).message;
      showAlert({
        text: errorMessage
          ? tt(`create_meeting_errors.${errorMessage}`)
          : tt('create_meeting_errors.generic_error'),
        category: Category.DANGER,
      });
    } else {
      showAlert({ text: tt('details_updated'), category: Category.SUCCESS });
      if (
        originalLocation !== formValues.locationType ||
        originalDate !== formValues.date ||
        originalAddress !== formValues.address
      ) {
        setModalState(ModalState.RESEND_NOTIFICATION);
      } else {
        onClose();
      }
    }
  };

  if (modalState === ModalState.SUCCESS) {
    return (
      <Modal visible={isVisible}>
        <Padding size={Size.DEFAULT}>
          <Flex direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <img src={success} />
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            <FlexItem>
              <Title size={4}>{tt('invitation_sent')}</Title>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem style={{ textAlign: 'center' }}>
              <Text>
                {tt('success_1')}
                <br />
                {tt('success_2')}
              </Text>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Button color={Color.BLUE} onClick={onClose}>
                {tt('done')}
              </Button>
            </FlexItem>
          </Flex>
        </Padding>
      </Modal>
    );
  } else if (modalState === ModalState.RESEND_NOTIFICATION) {
    return (
      <NotificationModal
        previousLocation={previousLocationType}
        meeting={meeting!}
        visible={isVisible}
        onClickClose={onClose}
      />
    );
  } else {
    return (
      <Modal
        visible={isVisible}
        title={modalState === ModalState.CREATE ? tt('create_meeting') : tt('edit_meeting')}
        onClickClose={onClose}
        footer={
          <Flex style={{ width: '100%' }} justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Text shade={Shade.LIGHT} size={Size.SMALL}>
                * {tt('mandatory_fields')}
              </Text>
            </FlexItem>
            <FlexItem>
              <Flex>
                <FlexItem>
                  <Button tier={Tier.TERTIARY} onClick={onClose}>
                    {tt('cancel')}
                  </Button>
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Button
                    onClick={
                      modalState == ModalState.CREATE ? handleCreateMeeting : handleUpdateMeeting
                    }
                    color={Color.BLUE}
                    leading={
                      isCreatingLead || isCreateFetching || isUpdateFetching ? <Spinner /> : null
                    }>
                    {modalState == ModalState.CREATE ? tt('send_invitation') : tt('update_details')}
                  </Button>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        }>
        <Title size={4}>1. {tt('choose_meeting')}*</Title>
        <Flex align={FlexAlign.START} justify={FlexJustify.SPACE_BETWEEN}>
          <FlexItem flex={1}>
            <BigRadio
              checked={isPhysical}
              name="locationType"
              label={tt('meeting_location.physical')}
              value={MeetingLocation.PHYSICAL}
              onChange={set}>
              <Flex>
                <FlexItem>
                  <img
                    src={isPhysical ? physicalMeetingActive : physicalMeeting}
                    className={styles.image}
                  />
                </FlexItem>
              </Flex>
            </BigRadio>
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem flex={1}>
            <BigRadio
              checked={isRemote}
              name="locationType"
              label={tt('meeting_location.remote')}
              value={MeetingLocation.REMOTE}
              onChange={set}>
              <Flex>
                <FlexItem>
                  <img
                    src={isRemote ? remoteMeetingActive : remoteMeeting}
                    className={styles.image}
                  />
                </FlexItem>
              </Flex>
            </BigRadio>
          </FlexItem>
        </Flex>
        {formErrors.locationType != null ? (
          <Margin size={{ top: Size.SMALL }}>
            <Hint category={Category.DANGER}>{formErrors.locationType}</Hint>
          </Margin>
        ) : null}
        <Margin size={Size.SMALL} />
        <FormGroup
          label={<Label>{tt('sales_person')}*</Label>}
          input={
            <Select
              placeholder={tt('select_teammate')}
              name="salesPersonId"
              value={get}
              options={teammates.map((teammate) => ({
                value: teammate.id,
                label: teammate.fullName,
              }))}
              onChange={set}
              error={formErrors['salesPersonId']}
            />
          }
        />
        <Margin size={Size.SMALL} />
        <Flex>
          <FlexItem flex>
            <FormGroup
              label={<Label>{tt('date')}*</Label>}
              input={
                <DateInput
                  minDate={dateToObject(new Date())}
                  placeholder="dd/mm/yyyy"
                  name="date"
                  value={dateObject}
                  onChange={handleChangeDateTime}
                />
              }
            />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem flex>
            <FormGroup
              label={<Label>{tt('time')}*</Label>}
              input={
                <TimeInput
                  placeholder="hh:mm"
                  name="date"
                  value={_roundTimeObject(timeObject)}
                  onChange={handleChangeDateTime}
                />
              }
            />
          </FlexItem>
        </Flex>
        <Margin size={Size.SMALL} />
        <AnimatePresence>
          {isPhysical ? (
            <motion.div
              style={{ overflow: 'hidden' }}
              initial={{ height: 0 }}
              animate={{ height: 'initial', overflow: 'visible' }}
              exit={{ height: 0, overflow: 'hidden' }}>
              <Label>{tt('location')}*</Label>
              <Margin size={Size.EXTRA_SMALL} />
              <ShowroomAddressSelector
                addresses={addresses}
                onSelectAddress={(address) => {
                  set(`${address.street}, ${address.zipCode} ${address.city}`, 'address');
                }}
                trigger={
                  <Input
                    style={{ pointerEvents: 'none' }}
                    name="address"
                    value={get}
                    onChange={() => {}}
                    error={formErrors['address']}
                  />
                }
              />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <Margin />
        <ParticipantsEditor
          onChange={set}
          values={formValues}
          extraErrors={formErrors}
          leadLocked={modalState === ModalState.EDIT || lead != null}
        />
        <Margin />
        <Title size={4}>3. {tt('choose_presentation')}*</Title>
        <Margin size={Size.EXTRA_SMALL} />
        <FormGroup
          label={<Label>{tt('add_presentation')}</Label>}
          input={
            <div className={styles.presentationsMultiSelect}>
              <MultiSelect
                placeholder={
                  hasNoPresentation ? tt('no_presentations') : tt('select_from_published')
                }
                disabled={hasNoPresentation}
                name="presentationIds"
                loading={isPresentationsLoading}
                options={_getPresentationOptions(assignedPresentations, otherPresentations)}
                onChange={set}
                values={formValues.presentationIds ?? []}
                error={formErrors['presentationIds']}
              />
            </div>
          }
        />
      </Modal>
    );
  }
};
