import gql from 'fraql';

import { Campaign } from '~/pods/crm/types';
import { useBackofficeQuery } from '~/utils/hooks';

export interface CampaignsQueryData {
  campaigns: Array<Campaign>;
}

const CampaignsQuery = gql`
  query CampaignsQuery {
    campaigns {
      token
      name
    }
  }
`;

export function useFetchCampaigns(options?: { skip: Boolean }): {
  isCampaignsLoading: boolean;
  campaigns: Array<Campaign>;
} {
  const res = useBackofficeQuery<CampaignsQueryData, null>({
    query: CampaignsQuery,
  });

  if (options?.skip ?? false) {
    return {
      isCampaignsLoading: false,
      campaigns: [],
    };
  }

  return {
    isCampaignsLoading: res.isLoading,
    campaigns: res.data?.campaigns ?? [],
  };
}
