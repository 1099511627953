import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

import { OrganisationFromCore } from '../../types';

interface OrganisationFromCoreQueryData {
  organisation: OrganisationFromCore;
}

const OrganisationFromCoreQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      publicToken
      name
      isAgency: agency
      assignedProjects {
        id
        name
        slug
        organisation {
          publicToken
          id
          name
        }
      }
      logo {
        contentType
        filename
        id
        url
      }
      users {
        id
        firstName
        lastName
        fullName @computed(type: User)
        email
        role
        createdAt
        assignedProjects {
          id
        }
      }
      allUsers {
        id
        firstName
        lastName
        fullName @computed(type: User)
        email
        role
        createdAt
        assignedProjects {
          id
        }
        deactivatedAt
      }
      invitations {
        id
        firstName
        lastName
        fullName @computed(type: Invitation)
        email
        role
        status
      }
    }
  }
`;

interface FetchOrganisationFromCore {
  isLoading: boolean;
  organisationFromCore?: OrganisationFromCore;
  error?: ErrorType;
  refreshOrganisationFromCore: VoidFunction;
}

export function useFetchOrganisationFromCore(): FetchOrganisationFromCore {
  const res = useMosaicQuery<OrganisationFromCoreQueryData, null>({
    query: OrganisationFromCoreQuery,
  });

  return {
    isLoading: res.isLoading,
    organisationFromCore: res.data?.organisation,
    error: buildError(res.error),
    refreshOrganisationFromCore: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
