import { Content, Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';


import { ProjectsPanel } from './components';


export const Dashboard = () => {
  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <ProjectsPanel />
      </Padding>
    </Content>
  );
};
