export function displaySurface(value: string | number, _unit: string) {
  const unit = _unit?.toLowerCase() ?? 'sqm';
  if (unit.includes('sqm')) {
    return `${value} m²`;
  } else if (unit.includes('sqf')) {
    return `${value} ft²`;
  } else {
    return `${value} ${unit}`;
  }
}
