import sv from '@drawbotics/drylus-style-vars';
import { Label, Paragraph, Tier, Title } from '@drawbotics/react-drylus';
import { Dayjs } from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { InsightsEstate, InsightsLead, InsightsSession, Position } from '../../types';
import {
  ConversionRate,
  DateHeader,
  DateHeaderSize,
  Footer,
  MostVisitedTypologies,
  NumberOfSessions,
  OptionRevenue,
  Page,
  PageGrid,
  PageGridItem,
  Report,
  RevenueBreakdownPerType,
  RevenuePerUnitTypology,
  StatusByTypology,
  Text,
  TotalRevenue,
  TotalSoldUnits,
  UnitsSold,
  VisitedTypologies,
} from './components';

const t = createTranslate('pods.insights_reports.routes.monthly_report');
const tt = createTranslate('pods.insights_reports.components.charts');

const styles = {
  description: css`
    width: 100%;
    position: absolute;
    top: 2.5cm;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  chartsP1: css`
    position: absolute;
    top: 7.2cm;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  extraHeader: css`
    position: absolute;
    top: 1.3cm;
    right: 2.8cm;
    text-align: right;
    display: flex;
    flex-direction: column;
  `,
  chartsP2: css`
    position: absolute;
    top: 4cm;
    bottom: 0;
    left: 0;
    right: 0;
  `,
};

interface MonthlyReportProps {
  projectName: string;
  units: InsightsEstate['units'];
  sessions: Array<InsightsSession>;
  leads: Array<InsightsLead>;
  currency: string;
  reportConfig: {
    dateRange: {
      start: Dayjs;
      end: Dayjs;
    };
    locale: string;
  };
}

export const MonthlyReport = ({
  sessions,
  units,
  leads,
  reportConfig,
  projectName,
  currency,
}: MonthlyReportProps) => {
  const { dateRange } = reportConfig;
  return (
    <Report>
      <Page
        dateHeader={
          <DateHeader size={DateHeaderSize.SMALL} color={sv.blue} dateRange={dateRange} />
        }
        footer={<Footer text={t('footer', { name: projectName })} />}>
        <div className={styles.description}>
          <Label style={{ fontSize: '12pt', marginBottom: '0.3cm' }}>{t('title')}</Label>
          <Title size={2} noMargin style={{ marginBottom: '0.6cm' }}>
            {projectName}
          </Title>
          <Paragraph style={{ width: '70%', display: 'block' }}>{t('explanation')}</Paragraph>
        </div>
        <div className={styles.chartsP1}>
          <PageGrid gridSize={0.015}>
            <PageGridItem position={Position.fromSquare(0, 4, 20, 15)}>
              <TotalSoldUnits units={units} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 26, 20, 15)}>
              <TotalRevenue units={units} dateRange={dateRange} currency={currency} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 48, 20, 15)}>
              <OptionRevenue units={units} dateRange={dateRange} currency={currency} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(17, 4, 42, 21)}>
              <RevenuePerUnitTypology dateRange={reportConfig.dateRange} units={units} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(17, 48, 20, 15)}>
              <MostVisitedTypologies
                units={units}
                dateRange={dateRange}
                title={tt('most_visited_typologies.title_monthly')}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(34, 48, 20, 15)}>
              <ConversionRate sessions={sessions} leads={leads} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(40, 4, 20, 9)}>
              <UnitsSold
                units={units}
                dateRange={dateRange}
                small
                title={tt('units_sold.title_monthly')}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(40, 26, 20, 9)}>
              <NumberOfSessions sessions={sessions} dateRange={dateRange} small />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(51, 4, 64, 15)}>
              <StatusByTypology units={units} dateRange={dateRange} />
            </PageGridItem>
          </PageGrid>
        </div>
      </Page>
      <Page
        dateHeader={
          <DateHeader size={DateHeaderSize.SMALL} color={sv.blue} dateRange={dateRange} />
        }
        footer={<Footer text={t('footer', { name: projectName })} />}>
        <div className={styles.extraHeader}>
          <Text tier={Tier.TERTIARY}>{t('title')}</Text>
          <Text tier={Tier.TERTIARY}>{projectName}</Text>
          <Text tier={Tier.TERTIARY}>{t('page', { page: 2, of: 2 })}</Text>
        </div>
        <div className={styles.chartsP2}>
          <PageGrid gridSize={0.015}>
            <PageGridItem position={Position.fromSquare(0, 4, 42, 26)}>
              <NumberOfSessions sessions={sessions} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 48, 20, 26)}>
              <VisitedTypologies
                units={units}
                dateRange={dateRange}
                chartHeight={90}
                title={tt('visited_typologies.title_monthly')}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(28, 4, 64, 32)}>
              <RevenueBreakdownPerType units={units} dateRange={dateRange} />
            </PageGridItem>
          </PageGrid>
        </div>
      </Page>
    </Report>
  );
};
