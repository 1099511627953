import gql from 'fraql';
import { OperationResult } from 'urql';

import { ID } from '~/types';
import { useMosaicMutation } from '~/utils/hooks';

import { Address, InterestType, Lead, LeadOrigin, PurchaseGoal, UnitFeature } from '../../pods/crm/types';

export interface CreateLeadVariables {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  organisationId?: string;
  minimumBudget?: number;
  maximumBudget?: number;
  minimumSurface?: number;
  maximumSurface?: number;
  unitIds?: Array<string>;
  typologies?: Array<number>;
  purchaseGoal?: PurchaseGoal;
  heardFrom?: LeadOrigin;
  unitFeatures?: Array<UnitFeature>;
  surfaceUnit?: string;
  assignedUserId?: ID;
  assignedOrganisationId?: ID;
  address?: {
    zipCode?: Address['zipCode'];
    city?: Address['city'];
    countryCode?: Address['countryCode'];
    street?: Address['street'];
  };
  interests?: Array<InterestType>;
}

const createLeadMutation = gql`
  mutation createLead($lead: CreateLeadInput!) {
    createLead(input: $lead) {
      lead {
        id
      }
    }
  }
`;

export function useCreateLead(): {
  isLoading: boolean;
  createLead: (lead: CreateLeadVariables) => Promise<OperationResult<{ createLead: { lead: Lead } }, any>>;
} {
  const { res, executeMutation: createLead } = useMosaicMutation<
    { createLead: { lead: Lead } },
    { lead: CreateLeadVariables }
  >(createLeadMutation);

  return {
    isLoading: res.fetching,
    createLead: (lead: CreateLeadVariables) => createLead({ lead }),
  };
}
