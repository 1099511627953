import { DateRange, InsightsSession, LineData } from '../../types';
import { getDataInRange } from '../get-data-in-range';

export function useLoadNumberOfSessions(
  sessions: Array<InsightsSession>,
  range: DateRange,
): { sessionsData: LineData; prevSessionsData: LineData } {
  const sessionsData = getDataInRange(range, sessions, 'createdAt');

  // To get the lines to match up perfectly, we subtract the exact maount of days in the
  // current range, instead of subtracting a month, which will casue a difference on up to 3 days
  // if going from March to February for instance.
  const daysInRange = range.end.diff(range.start, 'day') + 1; // .diff() is exclusive of second date

  const prevMonthRange: DateRange = {
    start: range.start.subtract(daysInRange, 'day'),
    end: range.start.subtract(1, 'day'),
  };

  const prevSessionsData = getDataInRange(prevMonthRange, sessions, 'createdAt').map((d, i) => {
    return { x: sessionsData[i].x, y: d.y };
  });

  return { sessionsData, prevSessionsData };
}
