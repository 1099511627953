import { Color, DrylusProvider } from '@drawbotics/react-drylus';
import { Location } from 'history';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';
import { ReportType } from '~/pods/insights/types';

import { useLoadTokenData } from '../../utils';
import { MonthlyReport } from './MonthlyReport';
import { WeeklyReport } from './WeeklyReport';

function _getToken(location: Location): string | undefined {
  const { token } = queryString.parse(location.search);
  if (typeof token !== 'string') {
    return undefined;
  }
  return token;
}

function _getType(location: Location): ReportType {
  return location.pathname.includes('weekly') ? ReportType.WEEKLY : ReportType.MONTHLY;
}

export const Reports = () => {
  const location = useLocation();
  const { isLoading, error, estate, reportConfig, leads, sessions, currency } = useLoadTokenData(
    _getToken(location),
    _getType(location),
  );

  if (error.isNotFound) {
    const message = 'One of the sources returned a 404 error';
    console.error(message);
    return (
      <div id="error-404" className="error placeholder">
        {message}
      </div>
    );
  } else if (error.isCritical) {
    const message = 'One of the sources returned a 500 error';
    console.error(message);
    return (
      <div id="error-500" className="error placeholder">
        {message}
      </div>
    );
  } else if (error.noToken) {
    const message = 'No token or invalid token provided';
    console.error(message);
    return (
      <div id="error-bad-token" className="error placeholder">
        {message}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div id="loading" className="placeholder">
        Loading...
      </div>
    );
  }

  const { units, name: projectName } = estate!;
  return (
    <DrylusProvider baseColor={Color.BLUE} style={{ width: '100%' }}>
      <RoutesWithNotFound>
        <Route
          path="/weekly"
          element={
            <WeeklyReport
              projectName={projectName}
              units={units}
              reportConfig={reportConfig!}
              leads={leads!}
              sessions={sessions!}
              currency={currency!}
            />
          }
        />
        <Route
          path="/monthly"
          element={
            <MonthlyReport
              projectName={projectName}
              units={units}
              reportConfig={reportConfig!}
              leads={leads!}
              sessions={sessions!}
              currency={currency!}
            />
          }
        />
      </RoutesWithNotFound>
    </DrylusProvider>
  );
};
