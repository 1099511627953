import sv, { darken } from '@drawbotics/drylus-style-vars';
import {
  Button,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Label,
  Margin,
  NumberInput,
  Padding,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import qs from 'query-string';
import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router';

import { CurrencyCode, HexColor } from '~/types';
import { createTranslate } from '~/utils';

import emptyState from '../images/empty-state-costs.svg';
import { FiscalSimulatorClient } from '../types';

const tt = createTranslate('pods.fiscal_simulator.components.costs_calculator');

const styles = {
  container: css`
    width: 400px;

    @media ${sv.screenL} {
      width: 100%;
    }
  `,
  input: css`
    input {
      border-radius: 0;
      background: ${sv.grey100};
      box-shadow: none;
      border-bottom: 1px solid ${sv.neutral};
      padding: ${sv.paddingSmall};

      &:hover {
        border-bottom-color: ${sv.neutralDark};
        box-shadow: none;
      }

      &:focus {
        box-shadow: none !important;
        background: ${sv.grey200};
      }

      .dark & {
        background: ${sv.grey800};
        border-bottom: 1px solid ${sv.grey500};
        color: ${sv.colorPrimaryInverse};
        font-weight: 300;

        &:hover {
          border-bottom-color: ${sv.grey300};
        }

        &:focus {
          background: ${sv.grey700};
        }
      }
    }
  `,
  button: (color?: string) => css`
    border-radius: 0;
    background: ${color ?? sv.blue};
    font-weight: 400;
    text-transform: uppercase;
    padding: calc(${sv.paddingSmall} + 1px) ${sv.defaultPadding};
    font-size: 0.9em;
    color: ${sv.colorPrimaryInverse};

    &:hover {
      background: ${darken(color ?? sv.blue, 20)};
    }

    &:disabled {
      background: ${sv.neutralLight};

      .dark & {
        background: ${sv.grey500};
        color: ${sv.colorSecondaryInverse};
      }
    }

    @media ${sv.screenL} {
      padding: calc(${sv.paddingSmall} - 3px) ${sv.defaultPadding};
    }
  `,
  total: (color?: string) => css`
    border: 2px solid ${color ?? sv.blue};
  `,
};

interface CostsCalculatorProps {
  taxes: FiscalSimulatorClient['taxes'];
  additionalFees: FiscalSimulatorClient['additionalFees'];
  color: HexColor;
  currency: CurrencyCode;
}

export const CostsCalculator = ({
  taxes,
  additionalFees,
  color,
  currency,
}: CostsCalculatorProps) => {
  const { search } = useLocation();
  const { value: _initialValue } = qs.parse(search);
  const initialValue = Number(_initialValue);

  const [value, setValue] = useState(initialValue || undefined);
  const [amount, setAmount] = useState(initialValue || undefined);

  return (
    <div className={styles.container}>
      <Flex style={{ width: '100%' }} align={FlexAlign.END}>
        <FlexItem flex>
          <FormGroup
            label={<Label>{tt('unit_price')}</Label>}
            input={
              <NumberInput
                className={styles.input}
                placeholder="e.g. 500.000"
                value={value ?? ''}
                onClick={(e: Event) => (e.target as HTMLInputElement).select()}
                onChange={(v) => setValue(Number(v))}
                withCounter={false}
              />
            }
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Button
            disabled={value == null}
            className={styles.button(color)}
            onClick={() => setAmount(value)}>
            {tt('calculate')}
          </Button>
        </FlexItem>
      </Flex>
      <Margin size={{ vertical: Size.SMALL }}>
        {amount === 0 || amount == null ? (
          <img style={{ width: 200, margin: 'auto', display: 'block' }} src={emptyState} />
        ) : (
          <Fragment>
            <div style={{ background: sv.grey100 }}>
              <Padding size={{ horizontal: Size.DEFAULT, vertical: Size.SMALL }}>
                <Flex style={{ marginBottom: sv.marginSmall }} justify={FlexJustify.SPACE_BETWEEN}>
                  <FlexItem>
                    <Label>{tt('unit')}</Label>
                  </FlexItem>
                  <FlexItem>
                    <Text>{{ value: amount, currency }}</Text>
                  </FlexItem>
                </Flex>
                {taxes.map((tax, i) => (
                  <Flex
                    key={i}
                    style={{ marginTop: i === 0 ? 0 : sv.marginSmall }}
                    justify={FlexJustify.SPACE_BETWEEN}>
                    <FlexItem>
                      <Label>{tax.reference}</Label>
                    </FlexItem>
                    <FlexItem>
                      <Text>
                        {{
                          value: Math.round(
                            amount * (tax.coefficient / 100) * (tax.unitDistribution / 100),
                          ),
                          currency,
                        }}
                      </Text>
                    </FlexItem>
                  </Flex>
                ))}
                {additionalFees.map((af, i) => (
                  <Flex
                    key={taxes.length + i}
                    style={{ marginTop: sv.marginSmall }}
                    justify={FlexJustify.SPACE_BETWEEN}>
                    <FlexItem>
                      <Label>{af.reference}</Label>
                    </FlexItem>
                    <FlexItem>
                      <Text>
                        {{ value: Math.round(amount * (af.coefficient / 100)), currency }}
                      </Text>
                    </FlexItem>
                  </Flex>
                ))}
              </Padding>
            </div>
            <Margin size={{ vertical: Size.SMALL }}>
              <div className={styles.total(color)}>
                <Padding size={{ horizontal: Size.DEFAULT, vertical: Size.SMALL }}>
                  <Flex justify={FlexJustify.SPACE_BETWEEN}>
                    <FlexItem>
                      <Text style={{ textTransform: 'uppercase' }} bold>
                        {tt('total')}
                      </Text>
                    </FlexItem>
                    <FlexItem>
                      <Text bold>
                        {{
                          value: Math.round(
                            amount +
                              taxes.reduce(
                                (memo, t) =>
                                  memo +
                                  amount * (t.coefficient / 100) * (t.unitDistribution / 100),
                                0,
                              ) +
                              additionalFees.reduce(
                                (memo, f) => memo + amount * (f.coefficient / 100),
                                0,
                              ),
                          ),
                          currency,
                        }}
                        *
                      </Text>
                    </FlexItem>
                  </Flex>
                </Padding>
              </div>
            </Margin>
          </Fragment>
        )}
      </Margin>
    </div>
  );
};
