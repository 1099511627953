import dayjs from 'dayjs';

import { runSimulation } from './generator';
import { DateRange, SimulationData, SimulationDescription } from './generator/types';

const TOTAL_LEADS = 120;

const description: SimulationDescription = {
  units: {
    number: 120,
    typologies: {
      T1: { number: 6, price: [120000, 210000] },
      T2: { number: 28, price: [200000, 390000] },
      T3: { number: 56, price: [395000, 500000] },
      T4: { number: 21, price: [450000, 575000] },
      T5: { number: 9, price: [580000, 780000] },
    },
  },
  dateRange: {
    start: dayjs().subtract(6, 'month'),
    increment: {
      amount: 1,
      units: 'month',
    },
  },
};

// Commercial launch
// Low-end campaign, badly optimized
function month1(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Few leads (~0.5%)
  leads.create(TOTAL_LEADS * 0.005, dateRange);

  // Sell one T1
  units.sell('T1', dateRange, 1);

  units.sell('T3', dateRange, 3);

  // Options on some T2
  units.option('T2', 15);

  // Very few options on some T4
  units.option('T4', 5);

  return { units, leads, sessions };
}

function month2(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Lots of visits few days later (620 sessions, 850 visits)
  const visitsWeights = { T2: 0.3, T3: 0.4, T1: 0.2 };
  sessions.createVisits(620, 850, dateRange, visitsWeights);

  // Sold some T2
  units.sell('T2', dateRange, 15);

  // very few T4
  units.sell('T4', dateRange, 5);

  // Sold some T3
  units.sell('T3', dateRange, 15);

  return { units, leads, sessions };
}

// Second campaign -- Low-end campaign
function month3(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Few visits (340 sessions, 410 visits)
  // emphasis on T4/T5
  const weights = { T4: 0.4, T5: 0.3 };
  sessions.createVisits(560, 720, dateRange, weights);

  // Lots of leads (7%)
  leads.create(TOTAL_LEADS * 0.07, dateRange);

  // Sell one T1
  units.sell('T1', dateRange, 1);

  // T2 sold out
  units.sell('T2', dateRange);

  // Option on remaining T3
  units.option('T3');

  // Option one T1
  units.option('T1', 1);

  return { units, leads, sessions };
}

function month4(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Few hundreds of visits per week
  // spread out evenly
  sessions.createVisits(400, 400, dateRange);

  // T3 sold out
  units.sell('T3', dateRange);

  return { units, leads, sessions };
}

function month5(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Few hundreds of visits per week
  // spread out evenly
  sessions.createVisits(600, 600, dateRange);

  // Masta leads (15%)
  leads.create(TOTAL_LEADS * 0.15, dateRange);

  return { units, leads, sessions };
}

// Third campaign -- High end campaign + decrease of price
function month6(input: SimulationData, dateRange: DateRange): SimulationData {
  const { units, leads, sessions } = input;

  // Lots of visits (700 sessions, 950 visits)
  // mainly on T5/T6
  const weights = { T4: 0.3, T5: 0.4 };
  sessions.createVisits(700, 950, dateRange, weights);

  //add extra visits and leads for last week
  const lastWeek = { end: dateRange.end, start: dateRange.end.subtract(7, 'day') };
  leads.create(TOTAL_LEADS * 0.1, lastWeek);
  sessions.createVisits(600, 800, lastWeek, weights);

  //add extra visits for last day
  const lastDay = { end: dateRange.end, start: dateRange.end.subtract(1, 'day') };
  leads.create(TOTAL_LEADS * 0.1, lastDay);
  sessions.createVisits(50, 65, lastDay, weights);

  // Masta leads (7%)
  leads.create(TOTAL_LEADS * 0.07, dateRange);

  // sell all T6
  units.sell('T6', dateRange, 7);

  // sell all but one T5
  units.sell('T5', dateRange, -1);

  // units.unavailable(1);

  // units.removePrice();

  return { units, leads, sessions };
}

export const fetchFakeData = () => {
  return runSimulation(description, [month1, month2, month3, month4, month5, month6]);
};

const { units, leads, sessions } = fetchFakeData();

export { units, leads, sessions };
