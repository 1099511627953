import gql from 'fraql';

export const UpdateActiveMutation = gql`
  mutation UpdateActive($data: UpdateActiveInput!) {
    updateActive(input: $data) {
      active
    }
  }
`;

export const UpdateAssetsMutation = gql`
  mutation UpdateAssets($data: UpdateAssetsInput!) {
    updateAssets(input: $data) {
      assets {
        assetId: id
        url
        position

        ... on AttachmentAsset {
          alt
          filename
        }
      }
    }
  }
`;

export const UpdateDescriptionMutation = gql`
  mutation UpdateDescription($data: UpdateDescriptionInput!) {
    updateDescription(input: $data) {
      description
    }
  }
`;

export const UpdateTaglineMutation = gql`
  mutation UpdateTagline($data: UpdateTaglineInput!) {
    updateTagline(input: $data) {
      tagline
    }
  }
`;

export const UpdateTitleMutation = gql`
  mutation UpdateTitle($data: UpdateTitleInput!) {
    updateTitle(input: $data) {
      title
    }
  }
`;

export const UpdateLinkMutation = gql`
  mutation UpdateLink($data: UpdateLinkInput!) {
    updateLink(input: $data) {
      link {
        id
        label
        linkType
        newTab
        websiteSection {
          id
          identifier
        }
      }
    }
  }
`;

export const IncrementSectionMutation = gql`
  mutation IncrementSectionPosition($data: IncrementSectionPositionInput!) {
    incrementSectionPosition(input: $data) {
      position
    }
  }
`;

export const DecrementSectionMutation = gql`
  mutation DecrementSectionPosition($data: DecrementSectionPositionInput!) {
    decrementSectionPosition(input: $data) {
      position
    }
  }
`;
