import {
  FilterGroup,
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  SearchInput,
  SelectFilter,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import {
  Filters as FiltersType,
  Listing,
  NumberMap,
  Range,
  StringMap,
  Unit,
  UnitAvailability,
} from '~/pods/listing-manager/types';
import { filterUnits } from '~/pods/listing-manager/utils';
import { getClosestRange, getRangeSteps } from '~/pods/listing-manager/utils/range-helpers';
import { displaySurface, isNullOrEmpty, run } from '~/utils';
import { createTranslate, translatePrice } from '~/utils/translation';

import { RangeFilter } from './RangeFilter';

const tt = createTranslate('pods.listing_manager.components.filters');

function kFormat(value: number): string {
  if (value < 1000) {
    return String(value);
  }
  return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
}

interface FilterProps {
  units: Array<Unit>;
  filters: FiltersType;
  hideSearch: boolean;
  resetPage: VoidFunction;
  listing: Listing;
}

export const Filters = ({ filters, units, hideSearch, resetPage, listing }: FilterProps) => {
  if (isNullOrEmpty(units)) return null;

  const { availability, bedrooms, rooms, price, surface, search } = filters;
  const allBedrooms: NumberMap = units.reduce(
    (memo: NumberMap, unit: Unit): NumberMap => ({
      ...memo,
      [unit.bedrooms]: (memo[unit.bedrooms] ?? 0) + 1,
    }),
    {},
  );

  const allRooms: NumberMap = units.reduce(
    (memo: NumberMap, unit: Unit): NumberMap => ({
      ...memo,
      [unit.rooms]: (memo[unit.rooms] ?? 0) + 1,
    }),
    {},
  );

  const allAvailabilities: StringMap = filterUnits(units, filters).reduce(
    (memo: StringMap, unit: Unit): StringMap => ({
      ...memo,
      [unit.availability]: (memo[unit.availability] ?? 0) + 1,
    }),
    {},
  );

  const minBudget = Math.min(...units.map((unit: Unit) => unit.price));
  const maxBudget = Math.max(...units.map((unit: Unit) => unit.price));
  const minSurface = Math.round(Math.min(...units.map((unit: Unit) => unit.surface)));
  const maxSurface = Math.round(Math.max(...units.map((unit: Unit) => unit.surface)));

  const { currency, surfaceUnit } = listing;

  const priceStep = currency == null ? 50000 : getRangeSteps(maxBudget, minBudget, 10);
  const priceRange: Range =
    currency == null ? [0, 2000000] : getClosestRange(maxBudget, minBudget, priceStep);

  const surfaceStep = surfaceUnit == null ? 25 : getRangeSteps(maxSurface, minSurface, 10);
  const surfaceRange: Range =
    surfaceUnit == null ? [0, 250] : getClosestRange(maxSurface, minSurface, surfaceStep);

  const areFiltersActive =
    price.value != null ||
    surface.value != null ||
    availability.value != null ||
    bedrooms.value != null ||
    rooms.value != null;

  return (
    <Flex
      responsive={{
        XL: { style: { flexDirection: 'row-reverse' }, justify: FlexJustify.SPACE_BETWEEN },
      }}>
      <FlexItem>
        <FilterGroup
          active={areFiltersActive}
          label={tt('filters')}
          icon="filter"
          onClear={() => {
            price.set(null);
            surface.set(null);
            availability.set(null);
            bedrooms.set(null);
          }}>
          <SelectFilter
            fullWidth
            value={bedrooms.value != null ? Number(bedrooms.value) : undefined}
            label={tt('bedrooms')}
            onClear={() => bedrooms.set(null)}
            onChange={(v: number) => bedrooms.set(v.toString())}
            options={Object.keys(allBedrooms)
              .map(Number)
              .map((bedroom) => ({
                label: tt('bedroom', { count: bedroom }),
                value: bedroom,
                trailing: <Text tier={Tier.SECONDARY}>{allBedrooms[bedroom]}</Text>,
              }))}
          />
          <SelectFilter
            fullWidth
            value={rooms.value != null ? Number(rooms.value) : undefined}
            label={tt('rooms')}
            onClear={() => rooms.set(null)}
            onChange={(v: number) => rooms.set(v.toString())}
            options={Object.keys(allRooms)
              .map(Number)
              .map((room) => ({
                label: tt('room', { count: room }),
                value: room,
                trailing: <Text tier={Tier.SECONDARY}>{allRooms[room]}</Text>,
              }))}
          />
          <RangeFilter
            active={price.values != null}
            label={
              price.values
                ? `${translatePrice(kFormat(Number(price.values[0])), currency)} - ${translatePrice(
                  kFormat(Number(price.values[1])),
                  currency,
                )}`
                : tt('price')
            }
            // Drylus returns NaN values if the min and max are the same (i.e. when only one unit is present)
            onChange={(value) =>
              value.includes(NaN) ? price.set(null) : price.set(value.map((v) => v.toString()))
            }
            onClear={() => price.set(null)}
            min={priceRange[0]}
            max={priceRange[1]}
            value={price.values != null ? (price.values.map(Number) as Range) : priceRange}
            step={priceStep}
            renderValue={(v) => translatePrice(v.toLocaleString(), currency)}
          />
          <RangeFilter
            active={surface.value != null}
            label={
              surface.value
                ? `${displaySurface(surface.value[0], surfaceUnit)} - ${displaySurface(
                  surface.value[1],
                  surfaceUnit,
                )}`
                : tt('surface')
            }
            // Drylus returns NaN values if the min and max are the same (i.e. when only one unit is present)
            onChange={(value) =>
              value.includes(NaN) ? surface.set(null) : surface.set(value.map((v) => v.toString()))
            }
            onClear={() => surface.set(null)}
            min={surfaceRange[0]}
            max={surfaceRange[1]}
            value={surface.values != null ? (surface.values.map(Number) as Range) : surfaceRange}
            step={surfaceStep}
            renderValue={(v: number) => displaySurface(v, surfaceUnit)}
          />
          <SelectFilter
            fullWidth
            value={String(availability.value) as UnitAvailability}
            label={tt('availability')}
            onClear={() => availability.set(null)}
            onChange={(v: UnitAvailability) => availability.set(v)}
            options={Object.keys(allAvailabilities).map((availability) => ({
              label: tt(`availabilities.${availability}`),
              value: availability as UnitAvailability,
              trailing: <Text tier={Tier.SECONDARY}>{allAvailabilities[availability]}</Text>,
            }))}
          />
        </FilterGroup>
      </FlexItem>
      {run(() => {
        if (!hideSearch) {
          return (
            <FlexItem responsive={{ L: { flex: true } }}>
              <Margin
                size={{ left: Size.EXTRA_SMALL }}
                responsive={{ XL: { size: { right: Size.SMALL } } }}>
                <SearchInput
                  style={{ minWidth: '200px' }}
                  onChange={(v: string) => {
                    resetPage();
                    search.set(v.replace(/ /g, '%20'));
                  }}
                  value={search.value ?? ''}
                  placeholder={tt('search_by')}
                  responsive={{
                    L: { style: undefined, placeholder: tt('search') },
                    XL: { style: { minWidth: '300px' } },
                  }}
                />
              </Margin>
            </FlexItem>
          );
        }
      })}
    </Flex>
  );
};
