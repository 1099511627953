import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Text,
} from '@drawbotics/react-drylus';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import PlaceholderImage from '../images/placeholders/BigChartPlaceholder.svg';
import { getEmptyDates, toDataPoints } from '../utils';

const MARGIN = {
  top: 25,
  bottom: 25,
  left: 30,
  right: 25,
};

const styles = {
  container: css`
    position: relative;
    width: 100%;
    height: 350px;
  `,
  chartContainer: css`
    width: 100%;
    height: 100%;
    opacity: 0.5;
  `,
  placeholder: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  `,
};

function _getPlaceholderData(months: number) {
  const start = dayjs().subtract(months, 'month');
  const end = dayjs().startOf('month');

  const dates = getEmptyDates({ start, end }, 'month', 'MMM YY');

  Object.keys(dates).forEach((key) => {
    dates[key] = 100;
  });

  return toDataPoints(dates);
}

interface LineChartPlaceholderProps {
  message?: string;
  height?: number;
  months?: number;
}

export const LineChartPlaceholder = ({
  message,
  height = 350,
  months = 10,
}: LineChartPlaceholderProps) => {
  const data = _getPlaceholderData(months);
  return (
    <div className={styles.container} style={{ height }}>
      <div className={styles.chartContainer}>
        <ResponsiveLine
          data={[{ id: 'line', data }]}
          axisLeft={{ tickValues: [0, 20, 40, 60, 80, 100] }}
          lineWidth={0}
          enablePoints={false}
          margin={MARGIN}
        />
      </div>
      <div className={styles.placeholder}>
        <Flex
          justify={FlexJustify.CENTER}
          align={FlexAlign.CENTER}
          direction={FlexDirection.VERTICAL}
          style={{ height: '100%' }}>
          <FlexItem>
            <img src={PlaceholderImage} />
          </FlexItem>
          {message != null ? (
            <FlexItem>
              <Text disabled>{message}</Text>
            </FlexItem>
          ) : null}
        </Flex>
      </div>
    </div>
  );
};
