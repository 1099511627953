import { Content, Margin, Padding, Separator, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { Availabilities, ProfileDetails } from '~/pods/profile/components';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.settings.routes.profile');

export const Profile = () => {
  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('profile')}</MosaicPageTitle>
        <ProfileDetails />
        <Margin size={Size.DEFAULT}>
          <Separator />
        </Margin>
        <Availabilities />
      </Padding>
    </Content>
  );
};
