import {
  Button,
  Category,
  Content,
  EmptyState,
  Grid,
  GridItem,
  Padding,
  Panel,
  PanelBody,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { Subscription } from '~/types';
import { createTranslate, useAuth, useIntercom } from '~/utils';

import statsPlaceholder from '../../images/stats.svg';
import { useLoadStats } from '../../utils';
import {
  MeetingDurationTile,
  MeetingEfficiencyTile,
  MeetingsOverTimeTile,
  MonthlyChampionsTile,
  TopPresentationTile,
} from './components';

const tt = createTranslate('pods.product_stats.routes.product_stats');

export const ProductStats = () => {
  const { data, isLoading, error } = useLoadStats();
  const { user } = useAuth();
  const { showIntercom } = useIntercom();

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('stats')}</MosaicPageTitle>
        {user?.organisation?.subscription === Subscription.ESSENTIALS ? (
          <Panel
            body={
              <PanelBody>
                <Padding size={{ vertical: Size.HUGE }}>
                  <EmptyState
                    image={statsPlaceholder}
                    title={tt('stats_will_show')}
                    description={tt('upgrade_to_growth')}>
                    <Button category={Category.INFO} onClick={showIntercom}>
                      {tt('contact_us')}
                    </Button>
                  </EmptyState>
                </Padding>
              </PanelBody>
            }
          />
        ) : (
          <Grid columns={3} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
            <GridItem span={2} rowSpan={2}>
              <MeetingsOverTimeTile isLoading={isLoading} data={data?.meetingsPerUser} />
            </GridItem>
            <GridItem>
              <MonthlyChampionsTile />
            </GridItem>
            <GridItem>
              <MeetingDurationTile isLoading={isLoading} duration={data?.medianMeetingDuration} />
            </GridItem>
            <GridItem>
              <TopPresentationTile
                isLoading={isLoading}
                data={data?.topPresentation}
                error={error}
              />
            </GridItem>
            <GridItem span={2}>
              <MeetingEfficiencyTile />
            </GridItem>
          </Grid>
        )}
      </Padding>
    </Content>
  );
};
