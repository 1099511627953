import sv from '@drawbotics/drylus-style-vars';

import { Availability } from '../types';

export function getColorForStatus(status: Availability): string {
  switch (status) {
    case Availability.BOOKED:
      return sv.green;
    case Availability.OPTION:
      return sv.blue;
    default:
      return sv.neutral;
  }
}

export function getLabelColorForStatus(status: Availability): string {
  switch (status) {
    case Availability.AVAILABLE:
      return sv.colorPrimary;
    default:
      return sv.colorPrimaryInverse;
  }
}
