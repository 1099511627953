import {
  BigRadio,
  Button,
  Category,
  DateInput,
  DateObject,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Label,
  Margin,
  Modal,
  MultiSelect,
  Size,
  Spinner,
  TextArea,
  Tier,
  dateToObject,
  objectToDate,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React from 'react';

import email from '~/pods/crm/images/email.svg';
import meeting from '~/pods/crm/images/meeting.svg';
import note from '~/pods/crm/images/note.svg';
import phoneCall from '~/pods/crm/images/phone-call.svg';
import { Log, LogType, ManualLogType, Project } from '~/pods/crm/types';
import {
  isLogWithProject,
  isManualLogType,
  useFetchOrganisation,
  useUpdateLog,
} from '~/pods/crm/utils';
import { createTranslate } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

const styles = {
  image: css`
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 80px;
  `,
};

const tt = createTranslate('pods.crm.routes.lead.components.log_modal');

function _getPlaceholderForType(type: ManualLogType): string | undefined {
  switch (type) {
    case LogType.MEETING:
      return tt('description_placeholder.meeting');
    case LogType.NOTE:
      return tt('description_placeholder.note');
    case LogType.PHONE_CALL:
      return tt('description_placeholder.call');
  }
}

interface CreateLogVariables {
  log: {
    leadId: string;
    type: ManualLogType;
    content?: string;
    date: string;
    projectIds: Array<string>;
  };
}

const createLogMutation = gql`
  mutation createLog($log: CreateLogInput!) {
    createLog(input: $log) {
      log {
        id
        projects {
          id
          name
        }
      }
    }
  }
`;

interface CreateLogPayload {
  createLog: {
    log: Log;
  };
}

interface LogForm {
  type: ManualLogType;
  content: string;
  date: DateObject;
  projectIds: Array<string>;
}

interface LogModalProps {
  leadId: string;
  visible: boolean;
  onClickClose: VoidFunction;
  log?: Log;
  mode: 'edit' | 'create';
  onOperationComplete?: VoidFunction;
}

export const LogModal = ({
  visible,
  onClickClose,
  leadId,
  log,
  mode,
  onOperationComplete,
}: LogModalProps) => {
  const logForm = useForm<LogForm>({
    date: dateToObject(log?.date ? new Date(log.date) : new Date()),
    type: log?.type && isManualLogType(log.type) ? log.type : undefined,
    content: log?.content,
    projectIds: log != null && isLogWithProject(log) ? log?.projects.map((p) => p.id) : [],
  });
  const { showAlert } = useAlert();
  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisation();
  const { isLoading: isUpdating, updateLog } = useUpdateLog();
  const {
    res: { fetching: isCreating },
    executeMutation: createLog,
  } = useMosaicMutation<CreateLogPayload, CreateLogVariables>(createLogMutation);

  const projects = organisation?.projects;
  const { type, projectIds: selectedProjects } = logForm.values;

  const handleClickClose = () => {
    onClickClose();
    setTimeout(() => {
      logForm.reset();
    }, 300);
  };

  const handleCreateLog = async () => {
    const { content, date } = logForm.values;
    const res = await createLog({
      log: {
        leadId,
        date: objectToDate(date!).toISOString(),
        content,
        type: type!,
        projectIds: selectedProjects ?? [],
      },
    });
    if (res.error != null || res.data?.createLog?.log?.id == null) {
      showAlert({ text: tt('new_log.error'), category: Category.DANGER });
      handleClickClose();
    } else {
      showAlert({ text: tt('new_log.success'), category: Category.SUCCESS });
      handleClickClose();
    }
    onOperationComplete?.();
  };

  const handleUpdateLog = async () => {
    if (log != null) {
      const { content, date } = logForm.values;
      await updateLog({
        id: log.id,
        date: objectToDate(date!).toISOString(),
        content,
        projectIds: selectedProjects,
      });
      handleClickClose();
    }
    onOperationComplete?.();
  };

  const isFormValid = type != null;

  return (
    <Modal
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={handleClickClose} tier={Tier.TERTIARY}>
              {tt('cancel_log_button')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            {mode === 'create' ? (
              <Button
                onClick={handleCreateLog}
                trailing={isCreating ? <Spinner size={Size.SMALL} inversed /> : null}
                disabled={isCreating || !isFormValid}
                category={Category.INFO}>
                {tt('create_log_button')}
              </Button>
            ) : (
              <Button
                onClick={handleUpdateLog}
                trailing={isUpdating ? <Spinner size={Size.SMALL} inversed /> : null}
                disabled={isUpdating}
                category={Category.INFO}>
                {tt('update_log_button')}
              </Button>
            )}
          </FlexItem>
        </Flex>
      }
      title={mode === 'create' ? tt('title.new') : tt('title.edit')}
      visible={visible}
      onClickClose={handleClickClose}>
      <Margin size={{ bottom: Size.LARGE }}>
        <Grid vGutters={Size.SMALL} hGutters={Size.SMALL} columns={2}>
          <GridItem>
            <BigRadio
              name="type"
              disabled={mode === 'edit'}
              value={LogType.PHONE_CALL}
              checked={type === LogType.PHONE_CALL}
              onChange={logForm.set}
              label={tt('log_type_label.call')}>
              <div className={styles.image}>
                <img src={phoneCall} />
              </div>
            </BigRadio>
          </GridItem>
          <GridItem>
            <BigRadio
              name="type"
              disabled={mode === 'edit'}
              value={LogType.MAIL}
              checked={type === LogType.MAIL}
              onChange={logForm.set}
              label={tt('log_type_label.email')}>
              <div className={styles.image}>
                <img src={email} />
              </div>
            </BigRadio>
          </GridItem>
          <GridItem>
            <BigRadio
              name="type"
              disabled={mode === 'edit'}
              value={LogType.NOTE}
              checked={type === LogType.NOTE}
              onChange={logForm.set}
              label={tt('log_type_label.note')}>
              <div className={styles.image}>
                <img src={note} />
              </div>
            </BigRadio>
          </GridItem>
          <GridItem>
            <BigRadio
              name="type"
              disabled={mode === 'edit'}
              value={LogType.MEETING}
              checked={type === LogType.MEETING}
              onChange={logForm.set}
              label={tt('log_type_label.meeting')}>
              <div className={styles.image}>
                <img src={meeting} />
              </div>
            </BigRadio>
          </GridItem>
        </Grid>
      </Margin>
      {type != null && type != LogType.MAIL ? (
        <Margin size={{ bottom: Size.LARGE }}>
          <FormGroup
            input={
              <TextArea
                name="content"
                placeholder={_getPlaceholderForType(type)}
                value={logForm.get}
                onChange={logForm.set}
              />
            }
            label={<Label>{tt('description')}</Label>}
          />
        </Margin>
      ) : null}
      <Margin size={{ bottom: Size.LARGE }}>
        <Margin size={{ bottom: Size.SMALL }}>
          <FormGroup
            input={
              <MultiSelect
                hint={organisationError != null ? tt('projects_error') : undefined}
                disabled={organisationError != null}
                name="projectIds"
                loading={isOrganisationLoading}
                onChange={logForm.set}
                values={selectedProjects ?? []}
                options={
                  projects != null
                    ? projects.map((project: Project) => ({
                        value: project.id,
                        label: project.name,
                      }))
                    : []
                }
                placeholder={tt('projects')}
              />
            }
            label={<Label>{tt('projects')}</Label>}
          />
        </Margin>
        <FormGroup
          input={<DateInput name="date" value={logForm.get} onChange={logForm.set} />}
          label={<Label>{tt('date')}</Label>}
        />
      </Margin>
    </Modal>
  );
};
