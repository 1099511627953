import {
  Avatar,
  Banner,
  Button,
  Category,
  CheckboxFilter,
  Color,
  Content,
  EmptyState,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  Padding,
  Size,
  Spinner,
  Table,
  Text,
  TextLink,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import produce from 'immer';
import { difference } from 'lodash';
import { filter, flow } from 'lodash/fp';
import React, { Fragment, useEffect, useState } from 'react';

import { MosaicPageTitle } from '~/components';
import { useFetchLicenseCount } from '~/pods/presentation/utils';
import { Id, OrganisationRole, User } from '~/types';
import { createTranslate, useIntercom } from '~/utils';

import {
  useAssignProject,
  useLoadAgencies,
  useLoadProjects,
  useUnassignProject,
} from '../../utils';
import { AgentsModal } from './components';
import { Agency } from './types';

const styles = {
  dropdownFix: css`
    text-align: left;

    // Force width & height bigger
    [data-element='panel'] {
      max-height: 350px;
      width: 225px;
    }
  `,
  disableFilter: css`
    opacity: 0.75;
    cursor: not-allowed;
    float: right;

    > div {
      pointer-events: none;
      width: fit-content;
    }
  `,
};

const tt = createTranslate('pods.settings.routes.agencies');

export const Agencies = () => {
  // todo: proper loading state
  const [agencyToDisplay, setAgencyToDisplay] = useState<Agency>();
  const { agencies, isLoading: isLoadingAgencies } = useLoadAgencies();
  const { licenses } = useFetchLicenseCount();
  const { projects } = useLoadProjects();
  const { showIntercom } = useIntercom();
  const { assignProject } = useAssignProject();
  const { unassignProject } = useUnassignProject();
  const { showAlert } = useAlert();

  const getProjectsByAgency = (agencies: Array<Agency>) =>
    agencies.reduce(
      (memo, agency) => ({
        ...memo,
        [agency.id]: agency.assignedProjects.map((project) => project.id),
      }),
      {},
    );

  // Store ids of projects being assigned while the request loads to avoid the checkbox feeling unresponsive
  const [optimisticIds, setOptimisticIds] = useState<Record<Id, Array<Id>>>(() =>
    getProjectsByAgency(agencies),
  );

  useEffect(() => {
    setOptimisticIds(getProjectsByAgency(agencies));
  }, [JSON.stringify(agencies)]);

  if (licenses?.availableLicenseCount == null) return null;

  const handleToggleProject = async (agencyId: Id, projectIds: Array<Id>) => {
    const assignedProjects = optimisticIds[agencyId];

    if (projectIds.length < assignedProjects.length) {
      const diff = difference(assignedProjects, projectIds);

      // eslint-disable-next-line no-console
      console.assert(
        diff.length === 1,
        `Expected to find 1 missing Id, instead found ${diff.length}`,
      );

      const removedProjectId = diff[0];

      setOptimisticIds(
        produce((draft: Record<Id, Array<Id>>) => {
          draft[agencyId] = draft[agencyId].filter((id) => id !== removedProjectId);
        }),
      );

      const result = await unassignProject({ agencyId, projectId: removedProjectId });

      if (result.error) {
        showAlert({
          text: 'Something went wrong while trying to assign this project',
          category: Category.DANGER,
        });
      }
    } else if (projectIds.length > assignedProjects.length) {
      const diff = difference(projectIds, assignedProjects);

      // eslint-disable-next-line no-console
      console.assert(diff.length === 1, `Expected to find 1 new Id, instead found ${diff.length}`);

      const newProjectId = diff[0];
      setOptimisticIds(
        produce((draft: Record<Id, Array<Id>>) => {
          draft[agencyId].push(newProjectId);
        }),
      );
      const result = await assignProject({ agencyId, projectId: newProjectId });

      if (result.error) {
        showAlert({
          text: tt('error_assigning_project'),
          category: Category.DANGER,
        });
      }
    }
  };

  const onClearAllProjectsInAgency = (agencyId: Id,) => {
    const projectsInAgency = optimisticIds[agencyId];


    setOptimisticIds(
      (oldState) => {
        return {
          ...oldState,
          [agencyId]: []
        }
      }
    );

    for (const projectId of projectsInAgency) {
      unassignProject({ agencyId, projectId })
    }
  }

  const tableData = agencies
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((agency) => {
      const sufficientLicenses = flow(
        filter((user: User) => user.role === OrganisationRole.AGENCY_SALES_MANAGER),
        (managers) => managers.length <= licenses?.availableLicenseCount,
      )(agency.users);

      return {
        name: (
          <Flex justify={FlexJustify.START}>
            <FlexItem>
              <Avatar image={agency.logo?.url} text={agency.name} />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Text>{agency.name}</Text>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <TextLink
                // @ts-ignore incorrectly typed
                onClick={() => setAgencyToDisplay(agency)}>
                {tt('agents_amount', { amount: agency.users.length })}
              </TextLink>
            </FlexItem>
          </Flex>
        ),
        access: (
          <div
            className={
              sufficientLicenses || optimisticIds[agency.id]?.length !== 0
                ? undefined
                : styles.disableFilter
            }>
            <div>
              <CheckboxFilter
                className={styles.dropdownFix}
                onChange={(ids) => handleToggleProject(agency.id, ids)}
                onClear={() => onClearAllProjectsInAgency(agency.id)}
                clearLabel={
                  tt('clear_all')
                }
                active={false}
                label={tt('projects')}
                enableSearch
                values={optimisticIds[agency.id]}
                options={projects.map((project) => ({ label: project.name, value: project.id }))}
              />
            </div>
          </div>
        ),
      };
    });

  const agenciesThatExceedRemainingLicenses = agencies.filter(
    (agency) =>
      agency.users.filter((u) => u.role === OrganisationRole.AGENCY_SALES_MANAGER).length >
      licenses.availableLicenseCount && agency.assignedProjects.length === 0,
  );

  const bannerText = (() => {
    if (agenciesThatExceedRemainingLicenses.length === 0) {
      return null;
    } else if (agenciesThatExceedRemainingLicenses.length === 1) {
      return `Sharing access with ${agenciesThatExceedRemainingLicenses[0].name} would exceed the number of seats in your subscription`;
    } else {
      return `Sharing access with certain agencies would exceed the number of seats in your subscription`;
    }
  })();

  return (
    <Fragment>
      <Content fullHeight>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <Flex align={FlexAlign.CENTER}>
            <FlexItem flex={1}>
              <MosaicPageTitle>{tt('agencies')}</MosaicPageTitle>
            </FlexItem>
            <FlexItem>
              <Button leading={<Icon name="mail" />} onClick={showIntercom} color={Color.BLUE}>
                {tt('new_agency')}
              </Button>
            </FlexItem>
          </Flex>
          {agenciesThatExceedRemainingLicenses.length > 0 && (
            <Margin size={{ bottom: Size.DEFAULT }}>
              <Banner
                category={Category.WARNING}
                trailing={
                  <Button tier={Tier.SECONDARY} inversed onClick={showIntercom}>
                    Contact Us
                  </Button>
                }>
                {bannerText}
              </Banner>
            </Margin>
          )}
          <Table
            header={[
              { label: tt('name'), value: 'name' },
              { label: tt('access_to_projects'), value: 'access' },
            ]}
            data={tableData}
            emptyContent={
              isLoadingAgencies ? (
                <Spinner fullSize />
              ) : tableData.length === 0 ? (
                <EmptyState title={tt('empty.title')} description={tt('empty.description')}>
                  <Button color={Color.BLUE} onClick={showIntercom}>
                    {tt('empty.cta')}
                  </Button>
                </EmptyState>
              ) : undefined
            }
          />
        </Padding>
      </Content>
      <AgentsModal agency={agencyToDisplay} onClickClose={() => setAgencyToDisplay(undefined)} />
    </Fragment>
  );
};
