import { useEffect, useRef } from 'react';

export function useDrawInCanvas(
  width: number | undefined,
  height: number | undefined,
  draw: (context: CanvasRenderingContext2D, width: number, height: number) => void,
) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current == null || width == null || height == null) {
      return;
    }

    const { current: canvas } = canvasRef;
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');

    if (context == null) {
      return;
    }

    draw(context, width, height);
  }, [canvasRef.current]);

  return canvasRef;
}
