import {
  Collapsible,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  LoadingPlaceholder,
  Size,
} from '@drawbotics/react-drylus';
import { Filter } from '@drawbotics/use-filters';
import React, { Fragment, ReactNode, useState } from 'react';

export const FiltersPlaceHolder = () => {
  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
      <FlexItem>
        <LoadingPlaceholder width={129} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={173} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={197} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={131} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={178} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={144} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={135} />
      </FlexItem>
    </Flex>
  );
};

interface FilterSectionProps {
  title: string;
  children?: ReactNode;
  isLoading?: boolean;
  filter: Filter;
}

export const FilterSection = ({ title, children, isLoading, filter }: FilterSectionProps) => {
  const [isOpen, setIsOpen] = useState(filter.value != null);
  return (
    <Fragment>
      <Collapsible title={title} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isLoading ? (
          <FiltersPlaceHolder />
        ) : (
          <div style={{ position: 'relative', zIndex: 1 }}>{children}</div>
        )}
      </Collapsible>
    </Fragment>
  );
};
