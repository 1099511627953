import { Currency } from '~/types';
import { displaySurface } from '~/utils';
import { translatePrice } from '~/utils/translation';

export function formatBudget({
  max,
  min,
  currency = 'EUR',
}: {
  max?: number;
  min?: number;
  currency?: Currency;
}): string | undefined {
  if (max == null || min == null) {
    return undefined;
  }
  return `${translatePrice(min, currency)} - ${translatePrice(max, currency)}`;
}

export function formatSurface({
  max,
  min,
  unit = 'sqm',
}: {
  max?: number;
  min?: number;
  unit?: string;
}): string | undefined {
  if (max == null || min == null) {
    return undefined;
  }
  return `${displaySurface(min, unit)} - ${displaySurface(max, unit)}`;
}
