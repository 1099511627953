import { Tier } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import logo from '~/images/mosaic-dark.svg';

import { Text } from './Text';

const styles = {
  footer: css`
    width: 100%;
    text-align: center;
  `,
  logo: css`
    margin-bottom: 0.3cm;

    > img {
      height: 0.4cm;
    }
  `,
  text: css`
    width: 100%;
    padding: 0 1.5cm;
  `,
};

interface FooterProps {
  text: ReactNode;
}

export const Footer = ({ text }: FooterProps) => {
  return (
    <div className={styles.footer}>
      <div className={styles.logo}>
        <img src={logo} />
      </div>
      <div className={styles.text}>
        <Text tier={Tier.TERTIARY} style={{ width: '100%' }}>
          {text}
        </Text>
      </div>
    </div>
  );
};
