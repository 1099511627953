import sv from '@drawbotics/drylus-style-vars';
import { Panel, PanelBody, PanelSection } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  legend: css`
    position: absolute;
    top: ${sv.marginSmall};
    right: ${sv.defaultMargin};
  `,
};

interface ChartPanelProps {
  title: string;
  legend?: ReactNode;
  chart: ReactNode;
}

export const ChartPanel = ({ title, legend, chart }: ChartPanelProps) => {
  return (
    <Panel
      style={{ height: '100%' }}
      body={
        <PanelBody style={{ height: '100%', position: 'relative' }}>
          {legend != null ? <div className={styles.legend}>{legend}</div> : null}
          <PanelSection style={{ height: '100%' }} title={title}>
            {chart}
          </PanelSection>
        </PanelBody>
      }
    />
  );
};
