import { useFilters } from '@drawbotics/use-filters';
import { HookService } from '@tokamakjs/react';
import { useLocation, useNavigate } from 'react-router';

@HookService()
export class FiltersService {
  private readonly _navigate = useNavigate();
  private readonly _location = useLocation();
  private readonly _filters = useFilters(this._location, this._navigate, [
    'unitId',
    'availability',
    'bedrooms',
    'rooms',
    'price',
    'surface',
    'page',
    'search',
  ]);

  get filters() {
    return this._filters;
  }

  get activeUnitId(): string {
    return this._filters.unitId.value as string;
  }

  public setActiveUnitId(id?: string): void {
    this._filters.unitId.set(id ?? null);
  }
}
