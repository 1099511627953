import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Modal,
  SearchInput,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import React, { useState } from 'react';

import { createTranslate } from '~/utils';

import { useLoadPortals } from '../hooks';
import { PortalsList } from './PortalsList';

const tt = createTranslate('pods.multidiffusion.components.portals_modal');

interface PortalsModalProps {
  isVisible: boolean;
  onClickClose: VoidFunction;
}

export const PortalsModal = ({ isVisible, onClickClose }: PortalsModalProps) => {
  const { isLoading, portals } = useLoadPortals();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredPortals = portals.filter((p) => {
    return (
      p.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      p.slug.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <Modal visible={isVisible} title={tt('title')} onClickClose={onClickClose}>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
        <FlexItem>
          <SearchInput
            placeholder={tt('search')}
            value={searchQuery}
            onChange={(v) => setSearchQuery(v as string)}
          />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          {isLoading ? <Spinner fullSize /> : <PortalsList portals={filteredPortals} />}
        </FlexItem>
      </Flex>
    </Modal>
  );
};
