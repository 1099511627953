import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Dropdown,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Label,
  Padding,
  Position,
  RadioGroup,
  Size,
  Spinner,
  Text,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import gql from 'fraql';
import { uniqBy } from 'lodash';
import React, { Fragment, useState } from 'react';

import { Lead, User } from '~/pods/crm/types';
import { Project } from '~/pods/settings/types';
import { createTranslate } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

import { ArchiveLeadsModal } from './ArchiveLeadsModal';

const styles = {
  leadsAction: css`
    display: none;
  `,
  label: css`
    display: block;
    padding-bottom: ${sv.paddingSmall};
  `,
  optionsSection: css`
    padding-bottom: ${sv.paddingSmall};
  `,
  scroll: css`
    overflow: auto;
    height: 400px;
  `,
};

const tt = createTranslate('pods.crm.routes.leads.components.leads_table_actions');
const ttDetails = createTranslate('pods.crm.routes.lead.components.lead_details.profile');

const archiveLeadsMutation = gql`
  mutation ArchiveLeadsMutation($ids: [ID!]!) {
    archiveLeads(input: { ids: $ids }) {
      leads {
        id
      }
    }
  }
`;

const assignLeadsMutation = gql`
  mutation AssignLeadsMutation($leadIds: [ID!]!, $userId: ID, $organisationId: ID) {
    assignLeads(input: { leadIds: $leadIds, userId: $userId, organisationId: $organisationId }) {
      leads {
        id
        user {
          id
          fullName @computed(type: User)
        }
      }
    }
  }
`;

interface LeadsTableActionProps {
  selectedLeads: Array<string>;
  allLeads: boolean;
  resetLeads: VoidFunction;
  users?: Array<User>;
  isLoading: boolean;
  projects: Array<Project>;
}

interface AssignLeadsVariables {
  userId?: string;
  organisationId?: string;
  leadIds: Array<string>;
}

interface AssignLeadsResult {
  assignLeads: {
    leads: Array<Lead>;
  };
}

interface ArchiveLeadsVariables {
  ids: Array<string>;
}

interface ArchiveLeadsResult {
  archiveLeads: {
    leads: Array<Lead>;
  };
}

export const LeadsTableActions = ({
  selectedLeads,
  resetLeads,
  users,
  isLoading,
  allLeads,
  projects,
}: LeadsTableActionProps) => {
  const [selected, setSelected] = useState<string>();
  const [isArchiveLeadsModalOpen, setIsArchiveLeadsModalOpen] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const {
    res: { fetching: isArchivingLeads },
    executeMutation: archiveLeads,
  } = useMosaicMutation<ArchiveLeadsResult, ArchiveLeadsVariables>(archiveLeadsMutation);
  const {
    res: { fetching: isAssigningLeads },
    executeMutation: assignLeads,
  } = useMosaicMutation<AssignLeadsResult, AssignLeadsVariables>(assignLeadsMutation);

  let agencyOptions: Array<{ label: string; value: string }> = [];
  for (const project of projects) {
    agencyOptions = agencyOptions.concat(
      project.assignedAgencies.map((a) => ({ label: a.name, value: `agency-${a.id}` })),
    );
  }
  agencyOptions = uniqBy(agencyOptions, (a) => a.value);

  const userOptions = users
    ? users.map((user) => ({ label: user.fullName, value: `member-${user.id}` }))
    : [];

  const handleArchiveLead = async () => {
    const { error } = await archiveLeads({ ids: selectedLeads });
    if (error != null) {
      showAlert({
        text: tt('archive.error'),
        category: Category.DANGER,
      });
    } else {
      showAlert({
        text: tt('archive.success'),
        category: Category.SUCCESS,
      });
    }
    resetLeads();
    setIsArchiveLeadsModalOpen(false);
  };

  const handleAssignLead = async () => {
    if (selected != null) {
      const { error, data } = await assignLeads({
        userId: selected.startsWith('member-') ? selected.replace('member-', '') : undefined,
        organisationId: selected.startsWith('agency-')
          ? selected.replace('agency-', '')
          : undefined,
        leadIds: selectedLeads,
      });

      if (error != null) {
        showAlert({
          text: tt('assign.error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('assign.success', { user: data?.assignLeads.leads[0].user?.fullName ?? '' }),
          category: Category.SUCCESS,
        });
      }
      resetLeads();
      setSelected(undefined);
    }
  };

  return (
    <Fragment>
      <Flex
        className={selectedLeads.length === 0 ? styles.leadsAction : undefined}
        justify={FlexJustify.START}>
        <FlexItem>
          <Tooltip content={tt('archive.title')} side={Position.BOTTOM}>
            <Button
              size={Size.SMALL}
              onClick={() => setIsArchiveLeadsModalOpen(true)}
              leading={<Icon name="archive" />}
            />
          </Tooltip>
        </FlexItem>
        <FlexItem>
          <Padding size={{ horizontal: Size.EXTRA_SMALL }}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Dropdown
                trigger={
                  <Tooltip
                    content={
                      users == null || users.length === 0 ? tt('assign.empty') : tt('assign.title')
                    }
                    side={Position.BOTTOM}>
                    <Button
                      size={Size.SMALL}
                      disabled={users == null || users.length === 0}
                      leading={<Icon name="user-plus" />}
                    />
                  </Tooltip>
                }
                side={Position.RIGHT}>
                <div style={{ minWidth: 200, whiteSpace: 'nowrap' }}>
                  <Padding size={Size.SMALL}>
                    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
                      <FlexItem className={cx(styles.scroll)}>
                        {agencyOptions.length === 0 ? null : (
                          <div className={styles.optionsSection}>
                            <Label className={styles.label}>{ttDetails('agencies')}</Label>
                            <RadioGroup
                              options={agencyOptions}
                              onChange={setSelected}
                              value={selected}
                            />
                          </div>
                        )}
                        <div className={styles.optionsSection}>
                          <Label className={styles.label}>{ttDetails('company_members')}</Label>
                          <RadioGroup
                            options={userOptions}
                            onChange={setSelected}
                            value={selected}
                          />
                        </div>
                      </FlexItem>
                      <FlexSpacer size={Size.EXTRA_SMALL} />
                      <FlexItem>
                        <Button
                          fullWidth
                          color={Color.BLUE}
                          size={Size.SMALL}
                          onClick={handleAssignLead}
                          disabled={selected == null}
                          trailing={
                            isAssigningLeads ? <Spinner size={Size.SMALL} inversed /> : null
                          }>
                          {tt('save_button')}
                        </Button>
                      </FlexItem>
                      <FlexSpacer size={Size.EXTRA_SMALL} />
                      <FlexItem>
                        <Button
                          fullWidth
                          size={Size.SMALL}
                          onClick={() => {
                            setSelected(undefined);
                            resetLeads();
                          }}>
                          {tt('cancel_button')}
                        </Button>
                      </FlexItem>
                    </Flex>
                  </Padding>
                </div>
              </Dropdown>
            )}
          </Padding>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        {allLeads ? (
          <FlexItem>
            <Text>
              All <Text bold>{selectedLeads.length}</Text> leads on this page are selected
            </Text>
          </FlexItem>
        ) : null}
      </Flex>
      <ArchiveLeadsModal
        loading={isArchivingLeads}
        onClickConfirm={handleArchiveLead}
        onClickClose={() => setIsArchiveLeadsModalOpen(false)}
        visible={isArchiveLeadsModalOpen}
      />
    </Fragment>
  );
};
