import { Color, Flex, FlexItem, Icon, IconType, Margin, Size } from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface InfoRowProps {
  icon: IconType;
  children: ReactNode;
}

export const InfoRow = ({ icon, children }: InfoRowProps) => {
  return (
    <Flex>
      <FlexItem>
        <Margin size={{ right: Size.SMALL }}>
          <Icon color={Color.PRIMARY} name={icon} />
        </Margin>
      </FlexItem>
      <FlexItem flex>{children}</FlexItem>
    </Flex>
  );
};
