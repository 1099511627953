import React from 'react';

export const CampaignsChartPlaceholder = () => {
  return (
    <svg
      width="172"
      height="105"
      viewBox="0 0 172 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4089 61.4295C5.9654 50.8305 10.8388 30.7113 20.6529 21.7089C33.2203 10.1809 42.2092 -1.93761e-05 84.0538 0C125.898 1.93762e-05 134.775 26.162 153.161 41.1913C171.547 56.2205 176.619 77.9294 167.743 99.0817C157.003 115.893 131.794 120.354 105.54 124.44C80.3149 128.335 53.6214 128.027 35.9545 121.842C18.2875 115.657 -8.13274 94.0759 2.4089 61.4295Z"
        fill="#F9FAFE"
      />
      <rect
        x="46.0392"
        y="36.9053"
        width="83"
        height="58"
        rx="5"
        transform="rotate(-0.248932 46.0392 36.9053)"
        fill="#7091D5"
      />
      <rect
        x="35.7573"
        y="33.6799"
        width="83"
        height="58"
        rx="5"
        transform="rotate(-5.65065 35.7573 33.6799)"
        fill="#CED9F0"
      />
      <path
        d="M49.7562 63.4462L57.4272 54.6481L76.1169 70.8868L104.209 39.9705"
        stroke="#9AAFDA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9179 80.1175L77.5126 53.2427L95.073 67.8124L112.559 48.1883"
        stroke="#A1BAED"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
