import { Range } from '../types';

export function getRangeSteps(max: number, min: number, amount: number): number {
  const rawStep = Math.round((max - min) / amount);

  if (rawStep === 0) {
    return 1;
  }

  const magnitude = String(rawStep).length - 1;
  const number = Math.pow(10, magnitude);

  // https://stackoverflow.com/a/23917134/2137368
  return Math.round(rawStep / number) * number;
}

export function getClosestRange(max: number, min: number, step: number): Range {
  const top = Math.ceil(max / step) * step;
  const bottom = Math.floor(min / step) * step;
  return [bottom, top === 0 ? 1 : top];
}
