import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import { useEffect } from 'react';

import { createTranslate, extractGQLErrorMessages } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

import { Presentation, PresentationStatus } from '../../types';

const tt = createTranslate('pods.marketing_suite.calendar_event');

interface UpdatePresentationVariables {
  presentationUpdate: {
    id: string;
    status?: PresentationStatus;
    name?: string;
    tagline?: string;
    description?: string;
    coverPicture?: string;
  };
}

interface UpdatePresentationPayload {
  updatePresentation: {
    presentation: Presentation;
  };
}

const updatePresentationMutation = gql`
  mutation updatePresentation($presentationUpdate: UpdatePresentationInput!) {
    updatePresentation(input: $presentationUpdate) {
      presentation {
        id
        name
        tagline
        description
        status
        coverPicture {
          id
        }
      }
    }
  }
`;

export function useUpdatePresentation() {
  const { res, executeMutation } = useMosaicMutation<
    UpdatePresentationPayload,
    UpdatePresentationVariables
  >(updatePresentationMutation);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!res.fetching && res.error == null && res.data != null) {
      showAlert({ text: tt('success'), category: Category.SUCCESS });
    } else if (!res.fetching && res.error != null) {
      const { message } = extractGQLErrorMessages(res.error);
      const text = message ?? tt('error');
      showAlert({ text, category: Category.DANGER });
    }
  }, [res.fetching]);

  return {
    isLoading: res.fetching,
    updatePresentation: (presentation: UpdatePresentationVariables) =>
      executeMutation(presentation),
  };
}
