import { weightedPick } from './weighted-pick';

export function pickCity(): string {
  const demoCities: Array<string> = [
    'Lyon',
    'Villeurbanne',
    'Ecully',
    'Bron',
    'Villefranche s/ Saone',
  ];

  const demoCitiesWeights: Record<string, number> = {
    Lyon: 0.35,
    Villeurbanne: 0.25,
    Ecully: 0.15,
    Bron: 0.15,
    'Villefranche s/ Saone': 0.1,
  };

  return weightedPick(demoCities, demoCitiesWeights);
}
