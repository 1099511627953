import faker from 'faker';

import { DateRange, ShowcaseUnit, UnitsDescription } from './types';
import { pickDateInRange } from './utils';

const ORIENTATIONS = ['North', 'East', 'South', 'West'];

export class Units {
  public readonly units: Array<ShowcaseUnit>;
  public readonly typologies: Array<string>;

  constructor(description: UnitsDescription) {
    this.units = this.createUnits(description);
    this.typologies = Object.keys(description.typologies);
  }

  option(typology: string, amount?: number): void {
    const units = this.units.filter((u) => u.typology === typology);

    if (amount == null) {
      units.forEach((u) => (u.availability = 'option'));
      return;
    }

    let numberOfOptionedUnits = 0;
    let iterations = 0;
    const maxNumberOfIterations = units.length;

    while (numberOfOptionedUnits < amount && iterations < maxNumberOfIterations) {
      iterations++;
      const unitToOption = faker.random.arrayElement(units);
      if (unitToOption.availability !== 'available') {
        continue;
      }
      unitToOption.availability = 'option';
      numberOfOptionedUnits++;
    }
  }

  // Sets the status to unavailable on the amount of available units specified.
  // Used for testing purposes.
  unavailable(amount: number): void {
    let numberOfUnavailableUnits = 0;
    let iterations = 0;
    const maxNumberOfIterations = this.units.length;

    while (numberOfUnavailableUnits < amount && iterations < maxNumberOfIterations) {
      iterations++;
      const unitToMakeUnavailable = faker.random.arrayElement(this.units);
      if (unitToMakeUnavailable.availability !== 'available') {
        continue;
      }
      unitToMakeUnavailable.availability = 'unavailable';
      numberOfUnavailableUnits++;
    }
  }

  // Sets the price to 0 on the amount of units specified. Used for testing purposes.
  removePrice(amount?: number) {
    if (amount == null) {
      this.units.forEach((u) => (u.price.value = 0));
      return;
    }

    let numberOfPricelessUnits = 0;
    let iterations = 0;
    const maxNumberOfIterations = this.units.length;

    while (numberOfPricelessUnits < amount && iterations < maxNumberOfIterations) {
      iterations++;
      const unitToMakeUnavailable = faker.random.arrayElement(this.units);
      unitToMakeUnavailable.price.value = 0;
      numberOfPricelessUnits++;
    }
  }

  sell(typology: string, dateRange: DateRange, amount?: number): void {
    const units = this.units.filter((u) => u.typology === typology);

    if (amount == null) {
      units.forEach((u) => {
        u.availability = 'booked';
        u.soldAt = pickDateInRange(dateRange);
      });
      return;
    }

    if (amount < 0) {
      units.slice(0, -1).forEach((u) => {
        u.availability = 'booked';
        u.soldAt = pickDateInRange(dateRange);
      });
      return;
    }

    let numberOfSoldUnits = 0;
    let iterations = 0;
    const maxNumberOfIterations = units.length;

    while (numberOfSoldUnits < amount && iterations < maxNumberOfIterations) {
      iterations++;
      const unitToSell = faker.random.arrayElement(units);
      if (unitToSell.availability !== 'available') {
        continue;
      }
      unitToSell.availability = 'booked';
      unitToSell.soldAt = pickDateInRange(dateRange);
      numberOfSoldUnits++;
    }
  }

  private createUnits(description: UnitsDescription): Array<ShowcaseUnit> {
    const { number, typologies } = description;

    const units: Array<ShowcaseUnit> = [];

    const fixedNumbers = Object.entries(typologies).filter((entry) => {
      const [, description] = entry;
      return description.number != null;
    });

    const weights = Object.entries(typologies).filter((entry) => {
      const [, description] = entry;
      return description.weight != null;
    });

    const totalFixedNumber = fixedNumbers.reduce((m, e) => e[1].number! + m, 0);

    if (totalFixedNumber > number) {
      throw new Error(
        `The sum of the amount of units for each typology exceeds the overall specified number of units: ${number} units expected, but the amounts specified per typology add up to ${totalFixedNumber}.`,
      );
    }

    fixedNumbers.forEach((entry) => {
      const [typology, description] = entry;
      for (let i = 0; i < description.number!; i++) {
        units.push(this.createUnit(i, typology, description.price));
      }
    });

    const totalWeights = weights.reduce((m, e) => e[1].weight! * 100 + m, 0);

    if (weights.length < 0 && totalWeights !== 100) {
      throw new Error('Weights have to add up to 1.');
    }

    const numberOfUnitsLeft = number - units.length;

    weights.forEach((entry) => {
      const [typology, description] = entry;
      const numberOfUnits = Math.round(numberOfUnitsLeft * description.weight!);

      for (let i = 0; i < numberOfUnits; i++) {
        units.push(this.createUnit(i, typology, description.price));
      }
    });

    return units.slice(0, number); // make sure we stay in range
  }

  private createUnit(index: number, typology: string, price: number | Array<number>): ShowcaseUnit {
    //the aim of this exercise is to make sure traction is correct with fake visits
    //121 is added to match backend unitIds for drawbotics_project_fr
    //241 is added to match the IDs from showcase project drawbotics_project_en
    const isFRProject = window.location.pathname.includes('drawbotics_project_fr');

    return {
      id: String(index + (isFRProject ? 121 : 241)),
      name: `Unit ${faker.random.alphaNumeric(3).toUpperCase()}`,
      availability: 'available',
      soldAt: null,
      orientation: faker.random.arrayElement(ORIENTATIONS),
      price: {
        value: Array.isArray(price) ? faker.random.number({ min: price[0], max: price[1] }) : price,
        currency: 'EUR',
      },
      typology,
    };
  }
}
