import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';
import arrayMove from 'array-move';
import immer from 'immer';

import { ID } from '~/types';

import {
  AnyWebsiteSectionData,
  AssetData,
  HeroSectionData,
  LinkData,
  Website,
  WebsiteData,
} from '../api/domain';

export interface WebsiteState {
  website?: WebsiteData;
}

@Injectable()
@StoreConfig({ name: 'website', resettable: true, producerFn: immer })
export class WebsiteStore extends Store<WebsiteState> {
  constructor() {
    super({});
  }

  public setWebsite(website: Website): void {
    this.update({ website: website.toData() });
  }

  public getWebsite(): Website | undefined {
    if (this.getValue().website == null) return;
    return Website.fromData(this.getValue().website!);
  }

  public updateWebsite(data: Partial<Website>): void {
    this.update((state) => {
      state.website = Object.assign({}, state.website, data);
    });
  }

  public updateSection(
    id: AnyWebsiteSectionData['id'],
    data: Partial<AnyWebsiteSectionData>,
  ): void {
    this.update((state) => {
      if (state.website == null) return;

      const sectionIndex = state.website._sections.findIndex((s) => s.id === id);
      Object.assign(state.website._sections[sectionIndex], data);
    });
  }

  public updateLink(id: AnyWebsiteSectionData['id'], data: Partial<LinkData>): void {
    this.update((state) => {
      if (state.website == null) return;

      const sectionIndex = state.website._sections.findIndex((s) => s.id === id);
      Object.assign((state.website._sections[sectionIndex] as HeroSectionData).link, data);
    });
  }

  public updateSectionAssets(id: AnyWebsiteSectionData['id'], assets: Array<AssetData>): void {
    this.update((state) => {
      if (state.website == null) return;

      const sectionIndex = state.website._sections.findIndex((s) => s.id === id);

      // TODO: check if has assets with typeguard
      if ((state.website._sections[sectionIndex] as any).assets == null) {
        return;
      }

      (state.website._sections[sectionIndex] as any).assets = assets
        .slice()
        .sort((a, b) => a.position! - b.position!);
    });
  }

  public incrementSection(id: ID): void {
    this.update((state) => {
      if (state.website == null) return;

      const sorted = state.website._sections
        .slice()
        .sort((a, b) => a.position - b.position)
        .map((section, index) => ({ ...section, position: index }));

      const currentIndex = sorted.findIndex((section) => section.id === id);

      if (currentIndex == null) return;

      const updatedOrder = arrayMove(sorted, currentIndex, currentIndex + 1);

      state.website._sections = updatedOrder.map((section, index) => ({
        ...section,
        position: index,
      }));
    });
  }

  public decrementSection(id: ID): void {
    this.update((state) => {
      if (state.website == null) return;

      const sorted = state.website._sections
        .slice()
        .sort((a, b) => a.position - b.position)
        .map((section, index) => ({ ...section, position: index }));

      const currentIndex = sorted.findIndex((section) => section.id === id);

      if (currentIndex == null) return;

      const updatedOrder = arrayMove(sorted, currentIndex, currentIndex - 1);

      state.website._sections = updatedOrder.map((section, index) => ({
        ...section,
        position: index,
      }));
    });
  }
}
