import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { Dashboard } from './Dashboard';

export const Routes = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<Dashboard />} />
    </RoutesWithNotFound>
  );
};
