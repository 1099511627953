import {
  Content,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Paragraph,
  Separator,
  Shade,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.website_builder.routes.website_builder');

export const BuilderLoadingPlaceholder = () => {
  return (
    <Content fullHeight>
      <Padding size={Size.DEFAULT}>
        <Margin size={{ bottom: Size.SMALL }}>
          <Flex>
            <FlexItem flex={1}>
              <MosaicPageTitle>{tt('project_website')}</MosaicPageTitle>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
          </Flex>
        </Margin>
        <Panel
          header={
            <PanelHeader>
              <Title size={4} noMargin>
                {tt('settings')}
              </Title>
            </PanelHeader>
          }
          body={
            <PanelBody>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>1. {tt('domain')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  <LoadingPlaceholder />
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.DEFAULT }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
                <FlexItem>
                  <Paragraph style={{ margin: 0 }}>
                    <Text shade={Shade.LIGHT}>{tt('domain_info')}</Text>
                  </Paragraph>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>2. {tt('choose_language')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  <LoadingPlaceholder />
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.DEFAULT }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
                <FlexItem>
                  <Paragraph style={{ margin: 0 }}>
                    <Text shade={Shade.LIGHT}>{tt('language_info')}</Text>
                  </Paragraph>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>3. {tt('upload_favicon')}</Text>
              </Margin>
              <LoadingPlaceholder />
              <Margin size={{ top: Size.EXTRA_SMALL }}>
                <LoadingPlaceholder />
              </Margin>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>4. {tt('og_fill')}</Text>
              </Margin>
              <LoadingPlaceholder />
              <Margin size={{ top: Size.EXTRA_SMALL }}>
                <LoadingPlaceholder />
              </Margin>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>5. {tt('contact_form_customize')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  <LoadingPlaceholder />
                  <Margin size={{ top: Size.EXTRA_SMALL }}>
                    <LoadingPlaceholder />
                  </Margin>
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.SMALL }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
              </Flex>
            </PanelBody>
          }
        />
      </Padding>
    </Content>
  );
};
