import { ID } from '~/types';

export enum UnitAvailability {
  AVAILABLE = 'available',
  BOOKED = 'booked',
  OPTION = 'option',
  UNAVAILABLE = 'unavailable',
}

export interface Entity {
  id: ID;
  name: string;
  reference: string;
}

export interface Unit {
  id: ID;
  reference: string;
}
