import sv from '@drawbotics/drylus-style-vars';
import {
  Content,
  Flex,
  FlexItem,
  FlexSpacer,
  Layout,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  Position,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { Dimensions } from './EditorNavbar';

const styles = {
  navbar: css`
    width: 100%;
    height: ${Dimensions.height}px;
    background: ${sv.white};
    padding: ${sv.defaultPadding};
  `,
  toolbar: css`
    background: #fff;
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
};

export const WebsiteEditorLoader = () => {
  return (
    <Layout
      bar={
        <div className={styles.navbar}>
          <Flex style={{ height: '100%' }}>
            <FlexItem>
              <LoadingPlaceholder width={40} />
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
            <FlexSpacer flex />
            <FlexItem>
              <LoadingPlaceholder />
            </FlexItem>
          </Flex>
        </div>
      }
      position={Position.TOP}
      barScrollable={false}
      fixed>
      <Layout
        bar={
          <div className={styles.toolbar}>
            <Padding>
              <LoadingPlaceholder width="80%" />
              <Margin size={{ top: Size.SMALL }} />
              <LoadingPlaceholder height={200} width="100%" />
              <Margin size={{ top: Size.LARGE }} />
              <LoadingPlaceholder height={200} width="100%" />
              <Margin size={{ top: Size.LARGE }} />
              <LoadingPlaceholder height={200} width="100%" />
            </Padding>
          </div>
        }
        position={Position.LEFT}
        style={{ height: '100%' }}
        fixed>
        <Content fullWidth style={{ background: sv.neutral, height: '100%' }}>
          <Padding style={{ height: '100%' }} size={Size.LARGE}>
            <Panel
              style={{ height: '100%', overflow: 'scroll', borderRadius: sv.borderRadiusLarge }}
              body={
                <PanelBody>
                  <div />
                </PanelBody>
              }
            />
          </Padding>
        </Content>
      </Layout>
    </Layout>
  );
};
