import sv from '@drawbotics/drylus-style-vars';
import {
  Avatar,
  Button,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  Modal,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils';

import { Agency } from '../types';

const tt = createTranslate('pods.settings.routes.agencies.components.agents_modal');

const styles = {
  modalContentFix: css`
    div[class*='Modal__title'] {
      margin-bottom: 0;
    }

    div[class*='Modal__content'] {
      margin-left: -24px;
      margin-right: -24px;
    }

    div[class*='Modal__footer'] {
      margin-top: 0px;
    }
  `,
  agentsList: css`
    > :nth-child(odd) {
      background-color: ${sv.grey100};
    }

    > div {
      display: flex;
      padding: ${sv.paddingExtraSmall} ${sv.paddingSmall};
      gap: ${sv.marginSmall};
      align-items: center;

      > :nth-child(2) {
        flex-grow: 1;
      }
    }
  `,
};

interface AgentsModalProps {
  agency?: Agency;
  onClickClose: VoidFunction;
}

export const AgentsModal = ({ agency, onClickClose }: AgentsModalProps) => {
  return (
    <Modal
      onClickClose={onClickClose}
      className={styles.modalContentFix}
      title={tt('title', { agency_name: agency?.name ?? '' })}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button color={Color.BLUE} onClick={onClickClose}>
              {tt('close')}
            </Button>
          </FlexItem>
        </Flex>
      }
      visible={agency != null}>
      <div className={styles.agentsList}>
        {agency?.users.map((agent) => (
          <div key={agent.id}>
            <Avatar image={agency.logo?.url} text={agent.fullName} />
            <Text>{agent.fullName}</Text>
            <Text tier={Tier.TERTIARY}>{agent.email}</Text>
          </div>
        ))}
      </div>
    </Modal>
  );
};
