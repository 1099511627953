import dayjs, { Dayjs } from 'dayjs';
import gql from 'fraql';

import { SHOWCASE_IDENTIFIER } from '~/constants';
import { useBackofficeQuery } from '~/utils';

import { Report, ReportType } from '../../types';

const ReportsQuery = gql`
  query ReportsQuery($projectSlug: String!) {
    reports(estateSlug: $projectSlug) {
      downloadUrl: url
      type: reportType
      dateRange @computed(type: Report)
      name
    }
  }
`;

function _showcaseData(): { isLoading: boolean; reports: Array<Report> } {
  const domain = window.location.origin;
  return {
    isLoading: false,
    reports: [
      {
        downloadUrl: `${domain}/insights-reports/monthly?token=showcase`,
        name: 'Monthly Report Showcase',
        dateRange: {
          start: dayjs().subtract(1, 'month').startOf('month'),
          end: dayjs().startOf('month'),
        },
        type: ReportType.MONTHLY,
      },
      {
        downloadUrl: `${domain}/insights-reports/weekly?token=showcase`,
        name: 'Weekly Report Showcase',
        dateRange: {
          start: dayjs().subtract(1, 'week').startOf('week'),
          end: dayjs().startOf('week'),
        },
        type: ReportType.WEEKLY,
      },
    ],
  };
}

interface ReportsQueryVariables {
  projectSlug: string;
}

interface ReportsQueryData {
  reports: Array<{
    downloadUrl: string;
    type: ReportType;
    dateRange: {
      start: Dayjs;
      end: Dayjs;
    };
    name: string;
  }>;
}

export function useLoadReports(projectSlug: string): {
  isLoading: boolean;
  reports: Array<Report>;
} {
  if (projectSlug === SHOWCASE_IDENTIFIER || process.env.APP_ENV === 'demo') {
    return _showcaseData();
  }

  const result = useBackofficeQuery<ReportsQueryData, ReportsQueryVariables>({
    query: ReportsQuery,
    variables: { projectSlug },
  });

  return { isLoading: result.isLoading, reports: result.data?.reports ?? [] };
}
