import { LineData } from '../types';

function _isLineData(data: any): data is LineData {
  return data != null && data.length > 0 && data[0].x != null && data[0].y != null;
}

export function getYGridLines(data: LineData | Array<number>, steps = 4): Array<number> {
  const values = _isLineData(data) ? data.map(({ y }) => y) : data;
  const maxValue = Math.max(...values) ?? 0;

  // todo: Display a placeholder chart if this is the case (i.e. no sales yet on this period)
  if (maxValue === 0) return [0];

  const minValue = 0;
  const delta = maxValue - minValue;
  const stepValue = Math.ceil(delta / steps);

  return new Array(steps + 1).fill(0).map((_, i) => minValue + i * stepValue);
}
