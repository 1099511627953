import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Content,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  Padding,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ErrorPlaceholder } from '~/components';
import { Log, LogType } from '~/pods/crm/types';
import {
  isManualLogType,
  useFetchLead,
  useFetchLogs,
  useFetchLogsCount,
  useUpdateLog,
} from '~/pods/crm/utils';
import { CreateAndEditMeetingModal } from '~/pods/meetings/components';
import { createTranslate, run, useAuth } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

import { DeleteLogModal } from './DeleteLogModal';
import { LogCard, LogCardPlaceholder } from './LogCard';
import { LogModal } from './LogModal';
import { LogsFilter } from './LogsFilter';

const styles = {
  logs: css`
    background: ${sv.neutralLight};
    min-height: 100%;
  `,
};

const tt = createTranslate('pods.crm.routes.lead.components.log_list');

const LOGS_PER_PAGE = 25;

interface DeleteLogVariables {
  logId: string;
}

const deleteLogMutation = gql`
  mutation deleteLog($logId: ID!) {
    destroyLog(input: { id: $logId }) {
      log {
        id
      }
    }
  }
`;

interface LogListProps {
  leadId: string;
  refreshKey: number;
}

export const LogList = ({ leadId, refreshKey }: LogListProps) => {
  const [isCreateMeetingModalVisible, setIsCreateMeetingModalVisible] = useState(false);
  const [isCreateLogModalVisible, setIsCreateLogModalVisible] = useState(false);
  const [hasClickedMore, setHasClickedMore] = useState(false);
  const [activeLog, setActiveLog] = useState<Log>();
  const [logToDelete, setLogToDelete] = useState<Log>();
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const { types, page } = useFilters(location, navigate, ['types', 'page'], {
    persistenceKey: `${user?.id}:crm:lead`,
  });

  const currentPage = page.value != null ? Number(page.value) : 1;

  const typesValuesFromUrl = types.values as Array<LogType> | undefined;
  const typesFilterValues = typesValuesFromUrl
    ? [
        ...typesValuesFromUrl,
        ...(typesValuesFromUrl.includes(LogType.MEETING) ? [LogType.REACH_MEETING] : []),
      ]
    : null;

  const {
    logs,
    isLoading: isLogsLoading,
    refetch,
    error: logsError,
  } = useFetchLogs(leadId, {
    types: typesFilterValues,
    limit: LOGS_PER_PAGE * currentPage,
  });
  const {
    logsCount,
    isLoading: isLogsCountLoading,
    refetch: refetchCount,
  } = useFetchLogsCount(leadId, {
    types: typesFilterValues,
    limit: LOGS_PER_PAGE * currentPage,
  });
  const { isLoading: isLeadLoading, lead, error: leadError } = useFetchLead(leadId);

  const { updateLog } = useUpdateLog();

  const {
    res: { fetching: isDeleting },
    executeMutation: deleteLog,
  } = useMosaicMutation<null, DeleteLogVariables>(deleteLogMutation);

  const handleDeleteLog = async () => {
    const res = await deleteLog({ logId: logToDelete!.id });
    if (res.error != null) {
      showAlert({ text: tt('delete_log.success'), category: Category.DANGER });
    } else {
      showAlert({ text: tt('delete_log.error'), category: Category.SUCCESS });
      setLogToDelete(undefined);
      refetch();
      refetchCount();
    }
  };

  const handlePinLog = async (log: Log) => {
    await updateLog({ id: log.id, pinned: !log.pinned });
  };

  useEffect(() => {
    if (activeLog != null) {
      setIsCreateLogModalVisible(true);
    }
  }, [activeLog]);

  useEffect(() => {
    refetch();
    refetchCount();
  }, [refreshKey]);

  return (
    <div className={styles.logs}>
      <Content style={{ background: 'none' }}>
        <Padding size={Size.LARGE}>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Flex justify={FlexJustify.START}>
              <FlexItem flex>
                <LogsFilter
                  value={types.values as any} // useFilters returns (T[] | null)[], meaning type cannot be assigned, although actual value is correct
                  onChange={types.set}
                  onClear={() => types.set(null)}
                />
              </FlexItem>
              <FlexItem>
                <Button
                  leading={isLeadLoading ? <Spinner size={Size.SMALL} /> : null}
                  disabled={leadError != null}
                  onClick={lead != null ? () => setIsCreateMeetingModalVisible(true) : undefined}
                  tier={Tier.SECONDARY}>
                  {tt('new_meeting_button')}
                </Button>
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Button
                  disabled={logsError != null}
                  onClick={() => setIsCreateLogModalVisible(true)}
                  color={Color.BLUE}
                  leading={<Icon name="plus" />}>
                  {tt('new_log_button')}
                </Button>
              </FlexItem>
            </Flex>
          </Margin>
          {run(() => {
            if ((isLogsLoading || isLogsCountLoading) && !hasClickedMore) {
              return (
                <Fragment>
                  <LogCardPlaceholder />
                  <LogCardPlaceholder />
                  <LogCardPlaceholder />
                  <LogCardPlaceholder />
                  <LogCardPlaceholder />
                </Fragment>
              );
            } else if (logsError != null || logs == null || logsCount == null) {
              return <ErrorPlaceholder error={logsError} />;
            } else if (logs.length === 0) {
              return (
                <EmptyState
                  title={tt('empty_state.title')}
                  description={tt('empty_state.description')}>
                  <Button onClick={() => setIsCreateLogModalVisible(true)} category={Category.INFO}>
                    {tt('empty_state.new_log_button')}
                  </Button>
                </EmptyState>
              );
            } else {
              return (
                <Fragment>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    {logs
                      .sort(
                        (a, b) =>
                          (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1) ||
                          b.date.localeCompare(a.date),
                      )
                      .map((log) => (
                        <Margin key={log.id} size={{ bottom: Size.EXTRA_SMALL }}>
                          <LogCard
                            onClickPin={() => handlePinLog(log)}
                            onClickDelete={
                              isManualLogType(log.type) ? () => setLogToDelete(log) : undefined
                            }
                            onClickEdit={
                              isManualLogType(log.type) ? () => setActiveLog(log) : undefined
                            }
                            log={log}
                          />
                        </Margin>
                      ))}
                  </Margin>
                  {logs.length < logsCount ? (
                    <Button
                      onClick={() => {
                        setHasClickedMore(true);
                        page.set(`${currentPage + 1}`);
                      }}
                      disabled={isLogsLoading}
                      trailing={
                        isLogsLoading ? <Spinner color={Color.BLUE} size={Size.SMALL} /> : null
                      }
                      style={{ margin: '0 auto', display: 'flex' }}
                      tier={Tier.SECONDARY}
                      color={Color.BLUE}>
                      {tt('load_more')}
                    </Button>
                  ) : null}
                </Fragment>
              );
            }
          })}
        </Padding>
      </Content>
      <LogModal
        key={activeLog?.id}
        mode={activeLog == null ? 'create' : 'edit'}
        log={activeLog}
        leadId={leadId}
        visible={isCreateLogModalVisible}
        onOperationComplete={refetch}
        onClickClose={() => {
          setIsCreateLogModalVisible(false);
          setTimeout(() => {
            setActiveLog(undefined);
          }, 300);
        }}
      />
      <DeleteLogModal
        loading={isDeleting}
        onClickConfirm={handleDeleteLog}
        onClickClose={() => setLogToDelete(undefined)}
        visible={logToDelete != null}
      />
      <CreateAndEditMeetingModal
        lead={lead}
        isVisible={isCreateMeetingModalVisible}
        onClose={() => setIsCreateMeetingModalVisible(false)}
      />
    </div>
  );
};
