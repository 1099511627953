import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { StatTile } from '../StatTile';

export const LoadingPlaceholderTile = () => {
  return (
    <StatTile>
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
        <FlexItem>
          <LoadingPlaceholder width="70%" />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem flex>
          <Flex style={{ height: '100%' }} align={FlexAlign.STRETCH}>
            <FlexItem>
              <Flex
                style={{ height: '100%' }}
                direction={FlexDirection.VERTICAL}
                justify={FlexJustify.SPACE_BETWEEN}>
                {Array.from({ length: 10 }).map((_, i) => (
                  <FlexItem key={i}>
                    <LoadingPlaceholder width={20} height={20} />
                  </FlexItem>
                ))}
                <FlexSpacer size={Size.SMALL} />
              </Flex>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <LoadingPlaceholder height="100%" width={2} />
            </FlexItem>
            <FlexItem flex>
              <Flex
                style={{ height: '100%' }}
                direction={FlexDirection.VERTICAL}
                justify={FlexJustify.SPACE_BETWEEN}
                align={FlexAlign.STRETCH}>
                {Array.from({ length: 10 }).map((_, i) => (
                  <FlexItem key={i}>
                    <LoadingPlaceholder width="100%" height={1} />
                  </FlexItem>
                ))}
                <FlexItem>
                  <LoadingPlaceholder width="100%" height={2} />
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <Flex justify={FlexJustify.SPACE_AROUND}>
            {Array.from({ length: 6 }).map((_, i) => (
              <FlexItem key={i}>
                <LoadingPlaceholder width={40} height={20} />
              </FlexItem>
            ))}
          </Flex>
        </FlexItem>
      </Flex>
    </StatTile>
  );
};
