import {
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  InlineEdit,
  Input,
  Size,
  Tooltip,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import React from 'react';

import { createTranslate, createTranslateWithLocale } from '~/utils';

import { Website } from '../../api/domain';
import { SocialMediaPlatform } from '../../types';
import { Themed } from './Themed';
import { SocialMediaIcon } from './Themed/SocialMediaIcon';

const BASE_TRANSLATE_KEY = 'pods.website_builder.routes.website_builder';

const tt = createTranslate(BASE_TRANSLATE_KEY);

const styles = {
  footer: css`
    height: 80px;
  `,
  noTextSelect: css`
    cursor: default;
  `,
};

export interface BaseFooterProps {
  website: Website;
  onChange: (data: Partial<Website>) => void;
}

export const BaseFooter = ({ website, onChange }: BaseFooterProps) => {
  const form = useForm({
    companyName: website.companyName ?? '',
    companyUrl: website.companyUrl ?? '',
    gdprUrl: website.gdprUrl ?? '',
  });

  const tl = createTranslateWithLocale(BASE_TRANSLATE_KEY, website.localeCode);

  const _onChangeSocialMedia = (sm: Website['socialMedia']): void => {
    onChange({ socialMedia: { ...website.socialMedia, ...sm } });
  };

  return (
    <div className={styles.footer}>
      <Flex style={{ height: '100%' }} align={FlexAlign.CENTER} justify={FlexJustify.SPACE_AROUND}>
        <FlexItem>
          <Flex>
            <FlexItem>
              <InlineEdit
                onCancel={() => {
                  form.set(website.companyName ?? '', 'companyName');
                  form.set(website.companyUrl ?? '', 'companyUrl');
                }}
                onClickConfirm={() => {
                  onChange({ companyName: form.get('companyName') });
                  onChange({ companyUrl: form.get('companyUrl') });
                }}
                edit={
                  <Flex>
                    <FlexItem>
                      <Input
                        size={Size.SMALL}
                        name="companyName"
                        placeholder={tt('company_name_placeholder')}
                        value={form.get('companyName')}
                        onChange={(v) => form.set(v.toString(), 'companyName')}
                      />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem>
                      <Input
                        size={Size.SMALL}
                        name="companyUrl"
                        placeholder={tt('company_link_placeholder')}
                        value={form.get('companyUrl')}
                        onChange={(v) => form.set(v.toString(), 'companyUrl')}
                      />
                    </FlexItem>
                  </Flex>
                }>
                <Themed.TextLink>
                  {website.companyName != null && website.companyName !== ''
                    ? website.companyName
                    : tt('company_name')}
                </Themed.TextLink>
              </InlineEdit>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <SocialMediaIcon
                name={SocialMediaPlatform.FACEBOOK}
                placeholder={tt('facebook_placeholder')}
                value={website.socialMedia?.facebook}
                onCancel={() => _onChangeSocialMedia(website.socialMedia)}
                onClickConfirm={(facebook) => _onChangeSocialMedia({ facebook })}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <SocialMediaIcon
                name={SocialMediaPlatform.TWITTER}
                placeholder={tt('twitter_placeholder')}
                value={website.socialMedia?.twitter}
                onCancel={() => _onChangeSocialMedia(website.socialMedia)}
                onClickConfirm={(twitter) => _onChangeSocialMedia({ twitter })}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <SocialMediaIcon
                name={SocialMediaPlatform.INSTAGRAM}
                placeholder={tt('instagram_placeholder')}
                value={website.socialMedia?.instagram}
                onCancel={() => _onChangeSocialMedia(website.socialMedia)}
                onClickConfirm={(instagram) => _onChangeSocialMedia({ instagram })}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <SocialMediaIcon
                name={SocialMediaPlatform.LINKEDIN}
                placeholder={tt('linkedin_placeholder')}
                value={website.socialMedia?.linkedin}
                onCancel={() => _onChangeSocialMedia(website.socialMedia)}
                onClickConfirm={(linkedin) => _onChangeSocialMedia({ linkedin })}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
          </Flex>
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem>
              <InlineEdit
                onCancel={() => form.set(website.gdprUrl ?? '', 'gdprUrl')}
                onClickConfirm={() => onChange({ gdprUrl: form.get('gdprUrl') })}
                edit={
                  <Input
                    name="gdprUrl"
                    placeholder={tt('gdpr_placeholder')}
                    value={form.get('gdprUrl')}
                    onChange={(v) => form.set(v.toString(), 'gdprUrl')}
                  />
                }>
                <Themed.TextLink>{tl('gdpr')}</Themed.TextLink>
              </InlineEdit>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Tooltip content={tt('privacy_policy_hint')}>
                <span className={styles.noTextSelect}>
                  <Themed.TextLink>{tl('privacy_policy')}</Themed.TextLink>
                </span>
              </Tooltip>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem>
          <span className={styles.noTextSelect}>
            <Themed.TextLink>{tl('by_drawbotics')}</Themed.TextLink>
          </span>
        </FlexItem>
      </Flex>
    </div>
  );
};
