import {
  AttachmentBox,
  AttachmentList,
  Button,
  Category,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Hint,
  Input,
  InputGroup,
  Label,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  Select,
  Shade,
  Size,
  Spinner,
  Text,
  TextArea,
  TextLink,
  Tier,
  Title,
  UploadBox,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import gql from 'fraql';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { Presentation, PresentationStatus } from '~/pods/presentation/types';
import { useFetchPresentation } from '~/pods/presentation/utils';
import { Attachment, OrganisationRole } from '~/types';
import {
  UploadedFile,
  createTranslate,
  getCountryName,
  getCountryOptions,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getRegionOptionsForCountry,
  isAttachment,
  isPriorityCountry,
  run,
  translate as t,
  useAuth,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

const tt = createTranslate('pods.presentation.routes.presentation.components.presentation_details');

interface UpdatePresentationVariables {
  presentation: {
    id: string;
    name?: string;
    tagline?: string;
    description?: string;
    status?: PresentationStatus;
    coverPicture?: string; // signed blob id
    address: {
      countryCode: string;
      region: string;
      city: string;
    };
  };
}

interface UpdatePresentationResult {
  updatePresentation: {
    presentation: Presentation;
  };
}

const updatePresentationMutation = gql`
  mutation UpdatePresentation($presentation: UpdatePresentationInput!) {
    updatePresentation(input: $presentation) {
      presentation {
        id
        name
        tagline
        description
        status
        address {
          countryCode
          region
          city
        }
        coverPicture {
          id
          url
          filename
        }
      }
    }
  }
`;

function _isNullOrEmptyString(input?: string | null) {
  return input == null || (typeof input === 'string' && input.length === 0);
}

interface EditableFieldProps {
  editing?: boolean;
  viewComponent: ReactNode;
  editComponent: ReactNode;
  title: string;
}

const EditableField = ({ editing, viewComponent, editComponent, title }: EditableFieldProps) => {
  return editing ? (
    <FormGroup input={editComponent} label={<Label>{title}</Label>} />
  ) : (
    <Fragment>
      <Margin size={{ bottom: Size.EXTRA_SMALL }}>
        <Label>{title}</Label>
      </Margin>
      {viewComponent}
    </Fragment>
  );
};

interface PresentationForm {
  name: string;
  tagline: string;
  description: string;
  countryCode: string;
  region: string;
  city: string;
  coverPicture: Attachment | UploadedFile;
}

interface PresentationDetailsProps {
  editOnly?: boolean;
  projectId: string;
}

export const PresentationDetails = ({ editOnly, projectId }: PresentationDetailsProps) => {
  const [editMode, setEditMode] = useState(editOnly);
  const { showAlert } = useAlert();
  const { presentation, isLoading, error: presentationError } = useFetchPresentation(projectId);
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);

  const presentationForm = useForm<PresentationForm>({
    name: presentation?.name ?? '',
    tagline: presentation?.tagline ?? '',
    description: presentation?.description ?? '',
    countryCode: presentation?.address?.countryCode,
    region: presentation?.address?.region,
    city: presentation?.address?.city,
    coverPicture: presentation?.coverPicture,
  });

  const { res: updatePresentationResult, executeMutation: updatePresentation } = useMosaicMutation<
    UpdatePresentationResult,
    UpdatePresentationVariables
  >(updatePresentationMutation);

  const { user } = useAuth();
  const isUserAdmin = user?.role === OrganisationRole.ADMIN;

  const handleUpload = async (files: FileList) => {
    if (files.length > 0) {
      const res = await uploadFiles(files);
      const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
      presentationForm.set(validFiles[0], 'coverPicture');
    }
  };

  const handleSaveChanges = async () => {
    const { coverPicture, countryCode, region, city, ...rest } = presentationForm.values;

    if (countryCode == null || region == null || city == null) {
      throw new Error('One of the parts of the address was nullish');
    }

    const { error } = await updatePresentation({
      presentation: {
        id: presentation!.id,
        status: editOnly ? PresentationStatus.UNPUBLISHED : undefined,
        address: {
          countryCode,
          region,
          city,
        },
        coverPicture:
          coverPicture != null && !isAttachment(coverPicture)
            ? coverPicture.signedBlobId
            : undefined,
        ...rest,
      },
    });
    if (error != null) {
      showAlert({
        text: tt('update_error'),
        category: Category.DANGER,
      });
    } else {
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (presentation != null) {
      presentationForm.set(presentation.name ?? '', 'name');
      presentationForm.set(presentation.tagline ?? '', 'tagline');
      presentationForm.set(presentation.description ?? '', 'description');
      presentationForm.set(presentation.coverPicture, 'coverPicture');
      presentationForm.set(presentation.address?.countryCode, 'countryCode');
      presentationForm.set(presentation.address?.region, 'region');
      presentationForm.set(presentation.address?.city, 'city');
    }
  }, [presentation]);

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: t('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  const { coverPicture } = presentationForm.values;

  const canSave =
    presentationForm.values.name !== '' &&
    presentationForm.values.tagline !== '' &&
    !_isNullOrEmptyString(presentationForm.values.countryCode) &&
    !_isNullOrEmptyString(presentationForm.values.region) &&
    !_isNullOrEmptyString(presentationForm.values.city) &&
    coverPicture != null;

  return (
    <Panel
      header={
        <PanelHeader>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Title noMargin size={4}>
                {tt('presentation_details')}
              </Title>
            </FlexItem>
            {!editOnly && isUserAdmin && !editMode ? (
              <FlexItem>
                <div onClick={() => setEditMode(true)}>
                  <TextLink>{tt('edit')}</TextLink>
                </div>
              </FlexItem>
            ) : null}
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          {run(() => {
            if (isLoading) {
              return (
                <Fragment>
                  <LoadingPlaceholder />
                  <Margin size={{ bottom: Size.DEFAULT }} />
                  <LoadingPlaceholder width={150} />
                  <Margin size={{ bottom: Size.DEFAULT }} />
                  <LoadingPlaceholder width="100%" height={40} />
                  <Margin size={{ bottom: Size.DEFAULT }} />
                  <LoadingPlaceholder width="100%" height={300} />
                </Fragment>
              );
            } else if (presentation != null && !isLoading) {
              return (
                <Fragment>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    <EditableField
                      editing={editMode}
                      title={`${tt('name')}*`}
                      viewComponent={<Text>{presentation.name}</Text>}
                      editComponent={
                        <Input
                          name="name"
                          value={presentationForm.get}
                          onChange={presentationForm.set}
                          placeholder={tt('name_of_project')}
                        />
                      }
                    />
                  </Margin>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    {editMode ? (
                      <FormGroup
                        label={<Label>{tt('address')}*</Label>}
                        input={
                          <InputGroup horizontal>
                            <Select
                              onChange={(k, v) => {
                                presentationForm.set(k, v);
                                presentationForm.set(undefined, 'region');
                                presentationForm.set(undefined, 'city');
                              }}
                              value={presentationForm.get}
                              disabled={!editMode}
                              options={getCountryOptions()}
                              placeholder={tt('country')}
                              name="countryCode"
                            />
                            {isPriorityCountry(presentationForm.values.countryCode) ? (
                              <Select
                                name="region"
                                placeholder="Region"
                                onChange={(k, v) => {
                                  presentationForm.set(k, v);
                                  presentationForm.set('', 'city');
                                }}
                                value={presentationForm.get}
                                disabled={!editMode}
                                options={getRegionOptionsForCountry(
                                  presentationForm.values.countryCode,
                                )}
                              />
                            ) : (
                              <Input
                                name="region"
                                placeholder={tt('region')}
                                onChange={presentationForm.set}
                                value={presentationForm.get}
                                disabled={presentationForm.values.countryCode == null}
                              />
                            )}
                            <Input
                              onChange={presentationForm.set}
                              disabled={!editMode || presentationForm.values.region == null}
                              value={presentationForm.get}
                              placeholder={tt('city')}
                              name="city"
                            />
                          </InputGroup>
                        }
                      />
                    ) : (
                      <Fragment>
                        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
                          <Label>{tt('address')}</Label>
                        </Margin>
                        {presentation.address != null ? (
                          <Text>
                            {`${presentation?.address?.city ?? ''}, ${
                              presentation?.address?.region ?? ''
                            }, ${
                              presentation?.address?.countryCode
                                ? getCountryName(presentation.address.countryCode)
                                : ''
                            }`}
                          </Text>
                        ) : null}
                      </Fragment>
                    )}
                  </Margin>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    <EditableField
                      editing={editMode}
                      title={`${tt('tagline')}*`}
                      viewComponent={<Text>{presentation.tagline}</Text>}
                      editComponent={
                        <Input
                          name="tagline"
                          value={presentationForm.get}
                          onChange={presentationForm.set}
                          placeholder={tt('tagline_of_project')}
                        />
                      }
                    />
                  </Margin>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    <EditableField
                      editing={editMode}
                      title={tt('short_description')}
                      viewComponent={
                        presentation.description != '' ? (
                          <Text>{presentation.description}</Text>
                        ) : (
                          <Text shade={Shade.LIGHT}>{tt('no_description')}</Text>
                        )
                      }
                      editComponent={
                        <TextArea
                          name="description"
                          value={presentationForm.get}
                          onChange={presentationForm.set}
                          placeholder={tt('write_description')}
                        />
                      }
                    />
                  </Margin>
                  <EditableField
                    editing={editMode}
                    title={`${tt('cover_picture')}*`}
                    viewComponent={
                      <img
                        src={presentation.coverPicture?.url}
                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                      />
                    }
                    editComponent={
                      <Fragment>
                        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
                          <UploadBox
                            onUploadFiles={handleUpload}
                            fullWidth
                            allowedFileFormats=".png,.jpg"
                          />
                        </Margin>
                        <Margin size={{ bottom: Size.SMALL }}>
                          <Text size={Size.SMALL} shade={Shade.MEDIUM}>
                            {tt('picture_description')}
                          </Text>
                        </Margin>
                        <AttachmentList>
                          {run(() => {
                            if (isFileUploading) {
                              return info.map((uploadInfo) => (
                                <AttachmentBox
                                  key={uploadInfo.filename}
                                  fileName={uploadInfo.filename}
                                  progress={uploadInfo.progress}
                                />
                              ));
                            } else if (coverPicture != null) {
                              return (
                                <AttachmentBox
                                  fileName={
                                    isAttachment(coverPicture)
                                      ? coverPicture.filename
                                      : coverPicture.originalFile.name
                                  }
                                />
                              );
                            }
                          })}
                        </AttachmentList>
                      </Fragment>
                    }
                  />
                </Fragment>
              );
            } else {
              return (
                <EmptyState
                  variation={getEmptyStateVariationFromErrorType(presentationError)}
                  description={getMessageFromErrorType(presentationError)}
                />
              );
            }
          })}
        </PanelBody>
      }
      footer={
        editMode ? (
          <PanelFooter>
            <Flex>
              <FlexItem flex>
                <Hint style={{ margin: 0 }}>{tt('mandatory_fields')}</Hint>
              </FlexItem>
              {editOnly ? null : (
                <FlexItem>
                  <Button tier={Tier.TERTIARY} onClick={() => setEditMode(false)}>
                    {tt('cancel')}
                  </Button>
                </FlexItem>
              )}
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <Button
                  onClick={handleSaveChanges}
                  trailing={
                    updatePresentationResult.fetching ? (
                      <Spinner size={Size.SMALL} inversed />
                    ) : null
                  }
                  disabled={!canSave || updatePresentationResult.fetching}
                  category={Category.INFO}>
                  {tt('save')}
                </Button>
              </FlexItem>
            </Flex>
          </PanelFooter>
        ) : undefined
      }
    />
  );
};
