import {
  Align,
  Flex,
  FlexDirection,
  FlexItem,
  Label,
  Margin,
  Paragraph,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

import { createTranslate } from '~/utils';

import { StatTile } from './StatTile';

const tt = createTranslate('pods.product_stats.components.empty_tile');

interface EmptyTileProps {
  title?: string;
  comingSoon?: boolean;
  cta?: ReactNode;
  image: string;
  text: ReactNode;
  lockRatio?: boolean;
}

export const EmptyTile = ({ title, comingSoon, cta, image, text, lockRatio }: EmptyTileProps) => {
  return (
    <StatTile lockRatio={lockRatio}>
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
        {comingSoon ? (
          <FlexItem>
            <Margin size={{ bottom: Size.EXTRA_SMALL }}>
              <Text bold disabled style={{ textTransform: 'uppercase' }} size={Size.SMALL}>
                {tt('coming_soon')}
              </Text>
            </Margin>
          </FlexItem>
        ) : null}
        {title != null ? (
          <FlexItem>
            <Margin size={{ bottom: Size.SMALL }}>
              <Label>{title}</Label>
            </Margin>
          </FlexItem>
        ) : null}
        <FlexItem>
          <img src={image} width={100} />
        </FlexItem>
        <FlexItem>
          <Paragraph align={Align.CENTER} style={{ maxWidth: 350, lineHeight: 1.5 }}>
            {text}
          </Paragraph>
        </FlexItem>
        <FlexItem>{cta}</FlexItem>
      </Flex>
    </StatTile>
  );
};
