import {
  Button,
  Color,
  DateInput,
  DateObject,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Label,
  Modal,
  Size,
  Spinner,
  Text,
  Tier,
  dateToObject,
  objectToDate,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import dayjs from 'dayjs';
import gql from 'fraql';
import React from 'react';

import burnUp from '~/pods/insights/images/burn_up.svg';
import { Project } from '~/pods/insights/types';
import { ID, ISO8601DateTime } from '~/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.insights.routes.deals');

const UpdateGoalDatesMutation = gql`
  mutation UpdateGoalDates($id: ID!, $start: String, $end: String) {
    projectResult: updateProject(
      input: { id: $id, dealsGoalStartDate: $start, dealsGoalEndDate: $end }
    ) {
      project {
        id
        dealsGoalStartDate
        dealsGoalEndDate
      }
    }
  }
`;

interface GoalSettingsForm {
  start?: DateObject;
  end?: DateObject;
}

interface GoalsModalProps {
  isVisible: boolean;
  onClickClose: VoidFunction;
  project: Project;
  refetch: VoidFunction;
}

export const GoalsModal = ({ isVisible, onClickClose, project, refetch }: GoalsModalProps) => {
  const {
    set,
    values: { start, end },
  } = useForm<GoalSettingsForm>({
    start:
      project.dealsGoalStartDate != null
        ? dateToObject(new Date(project.dealsGoalStartDate.toISOString()))
        : undefined,
    end:
      project.dealsGoalEndDate != null
        ? dateToObject(new Date(project.dealsGoalEndDate.toISOString()))
        : undefined,
  });

  const {
    res: { fetching: isUpdating },
    executeMutation: updateGoalDates,
  } = useMosaicMutation<
    { project: { id: ID } },
    { id: ID; start: ISO8601DateTime; end: ISO8601DateTime }
  >(UpdateGoalDatesMutation);

  const isSaveEnabled =
    (start != null && !dayjs(objectToDate(start)).isSame(project.dealsGoalStartDate, 'day')) ||
    (end != null && !dayjs(objectToDate(end)).isSame(project.dealsGoalEndDate, 'day'));

  const handleClickSave = async () => {
    if (start == null || end == null) {
      return;
    }
    await updateGoalDates({
      id: project.id,
      start: objectToDate(start).toISOString(),
      end: objectToDate(end).toISOString(),
    });
    refetch();
    onClickClose();
  };

  return (
    <Modal
      title={tt('goal_settings')}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <Button
              leading={isUpdating ? <Spinner /> : null}
              disabled={!isSaveEnabled}
              onClick={handleClickSave}
              color={Color.BLUE}>
              {tt('set_goals')}
            </Button>
          </FlexItem>
        </Flex>
      }
      visible={isVisible}
      onClickClose={onClickClose}>
      <Flex style={{ width: '500px' }} direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={burnUp} />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          <Flex>
            <FlexItem>
              <FormGroup
                label={<Label>{tt('starting_date')}</Label>}
                input={
                  <DateInput
                    maxDate={end}
                    name="start"
                    onChange={set}
                    value={start ?? ''}
                    placeholder={tt('start_date_placeholder')}
                  />
                }
              />
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <FormGroup
                label={<Label>{tt('completion_date')}</Label>}
                input={
                  <DateInput
                    minDate={start}
                    name="end"
                    onChange={set}
                    value={end ?? ''}
                    placeholder={tt('end_date_placeholder')}
                  />
                }
              />
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem style={{ textAlign: 'center' }}>
          <Text tier={Tier.TERTIARY}>{tt('goals_modal_flavour_text')}</Text>
        </FlexItem>
      </Flex>
    </Modal>
  );
};
