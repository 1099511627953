import sv from '@drawbotics/drylus-style-vars';
import { Category, InlineEdit, Input, Size, Text, useAlert } from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React from 'react';

import { Figure as FigureType } from '~/pods/meetings/types';
import { isFieldEmptyString } from '~/pods/meetings/utils';
import { HexColor } from '~/types';
import { createTranslate, extractGQLErrorMessages, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  figure: css`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  value: css`
    font-size: 3em;
    color: ${sv.colorSecondary};
    display: flex;

    /* Target inline edit */
    > div {
      display: flex;
    }

    [data-element='sup'] {
      font-size: 0.4em;
      left: 3px;
      font-weight: bold;
      height: 1.2em;
      top: 0.1em;
      display: flex;
      align-items: center;
    }
  `,
};

interface UpdateFigureVariables {
  figure: {
    id: string;
    value?: string;
    superscript?: string;
    subtext?: string;
  };
}

interface UpdateFigureResult {
  updateFigure: {
    figure: FigureType;
  };
}

const updateFigureMutation = gql`
  mutation updateFigure($figure: UpdateFigureInput!) {
    updateFigure(input: $figure) {
      figure {
        value
        superscript
        subtext
      }
    }
  }
`;

interface FigureForm {
  value: string;
  subtext: string;
  superscript: string;
}

interface FigureProps {
  figure: FigureType;
  primaryColor?: HexColor;
  readOnly: boolean;
}

export const Figure = ({ figure, primaryColor, readOnly }: FigureProps) => {
  const figureForm = useForm<FigureForm>({
    value: figure.value,
    subtext: figure.subtext,
    superscript: figure.superscript ?? '',
  });
  const { showAlert } = useAlert();

  const { executeMutation: updateFigure } = useMosaicMutation<
    UpdateFigureResult,
    UpdateFigureVariables
  >(updateFigureMutation);

  const handleResetValue = (name: keyof FigureForm) => {
    figureForm.set(figure[name] ?? '', name);
  };

  const handleSaveValue = async (name: keyof FigureForm) => {
    const res = await updateFigure({ figure: { id: figure.id, [name]: figureForm.values[name] } });
    if (res.error != null) {
      const message = extractGQLErrorMessages(res.error);
      showAlert({
        text:
          message.message != null
            ? tt(`errors.${message.message}`)
            : tt('could_not_save_figure', { name }),
        category: Category.DANGER,
      });
      handleResetValue(name);
    }
  };

  return (
    <div className={styles.figure}>
      <div className={styles.value} style={{ color: primaryColor }}>
        {readOnly ? (
          <span>
            {isFieldEmptyString(figureForm.values.value) ? '€25' : figureForm.values.value}
          </span>
        ) : (
          <InlineEdit
            onCancel={() => handleResetValue('value')}
            onClickConfirm={() => handleSaveValue('value')}
            edit={
              <Input
                name="value"
                style={{ fontSize: 'initial', width: 130 }}
                placeholder="€25"
                value={figureForm.get}
                onChange={figureForm.set}
              />
            }>
            <span>
              {isFieldEmptyString(figureForm.values.value) ? '€25' : figureForm.values.value}
            </span>
          </InlineEdit>
        )}
        {readOnly ? (
          <sup
            data-element="sup"
            style={{
              color: isFieldEmptyString(figureForm.values.superscript)
                ? sv.colorTertiary
                : undefined,
            }}>
            {isFieldEmptyString(figureForm.values.superscript)
              ? 'bn'
              : figureForm.values.superscript}
          </sup>
        ) : (
          <InlineEdit
            onCancel={() => handleResetValue('superscript')}
            onClickConfirm={() => handleSaveValue('superscript')}
            edit={
              <Input
                name="superscript"
                style={{ fontSize: '0.3em', width: 40 }}
                size={Size.SMALL}
                placeholder="bn"
                value={figureForm.get}
                onChange={figureForm.set}
              />
            }>
            <sup
              data-element="sup"
              style={{
                color: isFieldEmptyString(figureForm.values.superscript)
                  ? sv.colorTertiary
                  : undefined,
              }}>
              {isFieldEmptyString(figureForm.values.superscript)
                ? 'bn'
                : figureForm.values.superscript}
            </sup>
          </InlineEdit>
        )}
      </div>
      {readOnly ? (
        <Text>
          {isFieldEmptyString(figureForm.values.subtext)
            ? 'Lorem Ipsum'
            : figureForm.values.subtext}
        </Text>
      ) : (
        <InlineEdit
          onCancel={() => handleResetValue('subtext')}
          onClickConfirm={() => handleSaveValue('subtext')}
          edit={
            <Input
              name="subtext"
              size={Size.SMALL}
              placeholder="Lorem Ipsum"
              value={figureForm.get}
              onChange={figureForm.set}
            />
          }>
          <Text>
            {isFieldEmptyString(figureForm.values.subtext)
              ? 'Lorem Ipsum'
              : figureForm.values.subtext}
          </Text>
        </InlineEdit>
      )}
    </div>
  );
};
