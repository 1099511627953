import {
  Avatar,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  Margin,
  Padding,
  Separator,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment } from 'react';

import { Invitation, User } from '~/pods/meetings/types';
import { createTranslate, generateColorFromString } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.add_teammates_modal');

const styles = {
  scrollableList: css`
    max-height: 200px;
    overflow: auto;
  `,
};

interface RecapProps {
  userIds: Array<string>;
  teammates: Array<User | Invitation>;
  handleAddTeammates: VoidFunction;
}

export const Recap = ({ userIds, teammates }: RecapProps) => {
  const selectedUsers = teammates.filter((user) => userIds.includes(user.id));

  return (
    <Fragment>
      <Margin size={{ top: Size.LARGE }}>
        <Label>{tt('invite_following')}:</Label>
      </Margin>
      <div className={styles.scrollableList}>
        {selectedUsers.map((user, i) => (
          <Fragment key={user.id}>
            {i !== 0 ? <Separator /> : null}
            <Padding size={{ vertical: Size.SMALL }}>
              <Flex justify={FlexJustify.START}>
                <FlexItem>
                  <Avatar
                    text={user.fullName[0]}
                    backgroundColor={generateColorFromString(user.email)}
                  />
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Text>{user.fullName}</Text>
                </FlexItem>
              </Flex>
            </Padding>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};
