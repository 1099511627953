import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';
import { AssetSchema } from '../asset';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const MainSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.MAIN),
  title: ze.optional(z.string()),
  active: ze.defaults(z.boolean(), false),
  assets: z.array(AssetSchema),
  position: z.number(),
});

export type MainSectionData = z.infer<typeof MainSectionSchema>;

export class MainSection extends ze.ClassFrom(MainSectionSchema) {
  public static fromData(data: MainSectionData): MainSection {
    return ze.validate(data, MainSection);
  }

  get isActive() {
    return this.active;
  }

  public toData(): MainSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
