import {
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  LoadingPlaceholder,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { StatTile } from '../StatTile';

export const LoadingPlaceholderTile = () => {
  return (
    <StatTile>
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem flex>
          <LoadingPlaceholder width="50%" height="100%" style={{ margin: 'auto' }} />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          <LoadingPlaceholder width="80%" style={{ margin: 'auto' }} />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <LoadingPlaceholder width="50%" style={{ margin: 'auto' }} />
        </FlexItem>
      </Flex>
    </StatTile>
  );
};
