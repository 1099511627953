import { Banner, Button, ButtonLink, Category, Tier } from '@drawbotics/react-drylus';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Availability, InsightsEstate } from '~/pods/insights/types';
import { createTranslate, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.insights.routes.deals');

interface MissingInfoBannerProps {
  estate: InsightsEstate;
}

export const MissingInfoBanner = ({ estate }: MissingInfoBannerProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const [isUnavailableBannerVisible, setIsUnavailableBannerVisible] = useState(true);

  const { units } = estate;

  const unitsWithoutPrices = units.filter(
    (unit) => unit.price.value == null || unit.price.value === 0,
  );

  // We have a different, page-wide state in case none of the units have a price so we
  // don't need to show the banner in that case
  if (unitsWithoutPrices.length === units.length) {
    return null;
  }

  if (unitsWithoutPrices.length !== 0) {
    return (
      <Banner
        category={Category.INFO}
        trailing={
          <Link to={getUrlInProject('/listing')}>
            <ButtonLink inversed tier={Tier.SECONDARY}>
              {tt('to_listing')}
            </ButtonLink>
          </Link>
        }>
        {unitsWithoutPrices.length === 1
          ? tt('one_price_missing', { unitName: unitsWithoutPrices[0].name })
          : tt('multiple_prices_missing')}
      </Banner>
    );
  } else if (
    units.some((unit) => unit.availability === Availability.UNAVAILABLE) &&
    isUnavailableBannerVisible
  ) {
    return (
      <Banner
        category={Category.INFO}
        trailing={
          <Button tier={Tier.SECONDARY} onClick={() => setIsUnavailableBannerVisible(false)}>
            {tt('close')}
          </Button>
        }>
        {tt('unavailable_warning')}
      </Banner>
    );
  } else {
    return null;
  }
};
