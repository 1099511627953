import sv from '@drawbotics/drylus-style-vars';
import React, { ReactNode } from 'react';

interface CustomLabelTooltipProps {
  children: ReactNode;
}

export const CustomLabelTooltip = ({ children }: CustomLabelTooltipProps) => {
  return (
    <div
      style={{
        borderRadius: sv.defaultBorderRadius,
        padding: sv.paddingExtraSmall,
        background: sv.neutralDarkest,
      }}>
      {children}
    </div>
  );
};
