import {
  Button,
  ButtonLink,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  Padding,
  Position,
  SearchInput,
  Size,
  Spinner,
  Tier,
  Tooltip,
} from '@drawbotics/react-drylus';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import { MosaicPageTitle } from '~/components';
import { LeadsFilterTypes } from '~/pods/crm/types';
import { LeadsQueryFilters, useFetchLeadsExport } from '~/pods/crm/utils';
import { OrganisationRole } from '~/types';
import { createTranslate, useAuth, useMosaicNavigation } from '~/utils';

import { LeadsCounter } from './LeadsCounter';

const tt = createTranslate('pods.crm.routes.leads.components.leads_table_header');

const downloadCSV = (baseString: string) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.id = 'hidden_csv_element';
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(baseString);
  hiddenElement.target = '_blank';
  hiddenElement.download = `leads_${dayjs(Date.now()).format('YYYY-MMM-DD_HH')}h.csv`;
  hiddenElement.click();
};

interface LeadsTableHeaderProps {
  searchTerm: LeadsFilterTypes['searchTerm'];
  title: string;
  isLoading: boolean;
  leadCount?: { ownedLeadCount: number; totalLeadCount: number };
  filters: LeadsQueryFilters;
}

export const LeadsTableHeader = ({
  searchTerm,
  title,
  leadCount,
  isLoading,
  filters,
}: LeadsTableHeaderProps) => {
  const { user } = useAuth();
  const { projectToken, getUrlInOrg, getUrlInProject } = useMosaicNavigation();
  const { exportLeads } = useFetchLeadsExport();

  const handleExportLeads = async () => {
    const result = await exportLeads({
      ...filters,
      limit: undefined,
      offset: undefined,
    });
    if (result != null) {
      const { leadsExport } = result;
      downloadCSV(leadsExport);
    }
  };

  if (user == null) return null;

  return (
    <Flex justify={FlexJustify.END}>
      <FlexItem>
        <Padding size={{ right: Size.SMALL }}>
          <MosaicPageTitle>{title}</MosaicPageTitle>
        </Padding>
      </FlexItem>
      <FlexItem flex>
        {isLoading ? (
          <Spinner />
        ) : (
          <LeadsCounter
            ownedLeadCount={leadCount?.ownedLeadCount ?? 0}
            totalLeadCount={leadCount?.totalLeadCount ?? 0}
          />
        )}
      </FlexItem>
      <FlexItem>
        <SearchInput
          placeholder={tt('input_placeholder')}
          value={searchTerm.value ?? ''}
          onChange={searchTerm.set}
          minimal
        />
      </FlexItem>
      <FlexItem>
        <Padding size={{ left: Size.EXTRA_SMALL }}>
          {user?.role === OrganisationRole.ADMIN ? (
            <Button
              leading={<Icon name="upload" />}
              onClick={handleExportLeads}
              tier={Tier.SECONDARY}>
              {tt('export_leads')}
            </Button>
          ) : (
            <Tooltip side={Position.BOTTOM} content={tt('admin_only_export_tooltip')}>
              <Button disabled={true} leading={<Icon name="upload" />} tier={Tier.SECONDARY}>
                {tt('export_leads')}
              </Button>
            </Tooltip>
          )}
        </Padding>
      </FlexItem>
      <FlexItem>
        <Padding size={{ left: Size.EXTRA_SMALL }}>
          <Link to={projectToken != null ? getUrlInProject('/crm/new') : getUrlInOrg('/crm/new')}>
            <ButtonLink color={Color.BLUE} leading={<Icon name="plus" />}>
              {tt('new_lead_button')}
            </ButtonLink>
          </Link>
        </Padding>
      </FlexItem>
    </Flex>
  );
};
