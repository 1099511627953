import { getJwt } from '@drawbotics/auth';
import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  ButtonLink,
  Callout,
  Category,
  Content,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Margin,
  Size,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { useScreenSize } from '@drawbotics/use-screen-size';
import { css } from 'emotion';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';

import { createTranslate, extractGQLErrorMessages } from '~/utils';

import guest from '../../images/guest.svg';
import host from '../../images/host.svg';
import { useLoginLicense } from '../../utils';
import { ActionPanel, LoginForm } from './components';

const tt = createTranslate('pods.marketing_suite.routes.demodesk_login');

const styles = {
  panels: css`
    height: 100vh;

    @media ${sv.screenL} {
      flex-direction: column-reverse;
      height: 100%;
    }
  `,
};

export const DemodeskLogin = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const { token } = useParams();
  const { login } = useLoginLicense();
  const { showAlert } = useAlert();
  const { screenSize, ScreenSizes } = useScreenSize();

  const meetingLink = `${process.env.DEMODESK_HOST}/${token}`;

  const isMobile = screenSize <= ScreenSizes.L;

  const handleClickHost = async () => {
    const hasJwt = getJwt() != null;
    if (hasJwt) {
      const { error } = await login(token!);
      if (error != null) {
        const { message } = extractGQLErrorMessages(error);
        showAlert({
          text: message != null ? tt(`errors.${message}`) : tt('errors.login_error'),
          category: Category.DANGER,
        });
      } else {
        window.location.assign(meetingLink);
      }
    } else {
      setShowLoginForm(true);
    }
  };

  return (
    <Content fullHeight>
      {isMobile ? (
        <Margin
          size={{
            top: Size.DEFAULT,
            left: Size.DEFAULT,
            right: Size.DEFAULT,
            bottom: Size.LARGE,
          }}>
          <Callout category={Category.INFO}>{tt('mobile_hint')}</Callout>
        </Margin>
      ) : null}
      {showLoginForm ? (
        <Flex style={{ height: '100vh' }}>
          <FlexItem>
            <LoginForm token={token!} />
          </FlexItem>
        </Flex>
      ) : (
        <Fragment>
          <Flex responsive={{ L: { direction: FlexDirection.VERTICAL } }} className={styles.panels}>
            <FlexItem>
              <ActionPanel title={tt('im_the_host')} image={host}>
                <Flex>
                  <FlexItem>
                    <Button
                      onClick={handleClickHost}
                      style={{ boxShadow: `0 0 0 1px ${sv.neutralDarkest} inset` }}
                      tier={Tier.SECONDARY}>
                      {tt('login_with_drawbotics')}
                    </Button>
                  </FlexItem>
                </Flex>
              </ActionPanel>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_HUGE} responsive={{ L: { size: Size.LARGE } }} />
            <FlexItem>
              <ActionPanel title={tt('im_a_guest')} image={guest}>
                <Flex>
                  <FlexItem>
                    <a href={meetingLink}>
                      <ButtonLink inversed style={{ background: sv.neutralDarkest }}>
                        {tt('join_meeting')}
                      </ButtonLink>
                    </a>
                  </FlexItem>
                </Flex>
              </ActionPanel>
            </FlexItem>
          </Flex>
          {isMobile ? <Margin size={Size.DEFAULT} /> : null}
        </Fragment>
      )}
    </Content>
  );
};
