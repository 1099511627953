import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexAlign,
  FlexItem,
  Padding,
  Paragraph,
  Shade,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import companyOverview from '~/pods/meetings/images/company-overview.svg';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

const styles = {
  banner: css`
    border-radius: ${sv.borderRadiusLarge};
    background: ${sv.neutralLight};
    img {
      margin-bottom: -5px;
    }
  `,
};

export const InfoBanner = () => {
  return (
    <div className={styles.banner}>
      <Flex align={FlexAlign.END}>
        <FlexItem flex>
          <Padding size={{ vertical: Size.EXTRA_SMALL, horizontal: Size.DEFAULT }}>
            <Title size={4}>{tt('tell_story')}</Title>
            <Paragraph>
              <Text shade={Shade.MEDIUM}>{tt('company_overview_description')}</Text>
            </Paragraph>
          </Padding>
        </FlexItem>
        <FlexItem>
          <img src={companyOverview} />
        </FlexItem>
      </Flex>
    </div>
  );
};
