import {
  Content,
  Margin,
  Padding,
  Separator,
  Shade,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';
import { useParams } from 'react-router';

import { ErrorPlaceholder, MosaicPageTitle, SettingsSection } from '~/components';
import { useFetchOrganisation } from '~/pods/crm/utils';
import { useFetchProject } from '~/pods/project-settings/utils';
import { createTranslate, run } from '~/utils';

import {
  LeadEmailActionsPanel,
  LeadEmailNotificationPanel,
  LeadsAggregationPanel,
  ProjectDetailsPanel,
  SettingsLoader,
} from '../components';

const tt = createTranslate('pods.project_settings.project_settings');

const ttEmailNotification = createTranslate(
  'pods.project_settings.routes.components.email_notification_panel',
);

export const BaseSettings = () => {
  const { projectSlug } = useParams();
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useFetchProject(projectSlug!);

  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisation();

  const error = projectError ?? organisationError;
  const selectedProject = organisation?.projects?.find((project) => project.slug === projectSlug);

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('settings')}</MosaicPageTitle>
        {run(() => {
          if (isProjectLoading || isOrganisationLoading) {
            return <SettingsLoader />;
          } else if (error != null) {
            return <ErrorPlaceholder error={error} />;
          } else if (project != null && organisation != null && selectedProject != null) {
            return (
              <Fragment>
                <SettingsSection
                  left={
                    <Fragment>
                      <Title noMargin size={4}>
                        {tt('project_details')}
                      </Title>
                      <Margin size={{ top: Size.DEFAULT }}>
                        <Text shade={Shade.MEDIUM}>{tt('project_details_description')}</Text>
                      </Margin>
                    </Fragment>
                  }
                  right={<ProjectDetailsPanel project={project} />}
                />
                <Margin size={{ vertical: Size.DEFAULT }}>
                  <Separator />
                </Margin>
                <SettingsSection
                  left={
                    <Fragment>
                      <Title noMargin size={4}>
                        {ttEmailNotification('leads_settings')}
                      </Title>
                      <Margin size={{ top: Size.DEFAULT }}>
                        <Text shade={Shade.MEDIUM}>
                          {ttEmailNotification('leads_settings_description')}
                        </Text>
                      </Margin>
                    </Fragment>
                  }
                  right={<LeadEmailNotificationPanel project={selectedProject} />}
                />
                <Margin size={{ vertical: Size.DEFAULT }}>
                  <Separator />
                </Margin>
                <SettingsSection
                  left={
                    <Fragment>
                      <Title noMargin size={4}>
                        {tt('lead_email_settings.title')}
                      </Title>
                      <Margin size={{ top: Size.DEFAULT }}>
                        <Text shade={Shade.MEDIUM}>{tt('lead_email_settings.description')}</Text>
                      </Margin>
                    </Fragment>
                  }
                  right={
                    <LeadEmailActionsPanel
                      projectId={selectedProject.id}
                      links={project.emailCtaLinks}
                    />
                  }
                />
                <Margin size={{ vertical: Size.DEFAULT }}>
                  <Separator />
                </Margin>
                <SettingsSection
                  left={
                    <Fragment>
                      <Title noMargin size={4}>
                        {tt('lead_aggregation.title')}
                      </Title>
                      <Margin size={{ top: Size.DEFAULT }}>
                        <Text shade={Shade.MEDIUM}>{tt('lead_aggregation.side_description')}</Text>
                      </Margin>
                    </Fragment>
                  }
                  right={<LeadsAggregationPanel projectSlug={projectSlug!} />}
                />
              </Fragment>
            );
          }
        })}
      </Padding>
    </Content>
  );
};
