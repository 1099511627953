import { Category, EmptyState, useAlert } from '@drawbotics/react-drylus';
import LogRocket from 'logrocket';
import { ReactNode, useEffect, useState } from 'react';
import React from 'react';
import { OperationContext, UseQueryArgs, useQuery } from 'urql';

import {
  getEmptyStateVariationFromErrorType,
  getErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
} from '../errors';
import { splitObject } from '../utils';
import { BackofficeClient, MosaicClient } from './clients';
import { useOrgHeader } from './use-org-header';

interface QueryOptions<V> extends UseQueryArgs<V> {
  /**
   * If provided, this will be the message shown inside the alert triggered in case of a generic error
   */
  errorMessage?: string;
  /**
   * Callback that will be run in case of an error
   */
  onError?: VoidFunction;
  /**
   * If set to false, the hook will not display builtin error messages.
   * If an errorMessage is provided, it will still be shown.
   * Mainly intended to silence error messages if you plan to display the full error state returned by the hook.
   */
  disableErrorHandling?: boolean;
}

function _splitOptions<V>(options: QueryOptions<V>) {
  return splitObject(options, ['errorMessage', 'onError', 'disableErrorHandling']);
}

function useBaseQuery<D, V>(options: QueryOptions<V>, client: Partial<OperationContext>) {
  const { showAlert } = useAlert();
  const [fallbackState, setFallbackState] = useState<ReactNode>();
  const partialContextWithOrgHeader = useOrgHeader();

  const [queryOptions, { errorMessage, onError, disableErrorHandling = false }] =
    _splitOptions(options);

  const newClient = Object.assign(client, partialContextWithOrgHeader);

  const [res, reExecuteQuery] = useQuery<D, V>({
    ...queryOptions,
    context: newClient,
  });

  // If the organisationToken has changed in the URL, force a refetch with the updated header
  useEffect(() => {
    if (!queryOptions.pause)
      reExecuteQuery({
        requestPolicy: 'network-only',
        fetchOptions: partialContextWithOrgHeader.fetchOptions,
      });
  }, [JSON.stringify(partialContextWithOrgHeader)]);

  // Handle errors and success
  useEffect(() => {
    if (res.fetching === false) {
      if (res.error != null) {
        LogRocket.captureMessage(`Error while fetching data: ${JSON.stringify(res.error)}`);

        const errorType = getErrorType(res.error);

        if (!disableErrorHandling || errorMessage != null) {
          showAlert({
            text: errorMessage ?? getMessageFromErrorType(errorType),
            category: Category.DANGER,
          });
        }

        setFallbackState(
          <EmptyState
            title={getTitleFromErrorType(errorType)}
            description={getMessageFromErrorType(errorType)}
            variation={getEmptyStateVariationFromErrorType(errorType)}
          />,
        );

        onError?.();
      } else {
        setFallbackState(undefined);
      }
    }
  }, [res.fetching]);

  return {
    error: res.error,
    isLoading: res.fetching,
    data: res.data,
    refetch: reExecuteQuery,
    fallbackState,
  };
}

export function useBackofficeQuery<D, V>(options: QueryOptions<V>) {
  return useBaseQuery<D, V>(options, BackofficeClient);
}

export function useMosaicQuery<D, V>(options: QueryOptions<V>) {
  return useBaseQuery<D, V>(options, MosaicClient);
}
