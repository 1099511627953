import { getJwt } from '@drawbotics/auth';
import { gqlFetch } from '@tokamakjs/common';
import { Injectable } from '@tokamakjs/react';
import { omit } from 'lodash';
import urlJoin from 'url-join';

import { ID } from '~/types';

import { EditableUnitFields, Listing, Unit } from '../types';
import { UpdateUnitMutation } from './mutations';
import { CreateUnitMutation } from './mutations/create-unit';
import { DestroyUnitMutation } from './mutations/destroy-unit';
import { ListingQuery, ProjectQuery } from './queries';
import { ListingSchema, UnitSchema } from './validation';

const CORE_URL = urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql');

@Injectable()
export class ListingApi {
  public async loadListing(projectSlug: ID, organisationToken: ID): Promise<Listing> {
    // Get the Id of the project associated with this slug (from URL)
    const coreResponse = await gqlFetch({
      url: CORE_URL,
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'X-Organisation-Public-Token': organisationToken,
      },
      query: ProjectQuery,
      variables: { slug: projectSlug },
    });
    const projectId = (await coreResponse.json()).data.project.id;

    // Retrieve the listing for that project
    const listingResponse = await gqlFetch({
      url: CORE_URL,
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'X-Organisation-Public-Token': organisationToken,
      },
      query: ListingQuery,
      variables: { projectId },
    });
    const { listing } = (await listingResponse.json()).data;

    const { units } = listing;
    const modifiedUnits = units.map((u: any) => ({ ...omit(u, 'locked'), isLocked: u.locked }));

    return ListingSchema.parse({ ...omit(listing, 'units'), units: modifiedUnits });
  }

  public async updateUnit(
    unitId: ID,
    values: Partial<EditableUnitFields>,
    organisationToken: ID,
  ): Promise<Unit> {
    const response = await gqlFetch({
      url: CORE_URL,
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'X-Organisation-Public-Token': organisationToken,
      },
      query: UpdateUnitMutation,
      variables: { unit: { unitInput: { ...values, id: unitId } } },
    });

    const { unit } = (await response.json()).data.updatedUnit;

    const modifiedUnit = { ...omit(unit, 'locked'), isLocked: unit.locked };

    return UnitSchema.parse(modifiedUnit);
  }

  public async createUnit(listingId: ID, values: EditableUnitFields): Promise<Unit> {
    const res = await gqlFetch({
      url: CORE_URL,
      headers: { authorization: `Bearer ${getJwt()}` },
      query: CreateUnitMutation,
      variables: { unit: { listingId, ...values } },
    });
    const { unit } = (await res.json()).data.createdUnit;

    const modifiedUnit = { ...omit(unit, 'locked'), isLocked: unit.locked };

    return UnitSchema.parse(modifiedUnit);
  }

  public async destroyUnit(unitId: ID): Promise<void> {
    await gqlFetch({
      url: CORE_URL,
      headers: { authorization: `Bearer ${getJwt()}` },
      query: DestroyUnitMutation,
      variables: { unit: { unitId } },
    });
  }
}
