import {
  Button,
  ButtonLink,
  Category,
  Checkbox,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  InputGroup,
  Label,
  Margin,
  Modal,
  Paragraph,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import React from 'react';
import { Link } from 'react-router-dom';

import { createTranslate, useMosaicNavigation } from '~/utils';

import { ListingSettings } from '../../api/domain';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const MIN_ACTIVE_TOGGLES = 3;

interface ListingModalProps {
  visible: boolean;
  listingSettings: ListingSettings;
  onClickClose: VoidFunction;
  onChange: (listingSettings: ListingSettings) => void;
}

export const ListingModal = ({
  visible,
  listingSettings,
  onClickClose,
  onChange,
}: ListingModalProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const optionsForm = useForm(listingSettings);

  const activeToggles = Object.values(optionsForm.values).filter((toggle) => toggle === true);
  const shouldDisableToggles = activeToggles.length <= MIN_ACTIVE_TOGGLES;

  const handleSave = () => {
    onChange(optionsForm.values as Required<ListingSettings>);
    onClickClose();
  };

  return (
    <Modal
      style={{ maxWidth: 550 }}
      visible={visible}
      title="Listing settings"
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button onClick={handleSave} category={Category.INFO}>
              {tt('save')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Paragraph>{tt('listing_manager_description')}</Paragraph>
      <Link target="_blank" to={getUrlInProject('/listing')}>
        <ButtonLink tier={Tier.SECONDARY} leading={<Icon name="external-link" />}>
          {tt('go_to_listing')}
        </ButtonLink>
      </Link>
      <Margin size={{ top: Size.DEFAULT }} />
      <Label>{tt('sections_to_display')}</Label>
      <Paragraph>{tt('listing_manager_description_2')}</Paragraph>
      <InputGroup>
        <Checkbox
          disabled={shouldDisableToggles && optionsForm.values.surface}
          value={optionsForm.values.surface}
          name="surface"
          onChange={optionsForm.set}>
          {tt('surface')}
        </Checkbox>
        <Checkbox
          disabled={shouldDisableToggles && optionsForm.values.soldUnits}
          value={!optionsForm.values.soldUnits}
          name="soldUnits"
          onChange={(value, name) => optionsForm.set(!value, name)}>
          {tt('show_available_only')}
        </Checkbox>
        <Checkbox
          disabled={shouldDisableToggles && optionsForm.values.terrace}
          value={optionsForm.values.terrace}
          name="terrace"
          onChange={optionsForm.set}>
          {tt('terrace')}
        </Checkbox>
        <Checkbox
          disabled={shouldDisableToggles && optionsForm.values.price}
          value={optionsForm.values.price}
          name="price"
          onChange={optionsForm.set}>
          {tt('price')}
        </Checkbox>
        <Checkbox
          disabled={shouldDisableToggles && optionsForm.values.downloadFloorplan}
          value={optionsForm.values.downloadFloorplan}
          name="downloadFloorplan"
          onChange={optionsForm.set}>
          {tt('download_floorplan')}
        </Checkbox>
      </InputGroup>
    </Modal>
  );
};
