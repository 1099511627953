import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Size,
  Text,
  formatPrice,
} from '@drawbotics/react-drylus';
import { BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { omit } from 'lodash';
import React, { Fragment } from 'react';

import { DashedLine } from '~/pods/insights/components';
import { Availability, InsightsEstate } from '~/pods/insights/types';
import { TypologyFormat } from '~/types';
import { colorGenerator, createTranslate, currencyToSymbol } from '~/utils';

import { CustomLabelTooltip } from '../CustomLabelTooltip';
import { ChartPanel } from './ChartPanel';

const tt = createTranslate('pods.insights.routes.deals');

const AVAILABLE_CHART_HEIGHT = 240;
const BAR_WIDTH = 24;

const PlaceholderChartColumn = () => {
  return (
    <Flex style={{ height: '85%', width: '24px' }} direction={FlexDirection.VERTICAL}>
      <FlexItem flex>
        <DashedLine />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <LoadingPlaceholder width={24} height={16} />
      </FlexItem>
    </Flex>
  );
};

const LoadingPlaceholderChart = () => {
  return (
    <Flex style={{ width: '100%', height: '100%' }}>
      <FlexItem>
        <Flex
          style={{ height: '100%' }}
          justify={FlexJustify.CENTER}
          direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <LoadingPlaceholder width={80} height={16} />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <LoadingPlaceholder width={80} height={16} />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <LoadingPlaceholder width={80} height={16} />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <LoadingPlaceholder width={80} height={16} />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <LoadingPlaceholder width={80} height={16} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_LARGE} />
        </Flex>
      </FlexItem>
      <FlexItem style={{ height: '100%' }} flex>
        <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ width: '100%', height: '100%' }}>
          {[...Array(12)].map((_, i) => (
            <FlexItem key={i} style={{ height: '100%' }}>
              <PlaceholderChartColumn key={i} />
            </FlexItem>
          ))}
        </Flex>
      </FlexItem>
    </Flex>
  );
};

function _getPaddingRatio(availableSize: number, amountOfBars: number, desiredBarWidth: number) {
  return Math.min(1 - desiredBarWidth / (availableSize / amountOfBars), 0.8);
}

const HorizontalBarChart = (props: BarSvgProps & { typologyFormat: TypologyFormat }) => {
  return (
    <ResponsiveBar
      {...omit(props, 'typologyFormat')}
      margin={{
        left: props.typologyFormat === TypologyFormat.FRENCH ? 40 : 110,
        right: 40,
        bottom: 70,
      }}
      colors={(bar) => bar.data[`${bar.id}Color`]}
      enableGridY={false}
      enableGridX={true}
      enableLabel={false}
      innerPadding={1}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
      }}
      borderRadius={4}
      layout="horizontal"
      keys={['booked', 'option', 'available']}
      indexBy="typology"
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: sv.colorTertiary,
            },
            text: {
              marginTop: -10,
              fontSize: '9pt',
              fontFamily: 'Rubik',
              fontWeight: 400,
              fill: sv.colorSecondary,
            },
          },
        },
        grid: {
          line: {
            strokeDasharray: '2 2',
          },
        },
        tooltip: {
          container: {
            backgroundColor: 'transparent',
            boxShadow: '',
          },
        },
      }}
    />
  );
};

interface TypologyBreakdownProps {
  units: InsightsEstate['units'];
  availableTypologies: Array<string>;
  typologyFormat: TypologyFormat;
}

const TypologyBreakdownRevenue = ({
  units,
  availableTypologies,
  typologyFormat,
}: TypologyBreakdownProps) => {
  const colors = colorGenerator();

  const data = availableTypologies
    .slice()
    .reverse()
    .reduce((memo, typology) => {
      const color = colors.next().value;
      return [
        ...memo,
        {
          typology,
          booked: units
            .filter((u) => u.typology === typology && u.availability === Availability.BOOKED)
            .reduce((memo, unit) => memo + unit.price.value, 0),
          bookedColor: color.base,
          option: units
            .filter((u) => u.typology === typology && u.availability === Availability.OPTION)
            .reduce((memo, unit) => memo + unit.price.value, 0),
          optionColor: color.light,
          available: units
            .filter((u) => u.typology === typology && u.availability === Availability.AVAILABLE)
            .reduce((memo, unit) => memo + unit.price.value, 0),
          availableColor: color.lighter,
        },
      ];
    }, [] as Array<{ typology: string; booked: number; option: number; available: number }>);

  return (
    <ChartPanel
      title={tt('deals_by_typology')}
      chart={
        <HorizontalBarChart
          typologyFormat={typologyFormat}
          data={data}
          padding={_getPaddingRatio(AVAILABLE_CHART_HEIGHT, availableTypologies.length, BAR_WIDTH)}
          tooltip={({ id, value }) => {
            return (
              <CustomLabelTooltip>
                <Fragment>
                  <Text inversed>{`${tt(`revenue_goal_label_${id}`)}: `}</Text>
                  <Text inversed bold>
                    {formatPrice({
                      price: { value, currency: units[0].price.currency },
                    })}
                  </Text>
                </Fragment>
              </CustomLabelTooltip>
            );
          }}
          axisBottom={{
            tickValues: 8,
            format: (value) => `${value / 1000}k${currencyToSymbol[units[0].price.currency]}`,
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 45,
          }}
        />
      }
    />
  );
};

const TypologyBreakdownUnits = ({
  units,
  availableTypologies,
  typologyFormat,
}: TypologyBreakdownProps) => {
  const colors = colorGenerator();

  const data = availableTypologies
    .slice()
    .reverse()
    .reduce((memo, typology) => {
      const color = colors.next().value;
      return [
        ...memo,
        {
          typology,
          booked: units.filter(
            (u) => u.typology === typology && u.availability === Availability.BOOKED,
          ).length,
          bookedColor: color.base,
          option: units.filter(
            (u) => u.typology === typology && u.availability === Availability.OPTION,
          ).length,
          optionColor: color.light,
          available: units.filter(
            (u) => u.typology === typology && u.availability === Availability.AVAILABLE,
          ).length,
          availableColor: color.lighter,
        },
      ];
    }, [] as Array<{ typology: string; booked: number; option: number; available: number }>);

  return (
    <ChartPanel
      title={tt('units_by_typology')}
      chart={
        <HorizontalBarChart
          typologyFormat={typologyFormat}
          data={data}
          padding={_getPaddingRatio(AVAILABLE_CHART_HEIGHT, availableTypologies.length, BAR_WIDTH)}
          tooltip={({ id, value }) => {
            return (
              <CustomLabelTooltip>
                <Fragment>
                  <Text inversed>{`${tt(`unit_goal_label_${id}`)}: `}</Text>
                  <br />
                  <Text inversed bold>
                    {`${value} ${tt('units', { count: value })}`}
                  </Text>
                </Fragment>
              </CustomLabelTooltip>
            );
          }}
          axisBottom={{
            format: (value) => (value === 0 || value % 1 !== 0 ? '' : value),
            tickSize: 0,
            tickPadding: 10,
          }}
        />
      }
    />
  );
};

interface TypologyBreakdownPanelProps {
  isLoading?: boolean;
  units?: InsightsEstate['units'];
  metric?: 'units' | 'revenue';
  availableTypologies?: Array<string>;
  typologyFormat?: TypologyFormat;
}

export const TypologyBreakdownPanel = ({
  isLoading,
  units,
  metric,
  availableTypologies = [],
  typologyFormat,
}: TypologyBreakdownPanelProps) => {
  if (isLoading) {
    return <ChartPanel title={tt('units_by_typology')} chart={<LoadingPlaceholderChart />} />;
  }

  if (units == null || typologyFormat == null) {
    console.error(
      'Invariant violated: `units` or `typologyFormat` was null even though loading has completed',
    );
    return null;
  }

  if (metric === 'units') {
    return (
      <TypologyBreakdownUnits
        units={units}
        availableTypologies={availableTypologies}
        typologyFormat={typologyFormat}
      />
    );
  } else {
    return (
      <TypologyBreakdownRevenue
        units={units}
        availableTypologies={availableTypologies}
        typologyFormat={typologyFormat}
      />
    );
  }
};
