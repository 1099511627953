import { ze } from '@tokamakjs/common';
import gql from 'fraql';
import { z } from 'zod';

import { useMosaicQuery } from '~/utils';

import { Unit } from '../types';

export const ProjectQuery = gql`
  query MultidiffusionProjectQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
    }
  }
`;

export const unitFragment = gql`
  fragment _ on Unit {
    id
    reference
  }
`;

export const ListingQuery = gql`
  query ListingQuery($projectId: ID!) {
    listing(projectId: $projectId) {
      id
      name
      providerName
      currency
      surfaceUnit
      units {
        ${unitFragment}
      }
    }
  }
`;

const ProjectDataSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  project: z.object({
    id: z.string(),
  }),
});

const ListingDataSchema = z.object({
  listing: z.object({
    units: z.array(
      z.object({
        id: ze.id(),
        reference: z.string().nullable().optional(),
      }),
    ),
  }),
});

interface UseLoadUnitsReturnType {
  isLoadingUnits: boolean;
  units: Array<Unit>;
}

function isUnit(u: z.infer<typeof ListingDataSchema>['listing']['units'][0]): u is Unit {
  return u.reference != null;
}

export function useLoadUnits(projectSlug: string): UseLoadUnitsReturnType {
  const projectRes = useMosaicQuery({ query: ProjectQuery, variables: { projectSlug } });

  const projectData =
    projectRes.data != null ? ProjectDataSchema.parse(projectRes.data) : undefined;

  const listingRes = useMosaicQuery({
    query: ListingQuery,
    variables: { projectId: projectData?.project?.id },
    pause: projectData?.project?.id == null,
  });

  if (projectRes.isLoading || listingRes.isLoading) {
    return { isLoadingUnits: true, units: [] };
  }

  const unitsData =
    listingRes.data != null ? ListingDataSchema.parse(listingRes.data).listing.units : [];

  return { isLoadingUnits: false, units: unitsData.filter(isUnit) };
}
