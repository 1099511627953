import React, { ReactNode, useEffect } from 'react';
import { Route, Routes } from 'react-router';

import { useTriggerNotFound } from '~/utils';

const NotFound = () => {
  const triggerNotFound = useTriggerNotFound();
  useEffect(() => triggerNotFound(), []);
  return null;
};

interface RoutesWithNotFoundProps {
  children: ReactNode;
}

export const RoutesWithNotFound = ({ children }: RoutesWithNotFoundProps) => {
  return (
    <Routes>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
