import {
  Paragraph as DParagraph,
  ParagraphProps as DParagraphProps,
} from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css, cx } from 'emotion';
import React from 'react';

import { Theme, ThemeDefinition } from '../../../types';

const styles = {
  paragraph: (theme: ThemeDefinition) => css`
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.small};
    line-height: ${theme.fonts.smallSpacing};
  `,
  traditional: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral2};
  `,
  luxurious: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral5};
    font-weight: 300;
  `,
  minimalist: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral3};
    line-height: ${theme.fonts.largeSpacing};
  `,
};

interface ParagraphProps extends DParagraphProps {}

export const Paragraph = ({ ...props }: ParagraphProps) => {
  const theme = useTheme<ThemeDefinition>();
  return (
    <DParagraph
      className={cx(styles.paragraph(theme), {
        [styles.traditional(theme)]: theme.id === Theme.TRADITIONAL,
        [styles.luxurious(theme)]: theme.id === Theme.LUXURIOUS,
      })}
      {...props}
    />
  );
};
