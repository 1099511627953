import {
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Padding,
  ProgressBar,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { createTranslate } from '~/utils/translation';

import { Text } from '../Text';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.total_sold_units');

interface SoldUnitsProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const TotalSoldUnits = ({ units, dateRange }: SoldUnitsProps) => {
  const unitsSold = units.filter((u) => u.soldAt?.isSameOrBefore(dateRange.end)).length;
  const totalUnits = units.length;

  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.EXTRA_SMALL }}>
            <Text light style={{ fontSize: '24pt' }}>
              {unitsSold}
            </Text>
          </Padding>
        </FlexItem>
        <FlexItem style={{ width: '100%', minHeight: '24px' }}>
          <Text>{t('detailed', { units: totalUnits })}</Text>
        </FlexItem>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.SMALL }}>
            <ProgressBar color={Color.BLUE} percentage={unitsSold / totalUnits} size={Size.SMALL} />
          </Padding>
        </FlexItem>
      </Flex>
    </ChartTile>
  );
};
