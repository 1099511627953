import {
  Avatar,
  Button,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Label,
  Margin,
  Padding,
  RoundIcon,
  SearchInput,
  Separator,
  Size,
  Text,
  TextLink,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { Invitation, User } from '~/pods/meetings/types';
import { createTranslate, generateColorFromString, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.add_teammates_modal');

const styles = {
  scrollableList: css`
    max-height: 200px;
    overflow: auto;
  `,
};

interface UserListProps {
  users: Array<User | Invitation>;
  onClickAdd: (id: string) => void;
  onClickRemove: (id: string) => void;
  selectedIds: Array<string>;
  disableAddingUsers: boolean;
}

const UserList = ({
  users,
  onClickAdd,
  onClickRemove,
  selectedIds,
  disableAddingUsers,
}: UserListProps) => {
  return (
    <div className={styles.scrollableList}>
      {users.map((user, i) => (
        <Fragment key={user.id}>
          {i !== 0 ? <Separator /> : null}
          <Padding size={{ vertical: Size.SMALL }}>
            <Flex justify={FlexJustify.START}>
              <FlexItem>
                <Avatar
                  text={user.fullName[0]}
                  backgroundColor={generateColorFromString(user.email)}
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem flex={!selectedIds.includes(user.id)}>
                <Text>{user.fullName}</Text>
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              {selectedIds.includes(user.id) ? (
                <FlexItem flex>
                  <Button
                    onClick={() => onClickRemove(user.id)}
                    size={Size.SMALL}
                    tier={Tier.TERTIARY}>
                    {tt('remove')}
                  </Button>
                </FlexItem>
              ) : null}
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                {!selectedIds.includes(user.id) ? (
                  <Button
                    disabled={disableAddingUsers}
                    onClick={() => onClickAdd(user.id)}
                    size={Size.SMALL}
                    tier={Tier.SECONDARY}
                    leading={<Icon name="plus" />}
                  />
                ) : (
                  <RoundIcon inversed size={Size.LARGE} name="check" color={Color.GREEN} />
                )}
              </FlexItem>
            </Flex>
          </Padding>
        </Fragment>
      ))}
    </div>
  );
};

interface AddPeopleProps {
  selectedUserIds: Array<string>;
  teammates?: Array<User | Invitation>;
  onSelectUser: (id: string) => void;
  onRemoveUser: (id: string) => void;
  disableAddingUsers: boolean;
}

export const AddPeople = ({
  selectedUserIds,
  onRemoveUser,
  onSelectUser,
  teammates,
  disableAddingUsers,
}: AddPeopleProps) => {
  const { getUrlInOrg } = useMosaicNavigation();
  const [userSearch, setUserSearch] = useState('');

  const filteredTeammates = (teammates ?? []).filter((teammate) =>
    teammate.fullName.toLowerCase().includes(userSearch.toLowerCase()),
  );

  return (
    <Fragment>
      <UserList
        disableAddingUsers={disableAddingUsers}
        users={filteredTeammates}
        onClickAdd={onSelectUser}
        onClickRemove={onRemoveUser}
        selectedIds={selectedUserIds}
      />
      <Margin size={{ top: Size.SMALL }} />
      <FormGroup
        label={<Label>{tt('search_people')}</Label>}
        input={
          <SearchInput placeholder={tt('search')} value={userSearch} onChange={setUserSearch} />
        }
      />
      <Margin size={{ vertical: Size.DEFAULT }}>
        <Separator />
      </Margin>
      <Margin size={{ right: Size.EXTRA_SMALL }} style={{ display: 'inline' }}>
        <Label>{tt('not_listed')}</Label>
      </Margin>
      <Link to={getUrlInOrg('/settings/company-team')}>
        <TextLink>{tt('invite_new')}</TextLink>
      </Link>
    </Fragment>
  );
};
