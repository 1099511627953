import { css } from 'emotion';
import React, { Fragment, ReactElement, ReactNode } from 'react';

import { run } from '~/utils';

import { useDrawInCanvas, useGetNodeSize } from '../../../utils';
import { PageGridItem, PageGridItemProps } from './PageGridItem';

const styles = {
  pageGrid: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  canvas: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
};

function _isPageGridItemChild(child: any): child is ReactElement<PageGridItemProps> {
  return child != null && child.type === PageGridItem;
}

interface PageGridProps {
  children: ReactNode;
  gridSize?: number;
  debug?: boolean;
}

export const PageGrid = ({ children, gridSize = 0.1, debug = false }: Readonly<PageGridProps>) => {
  const { nodeRef, size } = useGetNodeSize();

  const canvasRef = useDrawInCanvas(size.width, size.height, (ctx, width, height) => {
    if (!debug) {
      return;
    }

    let x = 0;
    let y = 0;

    ctx.lineWidth = 0.1;
    ctx.strokeStyle = '#666';

    // vertical lines
    while (x <= width) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
      ctx.stroke();
      x += Math.floor(width * gridSize);
    }

    // horizontal lines
    while (y <= height) {
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
      ctx.stroke();
      y += Math.floor(width * gridSize);
    }
  });

  return (
    <div className={styles.pageGrid} ref={nodeRef}>
      {run(() => {
        if (size.width != null && size.height != null) {
          return (
            <Fragment>
              <canvas className={styles.canvas} ref={canvasRef} />
              {React.Children.map(children, (child: any) => {
                if (!_isPageGridItemChild(child)) {
                  return null;
                }

                const { props } = child;
                const unit = Math.floor(gridSize * size.width!);

                return React.cloneElement(child, {
                  style: {
                    top: props.position.getTop(unit),
                    left: props.position.getLeft(unit),
                    width: props.position.getWidth(unit),
                    height: props.position.getHeight(unit),
                  },
                });
              })}
            </Fragment>
          );
        }
      })}
    </div>
  );
};
