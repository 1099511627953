import { TargetDomain } from '@drawbotics/auth';
import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Margin,
  Modal,
  Select,
  Size,
  Spinner,
  Text,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { Form as FormType, useForm } from '@drawbotics/use-form';
import gql from 'fraql';
import React, { useState } from 'react';

import { Invitation, OrganisationRole } from '~/types';
import { createTranslate, extractGQLErrorMessages, isValidEmail } from '~/utils';
import { useMosaicMutation } from '~/utils';

import { Form } from './Form';

const tt = createTranslate('components.invite_modal');

interface CreateInvitationVariables {
  invitation: {
    firstName: string;
    lastName: string;
    email: string;
    organisationRole: OrganisationRole;
    projectId?: string;
  };
}

interface CreateInvitationPayload {
  createInvitation: {
    invitation: Invitation;
  };
}

const createInvitationMutation = gql`
  mutation createInvitation($invitation: CreateInvitationInput!) {
    createInvitation(input: $invitation) {
      invitation {
        id
        email
      }
    }
  }
`;

interface InviteForm {
  firstName: string;
  lastName: string;
  email: string;
  organisationRole?: OrganisationRole;
}

function _checkForm(form: FormType<InviteForm>) {
  const { email, firstName, lastName, organisationRole } = form.values;
  let valid = true;
  valid = valid && email != null && email != '';
  valid = valid && isValidEmail(email);
  valid = valid && firstName != null && firstName != '';
  valid = valid && lastName != null && lastName != '';
  valid = valid && organisationRole != null;
  return valid;
}

interface InviteModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  title: string;
  projectId?: string;
  onClickInvite: VoidFunction;
  isInvitingForAgency?: boolean;
}

export const InviteModal = ({
  onClickInvite,
  visible,
  onClickClose,
  title,
  projectId,
  isInvitingForAgency,
}: InviteModalProps) => {
  const inviteForm = useForm<InviteForm>({
    organisationRole: projectId ? OrganisationRole.MEMBER : undefined,
  });
  const [emailVisited, setEmailVisited] = useState(false);
  const { showAlert } = useAlert();
  const {
    res: { fetching: isSendingInvitation },
    executeMutation: sendInvitation,
  } = useMosaicMutation<CreateInvitationPayload, CreateInvitationVariables>(
    createInvitationMutation,
  );

  const handleClickClose = () => {
    onClickClose();
    setTimeout(() => {
      inviteForm.reset();
    }, 300);
  };

  const isEmailValid = isValidEmail(inviteForm.values.email);
  const showEmailError = emailVisited && !isEmailValid;
  const isFormValid = _checkForm(inviteForm);

  const handleSendInvitation = async () => {
    if (isFormValid) {
      const invitation = {
        ...inviteForm.values,
        email: inviteForm.values.email?.toLowerCase(),
        projectId,
        targetDomain: TargetDomain.MOSAIC.toUpperCase(),
      } as Required<InviteForm>;

      const { error, data } = await sendInvitation({ invitation });

      if (error != null) {
        const { message } = extractGQLErrorMessages(error);
        // TODO translate message (and rename to code)
        const text = message ?? tt('invitation_error');
        showAlert({
          text,
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('invitation_success', {
            entity: projectId == null ? tt('organisation') : tt('project'),
            recipient: data?.createInvitation.invitation.email!,
          }),
          category: Category.SUCCESS,
        });
        handleClickClose?.();
        onClickInvite();
      }
    }
  };

  return (
    <Modal
      onClickClose={handleClickClose}
      title={title}
      visible={visible}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={handleClickClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              disabled={!isFormValid || isSendingInvitation}
              trailing={isSendingInvitation ? <Spinner size={Size.SMALL} inversed /> : null}
              category={Category.INFO}
              onClick={handleSendInvitation}>
              {tt('send_invitation')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Form onSubmit={handleSendInvitation} style={{ maxWidth: '480px' }}>
        <FormGroup
          label={<Label>{tt('first_name')}</Label>}
          input={
            <Input
              name="firstName"
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('first_name_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('last_name')}</Label>}
          input={
            <Input
              name="lastName"
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('last_name_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('email')}</Label>}
          input={
            <Input
              onBlur={() => setEmailVisited(true)}
              error={showEmailError ? tt('invalid_email') : undefined}
              name="email"
              type="email"
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('email_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        {projectId == null ? (
          <FormGroup
            label={<Label>{isInvitingForAgency ? tt('agency_role') : tt('role')}</Label>}
            input={
              <Select
                options={
                  isInvitingForAgency
                    ? [
                        {
                          value: OrganisationRole.AGENCY_SALES_MANAGER,
                          label: tt('sales_manager'),
                        },
                        {
                          value: OrganisationRole.AGENCY_SALES_AGENT,
                          label: tt('sales_agent'),
                        },
                      ]
                    : [
                        {
                          value: OrganisationRole.ADMIN,
                          label: tt('admin'),
                        },
                        {
                          value: OrganisationRole.MEMBER,
                          label: tt('member'),
                        },
                      ]
                }
                name="organisationRole"
                value={inviteForm.get}
                onChange={inviteForm.set}
                placeholder={isInvitingForAgency ? tt('agency_role') : tt('role_placeholder')}
              />
            }
          />
        ) : null}
        <Margin size={{ bottom: Size.SMALL }} />
        {inviteForm.values.organisationRole === OrganisationRole.AGENCY_SALES_MANAGER ? (
          <Text tier={Tier.SECONDARY}>
            {
              'Sales manager have automatically access to all project can invite new team members and assign projects to sales agents.'
            }
          </Text>
        ) : null}
        {inviteForm.values.organisationRole === OrganisationRole.AGENCY_SALES_AGENT ? (
          <Text tier={Tier.SECONDARY}>
            {
              'Sales agents have access only to assigned projects, cannot invite new team members or assign projects.'
            }
          </Text>
        ) : null}
      </Form>
    </Modal>
  );
};
