import dayjs from 'dayjs';
import gql from 'fraql';
import { createEntity } from 'urql-computed-exchange';

export default createEntity('Report', {
  dateRange: {
    dependencies: gql`
      fragment _ on Report {
        startingDate
        endingDate
      }
    `,
    resolver: (report) => ({
      start: dayjs(report.startingDate),
      end: dayjs(report.endingDate),
    }),
  },
});
