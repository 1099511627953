import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import urlJoin from 'url-join';
import { gql } from 'urql';

import { LeadFromServer } from '~/pods/crm/types';
import { shouldUseShowcaseData, showcaseTokenData } from '~/pods/insights/showcase';
import {
  FetchError,
  InsightsLead,
  InsightsSession,
  InsightsUnit,
  ReportType,
  TokenData,
  TokenDataFromServer,
} from '~/pods/insights/types';
import { useMosaicQuery } from '~/utils';

import { useFetchData } from '../use-fetch-data';
import { mergeIntoEstate, transformLeads, transformSessions } from '../use-load-insights';
import { EstateWithSessions } from '../use-load-insights/utils';
import { transformEstate } from './translators';

interface ListingQueryVariables {
  publicToken: string;
}

interface ListingQueryData {
  units: Array<InsightsUnit>;
}

const ListingQuery = gql`
  query InsightsListingQuery($publicToken: ID!) {
    listing(publicToken: $publicToken) {
      units {
        id
        name: reference
        availability
        soldAt
        bedrooms
        orientation
        price @computed(type: Unit)
      }
    }
  }
`;

interface LoadInsightsError {
  isNotFound: boolean;
  isCritical: boolean;
  noToken?: boolean;
}

export interface UseLoadTokenDataOutput {
  isLoading: boolean;
  leads?: Array<InsightsLead>;
  sessions?: Array<InsightsSession>;
  estate?: EstateWithSessions;
  currency?: string;
  reportConfig?: {
    dateRange: {
      start: Dayjs;
      end: Dayjs;
    };
    locale: string;
  };
  error: LoadInsightsError;
}

function _buildError(
  reportError: FetchError,
  leadsError: FetchError,
  sessionsError: FetchError,
): LoadInsightsError {
  const isNotFound =
    reportError?.status === 404 || leadsError?.status === 404 || sessionsError?.status === 404;

  const isCritical =
    reportError?.status != null || leadsError?.status != null || sessionsError?.status != null;

  return {
    isCritical,
    isNotFound,
  };
}

export function useLoadTokenData(token?: string, type?: ReportType): UseLoadTokenDataOutput {
  if (token == null || type == null) {
    return {
      isLoading: false,
      error: {
        isCritical: false,
        isNotFound: false,
        noToken: true,
      },
    };
  }

  if (shouldUseShowcaseData(token)) {
    return showcaseTokenData(type);
  }

  const isWeekly = type === ReportType.WEEKLY;

  const tokenDataTransformer = useCallback(
    (tokenDataFromServer: TokenDataFromServer): TokenData => ({
      ...tokenDataFromServer.resource,
      dateRange: {
        start: dayjs(tokenDataFromServer.resource.range[0]),
        end: dayjs(tokenDataFromServer.resource.range[1]),
      },
      estate: transformEstate(tokenDataFromServer.resource.estate),
    }),
    [],
  );

  // Estate & report
  const tokenUrl = urlJoin(process.env.BACKOFFICE_HOST, '/api/public/v1/reports/', token);
  const [isLoadingReport, tokenData, reportError] = useFetchData(tokenUrl, {
    transform: tokenDataTransformer,
  });

  useLayoutEffect(() => {
    if (tokenData?.locale != null && tokenData?.locale != window.i18n.locale) {
      window.i18n.changeLocale(tokenData?.locale);
    }
  });

  // Leads
  const crmUrl = urlJoin(
    process.env.MOSAIC_HOST,
    '/public/v1/funnels/',
    tokenData?.estate.slug ?? '',
    `?token=${tokenData?.estate.authToken ?? ''}`,
  );

  const [isLoadingLeads, leads, leadsError] = useFetchData(crmUrl, {
    pause: tokenData != null,
    transform: (payload: { leads: Array<LeadFromServer> }) => transformLeads(payload.leads),
    config: {
      headers: {
        Accept: 'application/json',
      },
    },
  });

  // Sessions
  const sessionsUrl = urlJoin(
    process.env.ANALYTICS_HOST,
    '/api/v1/sessions',
    tokenData?.estate.slug ?? '',
    `?from=${tokenData?.dateRange.end.subtract(isWeekly ? 30 : 60, 'day')}&to=${
      tokenData?.dateRange.end
    }`,
  );
  const [isLoadingSessions, sessions, sessionsError] = useFetchData(sessionsUrl, {
    pause: tokenData != null,
    transform: transformSessions,
    config: {
      headers: {
        Authorization: `Token ${tokenData?.estate.authToken}`,
      },
    },
  });

  const listingResult = useMosaicQuery<ListingQueryData, ListingQueryVariables>({
    query: ListingQuery,
    variables: { publicToken: tokenData?.estate.slug },
  });

  const estateWithSessions = useMemo(
    () => mergeIntoEstate(tokenData?.estate, listingResult.data?.units, sessions),
    [tokenData?.estate?.id, sessions],
  );

  return {
    isLoading: isLoadingLeads || isLoadingSessions || isLoadingReport,
    leads: leads,
    sessions: sessions,
    estate: estateWithSessions,
    currency: tokenData?.estate.units[0]?.price.currency,
    reportConfig:
      tokenData != null
        ? {
            dateRange: tokenData.dateRange,
            locale: tokenData.locale,
          }
        : undefined,
    error: _buildError(reportError, leadsError, sessionsError),
  };
}
