import {
  Button,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Label,
  Margin,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { OrganisationRole, ShowroomAddress } from '~/types';
import { getCountryName, useAuth } from '~/utils';

interface AddressBoxDisplayProps {
  address: ShowroomAddress;
  onClickEdit: VoidFunction;
  onClickDelete: VoidFunction;
}

export const AddressBoxDisplay = ({
  address,
  onClickEdit,
  onClickDelete,
}: AddressBoxDisplayProps) => {
  const { city, zipCode, street, countryCode } = address;
  const { user } = useAuth();
  const isUserMember =
    user?.role === OrganisationRole.MEMBER || user?.role === OrganisationRole.AGENCY_SALES_AGENT;

  return (
    <Fragment>
      <Flex justify={FlexJustify.SPACE_BETWEEN}>
        <FlexItem>
          <Label>{city}</Label>
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem>
              <Button
                disabled={isUserMember}
                onClick={onClickEdit}
                leading={<Icon name="edit-2" />}
                tier={Tier.SECONDARY}
                size={Size.SMALL}
                style={{ display: 'inline' }}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                disabled={isUserMember}
                onClick={onClickDelete}
                leading={<Icon name="trash-2" />}
                tier={Tier.SECONDARY}
                size={Size.SMALL}
                style={{ display: 'inline' }}
              />
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
      <Margin size={{ top: Size.EXTRA_SMALL }}>
        <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
          <FlexItem>
            <Text>{street}</Text>
          </FlexItem>
          <FlexItem>
            <Text>{`${zipCode} ${city}`}</Text>
          </FlexItem>
          <FlexItem>
            <Text>{getCountryName(countryCode)}</Text>
          </FlexItem>
        </Flex>
      </Margin>
    </Fragment>
  );
};
