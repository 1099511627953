import sv from '@drawbotics/drylus-style-vars';
import {
  CheckboxFilter,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  PanelSection,
  SegmentedControl,
  SegmentedControlItem,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import dayjs, { Dayjs, OpUnitType, min as minDate } from 'dayjs';
import { css } from 'emotion';
import { zipWith } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { Campaign } from '~/pods/insights/types';
import { getEmptyDates, getGranularity, toDataPoints } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { HorizontalLegend } from './HorizontalLegend';

const tt = createTranslate('pods.insights.routes.campaigns');

const styles = {
  chartContainer: css`
    height: 400px;
    width: 100%;
  `,
};

interface CustomTooltipProps extends SliceTooltipProps {
  metric: 'visits' | 'leads' | 'conversionRate';
  granularity: OpUnitType;
}

export const CustomTooltip = ({ slice, metric, granularity }: CustomTooltipProps) => {
  const month = dayjs(slice.points[0].data.x)
    .locale(window.i18n.locale)
    .format(granularity === 'month' ? 'MMMM' : 'MMMM DD');
  const highestViews: string | undefined =
    slice.points.length > 1
      ? slice.points.reduce((memo, point) => (memo.data.y > point.data.y ? memo : point)).id
      : undefined;
  return (
    <div
      style={{
        borderRadius: sv.defaultBorderRadius,
        padding: sv.paddingExtraSmall,
        background: sv.neutralDarkest,
      }}>
      <Text inversed size={Size.SMALL} style={{ textTransform: 'capitalize' }}>
        {month}
      </Text>
      {slice.points
        .sort((a, b) => Number(b.data.y) - Number(a.data.y))
        .map((point) => (
          <div key={point.id} style={{ marginTop: 4 }}>
            <Flex align={FlexAlign.START}>
              <FlexItem>
                <div
                  style={{
                    height: 3,
                    width: 12,
                    borderRadius: 12,
                    marginTop: 6,
                    backgroundColor: point.serieColor,
                  }}
                />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem flex>
                <div style={{ marginBottom: 4 }}>
                  <Text light inversed size={Size.SMALL}>
                    {point.serieId}
                  </Text>
                </div>
                <Text size={Size.SMALL} shade={Shade.LIGHT}>{`${point.data.y} ${tt(
                  `keys.${metric}`,
                  { count: point.data.y as number },
                )}`}</Text>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_LARGE} />
              {highestViews === point.id ? (
                <FlexItem>
                  <Icon style={{ color: sv.white }} name="award" />
                </FlexItem>
              ) : null}
            </Flex>
          </div>
        ))}
    </div>
  );
};

function _getDataPoints(
  campaign: Campaign,
  startDate: Dayjs,
  granularity: OpUnitType,
  metric: 'visits' | 'leads',
) {
  const dataSet = campaign[metric];
  const emptyDates = getEmptyDates(
    { start: startDate.startOf(granularity), end: dayjs() },
    granularity,
    'YYYY-MM-DD',
  );

  const dataSummedByDate = dataSet.reduce((memo, d) => {
    const date = d.startOf(granularity).format('YYYY-MM-DD');
    return { ...memo, [date]: (memo[date] ?? 0) + 1 };
  }, emptyDates);
  return toDataPoints(dataSummedByDate);
}

function _computeDataForMetric(
  campaign: Campaign,
  startDate: Dayjs,
  granularity: OpUnitType,
  metric: 'visits' | 'leads' | 'conversionRate',
) {
  if (metric === 'conversionRate') {
    const leadsPoints = _getDataPoints(campaign, startDate, granularity, 'leads');
    const visitsPoints = _getDataPoints(campaign, startDate, granularity, 'visits');

    return zipWith(leadsPoints, visitsPoints, (lead, visit) => {
      const ratio = (lead.y / (visit.y === 0 ? 1 : visit.y)) * 100;

      return {
        x: lead.x,
        y: ratio,
      };
    });
  }

  return _getDataPoints(campaign, startDate, granularity, metric);
}

interface CampaignsOverTimeProps {
  campaigns: Array<Campaign>;
}

export const CampaignsOverTime = ({ campaigns }: CampaignsOverTimeProps) => {
  const [selectedCampaignsIds, setSelectedCampaignsIds] = useState(campaigns.map((c) => c.token));
  const [selectedMetric, setSelectedMetric] = useState<'visits' | 'leads' | 'conversionRate'>(
    'visits',
  );

  // If a campaign is added, it should be selected by default, but only if it's not in the list yet
  useEffect(() => {
    const lastCampaignToken = campaigns[campaigns.length - 1]?.token;

    if (lastCampaignToken != null && !selectedCampaignsIds.includes(lastCampaignToken)) {
      setSelectedCampaignsIds([...selectedCampaignsIds, lastCampaignToken]);
    }
  }, [campaigns.length]);

  const selectedCampaigns = campaigns.filter((c) => selectedCampaignsIds.includes(c.token));

  const earliestDate = minDate(selectedCampaigns.map((c) => c.launchedAt));
  const daysOfData = dayjs().diff(earliestDate, 'day');
  const granularity = getGranularity(daysOfData);

  const { chartData } = useMemo(() => {
    const data = selectedCampaigns.map((c) => {
      return {
        id: c.name,
        color: c.color,
        data: _computeDataForMetric(c, earliestDate, granularity, selectedMetric),
      };
    });

    return { chartData: data };
  }, [selectedCampaignsIds, selectedMetric]);

  const labelForMetric = {
    visits: tt('traffic_over_time'),
    leads: tt('leads_over_time'),
    conversionRate: tt('conversion_rate_over_time'),
  };

  return (
    <Fragment>
      <Flex style={{ width: '100%' }} justify={FlexJustify.END}>
        <FlexItem>
          <CheckboxFilter
            clearLabel={tt('select_all')}
            onClear={() => setSelectedCampaignsIds(campaigns.map((campaign) => campaign.token))}
            label={tt('campaigns')}
            onChange={setSelectedCampaignsIds}
            options={campaigns.map((campaign) => ({
              label: campaign.name,
              value: campaign.token,
            }))}
            values={selectedCampaignsIds}
          />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          <SegmentedControl>
            <SegmentedControlItem
              text={tt('visitors')}
              onClick={() => setSelectedMetric('visits')}
              active={selectedMetric === 'visits'}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('leads')}
              onClick={() => setSelectedMetric('leads')}
              active={selectedMetric === 'leads'}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('conversion_rate')}
              onClick={() => setSelectedMetric('conversionRate')}
              active={selectedMetric === 'conversionRate'}></SegmentedControlItem>
          </SegmentedControl>
        </FlexItem>
      </Flex>
      <PanelSection title={labelForMetric[selectedMetric]}>
        <div className={styles.chartContainer}>
          {daysOfData < 2 ? (
            <LineChartPlaceholder height={400} message={tt('insufficient_data')} />
          ) : (
            <ResponsiveLine
              margin={{ left: 40, top: 20, bottom: 30, right: 35 }}
              data={chartData}
              enableGridX={false}
              enableGridY={true}
              pointSize={9}
              pointColor="white"
              pointBorderWidth={3}
              pointBorderColor={{ from: 'serieColor' }}
              colors={{ datum: 'color' }}
              isInteractive
              enableSlices="x"
              sliceTooltip={(props) => (
                <CustomTooltip {...props} metric={selectedMetric} granularity={granularity} />
              )}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
              }}
              axisBottom={{
                format: '%b %d',
                tickSize: 0,
                tickValues: granularity == 'month' ? 'every 1 months' : 'every 1 weeks',
              }}
              yScale={{
                type: 'linear',
                stacked: true,
              }}
              axisLeft={{
                tickSize: 0,
                format: (val) => (typeof val !== 'number' || !Number.isInteger(val) ? '' : val),
              }}
              theme={{
                axis: {
                  ticks: {
                    line: {
                      stroke: sv.colorTertiary,
                    },
                    text: {
                      fontSize: '10pt',
                      fontFamily: 'Rubik',
                      fontWeight: 400,
                      fill: sv.colorSecondary,
                    },
                  },
                },
                grid: {
                  line: {
                    strokeDasharray: '4 4',
                  },
                },
              }}
            />
          )}
        </div>
        <Margin size={Size.DEFAULT} />
        <HorizontalLegend
          items={selectedCampaigns.map(({ name, color }) => ({ label: name, color }))}
        />
      </PanelSection>
    </Fragment>
  );
};
