import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Label,
  Margin,
  Padding,
  Shade,
  Size,
  Tier,
  Tooltip,
} from '@drawbotics/react-drylus';
import { BarItemProps, ResponsiveBar, Value } from '@nivo/bar';
import { css } from 'emotion';
import React, { Fragment, useEffect, useState } from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { TractionBarData } from '~/pods/insights/utils';

const MAX_ITEM_PER_PAGE = 5;

const styles = {
  chartContainer: css`
    width: 100%;
    height: 210px;
    text-align: center;
  `,
};

export enum BarSizes {
  STANDARD = 'STANDARD',
  HALF = 'HALF',
  THIRD = 'THIRD',
}

const barSizeToPosition = {
  STANDARD: 495,
  HALF: 205,
  THIRD: 306,
};

function _shortenValue(value: Value): string {
  return value.toLocaleString().substring(0, 55) + '...';
}

function _getYCoordinates(barNumber: number): Record<number, number> {
  const coordinates: Record<number, number> = {};
  // building y coordinates in reversed order to match nivo data order
  // 9 and 39 are constants coming from nivo, they should change when gap / padding between bars change
  for (let index = 0; index < barNumber; index++) {
    coordinates[index] = 9 + (barNumber - index - 1) * 39;
  }

  return coordinates;
}

function _getBar(barSize: BarSizes, barNumber: number) {
  const BarWithLabels = ({ x, y, width, color, label, data }: BarItemProps) => {
    // when there is less bar than the max allowed, we need to manually position the bars to stack them on top
    const yCoordinate = barNumber < MAX_ITEM_PER_PAGE ? _getYCoordinates(barNumber)[data.index] : y;
    return (
      <g>
        <rect width={width} height={32} y={yCoordinate} rx={4} fill={color} />;
        <g transform={`translate(${x + 5}, ${yCoordinate + 15})`} key={data.indexValue}>
          <text
            textAnchor="start"
            dominantBaseline="middle"
            style={{
              fontWeight: 400,
              fill: sv.neutralDarkest,
              fontSize: 14,
            }}>
            {data.indexValue.toString().length > 55
              ? _shortenValue(data.indexValue)
              : data.indexValue}
          </text>
        </g>
        <g transform={`translate(${barSizeToPosition[barSize]},${yCoordinate + 20})`}>
          <text
            style={{
              fontWeight: 500,
              fill: sv.neutralDarkest,
              fontSize: 12,
            }}>
            {label}
          </text>
        </g>
      </g>
    );
  };

  return BarWithLabels;
}

function _getFinalChartData(
  data: Array<TractionBarData>,
  page: number,
  isPaginationRequired: boolean,
): Array<TractionBarData> {
  if (isPaginationRequired) {
    const end = data.length - page * MAX_ITEM_PER_PAGE;
    const start = end - MAX_ITEM_PER_PAGE < 0 ? 0 : end - MAX_ITEM_PER_PAGE;
    return data.slice(start, end);
  } else {
    return data;
  }
}

interface HorizontalTractionBarChartProps {
  data: Array<TractionBarData>;
  label: string;
  tooltip?: string;
  barSize?: BarSizes;
}

export const HorizontalTractionBarChart = ({
  data,
  label,
  tooltip,
  barSize = BarSizes.STANDARD,
}: HorizontalTractionBarChartProps) => {
  const [page, setPage] = useState(0);
  const isPaginationRequired = data.length > MAX_ITEM_PER_PAGE;
  const sortedData = data.sort((a, b) => a.visitCount - b.visitCount);
  const maxValue = sortedData.length !== 0 ? sortedData[sortedData.length - 1].visitCount * 1.3 : 1;
  const finalChartData = _getFinalChartData(sortedData, page, isPaginationRequired);

  useEffect(() => {
    setPage(0);
  }, [data.length]);

  return (
    <Padding size={Size.EXTRA_SMALL}>
      <Margin size={{ top: Size.EXTRA_SMALL, bottom: Size.EXTRA_SMALL, left: Size.DEFAULT }}>
        <Flex justify={FlexJustify.START} style={{ minHeight: '32px' }}>
          <FlexItem flex={tooltip == null}>
            <Label>{label}</Label>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          {tooltip != null ? (
            <FlexItem flex>
              <Tooltip content={tooltip}>
                <Icon shade={Shade.MEDIUM} name="info" />
              </Tooltip>
            </FlexItem>
          ) : null}
          {isPaginationRequired ? (
            <Fragment>
              <FlexItem>
                <Margin size={{ horizontal: Size.EXTRA_SMALL }}>
                  <Button
                    size={Size.SMALL}
                    leading={<Icon name="arrow-left" />}
                    tier={Tier.TERTIARY}
                    disabled={page === 0}
                    onClick={() => setPage(page - 1)}
                  />
                </Margin>
              </FlexItem>
              <FlexItem>
                <Margin size={{ horizontal: Size.EXTRA_SMALL }}>
                  <Button
                    size={Size.SMALL}
                    leading={<Icon name="arrow-right" />}
                    tier={Tier.TERTIARY}
                    disabled={page + 1 >= Math.ceil(data.length / MAX_ITEM_PER_PAGE)}
                    onClick={() => setPage(page + 1)}
                  />
                </Margin>
              </FlexItem>
            </Fragment>
          ) : null}
        </Flex>
      </Margin>
      <div className={styles.chartContainer}>
        {data.length === 0 ? (
          <LineChartPlaceholder
            height={210}
            months={barSize === BarSizes.THIRD ? 5 : 10}
            message="No data to show"
          />
        ) : (
          <ResponsiveBar
            data={finalChartData}
            maxValue={maxValue}
            keys={['visitCount']}
            indexBy="data"
            layout="horizontal"
            barComponent={_getBar(barSize, finalChartData.length)}
            colors={({ data: { color } }) => color}
            margin={{ top: 5, right: 20, left: 15 }}
            // adjust _getYCoordinates if you change the padding
            padding={0.2}
            enableGridY={false}
            enableLabel={false}
            axisBottom={null}
            axisLeft={null}
            isInteractive={false}
            animate={false}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: '7pt',
                    fontWeight: 'lighter',
                    fill: sv.colorTertiary,
                  },
                },
              },
            }}
          />
        )}
      </div>
    </Padding>
  );
};
