import { CustomLayerProps } from '@nivo/line';
import dayjs from 'dayjs';
import { last } from 'lodash';
import React from 'react';

export const CustomLine = ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) => {
  return series.map(({ id, data, color }) => {
    const lastPoint = last(data);
    const hasTemporaryData =
      lastPoint?.data.x != null ? dayjs().isSame(dayjs(lastPoint?.data.x), 'month') : false;
    const points = hasTemporaryData ? data.slice(0, -1) : data;

    const lastPointLineData =
      lastPoint != null ? { x: xScale(lastPoint.data.x!), y: yScale(lastPoint.data.y!) } : null;
    return (
      <g key={id}>
        {points.length > 0 ? (
          <path
            d={lineGenerator(
              points.map((d) => ({
                x: xScale(d.data.x!),
                y: yScale(d.data.y!),
              })),
            )}
            fill="none"
            stroke={color}
            style={{
              strokeWidth: 3,
            }}
          />
        ) : null}
        {hasTemporaryData && lastPoint != null ? (
          <path
            d={lineGenerator(
              last(points) != null
                ? [
                    {
                      x: xScale(last(points)!.data.x!),
                      y: yScale(last(points)!.data.y!),
                    },
                    lastPointLineData!,
                  ]
                : [lastPointLineData!],
            )}
            fill="none"
            stroke={color}
            style={{
              strokeDasharray: '6, 6',
              strokeWidth: 3,
            }}
          />
        ) : null}
      </g>
    );
  });
};
