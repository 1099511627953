import { Label, Paragraph, Tier, Title } from '@drawbotics/react-drylus';
import { Dayjs } from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { InsightsEstate, InsightsLead, InsightsSession, Position } from '../../types';
import {
  ConversionRate,
  DateHeader,
  DateHeaderSize,
  Footer,
  LeastVisitedUnits,
  MostVisitedTypologies,
  MostVisitedUnits,
  NumberOfLeads,
  NumberOfVisits,
  Page,
  PageGrid,
  PageGridItem,
  Report,
  Text,
  TopCities,
  TotalRevenue,
  UnitsSold,
  VisitedTypologies,
  VisitsChart,
} from './components';

const t = createTranslate('pods.insights_reports.routes.weekly_report');
const tt = createTranslate('pods.insights_reports.components.charts');

const styles = {
  description: css`
    width: 100%;
    position: absolute;
    top: 2.5cm;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  charts: css`
    position: absolute;
    top: 7.5cm;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  extraHeader: css`
    position: absolute;
    top: 1.3cm;
    right: 2.8cm;
    text-align: right;
    display: flex;
    flex-direction: column;
  `,
  chartsP2: css`
    position: absolute;
    top: 4cm;
    bottom: 0;
    left: 0;
    right: 0;
  `,
};

interface WeeklyReportProps {
  projectName: string;
  units: InsightsEstate['units'];
  sessions: Array<InsightsSession>;
  leads: Array<InsightsLead>;
  currency: string;
  reportConfig: {
    dateRange: {
      start: Dayjs;
      end: Dayjs;
    };
    locale: string;
  };
}

export const WeeklyReport = ({
  projectName,
  units,
  sessions,
  leads,
  reportConfig,
  currency,
}: WeeklyReportProps) => {
  const { dateRange } = reportConfig;
  return (
    <Report>
      <Page
        dateHeader={<DateHeader size={DateHeaderSize.SMALL} dateRange={dateRange} />}
        footer={<Footer text={t('footer', { name: projectName })} />}>
        <div className={styles.description}>
          <Label style={{ fontSize: '12pt', marginBottom: '0.3cm' }}>{t('title')}</Label>
          <Title size={2} noMargin style={{ marginBottom: '0.6cm' }}>
            {projectName}
          </Title>
          <Paragraph style={{ width: '70%', display: 'block' }}>{t('explanation')}</Paragraph>
        </div>
        <div className={styles.charts}>
          <PageGrid gridSize={0.015}>
            <PageGridItem position={Position.fromSquare(0, 4, 20, 14)}>
              <ConversionRate sessions={sessions} leads={leads} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 26, 20, 14)}>
              <VisitsChart sessions={sessions} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 48, 20, 14)}>
              <TotalRevenue units={units} dateRange={dateRange} currency={currency} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(16, 4, 42, 26)}>
              <NumberOfVisits
                sessions={sessions}
                dateRange={{ start: dateRange.end.subtract(30, 'day'), end: dateRange.end }}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(16, 48, 20, 14)}>
              <UnitsSold
                units={units}
                dateRange={dateRange}
                title={tt('units_sold.title_weekly')}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(32, 48, 20, 14)}>
              <TopCities />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(48, 48, 20, 14)}>
              <MostVisitedTypologies
                units={units}
                dateRange={dateRange}
                title={tt('most_visited_typologies.title_weekly')}
              />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(44, 4, 42, 18)}>
              <NumberOfLeads
                leads={leads}
                dateRange={{ start: dateRange.end.subtract(30, 'day'), end: dateRange.end }}
              />
            </PageGridItem>
          </PageGrid>
        </div>
      </Page>
      <Page
        dateHeader={<DateHeader size={DateHeaderSize.SMALL} dateRange={dateRange} />}
        footer={<Footer text={t('footer', { name: projectName })} />}>
        <div className={styles.extraHeader}>
          <Text tier={Tier.TERTIARY}>{t('title')}</Text>
          <Text tier={Tier.TERTIARY}>{projectName}</Text>
          <Text tier={Tier.TERTIARY}>{t('page', { page: 2, of: 2 })}</Text>
        </div>
        <div className={styles.chartsP2}>
          <PageGrid gridSize={0.015}>
            <PageGridItem position={Position.fromSquare(0, 4, 20, 20)}>
              <MostVisitedUnits units={units} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 26, 20, 20)}>
              <LeastVisitedUnits units={units} dateRange={dateRange} />
            </PageGridItem>
            <PageGridItem position={Position.fromSquare(0, 48, 20, 20)}>
              <VisitedTypologies
                units={units}
                dateRange={dateRange}
                chartHeight={60}
                title={tt('visited_typologies.title_weekly')}
              />
            </PageGridItem>
          </PageGrid>
        </div>
      </Page>
    </Report>
  );
};
