import React from 'react';

import { InsightsSession } from '~/pods/insights/types';
import { generateBarChartData } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { BarSizes, HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction');

interface CityChartProps {
  sessions: Array<InsightsSession>;
}

export const CityChart = ({ sessions }: CityChartProps) => {
  const filtereData = sessions.filter((s) => s.ipData?.city != null);
  const cityData = generateBarChartData(
    filtereData.map((s) => s.ipData ?? {}),
    'city',
  );

  return <HorizontalTractionBarChart label={tt('city')} data={cityData} barSize={BarSizes.THIRD} />;
};
