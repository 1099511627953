import { Leads } from './leads';
import { Sessions } from './sessions';
import { Period, SimulationDescription } from './types';
import { Units } from './units';

export function runSimulation(description: SimulationDescription, months: Array<Period>) {
  const units = new Units(description.units);
  const sessions = new Sessions(units);
  const leads = new Leads();

  const initialData = { units, sessions, leads };

  const result = months.reduce((memo, month, i) => {
    const dateRange = {
      start: description.dateRange.start.add(
        description.dateRange.increment.amount * i,
        description.dateRange.increment.units,
      ),
      end: description.dateRange.start.add(
        description.dateRange.increment.amount * (i + 1),
        description.dateRange.increment.units,
      ),
    };

    return month(memo, dateRange);
  }, initialData);

  return {
    units: result.units.units,
    leads: result.leads.leads,
    sessions: result.sessions.sessions,
  };
}
