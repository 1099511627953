import get from 'lodash/get';

import { Unit } from '~/pods/listing-manager/types';

import { SortDescription } from './types';

function compareUnits(a: Unit, b: Unit, key: string): number {
  return String(get(a, key)).localeCompare(String(get(b, key)), undefined, { numeric: true });
}

export function sortUnits(units: Array<Unit>, sortedBy?: SortDescription): Array<Unit> {
  if (sortedBy == null) {
    // todo: write a util to return a srted copy of an array
    return units.slice().sort((a, b) => a.id.localeCompare(b.id));
  }
  return sortedBy.direction === 'asc'
    ? units.slice().sort((a, b) => compareUnits(a, b, sortedBy.path))
    : units.slice().sort((b, a) => compareUnits(a, b, sortedBy.path));
}
