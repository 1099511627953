import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';
import { AssetSchema } from '../asset';

export const TextImageSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.TEXT_IMAGE),
  title: ze.optional(z.string()),
  position: z.number(),
  assets: z.array(AssetSchema),
  active: ze.defaults(z.boolean(), false),
  description: ze.optional(z.string()),
});

export type TextImageSectionData = z.infer<typeof TextImageSectionSchema>;

export class TextImageSection extends ze.ClassFrom(TextImageSectionSchema) {
  public static fromData(data: TextImageSectionData): TextImageSection {
    return ze.validate(data, TextImageSection);
  }

  public static is(section: any): section is TextImageSection {
    return section instanceof this;
  }

  get isActive() {
    return this.active;
  }

  public toData(): TextImageSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
