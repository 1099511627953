import React, { useEffect } from 'react';

import { EnhancedRangeInput } from '~/pods/crm/components';
import { LeadsFilterTypes } from '~/pods/crm/types';
import { displaySurface } from '~/utils';

interface SurfaceFilterProps {
  surfaceUnit: 'sqm' | 'sqft';
  max: number;
  min: number;
  isLoading?: boolean;
  filter: LeadsFilterTypes['surface'];
  step: number;
}

export const SurfaceFilter = ({
  filter,
  surfaceUnit = 'sqm',
  min,
  max,
  isLoading,
  step,
}: SurfaceFilterProps) => {
  const renderNumberInput = (v: number | string | '' | '-') => {
    return displaySurface(String(v), surfaceUnit);
  };

  useEffect(() => {
    if (filter.value) {
      const [currentMin, currentMax] = filter.values ?? [];

      if (Number(currentMin) == min && Number(currentMax) == max) {
        filter.set(null);
      }
    }
  }, [filter.value]);

  return (
    <EnhancedRangeInput
      min={min}
      max={max}
      onChange={(range) => filter.set([range[0].toString(), range[1].toString()])}
      values={filter.values ? [Number(filter.values[0]), Number(filter.values[1])] : [min, max]}
      renderValue={renderNumberInput}
      step={step}
      isLoading={isLoading}
    />
  );
};
