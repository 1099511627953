import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Drawer,
  DrylusProvider,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  Padding,
  Separator,
  Size,
  Tier,
  useThemeColor,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import startCase from 'lodash/startCase';
import React, { Fragment, ReactNode, useState } from 'react';

import { Campaign, LeadsFilterTypes } from '~/pods/crm/types';
import {
  getClosestRange,
  getRangeStep,
  projectPriceRange,
  projectSurfaceRange,
} from '~/pods/crm/utils';
import { PortalData } from '~/pods/multidiffusion/domain';
import { Currency } from '~/types';
import { createTranslate } from '~/utils';

import { LeadsCounter } from '../../LeadsCounter';
import { BudgetFilter, PerksFilter, PurchaseGoalFilter, SourceFilter, SurfaceFilter } from './';
import { MarketingCampaignFilter } from './MarketingCampaignFilter';
import { PortalFilter } from './PortalFilter';
import { FilterSection } from '..';

const styles = {
  otherFilter: css`
    margin-left: calc(${sv.paddingExtraLarge} * -1);
    padding-left: ${sv.paddingExtraLarge};
    margin-right: calc(${sv.paddingExtraLarge} * -1);
    padding-right: ${sv.paddingExtraLarge};
    &:hover {
      background: ${sv.neutral};
      cursor: pointer;
    }
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  /*
    Fix rendering issue where chrome rounds 1px to the nearest value in HiDPI screens
    and ends up rendering some lines as 2px and others as 1px
  */
  separator: css`
    height: 0.5pt;
  `,
  activeOthersFilter: css`
    background: ${sv.neutralDarker} !important;
    color: ${sv.white} !important;
    box-shadow: inset 0 0 0 1px ${sv.neutralDarker} !important;
  `,
  themeProvider: css`
    width: 100%;
    height: 100%;
  `,
  drawerContent: css`
    background-color: ${sv.white};
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: ${sv.defaultPadding};
  `,
};

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.other_filters');
const tl = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');

const MarginedSeparator = () => {
  return (
    <Margin size={{ vertical: Size.SMALL }}>
      <Separator className={cx(styles.separator)} style={{ background: sv.neutral }} />
    </Margin>
  );
};

interface SavedFilterHeaderProps {
  title: string;
  onClickDelete: VoidFunction;
}

export const SavedFilterHeader = ({ title, onClickDelete }: SavedFilterHeaderProps) => {
  return (
    <Flex justify={FlexJustify.SPACE_BETWEEN}>
      <FlexItem>{title}</FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <Icon name="trash-2" onClick={onClickDelete} style={{ color: `${sv.colorSecondary}` }} />
      </FlexItem>
    </Flex>
  );
};

interface FilterSelectionControlProps {
  onClickSave: VoidFunction;
  onClickCancel: VoidFunction;
}

export const FilterSelectionControl = ({
  onClickSave,
  onClickCancel,
}: FilterSelectionControlProps) => {
  return (
    <Flex justify={FlexJustify.END}>
      <FlexItem>
        <Button size={Size.SMALL} tier={Tier.TERTIARY} onClick={onClickCancel}>
          {tt('cancel_button')}
        </Button>
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <Button size={Size.SMALL} category={Category.INFO} onClick={onClickSave}>
          {tt('save_button')}
        </Button>
      </FlexItem>
    </Flex>
  );
};

interface FilterSelectorProps {
  title: string;
  onClick: VoidFunction;
}

export const FilterSelector = ({ title, onClick }: FilterSelectorProps) => {
  return (
    <div className={styles.otherFilter} onClick={onClick}>
      <Padding size={{ vertical: Size.SMALL }}>{startCase(title)}</Padding>
    </div>
  );
};

interface FilterProps {
  isActive: boolean;
  isSelected: boolean;
  children: ReactNode;
  title: string;
  onClickDelete: VoidFunction;
  onClickSave: VoidFunction;
  onClickCancel: VoidFunction;
}

export const Filter = ({
  isActive,
  isSelected,
  children,
  title,
  onClickSave,
  onClickCancel,
  onClickDelete,
}: FilterProps) => {
  return (
    <Fragment>
      {isActive ? (
        <Margin size={{ top: Size.SMALL, bottom: Size.EXTRA_SMALL }}>
          <SavedFilterHeader title={title} onClickDelete={onClickDelete} />
        </Margin>
      ) : null}
      {isActive || isSelected ? <Margin size={{ bottom: Size.SMALL }}>{children}</Margin> : null}
      {isSelected ? (
        <Margin size={{ top: Size.EXTRA_SMALL }}>
          <FilterSelectionControl onClickSave={onClickSave} onClickCancel={onClickCancel} />
        </Margin>
      ) : null}
    </Fragment>
  );
};

interface OtherFiltersProps {
  campaigns?: Array<Campaign>;
  surfaceUnit: 'sqm' | 'sqft';
  filters: Pick<
    LeadsFilterTypes,
    | 'perks'
    | 'purchaseGoal'
    | 'sources'
    | 'budget'
    | 'surface'
    | 'marketingCampaign'
    | 'portalSlugs'
  >;
  budgetCurrency: Currency;
  leadCount?: { ownedLeadCount: number; totalLeadCount: number };
  portals: Array<PortalData>;
}

export const OtherFilters = ({
  filters,
  surfaceUnit,
  budgetCurrency,
  campaigns,
  leadCount,
  portals,
}: OtherFiltersProps) => {
  const themeColor = useThemeColor();

  const [showOtherFiltersDrawer, setShowOtherFiltersDrawer] = useState(false);

  const { perks, purchaseGoal, sources, budget, surface, marketingCampaign, portalSlugs } = filters;

  const activeFiltersCounter = Object.values({
    perks,
    purchaseGoal,
    sources,
    budget,
    surface,
    marketingCampaign,
    portalSlugs,
  }).reduce((previousValue, filter) => {
    return filter.value == null ? previousValue : previousValue + 1;
  }, 0);

  const minBudget = projectPriceRange[0];
  const maxBudget = projectPriceRange[1];
  const minSurface = projectSurfaceRange[0];
  const maxSurface = projectSurfaceRange[1];

  const zeroListingPrices = maxBudget === 0 && minBudget === 0;
  const zeroListingSurfaces = maxSurface === 0 && minSurface === 0;
  const budgetStep = zeroListingPrices ? 50000 : getRangeStep(maxBudget, minBudget, 10);
  const budgetRange = zeroListingPrices
    ? [0, 2000000]
    : getClosestRange(maxBudget, minBudget, budgetStep);
  const surfaceStep = zeroListingSurfaces ? 25 : getRangeStep(maxSurface, minSurface, 10);
  const surfaceRange = zeroListingSurfaces
    ? [0, 250]
    : getClosestRange(maxSurface, minSurface, surfaceStep);

  const drawerContent = (
    <Fragment>
      <MarginedSeparator />

      <FilterSection title={tt('titles.budget')} filter={budget}>
        <BudgetFilter
          min={budgetRange[0]}
          max={budgetRange[1]}
          step={budgetStep}
          currency={budgetCurrency}
          filter={budget}
        />
      </FilterSection>

      <MarginedSeparator />

      <FilterSection title={tt('titles.surface')} filter={surface}>
        <SurfaceFilter
          min={surfaceRange[0]}
          max={surfaceRange[1]}
          step={surfaceStep}
          surfaceUnit={surfaceUnit}
          filter={surface}
        />
      </FilterSection>

      <MarginedSeparator />

      <FilterSection title={tt('titles.source')} filter={sources}>
        <SourceFilter filter={sources} />
      </FilterSection>

      <MarginedSeparator />

      <FilterSection title={tt('titles.portal')} filter={portalSlugs}>
        <PortalFilter portals={portals} filter={portalSlugs} />
      </FilterSection>

      <MarginedSeparator />

      <FilterSection title={tt('titles.marketing_campaign')} filter={marketingCampaign}>
        <MarketingCampaignFilter campaigns={campaigns!} filter={marketingCampaign} />
      </FilterSection>

      <MarginedSeparator />

      <FilterSection title={tt('titles.perks')} filter={perks}>
        <PerksFilter filter={perks} />
      </FilterSection>
      <MarginedSeparator />

      <FilterSection title={tt('titles.purchase_goal')} filter={purchaseGoal}>
        <PurchaseGoalFilter filter={purchaseGoal} />
      </FilterSection>
    </Fragment>
  );

  return (
    <Fragment>
      <Button
        onClick={() => setShowOtherFiltersDrawer(true)}
        trailing={<Icon name="plus" />}
        tier={Tier.SECONDARY}
        className={cx(styles.noWrap, {
          [styles.activeOthersFilter]: activeFiltersCounter > 0,
        })}>
        {tl('titles.other')} {activeFiltersCounter > 0 ? `(${activeFiltersCounter})` : ''}
      </Button>
      <Drawer
        // className={cx(styles.drawer)}
        visible={showOtherFiltersDrawer}
        asOverlay
        onClickClose={() => setShowOtherFiltersDrawer(false)}
        /*
      scroll was cutting the borders around the range input cursors
      both overflow would need to be set as overflow: visible
      but we would lose the scroll bar when an user opens many filters
    */
        raw>
        <DrylusProvider className={cx(styles.themeProvider)} baseColor={themeColor}>
          <Flex
            className={cx(styles.drawerContent)}
            direction={FlexDirection.VERTICAL}
            align={FlexAlign.STRETCH}
            justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  <Button
                    size={Size.DEFAULT}
                    onClick={() => setShowOtherFiltersDrawer(false)}
                    tier={Tier.TERTIARY}
                    color={Color.PRIMARY}
                    leading={<Icon name="x" />}
                  />
                </FlexItem>
              </Flex>
              <Margin size={{ bottom: Size.DEFAULT }}>
                <LeadsCounter
                  ownedLeadCount={leadCount?.ownedLeadCount ?? 0}
                  totalLeadCount={leadCount?.totalLeadCount ?? 0}
                />
              </Margin>
              {drawerContent}
            </FlexItem>
          </Flex>
        </DrylusProvider>
      </Drawer>
    </Fragment>
  );
};
