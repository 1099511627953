import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { User } from '../../types';

interface UserQueryData {
  user: User;
}

const UserQuery = gql`
  query UserQuery {
    user {
      id
      firstName
      lastName
      locale
      fullName @computed(type: User)
      email
      profilePicture {
        id
        url
      }
      organisation: organisation {
        id
        name
        logo {
          url
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export function useFetchUser(): {
  isLoading: boolean;
  user?: User;
  refetch: VoidFunction;
  error?: ErrorType;
} {
  const res = useMosaicQuery<UserQueryData, null>({
    query: UserQuery,
  });

  return {
    isLoading: res.isLoading,
    user: res.data?.user,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
