import { CurrencyCode, HexColor, ID } from '~/types';

export enum FeeType {
  TAX = 'TAX',
  ADDITIONAL_FEE = 'ADDITIONAL_FEE',
}

export interface Fee {
  id: ID;
  coefficient: number;
  reference: string;
}

export interface Tax extends Fee {
  unitDistribution: number;
}

export interface FiscalSimulator {
  taxes: Array<Tax>;
  additionalFees: Array<Fee>;
  costSimulatorDisclaimer?: string;
}

export interface FiscalSimulatorClient extends FiscalSimulator {
  organisation: {
    name: string;
    logo: string;
    primaryColor: HexColor;
    secondaryColor: HexColor;
    currency: CurrencyCode;
  };
  coverImage?: string;
  projectName: string;
}
