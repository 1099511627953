import { TargetDomain, getLatestJwt } from '@drawbotics/auth';
import { Category, useAlert } from '@drawbotics/react-drylus';
import urlJoin from 'url-join';

import { createTranslate, useMosaicNavigation } from '~/utils';

import { LeadsQueryFilters } from './use-fetch-leads';

const tt = createTranslate('pods.crm.utils.hooks.export_leads');

export function useFetchLeadsExport(): {
  exportLeads: (filter: LeadsQueryFilters) => Promise<{ leadsExport: string } | undefined>;
} {
  const { showAlert } = useAlert();
  const { organisationToken } = useMosaicNavigation();

  const exportLeads = async (filter: LeadsQueryFilters) => {
    const res = await fetch(urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getLatestJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC)}`,
        'X-Organisation-Public-Token': organisationToken!,
      },
      body: JSON.stringify({
        query: `
          query LeadsExportQuery($filter: LeadFilterInput!) {
            leadsExport(filter: $filter)
          }
      `,
        variables: { filter },
      }),
    });

    if (!res.ok) {
      if (res)
        showAlert({
          text: tt('error'),
          category: Category.DANGER,
        });
      return;
    }

    const { data, errors } = await res.json();

    if (data == null) {
      if (errors?.[0].message === 'unauthorized') {
        showAlert({
          text: tt('error_unauthorized'),
          category: Category.DANGER,
        });
      }
      showAlert({
        text: tt('error'),
        category: Category.DANGER,
      });
      return;
    } else {
      showAlert({
        text: tt('success'),
        category: Category.SUCCESS,
      });

      return {
        leadsExport: data.leadsExport ?? '',
      };
    }
  };

  return { exportLeads };
}
