import sv from '@drawbotics/drylus-style-vars';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  report: css`
    min-width: 21cm;
    min-height: 100vh;
    background: ${sv.grey800};
  `,
};

interface ReportProps {
  children: ReactNode;
}

export const Report = ({ children }: Readonly<ReportProps>) => {
  return <div className={styles.report}>{children}</div>;
};
