import sv from '@drawbotics/drylus-style-vars';
import {
  Checkbox,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  Padding,
  SearchInput,
  Separator,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React, { useEffect, useState } from 'react';

import { ID, Id } from '~/types';
import { createTranslate } from '~/utils';

const styles = {
  root: css`
    white-space: nowrap;
    cursor: pointer;

    :hover {
      background-color: ${sv.neutralLight};
    }
  `,
  contentHoverableWrapper: css`
    width: max-content;

    position: absolute;
    z-index: 99999;

    padding-right: ${sv.marginExtraSmall};
    right: 100%;
    transform: translateY(-50%);
  `,
  content: css`
    background: ${sv.white};
    border-radius: ${sv.defaultBorderRadius};
    border: 1px solid ${sv.azure};
    box-shadow: ${sv.elevation2};
    transition: all ${sv.defaultTransitionTime} ${sv.bouncyTransitionCurve};
    padding: ${sv.paddingExtraSmall} 0 0 0;
  `,
  open: css`
    display: block;
  `,
  closed: css`
    display: none;
  `,

  searchInput: css`
    padding-top: ${sv.paddingExtraSmall};
    padding-bottom: ${sv.paddingSmall};
    padding-left: ${sv.paddingSmall};
    padding-right: ${sv.paddingSmall};
  `,

  panel: css`
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: ${sv.paddingExtraSmall} ${sv.paddingSmall};
    gap: ${sv.paddingExtraSmall};
    max-height: 300px;
    overflow: auto;
  `,
  clear: css`
    padding: ${sv.paddingExtraSmall} ${sv.paddingSmall};
    margin: ${sv.marginExtraSmall} 0;
    color: ${sv.blue};
    transition: ${sv.transitionShort};
    text-align: left;

    &:hover {
      cursor: pointer;
      background: ${sv.neutralLight};
    }
  `,

  option: css`
    width: 100%;

    > label {
      display: flex;
      justify-content: start;
      align-items: start;
    }

    label {
      flex: initial;
    }
  `,
  disabled: css`
    opacity: 0.75;
    cursor: not-allowed;

    > div {
      pointer-events: none;
    }
  `,
};

export type OrganisationWithProjects = {
  publicToken: ID;
  id: ID;
  name: string;
  projects: Array<{
    id: string;
    name: string;
  }>;
};

interface ProjectAccessProps {
  organisationWithProjects: OrganisationWithProjects;
  projectsAssignedToUser: Array<{ id: string }>;
  onChange: (action: 'assign' | 'unassign', projectId: Id) => void;
  disabled: boolean;
}

const tt = createTranslate('pods.settings.routes.company_team.components.project_access_options');

export const ProjectAccessOption = ({
  organisationWithProjects,
  projectsAssignedToUser,
  onChange,
}: ProjectAccessProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const projectIdsAssignedToUser = projectsAssignedToUser.map((p) => p.id);

  const [optimisticProjectIdsAssignedToUser, setOptimisticProjectIdsAssignedToUser] =
    useState(projectIdsAssignedToUser);

  useEffect(() => {
    setOptimisticProjectIdsAssignedToUser(projectIdsAssignedToUser);
  }, [JSON.stringify(projectsAssignedToUser)]);

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  const options = organisationWithProjects.projects.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  const filteredOptions =
    searchTerm != null && searchTerm != ''
      ? options.filter((option) => {
        const searchableLabel = option.label.toLowerCase().replace(/[^a-z0-9]/g, '');
        const transformedSearchTerm = searchTerm.toLowerCase().replace(/[^a-z0-9]/g, '');

        return searchableLabel.includes(transformedSearchTerm);
      })
      : options;

  const handleClearAll = () => {
    setOptimisticProjectIdsAssignedToUser([]);

    for (const project of projectsAssignedToUser) {
      onChange('unassign', project.id)
    }
  }

  return (
    <>
      <div
        onPointerOver={() => {
          setIsOpen(true);
        }}
        onPointerOut={() => {
          setIsOpen(false);
        }}
        className={cx(styles.root)}>
        <div
          className={cx([
            styles.contentHoverableWrapper,
            {
              [styles.open]: isOpen,
              [styles.closed]: !isOpen,
            },
          ])}
          onPointerOver={() => {
            setIsOpen(true);
          }}
          onPointerOut={() => {
            setIsOpen(false);
          }}>
          <div className={cx(styles.content)}>
            <div>
              <div className={cx(styles.searchInput)}>
                <SearchInput
                  value={searchTerm}
                  onChange={setSearchTerm}
                  placeholder={tt('search_project')}
                  size={Size.SMALL}
                  minimal
                />
              </div>
              <Separator />
              <div className={cx(styles.panel)}>
                {filteredOptions.map((option) => {
                  const isAssigned = optimisticProjectIdsAssignedToUser.includes(option.value);
                  return (
                    <Checkbox
                      onChange={() => {
                        setOptimisticProjectIdsAssignedToUser(
                          isAssigned
                            ? optimisticProjectIdsAssignedToUser.filter((i) => i != option.value)
                            : [...optimisticProjectIdsAssignedToUser, option.value],
                        );
                        onChange(isAssigned ? 'unassign' : 'assign', option.value);
                      }}
                      value={isAssigned}
                      key={option.value}
                      className={cx(styles.option)}>
                      {option.label}
                    </Checkbox>
                  );
                })}
              </div>
              <div className={styles.clear} onClick={handleClearAll}>
                {
                  tt('clear_all')
                }
              </div>
            </div>
          </div>
        </div>

        <Padding
          key={organisationWithProjects.id}
          size={{
            horizontal: Size.SMALL,
            vertical: Size.EXTRA_SMALL,
          }}>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Text>{organisationWithProjects.name}</Text>
            </FlexItem>
            <FlexItem>
              <Icon name="chevron-right" />
            </FlexItem>
          </Flex>
        </Padding>
      </div>
    </>
  );
};
