import {
  Content,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  Separator,
  Shade,
  Size,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { MosaicPageTitle, SettingsSection, ShowroomAddressEditor } from '~/components';
import { createTranslate } from '~/utils';

import { useFetchOrganisation } from '../../utils';
import { CompanyDetails } from './components';
import { LeadEmailNotificationPanel } from './components/LeadEmailNotificationsPanel';

const tt = createTranslate('pods.settings.routes.basic_info');
const ttSettings = createTranslate('pods.project_settings.routes.components.email_notification_panel');

export const BasicInfo = () => {
  const { isLoading, organisation } = useFetchOrganisation();

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('basic_info')}</MosaicPageTitle>
        <SettingsSection
          left={
            <Title noMargin size={4}>
              {tt('company_details')}
            </Title>
          }
          right={<CompanyDetails />}
        />
        <Margin size={{ vertical: Size.DEFAULT }}>
          <Separator />
        </Margin>
        <SettingsSection
          left={
            <Fragment>
              <Title noMargin size={4}>
                {ttSettings('leads_settings')}
              </Title>
              <Margin size={{ top: Size.DEFAULT }}>
                <Text shade={Shade.MEDIUM}>{ttSettings('leads_settings_description')}</Text>
              </Margin>
            </Fragment>
          }
          right={
            isLoading ? (
              <Panel
                body={
                  <PanelBody>
                    <LoadingPlaceholder />
                  </PanelBody>
                }
              />
            ) : organisation ? (
              <LeadEmailNotificationPanel organisation={organisation} />
            ) : null
          }
        />
        <Margin size={{ vertical: Size.DEFAULT }}>
          <Separator />
        </Margin>
        <SettingsSection
          left={
            <Fragment>
              <Title size={4}>{tt('addresses')}</Title>
              <Text tier={Tier.SECONDARY}>{tt('description')}</Text>
              <Margin />
              <ShowroomAddressEditor.ButtonOutlet />
            </Fragment>
          }
          right={
            <Panel
              body={
                <PanelBody>
                  <ShowroomAddressEditor />
                </PanelBody>
              }
            />
          }
        />
      </Padding>
    </Content>
  );
};
