import { Color, DrylusProvider } from '@drawbotics/react-drylus';
import { DiContainer, DiContainerProvider } from '@tokamakjs/react';
import { authExchange } from '@urql/exchange-auth';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  Provider as UrqlProvider,
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
} from 'urql';
import { computedExchange } from 'urql-computed-exchange';

import { entities } from '~/entities';

import { SubscriptionGuard } from './components';
import { AppRoutes } from './Routes';
import {
  CriticalErrorProvider,
  NotFoundProvider,
  addAuthToOperation,
  getAuth,
  willAuthError,
} from './utils';

interface AppProps {
  diContainer: DiContainer;
}

export const App = ({ diContainer }: AppProps) => {
  const client = createClient({
    maskTypename: true,
    url: process.env.MOSAIC_HOST,
    exchanges: [
      dedupExchange,
      cacheExchange,
      // @ts-ignore Type incompatibility error, see https://github.com/FormidableLabs/urql/issues/1017
      authExchange({ addAuthToOperation, getAuth, willAuthError }),
      computedExchange({ entities }),
      fetchExchange,
    ],
  });

  return (
    <UrqlProvider value={client}>
      <DrylusProvider baseColor={Color.BLUE} style={{ width: '100%', height: '100%' }}>
        <BrowserRouter>
          <NotFoundProvider>
            <CriticalErrorProvider>
              <SubscriptionGuard>
                <DiContainerProvider value={diContainer}>
                  <AppRoutes />
                </DiContainerProvider>
              </SubscriptionGuard>
            </CriticalErrorProvider>
          </NotFoundProvider>
        </BrowserRouter>
      </DrylusProvider>
    </UrqlProvider>
  );
};
