import sv from '@drawbotics/drylus-style-vars';
import { BaseFilter, Padding, RangeInput } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { Range } from '../../../types';

const styles = {
  wrapper: css`
    min-width: 200px;

    @media ${sv.screenM} {
      min-width: none;
      width: 100%;
    }
  `,
  container: css`
    [data-element='panel'] > div {
      overflow: visible;
    }
  `,
};

interface RangeFilterProps {
  active: boolean;
  label: string;
  onClear: () => void;
  onChange: (v: Range) => void;
  value: Range;
  min: number;
  max: number;
  step?: number;
  renderValue: (v: number) => string;
}

export const RangeFilter = ({ label, onClear, active, ...rest }: RangeFilterProps) => {
  return (
    <div className={styles.container}>
      <BaseFilter
        active={active}
        responsive={{ L: { fullWidth: true } }}
        label={label}
        onClear={onClear}>
        <Padding>
          <div className={styles.wrapper}>
            <RangeInput {...rest} />
          </div>
        </Padding>
      </BaseFilter>
    </div>
  );
};
