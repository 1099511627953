import {
  Banner,
  Button,
  Category,
  Color,
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Margin,
  Modal,
  Padding,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { useScreenSize } from '@drawbotics/use-screen-size';
import React, { Fragment, useEffect, useState } from 'react';

import { MosaicPageTitle } from '~/components/';
import {
  CreateAndEditUnitModal,
  Filters,
  ListingTable,
  PlaceholderListing,
} from '~/pods/listing-manager/routes/listing/components';
import { ID } from '~/types';
import { createTranslate, run } from '~/utils';

import { filterUnits } from '../../utils/filter-units';
import { useController } from '../../utils/hooks';
import { ListingController } from './listing.controller';

const tt = createTranslate('pods.listing_manager.routes');

export const ListingView = () => {
  const ctrl = useController(ListingController);
  const { screenSize, ScreenSizes } = useScreenSize();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unitIdToDelete, setUnitIdToDelete] = useState<ID>();

  const { isLoading, isProcessing, listing, activeUnit, filters, showIntercom, isAdmin, user } =
    ctrl;
  const filteredUnits = filterUnits(listing?.units ?? [], filters);
  const isReadonly = listing?.providerName != null;

  useEffect(() => {
    if (!activeUnit) {
      setIsModalVisible(false);
    }
  }, [activeUnit]);

  return (
    <Fragment>
      <Content fullHeight fullWidth>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <Flex>
            <FlexItem flex>
              {listing != null ? (
                <MosaicPageTitle>
                  {screenSize > ScreenSizes.XL ? tt('listing_manager') : listing!.name}
                </MosaicPageTitle>
              ) : null}
            </FlexItem>
            <FlexItem>
              <Button
                disabled={isReadonly || !isAdmin}
                onClick={() => setIsModalVisible(true)}
                color={Color.BLUE}>
                {`+ ${tt('add_units')}`}
              </Button>
            </FlexItem>
          </Flex>
          {run(() => {
            if (isLoading || listing == null) {
              return <PlaceholderListing />;
            } else {
              return (
                <Fragment>
                  <Margin size={{ bottom: Size.DEFAULT }}>
                    {isReadonly ? (
                      <Margin size={{ vertical: Size.SMALL }}>
                        <Banner
                          style={{ width: '100%' }}
                          category={Category.INFO}
                          trailing={
                            <Button onClick={showIntercom} inverted>
                              {tt('contact_us')}
                            </Button>
                          }>
                          {tt('external_provider_warning', {
                            provider: listing.providerName ?? '',
                          })}
                        </Banner>
                      </Margin>
                    ) : null}
                    <Flex justify={FlexJustify.END}>
                      <FlexItem>
                        <Filters
                          listing={listing}
                          resetPage={() => filters.page.set('1')}
                          hideSearch={activeUnit != null}
                          filters={filters}
                          units={listing.units}
                        />
                      </FlexItem>
                    </Flex>
                  </Margin>
                  <Margin size={{ bottom: Size.EXTRA_LARGE }}>
                    <ListingTable
                      isReadonly={isReadonly}
                      listing={listing}
                      page={filters.page.value ? Number(filters.page.value) : 1}
                      onChangePage={(page) => filters.page.set(page.toString())}
                      onClickRow={(id?: ID) => {
                        ctrl.setActiveUnitId(id);
                        setIsModalVisible(true);
                      }}
                      activeUnit={activeUnit}
                      units={filteredUnits}
                      disableDelete={user?.isAgent ?? true}
                      onClickDeleteUnit={(id) => setUnitIdToDelete(id)}
                      onClickAddUnits={() => setIsModalVisible(true)}
                    />
                  </Margin>
                </Fragment>
              );
            }
          })}
        </Padding>
      </Content>
      {listing != null ? (
        <CreateAndEditUnitModal
          isVisible={isModalVisible}
          onClickClose={() => {
            setIsModalVisible(false);
            ctrl.setActiveUnitId(undefined);
          }}
          listing={listing}
          activeUnit={activeUnit}
          onUpdateUnit={async (id, values) => await ctrl.updateUnit(id, values)}
          onCreateUnit={async (values) => await ctrl.createUnit(values)}
          isProcessing={isProcessing}
          isAgent={user?.isAgent}
        />
      ) : null}
      <Modal
        visible={unitIdToDelete != null}
        title={'Delete unit'}
        onClickClose={() => setUnitIdToDelete(undefined)}
        footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button onClick={() => setUnitIdToDelete(undefined)} tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <Button
                onClick={async () => {
                  await ctrl.destroyUnit(unitIdToDelete!);
                  setUnitIdToDelete(undefined);
                }}
                leading={isProcessing ? <Spinner /> : null}
                category={Category.DANGER}>
                {tt('confirm_delete')}
              </Button>
            </FlexItem>
          </Flex>
        }>
        <Text>{tt('ask_confirm_delete')}</Text>
      </Modal>
    </Fragment>
  );
};
