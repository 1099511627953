import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import { useEffect } from 'react';

import { Log } from '~/pods/crm/types';
import { createTranslate, extractGQLErrorMessages } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

const tt = createTranslate('pods.crm.utils.hooks.update_log');

interface UpdateLogVariables {
  log: {
    id: string;
    content?: string;
    date?: string;
    pinned?: boolean;
    projectIds?: Array<string>;
  };
}

interface UpdateLogPayload {
  updateLog: {
    log: Log;
  };
}

const updateLogMutation = gql`
  mutation updateLog($log: UpdateLogInput!) {
    updateLog(input: $log) {
      log {
        id
        date
        content
        pinned
      }
    }
  }
`;

export function useUpdateLog() {
  const { res, executeMutation: updateLog } = useMosaicMutation<
    UpdateLogPayload,
    UpdateLogVariables
  >(updateLogMutation);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!res.fetching && res.error == null && res.data != null) {
      showAlert({ text: tt('success'), category: Category.SUCCESS });
    } else if (!res.fetching && res.error != null) {
      const { message } = extractGQLErrorMessages(res.error);
      const text = message ?? tt('error');
      // TODO translate: 'invalid_email', 'not_found', 'unauthorized'
      showAlert({ text, category: Category.DANGER });
    }
  }, [res.fetching]);

  return {
    isLoading: res.fetching,
    updateLog: (log: UpdateLogVariables['log']) => updateLog({ log }),
  };
}
