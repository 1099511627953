import { DateRange } from '../types';
import { isDateInRange } from '.';
export function computeDeltaForRange<T extends Record<string, any> = any>(
  range: DateRange,
  dataset: Array<T>,
  key: keyof T,
): string {
  const rangeSize = range.end.diff(range.start, 'day');
  const prevRange = {
    start: range.start.subtract(rangeSize, 'day'),
    end: range.start,
  };

  const prevAmount = dataset.filter((d) => isDateInRange(d[key], prevRange)).length;
  const currentAmount = dataset.filter((d) => isDateInRange(d[key], range)).length;

  const delta = currentAmount - prevAmount;

  return delta >= 0 ? `+${delta}` : String(delta);
}
