import { Dayjs } from 'dayjs';
import gql from 'fraql';

import { Meeting, User } from '~/pods/product-stats/types';
import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { computeStatsData } from './compute-stats-data';

interface MeetingsQueryResult {
  meetings: Array<Meeting>;
}

const MeetingsCountQuery = gql`
  query MeetingsCountQuery {
    meetingsCount(filter: { statuses: [FINISHED, ARCHIVED] }) {
      count
    }
  }
`;

interface TeammatesQueryResult {
  teammates: Array<User>;
}

const TeammatesQuery = gql`
  query TeammatesQuery($licensed: Boolean!) {
    teammates(filter: { licensed: $licensed }) {
      ... on User {
        id
        fullName @computed(type: User)
        license {
          id
        }
      }
    }
  }
`;

const MeetingsQuery = gql`
  query MeetingsQuery($limit: Int!) {
    meetings(filter: { statuses: [FINISHED, ARCHIVED] }, pagination: { limit: $limit, offset: 0 }) {
      id
      realDuration: computedDuration
      presentations {
        id
        name
      }
      user {
        id
      }
      date: dateObject @computed(type: Meeting)
    }
  }
`;

export interface UserMeetingsOverTime {
  user: User;
  meetings: Array<{
    count: number;
    month: Dayjs;
  }>;
}

export interface StatsData {
  topPresentation?: {
    count: number;
    name: string;
  };
  medianMeetingDuration?: number; // in minutes
  meetingsPerUser?: Array<UserMeetingsOverTime>; // one per user
}

export function useLoadStats(): {
  isLoading: boolean;
  data?: StatsData;
  error?: ErrorType;
} {
  const meetingsCountRes = useMosaicQuery<{ meetingsCount: { count: number } }, null>({
    query: MeetingsCountQuery,
  });

  const meetingsRes = useMosaicQuery<MeetingsQueryResult, { limit: number }>({
    query: MeetingsQuery,
    variables: { limit: meetingsCountRes.data?.meetingsCount.count ?? 0 },
    pause: meetingsCountRes.isLoading,
  });

  const licensedUsersRes = useMosaicQuery<TeammatesQueryResult, { licensed: boolean }>({
    query: TeammatesQuery,
    variables: { licensed: true },
  });

  const unlicensedUsersRes = useMosaicQuery<TeammatesQueryResult, { licensed: boolean }>({
    query: TeammatesQuery,
    variables: { licensed: false },
  });

  const isLoading =
    meetingsCountRes.isLoading ||
    meetingsRes.isLoading ||
    licensedUsersRes.isLoading ||
    unlicensedUsersRes.isLoading;
  const error =
    meetingsCountRes.error ??
    meetingsRes.error ??
    licensedUsersRes.error ??
    unlicensedUsersRes.error;

  const meetings = meetingsRes.data?.meetings;
  const users = [
    ...(licensedUsersRes.data?.teammates ?? []),
    ...(unlicensedUsersRes.data?.teammates ?? []),
  ];
  const data = meetings == null || users == null ? undefined : computeStatsData(meetings, users);

  return {
    isLoading,
    data,
    error: buildError(error),
  };
}
