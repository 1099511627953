import sv from '@drawbotics/drylus-style-vars';
import {
  Color,
  Icon,
  IconType,
  LoadingPlaceholder,
  Margin,
  Padding,
  Position,
  Shade,
  Size,
  Tag,
  Text,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React, { ReactElement, ReactNode } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { createTranslate } from '~/utils';

const tt = createTranslate('components.base_sidebar');

const styles = {
  sidebar: css`
    display: flex;
    flex-direction: column;
    width: 220px;
    background: ${sv.white};
    position: relative;
    height: 100vh;
    box-shadow: ${sv.elevation1};
    white-space: nowrap;

    position: sticky;
    top: 0;
  `,
  sections: css`
    height: 100%;
    padding-top: ${sv.paddingSmall};
    overflow-x: hidden;
  `,
  navigationItem: css`
    padding: ${sv.paddingSmall} ${sv.defaultPadding};
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: ${sv.neutralLighter};
    }

    [data-element='item-label'] {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  subNavigationItem: css`
    padding-left: ${sv.paddingHuge};
  `,
  active: css`
    background: ${sv.blueLighter};
    box-shadow: inset 5px 0px 0px ${sv.blue};

    &:hover {
      background: ${sv.blueLighter};
    }
  `,
  disabled: css`
    background: none !important;

    &:hover {
      cursor: not-allowed;
      background: none;
    }
  `,
};

interface LinkWithTooltipProps {
  children: ReactNode;
  disabledText: string;
  to: string;
  disabled: boolean;
  openInNewTab?: boolean;
}

export const LinkWithTooltip = ({
  children,
  disabledText,
  to,
  disabled,
  openInNewTab,
}: LinkWithTooltipProps) => {
  const LinkComponent = disabled ? 'span' : Link;
  return (
    <LinkComponent
      to={to}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}>
      {disabled ? (
        <Tooltip content={disabledText} side={Position.RIGHT}>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </LinkComponent>
  );
};

interface SubNavigationItemProps {
  to: string;
  children: string;
  isActive?: boolean;
}

export const SubNavigationItem = ({ children, to, isActive }: SubNavigationItemProps) => {
  return (
    <Link to={to}>
      <div
        className={cx(styles.navigationItem, styles.subNavigationItem, {
          [styles.active]: isActive,
        })}>
        <Text shade={Shade.MEDIUM}>{children}</Text>
      </div>
    </Link>
  );
};

interface NavigationCategoryProps {
  label: string;
  icon: IconType;
  isHidden?: boolean;
  children: Array<ReactElement<SubNavigationItemProps>>;
}

export const NavigationCategory = ({
  label,
  icon,
  children,
  isHidden = false,
}: NavigationCategoryProps) => {
  const { pathname, search } = useLocation();
  const urlsToMatch = React.Children.map(children, (child) => child.props.to);
  const activeUrlIndex = urlsToMatch.findIndex((url) => pathname.replace(search, '') === url);
  const defaultUrl = children[0].props.to;
  const isOpen = activeUrlIndex >= 0;

  if (isHidden) return null;

  return (
    <div>
      <Link to={defaultUrl}>
        <div className={styles.navigationItem}>
          <Margin size={{ right: Size.SMALL }}>
            <Icon name={icon} />
          </Margin>
          <Text>{label}</Text>
        </div>
      </Link>
      {isOpen
        ? React.Children.map(children, (child, index) =>
            React.cloneElement(child, { isActive: index === activeUrlIndex }),
          )
        : null}
    </div>
  );
};

interface NavigationItemProps {
  children: ReactNode;
  icon: IconType;
  to: string;
  disabled?: boolean;
  loading?: boolean;
  trailingIcon?: IconType;
  openInNewTab?: boolean;
  matchNested?: boolean;
  isNew?: boolean;
  isHidden?: boolean;
}

export const NavigationItem = ({
  children,
  icon,
  trailingIcon,
  to,
  disabled = false,
  loading = false,
  openInNewTab = false,
  matchNested,
  isNew,
  isHidden = false,
}: NavigationItemProps) => {
  const { pathname, search } = useLocation();
  const active = matchNested
    ? pathname.replace(search, '').includes(to)
    : pathname.replace(search, '') === to;

  if (isHidden) return null;

  return loading ? (
    <div className={styles.navigationItem}>
      <LoadingPlaceholder />
    </div>
  ) : (
    <LinkWithTooltip
      disabledText={tt('feature_not_enabled')}
      to={to}
      disabled={disabled}
      openInNewTab={openInNewTab}>
      <div
        className={cx(styles.navigationItem, {
          [styles.active]: active,
          [styles.disabled]: disabled,
        })}>
        <Margin size={{ right: Size.SMALL }}>
          <Icon name={icon} shade={disabled ? Shade.LIGHT : Shade.DARK} />
        </Margin>
        <div data-element="item-label">
          <Text disabled={disabled}>{children}</Text>
        </div>
        {trailingIcon != null && disabled === false ? (
          <div data-element="trailing-icon">
            <Tooltip side={Position.RIGHT} content={tt('open_new_tab')}>
              <Icon name={trailingIcon} color={Color.BLUE} />
            </Tooltip>
          </div>
        ) : null}
        {isNew ? <Tag color={Color.GREEN}>New</Tag> : null}
      </div>
    </LinkWithTooltip>
  );
};

interface BaseSidebarProps {
  children: ReactNode;
  switcher: ReactNode;
}

export const BaseSidebar = ({ children, switcher }: BaseSidebarProps) => {
  return (
    <div className={styles.sidebar}>
      <Padding>{switcher}</Padding>
      <div>{children}</div>
    </div>
  );
};
