import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  InlineEdit,
  Size,
  TextArea,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import { css, cx } from 'emotion';
import React from 'react';

import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { AnyWebsiteSectionData, CoverSection } from '../../../api/domain';
import { LoremIpsum } from '../../../utils';
import { ControlBracket } from '../ControlBracket';
import { Themed } from '../Themed';

const styles = {
  container: css`
    padding: 0 ${sv.paddingMassive};
    margin: ${sv.marginMassive} 0;
    position: relative;
  `,
  controls: css`
    position: absolute;
    height: 100%;
    right: ${sv.defaultMargin};
  `,
  inactive: css`
    opacity: 0.4;
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
  coverTitle: css`
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    color: #424242;
  `,
  coverDescription: css`
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #424242;
  `,
};

interface CoverProps {
  cover: CoverSection;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  language: LocaleCode;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Cover = ({ cover, onChange, language, onIncrement, onDecrement }: CoverProps) => {
  const form = useForm({
    title: cover.title ?? '',
    description: cover.description ?? '',
  });
  const { showAlert } = useAlert();

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (
    sectionId: ID,
    data: Pick<CoverSection, 'title' | 'description'>,
  ) => {
    if (data.title != null) {
      data.title.length <= 200 ? onChange(sectionId, data) : _handleTextLengthError();
    }
    if (data.description != null) {
      data.description.length <= 800 ? onChange(sectionId, data) : _handleTextLengthError();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <ControlBracket
          sectionId={cover.id}
          onToggleVisibility={() => onChange(cover.id, { active: !cover.isActive })}
          isActive={cover.isActive}
          onIncrement={() => onIncrement(cover.id)}
          onDecrement={() => onDecrement(cover.id)}
        />
      </div>
      <div className={cx({ [styles.inactive]: !cover.isActive })}>
        <Flex
          direction={FlexDirection.HORIZONTAL}
          justify={FlexJustify.START}
          align={FlexAlign.START}
          style={{ width: '100%' }}>
          <FlexItem flex>
            <InlineEdit
              onCancel={() => form.set(cover.title ?? '', 'title')}
              onClickConfirm={() => _handleUpdateSection(cover.id, { title: form.get('title') })}
              edit={
                <TextArea
                  style={{ fontSize: '1rem', width: '500px' }}
                  name="coverTitle"
                  placeholder={tl('title_placeholder')}
                  value={form.get('title')}
                  onChange={(v) => form.set(v.toString(), 'title')}
                  error={form.get('title').length > 200 && tl('max_200_characters_error')}
                />
              }>
              <Themed.Paragraph
                className={styles.coverTitle}
                align={Align.LEFT}
                style={{
                  opacity: cover.title != null && cover.title !== '' ? '1' : '0.5',
                }}>
                {cover.title != null && cover.title !== '' ? cover.title : LoremIpsum.MEDIUM}
              </Themed.Paragraph>
            </InlineEdit>
          </FlexItem>
          <FlexSpacer size={Size.HUGE} />
          <FlexItem flex>
            <InlineEdit
              onCancel={() => form.set(cover.description ?? '', 'description')}
              onClickConfirm={() =>
                _handleUpdateSection(cover.id, { description: form.get('description') })
              }
              edit={
                <TextArea
                  className={styles.textarea}
                  name="coverDescription"
                  placeholder={tl('tagline_placeholder')}
                  value={form.get('description')}
                  onChange={(v) => form.set(v.toString(), 'description')}
                  error={form.get('description').length > 800 && tl('max_800_characters_error')}
                />
              }>
              <Themed.Paragraph
                className={styles.coverDescription}
                align={Align.LEFT}
                style={{
                  opacity: cover.description != null && cover.description !== '' ? '1' : '0.5',
                }}>
                {cover.description != null && cover.description !== ''
                  ? cover.description
                  : LoremIpsum.LONG}
              </Themed.Paragraph>
            </InlineEdit>
          </FlexItem>
        </Flex>
      </div>
    </div>
  );
};
