import { Layout, Position } from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface NavbarLayoutProps {
  children: ReactNode;
  navbar: ReactNode;
}

export const NavbarLayout = ({ children, navbar }: NavbarLayoutProps) => {
  return (
    <Layout bar={navbar} position={Position.LEFT} barScrollable={false} fixed>
      {children}
    </Layout>
  );
};
