import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { LocaleCode } from '~/utils';

import {
  DomainStatus,
  SectionIdentifier,
  Theme,
  ThemeDefinition,
  WebsiteLanguage,
} from '../../types';
import { getThemeValues } from '../../utils';
import {
  AnyWebsiteSectionData,
  AnyWebsiteSectionDataSchema,
  CoverSection,
  GallerySection,
  HeroSection,
  ListingSection,
  MainSection,
  MapSection,
  TextImageSection,
} from './sections';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
const AttachmentSchema = z.object({
  id: z.string(),
  filename: z.string(),
  url: z.string(),
});

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const ListingSettingsSchema = z.object({
  availability: z.boolean(),
  downloadFloorplan: z.boolean(),
  numberOfRooms: z.boolean(),
  price: z.boolean(),
  soldUnits: z.boolean(),
  surface: z.boolean(),
  terrace: z.boolean(),
});

export type ListingSettings = z.infer<typeof ListingSettingsSchema>;

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
const WebsiteSchema = z.object({
  id: z.string(),
  identifier: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  template: ze.defaults(z.nativeEnum(Theme), Theme.TRADITIONAL),
  customDomain: ze.optional(
    z.object({
      id: z.string(),
      cname: z.string(),
      hostname: z.string(),
      status: z.nativeEnum(DomainStatus),
    }),
  ),
  language: ze.defaults(z.nativeEnum(WebsiteLanguage), WebsiteLanguage.EN),
  primaryColor: ze.optional(z.string()),
  secondaryColor: ze.optional(z.string()),
  contactFormFilters: ze.defaults(z.boolean(), false),
  contactFormScheduling: ze.defaults(z.boolean(), false),
  logo: ze.optional(AttachmentSchema),
  favicon: ze.optional(AttachmentSchema),
  openGraphTitle: ze.optional(z.string()),
  openGraphDescription: ze.optional(z.string()),
  openGraphAttachment: ze.optional(AttachmentSchema),
  _sections: z.array(AnyWebsiteSectionDataSchema),
  listingSettings: ListingSettingsSchema,
  latitude: ze.optional(z.union([z.string(), z.number()]).transform((v) => Number(v))),
  longitude: ze.optional(z.union([z.string(), z.number()]).transform((v) => Number(v))),
  address: ze.optional(z.string()),
  companyName: ze.optional(z.string()),
  companyUrl: ze.optional(z.string()),
  gdprUrl: ze.optional(z.string()),
  socialMedia: ze.optional(
    z.object({
      facebook: ze.optional(z.string()),
      instagram: ze.optional(z.string()),
      linkedin: ze.optional(z.string()),
      twitter: ze.optional(z.string()),
    }),
  ),
});

export type WebsiteData = z.infer<typeof WebsiteSchema>;

const identifierToSection: Record<
  SectionIdentifier,
  { fromData: (args: any) => AnyWebsiteSectionData }
> = {
  [SectionIdentifier.COVER]: CoverSection,
  [SectionIdentifier.GALLERY]: GallerySection,
  [SectionIdentifier.HERO]: HeroSection,
  [SectionIdentifier.LISTING]: ListingSection,
  [SectionIdentifier.MAIN]: MainSection,
  [SectionIdentifier.MAP]: MapSection,
  [SectionIdentifier.TEXT_IMAGE]: TextImageSection,
};
export class Website extends ze.ClassFrom(WebsiteSchema) {
  public static fromData(data: WebsiteData): Website {
    return ze.validate(data, Website);
  }

  get theme(): ThemeDefinition {
    return getThemeValues(this.template, {
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
    });
  }

  get localeCode() {
    return this.language.toLocaleLowerCase() as LocaleCode;
  }

  get sections() {
    return this._sections
      .sort((a, b) => a.position - b.position)
      .map((section, index) => ({ ...section, position: index }))
      .map((section) => {
        return identifierToSection[section.identifier].fromData(section);
      });
  }

  public toData(): WebsiteData {
    return JSON.parse(JSON.stringify(this));
  }
}
