import gql from 'fraql';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { OperationResult } from 'urql';

import {
  Headers,
  getFileChecksum,
  progressToPercentage,
  readAsDataURL,
  request,
  sanitizeFilename,
} from './utils';

export interface DirectUpload {
  url: string;
  signedBlobId: string;
  headers: string;
  blobId: string;
}

export interface UploadedFile {
  originalFile: File;
  signedBlobId?: string;
  url: string;
}

export interface UploadInfo {
  progress?: number;
  filename: string;
  error?: Error;
  url: string;
}

interface UploadBlob {
  filename: string;
  byteSize: number;
  checksum: string;
  contentType: string;
}

interface CreateDirectUploadVariables {
  input: UploadBlob;
}

interface CreateDirectUploadPayload {
  createDirectUpload: {
    directUpload: DirectUpload;
  };
}

const createDirectUploadMutation = gql`
  mutation createDirectUpload($input: CreateDirectUploadInput!) {
    createDirectUpload(input: $input) {
      directUpload {
        blobId
        headers
        signedBlobId
        url
      }
    }
  }
`;

async function _directUpload(
  directUpload: DirectUpload,
  file: File,
  onProgress?: (e: ProgressEvent) => void,
) {
  const { url, headers: headersFromServer } = directUpload;
  const headers: Headers = JSON.parse(headersFromServer);
  return await request('PUT', url, file, {
    headers,
    onProgress,
  });
}

async function _generateBlob(
  file: File,
  sanitize: (filename: string) => string,
): Promise<UploadBlob> {
  return {
    filename: sanitize(file.name),
    byteSize: file.size,
    checksum: btoa(await getFileChecksum(file)),
    contentType: file.type,
  };
}

async function _uploadFile(
  file: File,
  extra: {
    sanitize: (filename: string) => string;
    onProgress: (file: File, e: ProgressEvent) => void;
  },
  createDirectUpload: (
    variables: CreateDirectUploadVariables,
  ) => Promise<OperationResult<CreateDirectUploadPayload>>,
) {
  const { sanitize, onProgress } = extra;
  const blob = await _generateBlob(file, sanitize);
  const { data, error } = await createDirectUpload({ input: blob });

  if (error != null || data == null) {
    throw error;
  }

  const { directUpload } = data.createDirectUpload;

  const res = await _directUpload(directUpload, file, (e) => onProgress(file, e));
  // Backend should send back something else than nothing, if all is good
  if (res != '') {
    throw res;
  }
  return { blob, directUpload };
}

export function useFileUpload(
  urqlMutation: <T, V>(
    query: string | DocumentNode,
  ) => { res: any; executeMutation: (v: V) => Promise<OperationResult<T>> },
): {
  data: Array<UploadedFile | undefined>;
  info: Array<UploadInfo>;
  uploadFiles: (
    files: FileList,
    sanitize?: (v: string) => string,
  ) => Promise<{ data: Array<UploadedFile | undefined> }>;
  isLoading: boolean;
} {
  const [data, setData] = useState<Array<UploadedFile | undefined>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadInfo, setUploadInfo] = useState<Array<UploadInfo>>([]);

  const { executeMutation: createDirectUpload } = urqlMutation<
    CreateDirectUploadPayload,
    CreateDirectUploadVariables
  >(createDirectUploadMutation);

  const uploadFiles = async (
    files: FileList,
    sanitize?: (filename: string) => string,
  ): Promise<{ data: Array<UploadedFile | undefined> }> => {
    const dataWithURLs = await Promise.all(
      [...files].map(async (file: File) => {
        const url = await readAsDataURL(file);
        return {
          filename: file.name,
          progress: 0,
          url,
        };
      }),
    );
    setUploadInfo(dataWithURLs);
    setIsLoading(true);

    const promises = [...files].map(async (file, index) => {
      const handleUpdateInfo = (progress?: number) => {
        setUploadInfo((previousInfos: Array<UploadInfo>) => {
          const newInfo = { ...previousInfos[index], progress };
          return previousInfos.map((info, i) => (i === index ? newInfo : info));
        });
      };

      try {
        const uploadResult = await _uploadFile(
          file,
          {
            sanitize: (name) =>
              sanitize ? sanitizeFilename(sanitize(name)) : sanitizeFilename(name),
            onProgress: (_, e) => handleUpdateInfo(progressToPercentage(e)),
          },
          createDirectUpload,
        );
        handleUpdateInfo();
        return {
          originalFile: file,
          signedBlobId: uploadResult.directUpload.signedBlobId,
          url: dataWithURLs[index].url,
        };
      } catch (error) {
        setUploadInfo((previousInfos) => {
          const newInfo = { ...previousInfos[index], progress: undefined, error };
          return previousInfos.map((info, i) => (i === index ? (newInfo as UploadInfo) : info));
        });
      }
    });
    const uploadedFiles = await Promise.all(promises);
    setData(uploadedFiles);
    setIsLoading(false);

    return { data: uploadedFiles };
  };

  return { data, isLoading, info: uploadInfo, uploadFiles };
}
