import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Paragraph,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React from 'react';

import { Meeting, MeetingLocation } from '~/pods/meetings/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.notification_modal');

interface SendUpdateVariables {
  meetingId: string;
  previousLocation: string;
}

interface SendUpdateResult {
  notifyUpdateMeeting: {
    meeting: Meeting;
  };
}

const sendUpdateMutation = gql`
  mutation sendUpdate($meetingId: ID!, $previousLocation: MeetingLocationEnum) {
    notifyUpdateMeeting(input: { meetingId: $meetingId, oldLocation: $previousLocation }) {
      meeting {
        id
      }
    }
  }
`;

interface NotificationModalProps {
  previousLocation?: MeetingLocation;
  meeting: Meeting;
  visible: boolean;
  onClickClose: VoidFunction;
}

export const NotificationModal = ({
  previousLocation,
  meeting,
  visible,
  onClickClose,
}: NotificationModalProps) => {
  const { showAlert } = useAlert();

  const { executeMutation: sendUpdate, res: sendUpdateResult } = useMosaicMutation<
    SendUpdateResult,
    SendUpdateVariables
  >(sendUpdateMutation);

  const handleSendUpdate = async () => {
    if (previousLocation != null) {
      const { error } = await sendUpdate({
        meetingId: meeting.id,
        previousLocation,
      });
      if (error != null) {
        showAlert({
          text: tt('something_went_wrong'),
          category: Category.DANGER,
        });
      } else {
        onClickClose();
        setTimeout(() => {
          showAlert({
            text: tt('notification_sent'),
            category: Category.SUCCESS,
          });
        }, 600);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      title={tt('meeting_update')}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('no_thanks')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              disabled={sendUpdateResult.fetching}
              trailing={sendUpdateResult.fetching ? <Spinner size={Size.SMALL} inversed /> : null}
              category={Category.INFO}
              onClick={handleSendUpdate}>
              {tt('send_notification')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Paragraph>{tt('want_to_send')}</Paragraph>
    </Modal>
  );
};
