import { ze } from '@tokamakjs/common';
import { z } from 'zod';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const AssetSchema = z.object({
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  assetId: ze.optional(z.string()),
  id: ze.optional(z.string()), // same value as assetId
  url: ze.optional(z.string()),
  signedBlobId: ze.optional(z.string()),
  position: ze.optional(z.number()),
  alt: ze.optional(z.string()),
  destroy: ze.optional(z.boolean()),
  filename: ze.optional(z.string()),
  attachmentUrl: ze.optional(z.string()), // same value as url but only in URLAsset
});

export type AssetData = z.infer<typeof AssetSchema>;
