import { Query } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';

import { ProjectApi } from '../api';
import { Project } from '../api/domain';
import { ProjectState, ProjectStore } from '../stores';

@Injectable()
export class ProjectService extends Query<ProjectState> {
  private readonly _project$ = this.select('project');

  get project$() {
    return this._project$;
  }

  constructor(protected readonly _store: ProjectStore, private readonly _projectApi: ProjectApi) {
    super(_store);
  }

  public async loadProject(slug: string): Promise<Project> {
    let { project } = this._store.getValue();

    if (project != null && project.slug === slug) {
      return project!;
    }

    project = await this._projectApi.fetchProject(slug);
    this._store.update({ project });

    return project;
  }
}
