import {
  Label,
  Margin,
  MultiSelect,
  MultiSelectOption,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import { flow, map, sortBy } from 'lodash/fp';
import React, { Fragment } from 'react';

import { InterestType, Project, Unit } from '../types';
import { useFetchBasicListing } from '../utils';

// import { createTranslate } from '~/utils';

// const tt = createTranslate('pods.crm.unit_interest_selection');

interface UnitInterestSelectionProps {
  project: Project;
  values: InterestType['unitInterests'];
  onChange: (values: NonNullable<InterestType['unitInterests']>) => void;
  customHeader?: boolean;
}

// TODO remove unit reference fall back once it's possible
export const UnitInterestSelection = ({
  project,
  values = [],
  onChange,
  customHeader,
}: UnitInterestSelectionProps) => {
  const { isLoading, units = [] } = useFetchBasicListing(project.slug);

  //@ts-ignore
  const unitOptions = flow(
    map((unit: Unit) => ({ label: unit.reference, value: unit.id })),
    sortBy('label'),
  )(units) as Array<MultiSelectOption<string>>;

  const handleOnChange = (unitIds: Array<string>) => {
    const unitInterests = unitIds.reduce((memo, unitId) => {
      const unit = units.find((unit) => unit.id === unitId);
      if (unit != null) {
        return [...memo, { unitId: unit.id, unitReference: unit.reference }];
      }
      return memo;
    }, [] as NonNullable<InterestType['unitInterests']>);

    onChange(unitInterests);
  };
  return (
    <Fragment>
      {customHeader == null ? (
        <Margin size={{ vertical: Size.EXTRA_SMALL }}>
          <Label>{project.name}</Label>
        </Margin>
      ) : null}
      {isLoading ? (
        <Margin size={{ vertical: Size.EXTRA_SMALL }}>
          <Spinner fullSize />
        </Margin>
      ) : (
        <MultiSelect
          onChange={handleOnChange}
          values={values.map((value) => value.unitId)}
          options={unitOptions}
        />
      )}
    </Fragment>
  );
};
