import { css } from 'emotion';
import React, { CSSProperties, ReactElement } from 'react';

const styles = {
  engagementOverlay: css`
    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    }
  `,
};

interface IconsProps {
  size: number;
  color?: string;
  inversed?: boolean;
  style?: CSSProperties;
}

interface EngagementIconWrapperProps {
  size: number;
  style?: CSSProperties;
  children: ReactElement;
  backgroundColor: string;
  inversed?: boolean;
}

const EngagementIconWrapper = ({
  backgroundColor,
  size,
  children,
  style,
  inversed,
}: EngagementIconWrapperProps) => {
  return (
    <div
      className={size > 16 ? styles.engagementOverlay : undefined}
      style={{
        backgroundColor,
        borderRadius: '50%',
        height: size,
        width: size,
        padding: inversed ? undefined : size / 6,
        ...style,
      }}>
      {children}
    </div>
  );
};

export const EngagementLevelDefault = ({ size }: IconsProps) => {
  return (
    <EngagementIconWrapper size={size} backgroundColor="#D3DCE6">
      <svg display="block" height="100%" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.9944 11.2744C14.9118 14.4527 12.2538 17 9.0051 17C5.6895 17 3 14.3559 3 11.0963C3 10.2253 3.19689 9.40068 3.53947 8.66126C3.80331 8.08057 4.52392 7.23663 4.96495 6.76821C4.66568 9.84587 5.38235 11.2395 6.51249 11.9402C7.56388 12.5945 8.87122 12.3506 9.37525 11.286C9.89897 10.1827 8.96966 9.09872 8.02459 7.94508C7.48512 7.28696 7.1701 6.75272 7.04015 6.17203C6.50855 3.91507 8.8279 1 8.8279 1C8.8279 3.88797 11.9112 4.98355 13.7186 7.4031C13.8682 7.59666 14.0139 7.80571 14.1439 8.01476C14.2896 8.27414 14.4235 8.52964 14.5377 8.78902C14.6873 9.09485 14.7857 9.42003 14.8645 9.78781C14.8803 9.91556 14.9118 10.0472 14.9314 10.1749C14.9787 10.4846 15.0141 11.2434 14.9944 11.2744Z"
          fill="#fff"
        />
      </svg>
    </EngagementIconWrapper>
  );
};

export const EngagementLevelHot = ({ inversed, size, style }: IconsProps) => {
  const backgroundColor = inversed ? 'transparent' : '#EC4C47';
  const fill = inversed ? '#EC4C47' : '#fff';

  return (
    <EngagementIconWrapper
      size={size}
      backgroundColor={backgroundColor}
      style={style}
      inversed={inversed}>
      <svg display="block" height="100%" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.9944 11.2744C14.9118 14.4527 12.2538 17 9.0051 17C5.6895 17 3 14.3559 3 11.0963C3 10.2253 3.19689 9.40068 3.53947 8.66126C3.80331 8.08057 4.52392 7.23663 4.96495 6.76821C4.66568 9.84587 5.38235 11.2395 6.51249 11.9402C7.56388 12.5945 8.87122 12.3506 9.37525 11.286C9.89897 10.1827 8.96966 9.09872 8.02459 7.94508C7.48512 7.28696 7.1701 6.75272 7.04015 6.17203C6.50855 3.91507 8.8279 1 8.8279 1C8.8279 3.88797 11.9112 4.98355 13.7186 7.4031C13.8682 7.59666 14.0139 7.80571 14.1439 8.01476C14.2896 8.27414 14.4235 8.52964 14.5377 8.78902C14.6873 9.09485 14.7857 9.42003 14.8645 9.78781C14.8803 9.91556 14.9118 10.0472 14.9314 10.1749C14.9787 10.4846 15.0141 11.2434 14.9944 11.2744Z"
          fill={fill}
        />
      </svg>
    </EngagementIconWrapper>
  );
};

export const EngagementLevelMedium = ({ inversed, size, style }: IconsProps) => {
  const backgroundColor = inversed ? 'transparent' : '#F8A00F';
  const fill = inversed ? '#F8A00F' : '#fff';

  return (
    <EngagementIconWrapper
      size={size}
      backgroundColor={backgroundColor}
      style={style}
      inversed={inversed}>
      <svg height="100%" display="block" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33333 3.66667V10.0185C5.515 10.751 5 11.8153 5 13C5 15.2091 6.79086 17 9 17C11.2091 17 13 15.2091 13 13C13 11.8153 12.485 10.751 11.6667 10.0185V3.66667C11.6667 2.19391 10.4728 1 9 1C7.52724 1 6.33333 2.19391 6.33333 3.66667ZM10.0667 3.66667V6.55522C9.74002 6.41251 9.37926 6.33333 9 6.33333C8.62074 6.33333 8.25998 6.41251 7.93333 6.55522V3.66667C7.93333 3.07756 8.4109 2.6 9 2.6C9.5891 2.6 10.0667 3.07756 10.0667 3.66667Z"
          fill={fill}
        />
      </svg>
    </EngagementIconWrapper>
  );
};

export const EngagementLevelCold = ({ inversed, size, style }: IconsProps) => {
  const backgroundColor = inversed ? 'transparent' : '#4673D1';
  const fill = inversed ? '#4673D1' : '#fff';

  return (
    <EngagementIconWrapper
      size={size}
      backgroundColor={backgroundColor}
      style={style}
      inversed={inversed}>
      <svg height="100%" display="block" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.41644 3.18794L8.16444 4.93595V7.54623L5.90303 6.2406L5.26319 3.85269L3.64044 4.28751L4.15287 6.19994L2.2403 6.71253L2.67512 8.33528L5.06294 7.69547L7.32235 8.99994L5.06259 10.3046L2.67505 9.66488L2.24023 11.2876L4.15266 11.8001L3.64034 13.7127L5.26309 14.1475L5.903 11.7593L8.16444 10.4536V13.0643L6.41665 14.8121L7.60458 16L9.00458 14.6L10.4048 16L11.5927 14.8121L9.84444 13.0638V10.4561L12.1023 11.7596L12.742 14.1472L14.3648 13.7124L13.8523 11.7999L15.7649 11.2873L15.33 9.66455L12.9419 10.3045L10.6823 8.99994L12.9418 7.69543L15.3297 8.33526L15.7646 6.71251L13.8521 6.20008L14.3645 4.28745L12.7417 3.85263L12.1019 6.24046L9.84444 7.54381V4.93605L11.5925 3.18798L10.4046 2.00004L9.00458 3.40004L7.60437 2L6.41644 3.18794Z"
          fill={fill}
        />
      </svg>
    </EngagementIconWrapper>
  );
};
