export function distribute<T>(items: Array<T>, amount: number): Array<number> {
  const averageAmount = amount / items.length;
  const result = [];

  while (amount > averageAmount) {
    result.push(averageAmount);
    amount -= averageAmount;
  }

  if (result.length != items.length) {
    result.push(amount);
  }

  return result;
}
