import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexAlign, FlexDirection, FlexItem, FlexJustify } from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface SettingsSectionProps {
  left: ReactNode;
  right: ReactNode;
}

export const SettingsSection = ({ left, right }: SettingsSectionProps) => {
  return (
    <Flex
      style={{ gap: sv.defaultMargin }}
      align={FlexAlign.STRETCH}
      justify={FlexJustify.CENTER}
      responsive={{ XL: { direction: FlexDirection.VERTICAL } }}>
      <FlexItem flex={1}>{left}</FlexItem>
      <FlexItem flex={3}>{right}</FlexItem>
    </Flex>
  );
};
