import React from 'react';

export const TractionBannerImage = () => {
  return (
    <svg
      width="282"
      height="160"
      viewBox="0 0 282 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M277.121 84.7825C271.42 66.9802 252.199 41.0016 236.466 25.881C216.32 6.5182 166.508 -21.1075 122.309 25.881C78.1106 72.8694 30.5358 47.4881 9.81674 84.7825C-10.9023 122.077 5.91687 146.342 17.0124 161.036C28.1079 175.73 53.4113 197.104 103.322 209.13C143.178 218.733 190.744 213.829 227.606 197.104C264.469 180.38 294.02 139.616 277.121 84.7825Z"
          fill="#F8F9FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.3245 18.4147C66.5336 15.9034 56.8991 13.1098 46.7391 21.8816C36.5792 30.6534 42.0689 56.1527 67.0309 46.9259C91.9929 37.699 102.115 20.926 84.3245 18.4147Z"
          fill="#F8F9FA"
        />
        <path
          d="M213.112 48.8826V126.088H103.602V48.8826C103.602 43.121 108.352 38.5117 114.001 38.5117H202.585C208.362 38.5117 213.112 43.121 213.112 48.8826Z"
          fill="#CED9F0"
        />
        <path
          d="M198.478 112.516H118.238C114.901 112.516 112.076 109.828 112.076 106.371V53.8762C112.076 50.5472 114.772 47.7305 118.238 47.7305H198.478C201.816 47.7305 204.64 50.4192 204.64 53.8762V106.371C204.64 109.7 201.816 112.516 198.478 112.516Z"
          fill="#172B4E"
        />
        <path
          d="M228.519 121.148C228.519 125.245 226.85 128.958 224.154 131.647C221.458 134.336 217.734 136 213.626 136H103.089C94.8723 136 88.1964 129.342 88.1964 121.148H144.556V123.069C144.556 124.477 145.712 125.629 147.124 125.629H169.591C171.003 125.629 172.159 124.477 172.159 123.069V121.148H228.519Z"
          fill="#A1BAED"
        />
        <path
          d="M139.806 99.5844H135.569C133.13 99.5844 131.204 97.6639 131.204 95.2312V88.1893C131.204 85.7566 133.13 83.8361 135.569 83.8361H139.806C142.245 83.8361 144.171 85.7566 144.171 88.1893V95.2312C144.171 97.6639 142.245 99.5844 139.806 99.5844Z"
          fill="#FFC566"
        />
        <path
          d="M160.347 99.5847H156.11C153.671 99.5847 151.745 97.6641 151.745 95.2315V80.5074C151.745 78.0747 153.671 76.1542 156.11 76.1542H160.347C162.786 76.1542 164.712 78.0747 164.712 80.5074V95.3595C164.583 97.6641 162.657 99.5847 160.347 99.5847Z"
          fill="#729DF4"
        />
        <path
          d="M180.76 99.5846H176.523C174.084 99.5846 172.158 97.6641 172.158 95.2314V64.887C172.158 62.4543 174.084 60.5338 176.523 60.5338H180.76C183.199 60.5338 185.125 62.4543 185.125 64.887V95.2314C185.125 97.6641 183.199 99.5846 180.76 99.5846Z"
          fill="#729DF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.7481 24.1608C97.7481 23.5127 97.2227 22.9873 96.5746 22.9873C95.9265 22.9873 95.4011 23.5127 95.4011 24.1608V24.8698C95.4011 28.6411 92.3438 31.6984 88.5724 31.6984C87.9243 31.6984 87.3989 32.2238 87.3989 32.8719C87.3989 33.52 87.9243 34.0454 88.5724 34.0454C92.3438 34.0454 95.4011 37.1027 95.4011 40.874V41.5813C95.4011 42.2294 95.9265 42.7548 96.5746 42.7548C97.2227 42.7548 97.7481 42.2294 97.7481 41.5813V40.8766C97.7481 37.1039 100.807 34.0454 104.579 34.0454C105.227 34.0454 105.753 33.52 105.753 32.8719C105.753 32.2238 105.227 31.6984 104.579 31.6984C100.807 31.6984 97.7481 28.6399 97.7481 24.8672V24.1608Z"
          fill="#FFC566"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.951 25.3481C125.951 24.9409 125.621 24.6108 125.214 24.6108C124.807 24.6108 124.477 24.9409 124.477 25.3481V25.7923C124.477 28.1615 122.556 30.0821 120.187 30.0821C119.78 30.0821 119.45 30.4122 119.45 30.8193C119.45 31.2265 119.78 31.5565 120.187 31.5565C122.556 31.5565 124.477 33.4771 124.477 35.8463V36.2901C124.477 36.6973 124.807 37.0273 125.214 37.0273C125.621 37.0273 125.951 36.6973 125.951 36.2901V35.8465C125.951 33.4772 127.872 31.5565 130.241 31.5565C130.648 31.5565 130.978 31.2265 130.978 30.8193C130.978 30.4122 130.648 30.0821 130.241 30.0821C127.872 30.0821 125.951 28.1614 125.951 25.7922V25.3481Z"
          fill="#B2C6E5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="282" height="160" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
