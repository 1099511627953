import { Query } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';

import { ID } from '~/types';

import { ListingApi } from '../api';
import { ListingState, ListingStore } from '../stores';
import { EditableUnitFields, Unit } from '../types';

@Injectable()
export class ListingService extends Query<ListingState> {
  public readonly listing$ = this.select('listing');

  constructor(protected readonly store: ListingStore, private readonly _listingApi: ListingApi) {
    super(store);
  }

  public async loadListing(projectSlug: ID, organisationToken: ID): Promise<void> {
    const listing = await this._listingApi.loadListing(projectSlug, organisationToken);
    this.store.update({ listing });
  }

  public async updateUnit(
    unitId: ID,
    values: Partial<EditableUnitFields>,
    organisationToken: ID,
  ): Promise<Unit> {
    const updatedUnit = await this._listingApi.updateUnit(unitId, values, organisationToken);
    this.store.updateUnit(updatedUnit);
    return updatedUnit;
  }

  public async createUnit(values: EditableUnitFields): Promise<Unit> {
    const createdUnit = await this._listingApi.createUnit(this.store.getValue().listing.id, values);
    this.store.addUnit(createdUnit);
    return createdUnit;
  }

  public async destroyUnit(unitId: ID): Promise<void> {
    await this._listingApi.destroyUnit(unitId);
    this.store.destroyUnit(unitId);
  }
}
