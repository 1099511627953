import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  LoadingPlaceholder,
  Margin,
  Select,
  Shade,
  ShowDateTime,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { Fragment, useState } from 'react';

import { CapitalFirst } from '~/components';
import { AddressSearch } from '~/pods/crm/components';
import { Address, Lead, LeadOrigin } from '~/pods/crm/types';
import { useFetchCampaigns, useUpdateLead } from '~/pods/crm/utils';
import { createTranslate, run, useAuth } from '~/utils';

import { InfoRow } from './InfoRow';
import { Section } from './Section';
import { TextWithFallback } from './TextWithFallback';

const te = createTranslate('pods.crm');
const tt = createTranslate('pods.crm.routes.lead.components.lead_details.about');

export const AboutPlaceHolder = () => {
  return (
    <Section label={tt('title')} onClickEdit={() => {}}>
      <Fragment>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="30%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="50%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="70%" />
        </Margin>
      </Fragment>
    </Section>
  );
};

interface AboutForm {
  address: Address;
  origin: LeadOrigin;
}

interface AboutProps {
  lead: Lead;
}

export const About = ({ lead }: AboutProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const aboutForm = useForm<AboutForm>({
    origin: lead.origin,
    address: lead.address,
  });

  const { user } = useAuth();

  const { isLoading: isUpdating, updateLead } = useUpdateLead();
  const { isCampaignsLoading, campaigns } = useFetchCampaigns({
    skip: user?.isAgent ?? true,
  });

  const handleSaveLeadInfo = async () => {
    const { origin, address } = aboutForm.values;
    if (isEditingAddress) {
      setInvalidAddress(true);
    } else {
      await updateLead({
        id: lead.id,
        heardFrom: origin,
        address:
          address == null
            ? undefined
            : {
                zipCode: address.zipCode,
                countryCode: address.countryCode,
                city: address.city,
                street: address.street,
              },
      });
      setIsEditing(false);
    }
  };

  return (
    <Section label={tt('title')} onClickEdit={() => setIsEditing(true)}>
      <Fragment>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="calendar">
            <CapitalFirst>
              {/* todo: text/date format */}
              {lead.createdAt != null ? (
                <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>
                  {tt('creation')}: {new Date(lead.createdAt)}
                </Text>
              ) : (
                <Text shade={Shade.MEDIUM}>{tt('fallback.creation')}</Text>
              )}
            </CapitalFirst>
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="users">
            <TextWithFallback
              value={
                lead.source != null ? te(`lead_source.${lead.source?.toLowerCase()}`) : undefined
              }
              fallback={tt('fallback.source')}
            />
          </InfoRow>
        </Margin>
        {!user?.isAgent && (
          <Margin size={{ bottom: Size.SMALL }}>
            <InfoRow icon="media-kit">
              {isCampaignsLoading ? (
                <LoadingPlaceholder />
              ) : (
                <TextWithFallback
                  value={
                    lead.campaignToken != null
                      ? campaigns.find((c) => c.token === lead.campaignToken)?.name
                      : undefined
                  }
                  fallback={tt('no_campaign')}
                />
              )}
            </InfoRow>
          </Margin>
        )}
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="target">
            {run(() => {
              if (isEditing) {
                return (
                  <Select
                    name="origin"
                    onChange={aboutForm.set}
                    value={aboutForm.get}
                    size={Size.SMALL}
                    options={Object.values(LeadOrigin).map((v) => ({
                      value: v,
                      label: te(`lead_origin.${v.toLowerCase()}`),
                    }))}
                  />
                );
              } else {
                return (
                  <TextWithFallback
                    value={
                      lead.origin != null
                        ? tt('origin', {
                            origin: te(`lead_origin.${lead.origin.toLowerCase()}`),
                          })
                        : undefined
                    }
                    fallback={tt('fallback.origin')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="map-pin">
            {run(() => {
              if (isEditing) {
                return (
                  <AddressSearch
                    error={invalidAddress ? tt('address_error') : undefined}
                    size={Size.SMALL}
                    name="address"
                    value={aboutForm.values.address}
                    onPickAddress={(v) => {
                      setInvalidAddress(false);
                      setIsEditingAddress(false);
                      aboutForm.set(v, 'address');
                    }}
                    onChange={(v) => {
                      if (v === '') {
                        setIsEditingAddress(false);
                      } else {
                        setIsEditingAddress(true);
                      }
                    }}
                  />
                );
              } else {
                return (
                  <TextWithFallback
                    value={
                      lead.address != null && lead.address?.fullAddress !== ''
                        ? lead.address?.fullAddress
                        : undefined
                    }
                    fallback={tt('fallback.address')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
      </Fragment>
      {isEditing ? (
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Margin size={{ right: Size.EXTRA_SMALL }}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  aboutForm.reset();
                }}
                size={Size.SMALL}
                tier={Tier.TERTIARY}>
                {tt('cancel_button')}
              </Button>
            </Margin>
          </FlexItem>
          <FlexItem>
            <Button
              onClick={handleSaveLeadInfo}
              trailing={isUpdating ? <Spinner size={Size.SMALL} inversed /> : null}
              disabled={isUpdating}
              size={Size.SMALL}
              category={Category.INFO}>
              {tt('save_button')}
            </Button>
          </FlexItem>
        </Flex>
      ) : null}
    </Section>
  );
};
