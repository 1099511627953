import gql from 'fraql';

import { Estate } from '~/pods/dashboard/types';
import { ErrorType } from '~/types';
import { buildError, useBackofficeQuery } from '~/utils';

interface CartQueryData {
  cart: Array<Estate>;
}

const CartQuery = gql`
  query CartQuery {
    cart {
      id
    }
  }
`;

export function useFetchCart(): { isLoading: boolean; estates?: Array<Estate>; error?: ErrorType } {
  const res = useBackofficeQuery<CartQueryData, null>({
    query: CartQuery,
  });

  return { isLoading: res.isLoading, estates: res.data?.cart, error: buildError(res.error) };
}
