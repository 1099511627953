import { HookService, onDidMount, state } from '@tokamakjs/react';
import { pick } from 'lodash';
import { Subscription } from 'rxjs';

import { ID, OrganisationRole } from '~/types';
import { useAuth, useIntercom, useMosaicNavigation } from '~/utils';

import { FiltersService, ListingService, RouterService } from '../../services';
import { EditableUnitFields, Listing, Unit } from '../../types';

@HookService()
export class ListingController {
  @state private _listing?: Listing;
  // Loading the page
  @state private _isLoading = true;
  // Processing a create/update/delete request. Might need better name
  @state private _isProcessing = false;
  @state private _intercom = useIntercom();
  @state private _user = useAuth().user;
  @state private _orgToken = useMosaicNavigation().organisationToken;

  private _subs = new Subscription();

  constructor(
    private readonly _router: RouterService,
    private readonly _listingService: ListingService,
    private readonly _filters: FiltersService,
  ) {}

  get isAdmin() {
    return this._user?.role === OrganisationRole.ADMIN;
  }

  get user() {
    return this._user;
  }

  get showIntercom() {
    return this._intercom.showIntercom;
  }

  get projectSlug() {
    return this._router.getParam('projectSlug');
  }

  get activeUnit(): Unit | undefined {
    return (this._listing?.units ?? []).find((u) => u.id === this._filters.activeUnitId);
  }

  get filters() {
    return this._filters.filters;
  }

  get listing() {
    return this._listing;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isProcessing() {
    return this._isProcessing;
  }

  @onDidMount()
  protected subscribe(): VoidFunction {
    this._subs.add(this._listingService.listing$.subscribe((v) => (this._listing = v)));

    return () => {
      this._subs.unsubscribe();
    };
  }

  @onDidMount()
  protected async loadUnits() {
    this._isLoading = true;
    const projectSlug = this._router.getParam('projectSlug');
    await this._listingService.loadListing(projectSlug!, this._orgToken!);
    this._isLoading = false;
  }

  @onDidMount()
  protected setPage() {
    const filters = this.filters;
    if (filters.page.value == null) {
      filters.page.set('1');
    }
  }

  public setActiveUnitId(id?: string): void {
    this._filters.setActiveUnitId(id);
  }

  public async updateUnit(unitId: ID, rawValues: EditableUnitFields): Promise<Unit> {
    this._isProcessing = true;
    const values = this._user?.isAgent ? pick(rawValues, ['availability', 'soldAt']) : rawValues;
    const unit = await this._listingService.updateUnit(unitId, values, this._orgToken!);
    this._isProcessing = false;

    return unit;
  }

  public async createUnit(values: EditableUnitFields): Promise<Unit> {
    this._isProcessing = true;
    const unit = await this._listingService.createUnit(values);
    this._isProcessing = false;

    return unit;
  }

  public async destroyUnit(unitId: ID): Promise<void> {
    this._isProcessing = true;
    await this._listingService.destroyUnit(unitId);
    this._isProcessing = false;
  }
}
