import {
  Button,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  Margin,
  Size,
  Tier,
  Tooltip,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import React from 'react';

import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.listing_manager.components.listing_table');

interface TableRowActionsProps {
  onClickDelete: VoidFunction;
  isReadonly: boolean;
  nonDeletable: boolean;
}

export const TableRowActions = ({
  onClickDelete,
  isReadonly,
  nonDeletable,
}: TableRowActionsProps) => {
  return (
    <Flex>
      <FlexItem>
        <Margin size={{ left: Size.SMALL }}>
          {run(() => {
            if (isReadonly) {
              return (
                <Tooltip content={tt('locked_provider')}>
                  <Button disabled size={Size.SMALL} tier={Tier.SECONDARY}>
                    {tt('edit')}
                  </Button>
                </Tooltip>
              );
            } else {
              return (
                <Button size={Size.SMALL} tier={Tier.SECONDARY}>
                  {tt('edit')}
                </Button>
              );
            }
          })}
        </Margin>
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        {run(() => {
          if (isReadonly) {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Button size={Size.SMALL} disabled leading={<Icon name={'trash'} />} />
              </div>
            );
          } else if (nonDeletable) {
            return (
              <Tooltip content={tt('locked_revo')}>
                {/* Todo: Hacky stuff below -> Wrapping Drylus <Button /> with <div></div> to stop event propagation.
                Since <Button> probably doesn't accept onClick when disabled */}
                <div onClick={(e) => e.stopPropagation()}>
                  <Button size={Size.SMALL} disabled leading={<Icon name={'trash'} />} />
                </div>
              </Tooltip>
            );
          } else {
            return (
              <Button
                size={Size.SMALL}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDelete();
                }}
                leading={<Icon name={'trash'} />}
              />
            );
          }
        })}
      </FlexItem>
    </Flex>
  );
};
