import gql from 'fraql';
import { useState } from 'react';

import { ID } from '~/types';
import { useMosaicMutation } from '~/utils';

import { DiffusionData } from '../domain';

const UpdateDiffusionMutation = gql`
  mutation UpdateDiffusionMutation($input: UpdateDiffusionInput!) {
    updateDiffusion(input: $input) {
      diffusion {
        id
      }
    }
  }
`;

interface UpdateDiffusionInput {
  diffusionId: ID;
  endDiffusionDate?: string;
  startDiffusionDate?: string;
}

interface UseUpdateDiffusionReturnType {
  isUpdatingDiffusion: boolean;
  updateDiffusion: (data: Partial<DiffusionData> & { id: ID }) => Promise<void>;
}

export function useUpdateDiffusion(): UseUpdateDiffusionReturnType {
  const { executeMutation } = useMosaicMutation(UpdateDiffusionMutation);
  const [isLoading, setIsLoading] = useState(false);

  const updateDiffusion = async (data: Partial<DiffusionData> & { id: ID }): Promise<void> => {
    setIsLoading(true);

    const input: UpdateDiffusionInput = {
      diffusionId: data.id,
      endDiffusionDate: data.endDiffusionDate,
      startDiffusionDate: data.startDiffusionDate,
    };

    const res = await executeMutation({ input });

    setIsLoading(false);

    if (res.error != null) {
      throw res.error;
    }
  };

  return { isUpdatingDiffusion: isLoading, updateDiffusion };
}
