import dayjs from 'dayjs';
import morphey, { fromKey, fromValue } from 'morphey';

import {
  InsightsLead,
  LeadFromServer,
  Project,
  ProjectFromServer,
  SessionFromServer,
} from '~/pods/insights/types';

const leadFromServer = (lead: LeadFromServer): InsightsLead => ({
  createdAt: fromValue(dayjs(lead.created_at)),
  campaignToken: fromKey('campaignToken'),
});

export function transformLeads(leads: Array<LeadFromServer>) {
  return leads.map((lead) => morphey(lead, leadFromServer));
}

const sessionFromServer = (session: SessionFromServer) => ({
  path: fromKey('collected.revo.breadcrumbs').using(
    (breadcrumbs: SessionFromServer['collected']['revo']['breadcrumbs']) => {
      return (
        breadcrumbs
          ?.filter((b) => b.type === 'unit')
          .map((unit) => ({
            id: unit.id,
            type: unit.type,
            timestamp: unit.timestamp != null ? dayjs(unit.timestamp) : dayjs(session.createdAt),
          })) ?? []
      );
    },
  ),
  time: fromKey('collected.revo.time.entities').using(
    (entities: SessionFromServer['collected']['revo']['time']['entities']) => {
      return entities?.filter((e) => e.type === 'unit') ?? [];
    },
  ),
  createdAt: fromValue(dayjs(session.createdAt)),
  campaignToken: fromKey('collected.campaign.token'),
  referrer: fromKey('collected.referrer'),
  ipData: fromKey('ipData'),
  userAgent: fromKey('collected.userAgent'),
});

export function transformSessions(sessions: Array<SessionFromServer>) {
  return sessions.map((session) => morphey(session, sessionFromServer));
}

const projectFromServer = (project: ProjectFromServer): Project => ({
  id: fromKey('id'),
  dealsGoalStartDate: fromValue(
    project.dealsGoalStartDate ? dayjs(project.dealsGoalStartDate) : null,
  ),
  dealsGoalEndDate: fromValue(project.dealsGoalEndDate ? dayjs(project.dealsGoalEndDate) : null),
});

export function transformProject(project?: ProjectFromServer) {
  if (project == null) {
    return project;
  }
  return morphey(project, projectFromServer);
}
