import sv from '@drawbotics/drylus-style-vars';
import {
  Avatar,
  Button,
  Category,
  Color,
  Dropdown,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Padding,
  Panel,
  PanelBody,
  Position,
  RoundIcon,
  Select,
  Shade,
  Size,
  Spinner,
  Table,
  Text,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import dayjs from 'dayjs';
import { css, cx } from 'emotion';
import gql from 'fraql';
import { capitalize } from 'lodash';
import React, { Fragment, ReactNode, useState } from 'react';

import { AssignedProject } from '~/pods/agency/types';
import { Licenses } from '~/pods/agency/utils/hooks';
import { Id, InvitationStatus, OrganisationRole } from '~/types';
import { Invitation } from '~/types';
import { createTranslate, extractGQLErrorMessages, useAuth, useMosaicMutation } from '~/utils';

import { OrganisationWithProjects, ProjectAccessOption } from './ProjectAccessOption';
import { RemoveTeamMemberModal } from './RemoveTeamMemberModal';

const tt = createTranslate('pods.settings.routes.company_team.components.team_table');

const styles = {
  projectsSelect: css`
    pointer-events: none;

    [data-element='select'] {
      color: ${sv.neutralDarkest};
    }
  `,
  disabled: css`
    opacity: 0.5;
  `,
};

interface UpdateRoleInput {
  user: {
    userId: string;
    role: OrganisationRole;
  };
}

interface UpdateRolePayload {
  updateUser: {
    user: {
      id: string;
      fullName: string;
      role: OrganisationRole;
    };
  };
}

const updateRoleMutation = gql`
  mutation updateRole($user: UpdateUserInput!) {
    updateUser(input: $user) {
      user {
        id
        fullName @computed(type: User)
        role
      }
    }
  }
`;

interface ResendInvitationVariables {
  invitationId: string;
}

interface ResendInvitationPayload {
  resendInvitation: {
    invitation: Invitation;
  };
}

const resendInvitationMutation = gql`
  mutation resendInvitation($invitationId: ID!) {
    resendInvitation(input: { invitationId: $invitationId }) {
      invitation {
        id
        email
      }
    }
  }
`;

interface AssignUserToProjectVariables {
  userId: string;
  projectId: string;
}

interface AssignUserToProjectPayload {
  assignAgentToProject: {
    assignedAgent: {
      id: Id;
    };
  };
}

const assignUserToProjectMutation = gql`
  mutation AssignUserToProject($userId: ID!, $projectId: ID!) {
    assignAgentToProject(input: { userId: $userId, projectId: $projectId }) {
      assignedAgent {
        id
      }
    }
  }
`;

interface UnassignUserFromProjectVariables {
  userId: string;
  projectId: string;
}

interface UnassignUserFromProjectPayload {
  unassignAgentToProject: {
    unassignedAgent: {
      id: Id;
    };
  };
}
const unassignUserFromProjectMutation = gql`
  mutation UnassignUserFromProject($userId: ID!, $projectId: ID!) {
    unassignAgentFromProject(input: { userId: $userId, projectId: $projectId }) {
      unassignedAgent: agent {
        id
      }
    }
  }
`;

const STATUS_DATE_FORMAT = 'DD/MM/YYYY HH:mm';

function _getContentForStatus(status: InvitationStatus, invitedOn: undefined | string): ReactNode {
  if (status === InvitationStatus.INITIATED) {
    return (
      <Tooltip content={tt('invitation_error')}>
        <RoundIcon color={Color.RED} size={Size.LARGE} name="alert-triangle" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        content={
          invitedOn ? (
            <Text
              dateOptions={{
                format: STATUS_DATE_FORMAT,
              }}
              inversed>
              {tt('status_tooltips.invited_on')} {new Date(invitedOn)}
            </Text>
          ) : (
            tt('not_validated')
          )
        }>
        <RoundIcon size={Size.LARGE} name="clock" />
      </Tooltip>
    );
  }
}

export interface Member {
  id: string;
  uniqueId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: OrganisationRole;
  type: 'invitation' | 'member';
  memberSince?: string;
  status?: InvitationStatus;
  assignedProjects?: Array<{ id: string }>;
  deactivatedAt?: string;
  invitedOn?: string;
}

interface TeamTableProps {
  isLoading?: boolean;
  members?: Array<Member>;
  isAgency?: true;
  projects?: Array<AssignedProject>;
  onSuccessfulMutation?: () => void;
  licenses?: Licenses;
}

const COMPANY_ROLES = [OrganisationRole.ADMIN, OrganisationRole.MEMBER];
const AGENCY_ROLES = [OrganisationRole.AGENCY_SALES_MANAGER, OrganisationRole.AGENCY_SALES_AGENT];

export const TeamTable = ({
  isAgency,
  members,
  isLoading,
  projects,
  onSuccessfulMutation,
  licenses,
}: TeamTableProps) => {
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const [resendingInProgress, setResendingInProgress] = useState<Array<string>>([]);

  const {
    res: { fetching: isSendingInvitation },
    executeMutation: sendInvitation,
  } = useMosaicMutation<ResendInvitationPayload, ResendInvitationVariables>(
    resendInvitationMutation,
  );
  const { executeMutation: updateRole } = useMosaicMutation<UpdateRolePayload, UpdateRoleInput>(
    updateRoleMutation,
  );

  const { executeMutation: assignUserToProject } = useMosaicMutation<
    AssignUserToProjectPayload,
    AssignUserToProjectVariables
  >(assignUserToProjectMutation);

  const { executeMutation: unassignUserFromProject } = useMosaicMutation<
    UnassignUserFromProjectPayload,
    UnassignUserFromProjectVariables
  >(unassignUserFromProjectMutation);

  const [memberToRemove, setMemberToRemove] = useState<Member | undefined>();

  const handleResendInvitation = async (memberId: string) => {
    setResendingInProgress((state) => [...state, memberId]);

    const { error, data } = await sendInvitation({ invitationId: memberId });

    if (error) {
      showAlert({
        text: tt('invitation_error_alert'),
        category: Category.DANGER,
      });
    } else {
      showAlert({
        text: tt('send_invitation_success', { member: data?.resendInvitation.invitation.email! }),
        category: Category.SUCCESS,
      });
    }
    setResendingInProgress((state) => [...state.filter((id) => id !== memberId)]);
  };

  const handleOnChangeRole = async ({ userId, role }: UpdateRoleInput['user']) => {
    const { error, data } = await updateRole({ user: { userId, role } });

    if (error != null) {
      const { message } = extractGQLErrorMessages(error);
      if (message === 'not_found') {
        showAlert({
          text: tt('errors.not_found'),
          category: Category.DANGER,
        });
      } else if (message === 'unauthorized') {
        showAlert({
          text: tt('errors.unauthorized'),
          category: Category.DANGER,
        });
      } else if (message === 'invalid_params') {
        showAlert({
          text: tt('errors.invalid_params'),
          category: Category.DANGER,
        });
      } else if (message === 'organisation_mismatch') {
        showAlert({
          text: tt('errors.organisation_mismatch'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('errors.role_update_error'),
          category: Category.DANGER,
        });
      }
    } else {
      showAlert({
        text: `${tt('role_update_success', {
          user: data?.updateUser.user.fullName ?? '',
          role: tt(data?.updateUser.user.role ?? ''),
        })} ${tt('role_update_info')}`,
        category: Category.INFO,
        hideDelay: 8000,
      });
    }
  };

  const allowedRoles = isAgency ? AGENCY_ROLES : COMPANY_ROLES;

  const projectsByOrganisation: Array<OrganisationWithProjects> = projects
    ? Object.values(
      projects.reduce<{ [key: string]: OrganisationWithProjects }>((previousValue, project) => {
        const reducedOrganisation = previousValue[project.organisation.id];

        const mappedProject = {
          id: project.id,
          name: project.name,
        };

        if (reducedOrganisation == null) {
          previousValue[project.organisation.id] = {
            publicToken: project.organisation.publicToken,
            id: project.organisation.id,
            name: project.organisation.name,
            projects: [mappedProject],
          };
        } else {
          reducedOrganisation.projects.push(mappedProject);
        }

        return previousValue;
      }, {}),
    )
    : [];

  const data = members?.map((member) => {
    const { assignedProjects } = member;

    return {
      id: member.uniqueId,
      name: (
        <ListTile
          className={member.deactivatedAt ? styles.disabled : undefined}
          leading={<Avatar text={`${member.firstName[0]}${member.lastName[0]}`} />}
          subtitle={
            <Text size={Size.SMALL} shade={Shade.LIGHT}>
              {member.email}
            </Text>
          }
          title={<Text size={Size.SMALL}>{member.fullName}</Text>}
        />
      ),
      role:
        // todo: simplify conditions and remove nested ternaries
        (!isAgency && user?.role === OrganisationRole.ADMIN) ||
          (isAgency && user?.role === OrganisationRole.AGENCY_SALES_MANAGER) ? (
          member.deactivatedAt || (user && user.id === member.id) ? (
            member.deactivatedAt ?
              <Select
                options={allowedRoles.map((r) => ({
                  value: r,
                  label: capitalize(tt(r)),
                }))}
                value={member.role}
                onChange={(role) => handleOnChangeRole({ userId: member.id, role })}
                size={Size.SMALL}
                disabled
              />

              : <Tooltip content={member.deactivatedAt ? undefined : tt('errors.invalid_params')}>
                <Select
                  options={allowedRoles.map((r) => ({
                    value: r,
                    label: capitalize(tt(r)),
                  }))}
                  value={member.role}
                  onChange={(role) => handleOnChangeRole({ userId: member.id, role })}
                  size={Size.SMALL}
                  disabled
                />
              </Tooltip>



          ) : (
            <Select
              options={allowedRoles.map((r) => ({
                value: r,
                label: capitalize(tt(r)),
              }))}
              value={member.role}
              onChange={(role) => handleOnChangeRole({ userId: member.id, role })}
              size={Size.SMALL}
            />
          )
        ) : (
          <Text>{tt(member.role)}</Text>
        ),
      status: (() => {
        if (member.deactivatedAt) {
          return (
            <Tooltip
              content={
                <Text dateOptions={{ format: STATUS_DATE_FORMAT }} inversed>
                  {tt('status_tooltips.removed_since')} {new Date(member.deactivatedAt)}
                </Text>
              }>
              <Button
                disabled
                size={Size.SMALL}
                category={Category.DANGER}
                leading={<Icon name="slash" />}
              />
            </Tooltip>
          );
        } else if (member.memberSince) {
          return (
            <Tooltip
              content={
                <Text dateOptions={{ format: STATUS_DATE_FORMAT }} inversed>
                  {tt('status_tooltips.member_since')} {new Date(member.memberSince)}
                </Text>
              }>
              <RoundIcon size={Size.LARGE} name="check" color={Color.GREEN} />
            </Tooltip>
          );
        } else if (
          member.status === InvitationStatus.INITIATED ||
          member.status === InvitationStatus.SENT
        ) {
          if (!isAgency || (isAgency && user?.role === OrganisationRole.AGENCY_SALES_MANAGER)) {
            return (
              <Flex justify={FlexJustify.START}>
                <FlexItem>{_getContentForStatus(member.status!, member.invitedOn)}</FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Tooltip content={tt('resend_invitation')}>
                    <Button
                      onClick={() => handleResendInvitation(member.id)}
                      size={Size.SMALL}
                      category={Category.INFO}
                      leading={
                        isSendingInvitation && resendingInProgress.includes(member.id) ? (
                          <Spinner inversed size={Size.SMALL} />
                        ) : (
                          <Icon name="mail" />
                        )
                      }
                    />
                  </Tooltip>
                </FlexItem>
              </Flex>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      })(),
      memberSince: member.memberSince && (
        <Text className={member.deactivatedAt ? styles.disabled : undefined}>
          {dayjs(member.memberSince).format('MMMM Do YYYY')}
        </Text>
      ),
      assignedProjects:
        projects != null && assignedProjects ? (
          <Dropdown
            side={Position.LEFT}
            trigger={
              <Select
                size={Size.SMALL}
                onChange={() => { }}
                placeholder={
                  assignedProjects!.length === 0 ? tt('projects') : `${assignedProjects!.length}`
                }
                options={[]}
                className={cx(styles.projectsSelect)}
              />
            }>
            {projectsByOrganisation.map((organisation) => {
              return (
                <ProjectAccessOption
                  disabled={
                    licenses?.[organisation.publicToken] === 0 || Boolean(member.deactivatedAt)
                  }
                  key={organisation.id}
                  organisationWithProjects={organisation}
                  projectsAssignedToUser={assignedProjects}
                  onChange={async (action, projectId) => {
                    if (action == 'assign') {
                      await assignUserToProject({
                        userId: member.id,
                        projectId,
                      });
                    } else {
                      await unassignUserFromProject({
                        userId: member.id,
                        projectId,
                      });
                    }

                    onSuccessfulMutation?.();
                  }}
                />
              );
            })}
          </Dropdown>
        ) : null,
      remove: (
        <Padding size={{ horizontal: Size.EXTRA_SMALL }}>
          <Button
            disabled={
              (member.id === user?.id) ||
              (isAgency && user?.role !== OrganisationRole.AGENCY_SALES_MANAGER) ||
              (!isAgency && user?.role !== OrganisationRole.ADMIN) ||
              (member.status === InvitationStatus.INITIATED) ||
              (member.status === InvitationStatus.SENT) ||
              Boolean(member.deactivatedAt)
            }
            leading={<Icon name="trash" />}
            category={Category.DANGER}
            size={Size.SMALL}
            tier={Tier.SECONDARY}
            onClick={() => setMemberToRemove(member)}
          />
        </Padding>
      ),
    };
  });

  const sortedData = data?.sort((a, b) => b.id.localeCompare(a.id));

  return (
    <Fragment>
      <Panel
        body={
          <PanelBody noPadding>
            <Table
              isLoading={isLoading}
              loadingRows={6}
              header={[
                { label: tt('name'), value: 'name' },
                { label: tt('role'), value: 'role' },
                { label: tt('status'), value: 'status' },
                { label: tt('member_since'), value: 'memberSince' },
                ...(projects && user?.role === OrganisationRole.AGENCY_SALES_MANAGER
                  ? [{ label: tt('access_to_projects'), value: 'assignedProjects' }]
                  : []),
                { label: tt('remove'), value: 'remove' },
              ]}
              data={sortedData}
              emptyContent={data == null ? <EmptyState description={tt('no_members_yet')} /> : null}
            />
          </PanelBody>
        }
      />
      <RemoveTeamMemberModal
        onSuccess={() => onSuccessfulMutation?.()}
        onClose={() => setMemberToRemove(undefined)}
        member={memberToRemove}
      />
    </Fragment>
  );
};
