import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexAlign, FlexItem, FlexJustify, Padding, Size } from '@drawbotics/react-drylus';
import { ResponsiveLine } from '@nivo/line';
import { css } from 'emotion';
import React from 'react';

import { DateRange, InsightsSession, LineData } from '~/pods/insights/types';
import { LineTheme, compressToNPoints, useLoadNumberOfSessions } from '~/pods/insights/utils';
import { getYGridLines } from '~/pods/insights/utils/get-y-grid-lines';
import { run } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { LineLabel } from '../LineLabel';
import { Text } from '../Text';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';
import { EmptyChart } from './EmptyChart';
import { SmallChart } from './SmallChart';

const t = createTranslate('pods.insights_reports.components.charts');

const styles = {
  chartContainer: css`
    width: 100%;
    height: calc(100% - 20px);
    position: relative;
  `,
};

interface SmallLayoutProps {
  title: string;
  data: LineData;
}

const SmallLayout = ({ title, data }: SmallLayoutProps) => {
  const amount = data.reduce((memo, d) => d.y + memo, 0);
  return (
    <ChartTile>
      <ChartTitle>{title}</ChartTitle>
      {run(() => {
        if (amount <= 0) {
          return (
            <Padding size={Size.EXTRA_SMALL}>
              <EmptyChart message={t('not_enough_data')} small />
            </Padding>
          );
        } else {
          return (
            <Flex align={FlexAlign.CENTER}>
              <FlexItem>
                <Padding size={{ top: Size.EXTRA_SMALL, right: Size.SMALL }}>
                  <Text light style={{ fontSize: '22pt' }}>
                    {amount}
                  </Text>
                </Padding>
              </FlexItem>
              <FlexItem
                style={{
                  position: 'relative',
                  top: 1,
                  width: '60%',
                  height: 35,
                }}>
                <SmallChart color={sv.green} width="100%" height="100%" data={data} />
              </FlexItem>
            </Flex>
          );
        }
      })}
    </ChartTile>
  );
};

interface NumberOfSessionsProps {
  sessions: Array<InsightsSession>;
  dateRange: DateRange;
  small?: boolean;
}

export const NumberOfSessions = ({ sessions, dateRange, small }: NumberOfSessionsProps) => {
  const { sessionsData, prevSessionsData } = useLoadNumberOfSessions(sessions, dateRange);
  const sessionsGridLines = getYGridLines(sessionsData);
  const prevSessionsGridLines = getYGridLines(prevSessionsData);
  const finalGridLines =
    Math.max(...sessionsGridLines) > Math.max(...prevSessionsGridLines)
      ? sessionsGridLines
      : prevSessionsGridLines;

  if (small) {
    return (
      <SmallLayout
        title={t('number_of_sessions.title_small')}
        data={compressToNPoints(sessionsData, 12)}
      />
    );
  }

  return (
    <ChartTile>
      <ChartTitle>{t('number_of_sessions.title')}</ChartTitle>
      <Padding size={{ top: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <LineLabel color={sv.blue} label={t('number_of_sessions.sessions')} />
          </FlexItem>
          <FlexItem>
            <LineLabel color={sv.blueLight} label={t('number_of_sessions.prev_sessions')} />
          </FlexItem>
        </Flex>
      </Padding>
      <Padding size={{ vertical: Size.SMALL }} style={{ width: '100%', height: '100%' }}>
        <div className={styles.chartContainer}>
          <ResponsiveLine
            data={[
              { id: 'prev-sessions', color: sv.blueLight, data: prevSessionsData },
              { id: 'sessions', color: sv.blue, data: sessionsData },
            ]}
            yScale={{
              type: 'linear',
              min: finalGridLines[0],
              max: Math.max(...finalGridLines),
            }}
            xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
            axisBottom={{
              format: '%d',
              tickValues: 'every 2 days',
              tickSize: 0,
              tickPadding: 12,
            }}
            curve="monotoneX"
            axisLeft={{ tickValues: finalGridLines, tickSize: 0 }}
            enableGridX={false}
            areaOpacity={0.1}
            enablePoints={false}
            isInteractive={false}
            margin={{ bottom: 25, left: 25, top: 5, right: 25 }}
            colors={{ datum: 'color' }}
            theme={LineTheme}
            // @ts-ignore is not correctly typed
            gridYValues={finalGridLines}
          />
        </div>
      </Padding>
    </ChartTile>
  );
};
