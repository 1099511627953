import { Module } from '@tokamakjs/react';

import { ProjectApi, WebsiteApi } from './api';
import { ProjectService, RouterService, WebsiteService } from './services';
import { ProjectStore, WebsiteStore } from './stores';

@Module({
  imports: [],
  providers: [
    ProjectService,
    WebsiteService,
    ProjectApi,
    ProjectStore,
    RouterService,
    WebsiteApi,
    WebsiteStore,
  ],
  exports: [],
})
export class WebsiteBuilderModule {}
