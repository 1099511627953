import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexJustify, Padding, Size } from '@drawbotics/react-drylus';
import { ResponsiveLine } from '@nivo/line';
import { Dayjs } from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import { InsightsLead } from '~/pods/insights/types';
import { LineTheme, useLoadNumberOfLeadsOverRange } from '~/pods/insights/utils';
import { createAreaLayer } from '~/pods/insights/utils/create-area-layer';
import { getYGridLines } from '~/pods/insights/utils/get-y-grid-lines';
import { createTranslate } from '~/utils/translation';

import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';
import { LineLabel } from '..';

const t = createTranslate('pods.insights_reports.components.charts.number_of_leads');

const styles = {
  chartContainer: css`
    width: 100%;
    height: calc(100% - 25px);
  `,
};

interface NumberOfLeadsProps {
  leads: Array<InsightsLead>;
  dateRange: {
    start: Dayjs;
    end: Dayjs;
  };
}

export const NumberOfLeads = ({ leads, dateRange }: NumberOfLeadsProps) => {
  const { data } = useLoadNumberOfLeadsOverRange(leads, dateRange);
  let gridLines = getYGridLines(data);
  const sevenDaysAgo = new Date(dateRange.end.subtract(7, 'day').format('YYYY-M-DD'));

  if (Math.min(...gridLines) === 0 && Math.max(...gridLines) === 0) {
    // instead of having an ungly single line, try to have something that resembles a chart
    gridLines = [0, 1, 2, 3, 4];
  }

  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ top: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <LineLabel color={sv.neutral} label={t('last_week')} squared />
          </FlexItem>
          <FlexItem>
            <LineLabel color={sv.orange} label={t('leads')} />
          </FlexItem>
        </Flex>
      </Padding>
      <Padding size={{ vertical: Size.EXTRA_SMALL }} style={{ width: '100%', height: '100%' }}>
        <div className={styles.chartContainer}>
          <ResponsiveLine
            data={[{ id: 'string', color: sv.orange, data }]}
            yScale={{ type: 'linear', min: gridLines[0], max: gridLines[gridLines.length - 1] }}
            xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
            axisBottom={{
              format: '%d',
              tickValues: 'every 2 days',
              tickSize: 0,
              tickPadding: 12,
            }}
            axisLeft={{ tickValues: gridLines, tickSize: 0 }}
            curve="monotoneX"
            enableArea
            areaOpacity={0.1}
            enablePoints={false}
            enableGridX={false}
            isInteractive={false}
            margin={{ bottom: 25, left: 25, top: 5, right: 25 }}
            colors={{ datum: 'color' }}
            theme={LineTheme}
            layers={[
              'grid',
              'markers',
              'areas',
              createAreaLayer({ start: sevenDaysAgo, height: Math.max(...gridLines) }),
              'lines',
              'slices',
              'axes',
              'points',
              'legends',
            ]}
            // @ts-ignore is not correctly typed
            gridYValues={gridLines}
          />
        </div>
      </Padding>
    </ChartTile>
  );
};
