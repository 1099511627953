import React from 'react';

export const TypologiesChartPlaceholder = () => {
  return (
    <svg
      width="144"
      height="112"
      viewBox="0 0 144 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.991 71.8484C144.604 25.9383 113.206 -28.9692 28.5866 17.8637C-24.4061 47.24 5.68945 87.767 43.06 103.07C80.4306 118.374 144.604 117.605 143.991 71.8484Z"
        fill="#F6F7FA"
      />
      <rect x="43.8919" y="40.9709" width="61.1081" height="45.5922" fill="#CED9F0" />
      <path
        d="M111.356 42.9126C111.356 47.1845 108.676 50.8349 105 52.233C103.928 52.6214 102.779 52.8544 101.554 52.8544C97.4955 52.8544 93.973 50.2913 92.4414 46.7184C90.9865 50.2913 87.464 52.8544 83.4054 52.8544C80.4955 52.8544 77.9685 51.6116 76.1306 49.5922C75.3649 48.7379 74.7523 47.8058 74.2928 46.7184C72.7613 50.2913 69.3153 52.8544 65.1802 52.8544C62.2703 52.8544 59.5901 51.534 57.8288 49.4369C57.1396 48.6602 56.6036 47.7281 56.1441 46.7961C56.1441 46.7961 56.1441 46.7961 56.1441 46.7184C54.6126 50.2913 51.1667 52.8544 47.1081 52.8544C45.9595 52.8544 44.8874 52.699 43.8919 52.3107C40.1396 50.9903 37.4595 47.4951 37.3063 43.301C37.3063 43.1456 37.3063 42.9903 37.3063 42.9126C37.3063 41.9029 37.4595 40.8932 37.6892 40.0388V40.0388C37.8423 39.4951 38.0721 39.0291 38.3018 38.5631L43.9685 24.1942C45.0405 21.3981 47.7973 19.534 50.7838 19.534H98.1081C101.171 19.534 103.851 21.3981 104.923 24.2718L110.131 37.9417C110.667 38.8738 111.05 39.8058 111.279 40.8932C111.279 41.5146 111.356 42.2136 111.356 42.9126Z"
        fill="#9AAFDA"
      />
      <path
        d="M62.7297 60.3883C57.982 60.3883 54.1532 64.2718 54.1532 69.0874V86.5631H71.3063V69.165C71.3063 64.2718 67.4775 60.3883 62.7297 60.3883Z"
        fill="#222F5B"
      />
      <g opacity="0.33">
        <path
          d="M76.1306 49.5922C75.1351 54.5631 71.0766 58.2136 66.2522 58.2136C62.1937 58.2136 58.6712 55.6505 57.0631 51.8447C55.3784 55.6505 51.6261 58.2136 47.2613 58.2136C46.036 58.2136 44.8874 58.0583 43.8153 57.6699V52.3107C44.8108 52.699 45.8829 52.8544 47.0315 52.8544C51.0901 52.8544 54.6126 50.2913 56.0676 46.7184C56.0676 46.7184 56.0676 46.7184 56.0676 46.7961C56.4504 47.8058 57.0631 48.6602 57.7522 49.4369C59.5901 51.534 62.1937 52.8544 65.1036 52.8544C69.1622 52.8544 72.6847 50.2913 74.2162 46.7184C74.7522 47.8058 75.3649 48.8155 76.1306 49.5922Z"
          fill="#7DA5CC"
        />
      </g>
      <g opacity="0.33">
        <path
          d="M105 52.233V69.5534C102.626 70.4078 100.023 70.9515 97.2658 70.9515C86.2387 70.9515 77.1261 62.8738 75.2883 52.233C75.6712 51.3786 75.9775 50.5243 76.1306 49.5922C77.8919 51.6116 80.4955 52.8544 83.4054 52.8544C87.464 52.8544 90.9865 50.2913 92.4414 46.7184C93.973 50.2913 97.4189 52.8544 101.554 52.8544C102.779 52.8544 103.928 52.6214 105 52.233Z"
          fill="#7DA5CC"
        />
      </g>
      <path
        d="M96.6532 62.5631C110.271 62.5631 121.311 51.3659 121.311 37.5534C121.311 23.7409 110.271 12.5437 96.6532 12.5437C83.0351 12.5437 71.9955 23.7409 71.9955 37.5534C71.9955 51.3659 83.0351 62.5631 96.6532 62.5631Z"
        fill="white"
      />
      <path
        d="M104.617 91.3786H44.045C42.5135 91.3786 41.2117 90.0583 41.2117 88.5049V87.5728C41.2117 86.0194 42.5135 84.699 44.045 84.699H104.694C106.225 84.699 107.527 86.0194 107.527 87.5728V88.5049C107.45 90.1359 106.225 91.3786 104.617 91.3786Z"
        fill="#98ACD9"
      />
      <path
        d="M93.5901 77.398H82.4865C81.2613 77.398 80.3423 76.3883 80.3423 75.2233V65.9806C80.3423 64.7379 81.3378 63.8058 82.4865 63.8058H93.5901C94.8153 63.8058 95.7342 64.8155 95.7342 65.9806V75.2233C95.7342 76.466 94.7387 77.398 93.5901 77.398Z"
        fill="#222F5B"
      />
      <path
        d="M107.068 33.2039H92.7478C92.3649 33.2039 92.0586 32.8932 92.0586 32.5048C92.0586 32.1165 92.3649 31.8058 92.7478 31.8058H107.068C107.45 31.8058 107.757 32.1165 107.757 32.5048C107.757 32.8932 107.45 33.2039 107.068 33.2039Z"
        fill="#222F5B"
      />
      <path
        d="M107.068 39.1845H92.7478C92.3649 39.1845 92.0586 38.8738 92.0586 38.4854C92.0586 38.0971 92.3649 37.7864 92.7478 37.7864H107.068C107.45 37.7864 107.757 38.0971 107.757 38.4854C107.757 38.8738 107.45 39.1845 107.068 39.1845Z"
        fill="#222F5B"
      />
      <path
        d="M107.068 45.165H92.7478C92.3649 45.165 92.0586 44.8544 92.0586 44.466C92.0586 44.0777 92.3649 43.767 92.7478 43.767H107.068C107.45 43.767 107.757 44.0777 107.757 44.466C107.757 44.8544 107.45 45.165 107.068 45.165Z"
        fill="#222F5B"
      />
      <path
        d="M88.8423 33.2039H87.0811C86.6982 33.2039 86.3919 32.8932 86.3919 32.5048C86.3919 32.1165 86.6982 31.8058 87.0811 31.8058H88.8423C89.2252 31.8058 89.5315 32.1165 89.5315 32.5048C89.5315 32.8932 89.2252 33.2039 88.8423 33.2039Z"
        fill="#222F5B"
      />
      <path
        d="M88.8423 39.1845H87.0811C86.6982 39.1845 86.3919 38.8738 86.3919 38.4854C86.3919 38.0971 86.6982 37.7864 87.0811 37.7864H88.8423C89.2252 37.7864 89.5315 38.0971 89.5315 38.4854C89.5315 38.8738 89.2252 39.1845 88.8423 39.1845Z"
        fill="#222F5B"
      />
      <path
        d="M88.8423 45.165H87.0811C86.6982 45.165 86.3919 44.8544 86.3919 44.466C86.3919 44.0777 86.6982 43.767 87.0811 43.767H88.8423C89.2252 43.767 89.5315 44.0777 89.5315 44.466C89.5315 44.8544 89.2252 45.165 88.8423 45.165Z"
        fill="#222F5B"
      />
    </svg>
  );
};
