import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import join from 'url-join';

import { useAuth } from '~/utils';

interface UseCalendarSyncOutput {
  microsoftCalendarSync: () => Promise<Response>;
  googleCalendarSync: (
    googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => Promise<Response>;
}

export function useCalendarSync(): UseCalendarSyncOutput {
  const { user } = useAuth();

  const googleCalendarSync = async (
    googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    return await fetch(
      join(
        process.env.AUTH_HOST,
        `/v1/google_oauth2/callback?code=${googleResponse.code}&flow=sync-calendar&core_id=${user?.id}`,
      ),
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const microsoftCalendarSync = async () => {
    return await fetch(
      join(
        process.env.AUTH_HOST,
        `/v1/microsoft_oauth2/callback?core_id=${user?.id}&flow=sync-calendar`,
      ),
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  };

  return {
    microsoftCalendarSync,
    googleCalendarSync,
  };
}
