import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexSpacer, Input, Size } from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import { HexColor } from '~/types';

const styles = {
  colorBox: css`
    height: ${sv.marginExtraLarge};
    width: ${sv.marginExtraLarge};
    border-radius: ${sv.borderRadiusLarge};
    padding: 2px;
    border: 1px solid ${sv.azure};
    transition: ${sv.defaultTransition};

    &:hover {
      border-color: ${sv.azureDark};
      cursor: pointer;
    }
  `,
  color: css`
    height: 100%;
    width: 100%;
    border-radius: ${sv.defaultBorderRadius};
    background: ${sv.neutralLight};
  `,
  picker: css`
    position: absolute;
    z-index: 9999;
    top: calc(100% + ${sv.marginExtraSmall});
    right: 0;
  `,
  pickerRight: css`
    left: 0;
  `,
  colorBoxMinimal: css`
    border-radius: 50%;
    height: ${sv.marginLarge};
    width: ${sv.marginLarge};
  `,
  colorMinimal: css`
    border-radius: 50%;
  `,
};

export enum HexInputType {
  MINIMAL = 'minimal',
}

interface HexInputProps<T = string> {
  value?: ((name?: T) => HexColor) | HexColor;
  onChange: (value: HexColor, name?: T) => void;
  name?: T;
  type?: HexInputType;
}

export const HexInput = <T extends string>({
  value: _value,
  onChange,
  name,
  type,
}: HexInputProps<T>) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const value = isFunction(_value) ? _value(name) : _value;

  const handleDocumentClick = (e: Event) => {
    // Needs Event because React.MouseEvent<HTMLDivElement> does not match addEventListener signature
    if (!pickerRef.current?.contains(e.target as Node)) {
      setIsPickerOpen(false);
    }
  };

  const handleOnChange = (value: HexColor) => {
    onChange(value, name);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  if (type === HexInputType.MINIMAL) {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className={cx(styles.colorBox, styles.colorBoxMinimal)}
          onClick={() => setIsPickerOpen(true)}>
          <div className={cx(styles.color, styles.colorMinimal)} style={{ background: value }} />
        </div>
        {isPickerOpen ? (
          <div ref={pickerRef} className={cx(styles.picker, styles.pickerRight)}>
            <SketchPicker
              color={value}
              disableAlpha
              onChangeComplete={(v) => handleOnChange(v.hex)}
            />
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <Flex>
      <FlexItem flex>
        <Input value={value ?? ''} placeholder="e.g. #0F2CBF" />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <div style={{ position: 'relative' }}>
          <div className={styles.colorBox} onClick={() => setIsPickerOpen(true)}>
            <div className={styles.color} style={{ background: value }} />
          </div>
          {isPickerOpen ? (
            <div ref={pickerRef} className={styles.picker}>
              <SketchPicker
                color={value}
                disableAlpha
                onChangeComplete={(v) => handleOnChange(v.hex)}
              />
            </div>
          ) : null}
        </div>
      </FlexItem>
    </Flex>
  );
};
