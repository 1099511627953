import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Panel,
  PanelBody,
  PanelHeader,
  Paragraph,
  Separator,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import React, { useState } from 'react';

import { UrlString } from '~/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.booklet_panel');

interface BookletPanelProps {
  url?: UrlString;
}

export const BookletPanel = ({ url }: BookletPanelProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <Panel
      style={{ height: '100%' }}
      header={
        <PanelHeader>
          <Title size={4} noMargin>
            {tt('evolutive_booklet')}
          </Title>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <FormGroup
            label={<Label>{tt('booklet_link')}</Label>}
            input={
              <Input
                hint={tt('use_to_share')}
                placeholder={tt('loading')}
                value={url ?? ''}
                // Should stay like this for style reasons
                onChange={() => {}}
                loading={url == null}
                trailing={
                  <Button
                    onClick={() => {
                      if (url != null) {
                        setCopied(true);
                        copyToClipboard(url);
                      }
                    }}
                    category={copied ? Category.SUCCESS : Category.INFO}
                    leading={<Icon name={copied ? 'check' : 'copy'} />}>
                    {copied ? tt('copied') : tt('copy')}
                  </Button>
                }
              />
            }
          />
          <Margin size={{ vertical: Size.SMALL }}>
            <Separator />
          </Margin>
          <Paragraph style={{ color: sv.colorSecondary }}>{tt('lorem')}</Paragraph>
        </PanelBody>
      }
    />
  );
};
