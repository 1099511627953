import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexJustify } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css } from 'emotion';
import React from 'react';

import { Website } from '~/pods/website-builder/api/domain';
import { UrlString } from '~/types';
import { LocaleCode } from '~/utils';

import { ThemeDefinition } from '../../../types';
import { Themed } from '../Themed';

const styles = {
  navbar: (theme: ThemeDefinition) => css`
    padding: ${sv.paddingSmall} ${sv.paddingExtraLarge};
    background: ${theme.colors.neutral4};
    box-shadow: ${sv.elevation2};
  `,
};

interface NavbarProps {
  logoUrl?: UrlString;
  language: LocaleCode;
  sections: Website['sections'];
}

export const Navbar = ({ logoUrl, language, sections }: NavbarProps) => {
  const theme = useTheme<ThemeDefinition>();
  return (
    <div className={styles.navbar(theme)}>
      <Flex justify={FlexJustify.SPACE_BETWEEN}>
        <FlexItem>
          <img
            style={{
              maxHeight: '65px',
              maxWidth: '200px',
              objectFit: 'contain',
              objectPosition: 'left',
            }}
            src={logoUrl ?? 'https://iconape.com/wp-content/png_logo_vector/fake-studio-logo.png'}
          />
        </FlexItem>
        <FlexItem>
          <Themed.Navigation language={language} sections={sections} />
        </FlexItem>
      </Flex>
    </div>
  );
};
