import {
  Button,
  Category,
  Checkbox,
  DropdownOption,
  DropdownSeparator,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Padding,
  Select,
  SelectOption,
  Size,
  TCell,
  Text,
  Tier,
  Toggle,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import { isEmpty, range } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { ShowroomAddressSelector } from '~/components';
import { Address, ID, Optional } from '~/types';
import { createTranslate, getCountryName, run } from '~/utils';

import { AvailabilityAddress, AvailabilityDay, Day, Timeslot } from '../types';
import { timeStringToNumber } from '../utils';
import { TimeSelect } from './TimeSelect';
import { TimeslotSelect } from './TimeslotSelect';

const tt = createTranslate('pods.marketing_suite.routes.availabilities');

const styles = {
  select: css`
    pointer-events: none;
    width: inherit;
    [data-element='select'] {
      padding-right: 33px;
    }
  `,
};

const times: Array<SelectOption<string>> = range(24)
  .reduce(
    (memo, current) => [
      ...memo,
      `${current < 10 ? `0${current}` : current}:00`,
      `${current < 10 ? `0${current}` : current}:30`,
    ],
    [] as Array<string>,
  )
  .map((time) => ({ label: time, value: time }));

interface AvailabilityRowProps {
  day: AvailabilityDay;
  addresses: Array<Address>;
  onChangeTimeslot: (timeslot: Partial<Timeslot>, isNew: boolean) => Promise<string | undefined>;
  onDeleteTimeslot: (id: ID) => void;
  onChangeRow: (availability: {
    day: Day;
    addressId?: AvailabilityAddress;
    remote?: boolean;
    isActive?: boolean;
  }) => void;
}

export const AvailabilityRow = ({
  day,
  addresses,
  onChangeTimeslot,
  onDeleteTimeslot,
  onChangeRow,
}: AvailabilityRowProps) => {
  const [isActive, setIsActive] = useState(day.isActive);
  const [newTimeSlot, setNewTimeSlot] = useState<Partial<Omit<Timeslot, 'id'>>>();
  const [timeslots, setTimeslots] = useState<Array<Timeslot>>([]);
  const [addressId, setAddressId] = useState<AvailabilityDay['address']>();
  const [remote, setRemote] = useState<boolean>();

  useEffect(() => {
    setIsActive(day.isActive);
    setTimeslots(day.timeslots);
    setAddressId(day.address);
    setRemote(day.remote);
  }, [JSON.stringify(day)]);

  useEffect(() => {
    if (newTimeSlot != null && newTimeSlot.start != null && newTimeSlot.end != null) {
      const handleChange = async () => {
        await onChangeTimeslot(newTimeSlot, true);
      };

      handleChange();
    }
  }, [JSON.stringify(newTimeSlot)]);

  useEffect(() => {
    setNewTimeSlot(undefined);
  }, [timeslots.length]);

  const handleUpdateTimeslot = (updatedTimeslot: Optional<Timeslot, 'start' | 'end'>) => {
    const { id, start, end } = updatedTimeslot;
    const timeslot = timeslots.find((timeslot) => timeslot.id === id);
    if (timeslot != null) {
      setTimeslots(
        timeslots.map((ts) =>
          ts.id == timeslot.id ? { ...ts, start: start ?? ts.start, end: end ?? ts.end } : ts,
        ),
      );
      onChangeTimeslot(updatedTimeslot, false);
    } else {
      console.error(`Could not find a timeslot to match id ${id}`);
    }
  };

  const handleDeleteTimeslot = (id: ID) => {
    setTimeslots(timeslots.filter((ts) => ts.id !== id));
    onDeleteTimeslot(id);
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
    if (!isEmpty(timeslots)) {
      onChangeRow({ day: day.name, isActive: !isActive });
    } else {
      setNewTimeSlot({ start: undefined, end: undefined });
    }
  };

  const handleChangeAddress = (addressId: AvailabilityAddress) => {
    setAddressId(addressId);
    onChangeRow({ day: day.name, addressId: addressId ?? null });
  };

  const handleToggleRemote = (remote: boolean) => {
    setRemote(remote);
    onChangeRow({ day: day.name, remote, addressId });
  };

  const occupiedSlots = timeslots.map((ts) => [
    timeStringToNumber(ts.start),
    timeStringToNumber(ts.end),
  ]);

  const timesWithDisabled = times.map((time) => ({
    ...time,
    disabled: occupiedSlots.some(
      ([start, end]) =>
        timeStringToNumber(time.value) >= start && timeStringToNumber(time.value) <= end,
    ),
  }));

  const timesNewSlotStart =
    newTimeSlot?.end != undefined
      ? timesWithDisabled.map((time) => {
        if (timeStringToNumber(time.value) >= timeStringToNumber(newTimeSlot?.end!)) {
          return {
            ...time,
            disabled: true,
          };
        }
        return time;
      })
      : timesWithDisabled;

  const timesNewSlotEnd =
    newTimeSlot?.start != undefined
      ? timesWithDisabled.map((time) => {
        if (timeStringToNumber(time.value) <= timeStringToNumber(newTimeSlot?.start!)) {
          return {
            ...time,
            disabled: true,
          };
        }
        return time;
      })
      : timesWithDisabled;

  const addressOptions = [
    ...addresses.map((address) => ({
      label: `${address.street}, ${address.zipCode} ${address.city}, ${getCountryName(
        address.countryCode,
      )}`,
      value: address.id,
    })),
  ];

  return (
    <Fragment>
      <TCell style={{ verticalAlign: 'middle' }}>
        <Checkbox value={isActive} onChange={handleChangeIsActive}>
          <Text disabled={!isActive}>{tt(`days.${day.name}`)}</Text>
        </Checkbox>
      </TCell>
      <TCell>
        {run(() => {
          if (!isActive) {
            return (
              <Fragment>
                <Select
                  options={[]}
                  disabled
                  style={{ width: 90 }}
                  onChange={() => { }}
                  size={Size.SMALL}
                />
                <Padding size={Size.EXTRA_SMALL} style={{ display: 'inline-block' }}>
                  {' - '}
                </Padding>
                <Select
                  options={[]}
                  disabled
                  style={{ width: 90 }}
                  onChange={() => { }}
                  size={Size.SMALL}
                />
              </Fragment>
            );
          } else if (newTimeSlot == null && timeslots && timeslots.length === 1) {
            const timeslot = timeslots[0];
            return (
              <Flex justify={FlexJustify.START}>
                <FlexItem>
                  <TimeslotSelect
                    options={timesWithDisabled}
                    onChange={(key, val) => handleUpdateTimeslot({ id: timeslot.id, [key]: val })}
                    timeslot={timeslot}
                  />
                </FlexItem>
                <FlexSpacer size={Size.EXTRA_SMALL} />
                <FlexItem>
                  <Button
                    disabled={newTimeSlot != null}
                    onClick={() => setNewTimeSlot({ start: undefined, end: undefined })}
                    size={Size.SMALL}
                    tier={Tier.SECONDARY}
                    leading={<Icon name="plus" />}></Button>
                </FlexItem>
              </Flex>
            );
          } else {
            return (
              <Flex
                direction={FlexDirection.VERTICAL}
                justify={FlexJustify.START}
                align={FlexAlign.START}>
                {timeslots.map((timeslot) => (
                  <Fragment key={timeslot.start}>
                    <FlexItem>
                      <Flex>
                        <FlexItem>
                          <TimeslotSelect
                            options={timesWithDisabled}
                            timeslot={timeslot}
                            onChange={(key, val) =>
                              handleUpdateTimeslot({ id: timeslot.id, [key]: val })
                            }
                          />
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem>
                          <Button
                            size={Size.SMALL}
                            onClick={() => handleDeleteTimeslot(timeslot.id)}
                            disabled={newTimeSlot != null}
                            tier={Tier.TERTIARY}
                            leading={<Icon name="trash-2" />}
                          />
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                  </Fragment>
                ))}
                {newTimeSlot != null ? (
                  <Fragment>
                    <FlexItem>
                      <Flex>
                        <FlexItem>
                          <TimeSelect
                            options={timesNewSlotStart}
                            onChange={(val) => setNewTimeSlot({ ...newTimeSlot, start: val })}
                            value={newTimeSlot.start}
                          />
                          {' - '}
                          <TimeSelect
                            options={timesNewSlotEnd}
                            onChange={(val) => setNewTimeSlot({ ...newTimeSlot, end: val })}
                            value={newTimeSlot.end}
                          />
                        </FlexItem>
                        <FlexSpacer size={Size.EXTRA_SMALL} />
                        <FlexItem>
                          <Button
                            size={Size.SMALL}
                            onClick={() => {
                              setNewTimeSlot(undefined);
                              if (timeslots.length === 0) {
                                setIsActive(false);
                              }
                            }}
                            tier={Tier.TERTIARY}
                            leading={<Icon name="trash-2" />}
                          />
                        </FlexItem>
                      </Flex>
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                  </Fragment>
                ) : null}
                <FlexItem style={{ width: '100%' }}>
                  <Button
                    disabled={newTimeSlot != null}
                    onClick={() => setNewTimeSlot({ start: undefined, end: undefined })}
                    size={Size.SMALL}
                    tier={Tier.TERTIARY}
                    leading={<Icon name="plus" />}></Button>
                </FlexItem>
              </Flex>
            );
          }
        })}
      </TCell>
      <TCell>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <ShowroomAddressSelector
              disabled={timeslots.length === 0 || !isActive}
              addresses={addresses}
              onSelectAddress={(address) => {
                handleChangeAddress(address.id);
              }}
              extraOptions={
                remote && addressId != null ? (
                  <Fragment>
                    <DropdownSeparator />
                    <DropdownOption
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'normal',
                      }}
                      category={Category.INFO}
                      text={tt('remove_location')}
                      onClick={() => handleChangeAddress(undefined)}
                    />
                  </Fragment>
                ) : null
              }
              trigger={
                <Select
                  className={styles.select}
                  disabled={!isActive || timeslots.length === 0}
                  options={addressId == null ? [] : addressOptions}
                  value={addressId ?? undefined}
                  onChange={() => { }}
                  size={Size.SMALL}
                />
              }
            />
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <ListTile
              title={tt('remote')}
              leading={
                <Tooltip content={tt('always_remote')}>
                  <Toggle
                    style={addressId == null ? { cursor: 'not-allowed', opacity: 0.5 } : undefined}
                    disabled={!isActive || timeslots.length === 0}
                    onChange={addressId == null ? () => { } : handleToggleRemote}
                    value={remote}
                  />
                </Tooltip>
              }
            />
          </FlexItem>
        </Flex>
      </TCell>
    </Fragment>
  );
};

AvailabilityRow.displayName = 'TCell';
