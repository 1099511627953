import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { OrganisationOverview } from '../../types';

interface OrganisationOverviewQueryData {
  overview?: OrganisationOverview;
}

const OrganisationOverviewQuery = gql`
  query OrganisationOverviewQuery {
    overview {
      id
      status
      title
      updatedAt: lastPublishedAt
      coverAsset {
        id
        url
      }
      description
      mainAsset {
        id
        url
      }
    }
  }
`;

export function useFetchBasicOrganisationOverview(options?: { skip: boolean }): {
  isLoading: boolean;
  organisationOverview?: OrganisationOverview;
  error?: ErrorType;
} {
  const res = useMosaicQuery<OrganisationOverviewQueryData, null>({
    query: OrganisationOverviewQuery,
    pause: options?.skip,
  });

  const isLoading =
    res.data?.overview == null && res.error == null ? res.isLoading || !options?.skip : false;

  return {
    error: buildError(res.error),
    isLoading,
    organisationOverview: res.data?.overview,
  };
}
