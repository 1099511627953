import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  EmptyState,
  Flex,
  FlexItem,
  Icon,
  Position,
  Tier,
  Tooltip,
} from '@drawbotics/react-drylus';
import arrayMove from 'array-move';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { MinimalSection, Section as SectionType } from '~/pods/presentation/types';
import { useFetchSections } from '~/pods/presentation/utils';
import { OrganisationRole } from '~/types';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
  run,
  useAuth,
  useMosaicMutation,
} from '~/utils';

import { CustomSectionModal } from './CustomSectionModal';
import { SectionsLoader } from './LoadingPresentationPlaceholder';
import { Section } from './Section';

const tt = createTranslate('pods.presentation.routes.presentation.components.sections_editor');

const styles = {
  root: css`
    * {
      font-family: ${sv.defaultFontFamily};
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    b,
    strong {
      font-weight: 500;
    }
  `,
  flap: css`
    position: absolute;
    top: ${sv.marginSmall};
    right: calc(${sv.defaultMargin} * -1);
    background: ${sv.white};
    padding: 12px 3px;
    padding-left: ${sv.paddingExtraSmall};
    border-bottom-right-radius: ${sv.borderRadiusLarge};
    border-top-right-radius: ${sv.borderRadiusLarge};
    box-shadow: ${sv.elevation1};
    color: ${sv.colorDisabled};
    z-index: 1;
    transition: all ${sv.transitionTimeShort} ${sv.bouncyTransitionCurve};

    > i {
      margin-top: 1px;
    }

    &:hover {
      cursor: pointer;
      color: ${sv.colorTertiary};
      right: calc((${sv.defaultMargin} + 4px) * -1);
    }
  `,
};

interface CreateSectionVariables {
  name: string;
  presentationId: string;
}

interface CreateSectionResult {
  createSection: {
    section: SectionType;
  };
}

const createSectionMutation = gql`
  mutation createSection($name: String!, $presentationId: ID!) {
    createSection(input: { name: $name, presentationId: $presentationId }) {
      section {
        id
        name
        position
        hidden
      }
    }
  }
`;

interface DeleteSectionVariables {
  id: string;
}

interface DeleteSectionResult {
  destroySection: {
    section: SectionType;
  };
}

const deleteSectionMutation = gql`
  mutation destroySection($id: ID!) {
    destroySection(input: { id: $id }) {
      section {
        id
      }
    }
  }
`;

interface UpdateSectionVariables {
  position: number;
  id: string;
}

interface UpdateSectionResult {
  updateSection: {
    section: SectionType;
  };
}

const updateSectionMudation = gql`
  mutation updateSection($position: Int, $id: ID!) {
    updateSection(input: { position: $position, id: $id }) {
      section {
        id
        position
      }
    }
  }
`;

const DragHandle = SortableHandle(() => (
  <div className={styles.flap}>
    <Icon name="grid" />
  </div>
));

const Item = SortableElement(
  ({
    section,
    onClickDelete,
    readOnly,
  }: {
    section: MinimalSection;
    onClickDelete: VoidFunction;
    readOnly: boolean;
  }) => {
    return (
      <div style={{ marginBottom: sv.marginLarge, position: 'relative' }}>
        <Section onClickDelete={onClickDelete} section={section} />
        {readOnly ? null : (
          <Tooltip content={tt('drag_to_arrange')} side={Position.LEFT}>
            <DragHandle />
          </Tooltip>
        )}
      </div>
    );
  },
);

const Container = SortableContainer(
  ({
    sections,
    onClickDelete,
    readOnly,
  }: {
    sections: Array<MinimalSection>;
    onClickDelete: (id: string) => void;
    readOnly: boolean;
  }) => {
    return (
      <div>
        {sections
          .sort((a, b) => a.position - b.position)
          .map((section, index) => (
            <Item
              onClickDelete={() => onClickDelete(section.id)}
              key={`${section.id}`}
              index={index}
              section={section}
              readOnly={readOnly}
            />
          ))}
      </div>
    );
  },
);

interface SectionsEditorProps {
  projectId: string;
  presentationId: string;
}

export const SectionsEditor = ({ projectId, presentationId }: SectionsEditorProps) => {
  const [isCustomSectionModalVisible, setIsCustomSectionModalVisible] = useState(false);
  const [sections, setSections] = useState<Array<MinimalSection>>();
  const {
    isLoading,
    sections: loadedSections,
    refetch,
    error: sectionsError,
  } = useFetchSections(projectId);

  const { res: createSectionResult, executeMutation: createSection } = useMosaicMutation<
    CreateSectionResult,
    CreateSectionVariables
  >(createSectionMutation);

  const { executeMutation: deleteSection } = useMosaicMutation<
    DeleteSectionResult,
    DeleteSectionVariables
  >(deleteSectionMutation);

  const { executeMutation: updateSection } = useMosaicMutation<
    UpdateSectionResult,
    UpdateSectionVariables
  >(updateSectionMudation);

  const { user } = useAuth();
  const isUserMember = user?.role === OrganisationRole.MEMBER;

  const handleCreateCustomSection = async (name: string) => {
    await createSection({ name, presentationId });
    setIsCustomSectionModalVisible(false);
    refetch();
  };

  const handleDeleteCustomSection = (id: string) => {
    if (sections != null) {
      setSections(sections.filter((s) => s.id !== id));
    }
    deleteSection({ id });
  };

  const handleSortEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (sections != null && oldIndex !== newIndex) {
      const sortedSections = arrayMove(sections, oldIndex, newIndex);
      const updatedSections = sortedSections.map((section, i) => ({ ...section, position: i + 1 }));
      setSections(updatedSections);
      updateSection({ id: updatedSections[newIndex].id, position: newIndex + 1 });
    }
  };

  useEffect(() => {
    if (loadedSections != null) {
      if (loadedSections.length !== sections?.length) {
        setSections(loadedSections);
      }
    }
  }, [isLoading, loadedSections?.length]);

  return (
    <div>
      {run(() => {
        if (isLoading && sections == null) {
          return <SectionsLoader />;
        } else if (sections != null) {
          return (
            <Fragment>
              <Container
                onClickDelete={handleDeleteCustomSection}
                sections={sections}
                useDragHandle
                onSortEnd={handleSortEnd}
                helperClass={styles.root}
                readOnly={isUserMember}
              />
              <Flex>
                <FlexItem>
                  <Button
                    onClick={() => setIsCustomSectionModalVisible(true)}
                    tier={Tier.SECONDARY}
                    leading={<Icon name="plus" />}
                    disabled={isUserMember}>
                    {tt('add_custom')}
                  </Button>
                </FlexItem>
                <FlexItem>
                  <CustomSectionModal
                    loading={createSectionResult.fetching}
                    visible={isCustomSectionModalVisible}
                    onClickCreate={handleCreateCustomSection}
                    onClickClose={() => setIsCustomSectionModalVisible(false)}
                  />
                </FlexItem>
              </Flex>
            </Fragment>
          );
        } else {
          return (
            <EmptyState
              title={getTitleFromErrorType(sectionsError)}
              variation={getEmptyStateVariationFromErrorType(sectionsError)}
              description={getMessageFromErrorType(sectionsError)}
            />
          );
        }
      })}
    </div>
  );
};
