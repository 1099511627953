import gql from 'fraql';
import { useState } from 'react';
import { CombinedError } from 'urql';

import { clearTokens, useMosaicMutation } from '~/utils';

import { demodeskLogin } from '../demodesk-auth';

interface LoginLicenseVariables {
  token: string;
}

interface LoginLicenseResult {
  loginLicense: {
    authToken: string;
  };
}

const loginLicenseMutation = gql`
  mutation loginLicense($token: String!) {
    loginLicense(input: { meetingToken: $token }) {
      authToken
    }
  }
`;

export function useLoginLicense(): {
  login: (token: string) => Promise<{ error?: CombinedError; status?: string }>;
  isLoading: boolean;
} {
  const [isLoadingDDAuth, setIsLoadingDDAuth] = useState(false);
  const { executeMutation: loginLicense, res: loginLicenseResult } = useMosaicMutation<
    LoginLicenseResult,
    LoginLicenseVariables
  >(loginLicenseMutation);

  const login = async (token: string) => {
    setIsLoadingDDAuth(true);

    const res = await demodeskLogin({
      fetchAuthToken: async () => {
        const { error, data } = await loginLicense({ token });
        return {
          token: data?.loginLicense?.authToken,
          error,
        };
      },
    });

    if (res?.error != null) {
      setIsLoadingDDAuth(false);
      clearTokens();
      return { error: res.error };
    } else {
      setIsLoadingDDAuth(false);
      return { status: 'ok' };
    }
  };

  return { login, isLoading: loginLicenseResult.fetching || isLoadingDDAuth };
}
