import { Filter } from '@drawbotics/use-filters';

import { Currency, ID, SurfaceUnit } from '~/types';

export type Range = [number, number];

export type NumberMap = Record<number, number>;

export type StringMap = Record<string, number>;

export enum UnitAvailability {
  AVAILABLE = 'available',
  BOOKED = 'booked',
  OPTION = 'option',
  UNAVAILABLE = 'unavailable',
}

export interface Unit {
  id: ID;
  isLocked: boolean;
  reference: string;
  availability: UnitAvailability;
  soldAt?: string;
  price: number;
  surface: number;
  terraceSurface: number;
  rooms: number;
  bedrooms: number;
  bathrooms: number;
  orientation?: string;
  floorplan?: string;
}

export type EditableUnitFields = Omit<
  Unit,
  'id' | 'isLocked' | 'rooms' | 'bedrooms' | 'bathrooms' | 'price' | 'surface' | 'terraceSurface'
> & {
  rooms: number | '-' | '';
  bedrooms: number | '-' | '';
  bathrooms: number | '-' | '';
  price?: number | '';
  surface?: number | '';
  terraceSurface?: number | '';
};

export interface ListingManagerFilters {
  availability: UnitAvailability;
  bedrooms: number;
  rooms: number;
  price: Range;
  surface: Range;
  page: number;
  search: string;
  unitId: string;
}

export interface Listing {
  id: ID;
  providerName?: string;
  name: string;
  currency: Currency;
  surfaceUnit: SurfaceUnit;
  units: Array<Unit>;
}

export interface Filters {
  availability: Filter;
  bedrooms: Filter;
  rooms: Filter;
  price: Filter;
  surface: Filter;
  search: Filter;
}
