import sv from '@drawbotics/drylus-style-vars';
import {
  ButtonLink,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Padding,
  Separator,
  Size,
  Tag,
  TextLink,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';
import urlJoin from 'url-join';

import logo from '~/images/mosaic.svg';
import { createTranslate } from '~/utils';

import { Website } from '../api/domain';

const MOSAIC_HOST = process.env.MOSAIC_HOST;

const tt = createTranslate('pods.website_builder.routes.website_builder');

export const Dimensions = {
  height: 70,
};

const styles = {
  navbar: css`
    width: 100%;
    height: ${Dimensions.height}px;
    background: ${sv.white};
  `,
  productLink: css`
    border-radius: ${sv.defaultBorderRadius};
    white-space: nowrap;
    &:hover {
      cursor: pointer;
      background: ${sv.neutralLight};
    }
  `,
};

interface NavbarProps {
  projectName: string;
  website: Website;
  isSaving: boolean;
}

export const EditNavbar = ({ projectName, website, isSaving }: NavbarProps) => {
  return (
    <div className={styles.navbar}>
      <Flex style={{ height: '100%' }}>
        <FlexItem flex>
          <Padding size={{ horizontal: Size.DEFAULT }}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <div style={{ display: 'inline-block' }}>
                  <Link to="/projects">
                    <img src={logo} height="30" />
                  </Link>
                </div>
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem>
                {tt('project_website_builder')}: {projectName}
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem style={{ height: '20px' }}>
                <Separator vertical />
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem flex>
                {isSaving ? (
                  <Tag color={Color.ORANGE}>{tt('saving')}</Tag>
                ) : (
                  <Tag color={Color.GREEN}>{tt('saved')}</Tag>
                )}
              </FlexItem>
              <FlexItem>
                <Link to={`${location.pathname.replace('/edit', '')}`}>
                  <TextLink style={{ textTransform: 'capitalize' }}>
                    {tt('go_back_to_mosaic')}
                  </TextLink>
                </Link>
              </FlexItem>
              <FlexSpacer size={Size.DEFAULT} />
              <FlexItem>
                <a
                  href={urlJoin(MOSAIC_HOST, 'websites/projects', website.identifier)}
                  target="_blank"
                  rel="noopener noreferrer">
                  <ButtonLink tier={Tier.PRIMARY} color={Color.BLUE}>
                    {tt('preview_site')}
                  </ButtonLink>
                </a>
              </FlexItem>
              {/* NOTE: restore once SSR is in place */}
              {/* <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <ButtonLink tier={Tier.PRIMARY} color={Color.BLUE}>
                  {'Publish'}
                </ButtonLink>
              </FlexItem> */}
            </Flex>
          </Padding>
        </FlexItem>
      </Flex>
    </div>
  );
};
