import gql from 'fraql';

import { Campaign, CampaignToken } from '~/pods/insights/types';
import { useBackofficeMutation } from '~/utils';

interface UpdateCampaignVariables {
  campaign: {
    token: CampaignToken;
    name: string;
    source: string;
  };
}

const updateCampaignMutation = gql`
  mutation updateCampaign($campaign: UpdateCampaignInput!) {
    updateCampaign(input: $campaign) {
      campaign {
        token
      }
    }
  }
`;

export function useUpdateCampaign() {
  const { res: updateCampaignResult, executeMutation: updateCampaign } = useBackofficeMutation<
    Campaign,
    UpdateCampaignVariables
  >(updateCampaignMutation);

  return { updateCampaign, updateCampaignResult };
}
