import {
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  ListTile,
  Padding,
  RoundIcon,
  Separator,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React from 'react';

import { Portal } from '../domain';

interface PortalsListProps {
  portals: Array<Portal>;
}

export const PortalsList = ({ portals }: PortalsListProps) => {
  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
      {portals
        .slice()
        .sort((a, b) => {
          if (a.isLocked === b.isLocked) {
            return a.name.localeCompare(b.name, undefined, { numeric: true });
          }

          return a.isLocked ? 1 : -1;
        })
        .map((portal, i) => (
          <FlexItem key={portal.name}>
            <Padding size={{ vertical: Size.EXTRA_SMALL }}>
              <ListTile
                style={{ width: '100%' }}
                leading={<img src={portal.logoUrl} style={{ maxWidth: 48, maxHeight: 24 }} />}
                title={<Text>{portal.name}</Text>}
                trailing={
                  portal.isLocked ? (
                    <RoundIcon name="lock" />
                  ) : (
                    <RoundIcon name="key" color={Color.GREEN} inversed />
                  )
                }
              />
            </Padding>
            {i != portals.length - 1 ? <Separator /> : null}
          </FlexItem>
        ))}
    </Flex>
  );
};
