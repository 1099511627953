import { Content, Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { BuilderLoadingPlaceholder } from '../../components';
import { WebsiteSettings } from '../../components/WebsiteSettings';
import { useController } from '../../hooks';
import { WebsiteController } from './website.controller';

interface WebsiteViewProps {}

export const WebsiteView = ({}: WebsiteViewProps) => {
  const ctrl = useController(WebsiteController);

  if (ctrl.isLoading) {
    return (
      <Content fullHeight>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <BuilderLoadingPlaceholder />
        </Padding>
      </Content>
    );
  }
  const website = ctrl.website!;

  return (
    <WebsiteSettings
      isSaving={ctrl.isSaving}
      website={website}
      onChange={(data) => ctrl.updateWebsite(data)}
      onChangeFavicon={(v) => ctrl.updateWebsiteFavicon(v)}
      onChangeAttachment={(v) => ctrl.updateWebsiteOpenGraphAttachment(v)}
    />
  );
};
