import gql from 'fraql';

import { Agency } from '~/pods/settings/routes/Agencies/types';
import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface AgenciesQueryData {
  agencies?: Array<Agency>;
}

const AgenciesQuery = gql`
  query AgenciesQuery {
    agencies {
      id
      name
      assignedProjects {
        id
      }
      logo {
        url
      }
      users {
        fullName @computed(type: User)
        email
        role
      }
    }
  }
`;

interface FetchAgencies {
  isLoading: boolean;
  agencies: Array<Agency>;
  error?: ErrorType;
  refreshOrganisationFromCore: VoidFunction;
}

export function useLoadAgencies(): FetchAgencies {
  const res = useMosaicQuery<AgenciesQueryData, null>({
    query: AgenciesQuery,
  });

  return {
    isLoading: res.isLoading,
    agencies: res.data?.agencies ?? [],
    error: buildError(res.error),
    refreshOrganisationFromCore: res.refetch,
  };
}
