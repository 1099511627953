import sv from '@drawbotics/drylus-style-vars';
import { Padding, Size } from '@drawbotics/react-drylus';
import { ResponsiveBar } from '@nivo/bar';
import { css } from 'emotion';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { BarTheme, useLoadRevenuePerType } from '~/pods/insights/utils';
import { getYGridLines } from '~/pods/insights/utils/get-y-grid-lines';
import { createTranslate } from '~/utils/translation';

import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.revenue_per_type');

const styles = {
  chartContainer: css`
    width: 100%;
    height: 100%;
  `,
};

function _getColorForTypology(typology: string): string {
  switch (typology.toLowerCase()) {
    case 't1':
      return sv.green;
    case 't2':
      return sv.blue;
    case 't3':
      return sv.orange;
    case 't4':
      return '#8B53F4';
    case 't5':
      return '#F2586A';
    default:
      return sv.grey300;
  }
}

interface RevenuePerUnitTypologyProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const RevenuePerUnitTypology = ({ units, dateRange }: RevenuePerUnitTypologyProps) => {
  const { data, dataKeys } = useLoadRevenuePerType(units, dateRange);

  const values = data.map((d) => {
    const { month, ...rest } = d;
    return Object.values(rest).reduce((m, v: any) => v + m, 0);
  }) as Array<number>;

  const gridYValues = getYGridLines(values, 4);

  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ vertical: Size.SMALL }} style={{ width: '100%', height: '100%' }}>
        <div className={styles.chartContainer}>
          <ResponsiveBar
            data={data}
            keys={dataKeys}
            indexBy="month"
            margin={{ bottom: 25, left: 60, top: 5, right: 60 }}
            isInteractive={false}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickValues: gridYValues,
              format: '.2s',
            }}
            axisBottom={{ tickSize: 0, tickRotation: -45, tickPadding: 10 }}
            enableLabel={false}
            colors={({ id }: { id: string }) => _getColorForTypology(id)}
            padding={0.4}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 110,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                symbolSize: 10,
                symbolShape: 'circle',
                itemTextColor: sv.colorTertiary,
              },
            ]}
            theme={BarTheme}
            // @ts-ignore is not correctly typed
            gridYValues={gridYValues}
          />
        </div>
      </Padding>
    </ChartTile>
  );
};
