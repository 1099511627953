import gql from 'fraql';

export const ProjectQuery = gql`
  query ProjectQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      name
      slug
    }
  }
`;
