import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  InlineEdit,
  Input,
  ListTile,
  Margin,
  Padding,
  Separator,
  Shade,
  Size,
  Spinner,
  Text,
  TextLink,
  useAlert,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import gql from 'fraql';
import React, { Fragment, Ref, forwardRef, useEffect, useState } from 'react';

import {
  Asset,
  Category as CategoryType,
  CategoryType as CategoryTypeEnum,
} from '~/pods/presentation/types';
import { getIconFromSlug } from '~/pods/presentation/utils';
import { Attachment, OrganisationRole, UrlString } from '~/types';
import { createTranslate, translate as t, useAuth, useMosaicMutation } from '~/utils';

import { Gallery } from './Gallery';
import { Links } from './Links';
import { PDFs } from './PDFs';

const tt = createTranslate('pods.presentation.routes.presentation.components.category_editor');

interface DeleteCategoryVariables {
  id: string;
}

interface DeleteCategoryResult {
  destroyCategory: {
    category: CategoryType;
  };
}

const deleteCategoryMutation = gql`
  mutation deleteCategory($id: ID!) {
    destroyCategory(input: { id: $id }) {
      category {
        id
      }
    }
  }
`;

export interface AssetChange {
  assetId?: string;
  signedBlobId?: String;
  url?: UrlString;
  destroy?: boolean;
}

interface UpdateCategoryVariables {
  category: {
    name?: string;
    id: string;
    assets?: Array<AssetChange>;
  };
}

interface UpdateCategoryResult {
  updateCategory: {
    category: CategoryType;
  };
}

const updateCategoryMutation = gql`
  mutation updateCategory($category: UpdateCategoryInput!) {
    updateCategory(input: $category) {
      category {
        id
        name
        ... on IframeCategory {
          assets {
            id
            url
          }
        }
        ... on ImageCategory {
          assets {
            id
            url
            ... on AttachmentAsset {
              filename
            }
          }
        }
        ... on PdfCategory {
          assets {
            id
            url
            ... on AttachmentAsset {
              filename
            }
          }
        }
      }
    }
  }
`;

interface CategoryEditorProps {
  category: CategoryType;
  onClickMoveUp?: VoidFunction;
  onClickMoveDown?: VoidFunction;
  withSeparator: boolean;
  reloadCategory: VoidFunction;
}

export const CategoryEditor = forwardRef(
  (
    {
      category,
      onClickMoveDown,
      onClickMoveUp,
      withSeparator,
      reloadCategory,
    }: CategoryEditorProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [categoryName, setCategoryName] = useState(category.name ?? '');
    const { showAlert } = useAlert();

    const { user } = useAuth();
    const isUserAdmin = user?.role === OrganisationRole.ADMIN;

    const { executeMutation: deleteCategory, res: deleteCategoryResult } = useMosaicMutation<
      DeleteCategoryResult,
      DeleteCategoryVariables
    >(deleteCategoryMutation);

    const { executeMutation: updateCategory, res: updateCategoryResult } = useMosaicMutation<
      UpdateCategoryResult,
      UpdateCategoryVariables
    >(updateCategoryMutation);

    const isCustomCategory = category.slug == null && category.name != null;

    useEffect(() => {
      if (category.name != null) {
        setCategoryName(category.name);
      }
    }, [category.name]);

    useEffect(() => {
      if (!deleteCategoryResult.fetching && deleteCategoryResult.error != null) {
        showAlert({
          text: tt('delete_error'),
          category: Category.DANGER,
        });
      }
      if (!updateCategoryResult.fetching && updateCategoryResult.error != null) {
        showAlert({
          text: tt('update_error'),
          category: Category.DANGER,
        });
      }
    }, [deleteCategoryResult.fetching, updateCategoryResult.fetching]);

    return (
      <div ref={ref}>
        {withSeparator ? <Separator /> : null}
        <Padding size={{ vertical: Size.SMALL }}>
          <Flex>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem flex style={{ display: 'flex' }}>
              {isCustomCategory ? (
                <InlineEdit
                  onClickConfirm={async () => {
                    if (categoryName !== '') {
                      const res = await updateCategory({
                        category: { id: category.id, name: categoryName },
                      });
                      if (res.error != null) {
                        setCategoryName(res.data?.updateCategory.category.name!);
                        reloadCategory();
                      }
                    }
                  }}
                  onCancel={() => setCategoryName(category.name!)}
                  edit={
                    <Input
                      value={categoryName}
                      onChange={(v) => setCategoryName(String(v))}
                      size={Size.SMALL}
                    />
                  }>
                  <ListTile
                    title={<Text size={Size.LARGE}>{categoryName}</Text>}
                    trailing={updateCategoryResult.fetching ? <Spinner size={Size.SMALL} /> : null}
                  />
                </InlineEdit>
              ) : (
                <ListTile
                  title={
                    <Text size={Size.LARGE}>
                      {t(`pods.presentation.categories.${category.slug}`)}
                    </Text>
                  }
                  leading={
                    category.slug != null ? <Icon name={getIconFromSlug(category.slug)} /> : null
                  }
                />
              )}
            </FlexItem>
            {isUserAdmin ? (
              <Fragment>
                <FlexSpacer size={Size.LARGE} />
                {category.type !== CategoryTypeEnum.LISTING ? (
                  <FlexItem>
                    <div onClick={() => setIsEditMode(true)}>
                      <TextLink>{tt('edit')}</TextLink>
                    </div>
                  </FlexItem>
                ) : null}
                {onClickMoveDown != null || onClickMoveUp != null ? (
                  <Fragment>
                    <FlexItem>
                      <Margin size={{ right: Size.SMALL }} />
                    </FlexItem>
                    <FlexItem>
                      <Icon
                        style={
                          onClickMoveUp == null
                            ? { color: sv.colorDisabled, pointerEvents: 'none' }
                            : undefined
                        }
                        onClick={onClickMoveUp}
                        name="arrow-up"
                      />
                    </FlexItem>
                    <FlexItem>
                      <Margin size={{ right: Size.EXTRA_SMALL }} />
                    </FlexItem>
                    <FlexItem>
                      <Icon
                        style={
                          onClickMoveDown == null
                            ? { color: sv.colorDisabled, pointerEvents: 'none' }
                            : undefined
                        }
                        onClick={onClickMoveDown}
                        name="arrow-down"
                      />
                    </FlexItem>
                  </Fragment>
                ) : null}
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Button
                    disabled={deleteCategoryResult.fetching}
                    onClick={() => deleteCategory({ id: category.id })}
                    leading={
                      deleteCategoryResult.fetching ? (
                        <Spinner size={Size.SMALL} />
                      ) : (
                        <Icon name="x" />
                      )
                    }
                    style={{ borderRadius: 0 }}
                  />
                </FlexItem>
              </Fragment>
            ) : null}
          </Flex>
        </Padding>
        <Padding size={{ vertical: Size.SMALL, horizontal: Size.DEFAULT }}>
          {run(() => {
            if (
              category.type != CategoryTypeEnum.LISTING &&
              (category.assets == null || category.assets.length === 0) &&
              !isEditMode
            ) {
              return <Text shade={Shade.LIGHT}>{tt('no_assets')}</Text>;
            }
            if (category.type === CategoryTypeEnum.IMAGE) {
              return (
                <Gallery
                  images={category.assets as Array<Attachment>}
                  onClickCancel={() => setIsEditMode(false)}
                  onClickSave={async (imageChanges: Array<AssetChange>) => {
                    await updateCategory({
                      category: { id: category.id, assets: imageChanges },
                    });
                    reloadCategory();
                    setIsEditMode(false);
                  }}
                  saving={updateCategoryResult.fetching}
                  editMode={isEditMode}
                />
              );
            } else if (category.type === CategoryTypeEnum.IFRAME) {
              return (
                <Links
                  urls={category.assets as Array<Asset>}
                  onClickCancel={() => setIsEditMode(false)}
                  onClickSave={async (urlChanges: Array<AssetChange>) => {
                    await updateCategory({
                      category: { id: category.id, assets: urlChanges },
                    });
                    reloadCategory();
                    setIsEditMode(false);
                  }}
                  saving={updateCategoryResult.fetching}
                  editMode={isEditMode}
                />
              );
            } else if (category.type === CategoryTypeEnum.PDF) {
              return (
                <PDFs
                  pdfs={category.assets as Array<Attachment>}
                  onClickCancel={() => setIsEditMode(false)}
                  onClickSave={async (pdfsChanges: Array<AssetChange>) => {
                    await updateCategory({
                      category: { id: category.id, assets: pdfsChanges },
                    });
                    reloadCategory();
                    setIsEditMode(false);
                  }}
                  editMode={isEditMode}
                  saving={updateCategoryResult.fetching}
                />
              );
            } else {
              return <Text shade={Shade.LIGHT}>{tt('cannot_edit_category')}</Text>;
            }
          })}
        </Padding>
      </div>
    );
  },
);
