import gql from 'fraql';

import { ErrorType } from '~/types';
import { useMosaicQuery } from '~/utils';

interface ReachData {
  meetingsCount: number;
  presentationsCount: number;
  usersCount: number;
}

const LicenseCountQuery = gql`
  query LicenseCountQuery {
    licenseCount {
      totalLicenseCount
    }
  }
`;

const MeetingsCountQuery = gql`
  query MeetingsCountQuery {
    meetingsCount(filter: { statuses: [PLANNED] }) {
      count
    }
  }
`;

const PresentationsQuery = gql`
  query PresentationsQuery {
    presentations(filter: { status: PUBLISHED }) {
      id
    }
  }
`;

const UsersQuery = gql`
  query UsersQuery {
    licensedUsers {
      id
    }
  }
`;

export function useFetchMeetingsData(): {
  isMeetingsLoading: boolean;
  data?: ReachData;
  error?: ErrorType;
} {
  const {
    data: licenseCountData,
    isLoading: isLicenseCountLoading,
    error: licenseCountError,
  } = useMosaicQuery<{ licenseCount?: { totalLicenseCount: number } }, null>({
    query: LicenseCountQuery,
  });

  const isReachEnabled =
    licenseCountData != null && (licenseCountData.licenseCount?.totalLicenseCount ?? 0) > 0;

  const {
    data: meetingsCountData,
    isLoading: isMeetingsLoading,
    error: meetingsError,
  } = useMosaicQuery<{ meetingsCount: { count: number } }, null>({
    query: MeetingsCountQuery,
    pause: !isReachEnabled,
  });

  const {
    data: presentationsData,
    isLoading: isPresentationsLoading,
    error: presentationsError,
  } = useMosaicQuery<{ presentations: Array<{ id: string }> }, null>({
    query: PresentationsQuery,
    pause: !isReachEnabled,
  });

  const {
    data: usersData,
    isLoading: isUsersLoading,
    error: usersError,
  } = useMosaicQuery<{ licensedUsers: Array<{ id: string }> }, null>({
    query: UsersQuery,
    pause: !isReachEnabled,
  });

  const hasData = meetingsCountData != null && presentationsData != null && usersData != null;
  const hasError =
    licenseCountError != null ||
    meetingsError != null ||
    presentationsError != null ||
    usersError != null;
  const isLoading =
    isLicenseCountLoading || isMeetingsLoading || isPresentationsLoading || isUsersLoading;

  return {
    isMeetingsLoading: isLoading,
    data:
      hasData && !isLoading && isReachEnabled
        ? {
            meetingsCount: meetingsCountData!.meetingsCount.count,
            presentationsCount: presentationsData!.presentations.length,
            usersCount: usersData!.licensedUsers.length,
          }
        : undefined,
    error: hasError ? ErrorType.NOT_AUTHORIZED : undefined, // pass generic error for UI purposes
  };
}
