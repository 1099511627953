import gql from 'fraql';

import { LogType } from '~/pods/crm/types';
import { useMosaicQuery } from '~/utils/hooks';

export interface LogsCountQueryData {
  logCount: {
    logCount: number;
  };
}

interface LogsQueryOptions {
  types?: Array<LogType> | null;
  limit?: number;
  offset?: number;
}

export interface LogsCountQueryVariables {
  filter: {
    leadId: string;
    types?: Array<LogType> | null;
    limit?: number;
    offset?: number;
  };
}

const LogsCountQuery = gql`
  query LogsCountQuery($filter: LogFilterInput!) {
    logCount(filter: $filter) {
      logCount
    }
  }
`;

export function useFetchLogsCount(
  leadId: string,
  options: LogsQueryOptions = {},
): { isLoading: boolean; logsCount?: number; refetch: VoidFunction } {
  const { types, limit, offset } = options;
  const res = useMosaicQuery<LogsCountQueryData, LogsCountQueryVariables>({
    query: LogsCountQuery,
    variables: { filter: { leadId, types, limit, offset } },
  });

  return {
    isLoading: res.isLoading,
    logsCount: res.data?.logCount?.logCount, // backend strange return value
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
