import { Category, useAlert } from '@drawbotics/react-drylus';
import { DocumentNode } from 'graphql';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { OperationContext, useMutation } from 'urql';

import { getErrorType, getMessageFromErrorType } from '../errors';
import { BackofficeClient, MosaicClient } from './clients';
import { useOrgHeader } from './use-org-header';

interface MutationOptions {
  /**
   * If provided, this will be the message shown inside the alert triggered in case of a generic error
   */
  errorMessage?: string;
  /**
   * Callback that will be run in case of an error
   */
  onError?: VoidFunction;
  successMessage?: string;
  onSuccess?: VoidFunction;
  /**
   * If set to false, the hook will not display builtin error messages.
   * If an errorMessage is provided, it will still be shown.
   */
  disableErrorHandling?: boolean;
  disableSuccessHandling?: boolean;
}

type Query = string | DocumentNode;

const defaultOptions = {
  // At the time when this is introduced, we tend to have feedback for success, but not for errors
  disableErrorHandling: false,
  disableSuccessHandling: true,
  onError: undefined,
  errorMessage: undefined,
  onSuccess: undefined,
  successMessage: undefined,
};

function useBaseMutation<D, V>(
  client: Partial<OperationContext>,
  query: Query,
  options: MutationOptions = defaultOptions,
) {
  // Todo: there should be a warning in place if error management is disabled and no errorMessage or onError was provided
  const [res, executeMutation] = useMutation<D, V>(query);
  const { showAlert } = useAlert();

  const partialContextWithOrgHeader = useOrgHeader();
  const newClient = Object.assign(client, partialContextWithOrgHeader);

  const {
    disableErrorHandling,
    errorMessage,
    disableSuccessHandling,
    successMessage,
    onSuccess,
    onError,
  } = options;

  useEffect(() => {
    if (res.fetching === false) {
      if (res.error != null) {
        LogRocket.captureMessage(`Error while fetching data: ${JSON.stringify(res.error)}`);

        const errorType = getErrorType(res.error);

        if (!disableErrorHandling || errorMessage != null) {
          showAlert({
            text: errorMessage ?? getMessageFromErrorType(errorType),
            category: Category.DANGER,
          });
        }
        onError?.();
      } else {
        if (!disableSuccessHandling || successMessage != null) {
          showAlert({
            text: errorMessage ?? 'Your chabges have been saved successfully',
            category: Category.DANGER,
          });
        }
        onSuccess?.();
      }
    }
  }, [res.fetching]);

  return {
    res,
    executeMutation: (variables: V) => executeMutation(variables, newClient),
  };
}

export function useBackofficeMutation<D, V>(query: Query, options?: MutationOptions) {
  return useBaseMutation<D, V>(BackofficeClient, query, options);
}

export function useMosaicMutation<D, V>(query: Query, options?: MutationOptions) {
  return useBaseMutation<D, V>(MosaicClient, query, options);
}
