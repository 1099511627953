import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import { useState } from 'react';

import { ID } from '~/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const DiffuseMutation = gql`
  mutation DiffuseMosaicMutation($input: DiffuseAdvertInput!) {
    diffuseAdvert(input: $input) {
      diffusions {
        id
      }
    }
  }
`;

const tt = createTranslate('components.error_placeholder');

interface UseDiffuseReturnType {
  isDiffusing: boolean;
  diffuse: (portals: Array<string>, startingDate: string, endingDate: string) => Promise<void>;
}

export function useDiffuse(advertId?: ID, refresh = async () => {}): UseDiffuseReturnType {
  const alert = useAlert();
  const [isDiffusing, setIsDiffusing] = useState(false);
  const { executeMutation } = useMosaicMutation<any, any>(DiffuseMutation);

  const diffuse = async (
    portals: Array<string>,
    startingDate: string,
    endingDate: string,
  ): Promise<void> => {
    setIsDiffusing(true);
    const res = await executeMutation({
      input: {
        advertId,
        portalSlugs: portals,
        startDiffusionDate: startingDate,
        endDiffusionDate: endingDate,
      },
    });
    setIsDiffusing(false);
    await refresh();

    if (res.error != null || res.data?.diffuseAdvert.diffusions.length === 0) {
      alert.showAlert({
        category: Category.DANGER,
        text: tt('empty_state_title'),
      });
    }
  };

  return { isDiffusing, diffuse };
}
