import gql from 'fraql';

const Invitation = {
  typeName: 'Invitation',
  fields: {
    fullName: {
      dependencies: gql`
        fragment _ on Invitation {
          firstName
          lastName
        }
      `,
      resolver: (invitation: any) => `${invitation.firstName} ${invitation.lastName}`,
    },
  },
};

export default Invitation;
