import { Flex, FlexItem, FlexJustify, Label, Margin, Size } from '@drawbotics/react-drylus';
import React from 'react';

interface ChartHeaderProps {
  label: string;
}

export const ChartHeader = ({ label }: ChartHeaderProps) => {
  return (
    <Margin size={{ top: Size.EXTRA_SMALL, bottom: Size.EXTRA_SMALL, left: Size.DEFAULT }}>
      <Flex justify={FlexJustify.START} style={{ minHeight: '32px' }}>
        <FlexItem>
          <Label>{label}</Label>
        </FlexItem>
      </Flex>
    </Margin>
  );
};
