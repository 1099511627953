import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  Input,
  LoadingPlaceholder,
  Margin,
  PhoneNumberInput,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { useState } from 'react';

import { Lead } from '~/pods/crm/types';
import { useUpdateLead } from '~/pods/crm/utils';
import { createTranslate } from '~/utils';
import { run } from '~/utils';

import { InfoRow } from './InfoRow';
import { Section } from './Section';
import { TextWithFallback } from './TextWithFallback';

const tt = createTranslate('pods.crm.routes.lead.components.lead_details.contact');

interface ContactForm {
  email: string;
  phoneNumber: string;
}

export const ContactPlaceholder = () => {
  return (
    <Section label={tt('title')} onClickEdit={() => {}}>
      <Margin size={{ bottom: Size.SMALL }}>
        <LoadingPlaceholder width="50%" />
      </Margin>
      <LoadingPlaceholder width="80%" />
    </Section>
  );
};

interface ContactProps {
  lead: Lead;
}

export const Contact = ({ lead }: ContactProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const contactForm = useForm<ContactForm>({
    email: lead.email,
    phoneNumber: lead.phoneNumber ?? '',
  });

  const { isLoading: isUpdating, updateLead } = useUpdateLead();

  const handleSaveLeadInfo = async () => {
    await updateLead({ id: lead.id, ...contactForm.values });
    setIsEditing(false);
  };

  return (
    <Section label={tt('title')} onClickEdit={() => setIsEditing(true)}>
      <Margin size={{ bottom: Size.SMALL }}>
        <InfoRow icon="at-sign">
          {run(() => {
            if (isEditing) {
              return (
                <Input
                  placeholder={tt('placeholder.email')}
                  name="email"
                  type="email"
                  size={Size.SMALL}
                  value={contactForm.get}
                  onChange={contactForm.set}
                />
              );
            } else {
              return <TextWithFallback value={lead.email} fallback={tt('fallback.email')} />;
            }
          })}
        </InfoRow>
      </Margin>
      <Margin size={{ bottom: Size.SMALL }}>
        <InfoRow icon="phone">
          {run(() => {
            if (isEditing) {
              return (
                <PhoneNumberInput
                  placeholder={tt('placeholder.phone')}
                  name="phoneNumber"
                  size={Size.SMALL}
                  value={contactForm.get}
                  onChange={contactForm.set}
                />
              );
            } else {
              return <TextWithFallback value={lead.phoneNumber} fallback={tt('fallback.phone')} />;
            }
          })}
        </InfoRow>
      </Margin>
      {isEditing ? (
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Margin size={{ right: Size.EXTRA_SMALL }}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  contactForm.reset();
                }}
                size={Size.SMALL}
                tier={Tier.TERTIARY}>
                {tt('cancel_button')}
              </Button>
            </Margin>
          </FlexItem>
          <FlexItem>
            <Button
              onClick={handleSaveLeadInfo}
              disabled={isUpdating}
              trailing={isUpdating ? <Spinner size={Size.SMALL} inversed /> : null}
              size={Size.SMALL}
              category={Category.INFO}>
              {tt('save_button')}
            </Button>
          </FlexItem>
        </Flex>
      ) : null}
    </Section>
  );
};
