import gql from 'fraql';

const User = {
  typeName: 'User',
  fields: {
    fullName: {
      dependencies: gql`
        fragment _ on User {
          firstName
          lastName
        }
      `,
      resolver: (user: any) => {
        if (user.firstName != null && user.lastName != null) {
          return `${user.firstName} ${user.lastName}`;
        }
        return user.firstName ?? user.lastName;
      },
    },
  },
};

export default User;
