import sv from '@drawbotics/drylus-style-vars';
import {
  Category,
  ListTile,
  Margin,
  Panel,
  PanelBody,
  RoundIcon,
  Size,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import dayjs from 'dayjs';
import { memoize } from 'lodash';
import React from 'react';

import { POINTS_PER_GRAPH, Timescale, timescaleToDays } from '~/pods/insights/constants';
import { DateRange, InsightsSession } from '~/pods/insights/types';
import {
  compressToNPoints,
  getEmptyDates,
  isDateInRange,
  toDataPoints,
} from '~/pods/insights/utils';
import { useMosaicNavigation } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { Delta } from '../Delta';
import { FlushChart } from '../FlushChart';
import { InsufficientData } from '../InsufficientData';
import { DashboardCardHeader } from './DashboardCardHeader';

const tt = createTranslate('pods.insights.routes.dashboard');

const TractionIcon = () => (
  <RoundIcon
    name="bar-chart"
    size={50}
    style={{ backgroundColor: sv.greenLighter, color: sv.green }}
  />
);

function _getVisitsPrevPeriod(range: DateRange, sessions: Array<InsightsSession>) {
  return sessions.filter((session) => isDateInRange(session.createdAt, range)).length;
}

const _memoGetVisitsPrevPeriod = memoize(_getVisitsPrevPeriod, (range) => JSON.stringify(range));

function _calcTotalVisitsData(
  range: DateRange,
  sessions: Array<InsightsSession>,
  _estateSlug: string, // only used in memoization
) {
  const emptyDates = getEmptyDates(range, 'day', 'YYYY-M-DD');

  const visitsByDay = sessions
    .filter((session) => isDateInRange(session.createdAt, range))
    .reduce((memo, session) => {
      const date = session.createdAt.format('YYYY-M-DD');
      return { ...memo, [date]: (memo[date] ?? 0) + 1 };
    }, emptyDates);

  const test = toDataPoints(visitsByDay);
  const totalVisitsData = compressToNPoints(test, POINTS_PER_GRAPH);

  return {
    totalVisitsData,
    totalVisits: totalVisitsData.reduce((memo, p) => memo + p.y, 0),
  };
}

const _memoCalcTotalVisitsData = memoize(_calcTotalVisitsData, (range, _, slug) =>
  JSON.stringify({ range, slug }),
);

interface VisitsDeltaProps {
  insufficientData: boolean;
  totalVisits: number;
  sessions: Array<InsightsSession>;
  timescale: string;
}

const VisitsDelta = ({ insufficientData, totalVisits, sessions, timescale }: VisitsDeltaProps) => {
  if (insufficientData) return <InsufficientData />;

  const daysAmount = timescaleToDays[timescale];
  const range = {
    start: dayjs()
      .subtract(2 * daysAmount, 'day')
      .startOf('day'),
    end: dayjs().subtract(daysAmount, 'day').startOf('day'),
  };

  const visitsPrevPeriod = _memoGetVisitsPrevPeriod(range, sessions);

  if (visitsPrevPeriod === 0) return <InsufficientData />;

  const ratio = (totalVisits - visitsPrevPeriod) / visitsPrevPeriod;

  const percentage = Math.round(ratio * 100);
  const isPositive = Math.sign(percentage) === 1 || Math.sign(percentage) === 0;

  const sign = isPositive ? '+' : '';
  const text = `${sign}${percentage}% ${tt('relative_last_period')}`;
  const category = isPositive ? Category.SUCCESS : Category.DANGER;
  const iconName = isPositive ? 'arrow-up-right' : 'arrow-down-right';

  return <Delta text={text} category={category} iconName={iconName} />;
};

interface TractionCardProps {
  estateSlug: string;
  timescale: string;
  sessions: Array<InsightsSession>;
  range: DateRange;
}

export const TractionCard = ({ estateSlug, timescale, sessions, range }: TractionCardProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const { totalVisitsData, totalVisits } = _memoCalcTotalVisitsData(range, sessions, estateSlug);
  const tractionDelta =
    timescale !== Timescale.ALL_TIME ? (
      <VisitsDelta
        insufficientData={false}
        totalVisits={totalVisits}
        sessions={sessions}
        timescale={timescale}
      />
    ) : (
      <Text light tier={Tier.SECONDARY}>
        {tt('select_a_range')}
      </Text>
    );

  return (
    <Panel
      style={{ width: '100%', height: '245px', overflow: 'hidden' }}
      body={
        <PanelBody style={{ position: 'relative' }}>
          <DashboardCardHeader
            icon={<TractionIcon />}
            title={tt('traction')}
            url={getUrlInProject('/analytics/traction')}
          />
          <Margin size={{ top: Size.DEFAULT }}>
            <ListTile
              leading={<Title style={{ margin: '0px' }}>{totalVisits}</Title>}
              title={<Text light>{tt('visits_last_period')}</Text>}
              subtitle={tractionDelta}
            />
          </Margin>
          <div
            style={{
              position: 'absolute',
              width: '368px',
              height: '91px',
              marginLeft: '-24px',
            }}>
            <FlushChart data={[{ color: sv.green, id: 'line', data: totalVisitsData }]} />
          </div>
        </PanelBody>
      }
    />
  );
};
