import './bootstrap/setup-log-rocket';

import { DiContainer } from '@tokamakjs/react';
import { createElement } from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { bootstrap } from './bootstrap';

bootstrap((diContainer: DiContainer) => {
  ReactDOM.render(createElement(App, { diContainer }), document.getElementById('root'));
});
