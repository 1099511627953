import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { MultidiffusionRoute } from './Multidiffusion';

export const Routes = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<MultidiffusionRoute />} />
    </RoutesWithNotFound>
  );
};
