import sv from '@drawbotics/drylus-style-vars';
import { groupBy } from 'lodash';

export interface TractionBarData {
  data: string;
  visitCount: number;
  color: string;
}

export function generateBarChartData(data: Array<any>, property: string): Array<TractionBarData> {
  const groupedData = groupBy(data, property);

  return Object.keys(groupedData).map((c) => ({
    data: c !== '' ? c : 'Organic',
    visitCount: groupedData[c].length,
    color: sv.neutral,
  }));
}
