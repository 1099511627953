import gql from 'fraql';

import { Address, ErrorType, ID } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Availabilities, AvailabilityDay, CalendarProvider, Day } from '../../types';

const AddressesQuery = gql`
  query AddressesQuery {
    addresses {
      id
      countryCode
      city
      zipCode
      street
    }
  }
`;

interface AvailabilitySlotFromServer {
  id: ID;
  day: Day;
  addressId: string;
  startTime: string;
  endTime: string;
  remote: boolean;
  active: boolean;
}

const AvailabilitiesQuery = gql`
  query AvailabilitiesQuery {
    availabilities {
      id
      day
      startTime
      endTime
      active
      addressId
      remote
    }
  }
`;

const CalendarQuery = gql`
  query CalendarStatus {
    currentUser {
      syncrCalendar
      schedulingToken
    }
  }
`;

function _allEqualByKey<Element, Key extends keyof Element>(array: Array<Element>, key: Key) {
  if (array.length === 0) {
    return true;
  }

  return array.every((e) => e[key] === array[0][key]);
}

function _processAvailabilities(
  slotsFromServer: Array<AvailabilitySlotFromServer>,
): Availabilities {
  const availabilitySlotsByDay = Object.keys(Day).reduce(
    (memo, currentDay) => ({
      ...memo,
      [currentDay]: slotsFromServer.filter((slot) => slot.day === currentDay),
    }),
    {} as Record<Day, Array<AvailabilitySlotFromServer>>,
  );

  let finalAvailabilities: Array<AvailabilityDay> = [];

  for (const [day, slots] of Object.entries(availabilitySlotsByDay)) {
    if (slots.length === 0) {
      finalAvailabilities.push({
        name: day as Day,
        timeslots: [],
        isActive: false,
        address: undefined,
        remote: false,
      });
      continue;
    }

    if (!_allEqualByKey(slots, 'active')) {
      console.error(
        'Expected all slots for a given day to have the same value for property `active`',
      );
    }

    if (!_allEqualByKey(slots, 'addressId')) {
      console.error(
        'Expected all slots for a given day to have the same value for property `addressId`',
      );
    }

    const occupiedTimeSlotsForThisDay = slots.map(({ id, startTime, endTime }) => ({
      id,
      start: startTime,
      end: endTime,
    }));

    finalAvailabilities.push({
      name: day as Day,
      isActive: slots[0].active,
      timeslots: occupiedTimeSlotsForThisDay,
      address: slots[0].addressId == null ? undefined : slots[0].addressId,
      remote: slots[0].remote,
    });
  }

  return finalAvailabilities;
}

export function useFetchAvailabilities(): {
  calendarStatus?: CalendarProvider;
  schedulingToken?: string;
  refetchCalendarStatus: VoidFunction;
  refetchAvailabilities: VoidFunction;
  addresses: Array<Address>;
  availabilities: Availabilities;
  isLoading: boolean;
  error?: ErrorType;
} {
  const {
    data: { addresses } = { addresses: [] },
    isLoading: isLoadingAddresses,
    error: addressesError,
  } = useMosaicQuery<{ addresses: Array<Address> }, null>({
    query: AddressesQuery,
  });

  const {
    data: { availabilities: availabilitiesFromServer } = { availabilities: null },
    isLoading: isLoadingAvailabilities,
    refetch: refetchAvailabilities,
    error: availabilitiesError,
  } = useMosaicQuery<{ availabilities: Array<AvailabilitySlotFromServer> }, null>({
    query: AvailabilitiesQuery,
  });

  const {
    data: calendarData,
    isLoading: isLoadingCalendarStatus,
    error: calendarStatusError,
    refetch: refetchCalendar,
  } = useMosaicQuery<
    { currentUser: { syncrCalendar?: CalendarProvider; schedulingToken?: string } },
    undefined
  >({
    query: CalendarQuery,
  });

  return {
    calendarStatus: calendarData?.currentUser.syncrCalendar,
    schedulingToken: calendarData?.currentUser.schedulingToken,
    refetchCalendarStatus: () => refetchCalendar({ requestPolicy: 'network-only' }),
    refetchAvailabilities: () => refetchAvailabilities({ requestPolicy: 'network-only' }),
    addresses: addresses,
    availabilities:
      availabilitiesFromServer == null ? [] : _processAvailabilities(availabilitiesFromServer),
    isLoading: isLoadingAddresses || isLoadingAvailabilities || isLoadingCalendarStatus,
    error: buildError(availabilitiesError ?? addressesError ?? calendarStatusError),
  };
}
