import gql from 'fraql';

import { unitFragment } from '../fragments';

export const ListingQuery = gql`
  query ListingQuery($projectId: ID!) {
    listing(projectId: $projectId) {
      id
      name
      providerName
      currency
      surfaceUnit
      units {
        ${unitFragment}
      }
    }
  }
`;
