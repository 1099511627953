import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.lead.components.delete_project_modal');

interface DeleteProjectModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  onClickConfirm: VoidFunction;
  loading: boolean;
}

export const DeleteProjectModal = ({
  visible,
  onClickClose,
  onClickConfirm,
  loading,
}: DeleteProjectModalProps) => {
  return (
    <Modal
      onClickClose={onClickClose}
      visible={visible}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel_button')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              disabled={loading}
              trailing={loading ? <Spinner inversed size={Size.SMALL} /> : null}
              category={Category.DANGER}
              onClick={onClickConfirm}>
              {tt('delete_button')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Text>{tt('delete_confirmation')}</Text>
    </Modal>
  );
};
