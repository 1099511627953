import sv, { fade } from '@drawbotics/drylus-style-vars';
import {
  Align,
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  ListTile,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import { css, keyframes } from 'emotion';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.lead.components.sales_agreement_modal.congrats');

const rain = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: 100%;
    transform: rotate(0);
  }
`;

const styles = {
  medal: css`
    height: 38px;
    width: 38px;
    border: 4px solid ${sv.orange};
    background: ${fade(sv.orange, 80)};
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${sv.white};
    font-size: 1.4em;
  `,
  container: css`
    max-width: 450px;
    padding: 0 ${sv.paddingExtraLarge};
  `,
  confettiBackground: css`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  `,
  confetti: css`
    position: absolute;
    width: 8px;
    height: 16px;
    top: 0;
    opacity: 0;
    z-index: 1;
    animation: ${rain} infinite ease-out;
  `,
};

function _random(min: number, max: number): number {
  return Math.floor(min + Math.random() * (max + 1 - min));
}

function _randomColor(): string {
  const value = Math.random();
  if (value < 0.2) {
    return sv.red;
  }
  if (value < 0.4) {
    return sv.green;
  }
  if (value < 0.6) {
    return sv.orange;
  }
  if (value < 0.8) {
    return '#8C46D1';
  }
  return sv.blue;
}

const Confetti = () => {
  const amount = 20;
  return (
    <div className={styles.confettiBackground}>
      {[...new Array(amount)].map((_, i) => {
        const depth = Math.max(Math.random(), 0.5); // 0 closest, 1 furthest
        const leftOffset = Math.min(Math.random() * 100, 97);
        return (
          <div
            key={i}
            style={{
              width: `${Math.max(1 - depth, 0.2) * 30}px`,
              height: `${Math.max(1 - depth, 0.2) * 60}px`,
              transform: `rotate(${_random(-80, 80)}deg)`,
              background: _randomColor(),
              left: `${leftOffset}%`,
              animationDuration: `${depth * 5000}ms`,
              animationDelay: `${Math.random() * 3000}ms`,
            }}
            className={styles.confetti}></div>
        );
      })}
    </div>
  );
};

interface MedalProps {
  count: number;
}

const Medal = ({ count }: MedalProps) => {
  return <div className={styles.medal}>{count}</div>;
};

const animationVariants = {
  visible: (delay: number = 0) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay,
    },
  }),
  hidden: {
    opacity: 0,
    y: -20,
  },
};

interface CongratulationsProps {
  onClickClose: VoidFunction;
}

export const Congratulations = ({ onClickClose }: CongratulationsProps) => {
  const titleControls = useAnimation();
  const subtitleControls = useAnimation();
  const badgeControls = useAnimation();

  const sequence = async () => {
    await titleControls.start('visible');
    await subtitleControls.start('visible');
    await badgeControls.start({
      scale: 1,
      rotate: 0,
      opacity: 1,
    });
  };

  useEffect(() => {
    sequence();
  }, []);

  return (
    <div className={styles.container}>
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <motion.div
            custom={0.7}
            variants={animationVariants}
            animate={titleControls}
            initial="hidden">
            <Title align={Align.CENTER} size={3}>
              {tt('good_job')}
            </Title>
          </motion.div>
        </FlexItem>
        <FlexItem style={{ textAlign: 'center' }}>
          <motion.div variants={animationVariants} animate={subtitleControls} initial="hidden">
            <Text>{tt('cheering', { sales_number: 5 })}</Text>
          </motion.div>
        </FlexItem>
        <FlexSpacer size={Size.LARGE} />
        <FlexItem>
          <motion.div
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            animate={badgeControls}
            initial={{ opacity: 0, scale: 0.4, rotate: -25 }}>
            <ListTile
              leading={<Medal count={5} />}
              title={<Text bold>{tt('sales_agreements')}</Text>}
              subtitle={tt('this_month')}
            />
          </motion.div>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_LARGE} />
        <Button onClick={onClickClose} category={Category.INFO}>
          {tt('continue_button')}
        </Button>
        <FlexSpacer size={Size.LARGE} />
      </Flex>
      <Confetti />
    </div>
  );
};
