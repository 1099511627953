import Cookies from 'js-cookie';
import { isEqualWith, omit, pick } from 'lodash';
import randomColor from 'randomcolor';

import { Attachment, HexColor } from '~/types';

import { UploadedFile } from './uploads';

const HELP_SEEN_COOKIE = 'dbs-help-seen';

export function generateColorFromString(seed: string): HexColor {
  return randomColor({ seed });
}

export function nonEmptyJoin(strings: Array<string | undefined>, joinBy: string): string {
  return strings.filter((s) => s != null).join(joinBy);
}

export function isAttachment(file: UploadedFile | Attachment): file is Attachment {
  return 'id' in file;
}

export function isShallowEqual(a: Object, b: Object) {
  return isEqualWith(a, b, (aValue, bValue, index) =>
    index === undefined ? undefined : Object.is(aValue, bValue),
  );
}

export function getRootDomain(): string {
  if (location.hostname.includes('localhost')) {
    const [, domain] = location.hostname.match(/(localhost:\d+)/) ?? [];
    return domain as string;
  } else if (location.hostname.includes('netlify')) {
    return location.hostname;
  } else {
    return `.${location.hostname.split('.').slice(-2).join('.')}`;
  }
}

export function setHelpSeen() {
  Cookies.set(HELP_SEEN_COOKIE, 'true', { domain: getRootDomain(), expires: 10000 });
}

export function getHelpSeen() {
  return Cookies.get(HELP_SEEN_COOKIE) != null;
}

export function median(values: Array<number>): number {
  const sorted = [...values].sort((a, b) => a - b);
  const half = Math.floor(sorted.length / 2);
  if (sorted.length % 2) {
    return sorted[half];
  }
  return Math.round((sorted[half - 1] + sorted[half]) / 2);
}

export function isNullOrEmpty(value: string | null | undefined | Array<unknown>): boolean {
  return (
    value == null ||
    (typeof value === 'string' && value.trim() === '') ||
    (Array.isArray(value) && value.length === 0)
  );
}

// Returns two objects that are subsets of the `object` argument, the first containing only the keys
// specified in `keys`, the other one containing only the keys not specified in `keys`.
export function splitObject<O extends object, K extends Array<keyof O>>(
  object: O,
  keys: K,
): [Omit<O, K[number]>, Pick<O, K[number]>] {
  return [omit(object, keys) as Omit<O, K[number]>, pick(object, keys) as Pick<O, K[number]>];
}
