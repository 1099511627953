import React, { useEffect } from 'react';

import { EnhancedRangeInput } from '~/pods/crm/components';
import { LeadsFilterTypes } from '~/pods/crm/types';
import { Currency } from '~/types';
import { translatePrice } from '~/utils/translation';

interface BudgetFilterProps {
  currency: Currency;
  max: number;
  min: number;
  isLoading?: boolean;
  filter: LeadsFilterTypes['budget'];
  step: number;
}

export const BudgetFilter = ({
  currency = 'EUR',
  min,
  max,
  isLoading,
  filter,
  step,
}: BudgetFilterProps) => {
  const renderNumberInput = (v: number | string | '' | '-') => {
    return translatePrice(String(v), currency);
  };

  useEffect(() => {
    if (filter.value) {
      const [currentMin, currentMax] = filter.values ?? [];

      if (Number(currentMin) == min && Number(currentMax) == max) {
        filter.set(null);
      }
    }
  }, [filter.value]);

  return (
    <EnhancedRangeInput
      min={min}
      max={max}
      onChange={(range) => filter.set([range[0].toString(), range[1].toString()])}
      values={filter.values ? [Number(filter.values[0]), Number(filter.values[1])] : [min, max]}
      renderValue={renderNumberInput}
      step={step}
      isLoading={isLoading}
    />
  );
};
