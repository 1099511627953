import sv from '@drawbotics/drylus-style-vars';
import { Layout, Position } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

export const dimensions = {
  width: 600,
};

const styles = {
  left: css`
    width: ${dimensions.width}px;
    height: 100%;
    background: ${sv.neutralLighter};
  `,
};

interface SplitLayoutProps {
  mainContent: ReactNode;
  secondaryContent: ReactNode;
}

export const SplitLayout = ({ secondaryContent, mainContent }: SplitLayoutProps) => {
  return (
    <Layout bar={<div className={styles.left}>{mainContent}</div>} position={Position.LEFT}>
      {secondaryContent}
    </Layout>
  );
};
