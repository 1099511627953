import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  Padding,
  Panel,
  PanelBody,
  PanelFooter,
  Separator,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { useEffect, useState } from 'react';

import {
  LeadEmailActionType,
  LeadEmailActionsForm,
  LeadEmailActionsLink,
  SingleLeadEmailActionForm,
  UpdateLeadEmailActionFormErrors,
  UpdateLeadEmailActionsFormErrors,
} from '~/pods/project-settings/types';
import {
  UpdateLeadEmailActionMutationVariables,
  UpdateLeadEmailActionsMutationVariables,
  useLeadEmailActionsMutation,
} from '~/pods/project-settings/utils';
import { Subscription } from '~/types';
import { createTranslate, useAuth } from '~/utils';
import { isValidUrl } from '~/utils';

import { LeadEmailAction } from './LeadEmailAction';

const tt = createTranslate('pods.project_settings.project_settings.lead_email_settings');

function linksToLeadEmailActions(links: LeadEmailActionsLink[]): LeadEmailActionsForm {
  const primary = links.find((link) => link.category === 'PRIMARY' && link.activated);
  const secondary = links.find((link) => link.category === 'SECONDARY' && link.activated);

  const linkToEmailAction = (link: LeadEmailActionsLink): SingleLeadEmailActionForm => {
    return {
      buttonLabel: link.label,
      scheduling:
        link.linkType === LeadEmailActionType.SCHEDULING
          ? { includeInterests: link.includeInterests }
          : undefined,
      externalLink:
        link.linkType === LeadEmailActionType.EXTERNAL_LINK ? { url: link.url } : undefined,
      download:
        link.linkType === LeadEmailActionType.DOWNLOAD
          ? { file: { filename: link.attachment?.filename!, url: link.attachment?.url! } }
          : undefined,
    };
  };

  return {
    primary: primary ? linkToEmailAction(primary) : undefined,
    secondary: secondary ? linkToEmailAction(secondary) : undefined,
  };
}

export const LeadEmailActionsPanel = (props: {
  projectId: string;
  links: LeadEmailActionsLink[];
}) => {
  const { user } = useAuth();

  const form = useForm<LeadEmailActionsForm>(linksToLeadEmailActions(props.links));

  const [errors, setErrors] = useState<UpdateLeadEmailActionsFormErrors>({});
  const [attempedToUpdate, setAttemptedToUpdate] = useState(false);

  const { isUpdatingLeadEmailActions, updateLeadEmailActions } = useLeadEmailActionsMutation();

  const licenseSupportsScheduling =
    user?.organisation?.subscription === Subscription.GROWTH ||
    user?.organisation?.subscription === Subscription.REACH;

  const validateLeadEmailAction = (
    leadEmailAction: undefined | SingleLeadEmailActionForm,
  ): undefined | UpdateLeadEmailActionFormErrors => {
    if (!leadEmailAction) {
      return undefined;
    }

    const __errors = leadEmailAction?.buttonLabel ? undefined : { buttonLabelIsRequired: true };

    if (!leadEmailAction.scheduling && !leadEmailAction.externalLink && !leadEmailAction.download) {
      return {
        ...__errors,
        linkToIsRequired: true,
      };
    }

    if (leadEmailAction?.scheduling) {
      const licenseDoesNotSupportScheduling = !licenseSupportsScheduling;

      if (licenseDoesNotSupportScheduling) {
        return {
          ...__errors,
          licenseDoesNotSupportScheduling,
        };
      }
    }

    if (leadEmailAction?.externalLink) {
      const externalLinkUrlRequired = !leadEmailAction.externalLink.url;
      const externalLinkUrlWrongFormat = leadEmailAction.externalLink.url
        ? !isValidUrl(leadEmailAction.externalLink.url)
        : false;

      if (externalLinkUrlRequired || externalLinkUrlWrongFormat) {
        return {
          ...__errors,
          externalLinkUrlRequired,
          externalLinkUrlWrongFormat,
        };
      }
    }

    if (leadEmailAction?.download) {
      const downloadFileIsRequired = !leadEmailAction.download.file;

      if (downloadFileIsRequired) {
        return {
          ...__errors,
          downloadFileIsRequired,
        };
      }
    }

    return __errors;
  };

  useEffect(() => {
    if (attempedToUpdate) {
      const _errors = {
        primary: validateLeadEmailAction(form.values.primary),
        secondary: validateLeadEmailAction(form.values.secondary),
      };

      setErrors(_errors);
    }
  }, [JSON.stringify(form.values)]);

  const handleUpdate = async () => {
    const _errors = {
      primary: validateLeadEmailAction(form.values.primary),
      secondary: validateLeadEmailAction(form.values.secondary),
    };

    setAttemptedToUpdate(true);

    if (_errors.primary || _errors.secondary) {
      setErrors(_errors);
    } else {
      const leadEmailActionToLinkMutationData = <Category extends 'PRIMARY' | 'SECONDARY'>(
        leadEmailAction: SingleLeadEmailActionForm,
        category: Category,
      ): UpdateLeadEmailActionMutationVariables<Category> => {
        return {
          activated: true,
          category,
          label: leadEmailAction.buttonLabel,
          ...(() => {
            if (leadEmailAction.scheduling) {
              return {
                linkType: LeadEmailActionType.SCHEDULING,
                includeInterests: leadEmailAction.scheduling.includeInterests,
              };
            } else if (leadEmailAction.externalLink) {
              return {
                linkType: LeadEmailActionType.EXTERNAL_LINK,
                url: leadEmailAction.externalLink.url,
              };
            } else {
              return {
                linkType: LeadEmailActionType.DOWNLOAD,
                signedBlobId: leadEmailAction.download?.file?.signedBlobId,
              };
            }
          })(),
        };
      };

      const res = await updateLeadEmailActions({
        links: {
          projectId: props.projectId,
          emailCtaLinks: ([] as UpdateLeadEmailActionsMutationVariables['links']['emailCtaLinks'])
            .concat(
              form.values.primary
                ? [leadEmailActionToLinkMutationData(form.values.primary, 'PRIMARY')]
                : [{ activated: false, category: 'PRIMARY' }],
            )
            .concat(
              form.values.secondary
                ? [leadEmailActionToLinkMutationData(form.values.secondary, 'SECONDARY')]
                : [{ activated: false, category: 'SECONDARY' }],
            ),
        },
      });

      if (res.data?.links) {
        const leadEmailActions = linksToLeadEmailActions(res.data.links);

        form.set(leadEmailActions.primary, 'primary');
        form.set(leadEmailActions.secondary, 'secondary');
      }
    }
  };

  return (
    <Panel
      body={
        <PanelBody>
          <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
            <FlexItem flex={1}>
              <LeadEmailAction
                licenseSupportsScheduling={licenseSupportsScheduling}
                actionPriority="primary"
                form={form}
                errors={errors.primary}
                onLinkToChange={(type) => {
                  if (type === LeadEmailActionType.SCHEDULING) {
                    if (form.values.secondary?.scheduling != null) {
                      form.set(
                        {
                          buttonLabel: form.values.secondary.buttonLabel,
                          scheduling: undefined,
                        },
                        'secondary',
                      );
                    }

                    form.set(
                      {
                        buttonLabel: form.values.primary?.buttonLabel,
                        scheduling: {},
                      },
                      'primary',
                    );
                  } else if (type === LeadEmailActionType.EXTERNAL_LINK) {
                    form.set(
                      {
                        buttonLabel: form.values.primary?.buttonLabel,
                        externalLink: {},
                      },
                      'primary',
                    );
                  } else if (type === LeadEmailActionType.DOWNLOAD) {
                    form.set(
                      {
                        buttonLabel: form.values.primary?.buttonLabel,
                        download: {},
                      },
                      'primary',
                    );
                  }
                }}
              />
            </FlexItem>
            <FlexItem>
              <Padding style={{ height: '100%' }} size={{ horizontal: Size.SMALL }}>
                <Separator style={{ height: '100%' }} vertical />
              </Padding>
            </FlexItem>
            <FlexItem flex={1}>
              <LeadEmailAction
                licenseSupportsScheduling={licenseSupportsScheduling}
                actionPriority="secondary"
                form={form}
                errors={errors.secondary}
                onLinkToChange={(type) => {
                  if (type === LeadEmailActionType.SCHEDULING) {
                    if (form.values.primary?.scheduling != null) {
                      form.set(
                        {
                          buttonLabel: form.values.primary.buttonLabel,
                          scheduling: undefined,
                        },
                        'primary',
                      );
                    }

                    form.set(
                      {
                        buttonLabel: form.values.secondary?.buttonLabel,
                        scheduling: {},
                      },
                      'secondary',
                    );
                  } else if (type === LeadEmailActionType.EXTERNAL_LINK) {
                    form.set(
                      {
                        buttonLabel: form.values.secondary?.buttonLabel,
                        externalLink: {},
                      },
                      'secondary',
                    );
                  } else if (type === LeadEmailActionType.DOWNLOAD) {
                    form.set(
                      {
                        buttonLabel: form.values.secondary?.buttonLabel,
                        download: {},
                      },
                      'secondary',
                    );
                  }
                }}
              />
            </FlexItem>
          </Flex>
        </PanelBody>
      }
      footer={
        <PanelFooter>
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button
                leading={isUpdatingLeadEmailActions ? <Spinner inversed /> : null}
                disabled={isUpdatingLeadEmailActions}
                onClick={handleUpdate}
                category={Category.INFO}>
                {tt('update')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelFooter>
      }
    />
  );
};
