import { TargetDomain, getJwt, refreshJwt } from '@drawbotics/auth';
import {
  Align,
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Modal,
  Paragraph,
  Size,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { useLocation } from '@tokamakjs/react';
import React, { Fragment, ReactNode } from 'react';

import { createTranslate, decodeUser, useIntercom } from '~/utils';

import { Navbar } from './Navbar';
import { NavbarLayout } from './NavbarLayout';

const tt = createTranslate('components.subscription_guard');

interface SubscriptionGuardProps {
  children: ReactNode;
}

export const SubscriptionGuard = ({ children }: SubscriptionGuardProps) => {
  const { pathname } = useLocation();
  const jwt = getJwt();
  const user = jwt != null ? decodeUser(jwt) : undefined;
  const { showIntercom } = useIntercom(user);

  if (user?.organisation?.isAgency) {
    return <Fragment>{children}</Fragment>;
  }

  if (
    !pathname.includes('impersonation') &&
    user != null &&
    user.organisation != null &&
    user?.organisation?.subscription == null
  ) {
    return (
      <NavbarLayout navbar={<Navbar />}>
        <Modal visible>
          <Flex direction={FlexDirection.VERTICAL} style={{ width: 400, margin: 'auto' }}>
            <FlexItem>
              <Title align={Align.CENTER} size={4}>
                {tt('no_subscription')}
              </Title>
            </FlexItem>
            <FlexItem>
              <Paragraph align={Align.CENTER}>{tt('power_comm')}</Paragraph>
            </FlexItem>
            <FlexItem>
              <Button category={Category.INFO} onClick={showIntercom}>
                {tt('contact_us')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                tier={Tier.SECONDARY}
                onClick={async () => {
                  await refreshJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC);
                  window.location.reload();
                }}>
                {tt('have_subscription')}
              </Button>
            </FlexItem>
          </Flex>
        </Modal>
      </NavbarLayout>
    );
  }

  return <Fragment>{children}</Fragment>;
};
