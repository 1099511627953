import { setJwt, setRefreshToken } from '@drawbotics/auth';
import { useLocation } from '@tokamakjs/react';
import queryString from 'query-string';
import { useEffect } from 'react';

export const ImpersonationRoute = () => {
  const { search } = useLocation();

  useEffect(() => {
    const { token, refreshToken } = queryString.parse(search);
    setJwt(token as string);
    setRefreshToken(refreshToken as string);
    // todo: fix redirect
    window.location.href = '/';
  }, []);

  return null;
};
