import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Margin,
  MultiSelect,
  SearchInput,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { AnimatePresence, motion } from 'framer-motion';
import { uniq } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { createTranslate, isNullOrEmpty, isValidEmail, useDebounce } from '~/utils';

import { useFetchLeads } from '../utils';
import { NewMeetingFormErrors } from './CreateAndEditMeetingModal';

const tt = createTranslate('pods.marketing_suite.components.participants_editor');

interface ParticipantsDetails {
  leadId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  additionalParticipantEmails?: Array<string>;
}

interface ParticipantsEditorProps {
  onChange: (
    value: ParticipantsDetails[keyof ParticipantsDetails],
    name?: keyof ParticipantsDetails,
  ) => void;
  values: ParticipantsDetails;
  leadLocked?: boolean;
  extraErrors: NewMeetingFormErrors;
}

export const ParticipantsEditor = ({
  values,
  onChange,
  leadLocked,
  extraErrors,
}: ParticipantsEditorProps) => {
  const { leadId, email, firstName, lastName, additionalParticipantEmails = [] } = values;

  const [leadEmail, setLeadEmail] = useState(email ?? '');
  const debouncedSearchTerm = useDebounce(leadEmail, 500);
  const [showExtraParticipants, setShowExtraParticipants] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const { isLoading: isLeadsLoading, leads = [] } = useFetchLeads(
    { searchTerm: debouncedSearchTerm },
    { skip: debouncedSearchTerm == '' },
  );

  const handleCheckEmailError = (email?: string) => {
    !isValidEmail(email)
      ? setErrors([...errors, 'leadEmail'])
      : setErrors(errors.filter((err) => err !== 'leadEmail'));
  };

  const handleClickResult = (leadId: string) => {
    const lead = leads.find((lead) => lead.id === leadId);
    if (lead != null) {
      setLeadEmail(lead.email!);
      onChange(lead.id, 'leadId');
      onChange(lead.firstName, 'firstName');
      onChange(lead.lastName, 'lastName');
      onChange(lead.email, 'email');
      handleCheckEmailError(lead.email);
    }
  };

  const handleOnChangeEmail = (value: string) => {
    setLeadEmail(value.trim());
    onChange(value.trim(), 'email');
    onChange(undefined, 'leadId'); // reset Lead id when modifying the email
  };

  const validateLeadEmail = () => {
    if (!isValidEmail(debouncedSearchTerm) && debouncedSearchTerm !== '') {
      setErrors([...errors, 'leadEmail']);
    } else {
      setErrors(errors.filter((err) => err !== 'leadEmail'));
    }
  };

  useEffect(() => {
    if (additionalParticipantEmails?.some((e) => !isValidEmail(e))) {
      setErrors([...errors, 'additionalEmails']);
    } else {
      setErrors(errors.filter((err) => err !== 'additionalEmails'));
    }
  }, [additionalParticipantEmails?.length]);

  useEffect(() => {
    if (additionalParticipantEmails?.length > 0) {
      setShowExtraParticipants(true);
    }
  }, [additionalParticipantEmails?.length]);

  useEffect(() => {
    if (!isNullOrEmpty(email)) {
      setLeadEmail(email!);
    }
  }, [email]);

  return (
    <Fragment>
      <FormGroup
        input={
          leadLocked ? (
            <Input value={leadEmail} />
          ) : (
            <SearchInput
              error={
                errors.includes('leadEmail') ? tt('invalid_email_format') : extraErrors['email']
              }
              loading={isLeadsLoading}
              placeholder={tt('search_lead')}
              options={leads.map((lead) => ({
                value: lead.id,
                label: `${lead.fullName} (${lead.email})`,
              }))}
              value={leadEmail}
              onClickResult={handleClickResult}
              onChange={handleOnChangeEmail}
              onBlur={validateLeadEmail}
            />
          )
        }
        label={<Label>{tt('lead_email')}*</Label>}
      />
      <Margin size={Size.SMALL} />
      <Flex>
        <FlexItem>
          <FormGroup
            input={
              <Input
                name="firstName"
                placeholder="e.g. John"
                value={firstName ?? ''}
                onChange={
                  leadId == null && !leadLocked ? (v, n) => onChange(String(v), n) : undefined
                }
                error={extraErrors['firstName']}
              />
            }
            label={<Label>{tt('first_name')}</Label>}
          />
        </FlexItem>
        <FlexSpacer />
        <FlexItem flex>
          <FormGroup
            input={
              <Input
                name="lastName"
                placeholder="e.g. Doe"
                value={lastName ?? ''}
                onChange={
                  leadId == null && !leadLocked ? (v, n) => onChange(String(v), n) : undefined
                }
                error={extraErrors['lastName']}
              />
            }
            label={<Label>{tt('last_name')}</Label>}
          />
        </FlexItem>
      </Flex>
      <Margin size={Size.SMALL} />
      <AnimatePresence>
        {showExtraParticipants && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'initial' }}
            exit={{ height: 0 }}>
            <Fragment>
              <Flex align={FlexAlign.END}>
                <FlexItem flex>
                  <FormGroup
                    input={
                      <MultiSelect
                        error={errors.includes('additionalEmails') ? tt('more_invalid') : undefined}
                        name="additionalParticipantEmails"
                        placeholder={tt('type_and_press')}
                        onChange={() => {}}
                        onChangeOptions={(options) => {
                          const labels = options.map((o) => o.label.trim());
                          if (uniq(labels).length === labels.length) {
                            onChange(labels, 'additionalParticipantEmails');
                          }
                        }}
                        values={additionalParticipantEmails}
                        options={additionalParticipantEmails?.map((email) => ({
                          label: email,
                          value: email,
                        }))}
                      />
                    }
                    label={<Label>{tt('additional_emails')}</Label>}
                  />
                </FlexItem>
                <FlexSpacer size={Size.EXTRA_SMALL} />
                <FlexItem>
                  <Button category={Category.INFO}>{tt('add_email')}</Button>
                </FlexItem>
              </Flex>
              <Margin size={Size.EXTRA_SMALL} />
              <Button
                onClick={() => {
                  onChange(undefined, 'additionalParticipantEmails');
                  setShowExtraParticipants(false);
                }}
                size={Size.SMALL}
                tier={Tier.TERTIARY}>
                {tt('clear_emails')}
              </Button>
            </Fragment>
          </motion.div>
        )}
      </AnimatePresence>
      {!showExtraParticipants && (
        <Button
          tier={Tier.TERTIARY}
          size={Size.SMALL}
          onClick={() => setShowExtraParticipants(true)}>
          {tt('add_participants')}
        </Button>
      )}
    </Fragment>
  );
};
