import {
  AttachmentBox,
  AttachmentList,
  Category,
  Checkbox,
  DateInput,
  EmptyState,
  EmptyStateVariation,
  FormGroup,
  InputGroup,
  Label,
  Margin,
  Select,
  Size,
  TextArea,
  UploadBox,
  useAlert,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import { Form } from '@drawbotics/use-form';
import React, { Fragment, useEffect } from 'react';

import { UnitInterestSelection } from '~/pods/crm/components';
import { InterestType, OfferOptions, Project } from '~/pods/crm/types';
import { useFetchOrganisation } from '~/pods/crm/utils';
import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

import { OfferForm } from './SalesAgreementModal';

const tt = createTranslate('pods.crm.routes.lead.components.sales_agreement_modal.document');

interface DocumentFormProps {
  form: Form<OfferForm>;
  files: Array<UploadedFile>;
  setFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
}

export const DocumentForm = ({ form, files, setFiles }: DocumentFormProps) => {
  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisation();
  const {
    data,
    uploadFiles,
    info,
    isLoading: areFilesUploading,
  } = useFileUpload(useMosaicMutation);
  const { showAlert } = useAlert();

  const projects = organisation?.projects;
  const { projectId, units } = form.values;
  const project = projects?.find((p) => p.id === projectId);

  const handleOnChangeInterests = (unitInterests: NonNullable<InterestType['unitInterests']>) => {
    form.set(unitInterests, 'units');
  };

  const handleChangeOptions = (value: boolean, name?: OfferOptions) => {
    const { options = [] } = form.values;
    if (value) {
      form.set([...options, name], 'options');
    } else {
      form.set(
        options.filter((o) => o !== name),
        'options',
      );
    }
  };

  const handleUpload = async (files: FileList) => {
    const res = await uploadFiles(files);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    setFiles(validFiles);
  };

  const handleRemoveFile = (filename: string) => {
    // TODO when available on server, call deleteBlob
    setFiles(files.filter((file) => file.originalFile.name !== filename));
  };

  useEffect(() => {
    if (!areFilesUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: tt('upload.error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [areFilesUploading]);

  useEffect(() => {
    // If there are many files, resize to center modal
    window.dispatchEvent(new Event('resize'));
  }, [files.length]);

  //TODO change unitReference when it's stable
  return (
    <Fragment>
      {organisationError != null ? (
        <EmptyState
          title={tt('empty_state.title')}
          description={tt('empty_state.description')}
          variation={EmptyStateVariation.FAILED}
        />
      ) : (
        <Fragment>
          <Margin size={{ bottom: Size.SMALL }}>
            <FormGroup
              label={<Label>{tt('offer_title')}</Label>}
              input={
                <InputGroup>
                  <Select
                    disabled={organisationError != null}
                    name="projectId"
                    loading={isOrganisationLoading}
                    onChange={(projectId) => {
                      form.set(projectId, 'projectId');
                      form.set([], 'units');
                    }}
                    value={form.get}
                    options={
                      projects?.map((project: Project) => ({
                        value: project.id,
                        label: project.name,
                      })) ?? []
                    }
                    placeholder={tt('select_project')}
                  />
                  {run(() => {
                    if (project != null) {
                      return (
                        <UnitInterestSelection
                          onChange={handleOnChangeInterests}
                          project={project}
                          values={units ?? []}
                          customHeader
                        />
                      );
                    }
                  })}
                </InputGroup>
              }
            />
          </Margin>
          <Margin size={{ bottom: Size.SMALL }}>
            <UploadBox multiple fullWidth onUploadFiles={handleUpload} />
          </Margin>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <AttachmentList>
              {files.map((file) => (
                <AttachmentBox
                  key={file.originalFile.name}
                  fileName={file.originalFile.name}
                  onClickClose={() => handleRemoveFile(file.originalFile.name)}
                />
              ))}
              {areFilesUploading
                ? info.map((uploadInfo) => (
                    <AttachmentBox
                      key={uploadInfo.filename}
                      fileName={uploadInfo.filename}
                      progress={uploadInfo.progress}
                    />
                  ))
                : []}
            </AttachmentList>
          </Margin>
          <Margin size={{ bottom: Size.DEFAULT }}>
            <FormGroup
              label={<Label>{tt('description_label')}</Label>}
              input={
                <InputGroup>
                  <TextArea
                    name="description"
                    placeholder={tt('notes')}
                    value={form.get}
                    onChange={form.set}
                  />
                  <Checkbox
                    name={OfferOptions.GIVEN_PRICE}
                    value={form.values.options?.includes(OfferOptions.GIVEN_PRICE)}
                    onChange={handleChangeOptions}>
                    {tt('offer.at_price')}
                  </Checkbox>
                  <Checkbox
                    name={OfferOptions.NO_CREDIT_PRECEDENCE}
                    value={form.values.options?.includes(OfferOptions.NO_CREDIT_PRECEDENCE)}
                    onChange={handleChangeOptions}>
                    {tt('offer.credit_precedent')}
                  </Checkbox>
                  <Checkbox
                    name={OfferOptions.WITHOUT_CONDITION}
                    value={form.values.options?.includes(OfferOptions.WITHOUT_CONDITION)}
                    onChange={handleChangeOptions}>
                    {tt('offer.no_condition')}
                  </Checkbox>
                </InputGroup>
              }
            />
          </Margin>
          <FormGroup
            label={<Label>{tt('date_label')}</Label>}
            input={<DateInput name="date" value={form.get} onChange={form.set} />}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
