import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Invitation, TeammateType, User } from '../../types';

interface TeammatesQueryResult {
  teammates: Array<User | Invitation>;
}

interface TeammatesQueryVariables {
  filter: {
    licensed?: boolean;
  };
}

const TeammatesQuery = gql`
  query TeammatesQuery($filter: TeammateFilterInput!) {
    teammates(filter: $filter) {
      ... on User {
        id
        email
        fullName @computed(type: User)
        meetingCount
        role
        memberSince: createdAt
      }
      ... on Invitation {
        id
        email
        fullName @computed(type: Invitation)
        role
        lastSentAt
      }
    }
  }
`;

export function useFetchTeammates({
  licensed = true, // load licensed teammates by default, as only 1 instance requires non-licensed loading
}: // type,
{
  licensed?: boolean;
  type?: TeammateType;
} = {}): {
  isLoading: boolean;
  teammates?: Array<User | Invitation>;
  error?: ErrorType;
  refetch: VoidFunction;
} {
  const res = useMosaicQuery<TeammatesQueryResult, TeammatesQueryVariables>({
    query: TeammatesQuery,
    variables: { filter: { licensed } },
  });

  return {
    isLoading: res.isLoading,
    teammates: res.data?.teammates,
    error: buildError(res.error),
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
