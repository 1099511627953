import React from 'react';
import { Route } from 'react-router';

import { RoutesWithNotFound } from '~/components';

import { Profile } from './Profile';

export const ProfileRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<Profile />} />
    </RoutesWithNotFound>
  );
};
