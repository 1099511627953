import { Button, Color, Icon, Size, Spinner } from '@drawbotics/react-drylus';
import React, { useState } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.presentations_table');

interface StatusButtonProps {
  title: string;
  onClick: VoidFunction;
  loading: boolean;
  isPublished: boolean;
  align?: 'right' | 'left';
  disabled?: boolean;
}

export const StatusButton = ({
  title,
  onClick,
  loading,
  isPublished,
  align = 'left',
  disabled = false,
}: StatusButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={align === 'right' ? { display: 'flex', justifyContent: 'flex-end' } : undefined}
      onMouseEnter={disabled ? undefined: () => setIsHovered(true)}
      onMouseLeave={disabled ? undefined: () => setIsHovered(false)}>
      {isHovered ? (
        <Button
          size={Size.SMALL}
          leading={isPublished ? <Icon name="eye-off" /> : <Icon name="eye" />}
          trailing={loading ? <Spinner size={Size.SMALL} inversed /> : null}
          color={isPublished ? Color.RED : Color.GREEN}
          onClick={onClick}
          disabled={disabled}>
          {isPublished ? tt('unpublish') : tt('publish')}
        </Button>
      ) : (
        <Button
          size={Size.SMALL}
          leading={isPublished ? <Icon name="eye" /> : <Icon name="eye-off" />}
          trailing={loading ? <Spinner size={Size.SMALL} inversed={isPublished} /> : null}
          color={isPublished ? Color.GREEN : undefined}
          disabled={disabled}>
          {title}
        </Button>
      )}
    </div>
  );
};
