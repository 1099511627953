import faker from 'faker';

import { DateRange, ShowcaseSession } from './types';
import { Units } from './units';
import {
  distribute,
  pickBrowser,
  pickCampaign,
  pickCity,
  pickDateInRange,
  pickDevice,
  pickSource,
  weightedPick,
} from './utils';

export class Sessions {
  public readonly sessions: Array<ShowcaseSession> = [];

  constructor(private readonly units: Units) {}

  createVisits(
    sessionsAmount: number,
    visitsAmount: number,
    dateRange: DateRange,
    weights: Record<string, number> = {},
  ): void {
    const sessions = [];

    const weightsSum = Object.values(weights).reduce((m, v) => m + v, 0);

    if (weightsSum > 1) {
      throw new Error('Weights sum cannot be greater than 1.');
    }

    if (Object.keys(weights).some((k) => !this.units.typologies.includes(k))) {
      throw new Error('Invalid typology found in weights.');
    }

    for (let i = 0; i < sessionsAmount; i++) {
      sessions.push(this.createSession(dateRange));
    }

    const totalWeights = this.getWeights(weights);

    for (let i = 0; i < visitsAmount; i++) {
      const unitId = this.pickUnitIdWithWeight(totalWeights);
      const sessionIndex = faker.random.number(sessionsAmount - 1);
      sessions[sessionIndex] = this.createVisitInSession(unitId, sessions[sessionIndex]);
    }

    this.sessions.push(...sessions);
  }

  private createSession(dateRange: DateRange): ShowcaseSession {
    return {
      createdAt: pickDateInRange(dateRange),
      campaignToken: pickCampaign(),
      time: [],
      path: [],
      referrer: pickSource(),
      ipData: {
        ip: '',
        type: '',
        continent_code: '',
        continent_name: '',
        region_code: '',
        region_name: '',
        city: pickCity(),
        zip: '',
        latitude: 0,
        longitude: 0,
        location: {
          geoname_id: 0,
          capital: '',
          languages: [],
          country_flag: '',
          country_flag_emoji: '',
          country_flag_emoji_unicode: '',
          calling_code: '',
          is_eu: true,
        },
      },
      userAgent: {
        string: pickDevice(),
        browser: {
          name: pickBrowser(),
          version: '',
        },
      },
    };
  }

  private createVisitInSession(unitId: string, session: ShowcaseSession): ShowcaseSession {
    return {
      ...session,
      time: [...session.time, { id: unitId, time: faker.random.number(10000) }],
      path: [...session.path, { id: unitId, type: 'unit', timestamp: session.createdAt }],
    };
  }

  private getWeights(initialWeights: Record<string, number>): Record<string, number> {
    const { typologies } = this.units;

    const initialWeightsSum = Object.values(initialWeights).reduce((m, v) => m + v, 0);
    const restWeightsSum = 1 - initialWeightsSum;

    const noWeightTypologies = typologies.filter((t) => !Object.keys(initialWeights).includes(t));
    const distributedWeights = distribute(noWeightTypologies, restWeightsSum);

    const restWeights = noWeightTypologies.reduce(
      (memo, t, i) => ({ ...memo, [t]: distributedWeights[i] }),
      {} as Record<string, number>,
    );

    return typologies.reduce((memo, typology) => {
      const weight = initialWeights[typology] ?? restWeights[typology];
      return { ...memo, [typology]: weight };
    }, {});
  }

  private pickUnitIdWithWeight(weights: Record<string, number>): string {
    const chosenTypology = weightedPick(this.units.typologies, weights);
    const typologyUnits = this.units.units.filter((u) => u.typology === chosenTypology);
    return faker.random.arrayElement(typologyUnits).id;
  }
}
