import {
  Checkbox,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { LeadsFilterTypes, UnitFeature } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm');

interface PerksFilterProps {
  filter: LeadsFilterTypes['perks'];
}

export const PerksFilter = ({ filter }: PerksFilterProps) => {
  const toggleSelection = (value: UnitFeature) => {
    if (filter.value == null) {
      filter.set([value]);
    } else if (Array.isArray(filter.value)) {
      if (filter.value.includes(value)) {
        filter.set(filter.value.filter((userId) => userId !== value));
      } else {
        filter.set([...filter.value, value]);
      }
    } else {
      if (value === filter.value) {
        filter.set(null);
      } else {
        filter.set([filter.value, value]);
      }
    }
  };

  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
      {Object.values(UnitFeature).map((value) => (
        <FlexItem key={value}>
          <Margin size={{ vertical: Size.EXTRA_SMALL }}>
            <Checkbox
              onChange={() => toggleSelection(value)}
              value={
                Array.isArray(filter.value)
                  ? filter.value?.includes(value)
                  : String(filter.value) === value
              }>
              {tt(`unit_feature.${value.toLowerCase()}`)}
            </Checkbox>
          </Margin>
        </FlexItem>
      ))}
    </Flex>
  );
};
