import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  InputGroup,
  LoadingPlaceholder,
  Margin,
  MultiSelect,
  Select,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import startCase from 'lodash/startCase';
import React, { Fragment, useState } from 'react';

import { Lead, PurchaseGoal, UnitFeature } from '~/pods/crm/types';
import {
  getProjectPriceOptions,
  getProjectSurfaceOptions,
  projectPriceRange,
  projectSurfaceRange,
  unitTypeOptions as unitTypes,
  useUpdateLead,
} from '~/pods/crm/utils';
import { Currency } from '~/types';
import { run } from '~/utils';
import { formatBudget, formatSurface } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { InfoRow } from './InfoRow';
import { Section } from './Section';
import { TextWithFallback } from './TextWithFallback';

const te = createTranslate('pods.crm');
const tt = createTranslate('pods.crm.routes.lead.components.lead_details.interests');

export const InterestedInPlaceHolder = () => {
  return (
    <Section label={tt('title')} onClickEdit={() => {}}>
      <Fragment>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="45%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="50%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="100%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="80%" />
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <LoadingPlaceholder width="60%" />
        </Margin>
        <LoadingPlaceholder width="30%" />
      </Fragment>
    </Section>
  );
};

interface InterestedInForm {
  unitFeatures: Array<UnitFeature>;
  purchaseGoal: PurchaseGoal;
  minimumBudget: number;
  maximumBudget: number;
  minimumSurface: number;
  maximumSurface: number;
  typologies: Array<number>;
}

interface InterestedInProps {
  lead: Lead;
  currency: Currency;
}

export const InterestedIn = ({ lead, currency }: InterestedInProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading, updateLead } = useUpdateLead();

  const interestedInForm = useForm<InterestedInForm>({
    unitFeatures: lead.unitFeatures ?? [],
    purchaseGoal: lead.purchaseGoal ?? undefined,
    minimumBudget: lead.minimumBudget ?? undefined,
    maximumBudget: lead.maximumBudget ?? undefined,
    minimumSurface: lead.minimumSurface ?? undefined,
    maximumSurface: lead.maximumSurface ?? undefined,
    typologies: lead.typologies ?? [],
  });

  const surfaceUnit = lead?.surfaceUnit;
  const { shortLabels: unitTypeOptions } = unitTypes;
  const {
    typologies,
    minimumBudget,
    maximumBudget,
    minimumSurface,
    maximumSurface,
    unitFeatures,
    purchaseGoal,
  } = interestedInForm.values;

  const handleSaveLeadInfo = async () => {
    await updateLead({
      id: lead.id,
      minimumBudget: Number(minimumBudget),
      maximumBudget: Number(maximumBudget),
      minimumSurface: Number(minimumSurface),
      maximumSurface: Number(maximumSurface),
      unitFeatures,
      purchaseGoal,
      typologies,
    });
    setIsEditing(false);
  };

  return (
    <Section label={tt('title')} onClickEdit={() => setIsEditing(true)}>
      <Fragment>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="home">
            {run(() => {
              if (isEditing) {
                return (
                  <Select
                    name="purchaseGoal"
                    size={Size.SMALL}
                    onChange={interestedInForm.set}
                    value={interestedInForm.get}
                    options={Object.values(PurchaseGoal).map((o) => ({
                      value: o,
                      label: te(`purchase_goal.${o.toLowerCase()}`),
                    }))}
                  />
                );
              } else {
                return (
                  <TextWithFallback
                    value={
                      lead.purchaseGoal != null
                        ? te(`purchase_goal.${lead.purchaseGoal?.toLowerCase()}`)
                        : undefined
                    }
                    fallback={tt('fallback.goal')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="bed">
            {run(() => {
              if (isEditing) {
                return (
                  <MultiSelect
                    name="typologies"
                    onChange={interestedInForm.set}
                    values={interestedInForm.get}
                    options={Object.keys(unitTypeOptions).map((unitType) => ({
                      label: te(`typologies.${unitTypeOptions[unitType]}`),
                      value: Number(unitType),
                    }))}
                  />
                );
              } else {
                return (
                  <TextWithFallback
                    value={
                      lead.typologies != null && lead.typologies.length !== 0
                        ? lead.typologies
                            .map((t: number) => te(`typologies.${unitTypeOptions[t]}`))
                            .join(', ')
                        : undefined
                    }
                    fallback={tt('fallback.typology')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="money-bag">
            {run(() => {
              if (isEditing) {
                return (
                  <InputGroup horizontal>
                    <Select
                      name="minimumBudget"
                      onChange={(minBudget) => {
                        interestedInForm.set(minBudget, 'minimumBudget');
                        if (maximumBudget == null) {
                          interestedInForm.set(projectPriceRange[1], 'maximumBudget');
                        }
                      }}
                      value={interestedInForm.get}
                      placeholder="min"
                      options={
                        getProjectPriceOptions({
                          currency,
                          topLimit: maximumBudget ?? projectPriceRange[1],
                        }) ?? []
                      }
                    />
                    <Select
                      name="maximumBudget"
                      onChange={(maxBudget) => {
                        interestedInForm.set(maxBudget, 'maximumBudget');
                        if (minimumBudget == null) {
                          interestedInForm.set(projectPriceRange[0], 'minimumBudget');
                        }
                      }}
                      value={interestedInForm.get}
                      placeholder="max"
                      options={
                        getProjectPriceOptions({
                          currency,
                          bottomLimit: minimumBudget ?? projectPriceRange[0],
                        }) ?? []
                      }
                    />
                  </InputGroup>
                );
              } else {
                return (
                  <TextWithFallback
                    value={formatBudget({
                      max: lead.maximumBudget,
                      min: lead.minimumBudget,
                      currency,
                    })}
                    fallback={tt('fallback.budget')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="surface">
            {run(() => {
              if (isEditing) {
                return (
                  <InputGroup horizontal>
                    <Select
                      name="minimumSurface"
                      onChange={(minSurface) => {
                        interestedInForm.set(minSurface, 'minimumSurface');
                        if (maximumSurface == null) {
                          interestedInForm.set(projectSurfaceRange[1], 'maximumSurface');
                        }
                      }}
                      value={interestedInForm.get}
                      placeholder="min"
                      options={
                        getProjectSurfaceOptions({
                          surfaceUnit,
                          topLimit: maximumSurface ?? projectSurfaceRange[1],
                        }) ?? []
                      }
                    />
                    <Select
                      name="maximumSurface"
                      onChange={(maxSurface) => {
                        interestedInForm.set(maxSurface, 'maximumSurface');
                        if (minimumSurface == null) {
                          interestedInForm.set(projectSurfaceRange[0], 'minimumSurface');
                        }
                      }}
                      value={interestedInForm.get}
                      placeholder="max"
                      options={
                        getProjectSurfaceOptions({
                          surfaceUnit,
                          bottomLimit: minimumSurface ?? projectSurfaceRange[0],
                        }) ?? []
                      }
                    />
                  </InputGroup>
                );
              } else {
                return (
                  <TextWithFallback
                    value={formatSurface({
                      max: lead.maximumSurface,
                      min: lead.minimumSurface,
                      unit: lead.surfaceUnit ?? 'sqm',
                    })}
                    fallback={tt('fallback.surface')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
        <Margin size={{ bottom: Size.SMALL }}>
          <InfoRow icon="beach-umbrella">
            {run(() => {
              if (isEditing) {
                return (
                  <MultiSelect
                    name="unitFeatures"
                    values={interestedInForm.get}
                    onChange={interestedInForm.set}
                    options={Object.values(UnitFeature).map((o) => ({
                      value: o,
                      label: te(`unit_feature.${o.toLowerCase()}`),
                    }))}
                  />
                );
              } else {
                return (
                  <TextWithFallback
                    value={
                      lead.unitFeatures != null && lead.unitFeatures.length > 0
                        ? lead.unitFeatures.map((f) => startCase(f.toLowerCase())).join(', ')
                        : undefined
                    }
                    fallback={tt('fallback.feature')}
                  />
                );
              }
            })}
          </InfoRow>
        </Margin>
      </Fragment>
      {isEditing ? (
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Margin size={{ right: Size.EXTRA_SMALL }}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  interestedInForm.reset();
                }}
                size={Size.SMALL}
                tier={Tier.TERTIARY}>
                {tt('cancel_button')}
              </Button>
            </Margin>
          </FlexItem>
          <FlexItem>
            <Button
              trailing={isLoading ? <Spinner size={Size.SMALL} inversed /> : null}
              disabled={isLoading}
              onClick={handleSaveLeadInfo}
              size={Size.SMALL}
              category={Category.INFO}>
              {tt('save_button')}
            </Button>
          </FlexItem>
        </Flex>
      ) : null}
    </Section>
  );
};
