import { Currency, CurrencyCode } from '~/types';

export const currencyToText: Record<Currency, string> = {
  EUR: 'eur',
  GBP: 'gbp',
  USD: 'usd',
};

export const currencyToSymbol: Record<Currency, string> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export function getCurrencyDetails(code: Currency = CurrencyCode.EUR) {
  const symbol = currencyToSymbol[code];
  const text = currencyToText[code];

  return {
    code,
    symbol,
    text,
  };
}
