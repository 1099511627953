import {
  Button,
  Category,
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Label,
  LoadingPlaceholder,
  Margin,
  MultiSelect,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { Fragment, useState } from 'react';

import { UnitInterestSelection } from '~/pods/crm/components';
import { InterestType, Lead, ProjectInterest } from '~/pods/crm/types';
import { useFetchOrganisation, useUpdateLead } from '~/pods/crm/utils';
import { createTranslate } from '~/utils';

import { Section } from './Section';

const tt = createTranslate('pods.crm.routes.lead.components.lead_details.project');

interface ProjectsForm {
  interests: Array<InterestType>;
}

interface ProjectInterestsProps {
  projectInterest: ProjectInterest;
}

const ProjectInterestView = ({ projectInterest }: ProjectInterestsProps) => {
  const { unitInterests } = projectInterest;

  return (
    <Flex justify={FlexJustify.START} align={FlexAlign.START}>
      <FlexItem>
        <Icon name="heart" color={Color.PRIMARY} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem>
        <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
          <Text>{projectInterest.project.name}</Text>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <Text size={Size.SMALL}>
            {unitInterests.map((interest) => interest.unitReference).join(', ')}
          </Text>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

export const ProjectsPlaceholder = () => {
  return (
    <Section label={tt('title')} onClickEdit={() => {}}>
      <Margin size={{ bottom: Size.SMALL }}>
        <LoadingPlaceholder width="50%" />
      </Margin>
      <LoadingPlaceholder width="80%" />
    </Section>
  );
};

interface ProjectsProps {
  lead: Lead;
  onClickUpdate: VoidFunction;
}

export const Projects = ({ lead, onClickUpdate }: ProjectsProps) => {
  const { organisation } = useFetchOrganisation();
  const projects = organisation?.projects;

  const [isEditing, setIsEditing] = useState(false);

  const interestsForm = useForm<ProjectsForm>({
    interests:
      lead.interestedIn?.map((projectInterest) => ({
        projectId: projectInterest.project.id,
        unitInterests: projectInterest.unitInterests.map((unitInterest) => ({
          unitId: unitInterest.unitId,
          unitReference: unitInterest.unitReference ?? tt('unit_from_revo'),
        })),
      })) ?? [],
  });

  const { isLoading: isUpdating, updateLead } = useUpdateLead();

  const handleCancelLeadInfo = () => {
    setIsEditing(false);
    interestsForm.reset();
  };

  const handleSaveLeadInfo = async () => {
    await updateLead({ id: lead.id, ...interestsForm.values });
    setIsEditing(false);
    onClickUpdate();
  };

  return (
    <Fragment>
      <Section
        label={tt('title')}
        onClickEdit={() => {
          setIsEditing(true);
        }}>
        <Margin size={{ bottom: Size.SMALL }}>
          {(() => {
            if (!projects || !lead || !lead.interestedIn) {
              return null;
            }

            if (isEditing) {
              return (
                <Fragment>
                  <Flex justify={FlexJustify.START}>
                    <FlexItem>
                      <Icon name="heart" color={Color.PRIMARY} />
                    </FlexItem>
                    <FlexSpacer size={Size.EXTRA_SMALL} />
                    <FlexItem flex={1}>
                      <MultiSelect
                        name="interests"
                        options={projects.map((p) => ({
                          label: p.name,
                          value: p.id,
                        }))}
                        values={
                          interestsForm.values.interests
                            ?.filter((interest) => !interest.destroy)
                            .map((i) => i.projectId) ?? []
                        }
                        onChange={(projectIds) => {
                          const previousInterests =
                            interestsForm.values.interests?.map((previousInterest) => ({
                              ...previousInterest,
                              /*
                                Some projects might've been added again, so better mark them all as non-deleted
                                and filter below the ones who have been deleted now
                              */
                              destroy: undefined,
                            })) ?? [];

                          let changingInterests: InterestType[] = previousInterests;

                          /*
                            Add projects that weren't added yet
                          */
                          changingInterests = [
                            ...previousInterests,
                            ...projectIds
                              .filter(
                                (projectId) =>
                                  !changingInterests.find((i) => i.projectId === projectId),
                              )
                              .map((projectId) => {
                                return {
                                  projectId,
                                  unitInterests: [],
                                };
                              }),
                          ];

                          /*
                            Mark interests that are now NOT included in the new list of interests
                            as interests to be removed/destroyed

                            If an interest was added and removed without commiting those changes in the backend
                            just remove it entirely from the interests form, or else it will return 'unit_assignation_issue'
                          */
                          changingInterests = changingInterests
                            .map((interest) => {
                              const isInProjectsIds = projectIds.find(
                                (projectId) => projectId === interest.projectId,
                              );

                              if (isInProjectsIds) {
                                return interest;
                              } else {
                                const comesFromServer = Boolean(
                                  lead.interestedIn?.find(
                                    (interestFromServer) =>
                                      interestFromServer.project.id === interest.projectId,
                                  ),
                                );

                                if (comesFromServer) {
                                  return {
                                    ...interest,
                                    destroy: true,
                                  };
                                } else {
                                  return undefined;
                                }
                              }
                            })
                            .filter((i): i is InterestType => Boolean(i));

                          interestsForm.set(changingInterests, 'interests');
                        }}
                      />
                    </FlexItem>
                  </Flex>
                  {(interestsForm.values.interests ?? []).map((interest) => {
                    const project = projects.find((p) => p.id === interest.projectId);

                    if (!project || interest.destroy) {
                      return null;
                    }

                    return (
                      <Margin key={interest.projectId} size={{ top: Size.EXTRA_SMALL }}>
                        <FormGroup
                          label={<Label>{project.name}</Label>}
                          input={
                            <UnitInterestSelection
                              onChange={(unitInterests) => {
                                if (interestsForm.values.interests) {
                                  interestsForm.set(
                                    interestsForm.values.interests.map((projectInInterests) => {
                                      if (projectInInterests.projectId === project.id) {
                                        return {
                                          projectId: project.id,
                                          unitInterests,
                                        };
                                      } else {
                                        return projectInInterests;
                                      }
                                    }),
                                    'interests',
                                  );
                                } else {
                                  throw new Error();
                                }
                              }}
                              project={project}
                              values={interest.unitInterests}
                              customHeader={true}
                            />
                          }
                        />
                      </Margin>
                    );
                  })}
                  <Margin size={{ top: Size.EXTRA_SMALL }}>
                    <Flex justify={FlexJustify.END}>
                      <FlexItem>
                        <Margin size={{ right: Size.EXTRA_SMALL }}>
                          <Button
                            onClick={handleCancelLeadInfo}
                            size={Size.SMALL}
                            tier={Tier.TERTIARY}>
                            {tt('cancel_button')}
                          </Button>
                        </Margin>
                      </FlexItem>
                      <FlexItem>
                        <Button
                          onClick={handleSaveLeadInfo}
                          disabled={isUpdating || projects == null || projects.length === 0}
                          trailing={isUpdating ? <Spinner size={Size.SMALL} inversed /> : null}
                          size={Size.SMALL}
                          category={Category.INFO}>
                          {tt('save_button')}
                        </Button>
                      </FlexItem>
                    </Flex>
                  </Margin>
                </Fragment>
              );
            }

            if (lead.interestedIn.length === 0) {
              return (
                <Flex justify={FlexJustify.START}>
                  <FlexItem>
                    <Icon name="heart" color={Color.PRIMARY} />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>
                    <Text shade={Shade.MEDIUM}>{tt('no_projects')}</Text>
                  </FlexItem>
                </Flex>
              );
            } else {
              return lead.interestedIn.map((interest) => {
                return (
                  <Margin key={interest.project.id} size={{ bottom: Size.SMALL }}>
                    <ProjectInterestView projectInterest={interest} />
                  </Margin>
                );
              });
            }
          })()}
        </Margin>
      </Section>
    </Fragment>
  );
};
