import { Flex, FlexItem, FlexJustify, Margin, Separator, Size } from '@drawbotics/react-drylus';
import React, { Fragment, ReactNode } from 'react';

interface RowProps {
  left: ReactNode;
  right: ReactNode;
  showSeparator: boolean;
}

export const Row = ({ left, right, showSeparator }: RowProps) => {
  return (
    <Fragment>
      <Margin size={{ top: Size.EXTRA_SMALL, bottom: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.SPACE_BETWEEN}>
          <FlexItem>{left}</FlexItem>
          <FlexItem>{right}</FlexItem>
        </Flex>
      </Margin>
      {showSeparator && <Separator />}
    </Fragment>
  );
};
