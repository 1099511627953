import {
  Button,
  Category,
  Content,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Margin,
  Padding,
  Shade,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import keys from '~/pods/meetings/images/keys.png';
import { createTranslate, useIntercom } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.intro');

export const Intro = () => {
  const { showIntercom } = useIntercom();
  return (
    <Content fullHeight>
      <Padding style={{ height: '100%' }} size={Size.EXTRA_LARGE}>
        <Margin size={Size.MASSIVE} />
        <Flex direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <img width={300} src={keys} />
          </FlexItem>
          <FlexItem>
            <Title size={2}>{tt('digital_meetings')}</Title>
          </FlexItem>
          <FlexItem>
            <Text
              style={{ textAlign: 'center', maxWidth: 600, display: 'block' }}
              shade={Shade.LIGHT}>
              {tt('get_in_touch')}
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.LARGE} />
          <FlexItem>
            <Button size={Size.LARGE} category={Category.INFO} onClick={showIntercom}>
              {tt('contact_us')}
            </Button>
          </FlexItem>
        </Flex>
      </Padding>
    </Content>
  );
};
