import sv from '@drawbotics/drylus-style-vars';
import { Button, ButtonLink, Color, EmptyState, Flex, FlexAlign, FlexItem, FlexJustify, Icon, Padding, Size, Table, Tier } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Project } from '~/pods/dashboard/types';
import { useFetchFavoredProjects } from '~/pods/dashboard/utils/hooks/use-fetch-favored-projects';
import { useMutateFavoredProjects } from '~/pods/dashboard/utils/hooks/use-mutate-favored-projects';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  getTitleFromErrorType,
  run,
  useMosaicNavigation,
} from '~/utils';

const ttPanel = createTranslate('pods.dashboard.routes.dashboard.components.projects_panel');
const tt = createTranslate('pods.dashboard.routes.dashboard.components.projects_table');

interface ProjectsTableProps {
  projects?: Array<Project>;
  isLoading: boolean;
  isSearching: boolean;
  onNewProject: () => void
}

const styles = {
  root: css`
    padding-top: ${sv.paddingSmall};
    padding-bottom: ${sv.paddingSmall};
  `,
  pointer: css`
    cursor: pointer;
  `
};

export const ProjectsTable = ({ projects, isLoading, isSearching, onNewProject }: ProjectsTableProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  const [favoredProjects, setFavoredProjects] = useState<string[]>([]);

  const {
    data: favoredProjectsFromFirstCall
  } = useFetchFavoredProjects();

  const {
    favorProject,
    unfavorProject
  } = useMutateFavoredProjects()

  useEffect(() => {
    if (favoredProjectsFromFirstCall) {
      setFavoredProjects(favoredProjectsFromFirstCall.user.favoredProjects)
    }
  }, [favoredProjectsFromFirstCall])

  const onProjectStarToggle = (projectPublicToken: string) => {
    if (favoredProjects.includes(projectPublicToken)) {
      setFavoredProjects(

        favoredProjects.filter((v) => v !== projectPublicToken),
      )

      unfavorProject({ projectPublicToken: projectPublicToken })
    } else {
      setFavoredProjects(
        [
          ...favoredProjects,
          projectPublicToken
        ]
      )

      favorProject({ projectPublicToken: projectPublicToken })
    }

  }

  const data = projects?.map((project) => ({
    id: project.id,
    name: (
      <Flex align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem>
          <Padding size={{ vertical: Size.SMALL, left: Size.EXTRA_SMALL, right: Size.SMALL }}>
            <Icon
              className={styles.pointer}
              name={favoredProjects.includes(project.publicToken) ? 'star-filled' : 'star'}
              onClick={() => {
                onProjectStarToggle(project.publicToken)
              }}
            />
          </Padding>
        </FlexItem>
        <FlexItem>
          {project.name}
        </FlexItem>
      </Flex>
    ),
    actions: (
      <Link to={getUrlInOrg('/project', project.publicToken)}>
        <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY}>
          {tt('see_project')}
        </ButtonLink>
      </Link>
    ),
  }));

  const noProjects = projects != null && projects.length === 0;

  return (
    <Table
      className={styles.root}
      size={Size.SMALL}
      emptyContent={run(() => {
        if (noProjects && isSearching) {
          return <EmptyState title={tt('no_results')} description={tt('no_results_detail')} />;
        } else if (noProjects) {
          return <Flex justify={FlexJustify.CENTER}>
            <FlexItem>
              <Padding size={Size.SMALL}>
                <Button
                  onClick={onNewProject}
                  color={Color.BLUE}
                  leading={<Icon name="plus" />}>
                  {ttPanel('new_project')}
                </Button>
              </Padding>
            </FlexItem>
          </Flex>
        } else if (projects == null && !isLoading) {
          return (
            <EmptyState
              title={getTitleFromErrorType()}
              description={getMessageFromErrorType()}
              variation={getEmptyStateVariationFromErrorType()}
            />
          );
        }
      })}
      isLoading={!isSearching && isLoading}
      data={data ?? []}
      header={[
        { label: tt('project'), value: 'name' },
        {
          label: tt('actions'),
          value: 'actions',
        },
      ]}
    />
  );
};
