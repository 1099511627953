import {
  Align,
  DateInput,
  Flex,
  FlexItem,
  FormGroup,
  Label,
  Margin,
  Select,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { run } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { UnitAvailability } from '../../../types';

const tt = createTranslate('pods.listing_manager.components.availability_input');

interface DateInputType {
  year: number;
  month: number;
  day: number;
}

function _dateToObject(date: string): DateInputType {
  return {
    year: new Date(date).getFullYear(),
    month: new Date(date).getMonth() + 1,
    day: new Date(date).getDate(),
  };
}

function _objectToDate(date: DateInputType): string {
  return new Date(date.year, date.month - 1, date.day).toISOString();
}

interface AvailabilityInputProps {
  availability: UnitAvailability;
  onChangeAvailability: (value: UnitAvailability) => void;
  soldAt?: string;
  onChangeSoldAt: (value: string) => void;
}

export const AvailabilityInput = ({
  availability,
  onChangeAvailability,
  soldAt,
  onChangeSoldAt,
}: AvailabilityInputProps) => {
  return (
    <Flex>
      <FlexItem flex>
        <Margin
          size={{ right: Size.EXTRA_SMALL }}
          responsive={{ XL: { size: { right: Size.SMALL } } }}>
          <FormGroup
            label={<Label>{tt('availability')}</Label>}
            input={
              <Select
                value={availability}
                onChange={onChangeAvailability}
                options={[
                  { label: tt('available'), value: UnitAvailability.AVAILABLE },
                  { label: tt('option'), value: UnitAvailability.OPTION },
                  { label: tt('sold'), value: UnitAvailability.BOOKED },
                  {
                    label: tt('unavailable'),
                    value: UnitAvailability.UNAVAILABLE,
                    disabled: availability !== UnitAvailability.UNAVAILABLE,
                  },
                ]}
              />
            }
          />
        </Margin>
      </FlexItem>
      {run(() => {
        if (availability === 'booked') {
          return (
            <FlexItem flex>
              <FormGroup
                label={<Label>{tt('sold_on')}</Label>}
                input={
                  <DateInput
                    placeholder={tt('date_of_sale')}
                    align={Align.RIGHT}
                    displayOptions={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
                    onChange={(v: DateInputType) => onChangeSoldAt(_objectToDate(v))}
                    value={soldAt == null ? '' : _dateToObject(soldAt)}
                  />
                }
              />
            </FlexItem>
          );
        }
      })}
    </Flex>
  );
};
