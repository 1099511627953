import { Button, Flex, FlexItem, FlexSpacer, Size } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { css, cx } from 'emotion';
import { get } from 'lodash';
import React from 'react';

import { Website } from '~/pods/website-builder/api/domain';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { SectionIdentifier, Theme, ThemeDefinition } from '../../../types';

const styles = {
  item: (theme: ThemeDefinition) => css`
    text-transform: uppercase;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.fonts.small};
    background: ${theme.colors.neutral4};
    padding: 0;
    padding-bottom: 2px;
    color: ${theme.colors.neutral1};
    border-radius: 0;
    font-weight: 600;
    letter-spacing: 1.5px;

    &:hover {
      background: ${theme.colors.neutral4};
      box-shadow: 0px 3px 0px -1px ${theme.colors.neutral1};
    }
  `,
  luxurious: (theme: ThemeDefinition) => css`
    font-weight: 400;

    &:hover {
      box-shadow: 0px 3px 0px -1px ${theme.colors.secondary};
    }
  `,
  minimalist: (theme: ThemeDefinition) => css`
    text-transform: none;
    background: transparent;
    color: ${theme.colors.neutral2};

    &:hover {
      background: transparent;
      font-weight: 800;
      box-shadow: none;
    }
  `,
};

interface NavigationProps {
  language: LocaleCode;
  sections: Website['sections'];
}

export const Navigation = ({ language, sections }: NavigationProps) => {
  const theme = useTheme<ThemeDefinition>();
  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const galleryActive = get(
    sections.find((section) => section.identifier === SectionIdentifier.GALLERY),
    'active',
    false,
  );

  const mainActive = get(
    sections.find((section) => section.identifier === SectionIdentifier.MAIN),
    'active',
    false,
  );

  return (
    <Flex>
      {galleryActive ? (
        <FlexItem>
          <Button
            className={cx(
              styles.item(theme),
              { [styles.luxurious(theme)]: theme.id === Theme.LUXURIOUS },
              { [styles.minimalist(theme)]: theme.id === Theme.MINIMALIST },
            )}>
            {tl('gallery')}
          </Button>
        </FlexItem>
      ) : null}
      <FlexSpacer size={Size.LARGE} />
      {mainActive ? (
        <FlexItem>
          <Button
            className={cx(
              styles.item(theme),
              { [styles.luxurious(theme)]: theme.id === Theme.LUXURIOUS },
              { [styles.minimalist(theme)]: theme.id === Theme.MINIMALIST },
            )}>
            {tl('project')}
          </Button>
        </FlexItem>
      ) : null}
      <FlexSpacer size={Size.LARGE} />
      <FlexItem>
        <Button
          className={cx(
            styles.item(theme),
            { [styles.luxurious(theme)]: theme.id === Theme.LUXURIOUS },
            { [styles.minimalist(theme)]: theme.id === Theme.MINIMALIST },
          )}>
          {tl('location')}
        </Button>
      </FlexItem>
      <FlexSpacer size={Size.LARGE} />
      <FlexItem>
        <Button
          className={cx(
            styles.item(theme),
            { [styles.luxurious(theme)]: theme.id === Theme.LUXURIOUS },
            { [styles.minimalist(theme)]: theme.id === Theme.MINIMALIST },
          )}>
          {tl('contact')}
        </Button>
      </FlexItem>
      <FlexSpacer size={Size.LARGE} />
    </Flex>
  );
};
