import { Filter } from '@drawbotics/use-filters';

export enum SortBy {
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type FilterOptions = Record<string, { label: string }>;

export interface LeadsFilterTypes {
  projects: Filter;
  organisations: Filter;
  assignedTo: Filter;
  status: Filter;
  unitTypes: Filter;
  perks: Filter;
  purchaseGoal: Filter;
  sources: Filter;
  budget: Filter;
  surface: Filter;
  marketingCampaign: Filter;
  engagementLevel: Filter;
  portalSlugs: Filter;
  page: Filter;
  sortBy: Filter;
  searchTerm: Filter;
}
