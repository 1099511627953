import {
  Button,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Panel,
  PanelBody,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import chart from '~/pods/insights/images/chart.svg';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.deals');

interface InsufficientDataPanelProps {
  openModal: VoidFunction;
}

export const InsufficientDataPanel = ({ openModal }: InsufficientDataPanelProps) => {
  return (
    <Panel
      style={{ height: '70vh' }}
      body={
        <PanelBody style={{ height: '100%' }}>
          <Flex
            style={{ height: '100%' }}
            justify={FlexJustify.CENTER}
            direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <img src={chart} />
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            <FlexItem style={{ textAlign: 'center' }}>
              <Text>
                {tt('insufficient_data_1')}
                <br />
                {tt('insufficient_data_2')}
              </Text>
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            <FlexItem>
              <Title size={3}>{tt('stay_on_track')}</Title>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Button color={Color.BLUE} leading={<Icon name="settings" />} onClick={openModal}>
                {tt('goals_settings')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};
