import { Button, Color, Flex, FlexItem, FlexJustify } from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.campaigns');

interface HeaderProps {
  onClickCreateCampaign?: VoidFunction;
}

export const Header = ({ onClickCreateCampaign }: HeaderProps) => {
  return (
    <Flex justify={FlexJustify.SPACE_BETWEEN}>
      <FlexItem>
        <MosaicPageTitle>{tt('title')}</MosaicPageTitle>
      </FlexItem>
      <FlexItem>
        {onClickCreateCampaign != null ? (
          <Button onClick={onClickCreateCampaign} color={Color.BLUE}>
            {tt('create_campaign')}
          </Button>
        ) : null}
      </FlexItem>
    </Flex>
  );
};
