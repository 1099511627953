import {
  Button,
  Category,
  Content,
  EmptyState,
  Padding,
  Panel,
  PanelBody,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import reportsPlaceholder from '~/pods/insights/images/placeholders/reports.svg';
import { Subscription } from '~/types';
import { useAuth, useIntercom, useMosaicNavigation, useTriggerNotFound } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { useLoadReports } from '../../utils/hooks/use-load-reports';
import { ReportsTable } from './components';

const tt = createTranslate('pods.insights.routes.deals');

const styles = {
  reports: css``,
};

interface ReportsIndexProps {}

export const ReportsIndex = ({}: ReportsIndexProps) => {
  const triggerNotFound = useTriggerNotFound();
  const { projectToken } = useMosaicNavigation();
  const { user } = useAuth();
  const { showIntercom } = useIntercom();

  if (projectToken == null) {
    triggerNotFound();
    return null;
  }

  const { isLoading, reports } = useLoadReports(projectToken);

  const sortedReports = reports.sort((a, b) =>
    a.dateRange.start.isAfter(b.dateRange.start) ? -1 : 1,
  );

  const isNotAllowed =
    user?.organisation?.subscription !== Subscription.GROWTH &&
    user?.organisation?.subscription !== Subscription.REVO;

  return (
    <div className={styles.reports}>
      <Content fullHeight>
        <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
          <MosaicPageTitle>{tt('reports_index')}</MosaicPageTitle>
          {isNotAllowed ? (
            <Panel
              body={
                <PanelBody>
                  <Padding size={{ vertical: Size.HUGE }}>
                    <EmptyState
                      image={reportsPlaceholder}
                      title={tt('reports_will_show')}
                      description={tt('upgrade_to_growth')}>
                      <Button category={Category.INFO} onClick={showIntercom}>
                        {tt('contact_us')}
                      </Button>
                    </EmptyState>
                  </Padding>
                </PanelBody>
              }
            />
          ) : (
            <ReportsTable isLoading={isLoading} reports={sortedReports} />
          )}
        </Padding>
      </Content>
    </div>
  );
};
