import { ID } from '~/types';

import { DiffusionStatus } from '../types';
import { Portal, PortalData } from './Portal';

export interface DiffusionData {
  id: ID;
  portal: PortalData;
  startDiffusionDate: string;
  endDiffusionDate: string;
  status: DiffusionStatus;
}

export class Diffusion {
  public static fromData(data: DiffusionData): Diffusion {
    return new Diffusion(
      data.id,
      Portal.fromData(data.portal),
      data.startDiffusionDate,
      data.endDiffusionDate,
      data.status,
    );
  }

  constructor(
    public readonly id: ID,
    public readonly portal: Portal,
    public readonly startingDate: string,
    public readonly endingDate: string,
    public readonly status: DiffusionStatus,
  ) {}

  public toData(): DiffusionData {
    return {
      id: this.id,
      portal: this.portal.toData(),
      startDiffusionDate: this.startingDate,
      endDiffusionDate: this.endingDate,
      status: this.status,
    };
  }
}
