import gql from 'fraql';

import { ErrorType, ID } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface ProjectQueryData {
  project: {
    id: ID;
  };
}

const ProjectQuery = gql`
  query FiscalSimulatorProjectQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
    }
  }
`;

export function useFetchProject(projectSlug: string): {
  isLoading: boolean;
  project?: { id: ID };
  error?: ErrorType;
} {
  const res = useMosaicQuery<ProjectQueryData, { projectSlug: string }>({
    query: ProjectQuery,
    variables: { projectSlug },
  });

  return { isLoading: res.isLoading, project: res.data?.project, error: buildError(res.error) };
}
