import sv from '@drawbotics/drylus-style-vars';
import { Dayjs } from 'dayjs';
import groupBy from 'lodash/groupBy';

import { InsightsEstate, InsightsUnit } from '../../types';

interface Breakdown {
  sold: number;
  option: number;
  available: number;
}

interface PieSection {
  id: string;
  label: string;
  value: number;
  color: string;
}

type PieData = Array<PieSection>;

function _mergeExtraTypologies(breakdown: Record<string, Breakdown>): Record<string, Breakdown> {
  const keys = Object.keys(breakdown).sort();
  if (keys.length < 5) {
    return breakdown;
  }

  const keysToCompress = keys.slice(4);

  const compressed = keysToCompress.reduce(
    (memo, key) => {
      const typology = breakdown[key];
      return {
        sold: memo['sold'] + typology.sold,
        option: memo['option'] + typology.option,
        available: memo['available'] + typology.available,
      };
    },
    { sold: 0, option: 0, available: 0 },
  );

  let final: Record<string, Breakdown> = keys.slice(0, 4).reduce((memo, key) => {
    return {
      ...memo,
      [key]: breakdown[key],
    };
  }, {});

  final['other'] = compressed;

  return final;
}

export function useLoadStatusBreakdownByTypology(units: InsightsEstate['units'], until: Dayjs) {
  const unitsAdjusted = units.map((u) => {
    if (u.soldAt == null || u.soldAt.isSameOrBefore(until)) return u;
    else return { ...u, soldAt: null, availability: 'available' } as InsightsUnit;
  });
  const unitsByTypology = groupBy(unitsAdjusted, 'typology');

  const breakdownByTypology = Object.keys(unitsByTypology).reduce((memo, key: string) => {
    const unitsInTypology = unitsByTypology[key];
    return {
      ...memo,
      [key]: {
        sold: unitsInTypology.filter((u) => u.availability === 'booked').length,
        option: unitsInTypology.filter((u) => u.availability === 'option').length,
        available: unitsInTypology.filter((u) => u.availability === 'available').length,
      },
    };
  }, {});

  const breakdownByTypologyCompressed = _mergeExtraTypologies(breakdownByTypology);
  const breakdownForChart: Record<string, PieData> = Object.keys(
    breakdownByTypologyCompressed,
  ).reduce(
    (memo, key) => ({
      ...memo,
      [key]: [
        {
          id: 'sold',
          label: 'sold',
          value: breakdownByTypologyCompressed[key].sold,
          color: sv.green,
        },
        {
          id: 'option',
          label: 'option',
          value: breakdownByTypologyCompressed[key].option,
          color: sv.blue,
        },
        {
          id: 'left',
          label: 'left',
          value: breakdownByTypologyCompressed[key].available,
          color: sv.grey300,
        },
      ],
    }),
    {},
  );

  return breakdownForChart;
}
