import {
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  Grid,
  GridItem,
  Margin,
  Panel,
  PanelBody,
  SegmentedControl,
  SegmentedControlItem,
  Size,
} from '@drawbotics/react-drylus';
import dayjs, { Dayjs } from 'dayjs';
import React, { Fragment, useState } from 'react';

import { MosaicPageTitle } from '~/components';
import { InsightsLoadingError } from '~/pods/insights/components';
import {
  DateRange,
  InsightsEstate,
  InsightsLead,
  InsightsSession,
  Project,
} from '~/pods/insights/types';
import { createTranslate } from '~/utils/translation';

import { Timescale, timescaleToDays } from '../../constants';
import { Delta } from '../Dashboard/components/Delta';
import { useLoadCampaigns } from '../MarketingCampaigns/hooks';
import {
  BrowserChart,
  CampaignsChart,
  CityChart,
  DevicesChart,
  SourcesChart,
  TractionBanner,
  TractionPlaceholder,
  TypologiesCharts,
  VisitTimesChart,
  VisitorsChart,
} from './components';

const tt = createTranslate('pods.insights.routes.traction');

function _getIconName(delta: number): 'arrow-up-right' | 'arrow-down-right' | undefined {
  if (delta === 0) {
    return undefined;
  } else {
    return delta > 0 ? 'arrow-up-right' : 'arrow-down-right';
  }
}

interface BannerDeltaProps {
  data: number;
  previousData: number;
  timePeriod: string;
}

const BannerDelta = ({ data, previousData, timePeriod }: BannerDeltaProps) => {
  if (previousData === 0) return null;

  const delta = data - previousData;

  const isPositive = delta > 0;

  const sign = isPositive ? '+' : '';
  const change = `${sign}${Number.isInteger(delta) ? delta : `${(delta * 100).toFixed(2)}%`}`;
  const text = tt('banner_delta_text', { change, timePeriod });
  const category = isPositive ? Category.SUCCESS : Category.DANGER;
  const iconName = _getIconName(delta);

  return <Delta text={text} category={category} iconName={iconName} />;
};

function _getEarliestDate(sessions: Array<InsightsSession>, leads: Array<InsightsLead>) {
  const allDates = [...sessions.map((s) => s.createdAt), ...leads.map((l) => l.createdAt)];

  // If there are no sold units, no leads and no sessions yet, use a placeholder date
  if (allDates.length === 0) {
    return dayjs().subtract(1, 'month');
  }

  //this opeartion is slow ~30ms
  return dayjs.min(allDates);
}

function _calcRangeFromTimescale(timescale: string, earliestDate: Dayjs): DateRange {
  const end = dayjs().startOf('day');
  const start =
    timescale === Timescale.ALL_TIME
      ? earliestDate
      : end.subtract(timescaleToDays[timescale] - 1, 'day');

  return { start, end };
}

function _calcPreviousRangeFromTimescale(timescale: string): DateRange {
  const end = dayjs().subtract(timescaleToDays[timescale], 'day').startOf('day');
  const start = dayjs()
    .subtract(2 * timescaleToDays[timescale] - 1, 'day')
    .startOf('day');

  return { start, end };
}

function _isDateInRange(date: Dayjs | null, { start, end }: DateRange) {
  if (date == null) return false;

  return date.isAfter(start) && date.isBefore(end.add(1, 'day'));
}

function _getBannerTimePeriod(timescale: string) {
  switch (timescale) {
    case Timescale.WEEK:
      return tt('7_days');

    case Timescale.MONTH:
      return tt('28_days');

    case Timescale.TRIMESTER:
      return tt('90_days');

    default:
      return '';
  }
}

interface TractionProps {
  units?: InsightsEstate['units'];
  sessions?: Array<InsightsSession>;
  leads?: Array<InsightsLead>;
  project?: Project;
  availableTypologies: Array<string>;
  isLoading: boolean;
}

export const Traction = ({ units, sessions, leads, project, isLoading }: TractionProps) => {
  const {
    campaigns,
    isLoading: isCampaignsLoading,
    error,
  } = useLoadCampaigns(leads, sessions, project?.id);
  const [timescale, setTimescale] = useState(Timescale.WEEK);

  if (isLoading || isCampaignsLoading) {
    return <TractionPlaceholder />;
  } else if (units == null || project == null || sessions == null || leads == null) {
    return <InsightsLoadingError />;
  }

  const earliestDate = _getEarliestDate(sessions, leads);
  const range = _calcRangeFromTimescale(timescale, earliestDate);

  // Ternaries below are to avoid unnecessary filtering when All Time is selected
  const previousRange =
    timescale !== Timescale.ALL_TIME ? _calcPreviousRangeFromTimescale(timescale) : range;

  // New calculation speeds visitors calculation from 130ms to 30ms
  const visitorsInRange =
    timescale !== Timescale.ALL_TIME
      ? sessions.filter((s) => _isDateInRange(s.createdAt, range))
      : sessions;

  const visitorsInPreviousRange =
    timescale !== Timescale.ALL_TIME
      ? sessions.filter((s) => _isDateInRange(s.createdAt, previousRange))
      : [];

  const leadsInRange =
    timescale !== Timescale.ALL_TIME
      ? leads.filter((f) => _isDateInRange(f.createdAt, range))
      : leads;

  const leadsInPreviousRange =
    timescale !== Timescale.ALL_TIME
      ? leads.filter((f) => _isDateInRange(f.createdAt, previousRange))
      : [];

  const conversionInRange =
    visitorsInRange.length > 0 ? leadsInRange.length / visitorsInRange.length : 0;

  const conversionInPreviousRange =
    visitorsInPreviousRange.length > 0
      ? leadsInPreviousRange.length / visitorsInPreviousRange.length
      : 0;

  const visitorsDelta =
    timescale !== Timescale.ALL_TIME ? (
      <BannerDelta
        data={visitorsInRange.length}
        previousData={visitorsInPreviousRange.length}
        timePeriod={_getBannerTimePeriod(timescale)}
      />
    ) : undefined;

  const leadsDelta =
    timescale !== Timescale.ALL_TIME ? (
      <BannerDelta
        data={leadsInRange.length}
        previousData={leadsInPreviousRange.length}
        timePeriod={_getBannerTimePeriod(timescale)}
      />
    ) : undefined;

  const conversionDelta =
    timescale !== Timescale.ALL_TIME ? (
      <BannerDelta
        data={conversionInRange}
        previousData={conversionInPreviousRange}
        timePeriod={_getBannerTimePeriod(timescale)}
      />
    ) : undefined;

  return (
    <Fragment>
      <Flex justify={FlexJustify.START}>
        <FlexItem flex>
          <MosaicPageTitle>{tt('traction')}</MosaicPageTitle>
        </FlexItem>
        <FlexItem>
          <SegmentedControl>
            <SegmentedControlItem
              text={tt('last_7_days')}
              onClick={() => setTimescale(Timescale.WEEK)}
              active={timescale === Timescale.WEEK}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('last_28_days')}
              onClick={() => setTimescale(Timescale.MONTH)}
              active={timescale === Timescale.MONTH}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('last_90_days')}
              onClick={() => setTimescale(Timescale.TRIMESTER)}
              active={timescale === Timescale.TRIMESTER}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('all_time')}
              onClick={() => setTimescale(Timescale.ALL_TIME)}
              active={timescale === Timescale.ALL_TIME}></SegmentedControlItem>
          </SegmentedControl>
        </FlexItem>
      </Flex>
      <Margin size={{ bottom: Size.SMALL }}>
        <Panel
          body={
            <PanelBody noPadding>
              <TractionBanner
                visitors={visitorsInRange.length}
                visitorsDelta={visitorsDelta}
                leads={leadsInRange.length}
                leadsDelta={leadsDelta}
                conversionRate={conversionInRange}
                conversionRateDelta={conversionDelta}
              />
            </PanelBody>
          }
        />
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Panel
          body={
            <PanelBody noPadding>
              <VisitorsChart
                visitors={visitorsInRange}
                previousVisitors={visitorsInPreviousRange}
                leads={leadsInRange}
                previousLeads={leadsInPreviousRange}
                range={range}
                previousRange={previousRange}
                timescale={timescale}
              />
            </PanelBody>
          }
        />
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={2} vGutters={Size.SMALL}>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <VisitTimesChart visitors={visitorsInRange} range={range} />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <TypologiesCharts units={units} visitors={visitorsInRange} />
          </GridItem>
        </Grid>
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={2} vGutters={Size.SMALL}>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <CampaignsChart campaigns={campaigns} range={range} error={error} />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <SourcesChart sessions={visitorsInRange} />
                </PanelBody>
              }
            />
          </GridItem>
        </Grid>
      </Margin>
      <Margin size={{ vertical: Size.SMALL }}>
        <Grid columns={3} vGutters={Size.SMALL}>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <BrowserChart sessions={visitorsInRange} />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <CityChart sessions={visitorsInRange} />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <DevicesChart sessions={visitorsInRange} />
                </PanelBody>
              }
            />
          </GridItem>
        </Grid>
      </Margin>
    </Fragment>
  );
};
