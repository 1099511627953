import groupBy from 'lodash/groupBy';

import { Availability, DateRange, InsightsEstate, InsightsUnit } from '../../types';

function _reducePriceByAvailability(
  units: Array<InsightsUnit>,
  availability: Availability,
): number {
  return units
    .filter((u) => u.availability === availability)
    .reduce((memo, u) => u.price.value + memo, 0);
}

export function useLoadRevenueBreakdown(
  units: InsightsEstate['units'],
  dateRange: DateRange,
): { data: Array<Record<string, number | string>>; dataKeys: Array<string> } {
  // In an effort to make the report as accurate as possible for the period it
  // represents, as opposed to the moment it is generated, we "unsell" the units
  // whose `soldAt` date is over the range of the report:
  //          Sold unit B5
  //               ^
  //               |
  //   NOV    DEC  | JAN    FEB
  //  --+------+---+--+------+--
  //    +------+             ^
  //     Report          Generation
  //     DateRange              Date
  //
  // Here we generate a report in February For the Nov - Dec range, so we "unsell" the B5 unit
  // that was sold after the range represented by the report.

  const unitsAdjusted = units.map((u) => {
    if (u.soldAt == null || u.soldAt.isSameOrBefore(dateRange.end)) return u;
    else return { ...u, soldAt: null, availability: 'available' } as InsightsUnit;
  });

  const unitsByTypology = groupBy(unitsAdjusted, 'typology');
  const data = Object.entries(unitsByTypology).map(([typology, unitsInTypology]) => {
    return {
      typology,
      booked: _reducePriceByAvailability(unitsInTypology, Availability.BOOKED),
      option: _reducePriceByAvailability(unitsInTypology, Availability.OPTION),
      available: _reducePriceByAvailability(unitsInTypology, Availability.AVAILABLE),
    };
  });

  const sorted = data.sort(
    (a, b) => b.booked + b.option + b.available - (a.booked + a.option + a.available),
  );

  return {
    data: sorted,
    dataKeys: ['booked', 'option', 'available'],
  };
}
