import { mapbox } from 'mapbox.js';

import { nonEmptyJoin } from '../utils';

interface MapxboxFeature {
  id: string;
  address?: string; // street number
  text: string; // street name
  matching_text?: string; // street name (translated)
  context: Array<{
    id: string;
    text: string;
    short_code?: string; // country code
  }>;
  geometry: {
    coordinates: [number, number]; // lng, lat
  };
}

interface MapboxRawResult {
  results: {
    features: Array<MapxboxFeature>;
  };
}

export interface GeocoderResult {
  id: string;
  fullAddress: string;
  street?: string;
  zipCode?: string;
  countryCode?: string;
  countryName?: string;
  city?: string;
  coordinates?: {
    lat: number;
    lng: number;
  };
}

interface MapboxQueryVariables {
  query: string;
  language: string;
  types: Array<string>;
}

function createFindContext(context: MapxboxFeature['context']): (id: string) => string | undefined {
  return (id: string) => context?.find((c) => c.id.includes(id))?.text;
}

function geocoderResultToString({
  street,
  zipCode,
  city,
  countryName,
  region,
}: {
  street?: string;
  zipCode?: string;
  city?: string;
  countryName?: string;
  region?: string;
}): string {
  const district = nonEmptyJoin([zipCode, region], ' ');
  return nonEmptyJoin([street, district, city, countryName], ', ');
}

function mapboxFeatureToGeocoderResult(feature: MapxboxFeature): GeocoderResult {
  const {
    id,
    address: streetNumber,
    context,
    text,
    matching_text: matchingText,
    geometry,
  } = feature;
  const findText = createFindContext(context);
  const street = nonEmptyJoin([streetNumber, matchingText || text], ' ');
  const zipCode = findText('postcode');
  const city = findText('place');
  const countryName = findText('country');
  const region = findText('region');
  // mapbox will always return at least one result as a country
  const countryCode = feature.context
    .find((item) => item.id.startsWith('country'))
    ?.short_code?.toUpperCase();
  const fullAddress = geocoderResultToString({ street, zipCode, city, countryName, region });

  return {
    id,
    street,
    zipCode,
    city,
    countryCode,
    countryName,
    fullAddress,
    coordinates: {
      lat: geometry.coordinates[1],
      lng: geometry.coordinates[0],
    },
  };
}

function promisifiedQuery(arg: MapboxQueryVariables, func: any): Promise<MapboxRawResult> {
  return new Promise<MapboxRawResult>((resolve, reject) => {
    func(arg, (err: any, res: MapboxRawResult) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export function useGeocoder(): { query: (queryString: string) => Promise<Array<GeocoderResult>> } {
  const geocoder = mapbox.geocoder('mapbox.places', {
    accessToken: process.env.MAPBOX_ACCESS_TOKEN,
  });

  const query = async (queryString: string, language = 'en') => {
    const res = await promisifiedQuery(
      { query: queryString, language: language, types: ['address'] },
      geocoder.query,
    );
    const addresses: Array<GeocoderResult> = res.results.features.map(
      mapboxFeatureToGeocoderResult,
    );
    return addresses;
  };

  return { query };
}
