import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Lead } from '../../types';

interface LeadsQueryFilters {
  searchTerm?: string;
}

export interface LeadsQueryResult {
  leads: Array<Lead>;
}

export interface LeadsQueryVariables {
  filter?: LeadsQueryFilters;
}

const LeadsQuery = gql`
  query LeadsQuery($filter: LeadFilterInput!) {
    leads(filter: $filter) {
      id
      firstName
      lastName
      fullName @computed(type: Lead)
      email
    }
  }
`;

export function useFetchLeads(
  filters: LeadsQueryFilters,
  options?: { skip: boolean },
): {
  isLoading: boolean;
  leads?: Array<Lead>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<LeadsQueryResult, LeadsQueryVariables>({
    query: LeadsQuery,
    variables: { filter: filters },
    pause: options?.skip,
  });

  return {
    isLoading: res.isLoading,
    leads: res.data?.leads,
    error: buildError(res.error),
  };
}
