import { v4 } from 'uuid';

import { mergeIntoEstate } from '~/pods/insights/utils/hooks/use-load-insights/utils';
import { CurrencyCode, TypologyFormat } from '~/types';

import { InsightsUnit } from '../types';
import { leads, sessions, units } from './generate';

const showcaseLeadsData = leads;
const showcaseSessionsData = sessions;

export const showcaseEstateData = {
  id: '1',
  slug: 'showcase',
  name: 'Showcase Project',
  authToken: v4(),
};

export const showcaseProjectData = {
  id: 'showcase',
  name: 'Showcase',
  dealsGoalStartDate: undefined,
  dealsGoalEndDate: undefined,
};

export const computedShowcaseData = {
  isLoading: false,
  estate: mergeIntoEstate(showcaseEstateData, units as InsightsUnit[], showcaseSessionsData)!,
  project: showcaseProjectData,
  sessions: showcaseSessionsData,
  leads: showcaseLeadsData,
  error: { isNotFound: false, isCritical: false },
  typologyFormat: TypologyFormat.FRENCH,
  refetchProject: () => {},
};

export const showcaseCampaignsData = {
  isLoading: false,
  campaigns: [
    {
      launchedAt: '2021-01-24T09:44:11Z',
      token: 'CAMP1111',
      name: 'Launch - Google Paid Ads',
      destinationUrl: 'https://www.test_url.com',
      source: '',
    },
    {
      launchedAt: '2021-02-23T09:44:12Z',
      token: 'CAMP2222',
      name: 'Launch - Facebook Ads',
      destinationUrl: 'https://www.test_url.com',
      source: '',
    },
    {
      launchedAt: '2021-03-26T09:44:12Z',
      token: 'CAMP3333',
      name: 'Launch - Emails',
      destinationUrl: 'https://www.test_url.com',
      source: '',
    },
    {
      launchedAt: '2021-04-26T09:44:12Z',
      token: 'CAMP4444',
      name: 'Ongoing - Partner Referral',
      destinationUrl: 'https://www.test_url.com',
      source: '',
    },
    {
      launchedAt: '2021-05-24T09:44:12Z',
      token: 'CAMP5555',
      name: 'Ongoing - Newsletter',
      destinationUrl: 'https://www.test_url.com',
      source: '',
    },
  ],
  refetchCampaigns: () => {},
  error: undefined,
};

export const showcaseOrganisationData = {
  isLoading: false,
  organisation: {
    id: '1',
    publicToken: '1',
    name: 'Showcase Org',
    currency: CurrencyCode.EUR,
    isAgency: false,
  },
  refetch: () => {},
  error: undefined,
};
