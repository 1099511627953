import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  Margin,
  Size,
  Text,
  formatPrice,
} from '@drawbotics/react-drylus';
import { SliceTooltipProps } from '@nivo/line';
import React from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.deals');

interface CustomLineTooltipProps extends SliceTooltipProps {
  currency?: string;
}

export const CustomLineTooltip = ({ slice, currency }: CustomLineTooltipProps) => {
  return (
    <div
      style={{
        borderRadius: sv.defaultBorderRadius,
        padding: sv.paddingExtraSmall,
        background: sv.neutralDarkest,
      }}>
      <Text inversed>{`${tt(currency == null ? 'sold' : 'income')}:`}</Text>
      <Margin size={{ left: Size.EXTRA_SMALL }}>
        {slice.points.map((point) => (
          <div key={point.id} style={{ marginTop: 4 }}>
            <Flex align={FlexAlign.START}>
              <FlexItem>
                <div
                  style={{
                    height: 3,
                    width: 12,
                    borderRadius: 12,
                    marginTop: 6,
                    backgroundColor: point.serieColor,
                  }}
                />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem flex>
                <div style={{ marginBottom: 4 }}>
                  <Text light inversed size={Size.SMALL}>
                    {point.serieId}:{' '}
                    {currency == null
                      ? point.data.y
                      : formatPrice({ price: { value: Number(point.data.y), currency } })}
                  </Text>
                </div>
              </FlexItem>
            </Flex>
          </div>
        ))}
      </Margin>
      {currency != null ? (
        <Text inversed>
          {`${tt('total')}: ${formatPrice({
            price: {
              value: slice.points.reduce((memo, point) => memo + (point.data.y as number), 0),
              currency,
            },
          })}`}
        </Text>
      ) : null}
    </div>
  );
};
