import gql from 'fraql';

import { unitFragment } from '../fragments';

export const CreateUnitMutation = gql`
  mutation CreateUnit($unit: CreateUnitInput!) {
    createdUnit: createUnit(input: $unit) {
      unit {
        ${unitFragment}
      }
    }
  }
`;
