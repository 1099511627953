import {
  Avatar,
  Button,
  Category,
  EmptyState,
  Icon,
  ListTile,
  Size,
  Spinner,
  Table,
  Tier,
  Tooltip,
  formatDate,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { useState } from 'react';

import { CapitalFirst } from '~/components';
import { Invitation, Teammate, User } from '~/pods/meetings/types';
import { ErrorType, OrganisationRole } from '~/types';
import {
  createTranslate,
  generateColorFromString,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  run,
  useAuth,
  useMosaicMutation,
} from '~/utils';

import { isUser } from '../utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.sales_team_table');
const ta = createTranslate('pods.marketing_suite');

interface SalesTeamTableProps {
  isLoading: boolean;
  teammates: Array<User | Invitation>;
  openAddModal: VoidFunction;
  error?: ErrorType;
  refetchTeammates: VoidFunction;
  refetchLicenses: VoidFunction;
}

interface RevokeTeammateVariables {
  teammate: {
    userId?: string;
    invitationId?: string;
  };
}

interface RevokeTeammateResult {
  teammate: {
    id: string;
  };
}

const revokeTeammateMutation = gql`
  mutation revokeTeammate($teammate: RemoveTeammateInput!) {
    removeTeammate(input: $teammate) {
      teammate {
        ... on User {
          id
        }
        ... on Invitation {
          id
        }
      }
    }
  }
`;

export const SalesTeamTable = ({
  isLoading,
  teammates,
  openAddModal,
  error,
  refetchTeammates,
  refetchLicenses,
}: SalesTeamTableProps) => {
  const { showAlert } = useAlert();
  const [idToRemove, setIdToRemove] = useState<string>();
  const { user } = useAuth();
  const { executeMutation: revokeTeammate, res: revokeTeammateResult } = useMosaicMutation<
    RevokeTeammateResult,
    RevokeTeammateVariables
  >(revokeTeammateMutation);

  const isUserMember = user?.role === OrganisationRole.MEMBER;

  const handleRevokeTeammate = async (teammate: Teammate) => {
    setIdToRemove(teammate.id);
    const { error } = await revokeTeammate({
      teammate: { [isUser(teammate) ? 'userId' : 'invitationId']: teammate.id },
    });
    setIdToRemove(undefined);
    if (error != null) {
      showAlert({
        text: tt('something_went_wrong', { teammate: teammate.fullName }),
        category: Category.DANGER,
      });
    } else {
      refetchTeammates();
      refetchLicenses();
      showAlert({
        text: tt('license_revoked', { teammate: teammate.fullName }),
        category: Category.SUCCESS,
      });
    }
  };

  const showEmptyState = !isLoading && teammates.length === 0;
  const tableData = teammates.map((teammate) => {
    return {
      id: teammate.id,
      name: (
        <ListTile
          title={teammate.fullName}
          subtitle={teammate.email}
          leading={
            <Avatar
              text={teammate.fullName[0]}
              backgroundColor={generateColorFromString(teammate.email)}
            />
          }
        />
      ),
      meetingCount: isUser(teammate) ? teammate.meetingCount : null,
      companyAccess: ta(`company_role.${teammate.role.toLowerCase()}`),
      revokeAccess: (
        <Tooltip content={isUserMember ? tt('only_admins') : tt('revoke_subscription')}>
          <Button
            disabled={revokeTeammateResult.fetching || isUserMember}
            tier={Tier.SECONDARY}
            size={Size.SMALL}
            category={Category.DANGER}
            onClick={() => handleRevokeTeammate(teammate)}
            leading={
              revokeTeammateResult.fetching && idToRemove === teammate.id ? (
                <Spinner size={Size.SMALL} />
              ) : (
                <Icon name="user-minus" />
              )
            }
          />
        </Tooltip>
      ),
      memberSince: (
        <CapitalFirst>
          {formatDate({
            date: new Date(isUser(teammate) ? teammate.memberSince : teammate.lastSentAt),
          })}
        </CapitalFirst>
      ),
    };
  });

  return (
    <Table
      isLoading={isLoading && teammates.length === 0}
      loadingRows={8}
      emptyContent={run(() => {
        if (error != null) {
          return (
            <EmptyState
              variation={getEmptyStateVariationFromErrorType(error)}
              description={getMessageFromErrorType(error)}
            />
          );
        } else if (showEmptyState) {
          return (
            <EmptyState title={tt('start_creating')} description={tt('cant_see_team')}>
              <Button onClick={openAddModal} category={Category.INFO}>
                {tt('add_teammates')}
              </Button>
            </EmptyState>
          );
        }
      })}
      data={tableData}
      header={[
        { label: tt('name'), value: 'name' },
        { label: tt('meeting_count'), value: 'meetingCount' },
        { label: tt('company_access'), value: 'companyAccess' },
        { label: tt('revoke_access'), value: 'revokeAccess' },
        { label: tt('member_since'), value: 'memberSince' },
      ]}
    />
  );
};
