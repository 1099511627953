import sv from '@drawbotics/drylus-style-vars';
import {
  Banner,
  Button,
  ButtonLink,
  Category,
  Content,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Margin,
  Padding,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React from 'react';
import { Link } from 'react-router-dom';

import { MosaicPageTitle } from '~/components';
import { OrganisationRole } from '~/types';
import {
  createTranslate,
  getMessageFromErrorType,
  getTitleFromErrorType,
  useAuth,
  useMosaicMutation,
  useMosaicNavigation,
} from '~/utils';

import {
  OrganisationOverviewStatus,
  PresentationStatus,
  Presentation as PresentationType,
} from '../../types';
import {
  useFetchBasicOrganisationOverview,
  useFetchBasicPresentation,
  useFetchProject,
} from '../../utils';
import {
  LoadingPresentationPlaceholder,
  PresentationDetails,
  PresentationPlaceholder,
  SectionsEditor,
} from './components';

const tt = createTranslate('pods.presentation.routes.presentation');

interface UpdatePresentationVariables {
  presentation: {
    id: string;
    status?: PresentationStatus;
  };
}

interface UpdatePresentationResult {
  updatePresentation: {
    presentation: PresentationType;
  };
}

const updatePresentationMutation = gql`
  mutation UpdatePresentation($presentation: UpdatePresentationInput!) {
    updatePresentation(input: $presentation) {
      presentation {
        id
        status
      }
    }
  }
`;

export const Presentation = () => {
  const { projectToken, getUrlInOrg } = useMosaicNavigation();
  const {
    isLoading: isProjectLoading,
    project,
    error: projectError,
  } = useFetchProject(projectToken!);
  const {
    isLoading: isPresentationLoading,
    presentation,
    error: presentationError,
  } = useFetchBasicPresentation(project?.id, { skip: project?.id == null });
  const { organisationOverview } = useFetchBasicOrganisationOverview({
    skip: presentation == null,
  });
  const { showAlert } = useAlert();

  const { res: updatePresentationResult, executeMutation: updatePresentation } = useMosaicMutation<
    UpdatePresentationResult,
    UpdatePresentationVariables
  >(updatePresentationMutation);
  const { user } = useAuth();
  const isUserMember = user?.role === OrganisationRole.MEMBER;

  if (isProjectLoading || isPresentationLoading) {
    return <LoadingPresentationPlaceholder />;
  } else if (
    project == null ||
    (presentation == null && presentationError != null && presentation == null)
  ) {
    return (
      <Content fullHeight>
        <Padding size={Size.LARGE}>
          <EmptyState
            title={getTitleFromErrorType(projectError ?? presentationError)}
            description={getMessageFromErrorType(projectError ?? presentationError)}
            variation={EmptyStateVariation.FAILED}
          />
        </Padding>
      </Content>
    );
  }

  const handlePublishPresentation = async () => {
    if (presentation != null) {
      const { error } = await updatePresentation({
        presentation: { id: presentation.id, status: PresentationStatus.PUBLISHED },
      });
      if (error != null) {
        showAlert({
          text: tt('publish_error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('publish_success'),
          category: Category.SUCCESS,
        });
      }
    }
  };

  const handleUnpublishPresentation = async () => {
    if (presentation != null) {
      const { error } = await updatePresentation({
        presentation: { id: presentation.id, status: PresentationStatus.UNPUBLISHED },
      });
      if (error != null) {
        showAlert({
          text: tt('unpublish_error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('unpublish_success'),
          category: Category.INFO,
        });
      }
    }
  };

  const showCustomizationBanner =
    presentation?.organisation.primaryColor == null ||
    presentation?.organisation.secondaryColor == null;
  const showOrganisationOverviewBanner =
    organisationOverview?.status === OrganisationOverviewStatus.UNPUBLISHED;
  const isPresentationOngoing = presentation?.status !== PresentationStatus.INITIATED;

  return (
    <Content fullHeight>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <Flex>
          <FlexItem flex>
            <MosaicPageTitle>
              {isPresentationOngoing ? tt('sales_presentation') : tt('create_presentation')}
            </MosaicPageTitle>
          </FlexItem>
          <FlexItem>
            {!isPresentationOngoing || presentation == null ? null : (
              <a target="_blank" rel="noopener noreferrer" href={presentation.presentationUrl}>
                <ButtonLink tier={Tier.SECONDARY}>{tt('see_preview_page')}</ButtonLink>
              </a>
            )}
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            {!isPresentationOngoing || presentation == null ? null : (
              <Button
                disabled={updatePresentationResult.fetching || isUserMember}
                trailing={
                  updatePresentationResult.fetching ? <Spinner size={Size.SMALL} inversed /> : null
                }
                category={
                  presentation.status === PresentationStatus.PUBLISHED
                    ? Category.DANGER
                    : Category.INFO
                }
                onClick={
                  presentation.status === PresentationStatus.PUBLISHED
                    ? handleUnpublishPresentation
                    : handlePublishPresentation
                }>
                {presentation.status === PresentationStatus.PUBLISHED
                  ? tt('unpublish_presentation')
                  : tt('publish_presentation')}
              </Button>
            )}
          </FlexItem>
        </Flex>
        {showCustomizationBanner ? (
          <Margin size={{ bottom: Size.LARGE }}>
            <Banner
              category={Category.INFO}
              trailing={
                <Link to={getUrlInOrg('/settings/branding')}>
                  <ButtonLink
                    inversed
                    size={Size.SMALL}
                    tier={Tier.SECONDARY}
                    style={{ whiteSpace: 'nowrap' }}>
                    {tt('company_settings')}
                  </ButtonLink>
                </Link>
              }>
              {tt('company_settings_description')}
            </Banner>
          </Margin>
        ) : null}
        {showOrganisationOverviewBanner ? (
          <Margin size={{ bottom: Size.LARGE }}>
            <Banner
              category={Category.INFO}
              trailing={
                <Link to={getUrlInOrg('/meetings/company-overview')}>
                  <ButtonLink
                    inversed
                    size={Size.SMALL}
                    tier={Tier.SECONDARY}
                    style={{ whiteSpace: 'nowrap' }}>
                    {tt('presentations')}
                  </ButtonLink>
                </Link>
              }>
              {tt('create_overview')}
            </Banner>
          </Margin>
        ) : null}
        {presentation == null ? (
          <PresentationPlaceholder />
        ) : (
          <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.START}>
            <FlexItem flex style={{ width: `calc(50% - ${sv.marginSmall})`, flex: 'none' }}>
              <PresentationDetails projectId={project.id} editOnly={!isPresentationOngoing} />
            </FlexItem>
            <FlexSpacer size={Size.LARGE} />
            {isPresentationOngoing ? (
              <FlexItem flex style={{ width: '50%', flex: 'none' }}>
                <SectionsEditor projectId={project.id} presentationId={presentation.id} />
              </FlexItem>
            ) : null}
          </Flex>
        )}
      </Padding>
    </Content>
  );
};
