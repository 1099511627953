import { Tile } from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface ChartTileProps {
  children: ReactNode;
}

export const ChartTile = ({ children }: ChartTileProps) => {
  return <Tile style={{ width: '100%', height: '100%' }}>{children}</Tile>;
};
