import sv from '@drawbotics/drylus-style-vars';
import { Global } from '@emotion/react';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import logo from '~/images/mosaic-dark.svg';

const styles = {
  page: css`
    width: 21cm;
    height: 29.7cm;
    background: ${sv.backgroundColor};
    position: relative;
    overflow: hidden;
    margin: 1cm auto;

    @media print {
      margin: 0;

      @page {
        size: A4 portrait;
        margin: 0;
      }
    }
  `,
  logo: css`
    position: absolute;
    width: 4cm;
    top: 1.2cm;
    left: 1cm;

    > img {
      display: block;
    }
  `,
  dateHeader: css`
    position: absolute;
    top: 0;
    right: 1cm;
  `,
  footer: css`
    width: 100%;
    position: absolute;
    bottom: 1.3cm;
  `,
};

interface PageProps {
  children: ReactNode;
  dateHeader?: ReactNode;
  footer?: ReactNode;
}

export const Page = ({ children, dateHeader, footer }: PageProps) => {
  return (
    <div className={styles.page}>
      <Global styles={{ body: { fontSize: '8pt' }, html: { fontSize: '8pt' } }} />
      <div className={styles.logo}>
        <img style={{ maxWidth: '100%' }} src={logo} />
      </div>
      {dateHeader && <div className={styles.dateHeader}>{dateHeader}</div>}
      {children}
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};
