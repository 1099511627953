import dayjs from 'dayjs';
import produce from 'immer';

import { InsightsEstate, InsightsSession, InsightsUnit } from '../../../types';

export type EstateWithSessions = InsightsEstate & {
  units: Array<InsightsUnit & { visits: string[]; timeVisited: number }>;
};

export function mergeIntoEstate(
  estate: Omit<InsightsEstate, 'units'> | undefined,
  units: Array<InsightsUnit> | undefined,
  _sessions: Array<InsightsSession> | null,
) {
  if (estate == null || units == null) {
    return undefined;
  }

  const sessions = _sessions ?? [];

  return produce(estate, (estate: EstateWithSessions) => {
    // Add defaults and apply dayjs
    estate.units = units.map((u) => ({
      ...u,
      soldAt: u.soldAt ? dayjs(u.soldAt) : null,
      visits: [],
      timeVisited: 0,
    }));

    // Add basic values for visits and time visited
    sessions.forEach((session) => {
      const { path, time } = session;

      path.forEach((entity) => {
        const index = estate.units.findIndex((u) => String(u.id) === String(entity.id));
        if (index < 0) return;
        estate.units[index].visits = [
          ...(estate.units[index].visits ?? []),
          entity.timestamp.format(),
        ];
      });

      time.forEach((entity) => {
        const index = estate.units.findIndex((u) => String(u.id) === String(entity.id));
        if (index < 0 || estate.units[index].visits.length === 0) return;
        estate.units[index].timeVisited += entity.time;
      });
    });
  }) as EstateWithSessions;
}
