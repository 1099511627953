import sv from '@drawbotics/drylus-style-vars';
import {
  ButtonLink,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Padding,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Campaign, DateRange } from '~/pods/insights/types';
import { isDateInRange } from '~/pods/insights/utils';
import { ErrorType } from '~/types';
import { createTranslate, useMosaicNavigation } from '~/utils';

import { CampaignsChartPlaceholder } from '../images/CampaignsChartPlaceholder';
import { ChartHeader } from './ChartHeader';
import { HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction.campaigns_chart');

const styles = {
  chartContainer: css`
    width: 100%;
    height: 210px;
    text-align: center;
  `,
};

interface CampaignErrorPlaceholderProps {
  error?: ErrorType;
}

const CampaignErrorPlaceholder = ({ error }: CampaignErrorPlaceholderProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  return (
    <Padding size={Size.EXTRA_SMALL}>
      <ChartHeader label={tt('campaigns')} />
      <div className={styles.chartContainer}>
        <Flex direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <CampaignsChartPlaceholder />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>{error != null ? tt('campaigns_load_error') : tt('no_campaigns_yet')}</FlexItem>
          {error == null ? (
            <Fragment>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Link to={getUrlInProject('/analytics/campaigns')}>
                  <ButtonLink color={Color.BLUE}>{tt('create_campaign')}</ButtonLink>
                </Link>
              </FlexItem>
            </Fragment>
          ) : null}
        </Flex>
      </div>
    </Padding>
  );
};

interface CampaignsChartProps {
  campaigns: Array<Campaign>;
  error?: ErrorType;
  range: DateRange;
}

export const CampaignsChart = ({ campaigns, range, error }: CampaignsChartProps) => {
  const campaignsData = campaigns.map((c) => ({
    data: c.name,
    visitCount: c.visits.filter((v) => isDateInRange(v, range)).length,
    color: sv.neutral,
  }));

  const hasAnyVisits = campaignsData.some((d) => d.visitCount > 0);

  if (error != null || campaigns.length === 0) {
    return <CampaignErrorPlaceholder error={error} />;
  } else {
    return (
      <HorizontalTractionBarChart
        label={tt('campaigns')}
        data={hasAnyVisits ? campaignsData : []}
      />
    );
  }
};
