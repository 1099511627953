import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  LoadingPlaceholder,
  Margin,
  Modal,
  Size,
  Spinner,
  Text,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import gql from 'fraql';
import { range } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Optional, ShowroomAddress } from '~/types';
import { createTranslate, run, useMosaicMutation, useMosaicQuery } from '~/utils';

import { AddressBox } from '../../pods/settings/routes/BasicInfo/components/AddressBox';
import { CreateAndEditAddressModal } from './components';
import handshake from './images/handshake.svg';

const BUTTON_OUTLET_ID = 'address-manager-button-outlet';

const tt = createTranslate('pods.settings.routes.basic_info');

const styles = {
  loadingBox: css`
    height: 120px;
    border-radius: ${sv.borderRadiusLarge};
    border: 1px solid ${sv.grey300};
    padding: ${sv.paddingSmall};
  `,
};

const AddressesLoadingPlaceholder = () => {
  return (
    <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
      {range(3).map((index) => (
        <GridItem key={index}>
          <div className={styles.loadingBox}>
            <LoadingPlaceholder width={300} height={18} />
            <Margin size={Size.SMALL} />
            <LoadingPlaceholder width={150} height={15} />
            <Margin size={Size.EXTRA_SMALL} />
            <LoadingPlaceholder width={100} height={15} />
            <Margin size={Size.EXTRA_SMALL} />
            <LoadingPlaceholder width={150} height={15} />
          </div>
        </GridItem>
      ))}
    </Grid>
  );
};

interface EmptyPlaceholderProps {
  onClickAdd: VoidFunction;
}

const EmptyPlaceholder = ({ onClickAdd }: EmptyPlaceholderProps) => {
  return (
    <Margin size={Size.DEFAULT}>
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={handshake} />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          <Text tier={Tier.SECONDARY}>
            {tt('empty_text_1')}
            <br />
            {tt('empty_text_2')}
          </Text>
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>
          <Button onClick={onClickAdd} color={Color.BLUE}>
            {tt('add_address')}
          </Button>
        </FlexItem>
      </Flex>
    </Margin>
  );
};

const AddressesQuery = gql`
  query AddressesQuery {
    addresses {
      id
      countryCode
      city
      zipCode
      street
    }
  }
`;

const createAddressMutation = gql`
  mutation createAddress($address: CreateAddressInput!) {
    createAddress(input: $address) {
      address {
        id
      }
    }
  }
`;

const updateAddressMutation = gql`
  mutation updateAddress($address: UpdateAddressInput!) {
    updateAddress(input: $address) {
      address {
        id
      }
    }
  }
`;

const deleteAddressMutation = gql`
  mutation destroyAddress($address: DestroyAddressInput!) {
    destroyAddress(input: $address) {
      address {
        id
      }
    }
  }
`;

export const ShowroomAddressEditor = () => {
  const [addresses, setAddresses] = useState<Array<ShowroomAddress & { isNewAddress?: boolean }>>(
    [],
  );
  const [addressBeingEditedId, setAddressBeingEditedId] = useState<string>();
  const [addressToDelete, setAddressToDelete] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { showAlert } = useAlert();

  const { data, isLoading, refetch } = useMosaicQuery<{ addresses: Array<ShowroomAddress> }, null>({
    query: AddressesQuery,
  });

  const {
    executeMutation: createAddress,
    res: { fetching: isCreateFetching },
  } = useMosaicMutation<{ id: string }, { address: Omit<ShowroomAddress, 'id'> }>(
    createAddressMutation,
  );

  const {
    executeMutation: updateAddress,
    res: { fetching: isUpdateFetching },
  } = useMosaicMutation<{ id: string }, { address: ShowroomAddress }>(updateAddressMutation);

  const {
    executeMutation: deleteAddress,
    res: { fetching: isDeleteFetching },
  } = useMosaicMutation<{ id: string }, { address: { id: string } }>(deleteAddressMutation);

  useEffect(() => {
    if (data?.addresses != null) {
      setAddresses(data?.addresses);
    }
  }, [JSON.stringify(data)]);

  const isFetching = isCreateFetching || isUpdateFetching || isDeleteFetching;

  const handleClickSave = async (address: Optional<ShowroomAddress, 'id'>) => {
    const { error } =
      address.id == null
        ? await createAddress({ address })
        : await updateAddress({ address: address as ShowroomAddress });

    if (error != null) {
      showAlert({
        text: tt('generic_address_error'),
        category: Category.DANGER,
      });
      return;
    }
    refetch({ requestPolicy: 'network-only' });
    setAddressBeingEditedId(undefined);
  };

  const handleDeleteAddress = async () => {
    if (addressToDelete != null) {
      await deleteAddress({ address: { id: addressToDelete } });
      refetch({ requestPolicy: 'network-only' });
    }
    setAddressToDelete(undefined);
  };

  const handleCloseModal = () => {
    setAddressBeingEditedId(undefined);
    setIsModalVisible(false);
  };

  const NewAddressButton = (
    <Button
      color={Color.BLUE}
      onClick={() => {
        setIsModalVisible(true);
      }}>
      {tt('create_address')}
    </Button>
  );

  const buttonOutlet = document.querySelector(`#${BUTTON_OUTLET_ID}`);

  return (
    <Fragment>
      {run(() => {
        if (isLoading) {
          return <AddressesLoadingPlaceholder />;
        } else if (addresses.length === 0) {
          return <EmptyPlaceholder onClickAdd={() => setIsModalVisible(true)} />;
        } else {
          return (
            <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
              {addresses.map((address) => (
                <GridItem key={address.id} style={{ width: '100%' }}>
                  <AddressBox
                    address={address}
                    onClickEdit={() => setAddressBeingEditedId(address.id)}
                    onClickDelete={() => setAddressToDelete(address.id)}
                  />
                </GridItem>
              ))}
            </Grid>
          );
        }
      })}
      <CreateAndEditAddressModal
        address={addresses.find((addr) => addr.id === addressBeingEditedId)}
        isVisible={isModalVisible || addressBeingEditedId != null}
        onClickClose={handleCloseModal}
        onSave={handleClickSave}
        isFetching={isFetching}
      />
      <Modal
        title={tt('confirm_delete_title')}
        visible={addressToDelete != null}
        onClickClose={() => setAddressToDelete(undefined)}
        footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button
                disabled={isFetching}
                onClick={() => setAddressToDelete(undefined)}
                tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Button
                disabled={isFetching}
                leading={isFetching ? <Spinner size={Size.SMALL} /> : null}
                onClick={handleDeleteAddress}
                color={Color.RED}>
                {tt('confirm_delete')}
              </Button>
            </FlexItem>
          </Flex>
        }>
        <Text>{tt('confirm_delete_body')}</Text>
      </Modal>
      {run(() => {
        if (buttonOutlet != null) {
          return ReactDOM.createPortal(NewAddressButton, buttonOutlet);
        } else {
          console.warn('Did not find an outlet for the new address button');
        }
      })}
    </Fragment>
  );
};

ShowroomAddressEditor.ButtonOutlet = () => <div id={BUTTON_OUTLET_ID} />;
