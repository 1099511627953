import { weightedPick } from './weighted-pick';

export function pickSource(): string {
  const demoSources: Array<string> = [
    '',
    'http://www.example.com',
    'google.ads.com',
    'google.com',
    'facebook.com',
    'linkedin.com/someonespostgoogle.ads.com',
    'bing.com',
  ];

  const demoSourcesWeights: Record<string, number> = {
    '': 0.02,
    'http://www.example.com': 0.01,
    'google.ads.com': 0.35,
    'google.com': 0.35,
    'facebook.com': 0.2,
    'linkedin.com/someonespostgoogle.ads.com': 0.04,
    'bing.com': 0.03,
  };

  return weightedPick(demoSources, demoSourcesWeights);
}
