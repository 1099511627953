import sv from '@drawbotics/drylus-style-vars';
import {
  Dropdown,
  DropdownSeparator,
  DropdownTitle,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  Padding,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ShowroomAddress } from '~/types';
import { createTranslate, getCountryName, useMosaicNavigation } from '~/utils';

const tt = createTranslate('components.showroom_address_selector');

const styles = {
  dropdown: css`
    width: 100%;
  `,
  addressOption: css`
    width: 100%;
    min-width: 300px;
    cursor: pointer;

    &:hover {
      background-color: ${sv.backgroundColor};
    }
  `,
};

interface AddressOptionProps {
  address: ShowroomAddress;
  onClick: VoidFunction;
}

const AddressOption = ({ address, onClick }: AddressOptionProps) => {
  return (
    <div className={styles.addressOption}>
      <Padding size={{ horizontal: Size.SMALL, vertical: Size.EXTRA_SMALL }}>
        <div style={{ width: '100%', textAlign: 'left' }} onClick={onClick}>
          <DropdownTitle style={{ paddingLeft: '0px' }} text={address.city} />
          <Text>{address.street}</Text>
          <br />
          <Text>{`${address.zipCode} ${address.city}, ${getCountryName(
            address.countryCode,
          )}`}</Text>
        </div>
      </Padding>
    </div>
  );
};

interface ShowroomAddressSelectorProps {
  addresses: Array<ShowroomAddress>;
  onSelectAddress: (address: ShowroomAddress) => void;
  extraOptions?: ReactNode;
  trigger: ReactNode;
  disabled?: boolean;
}

export const ShowroomAddressSelector = ({
  addresses,
  onSelectAddress,
  trigger,
  extraOptions,
  disabled,
}: ShowroomAddressSelectorProps) => {
  const { getAddressEditUrl } = useMosaicNavigation();

  if (disabled) {
    return <div style={{ cursor: 'not-allowed' }}>{trigger}</div>;
  }

  return (
    <Dropdown className={styles.dropdown} trigger={trigger}>
      {addresses.map((address) => (
        <AddressOption
          key={address.id}
          onClick={() => onSelectAddress(address)}
          address={address}
        />
      ))}
      {extraOptions}
      {addresses.length === 0 ? null : <DropdownSeparator />}
      <Link to={getAddressEditUrl()} target="_blank">
        <div className={styles.addressOption}>
          <Padding size={{ horizontal: Size.SMALL, vertical: Size.EXTRA_SMALL }}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <Text>{tt('manage')}</Text>
              </FlexItem>
              <FlexItem>
                <Icon name="external-link" />
              </FlexItem>
            </Flex>
          </Padding>
        </div>
      </Link>
    </Dropdown>
  );
};
