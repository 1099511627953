import { ze } from '@tokamakjs/common';
import { z } from 'zod';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
const ProjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
});

export class Project extends ze.ClassFrom(ProjectSchema) {
  public static fromJson(json: any): Project {
    return ze.validate(json, Project);
  }

  get isWebsiteEnabled() {
    return true;
  }
}
