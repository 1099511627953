import {
  ButtonLink,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import { useMosaicNavigation } from '~/utils';

export const InsightsLoadingError = () => {
  const { getUrlInOrg } = useMosaicNavigation();
  return (
    <Margin size={Size.MASSIVE}>
      <Flex
        style={{ height: '100%' }}
        direction={FlexDirection.VERTICAL}
        align={FlexAlign.CENTER}
        justify={FlexJustify.CENTER}>
        <FlexItem>
          <EmptyState
            variation={EmptyStateVariation.FAILED}
            title="Something went wrong"
            description="Something went wrong while fetching the Insights data.">
            <Link to={getUrlInOrg()}>
              <ButtonLink category={Category.INFO}>Go to home</ButtonLink>
            </Link>
          </EmptyState>
        </FlexItem>
      </Flex>
    </Margin>
  );
};
