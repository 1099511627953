import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Meeting } from '../../types/meeting';

interface MeetingQueryResult {
  meeting: Meeting;
}

interface MeetingQueryVariables {
  id: string;
}

const MeetingQuery = gql`
  query MeetingQuery($id: ID!) {
    meeting(id: $id) {
      id
      user {
        id
        fullName @computed(type: User)
        firstName
        lastName
        meetingCount
        email
        license {
          id
        }
      }
      lead {
        id
        email
        firstName
        lastName
      }
      location
      status
      address
      date: datetime
      meetingLink
      additionalParticipants
      displayListingPlans
      displayListingPrice
      displayListingStatus
      displayListingSurface
      displayListingRooms
      displayListingBedrooms
      allowListingPlansDownload
      hideSoldUnits
      evolutiveBookletUrl
      presentations {
        id
        name
        listingDisplay(meetingId: $id)
        presentationUrl
        coverPicture {
          id
          filename
          url
        }
      }
    }
  }
`;

export function useFetchMeeting(meetingId: string): {
  isLoading: boolean;
  meeting?: Meeting;
  error?: ErrorType;
} {
  const res = useMosaicQuery<MeetingQueryResult, MeetingQueryVariables>({
    query: MeetingQuery,
    variables: { id: meetingId },
  });

  return {
    isLoading: res.isLoading,
    meeting: res.data ? {
      ...res.data.meeting,
      lead: {
        ...res.data.meeting.lead,
        fullName: `${res.data.meeting.lead.firstName} ${res.data.meeting.lead.lastName || ""}`
      }
    } : undefined,
    error: buildError(res.error),
  };
}
