import {
  Button,
  Category,
  FormGroup,
  Input,
  Label,
  Margin,
  Padding,
  Panel,
  PanelBody,
  Shade,
  Size,
  Spinner,
  Text,
  TextLink,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React from 'react';

import { Form } from '~/components';
import { useLogin, useLoginLicense } from '~/pods/meetings/utils';
import { createTranslate, extractGQLErrorMessages, translate as t } from '~/utils';

const ta = createTranslate('pods.marketing_suite.routes.demodesk_login');
const tt = createTranslate('pods.marketing_suite.routes.demodesk_login.components.login_form');

interface LoginFormForm {
  email: string;
  password: string;
}

export const LoginForm = ({ token }: { token: string }) => {
  const loginForm = useForm<LoginFormForm>({
    email: '',
    password: '',
  });
  const { isLoggingIn, login } = useLogin();
  const { isLoading: isLoginLicenseLoading, login: loginLicense } = useLoginLicense();
  const { showAlert } = useAlert();

  const meetingLink = `${process.env.DEMODESK_HOST}/${token}`;

  const handleLogin = async () => {
    const formData = {
      ...loginForm.values,
      email: loginForm.values.email?.toLowerCase(),
    } as Required<LoginFormForm>;

    const user = await login(formData);

    if (user == null) {
      showAlert({
        text: t('pods.auth.routes.login.credentials_incorrect'),
        category: Category.DANGER,
      });
    } else {
      const { error } = await loginLicense(token);
      if (error != null) {
        const { message } = extractGQLErrorMessages(error);
        showAlert({
          text: message != null ? ta(`errors.${message}`) : ta('errors.login_error'),
          category: Category.DANGER,
        });
      } else {
        window.location.assign(meetingLink);
      }
    }
  };

  const formIsIncomplete = Object.values(loginForm.values).some((val) => val == null || val === '');
  const isLoading = isLoggingIn || isLoginLicenseLoading;

  return (
    <Panel
      body={
        <PanelBody>
          <div style={{ width: 500 }}>
            <Padding size={{ horizontal: Size.HUGE, vertical: Size.EXTRA_LARGE }}>
              <Title noMargin size={3}>
                {tt('im_the_host')}
              </Title>
              <Margin size={{ vertical: Size.LARGE }}>
                <Text shade={Shade.LIGHT}>{tt('not_host')} </Text>
                <a href={meetingLink}>
                  <TextLink>{tt('join_as_guest')}</TextLink>
                </a>
              </Margin>
              <Form onSubmit={handleLogin}>
                <FormGroup
                  input={
                    <Input
                      placeholder="your@email.com"
                      type="email"
                      name="email"
                      value={loginForm.get}
                      onChange={loginForm.set}
                    />
                  }
                  label={<Label>{tt('email')}</Label>}
                />
                <Margin size={Size.DEFAULT} />
                <FormGroup
                  input={
                    <Input
                      placeholder={tt('type_password')}
                      type="password"
                      name="password"
                      value={loginForm.get}
                      onChange={loginForm.set}
                    />
                  }
                  label={<Label>{tt('password')}</Label>}
                />
                <Margin size={Size.DEFAULT} />
                <Button
                  trailing={isLoading ? <Spinner /> : null}
                  onClick={handleLogin}
                  disabled={isLoading || formIsIncomplete}>
                  {tt('login')}
                </Button>
              </Form>
            </Padding>
          </div>
        </PanelBody>
      }
    />
  );
};
