import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Presentation } from '../../types';

interface PresentationQueryData {
  presentation: Presentation;
}

interface PresentationQueryVariables {
  projectId: string;
}

const PresentationQuery = gql`
  query PresentationQuery($projectId: ID) {
    presentation(projectId: $projectId) {
      id
      # presentationUrl
      name
      tagline
      description
      address {
        countryCode
        city
        region
      }
      coverPicture {
        id
        url
        filename
      }
      status
      organisation: organisation {
        id
      }
    }
  }
`;

export function useFetchPresentation(projectId: string): {
  isLoading: boolean;
  presentation?: Presentation;
  error?: ErrorType;
} {
  const res = useMosaicQuery<PresentationQueryData, PresentationQueryVariables>({
    query: PresentationQuery,
    variables: { projectId },
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    presentation: res.data?.presentation,
  };
}
