import { Dayjs } from 'dayjs';

export enum ReportType {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export interface Report {
  name: string;
  dateRange: {
    start: Dayjs;
    end: Dayjs;
  };
  type: ReportType;
  downloadUrl: string;
}
