import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface ProjectCountQueryFilters {
  name?: string;
}

export interface ProjectCountQueryResult {
  projectsCount: {
    count: number;
  };
}

export interface ProjectCountQueryVariables {
  filter?: ProjectCountQueryFilters;
}

const ProjectCountQuery = gql`
  query ProjectCountQuery($filter: ProjectFilterInput!) {
    projectsCount(filter: $filter) {
      count
    }
  }
`;

export function useFetchProjectCount(
  filters: ProjectCountQueryFilters,
  options?: { pause?: boolean },
): {
  isLoading: boolean;
  count?: number;
  error?: ErrorType;
  refetchProjectCount: VoidFunction;
} {
  const res = useMosaicQuery<ProjectCountQueryResult, ProjectCountQueryVariables>({
    query: ProjectCountQuery,
    variables: { filter: filters },
    pause: options?.pause,
  });

  return {
    isLoading: res.isLoading,
    count: res.data?.projectsCount?.count,
    error: buildError(res.error),
    refetchProjectCount: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
