export enum LeadEmailActionType {
  SCHEDULING = 'SCHEDULING',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  DOWNLOAD = 'DOWNLOAD'
}

export type SingleLeadEmailActionForm = {
  buttonLabel: string,
  scheduling?: {
    includeInterests?: boolean
  },
  externalLink?: {
    url?: string
  }
  download?: {
    file?: {
      id?: string,
      filename: string,
      signedBlobId?: string,
      url: string,
    }
  }
}

export type LeadEmailActionsForm = {
  primary?: SingleLeadEmailActionForm,
  secondary?: SingleLeadEmailActionForm
}

export type UpdateLeadEmailActionFormErrors = {
  buttonLabelIsRequired?: boolean;
  licenseDoesNotSupportScheduling?: boolean,
  externalLinkUrlRequired?: boolean;
  externalLinkUrlWrongFormat?: boolean;
  downloadFileIsRequired?: boolean;
  linkToIsRequired?: boolean;
}

export type UpdateLeadEmailActionsFormErrors = {
  primary?: UpdateLeadEmailActionFormErrors;
  secondary?: UpdateLeadEmailActionFormErrors;
};

export interface LeadEmailActionsLink {
  id: string;
  label: string;
  linkType: LeadEmailActionType,
  activated: boolean,
  category: 'PRIMARY' | 'SECONDARY'
  attachment?: {
    id: string,
    filename: string,
    url: string
  },
  includeInterests?: boolean
  url?: string
}
