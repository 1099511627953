import gql from 'fraql';

import { LicenseCount } from '~/pods/meetings/types';
import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface LicenseCountQueryData {
  licenseCount: LicenseCount;
}

const LicenseCountQuery = gql`
  query LicenseCountQuery {
    licenseCount {
      availableLicenseCount
      totalLicenseCount
    }
  }
`;

export function useFetchLicenseCount(): {
  isLoading: boolean;
  licenses?: LicenseCount;
  error?: ErrorType;
  refetch: VoidFunction;
} {
  const res = useMosaicQuery<LicenseCountQueryData, null>({
    query: LicenseCountQuery,
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    licenses: res.data?.licenseCount,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
