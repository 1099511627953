import { Category, Padding, Size, Tile } from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { useLoadPercentageByTypology } from '~/pods/insights/utils/hooks/use-load-percentage-by-typology';
import { createTranslate } from '~/utils';

import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';
import { EmptyChart } from './EmptyChart';
import { Row } from './Row';

const tt = createTranslate('pods.insights_reports.components.charts');

interface VisitedTypologiesProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
  title: string;
}

export const MostVisitedTypologies = ({ units, dateRange, title }: VisitedTypologiesProps) => {
  const computed = useLoadPercentageByTypology(units, dateRange);

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{title}</ChartTitle>
      <Padding size={{ top: Size.SMALL }}>
        {computed == null ? (
          <EmptyChart message={tt('not_enough_data')} />
        ) : (
          computed.slice(0, 3).map((row, index) => {
            return (
              <Row
                key={row.id}
                left={<Text>{row.label}</Text>}
                right={
                  <Text bold category={Category.SUCCESS}>
                    {Math.round(row.value * 100)}%
                  </Text>
                }
                showSeparator={index !== computed.slice(0, 3).length - 1}
              />
            );
          })
        )}
      </Padding>
    </Tile>
  );
};
