import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import dayjs from 'dayjs';
import get from 'lodash/get';
import set from 'lodash/set';

function isValidLocale(locale: string) {
  return locale === 'en' || locale === 'fr' || locale === 'nl';
}

function getLocale() {
  const fromBrowser = navigator.languages ? navigator.languages[0] : navigator.language;
  const fromLocalStorage = localStorage.getItem('locale');
  const locale = fromLocalStorage ? fromLocalStorage : fromBrowser;
  const finalLocale = locale ? locale.replace(/-.*/, '') : 'en';
  return isValidLocale(finalLocale) ? finalLocale : 'en';
}

function changeWindowLocale(next: Function) {
  return (locale: string) => {
    const currentLocale = get(window, 'i18n.locale');
    if (currentLocale === locale) {
      return locale;
    }

    set(window, 'i18n.locale', locale);
    dayjs.locale(locale);
    localStorage.setItem('locale', locale);

    const listeners = get(window, 'i18n._listeners', []);
    for (let listener of listeners) {
      listener(locale);
    }

    return next ? next(locale) : locale;
  };
}

function onChangeLocale(next: Function) {
  const listeners = get(window, 'i18n._listeners', []);
  set(window, 'i18n._listeners', [...listeners, next]);
}

export function setupLocaleSystem(next: Function) {
  const defaultLocale = getLocale();
  dayjs.locale(defaultLocale);

  // Add change locale function to window
  set(window, 'i18n.changeLocale', changeWindowLocale(next));
  set(window, 'i18n.locale', defaultLocale);
  set(window, 'i18n.onChangeLocale', onChangeLocale);
}
