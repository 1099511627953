import { Flex, FlexItem, Padding, SelectOption, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { Timeslot } from '../types';
import { timeStringToNumber } from '../utils';
import { TimeSelect } from './TimeSelect';

interface TimeslotSelectProps {
  options: Array<SelectOption<string>>;
  timeslot: Timeslot;
  onChange: (key: string, value: string) => void;
}

export const TimeslotSelect = ({ options, timeslot, onChange }: TimeslotSelectProps) => {
  const [start, end] = [timeStringToNumber(timeslot.start), timeStringToNumber(timeslot.end)];

  const availableOptionsStart = options.map((option) => {
    const current = timeStringToNumber(option.value);
    return {
      ...option,
      disabled: current >= end,
    };
  });

  const availableOptionsEnd = options.map((option) => {
    const current = timeStringToNumber(option.value);
    return {
      ...option,
      disabled: current <= start,
    };
  });

  return (
    <Flex>
      <FlexItem>
        <TimeSelect
          options={availableOptionsStart}
          onChange={(val) => onChange('start', val)}
          value={timeslot.start}
        />


      </FlexItem>
      <FlexItem>
        <Padding size={Size.EXTRA_SMALL}>
          {' - '}
        </Padding>
      </FlexItem>
      <FlexItem>
        <TimeSelect
          options={availableOptionsEnd}
          onChange={(val) => onChange('end', val)}
          value={timeslot.end}
        />
      </FlexItem>
    </Flex>
  );
};
