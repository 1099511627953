import {
  Button,
  Category,
  Drawer,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Separator,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Title,
  Tooltip,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { Fragment, useEffect, useState } from 'react';

import { TextToClipboard } from '~/components';
import { Campaign, CampaignFromServer, CampaignToken } from '~/pods/insights/types';
import { urlWithToken } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';
import { isValidUrl } from '~/utils';

const tt = createTranslate('pods.insights.routes.campaigns');

interface CampaignRecapProps {
  campaign?: CampaignFromServer;
}

const CampaignRecap = ({ campaign }: CampaignRecapProps) => {
  if (campaign == null) {
    return null;
  }

  return (
    <Fragment>
      <Title size={3}>{tt('your_campaign')}</Title>
      <TextToClipboard text={urlWithToken(campaign.destinationUrl, campaign.token).toString()} />
      <Margin size={Size.LARGE}>
        <Separator />
      </Margin>
      <FormGroup
        label={<Label>{tt('campaign_name')}</Label>}
        input={<Text>{campaign.name}</Text>}
      />
      <Margin size={Size.DEFAULT} />
      <FormGroup
        label={<Label>{tt('destination_url')}</Label>}
        input={<Text>{campaign.destinationUrl}</Text>}
      />
      <Margin size={Size.DEFAULT} />
      <FormGroup label={<Label>{tt('source')}</Label>} input={<Text>{campaign.source}</Text>} />
    </Fragment>
  );
};

interface CampaignForm {
  name: string;
  url: string;
  source: string;
}

interface CampaignDrawerProps {
  isVisible: boolean;
  onClickClose: () => void;
  onClickCreate: (
    name: string,
    url: string,
    source: string,
  ) => Promise<CampaignFromServer | undefined>;
  onClickSave: (token: CampaignToken, name: string, source: string) => void;
  isLoading: boolean;
  campaign?: Campaign;
}

export const CampaignDrawer = ({
  isVisible,
  onClickClose,
  onClickCreate,
  onClickSave,
  campaign,
  isLoading,
}: CampaignDrawerProps) => {
  const [isUrlFieldVisited, setIsUrlFieldVisited] = useState(false);
  const [createdCampaign, setCreatedCampaign] = useState<CampaignFromServer>();
  const campaignForm = useForm<CampaignForm>({
    name: campaign?.name ?? '',
    url: campaign?.destinationUrl.toString() ?? '',
    source: campaign?.source ?? '',
  });
  const { values: formValues } = campaignForm;

  const isEditingMode = campaign != null;
  const isRecapMode = createdCampaign != null;

  useEffect(() => {
    if (isEditingMode) {
      campaignForm.set(campaign?.name!, 'name');
      campaignForm.set(campaign?.destinationUrl.toString()!, 'url');
      campaignForm.set(campaign?.source!, 'source');
    }
  }, [JSON.stringify(campaign)]);

  const urlIsValid = isValidUrl(formValues.url ?? '');
  const isSubmitDisabled =
    formValues.name === '' ||
    formValues.url === '' ||
    formValues.source === '' ||
    isLoading ||
    !urlIsValid ||
    (isEditingMode && formValues.name === campaign?.name && formValues.source === campaign?.source);

  const handleClose = () => {
    setCreatedCampaign(undefined);
    onClickClose();
  };

  const handleSubmitCampaign = async () => {
    if (isEditingMode) {
      onClickSave(campaign?.token!, campaignForm.values.name!, campaignForm.values.source!);
    } else {
      const campaign = await onClickCreate(
        campaignForm.values.name!,
        campaignForm.values.url!,
        campaignForm.values.source!,
      );
      setCreatedCampaign(campaign);
    }

    campaignForm.reset();
    setIsUrlFieldVisited(false);
  };

  return (
    <Drawer
      visible={isVisible}
      onClickClose={handleClose}
      asOverlay
      footer={
        <Flex style={{ width: '100%' }} justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={handleClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              category={Category.INFO}
              leading={isLoading ? <Spinner /> : null}
              disabled={!isRecapMode && isSubmitDisabled}
              onClick={isRecapMode ? handleClose : handleSubmitCampaign}>
              {isEditingMode || isRecapMode ? tt('save') : tt('create')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      {isRecapMode ? (
        <CampaignRecap campaign={createdCampaign} />
      ) : (
        <Fragment>
          <Title size={3}>{isEditingMode ? tt('edit_campaign') : tt('create_new_campaign')}</Title>
          <Margin size={Size.LARGE} />
          <FormGroup
            label={<Label>{`${tt('campaign_name')}*`}</Label>}
            input={
              <Input
                name="name"
                disabled={isLoading}
                value={campaignForm.get}
                onChange={campaignForm.set}
                placeholder={tt('campaign_name_placeholder')}
              />
            }
          />
          <Margin size={Size.DEFAULT} />
          <FormGroup
            label={<Label>{`${tt('destination_url')}*`}</Label>}
            input={
              <Input
                name="url"
                onBlur={() => setIsUrlFieldVisited(true)}
                valid={(!isEditingMode && urlIsValid) || !isUrlFieldVisited}
                error={urlIsValid || !isUrlFieldVisited ? false : tt('url_invalid')}
                type="url"
                disabled={isLoading || isEditingMode}
                value={campaignForm.get}
                onChange={campaignForm.set}
                placeholder={tt('url_placeholder')}
              />
            }
          />
          <Margin size={Size.DEFAULT} />
          <FormGroup
            label={
              <Flex justify={FlexJustify.START}>
                <FlexItem>
                  <Label>{`${tt('source')}*`}</Label>
                </FlexItem>
                <FlexSpacer size={Size.EXTRA_SMALL} />
                <FlexItem>
                  <Tooltip content={tt('source_hint')}>
                    <Icon shade={Shade.MEDIUM} name="info" />
                  </Tooltip>
                </FlexItem>
              </Flex>
            }
            input={
              <Input
                name="source"
                disabled={isLoading}
                value={campaignForm.get}
                onChange={campaignForm.set}
                placeholder={tt('source_placeholder')}
              />
            }
          />
        </Fragment>
      )}
    </Drawer>
  );
};
