import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Presentation } from '../../types';

interface PresentationQueryData {
  presentation: Presentation;
}

interface PresentationQueryVariables {
  projectId: string;
}

const PresentationQuery = gql`
  query PresentationQuery($projectId: ID) {
    presentation(projectId: $projectId) {
      id
      presentationUrl
      name
      status
      organisation: organisation {
        id
        logo {
          url
        }
        primaryColor
        secondaryColor
      }
    }
  }
`;

export function useFetchBasicPresentation(
  projectId?: string,
  options?: { skip: boolean },
): { isLoading: boolean; presentation?: Presentation; error?: ErrorType } {
  const res = useMosaicQuery<PresentationQueryData, PresentationQueryVariables>({
    query: PresentationQuery,
    variables: { projectId: projectId ?? '' },
    pause: options?.skip,
  });

  const isLoading =
    res.data?.presentation == null && res.error == null ? res.isLoading || !options?.skip : false;

  return {
    error: buildError(res.error),
    isLoading,
    presentation: res.data?.presentation,
  };
}
