import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { Attachment } from '~/types';
import { createTranslate, getCountryName } from '~/utils';

import { Advert } from '../domain';
import advert from '../images/advert.svg';

const tt = createTranslate('pods.multidiffusion.components.advert_panel');
const ts = createTranslate('pods.multidiffusion.progress_status');

const styles = {
  galleryPic: css`
    position: relative;
  `,
  galleryOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 90, 112, 0.8);
  `,
};

interface EmptyAdvertPanelProps {
  onClickCreateAdvert: VoidFunction;
}

const EmptyAdvertPanel = ({ onClickCreateAdvert }: EmptyAdvertPanelProps) => {
  return (
    <Flex>
      <FlexItem>
        <img src={advert} />
      </FlexItem>
      <FlexItem>
        <Flex direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <Title size={4}>{tt('cta_title')}</Title>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button category={Category.INFO} onClick={onClickCreateAdvert}>
              {tt('cta')}
            </Button>
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

interface AdvertGalleryProps {
  images: Array<Attachment>;
}

const AdvertGallery = ({ images }: AdvertGalleryProps) => {
  return (
    <Flex align={FlexAlign.STRETCH}>
      <FlexItem flex className={styles.galleryPic}>
        <img
          src={images[0]?.url}
          style={{ width: '100%', display: 'block', height: '100%', objectFit: 'cover' }}
        />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem flex className={styles.galleryPic}>
        <img
          src={images[1]?.url}
          style={{ width: '100%', display: 'block', height: '100%', objectFit: 'cover' }}
        />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem flex className={styles.galleryPic}>
        <img
          src={images[2]?.url}
          style={{ width: '100%', display: 'block', height: '100%', objectFit: 'cover' }}
        />
        {images.slice(3).length > 0 ? (
          <Flex className={styles.galleryOverlay} direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <Title size={3} noMargin>
                <Text inversed>+{images.slice(3).length}</Text>
              </Title>
            </FlexItem>
            <FlexItem>
              <Text size={Size.SMALL} inversed>
                {tt('images')}
              </Text>
            </FlexItem>
          </Flex>
        ) : null}
      </FlexItem>
    </Flex>
  );
};

interface AdvertInfoRowProps {
  label: string;
  value: ReactNode;
}

const AdvertInfoRow = ({ label, value }: AdvertInfoRowProps) => {
  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START} justify={FlexJustify.START}>
      <FlexItem>
        <Label>{label}</Label>
      </FlexItem>
      <FlexItem>
        {typeof value === 'string' ? <Text>{value ?? '--'}</Text> : value ?? '--'}
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
    </Flex>
  );
};

interface AdvertInfoProps {
  advert: Advert;
}

const AdvertInfo = ({ advert }: AdvertInfoProps) => {
  return (
    <Flex>
      <FlexItem flex style={{ width: '50%', flexShrink: 0 }}>
        <Flex align={FlexAlign.STRETCH}>
          <FlexItem flex>
            <AdvertInfoRow label={tt('labels.name')} value={advert.title ?? ''} />
            <AdvertInfoRow
              label={tt('labels.address')}
              value={
                <Text style={{ lineHeight: 1.5 }}>
                  {advert.street ? `${advert.street},` : ''} {advert.streetNumber}
                  {advert.street || advert.streetNumber ? <br /> : null}
                  {advert.zipCode} {advert.city}
                  <br />
                  {getCountryName(advert.countryCode!)}
                </Text>
              }
            />
          </FlexItem>
          <FlexSpacer size={Size.LARGE} />
          <FlexItem flex>
            <AdvertInfoRow
              label={tt('labels.delivery_date')}
              value={
                advert.deliveryDate != null
                  ? new Date(advert.deliveryDate).toLocaleDateString(window.i18n.locale)
                  : '--'
              }
            />
            <AdvertInfoRow
              label={tt('labels.progress_status')}
              value={advert.progressStatus != null ? ts(advert.progressStatus) : '--'}
            />
            <AdvertInfoRow
              label={tt('labels.units_displayed')}
              value={
                advert.displayedUnits.length > 3
                  ? `${advert.displayedUnits.slice(0, 3).join(', ')}, +${
                      advert.displayedUnits.slice(3).length
                    } units`
                  : advert.displayedUnits.join(', ')
              }
            />
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem flex style={{ width: '50%', flexShrink: 0 }}>
        <AdvertGallery images={advert.images} />
      </FlexItem>
    </Flex>
  );
};

interface AdvertPanelProps {
  advert?: Advert;
  onClickCreateAdvert: VoidFunction;
  onClickEdit: VoidFunction;
}

export const AdvertPanel = ({ advert, onClickCreateAdvert, onClickEdit }: AdvertPanelProps) => {
  return (
    <Panel
      header={
        <PanelHeader>
          <Flex>
            <FlexItem flex>
              <Title size={4} noMargin>
                {tt('title')}
              </Title>
            </FlexItem>
            <FlexItem>
              <Button tier={Tier.SECONDARY} disabled={advert == null} onClick={onClickEdit}>
                {tt('edit_button')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          {advert == null ? (
            <EmptyAdvertPanel onClickCreateAdvert={onClickCreateAdvert} />
          ) : (
            <AdvertInfo advert={advert} />
          )}
        </PanelBody>
      }
    />
  );
};
