import sv from '@drawbotics/drylus-style-vars';
import { tooltipStyles } from '@drawbotics/react-drylus';
import { ResponsivePie } from '@nivo/pie';
import { css, cx } from 'emotion';
import findIndex from 'lodash/findIndex';
import startCase from 'lodash/startCase';
import React from 'react';

import lead from '~/pods/crm/images/lead.svg';
import { Step, StepType } from '~/pods/crm/types';
import { HexColor } from '~/types';

const dimensions = 80;

const styles = {
  statusWheel: css`
    display: inline-block;
    position: relative;
    padding-top: ${sv.paddingSmall};
    padding-bottom: ${sv.paddingSmall};
  `,
  person: css`
    height: ${dimensions}px;
    width: ${dimensions}px;
    border-radius: 1000px;
    background: ${sv.white};
    border: 1px solid ${sv.neutralLight};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  tooltip: css`
    position: relative;
    white-space: nowrap;

    &::after {
      content: none;
    }
  `,
  wheel: css`
    position: absolute;
    top: 4px;
    right: calc((${sv.marginSmall} - 4px) * -1);
    height: calc(${dimensions}px + 24px);
    width: calc(${dimensions}px + 24px);
  `,
};

type PieData = Array<{
  id: string;
  label: string;
  value: number;
  color: HexColor;
}>;

export const PlaceholderWheel = () => {
  return (
    <div className={styles.statusWheel}>
      <div className={styles.person}>
        <img src={lead} />
      </div>
      <div className={styles.wheel}>
        <ResponsivePie
          isInteractive={false}
          colors={{ datum: 'color' }}
          enableSlicesLabels={false}
          enableRadialLabels={false}
          innerRadius={0.89}
          padAngle={2}
          data={Array.from(Array(6)).map((_, i) => ({
            id: `${i}`,
            label: '',
            value: 1 / 6,
            color: sv.neutral,
          }))}
        />
      </div>
    </div>
  );
};

interface StatusWheelProps {
  steps: Array<Step>;
  activeStep: Step;
}

export const StatusWheel = ({ steps, activeStep }: StatusWheelProps) => {
  const sortedSteps = steps
    .slice()
    .filter((step) => step.type !== StepType.LOST && step.type !== StepType.INITIAL)
    .sort((a, b) => a.position - b.position);
  const isLost = activeStep.type === StepType.LOST;

  const data: PieData = sortedSteps.map((step, i) => {
    if (isLost) {
      return {
        id: step.id,
        label: i === 0 ? startCase(activeStep.name) : '',
        value: 1 / steps.length,
        color: i === 0 ? activeStep.color : sv.neutral,
      };
    }
    return {
      id: step.id,
      label: startCase(step.name),
      value: 1 / steps.length,
      color: findIndex(steps, (step) => step.id === activeStep.id) > i ? step.color : sv.neutral,
    };
  });

  return (
    <div className={styles.statusWheel}>
      <div className={styles.person}>
        <img src={lead} />
      </div>
      <div className={styles.wheel}>
        <ResponsivePie
          theme={{
            tooltip: {
              container: {
                padding: 0,
                background: 'none',
                borderRadius: 0,
                boxShadow: 'none',
              },
            },
          }}
          tooltip={({ label }) =>
            label === '' ? null : (
              <div className={cx(tooltipStyles.root, styles.tooltip, tooltipStyles.visible)}>
                {label}
              </div>
            )
          }
          animate // Broken at lib level https://github.com/plouc/nivo/issues/39
          colors={{ datum: 'color' }}
          enableSlicesLabels={false}
          enableRadialLabels={false}
          innerRadius={0.89}
          padAngle={2}
          data={data}
        />
      </div>
    </div>
  );
};
