import sv from '@drawbotics/drylus-style-vars';
import { Icon, Size } from '@drawbotics/react-drylus';
import { Style } from '@drawbotics/react-drylus/lib/types';
import { css, cx } from 'emotion';
import React from 'react';

const dimensions = {
  width: 80,
  height: 56,
};

const styles = {
  thumbnail: css`
    padding: ${sv.paddingSmall};
    width: ${dimensions.width}px;
    height: ${dimensions.height}px;
    overflow: hidden;
    border-radius: ${sv.defaultBorderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${sv.neutralLight};
    color: ${sv.colorSecondary};

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  large: css`
    width: ${dimensions.width * 1.5}px;
    height: ${dimensions.height * 1.5}px;
  `,
  small: css`
    width: ${dimensions.width * 0.7}px;
    height: ${dimensions.height * 0.7}px;
  `,
};

interface ThumbnailProps {
  size?: Size.DEFAULT | Size.LARGE | Size.SMALL;
  image?: string;
  style?: Style;
}

export const Thumbnail = ({ size = Size.DEFAULT, image, style }: ThumbnailProps) => {
  return (
    <div
      style={style}
      className={cx(
        styles.thumbnail,
        { [styles.large]: size === Size.LARGE },
        { [styles.small]: size === Size.SMALL },
      )}>
      {image == null ? <Icon name="image" /> : <img src={image} />}
    </div>
  );
};
