import { ButtonLink, Category, EmptyState, EmptyStateVariation } from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorType } from '~/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('components.error_placeholder');

function _getEmptyStateVariation(error: ErrorType) {
  switch (error) {
    case ErrorType.NOT_FOUND:
      return EmptyStateVariation.NOT_FOUND;
    case ErrorType.NOT_AUTHORIZED:
      return EmptyStateVariation.NOT_ALLOWED;
    default:
      return EmptyStateVariation.FAILED;
  }
}

interface ErrorPlaceholderProps {
  error?: ErrorType;
}

export const ErrorPlaceholder = ({ error }: ErrorPlaceholderProps) => {
  if (error === ErrorType.NOT_FOUND || error === ErrorType.NOT_AUTHORIZED) {
    return (
      <EmptyState
        variation={_getEmptyStateVariation(error)}
        title={tt('empty_state_title')}
        description={tt('error_msg_40x')}>
        <Link to="/">
          <ButtonLink category={Category.INFO}>{tt('back_home')}</ButtonLink>
        </Link>
      </EmptyState>
    );
  } else {
    return (
      <EmptyState
        variation={EmptyStateVariation.FAILED}
        title={tt('empty_state_title')}
        description={tt('error_msg_500')}>
        <Link to="/">
          <ButtonLink category={Category.INFO}>{tt('back_home')}</ButtonLink>
        </Link>
      </EmptyState>
    );
  }
};
