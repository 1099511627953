import { ResponsiveLine } from '@nivo/line';
import { css } from 'emotion';
import React from 'react';

import { LineData } from '~/pods/insights/types';

const styles = {
  graphContainer: css`
    width: 140px;
    height: 70px;
  `,
};

const MARGIN = {
  top: 2,
  bottom: 2,
  left: 2,
  right: 2,
};

interface CircularChartProps {
  color: string;
  data: LineData;
  width: string;
  height: string;
}

const CircularChart = ({ color, data, width, height }: CircularChartProps) => {
  return (
    <div className={styles.graphContainer} style={{ width, height }}>
      <ResponsiveLine
        data={[{ color, id: 'line', data }]}
        curve="monotoneX"
        enableArea={true}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        isInteractive={false}
        margin={MARGIN}
        lineWidth={2}
        colors={{ datum: 'color' }}
      />
    </div>
  );
};

export default CircularChart;
