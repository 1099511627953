import gql from 'fraql';

export const UpdateWebsiteMutation = gql`
  mutation UpdateWebsite($data: UpdateWebsiteInput!) {
    updateWebsite(input: $data) {
      website {
        id
        logo {
          id
          filename
          url
        }
        favicon {
          id
          filename
          url
        }
        openGraphAttachment {
          id
          filename
          url
        }
      }
    }
  }
`;
