import {
  Button,
  Dropdown,
  Icon,
  ListTile,
  Margin,
  Padding,
  Position,
  Size,
  Tier,
  Toggle,
} from '@drawbotics/react-drylus';
import React, { useEffect, useState } from 'react';

import { createTranslate, isShallowEqual } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.display_options');

export interface Options {
  displayListingPlans: boolean;
  displayListingPrice: boolean;
  displayListingStatus: boolean;
  displayListingSurface: boolean;
  displayListingRooms: boolean;
  displayListingBedrooms: boolean;
  allowListingPlansDownload: boolean;
  hideSoldUnits: boolean;
}

interface DisplayOptionsProps {
  options: Options;
  onChange: (options: Options) => void;
}

export const DisplayOptions = ({ options, onChange }: DisplayOptionsProps) => {
  const [localOptions, setLocalOptions] = useState<Options>(options);

  const handleChangeOptions = (value: boolean, name?: string) => {
    if (name != null) {
      const newOptions = { ...localOptions, [name]: value };
      setLocalOptions(newOptions);
      onChange(newOptions);
    }
  };

  useEffect(() => {
    if (!isShallowEqual(options, localOptions)) {
      setLocalOptions(options);
    }
  }, [...Object.values(options)]);

  return (
    <Dropdown
      side={Position.LEFT}
      trigger={
        <Button
          tier={Tier.SECONDARY}
          leading={<Icon name="settings" />}
          trailing={<Icon name="chevron-down" />}>
          {tt('display_options')}
        </Button>
      }>
      <Padding
        size={{ vertical: Size.EXTRA_SMALL, horizontal: Size.SMALL }}
        style={{ whiteSpace: 'nowrap' }}>
        <ListTile
          title={tt('rooms')}
          leading={
            <Toggle
              name="displayListingRooms"
              onChange={handleChangeOptions}
              value={localOptions.displayListingRooms}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('bedrooms')}
          leading={
            <Toggle
              name="displayListingBedrooms"
              onChange={handleChangeOptions}
              value={localOptions.displayListingBedrooms}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('status')}
          leading={
            <Toggle
              name="displayListingStatus"
              onChange={handleChangeOptions}
              value={localOptions.displayListingStatus}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('price')}
          leading={
            <Toggle
              name="displayListingPrice"
              onChange={handleChangeOptions}
              value={localOptions.displayListingPrice}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('surface')}
          leading={
            <Toggle
              name="displayListingSurface"
              onChange={handleChangeOptions}
              value={localOptions.displayListingSurface}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('plan_view')}
          leading={
            <Toggle
              name="displayListingPlans"
              onChange={handleChangeOptions}
              value={localOptions.displayListingPlans}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('plan_download')}
          leading={
            <Toggle
              name="allowListingPlansDownload"
              onChange={handleChangeOptions}
              value={localOptions.allowListingPlansDownload}
            />
          }
        />
        <Margin size={{ top: Size.SMALL }} />
        <ListTile
          title={tt('hide_sold_units')}
          leading={
            <Toggle
              name="hideSoldUnits"
              onChange={handleChangeOptions}
              value={localOptions.hideSoldUnits}
            />
          }
        />
      </Padding>
    </Dropdown>
  );
};
