import React from 'react';

import { InsightsSession } from '~/pods/insights/types';
import { generateBarChartData } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction.sources_chart');

interface SourcesChartProps {
  sessions: Array<InsightsSession>;
}

export const SourcesChart = ({ sessions }: SourcesChartProps) => {
  const sourcesData = generateBarChartData(sessions, 'referrer');

  return (
    <HorizontalTractionBarChart
      label={tt('sources')}
      tooltip={tt('sources_tooltip')}
      data={sourcesData}
    />
  );
};
