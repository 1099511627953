import { Category, Color, Icon, IconType, ListTile, Size, Text } from '@drawbotics/react-drylus';
import React from 'react';

interface DeltaProps {
  text: string;
  category: Category.DANGER | Category.SUCCESS;
  iconName?: IconType;
}

export const Delta = ({ text, category, iconName }: DeltaProps) => {
  const color = category === Category.DANGER ? Color.RED : Color.GREEN;
  const icon = iconName != null ? <Icon name={iconName} color={color} /> : undefined;
  const growthText = (
    <Text light category={category} size={Size.SMALL}>
      {text}
    </Text>
  );
  return <ListTile leading={icon} title={growthText} />;
};
