import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import { useEffect } from 'react';
import { OperationResult } from 'urql';

import { Project } from '~/pods/crm/types';
import { createTranslate, extractGQLErrorMessages } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

const tt = createTranslate('pods.project_settings.utils.hooks.update_project');

interface UpdateProjectVariables {
  project: {
    id: string;
    leadNotificationSetting?: boolean;
    leadNotificationEmails?: Array<String>;
  };
}

interface UpdateProjectPayload {
  updateProject: {
    project: Project;
  };
}

const updateProjectMutation = gql`
  mutation updateProject($project: UpdateProjectInput!) {
    updateProject(input: $project) {
      project {
        id
        leadNotificationSetting
        leadNotificationEmails
      }
    }
  }
`;

export function useUpdateProject(): {
  isLoading: boolean;
  updateProject: (project: UpdateProjectVariables['project']) => Promise<OperationResult>;
} {
  const { res, executeMutation: updateProject } = useMosaicMutation<
    UpdateProjectPayload,
    UpdateProjectVariables
  >(updateProjectMutation);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!res.fetching && res.error == null && res.data != null) {
      showAlert({ text: tt('success'), category: Category.SUCCESS });
    } else if (!res.fetching && res.error != null) {
      const { message } = extractGQLErrorMessages(res.error);
      const text = message ?? tt('error');
      showAlert({ text, category: Category.DANGER });
    }
  }, [res.fetching]);

  return {
    isLoading: res.fetching,
    updateProject: (project: UpdateProjectVariables['project']) => updateProject({ project }),
  };
}
