import sv from '@drawbotics/drylus-style-vars';
import { Category, Padding, Size } from '@drawbotics/react-drylus';
import { Dayjs } from 'dayjs';
import { css } from 'emotion';
import React from 'react';

import { InsightsEstate } from '~/pods/insights/types';
import { useLoadSoldUnitsOverRange } from '~/pods/insights/utils';
import { createTranslate } from '~/utils/translation';

import { Delta } from '../../../Dashboard/components/Delta';
import { Text } from '../Text';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';
import { SmallChart } from './SmallChart';

const tt = createTranslate('pods.insights_reports.components.charts');

const styles = {
  verticalContainer: css`
    display: flex;
    align-items: center;
  `,
  horizontalContainer: css`
    display: flex;
    flex-direction: column;
  `,
};

interface UnitsSoldProps {
  units: InsightsEstate['units'];
  dateRange: {
    start: Dayjs;
    end: Dayjs;
  };
  title: string;
  small?: boolean;
}

export const UnitsSold = ({ units, dateRange, small, title }: UnitsSoldProps) => {
  const { data, amount, delta } = useLoadSoldUnitsOverRange(units, dateRange);
  return (
    <ChartTile>
      <ChartTitle>{title}</ChartTitle>
      <div className={small ? styles.verticalContainer : styles.horizontalContainer}>
        <Padding size={{ top: Size.EXTRA_SMALL, right: small ? Size.SMALL : undefined }}>
          <Text light style={{ fontSize: '22pt' }}>
            {amount}
          </Text>
        </Padding>
        {!small && (
          <div style={{ paddingBottom: 2 }}>
            <Delta
              iconName={delta.startsWith('+') ? 'arrow-up-right' : 'arrow-down-right'}
              category={delta.startsWith('+') ? Category.SUCCESS : Category.DANGER}
              text={tt('units_sold.delta', { delta })}
            />
          </div>
        )}
        <div
          style={{
            position: 'relative',
            top: 1,
            width: small ? '100px' : '100%',
            height: small ? '35px' : '28px',
          }}>
          <SmallChart color={sv.blue} height={small ? 28 : 35} data={data} />
        </div>
      </div>
    </ChartTile>
  );
};
