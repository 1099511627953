import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Dropdown,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  InputWithRef,
  Label,
  Margin,
  NumberInput,
  Padding,
  Position,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import { Style } from '@drawbotics/react-drylus/lib/types';
import { isFunction } from '@drawbotics/react-drylus/lib/utils';
import { css } from 'emotion';
import React, { useEffect, useRef, useState } from 'react';

const styles = {
  root: css`
    span {
      color: ${sv.colorPrimary} !important;
    }
  `,
};

export interface TimeObject {
  hour: number;
  minute: number;
}

function _renderTime(value: string | number): string {
  if (value === 0) {
    return `O${value}`;
  } else if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
}

export interface TimeInputProps<T = string> {
  value: ((name?: T) => TimeObject) | TimeObject;
  onChange?: (value: TimeObject, name?: T) => void;
  name?: T;
  disabled?: boolean;
  placeholder?: string;
  loading?: boolean;
  align?: Position;
  style?: Style;
}

export const TimeInput = <T extends string>({
  value: _value,
  onChange,
  disabled,
  placeholder,
  align = Position.BOTTOM,
  style,
  loading,
  name,
}: TimeInputProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const value = isFunction(_value) ? _value(name) : _value;
  const [localValue, setLocalValue] = useState<TimeObject>(value);

  useEffect(() => {
    if (value.hour !== localValue.hour || value.minute !== localValue.minute) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div style={style} className={styles.root}>
      <Dropdown
        style={{ width: '100%' }}
        side={align}
        trigger={
          <InputWithRef
            ref={inputRef}
            trailing={
              onChange != null ? (
                <Button
                  disabled={disabled}
                  leading={<Icon name="clock" />}
                  onClick={() => inputRef.current?.focus()}
                />
              ) : null
            }
            value={`${_renderTime(value.hour)}:${_renderTime(value.minute)}`}
            loading={loading}
            placeholder={placeholder}
            onChange={() => {}}
          />
        }>
        <Padding size={{ horizontal: Size.SMALL, vertical: Size.EXTRA_SMALL }}>
          <Margin size={{ bottom: Size.EXTRA_SMALL }}>
            <Label>select time</Label>
          </Margin>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <NumberInput
                max={24}
                min={-1}
                style={{ minWidth: 100 }}
                value={localValue.hour}
                onChange={(v) => {
                  const finalValue = v === 24 ? 0 : v === -1 ? 23 : Number(v);
                  typeof v === 'number' ? setLocalValue({ ...localValue, hour: finalValue }) : null;
                }}
                renderValue={_renderTime}
              />
            </FlexItem>
            <FlexItem>
              <Margin size={{ horizontal: Size.EXTRA_SMALL }}>
                <Text>:</Text>
              </Margin>
            </FlexItem>
            <FlexItem>
              <NumberInput
                max={60}
                min={-5}
                step={5}
                renderValue={_renderTime}
                style={{ minWidth: 100 }}
                value={localValue.minute}
                onChange={(v) => {
                  const finalValue = v === 60 ? 0 : v === -5 ? 55 : Number(v);
                  typeof v === 'number'
                    ? setLocalValue({ ...localValue, minute: finalValue })
                    : null;
                }}
              />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Button
                onClick={() => {
                  onChange?.(localValue, name);
                  document.dispatchEvent(new Event('mousedown'));
                }}
                category={Category.INFO}>
                OK
              </Button>
            </FlexItem>
          </Flex>
        </Padding>
      </Dropdown>
    </div>
  );
};
