import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  Icon,
  ListTile,
  LoadingPlaceholder,
  Margin,
  SegmentedControl,
  SegmentedControlItem,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { useScreenSize } from '@drawbotics/use-screen-size';
import { css } from 'emotion';
import React, { Fragment, ReactNode, useState } from 'react';

import { MosaicPageTitle } from '~/components';
import { TypologyFormat } from '~/types';
import { createTranslate, run } from '~/utils';

import { Availability, InsightsEstate, Project } from '../../types';
import { GoalsModal, MissingInfoBanner } from './components';
import {
  InsufficientDataPanel,
  MissingPricesPanel,
  SoldOverTimePanel,
  TypologyBreakdownPanel,
  UnitGoalPanel,
} from './components/Panels';

const tt = createTranslate('pods.insights.routes.deals');

const FIRST_ROW_HEIGHT = 350;
const FIRST_ROW_HEIGHT_PX = `${FIRST_ROW_HEIGHT}px`;
const SECOND_ROW_HEIGHT = 380;
const SECOND_ROW_HEIGHT_PX = `${SECOND_ROW_HEIGHT}px`;

const styles = {
  spacer: css`
    height: ${sv.baseMargin};
    display: none;

    @media ${sv.screenL} {
      display: block;
    }
  `,
};

interface DealsLayoutProps {
  title: string;
  controls?: Array<ReactNode>;
  unitGoalPanel?: ReactNode;
  dealsBreakdownPanel?: ReactNode;
  unitsSoldPanel?: ReactNode;
  emptyStatePanel?: ReactNode;
  banner?: ReactNode;
}

const DealsLayout = ({
  title,
  controls,
  unitGoalPanel,
  dealsBreakdownPanel,
  unitsSoldPanel,
  emptyStatePanel,
  banner,
}: DealsLayoutProps) => {
  const { screenSize, ScreenSizes } = useScreenSize();

  return (
    <Fragment>
      {/* // Title and controls */}
      <Flex
        justify={FlexJustify.SPACE_BETWEEN}
        responsive={{ L: { direction: FlexDirection.VERTICAL, align: FlexAlign.START } }}>
        <FlexItem>
          <MosaicPageTitle>{title}</MosaicPageTitle>
        </FlexItem>
        <FlexSpacer flex responsive={{ L: { direction: FlexDirection.VERTICAL, flex: false } }} />
        {controls?.map((element, index) => {
          return (
            <Fragment key={index}>
              <FlexItem>{element}</FlexItem>
              {index !== controls.length - 1 ? (
                // todo: Specifying the direction on the spacer should not be necessary
                <FlexSpacer
                  direction={
                    screenSize <= ScreenSizes.L ? FlexDirection.VERTICAL : FlexDirection.HORIZONTAL
                  }
                  size={Size.DEFAULT}
                />
              ) : null}
            </Fragment>
          );
        })}
      </Flex>

      {/* Banner */}
      {banner != null ? (
        <Margin size={{ bottom: Size.DEFAULT }} responsive={{ L: { size: { top: Size.DEFAULT } } }}>
          {banner}
        </Margin>
      ) : null}

      <div className={styles.spacer} />

      {/* Panels */}
      {run(() => {
        if (emptyStatePanel != null) {
          return emptyStatePanel;
        } else {
          return (
            <Grid columns={4} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
              <GridItem
                style={{ height: FIRST_ROW_HEIGHT_PX }}
                // todo: GridItem doesn't have responsive props
                span={screenSize <= ScreenSizes.XL ? 4 : 1}>
                {unitGoalPanel}
              </GridItem>
              <GridItem
                style={{ height: FIRST_ROW_HEIGHT_PX }}
                // todo: GridItem doesn't have responsive props
                span={screenSize <= ScreenSizes.XL ? 4 : 3}>
                {dealsBreakdownPanel}
              </GridItem>
              <GridItem style={{ height: SECOND_ROW_HEIGHT_PX }} span={4}>
                {unitsSoldPanel}
              </GridItem>
            </Grid>
          );
        }
      })}
    </Fragment>
  );
};

interface DealsProps {
  isLoading: boolean;
  estate?: InsightsEstate;
  availableTypologies: Array<string>;
  project?: Project;
  refetchProject: VoidFunction;
  typologyFormat?: TypologyFormat;
}

export const Deals = ({
  estate,
  availableTypologies,
  isLoading,
  project,
  refetchProject,
  typologyFormat,
}: DealsProps) => {
  const [selectedMetric, setSelectedMetric] = useState<'units' | 'revenue'>('units');
  const [isGoalsModalVisible, setIsGoalsModalVisible] = useState(false);

  if (isLoading) {
    return (
      <DealsLayout
        title={tt('deals')}
        controls={[
          <LoadingPlaceholder key={1} width={200} height={40} />,
          <LoadingPlaceholder key={2} width={160} height={40} />,
        ]}
        unitGoalPanel={<UnitGoalPanel isLoading />}
        dealsBreakdownPanel={<TypologyBreakdownPanel isLoading />}
        unitsSoldPanel={<SoldOverTimePanel isLoading />}
      />
    );
  }

  if (estate == null || project == null) {
    console.error('Invariant violated: `estate` or `project` is null even though loading is over');
    return null;
  }

  const { units } = estate;

  const showMissingPricesPanel = units.every((u) => u.price.value == null || u.price.value === 0);
  const showInsufficientDataPanel =
    units.length === 0 || units.every((u) => u.availability === Availability.AVAILABLE);
  const filteredUnits = units.filter((u) => u.availability !== Availability.UNAVAILABLE);

  return (
    <Fragment>
      <DealsLayout
        title={tt('deals')}
        banner={<MissingInfoBanner estate={estate} />}
        controls={[
          <SegmentedControl key={1}>
            <SegmentedControlItem
              onClick={() => setSelectedMetric('units')}
              active={selectedMetric === 'units'}
              text={tt('unit_goal')}
            />
            <SegmentedControlItem
              onClick={() => setSelectedMetric('revenue')}
              active={selectedMetric === 'revenue'}
              text={tt('revenue_goal')}
            />
          </SegmentedControl>,
          <Button key={2} tier={Tier.SECONDARY} onClick={() => setIsGoalsModalVisible(true)}>
            <ListTile leading={<Icon name="settings" />} title={tt('goals_settings')} />
          </Button>,
        ]}
        emptyStatePanel={run(() => {
          if (showInsufficientDataPanel) {
            return <InsufficientDataPanel openModal={() => setIsGoalsModalVisible(true)} />;
          } else if (showMissingPricesPanel && selectedMetric === 'revenue') {
            return <MissingPricesPanel />;
          }
        })}
        unitGoalPanel={<UnitGoalPanel units={filteredUnits} metric={selectedMetric} />}
        dealsBreakdownPanel={
          <TypologyBreakdownPanel
            units={filteredUnits}
            availableTypologies={availableTypologies}
            metric={selectedMetric}
            typologyFormat={typologyFormat}
          />
        }
        unitsSoldPanel={
          <SoldOverTimePanel
            units={filteredUnits}
            availableTypologies={availableTypologies}
            metric={selectedMetric}
            project={project}
          />
        }
      />
      <GoalsModal
        isVisible={isGoalsModalVisible}
        onClickClose={() => setIsGoalsModalVisible(false)}
        project={project}
        refetch={refetchProject}
      />
    </Fragment>
  );
};
