import { pickBy, toPlainObject } from 'lodash';

import { ID } from '~/types';

import { Advantages, EligibilityCertificate, QualityCertificate, Unit } from '../types';

interface Attachment {
  alt?: string;
  contentType?: string;
  filename: string;
  id: ID;
  position?: number;
  url: string;
}

export interface AdvertData {
  projectPublicToken: ID;
  images: Array<Attachment>;
  documents: Array<Attachment>;
  qualityCertificates: Array<QualityCertificate>;
  eligibilityCertificates: Array<EligibilityCertificate>;
  advantages: Array<Advantages>;
  progressStatus?: string;
  city?: string;
  countryCode?: string;
  deliveryDate?: string;
  description?: string;
  reference?: string;
  region?: string;
  street?: string;
  streetNumber?: string;
  title?: string;
  videoUrl?: string;
  zipCode?: string;
  listing?: Array<string>;
  unitIds?: Array<string>;
  id?: ID;
  displaySoldUnits?: boolean;
  listingAttributes?: {
    availability?: boolean;
    bathrooms?: boolean;
    bedrooms?: boolean;
    rooms?: boolean;
    surface?: boolean;
    terraceSurface?: boolean;
  };
}

export class Advert {
  public static fromData(projectPublicToken: string, data: Partial<AdvertData>): Advert {
    return new Advert(
      projectPublicToken,
      data.images ?? [],
      data.documents ?? [],
      data.qualityCertificates ?? [],
      data.eligibilityCertificates ?? [],
      data.advantages ?? [],
      data.progressStatus,
      data.city,
      data.countryCode,
      data.deliveryDate,
      data.description,
      data.reference,
      data.region,
      data.street,
      data.streetNumber,
      data.title,
      data.videoUrl,
      data.zipCode,
      data.id,
      data.unitIds,
      data.displaySoldUnits,
      data.listingAttributes,
    );
  }

  private _units = [] as Array<Unit>;

  get displayedUnits(): Array<string> {
    return this._units.filter((u) => this.unitIds.includes(u.id)).map((u) => u.reference);
  }

  get images(): Array<Attachment> {
    return this._images.slice().sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
  }

  get units(): Array<Unit> {
    return this._units;
  }

  constructor(
    public readonly projectPublicToken: string,
    public readonly _images: Array<Attachment>,
    public readonly documents: Array<Attachment>,
    public readonly qualityCertificates: Array<QualityCertificate>,
    public readonly eligibilityCertificates: Array<EligibilityCertificate>,
    public readonly advantages: Array<Advantages>,
    public readonly progressStatus?: string,
    public readonly city?: string,
    public readonly countryCode?: string,
    public readonly deliveryDate?: string,
    public readonly description?: string,
    public readonly reference?: string,
    public readonly region?: string,
    public readonly street?: string,
    public readonly streetNumber?: string,
    public readonly title?: string,
    public readonly videoUrl?: string,
    public readonly zipCode?: string,
    public readonly id?: ID,
    public readonly unitIds = [] as Array<string>,
    public readonly displaySoldUnits = false,
    public readonly listingAttributes = {},
  ) { }

  public setUnits(units: Array<Unit>): void {
    this._units = units;
  }

  public toData(): AdvertData {
    return pickBy(toPlainObject(this), (_, k) => !k.startsWith('_')) as AdvertData;
  }
}
