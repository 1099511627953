import {
  Avatar,
  Button,
  ButtonLink,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Position,
  Shade,
  Size,
  Table,
  Tag,
  Text,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import { ShowDateTime, run } from '@drawbotics/react-drylus/lib/utils';
import { Filter } from '@drawbotics/use-filters';
import copyToClipboard from 'copy-to-clipboard';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Meeting, MeetingLocation } from '~/pods/meetings/types';
import { downloadICalFile, getColorForMeeting, isMeetingUnactionable } from '~/pods/meetings/utils';
import { createTranslate, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meetings.components.meetings_table');
const ta = createTranslate('pods.marketing_suite');

interface MeetingsTableProps {
  sortDirection: Filter;
  meetings?: Array<Meeting>;
  loading: boolean;
  onClickNew: VoidFunction;
}

export const MeetingsTable = ({
  meetings,
  loading,
  sortDirection,
  onClickNew,
}: MeetingsTableProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  const { showAlert } = useAlert();

  const handleSortTable = () => {
    const direction = sortDirection.value ?? 'desc';
    sortDirection.set(direction === 'desc' ? 'asc' : 'desc');
  };

  const data =
    loading || meetings == null
      ? undefined
      : meetings.map((meeting) => {
          const isUnactionableMeeting = isMeetingUnactionable(meeting);
          return {
            id: meeting.id,
            prospect: (
              <ListTile
                title={<Text disabled={isUnactionableMeeting}>{meeting.lead.fullName}</Text>}
                subtitle={
                  <Text disabled={isUnactionableMeeting} shade={Shade.MEDIUM} size={Size.SMALL}>
                    {meeting.lead.email}
                  </Text>
                }
                leading={
                  <Tooltip content={tt('see_in_crm')} side={Position.RIGHT}>
                    <Link to={getUrlInOrg('/crm', meeting.lead.id)}>
                      <ButtonLink
                        category={Category.INFO}
                        tier={Tier.SECONDARY}
                        size={Size.SMALL}
                        leading={<Icon name="users" />}
                      />
                    </Link>
                  </Tooltip>
                }
              />
            ),
            date: (
              <ListTile
                title={
                  <Text
                    disabled={isUnactionableMeeting}
                    dateOptions={{ showTime: ShowDateTime.ALWAYS }}>
                    {new Date(meeting.date)}
                  </Text>
                }
                leading={
                  <Tooltip content={tt('add_to_calendar')} side={Position.BOTTOM}>
                    <Button
                      disabled={isUnactionableMeeting}
                      category={Category.INFO}
                      tier={Tier.SECONDARY}
                      size={Size.SMALL}
                      onClick={() => downloadICalFile(meeting)}
                      leading={<Icon name="calendar" />}
                    />
                  </Tooltip>
                }
              />
            ),
            sales: (
              <ListTile
                title={<Text disabled={isUnactionableMeeting}>{meeting.user.fullName}</Text>}
                leading={
                  <Avatar
                    style={isUnactionableMeeting ? { opacity: 0.5 } : undefined}
                    text={meeting.user.fullName[0]}
                    image={meeting.user.profilePicture?.url}
                  />
                }
              />
            ),
            details: (
              <Link to={getUrlInOrg('/meetings', meeting.id)}>
                <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY}>
                  {tt('see_details')}
                </ButtonLink>
              </Link>
            ),
            meeting: isUnactionableMeeting ? (
              <Tag color={getColorForMeeting(meeting)}>
                {ta(`meeting_status.${meeting.status.toLowerCase()}`)}
              </Tag>
            ) : (
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  {run(() => {
                    if (meeting.location === MeetingLocation.PHYSICAL) {
                      return (
                        <a
                          href={meeting.evolutiveBookletUrl}
                          target="_blank"
                          rel="noopener noreferrer">
                          <ButtonLink
                            disabled={meeting.evolutiveBookletUrl == null}
                            category={Category.INFO}
                            size={Size.SMALL}>
                            {tt('open_presentation')}
                          </ButtonLink>
                        </a>
                      );
                    } else if (meeting.location === MeetingLocation.REMOTE) {
                      return (
                        <a href={meeting.meetingLink} target="_blank" rel="noopener noreferrer">
                          <ButtonLink
                            disabled={meeting.meetingLink == null}
                            category={Category.INFO}
                            size={Size.SMALL}>
                            {tt('go_to_meeting')}
                          </ButtonLink>
                        </a>
                      );
                    }
                  })}
                </FlexItem>
                <FlexSpacer size={Size.EXTRA_SMALL} />
                <FlexItem>
                  <Tooltip
                    content={
                      meeting.location === MeetingLocation.PHYSICAL
                        ? tt('copy_address')
                        : tt('copy_link')
                    }
                    side={Position.BOTTOM}>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          meeting.location === MeetingLocation.PHYSICAL
                            ? meeting.address!
                            : meeting.meetingLink!,
                        );
                        showAlert({
                          text:
                            meeting.location === MeetingLocation.PHYSICAL
                              ? tt('copy_address_success')
                              : tt('copy_meeting_link_success'),
                          category: Category.SUCCESS,
                        });
                      }}
                      category={Category.INFO}
                      size={Size.SMALL}
                      tier={Tier.SECONDARY}
                      leading={<Icon name="copy" />}
                    />
                  </Tooltip>
                </FlexItem>
              </Flex>
            ),
          };
        });

  return (
    <Fragment>
      <Table
        isLoading={loading}
        activeHeader={
          sortDirection.value != null
            ? { key: 'date', direction: sortDirection.value as 'asc' | 'desc' }
            : undefined
        }
        onClickHeader={handleSortTable}
        sortableBy={['date']}
        data={data}
        emptyContent={run(() => {
          if (data == null) {
            return (
              <EmptyState
                variation={EmptyStateVariation.FAILED}
                description={tt('something_went_wrong')}
                title={tt('could_not_load')}
              />
            );
          } else if (data.length === 0 && !loading) {
            return (
              <EmptyState description="This table is empty">
                <Button onClick={onClickNew} category={Category.INFO}>
                  {tt('create_first_meeting')}
                </Button>
              </EmptyState>
            );
          }
        })}
        header={[
          { label: tt('lead'), value: 'prospect' },
          { label: tt('date_time'), value: 'date' },
          { label: tt('sales'), value: 'sales' },
          { label: tt('details'), value: 'details' },
          { label: tt('meeting'), value: 'meeting' },
        ]}
      />
    </Fragment>
  );
};
