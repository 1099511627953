import sv from '@drawbotics/drylus-style-vars';
import { css } from 'emotion';
import React from 'react';

import { DateRange } from '../../../types';

const styles = {
  dateHeader: css`
    background: ${sv.brand};
    color: ${sv.colorPrimaryInverse};
    padding: 0.8cm 0.3cm 0.15cm;
    text-align: center;
  `,
  separator: css`
    width: 0.5cm;
    height: 0.05cm;
    background: white;
    margin: 1mm auto;
  `,
  date: css``,
  month: css`
    font-weight: 300;
    font-size: 9pt;
  `,
  day: css`
    font-weight: 300;
    font-size: 18pt;
  `,
};

export enum DateHeaderSize {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
}

interface DateHeaderProps {
  size?: DateHeaderSize;
  dateRange: DateRange;
  color?: string;
}

export const DateHeader = ({
  size = DateHeaderSize.DEFAULT,
  dateRange,
  color,
}: DateHeaderProps) => {
  return (
    <div
      className={styles.dateHeader}
      style={{
        transform: size === DateHeaderSize.SMALL ? 'scale(0.75)' : undefined,
        transformOrigin: '0 0',
        background: color,
      }}>
      <div className={styles.date}>
        <div className={styles.month}>{dateRange.start.format('MMM')}</div>
        <div className={styles.day}>{dateRange.start.format('DD')}</div>
      </div>
      <div className={styles.separator} />
      <div className={styles.date}>
        <div className={styles.month}>{dateRange.end.format('MMM')}</div>
        <div className={styles.day}>{dateRange.end.format('DD')}</div>
      </div>
    </div>
  );
};
