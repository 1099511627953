import { CheckboxFilter } from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React from 'react';

import { LeadsFilterTypes, Project } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

import { filterStyles } from './filter-styles';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');

const styles = {
  clipText: css`
    [data-element='trigger'] [data-element='label'] {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
    }
  `,
};

interface ProjectsFilterProps {
  projects: Array<Project>;
  filter: LeadsFilterTypes['projects'];
}

export const ProjectsFilter = ({ projects, filter }: ProjectsFilterProps) => {
  const options = projects
    .map((project) => ({
      value: project.id,
      label: project.name,
    }))
    .sort(function (a, b) {
      const lowerCasedA = a.label.toLowerCase();
      const lowerCasedB = b.label.toLowerCase();

      if (lowerCasedA < lowerCasedB) {
        return -1;
      }
      if (lowerCasedA > lowerCasedB) {
        return 1;
      }
      return 0;
    });

  const values: string[] = filter.values ?? [];

  return (
    <CheckboxFilter
      label={tt('titles.projects')}
      className={cx(filterStyles.filter, styles.clipText)}
      options={options}
      values={values}
      enableSearch
      searchPlaceholder={tt('projects.search')}
      onChange={filter.set}
      onClear={() => filter.set(null)}
    />
  );
};
