import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { User } from '../../types';

interface LicensedUsersQueryResult {
  licensedUsers: Array<User>;
}

const LicensedUsersQuery = gql`
  query LicensedUsersQuery {
    licensedUsers {
      id
      email
      firstName
      lastName
      fullName @computed(type: User)
      meetingCount
      role
      grabToken
      memberSince: createdAt
    }
  }
`;

export function useFetchLicensedUsers(): {
  isLoading: boolean;
  users?: Array<User>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<LicensedUsersQueryResult, undefined>({
    query: LicensedUsersQuery,
  });

  return {
    isLoading: res.isLoading,
    users: res.data?.licensedUsers,
    error: buildError(res.error),
  };
}
