import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Meeting, MeetingStatus } from '../../types/meeting';

interface MeetingsQueryFilters {
  leadFilter?: string;
  sortBy?: 'DATETIME' | 'USER_NAME';
  sortingOrder?: 'DESC' | 'ASC';
  statuses?: Array<MeetingStatus>;
  userIds?: Array<string>;
}

interface MeetingsQueryPagination {
  limit: number;
  offset: number;
}

export interface MeetingsQueryResult {
  meetings: Array<Meeting>;
}

export interface MeetingsQueryVariables {
  filters: MeetingsQueryFilters;
  pagination: MeetingsQueryPagination;
}

const MeetingsQuery = gql`
  query MeetingsQuery($filters: MeetingFilterInput!, $pagination: PaginationFilterInput!) {
    meetings(filter: $filters, pagination: $pagination) {
      id
      user {
        id
        fullName @computed(type: User)
        meetingCount
        email
        license {
          id
        }
        profilePicture {
          url
        }
      }
      lead {
        email
        firstName
        lastName
        id
      }
      location
      status
      address
      date: datetime
      meetingLink
      additionalParticipants
      evolutiveBookletUrl
    }
  }
`;

export function useFetchMeetings(
  filters: MeetingsQueryFilters,
  pagination: MeetingsQueryPagination,
  pause: boolean,
): {
  isLoading: boolean;
  meetings?: Array<Meeting>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<MeetingsQueryResult, MeetingsQueryVariables>({
    query: MeetingsQuery,
    variables: { filters, pagination },
    pause,
  });

  return {
    isLoading: res.isLoading,
    meetings: res.data?.meetings.map((meeting) => {
      return {
        ...meeting,
        lead: {
          ...meeting.lead,
          fullName: `${meeting.lead.firstName} ${meeting.lead.lastName}`
        }
      }
    }),
    error: buildError(res.error),
  };
}
