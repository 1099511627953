import {
  Button,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Panel,
  PanelBody,
  PanelSection,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

import charts from '../images/charts.svg';

const tt = createTranslate('pods.insights.routes.campaigns');

interface EmptyStateProps {
  onClickCreateCampaign: VoidFunction;
}

export const EmptyState = ({ onClickCreateCampaign }: EmptyStateProps) => {
  return (
    <Panel
      body={
        <PanelBody>
          <PanelSection>
            <Flex
              style={{ height: '650px' }}
              direction={FlexDirection.VERTICAL}
              justify={FlexJustify.CENTER}>
              <FlexItem>
                <img src={charts} />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Text style={{ textAlign: 'center' }} size={Size.LARGE}>
                  {tt('empty_state_text')}
                </Text>
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Button onClick={onClickCreateCampaign} color={Color.BLUE}>
                  {tt('empty_state_cta')}
                </Button>
              </FlexItem>
            </Flex>
          </PanelSection>
        </PanelBody>
      }
    />
  );
};
