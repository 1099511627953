import sv from '@drawbotics/drylus-style-vars';
import {
  Color,
  ListTile,
  Margin,
  Panel,
  PanelBody,
  ProgressBar,
  RoundIcon,
  Size,
  Text,
  Title,
  formatPrice,
} from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange } from '~/pods/insights/types';
import { InsightsEstate } from '~/pods/insights/types';
import { isDateInRange } from '~/pods/insights/utils';
import { Currency } from '~/types';
import { useMosaicNavigation } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { DashboardCardHeader } from './DashboardCardHeader';

const tt = createTranslate('pods.insights.routes.dashboard');

const DealsIcon = () => (
  <RoundIcon name="award" size={50} style={{ backgroundColor: sv.blueLighter, color: sv.blue }} />
);

interface LeadsCardProps {
  units: InsightsEstate['units'];
  range: DateRange;
  currency: Currency;
}

export const DealsCard = ({ units, range, currency }: LeadsCardProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const soldRevenue = units
    .filter((u) => isDateInRange(u.soldAt, range))
    .reduce((memo, u) => memo + u.price.value, 0);
  const potentialRevenue = units.reduce((memo, u) => memo + u.price.value, 0);
  const revenueRatio = potentialRevenue > 0 ? soldRevenue / potentialRevenue : 0;
  const formattedRevenue = formatPrice({
    price: { value: potentialRevenue > 0 ? potentialRevenue : 0, currency },
    options: { notation: 'compact' },
  });

  return (
    <Panel
      style={{ width: '100%', height: '245px' }}
      body={
        <PanelBody>
          <DashboardCardHeader
            icon={<DealsIcon />}
            title={tt('deals')}
            url={getUrlInProject('/analytics/deals')}
          />
          <Margin size={{ top: Size.DEFAULT, bottom: Size.LARGE }}>
            <ListTile
              leading={
                <Title style={{ margin: '0px' }}>{`${Math.round(revenueRatio * 100)}%`}</Title>
              }
              title={<Text light>{tt('revenue_sold', { revenue: formattedRevenue })}</Text>}
            />
          </Margin>
          <ProgressBar percentage={revenueRatio} color={Color.BLUE} />
        </PanelBody>
      }
    />
  );
};
