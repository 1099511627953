import { DateObject } from '@drawbotics/react-drylus';
import dayjs from 'dayjs';

import { TimeObject } from '../components';

export function isTimeObject(obj: TimeObject | DateObject): obj is TimeObject {
  return 'hour' in obj && 'minute' in obj;
}

export function dateToTimeObject(date: Date): TimeObject {
  const dateObject = dayjs(date).toObject();
  return { minute: dateObject.minutes, hour: dateObject.hours };
}
