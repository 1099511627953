import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Paragraph,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React from 'react';

import { Meeting } from '~/pods/meetings/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.cancel_meeting_modal');

interface CancelMeetingVariables {
  id: string;
}

interface CancelMeetingResult {
  cancelMeeting: {
    meeting: Meeting;
  };
}

const cancelMeetingMutation = gql`
  mutation cancelMeeting($id: ID!) {
    cancelMeeting(input: { meetingId: $id }) {
      meeting {
        id
      }
    }
  }
`;

interface CancelMeetingModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  onClickConfirm: VoidFunction;
  meetingId: string;
}

export const CancelMeetingModal = ({
  meetingId,
  visible,
  onClickClose,
  onClickConfirm,
}: CancelMeetingModalProps) => {
  const { showAlert } = useAlert();
  const { executeMutation: cancelMeeting, res: cancelMeetingResult } = useMosaicMutation<
    CancelMeetingResult,
    CancelMeetingVariables
  >(cancelMeetingMutation);

  const handleCancelMeeting = async () => {
    const { error } = await cancelMeeting({ id: meetingId });
    if (error != null) {
      showAlert({ text: tt('something_went_wrong'), category: Category.DANGER });
    } else {
      onClickConfirm();
      setTimeout(() => {
        showAlert({ text: tt('meeting_cancelled'), category: Category.SUCCESS });
      }, 600);
    }
  };

  return (
    <Modal
      visible={visible}
      title={tt('confirm_cancel')}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              disabled={cancelMeetingResult.fetching}
              trailing={
                cancelMeetingResult.fetching ? <Spinner size={Size.SMALL} inversed /> : null
              }
              category={Category.DANGER}
              onClick={handleCancelMeeting}>
              {tt('yes_cancel')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Paragraph>
        {tt('are_you_sure')}
        <br />
        {tt('receive_email')}
      </Paragraph>
    </Modal>
  );
};
