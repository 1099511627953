import {
  Button,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Icon,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import React, { useContext } from 'react';

import { ID } from '~/types';

import { WebsiteContext } from '../WebsiteDesignPanel';

export interface ControlButtonsProps {
  horizontal?: boolean;
  onClickEdit?: VoidFunction;
  onToggleVisibility?: VoidFunction;
  onIncrement?: VoidFunction;
  onDecrement?: VoidFunction;
  isActive?: boolean;
  sectionId: ID;
}

export const ControlButtons = ({
  horizontal = false,
  onClickEdit,
  onToggleVisibility,
  isActive,
  onIncrement,
  onDecrement,
  sectionId,
}: ControlButtonsProps) => {
  const website = useContext(WebsiteContext);
  const sections = website?.sections;

  if (sections == null) return null;
  const index = sections.findIndex((s) => s.id === sectionId);

  const isFirst = index === 1; // 1 instead of 0 because the hero is fixed at the top
  const isLast = index === sections.length - 2; // -2 because the map is fixed at the bottom

  return (
    <Flex direction={horizontal ? FlexDirection.HORIZONTAL : FlexDirection.VERTICAL}>
      {onClickEdit != null ? (
        <FlexItem>
          <Button
            onClick={onClickEdit}
            size={Size.SMALL}
            color={Color.BLUE}
            leading={<Icon name="edit-2" />}
          />
        </FlexItem>
      ) : null}
      <FlexSpacer size={Size.EXTRA_SMALL} />
      {onToggleVisibility != null ? (
        <FlexItem>
          <Button
            onClick={onToggleVisibility}
            size={Size.SMALL}
            tier={Tier.SECONDARY}
            leading={<Icon name={isActive ? 'eye' : 'eye-off'} />}
          />
        </FlexItem>
      ) : null}
      {onDecrement && !isFirst ? <FlexSpacer size={Size.EXTRA_SMALL} /> : null}
      {onDecrement && !isFirst ? (
        <Button
          onClick={onDecrement}
          size={Size.SMALL}
          tier={Tier.SECONDARY}
          leading={<Icon name="arrow-up" />}
        />
      ) : null}
      {onIncrement && !isLast ? <FlexSpacer size={Size.EXTRA_SMALL} /> : null}
      {onIncrement && !isLast ? (
        <Button
          onClick={onIncrement}
          size={Size.SMALL}
          tier={Tier.SECONDARY}
          leading={<Icon name="arrow-down" />}
        />
      ) : null}
    </Flex>
  );
};
