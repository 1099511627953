import sv from '@drawbotics/drylus-style-vars';
import {
  Checkbox,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Icon,
  ListTile,
  Padding,
  Shade,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';

import { Campaign, CampaignToken } from '~/pods/insights/types';
import { truncate } from '~/utils';

import { CampaignComparison } from './CampaignComparison';

const styles = {
  borderContainer: css`
    border-radius: 4px;
    border: 1px ${sv.grey300} solid;
    min-height: 300px;
    display: flex;
    flex-direction: horizontal;
  `,
  campaignThumbnails: css`
    min-width: 370px;
  `,
  campaignDetails: css`
    width: 100%;
  `,
  campaignThumbnail: css`
    height: 110px;
    min-width: 100%;
    background-color: ${sv.neutralLighter};
    border-bottom: 1px ${sv.grey300} solid;
    cursor: pointer;
    user-select: none;

    & :hover {
      background-color: ${sv.grey100};
    }
  `,
};

function _getDisplayUrl(url: URL): string {
  return url.toString().replace(/https?:\/\/|www.|\/$/g, '');
}

interface CampaignThumbnailProps {
  campaign: Campaign;
  onClick: VoidFunction;
  isSelected: boolean;
}

const CampaignThumbnail = ({ campaign, onClick, isSelected }: CampaignThumbnailProps) => {
  const { destinationUrl, name, launchedAt, visitsCount, leadsCount } = campaign;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={styles.campaignThumbnail}
      style={{ backgroundColor: isSelected ? sv.white : undefined }}>
      <Padding style={{ height: '100%' }} size={Size.SMALL}>
        <Flex style={{ height: '100%' }} align={FlexAlign.STRETCH}>
          <FlexItem>
            <Padding style={{ height: '100%' }} size={{ right: Size.SMALL }}>
              <Flex
                style={{ height: '100%' }}
                direction={FlexDirection.VERTICAL}
                justify={FlexJustify.CENTER}>
                <FlexItem>
                  <Checkbox value={isSelected} onChange={onClick} />
                </FlexItem>
              </Flex>
            </Padding>
          </FlexItem>
          <FlexItem style={{ height: '100%' }} flex>
            <Flex
              style={{ height: '100%' }}
              justify={FlexJustify.SPACE_BETWEEN}
              direction={FlexDirection.VERTICAL}
              align={FlexAlign.START}>
              <FlexItem>
                <Text tier={Tier.TERTIARY}>{launchedAt.format('DD MMM')}</Text>
              </FlexItem>
              <FlexItem>
                <Text
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  size={Size.LARGE}
                  bold>
                  {truncate(name, 30)}
                </Text>
              </FlexItem>
              <FlexItem>
                <Text size={Size.SMALL} tier={Tier.TERTIARY}>
                  {truncate(_getDisplayUrl(destinationUrl), 30)}
                </Text>
              </FlexItem>
            </Flex>
          </FlexItem>
          <FlexItem>
            <Flex
              style={{ height: '100%' }}
              justify={FlexJustify.END}
              direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <ListTile
                  leading={<Text tier={Tier.TERTIARY}>{visitsCount}</Text>}
                  title={<Icon shade={Shade.LIGHT} name="bar-chart" />}></ListTile>
              </FlexItem>
              <FlexItem>
                <ListTile
                  leading={<Text tier={Tier.TERTIARY}>{leadsCount}</Text>}
                  title={<Icon shade={Shade.LIGHT} name="user" />}></ListTile>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </Padding>
    </div>
  );
};

interface CampaignDetailsProps {
  campaigns: Array<Campaign>;
  onClickEditCampaign: (campaign: Campaign) => void;
}

export const CampaignDetails = ({ campaigns, onClickEditCampaign }: CampaignDetailsProps) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<CampaignToken>>(
    campaigns.length !== 0 ? [campaigns[campaigns.length - 1].token] : [],
  );

  const handleToggleCampaign = (campaign: CampaignToken) => {
    if (selectedCampaigns.includes(campaign)) {
      setSelectedCampaigns(selectedCampaigns.filter((c) => c !== campaign));
    } else {
      setSelectedCampaigns([...selectedCampaigns, campaign]);
    }
  };

  return (
    <div className={styles.borderContainer}>
      <div className={styles.campaignThumbnails}>
        {campaigns
          .slice()
          .reverse()
          .map((campaign) => {
            const isSelected = selectedCampaigns.includes(campaign.token);
            return (
              <CampaignThumbnail
                key={campaign.token}
                campaign={campaign}
                onClick={
                  isSelected && selectedCampaigns.length === 1
                    ? () => {}
                    : () => handleToggleCampaign(campaign.token)
                }
                isSelected={isSelected}
              />
            );
          })}
      </div>
      <div className={styles.campaignDetails}>
        <Padding style={{ width: '100%' }}>
          <CampaignComparison
            onClickEditCampaign={onClickEditCampaign}
            campaigns={campaigns.filter((c) => selectedCampaigns.includes(c.token))}
          />
        </Padding>
      </div>
    </div>
  );
};
