import { UrlString } from '~/types';

import { Presentation } from './presentation';
import { User } from './user';

export enum MeetingLocation {
  REMOTE = 'REMOTE',
  PHYSICAL = 'PHYSICAL',
}

export enum MeetingStatus {
  PLANNED = 'PLANNED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
}

export interface Meeting {
  id: string;
  user: User;
  date: string;
  lead: {
    email: string;
    fullName?: string; // computed
    firstName: string;
    lastName: string;
    id: string;
  }
  address?: string;
  location: MeetingLocation;
  status: MeetingStatus;
  meetingLink?: UrlString;
  additionalParticipants?: Array<string>;
  presentations: Array<Presentation>;
  displayListingPlans: boolean;
  displayListingPrice: boolean;
  displayListingStatus: boolean;
  displayListingSurface: boolean;
  displayListingRooms: boolean;
  displayListingBedrooms: boolean;
  allowListingPlansDownload: boolean;
  hideSoldUnits: boolean;
  evolutiveBookletUrl: UrlString;
}
