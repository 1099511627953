import { IconType } from '@drawbotics/react-drylus';

import { CategorySlug } from '../types';

const iconSlugMap: Record<CategorySlug, IconType> = {
  revo: 'model-360',
  virtual_tour: 'interior-tour-3d',
  video: 'video-animation',
  map: 'map',
  configurator: 'edit-3',
  panorama: 'panorama-360',
  fiscal_simulation: 'filter',
  loan_simulation: 'money-bag',
  pinel: 'trending-up',
  access: 'revo',
  city: 'map-pin',
  interior_3d: 'interior-3d',
  shoebox: 'shoebox',
  exterior_3d: 'exterior-3d',
  site_plan: 'site-plan',
  brochure: 'brochure',
  finitions: 'award',
  listing: 'clipboard',
};

export function getIconFromSlug(slug: CategorySlug): IconType {
  return iconSlugMap[slug];
}
