import { useTheme } from '@emotion/react';
import { css, cx } from 'emotion';
import React from 'react';

import { Theme, ThemeDefinition } from '../../../types';

const styles = {
  traditionalLink: (theme: ThemeDefinition) => css`
    font-family: ${theme.fonts.secondary};
  `,
  luxuriousLink: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral4};
    font-family: ${theme.fonts.secondary};
  `,
  minimalistLink: (theme: ThemeDefinition) => css`
    color: ${theme.colors.neutral2};
    font-family: ${theme.fonts.secondary};
  `,
};

interface TextLinkProps {
  children: React.ReactNode;
}

export const TextLink = ({ children }: TextLinkProps) => {
  const theme = useTheme<ThemeDefinition>();

  return (
    <span
      className={cx(
        { [styles.traditionalLink(theme)]: theme.id === Theme.TRADITIONAL },
        { [styles.luxuriousLink(theme)]: theme.id === Theme.LUXURIOUS },
        { [styles.minimalistLink(theme)]: theme.id === Theme.MINIMALIST },
      )}>
      {children}
    </span>
  );
};
