import { z } from 'zod';

import { SectionIdentifier } from '~/pods/website-builder/types';

import { CoverSection, CoverSectionData, CoverSectionSchema } from './cover-section';
import { GallerySection, GallerySectionData, GallerySectionSchema } from './gallery-section';
import { HeroSection, HeroSectionData, HeroSectionSchema } from './hero-section';
import { ListingSection, ListingSectionData, ListingSectionSchema } from './listing-section';
import { MainSection, MainSectionData, MainSectionSchema } from './main-section';
import { MapSection, MapSectionData, MapSectionSchema } from './map-section';
import {
  TextImageSection,
  TextImageSectionData,
  TextImageSectionSchema,
} from './text-image-section';

export type AnyWebsiteSectionData =
  | HeroSectionData
  | MapSectionData
  | MainSectionData
  | GallerySectionData
  | CoverSectionData
  | TextImageSectionData
  | ListingSectionData;

export type UpdateSectionData = Partial<Omit<HeroSectionData, 'identifier'>> &
  Partial<Omit<MapSectionData, 'identifier'>> &
  Partial<Omit<MainSectionData, 'identifier'>> &
  Partial<Omit<GallerySectionData, 'identifier'>> &
  Partial<Omit<CoverSectionData, 'identifier'>> &
  Partial<Omit<ListingSectionData, 'identifier'>> &
  Partial<Omit<TextImageSectionData, 'identifier'>>;

export const AnyWebsiteSectionDataSchema = z.union([
  HeroSectionSchema,
  MapSectionSchema,
  MainSectionSchema,
  GallerySectionSchema,
  CoverSectionSchema,
  ListingSectionSchema,
  TextImageSectionSchema,
]);

export class WebsiteSection {
  public static fromData(data: AnyWebsiteSectionData) {
    if (data.identifier === SectionIdentifier.HERO) {
      return HeroSection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.MAP) {
      return MapSection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.MAIN) {
      return MainSection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.GALLERY) {
      return GallerySection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.COVER) {
      return CoverSection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.LISTING) {
      return ListingSection.fromData(data);
    }

    if (data.identifier === SectionIdentifier.TEXT_IMAGE) {
      return TextImageSection.fromData(data);
    }
  }
}
