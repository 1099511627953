import React, { useState } from 'react';

import { LeadEmailNotificationSettingsPanel as LeadNotificationSettingsPanelInput } from '~/components/LeadEmailNotificationSettingsPanel';
import { Organisation } from '~/pods/settings/types';
import { useUpdateOrganisation } from '~/pods/settings/utils/hooks/use-update-organisation';
import { OrganisationRole } from '~/types';
import { useAuth } from '~/utils';

interface EmailNotificationPanelProps {
  organisation: Organisation;
}

export const LeadEmailNotificationPanel = ({ organisation }: EmailNotificationPanelProps) => {
  const [leadNotificationEmails, setLeadNotificationEmails] = useState(
    organisation.leadNotificationEmails ?? [],
  );

  const { isUpdatingOrganisation, updateOrganisation } = useUpdateOrganisation();
  const { user } = useAuth();

  const isUserMember = user?.role === OrganisationRole.MEMBER;

  return (
    <LeadNotificationSettingsPanelInput
      emails={leadNotificationEmails}
      onChange={async (emails) => {
        setLeadNotificationEmails(emails)

        await updateOrganisation({
          input: {
            id: organisation.id,
            leadNotificationEmails: emails,
          },
        });
      }}
      disabled={isUserMember}
      isSaving={isUpdatingOrganisation}
    />
  );
};
