import { DateRange, InsightsEstate } from '../../types';
import { computeDeltaForRange } from '../compute-delta-for-range';
import { getDataInRange } from '../get-data-in-range';
import { compressToNPoints } from '..';

export function useLoadSoldUnitsOverRange(units: InsightsEstate['units'], range: DateRange) {
  const data = compressToNPoints(getDataInRange(range, units, 'soldAt'), 12);

  return {
    data,
    amount: data.reduce((memo, d) => d.y + memo, 0),
    delta: computeDeltaForRange(range, units, 'soldAt'),
  };
}
