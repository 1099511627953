import {
  Button,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React from 'react';

import { LeadsFilterTypes, Organisation } from '~/pods/crm/types';
import { useFetchCampaigns, useFetchUsers } from '~/pods/crm/utils';
import { PortalData } from '~/pods/multidiffusion/domain';
import { Project } from '~/pods/settings/types/project';
import { createTranslate } from '~/utils';

import { OtherFilters, ProjectsFilter, StatusFilter, UnitTypeFilter } from './';
import { AssignedToFilter } from './AssignedToFilter';
import { EngagementLevelFilter } from './EngagementLevelFilter';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');

const styles = {
  noWrap: css`
    white-space: nowrap;
  `,
};

type FilterType = Pick<
  LeadsFilterTypes,
  | 'projects'
  | 'organisations'
  | 'assignedTo'
  | 'status'
  | 'unitTypes'
  | 'perks'
  | 'purchaseGoal'
  | 'sources'
  | 'budget'
  | 'surface'
  | 'marketingCampaign'
  | 'engagementLevel'
  | 'portalSlugs'
>;

interface LeadsFilterBarProps {
  organisation?: Organisation;
  isLoading: boolean;
  filters: FilterType;
  leadCount?: { ownedLeadCount: number; totalLeadCount: number };
  projects?: Array<Project>;
  portals: Array<PortalData>;
}

const LOADING_PLACEHOLDER_WIDTH = 64;

export const LeadsFilterBar = ({
  organisation,
  isLoading: isOrganisationLoading,
  filters,
  leadCount,
  projects,
  portals,
}: LeadsFilterBarProps) => {
  const { isUsersLoading, users } = useFetchUsers();
  const { isCampaignsLoading, campaigns } = useFetchCampaigns();

  const {
    projects: projectsFilter,
    assignedTo,
    organisations,
    status,
    unitTypes,
    engagementLevel,
    ...otherFilters
  } = filters;

  const handleOnClickClear = () => {
    Object.keys(filters).forEach((key) => {
      filters[key as keyof FilterType].set(null);
    });
  };

  return (
    <Flex justify={FlexJustify.END}>
      <FlexItem>
        {isUsersLoading || isOrganisationLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : users != null && projects != null ? (
          <AssignedToFilter
            users={users}
            projects={projects}
            membersFilter={assignedTo}
            organisationsFilter={organisations}
          />
        ) : undefined}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        {isOrganisationLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : organisation?.steps != null ? (
          <StatusFilter steps={organisation.steps} filter={status} />
        ) : undefined}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        {isOrganisationLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : organisation?.leads != null ? (
          <EngagementLevelFilter filter={engagementLevel} />
        ) : undefined}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        {isOrganisationLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : organisation?.projects != null ? (
          <ProjectsFilter projects={organisation.projects} filter={projectsFilter} />
        ) : undefined}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        {isOrganisationLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : organisation?.projects != null ? (
          <UnitTypeFilter filter={unitTypes} />
        ) : undefined}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        {isCampaignsLoading ? (
          <LoadingPlaceholder width={LOADING_PLACEHOLDER_WIDTH} />
        ) : (
          <OtherFilters
            surfaceUnit={organisation?.leads?.[0]?.surfaceUnit ?? 'sqm'}
            budgetCurrency={organisation?.leads?.[0]?.budgetCurrency ?? 'EUR'}
            filters={otherFilters}
            campaigns={campaigns}
            leadCount={leadCount}
            portals={portals}
          />
        )}
      </FlexItem>

      <FlexSpacer size={Size.EXTRA_SMALL} />

      <FlexItem>
        <Button className={cx(styles.noWrap)} onClick={handleOnClickClear} tier={Tier.TERTIARY}>
          {tt('clear_filters')}
        </Button>
      </FlexItem>
    </Flex>
  );
};
