import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  Input,
  Label,
  Margin,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';

import { Thumbnail } from '~/components';
import { createTranslate } from '~/utils';

const styles = {
  galleryItem: css`
    position: relative;
    padding: ${sv.paddingExtraSmall};
    border-radius: ${sv.defaultBorderRadius};
    background: ${sv.backgroundColor};
  `,
  confirmDelete: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${sv.paddingExtraSmall};
    border-radius: ${sv.defaultBorderRadius};
    background: ${sv.backgroundColor};
    text-align: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  handle: css`
    display: flex;
    padding: 4px 0px;
    border-radius: ${sv.defaultBorderRadius};
    transition: ${sv.transitionShort};

    &:hover {
      cursor: pointer;
      background: ${sv.neutral};
    }
  `,
};

const tt = createTranslate('pods.website_builder.routes.website_builder');

interface GalleryModalItemProps {
  id: string;
  imageUrl: string;
  alt?: string;
  onDelete: VoidFunction;
  onChangeAlt: (alt: string) => void;
}

export const GalleryModalItem = ({
  id,
  imageUrl,
  onDelete,
  onChangeAlt,
  alt,
}: GalleryModalItemProps) => {
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { transform, transition, setNodeRef, attributes, listeners, active } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? undefined,
    zIndex: active?.id === id ? 999 : undefined,
    boxShadow: active?.id === id ? sv.elevation2 : undefined,
  };

  return (
    <div className={styles.galleryItem} ref={setNodeRef} style={style}>
      {confirmDeleteVisible ? (
        <div className={styles.confirmDelete}>
          <Margin size={{ bottom: Size.EXTRA_SMALL }}>
            <Text>{tt('confirm_delete_image')}</Text>
          </Margin>
          <Flex>
            <FlexItem>
              <Button
                onClick={() => setConfirmDeleteVisible(false)}
                size={Size.SMALL}
                tier={Tier.SECONDARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button category={Category.DANGER} size={Size.SMALL} onClick={onDelete}>
                {tt('delete')}
              </Button>
            </FlexItem>
          </Flex>
        </div>
      ) : null}
      <Flex>
        <FlexItem style={{ display: 'flex' }}>
          <div className={styles.handle} {...attributes} {...listeners}>
            <Icon name="drag-and-drop" />
          </div>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <Thumbnail
            style={{ height: 75, width: 90, border: '3px solid white' }}
            image={imageUrl}
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem flex>
          <Margin size={{ bottom: Size.EXTRA_SMALL }}>
            <Label>Alt</Label>
          </Margin>
          <Input
            value={alt ?? ''}
            onChange={(v) => onChangeAlt(String(v))}
            placeholder={tt('alt_hint')}
          />
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem style={{ alignSelf: 'start' }}>
          <Icon name="x" onClick={() => setConfirmDeleteVisible(true)} />
        </FlexItem>
      </Flex>
    </div>
  );
};
