import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexJustify, Padding, Size } from '@drawbotics/react-drylus';
import { ResponsiveLine } from '@nivo/line';
import { css } from 'emotion';
import React from 'react';

import { DateRange, InsightsSession } from '~/pods/insights/types';
import { LineTheme, useLoadNumberOfVisitsOverRange } from '~/pods/insights/utils';
import { createAreaLayer } from '~/pods/insights/utils/create-area-layer';
import { getYGridLines } from '~/pods/insights/utils/get-y-grid-lines';
import { createTranslate } from '~/utils/translation';

import { LineLabel } from '../LineLabel';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.number_of_visits');

const styles = {
  chartContainer: css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  chart: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 25px);
  `,
};

interface NumberOfVisitsProps {
  sessions: Array<InsightsSession>;
  dateRange: DateRange;
}

export const NumberOfVisits = ({ sessions, dateRange }: NumberOfVisitsProps) => {
  const { sessionsData, visitsData } = useLoadNumberOfVisitsOverRange(sessions, dateRange);
  const sessionsGridLines = getYGridLines(sessionsData);
  const visitsGridLines = getYGridLines(visitsData);
  const finalGridLines =
    Math.max(...sessionsGridLines) > Math.max(...visitsGridLines)
      ? sessionsGridLines
      : visitsGridLines;
  const sevenDaysAgo = new Date(dateRange.end.subtract(7, 'day').format('YYYY-M-DD'));

  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ top: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <LineLabel color={sv.neutral} label={t('last_week')} squared />
          </FlexItem>
          <FlexItem>
            <LineLabel color={sv.green} label={t('units_visits')} />
          </FlexItem>
          <FlexItem>
            <LineLabel color={sv.blue} label={t('user_sessions')} />
          </FlexItem>
        </Flex>
      </Padding>
      <Padding size={{ vertical: Size.EXTRA_SMALL }} style={{ width: '100%', height: '100%' }}>
        <div className={styles.chartContainer}>
          <div className={styles.chart}>
            <ResponsiveLine
              data={[
                { id: 'visits', color: sv.green, data: visitsData },
                { id: 'sessions', color: sv.blue, data: sessionsData },
              ]}
              yScale={{
                type: 'linear',
                min: finalGridLines[0],
                max: Math.max(...finalGridLines),
              }}
              xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
              axisBottom={{
                format: '%d',
                tickValues: 'every 2 days',
                tickSize: 0,
                tickPadding: 12,
              }}
              curve="monotoneX"
              axisLeft={{ tickValues: finalGridLines, tickSize: 0 }}
              enableGridX={false}
              areaOpacity={0.1}
              enablePoints={false}
              isInteractive={false}
              margin={{ bottom: 25, left: 25, top: 5, right: 25 }}
              colors={{ datum: 'color' }}
              theme={LineTheme}
              layers={[
                'grid',
                'markers',
                'areas',
                createAreaLayer({ start: sevenDaysAgo, height: Math.max(...finalGridLines) }),
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
              ]}
              // @ts-ignore is not correctly typed
              gridYValues={finalGridLines}
            />
          </div>
        </div>
      </Padding>
    </ChartTile>
  );
};
