import { css } from 'emotion';

export const filterStyles = {
  filter: css`
    > div {
      max-height: initial;
    }

    [data-element='panel'] {
      max-height: 460px;
      min-width: max-content;
    }

    [data-element='label'] {
      white-space: nowrap;
    }
  `,
};
