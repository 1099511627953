import gql from 'fraql';

export const WebsiteQuery = gql`
  query WebsiteQuery($projectId: ID!) {
    website(projectId: $projectId) {
      id
      identifier
      listingSettings {
        availability
        downloadFloorplan
        numberOfRooms
        price
        soldUnits
        surface
        terrace
      }
      latitude
      longitude
      address
      companyName
      gdprUrl
      template
      primaryColor
      secondaryColor
      contactFormFilters
      contactFormScheduling
      language
      openGraphTitle
      openGraphDescription
      openGraphAttachment {
        id
        url
        filename
      }
      logo {
        id
        filename
        url
      }
      favicon {
        id
        filename
        url
      }
      customDomain {
        id
        cname
        hostname
        status
      }
      socialMedia {
        facebook
        instagram
        linkedin
        twitter
      }

      _sections: sections {
        id
        identifier
        position

        ... on HeroSection {
          title
          description
          tagline
          link {
            id
            label
            linkType
            newTab
            ... on DownloadLink {
              attachment {
                filename
                id
                url
              }
            }
            ... on SectionLink {
              targetWebsiteSection {
                id
                identifier
              }
            }
            ... on UrlLink {
              url
            }
          }
          assets {
            assetId: id
            id
            url
            position

            ... on UrlAsset {
              attachmentUrl: url
            }

            ... on AttachmentAsset {
              alt
              filename # Mandatory to request even if not used so AssetTile can differentiate them
            }
          }
        }

        ... on CoverSection {
          title
          description
          active
        }

        ... on GallerySection {
          active
          assets {
            assetId: id
            id
            url
            position

            ... on UrlAsset {
              attachmentUrl: url
            }

            ... on AttachmentAsset {
              alt
              filename
            }
          }
        }

        ... on MainSection {
          title
          active
          assets {
            assetId: id
            id
            url
            position

            ... on UrlAsset {
              attachmentUrl: url
            }

            ... on AttachmentAsset {
              alt
              filename
            }
          }
        }

        ... on MapSection {
          title
          description
          assets {
            assetId: id
            url
            id
          }
          link {
            id
            label
            linkType
            newTab
            ... on DownloadLink {
              attachment {
                filename
                id
                url
              }
            }
            ... on SectionLink {
              targetWebsiteSection {
                id
                identifier
              }
            }
            ... on UrlLink {
              url
            }
          }
        }

        ... on TextImageSection {
          title
          description
          position
          active
          assets {
            assetId: id
            id
            url
            position

            ... on UrlAsset {
              attachmentUrl: url
            }

            ... on AttachmentAsset {
              alt
              filename
            }
          }
        }

        ... on ListingSection {
          title
          active
        }
      }
    }
  }
`;
