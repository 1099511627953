import sv from '@drawbotics/drylus-style-vars';
import { PieSvgProps, ResponsivePie } from '@nivo/pie';
import React from 'react';

interface PieChartProps extends PieSvgProps {
  data: Array<{
    id: string;
    color: string;
    label: string;
    value: number;
  }>;
  isInteractive?: boolean;
  enableLabels?: boolean;
  margin?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  };
}

export const PieChart = ({
  data,
  isInteractive = true,
  enableLabels = false,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  ...rest
}: PieChartProps) => {
  return (
    <ResponsivePie
      margin={margin}
      data={data}
      innerRadius={0.75}
      padAngle={1}
      cornerRadius={3}
      sortByValue={false}
      borderWidth={0}
      colors={{ datum: 'color' }}
      isInteractive={isInteractive}
      enableRadialLabels={enableLabels}
      radialLabelsLinkDiagonalLength={1}
      radialLabelsLinkHorizontalLength={1}
      radialLabelsTextColor={sv.grey400}
      radialLabelsLinkStrokeWidth={0}
      enableSlicesLabels={false}
      // @ts-ignore Improperly typed
      radialLabel={(d) => (d.value > 0 ? d.value : '')}
      {...rest}
    />
  );
};
