import { Category, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import React from 'react';

import { AssetTile } from '~/components';
import { OrganisationOverview } from '~/pods/meetings/types';
import { AttachmentAsset, UrlAsset } from '~/types';
import { createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

interface UpdateCoverAssetVariables {
  coverAsset?: {
    assetId?: string;
    signedBlobId?: string;
    url?: string;
  };
}

interface UpdateCoverAssetResult {
  updateOverview: {
    overview: OrganisationOverview;
  };
}

const updateCoverAssetMutation = gql`
  mutation updateCoverAsset($coverAsset: AssetInput!) {
    updateOverview(input: { coverAsset: $coverAsset }) {
      overview {
        coverAsset {
          id
          url
          ... on AttachmentAsset {
            filename
          }
        }
      }
    }
  }
`;

interface CoverAssetProps {
  asset?: AttachmentAsset | UrlAsset;
  readOnly: boolean;
}

export const CoverAsset = ({ asset, readOnly }: CoverAssetProps) => {
  const { executeMutation: updateCoverAsset } = useMosaicMutation<
    UpdateCoverAssetResult,
    UpdateCoverAssetVariables
  >(updateCoverAssetMutation);
  const { showAlert } = useAlert();

  return (
    <AssetTile
      asset={asset}
      readOnly={readOnly}
      onSaveUrl={async (url) => {
        const res = await updateCoverAsset({
          coverAsset: { url, assetId: asset?.id },
        });
        if (res.error != null) {
          showAlert({ text: tt('could_not_save_url'), category: Category.DANGER });
        }
        return res.error == null;
      }}
      onFinishUpload={async (uploadedFile) => {
        const res = await updateCoverAsset({
          coverAsset: { signedBlobId: uploadedFile.signedBlobId, assetId: asset?.id },
        });
        if (res.error != null) {
          showAlert({ text: tt('could_not_upload'), category: Category.DANGER });
        }
        return res.error == null;
      }}
    />
  );
};
