import {
  ButtonLink,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexDirection,
  FlexItem,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorType } from '~/types';
import { run, useMosaicNavigation } from '~/utils';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.listing_manager.components.listing_error');

interface ListingErrorProps {
  type: ErrorType;
}

export const ListingError = ({ type }: ListingErrorProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  return (
    <Flex direction={FlexDirection.VERTICAL} style={{ minHeight: '70vh' }}>
      <FlexItem>
        {run(() => {
          if (type === ErrorType.NOT_FOUND) {
            return (
              <EmptyState
                title={tt('listing_does_not_exist')}
                variation={EmptyStateVariation.NOT_FOUND}
                description={tt('tried_to_access')}>
                <Link to={getUrlInOrg()}>
                  <ButtonLink category={Category.INFO}>{tt('back_to_home')}</ButtonLink>
                </Link>
              </EmptyState>
            );
          }
          return (
            <EmptyState
              description={tt('try_to_refresh')}
              variation={EmptyStateVariation.FAILED}
              title={tt('something_went_wrong')}>
              <Link to={getUrlInOrg()}>
                <ButtonLink category={Category.INFO}>{tt('back_to_home')}</ButtonLink>
              </Link>
            </EmptyState>
          );
        })}
      </FlexItem>
    </Flex>
  );
};
