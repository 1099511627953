import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Project } from '../../types';

interface ProjectQueryData {
  project: Project;
}

interface ProjectQueryVariables {
  projectSlug: string;
}

const ProjectQuery = gql`
  query ProjectQuery($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      name
    }
  }
`;

export function useFetchProject(projectSlug: string): {
  isLoading: boolean;
  project?: Project;
  error?: ErrorType;
} {
  const res = useMosaicQuery<ProjectQueryData, ProjectQueryVariables>({
    query: ProjectQuery,
    variables: { projectSlug },
  });

  return { isLoading: res.isLoading, project: res.data?.project, error: buildError(res.error) };
}
