import {
  Color,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  Padding,
  ProgressBar,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { isDateInRange } from '~/pods/insights/utils';
import { createTranslate } from '~/utils/translation';

import { DateRange, InsightsLead, InsightsSession } from '../../../../types';
import { Text } from '../Text';
import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';

const t = createTranslate('pods.insights_reports.components.charts.conversion_rate');

interface ConversionRateProps {
  leads: Array<InsightsLead>;
  sessions: Array<InsightsSession>;
  dateRange: DateRange;
}

export const ConversionRate = ({ sessions, leads, dateRange }: ConversionRateProps) => {
  const totalVisits = sessions.filter((s) => isDateInRange(s.createdAt, dateRange)).length;
  const totalLeads = leads.filter((l) => isDateInRange(l.createdAt, dateRange)).length;

  const conversionRate = totalLeads / totalVisits || 0; // Default to 0 if NaN

  return (
    <ChartTile>
      <ChartTitle>{t('title')}</ChartTitle>
      <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.EXTRA_SMALL }}>
            <Text light style={{ fontSize: '24pt' }}>
              {(conversionRate * 100).toFixed(2)}%
            </Text>
          </Padding>
        </FlexItem>
        <FlexItem>
          <Text>{t('detailed', { leads: totalLeads, visits: totalVisits })}</Text>
        </FlexItem>
        <FlexItem style={{ width: '100%' }}>
          <Padding size={{ vertical: Size.EXTRA_SMALL }}>
            <ProgressBar color={Color.ORANGE} percentage={conversionRate} size={Size.SMALL} />
          </Padding>
        </FlexItem>
      </Flex>
    </ChartTile>
  );
};
