import sv from '@drawbotics/drylus-style-vars';
import {
  Color,
  Flex,
  FlexItem,
  ListTile,
  Margin,
  Panel,
  PanelBody,
  ProgressBar,
  RoundIcon,
  Shade,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { useMosaicNavigation } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { DateRange, InsightsLead, InsightsSession } from '../../../../types';
import { isDateInRange } from '../../../../utils';
import { DashboardCardHeader } from './DashboardCardHeader';

const tt = createTranslate('pods.insights.routes.dashboard');

const LeadsIcon = () => (
  <RoundIcon
    name="users"
    size={50}
    style={{ backgroundColor: sv.orangeLighter, color: sv.orange }}
  />
);

interface LeadsCardProps {
  leads: Array<InsightsLead>;
  sessions: Array<InsightsSession>;
  range: DateRange;
}

export const LeadsCard = ({ leads, range, sessions }: LeadsCardProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const leadsInRange = leads.filter((f) => isDateInRange(f.createdAt, range));
  const sessionsInRange = sessions.filter((s) => isDateInRange(s.createdAt, range));
  const conversionRate =
    sessionsInRange.length > 0 ? leadsInRange.length / sessionsInRange.length : 0;
  const invalidData = conversionRate === Infinity;

  return (
    <Panel
      style={{ width: '100%', height: '245px' }}
      body={
        <PanelBody>
          <DashboardCardHeader icon={<LeadsIcon />} title="Leads" url={getUrlInProject(`/crm`)} />
          {invalidData ? (
            <Flex>
              <FlexItem>
                <Margin size={{ top: Size.EXTRA_LARGE }}>
                  <Text shade={Shade.LIGHT}>{tt('insufficient_data')}</Text>
                </Margin>
              </FlexItem>
            </Flex>
          ) : (
            <Fragment>
              <Margin size={{ top: Size.DEFAULT, bottom: Size.LARGE }}>
                <ListTile
                  leading={
                    <Title style={{ margin: '0px' }}>{(conversionRate * 100).toFixed(2)}%</Title>
                  }
                  title={
                    <Text light>
                      {tt('leads_visitors', {
                        leads: leadsInRange.length,
                        visitors: sessionsInRange.length,
                      })}
                    </Text>
                  }
                />
              </Margin>
              <ProgressBar percentage={conversionRate} color={Color.ORANGE} />
            </Fragment>
          )}
        </PanelBody>
      }
    />
  );
};
