import { Filters, Unit } from '../types';

export function filterUnits(units: Array<Unit>, filters: Filters): Array<Unit> {
  const { availability, bedrooms, rooms, price, surface, search } = filters;

  return units.filter((unit: Unit) => {
    let matchesCondition = true;

    if (availability.value != null) {
      matchesCondition = matchesCondition && unit.availability === availability.value;
    }
    if (bedrooms.value != null) {
      matchesCondition = matchesCondition && unit.bedrooms === Number(bedrooms.value);
    }
    if (rooms.value != null) {
      matchesCondition = matchesCondition && unit.rooms === Number(rooms.value);
    }
    if (price.value != null) {
      matchesCondition =
        matchesCondition &&
        unit.price >= Number(price.value[0]) &&
        unit.price <= Number(price.value[1]);
    }
    if (surface.value != null) {
      matchesCondition =
        matchesCondition &&
        unit.surface >= Number(surface.value[0]) &&
        unit.surface <= Number(surface.value[1]);
    }
    if (search.value != null) {
      matchesCondition =
        matchesCondition && unit.reference.toLowerCase().includes(search.value.toLowerCase());
    }

    return matchesCondition;
  });
}
