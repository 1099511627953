import {
  Table as DTable,
  TableProps as DTableProps,
  RoundIcon,
  Size,
} from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import createEmotion from 'create-emotion';
import React from 'react';

import { LocaleCode, createTranslateWithLocale, displaySurface, translatePrice } from '~/utils';

import { Website } from '../../../api/domain';
import { ThemeDefinition, Unit, UnitAvailability } from '../../../types';

// Necessary to create a new emotion instance otherwise drylus styles take precedence
const { css } = createEmotion({ key: 'table-emotion' });

const styles = {
  table: (theme: ThemeDefinition) => css`
    max-height: 500px;
    overflow-y: auto;

    * {
      font-family: ${theme.fonts.secondary};
    }

    th {
      background: ${theme.colors.neutral3} !important;

      > div {
        color: ${theme.colors.neutral1} !important;
      }
    }

    thead {
      border-bottom: none !important;
    }

    tbody {
      color: ${theme.colors.neutral1} !important;
    }

    tr {
      border-bottom: 1px solid ${theme.colors.neutral3};
      background: ${theme.colors.neutral4} !important;

      &:last-of-type {
        border-bottom: none;
      }
    }
  `,
};

interface TableProps {
  units: Array<Unit>;
  settings?: Website['listingSettings'];
  language: LocaleCode;
}

export const Table = ({ units, settings, language }: TableProps) => {
  const theme = useTheme<ThemeDefinition>();
  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const data = units
    .filter((unit) => !(settings?.availability && unit.availability !== UnitAvailability.AVAILABLE))
    .sort((a, b) => a.reference.localeCompare(b.reference, undefined, { numeric: true }))
    .map((unit) => ({
      id: unit.id,
      unit: unit.reference,
      surface: displaySurface(unit.surface.value, unit.surface.unit),
      numberOfRooms: unit.bedrooms, // TODO use typology in fr
      terrace: unit.terrace ? tl('yes') : tl('no'),
      price: translatePrice(unit.price.value, unit.price.currency),
      downloadFloorplan: (
        <a href={unit.floorplan} target="_blank" rel="noreferrer noopener">
          <RoundIcon
            size={Size.LARGE}
            name="download"
            inversed
            style={{ backgroundColor: theme.colors.primary }}
          />
        </a>
      ),
    }));

  return (
    <DTable
      className={styles.table(theme)}
      data={data}
      header={[
        { value: 'unit', label: tl('unit') },
        ...(settings?.surface ? [{ value: 'surface', label: tl('surface') }] : []),
        ...(settings?.numberOfRooms ? [{ value: 'numberOfRooms', label: tl('rooms') }] : []),
        ...(settings?.terrace ? [{ value: 'terrace', label: tl('terrace') }] : []),
        ...(settings?.price ? [{ value: 'price', label: tl('price') }] : []),
        ...(settings?.downloadFloorplan
          ? [{ value: 'downloadFloorplan', label: tl('floorplan') }]
          : []),
      ].filter((header) =>
        settings != null ? settings[header.value as keyof DTableProps['header']] !== false : true,
      )}
    />
  );
};
