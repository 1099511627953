import { Dayjs } from 'dayjs';

export type UnitEntity = {
  id: string;
  type: 'unit';
  timestamp: Dayjs;
};

export type Visits = Array<UnitEntity>;

export function isUnitEntity(entity: any): entity is UnitEntity {
  return entity != null && entity.type === 'unit';
}
