import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Lead } from '../../types';
import { getLeadsMonthlyDifference, getLeadsWeeklyDifference } from '../leads-insights-utils';

interface LeadsData {
  totalLeads: number;
  weekDifference?: number;
  monthDifference?: number;
}

interface LeadsQueryData {
  leads: Array<Lead>;
}

const LeadsQuery = gql`
  query LeadsQuery {
    leads(filter: { sortBy: CREATED_AT_DESC }) {
      id
      createdAt @computed(type: Lead)
    }
  }
`;

export function useFetchLeadsData(): { isLoading: boolean; data?: LeadsData; error?: ErrorType } {
  const res = useMosaicQuery<LeadsQueryData, null>({
    query: LeadsQuery,
  });

  const leads = res.data?.leads;
  const weekDifference = leads != null ? getLeadsWeeklyDifference(leads) : undefined;
  const monthDifference = leads != null ? getLeadsMonthlyDifference(leads) : undefined;

  return {
    isLoading: res.isLoading,
    data:
      leads == null || leads?.length === 0
        ? undefined
        : { totalLeads: leads.length, weekDifference, monthDifference },
    error: buildError(res.error),
  };
}
