import { ID } from '~/types';

export enum ProgressStatus {
  AVAILABLE_IMMEDIATELY = 'LIVRAISON_IMMEDIATE',
  WORKS_IN_PROGRESS = 'TRAVAUX_EN_COURS',
}

export enum EligibilityCertificate {
  ACCESSION_ABORDABLE = 'ACCESSION_ABORDABLE',
  ACCESSION_LIBRE = 'ACCESSION_LIBRE',
  ACCESSION_MAITRISEE = 'ACCESSION_MAITRISEE',
  BAIL_REEL_SOLIDAIRE = 'BAIL_REEL_SOLIDAIRE',
  COMPATIBLE_PSLA = 'COMPATIBLE_PSLA',
  COMPATIBLE_PTZ_PLUS = 'COMPATIBLE_PTZ_PLUS',
  DEFISC_LOI_BESSON = 'DEFISC_LOI_BESSON',
  DEFISC_LOI_BORLOO = 'DEFISC_LOI_BORLOO',
  DEFISC_LOI_CENSI_BOUVARD = 'DEFISC_LOI_CENSI_BOUVARD',
  DEFISC_LOI_DEMESSINE = 'DEFISC_LOI_DEMESSINE',
  DEFISC_LOI_DUFLOT = 'DEFISC_LOI_DUFLOT',
  DEFISC_LOI_GIRARDIN_PAUL = 'DEFISC_LOI_GIRARDIN_PAUL',
  DEFISC_LOI_MALRAUX = 'DEFISC_LOI_MALRAUX',
  DEFISC_LOI_MH = 'DEFISC_LOI_MH',
  DEFISC_LOI_PINEL = 'DEFISC_LOI_PINEL',
  DEFISC_LOI_PINEL_OUTRE_MER = 'DEFISC_LOI_PINEL_OUTRE_MER',
  DEFISC_LOI_ROBIEN = 'DEFISC_LOI_ROBIEN',
  DEFISC_LOI_ROBIEN_RECENTREE = 'DEFISC_LOI_ROBIEN_RECENTREE',
  DEFISC_NUE_PROPRIETE_DEMEMBREMENT = 'DEFISC_NUE_PROPRIETE_DEMEMBREMENT',
  DEFISC_REGIME_MICRO_BIC = 'DEFISC_REGIME_MICRO_BIC',
  DEFISC_REGIME_SCPI = 'DEFISC_REGIME_SCPI',
  DEFISC_RESIDENCE_SERVICES = 'DEFISC_RESIDENCE_SERVICES',
  DEFISC_RESIDENCE_TOURISME_CLASSEE = 'DEFISC_RESIDENCE_TOURISME_CLASSEE',
  DEFISC_STATUT_EPAD = 'DEFISC_STATUT_EPAD',
  DEFISC_STATUT_LMNP = 'DEFISC_STATUT_LMNP',
  DEFISC_STATUT_LMP = 'DEFISC_STATUT_LMP',
  DEFISC_STATUT_MAPAD = 'DEFISC_STATUT_MAPAD',
  DEFISC_STATUT_ZRR = 'DEFISC_STATUT_ZRR',
  DEFISC_TVA_5_VIRGULE_5_POURCENT = 'DEFISC_TVA_5_VIRGULE_5_POURCENT',
  DEFISC_TVA_7_POURCENT = 'DEFISC_TVA_7_POURCENT',
  DEFISC_ZONE_ANRU = 'DEFISC_ZONE_ANRU',
  HABITAT_PARTICIPATIF = 'HABITAT_PARTICIPATIF',
  LOI_SCELLIER = 'LOI_SCELLIER',
  ORGANISME_FONCIER_SOLIDAIRE = 'ORGANISME_FONCIER_SOLIDAIRE',
  PASS_FONCIER_COLLECTIF = 'PASS_FONCIER_COLLECTIF',
  PASS_FONCIER_INDIVIDUEL = 'PASS_FONCIER_INDIVIDUEL',
  PRET_LOCATIF_SOCIAL = 'PRET_LOCATIF_SOCIAL',
  SCHEMA_LEGAL_MUS = 'SCHEMA_LEGAL_MUS',
  ZONE_FISCALE_ROBIEN_BORLOO = 'ZONE_FISCALE_ROBIEN_BORLOO',
}

export enum QualityCertificate {
  LABEL_ACERMI = 'LABEL_ACERMI',
  LABEL_BBC = 'LABEL_BBC',
  LABEL_BEPAS = 'LABEL_BEPAS',
  LABEL_BEPOS = 'LABEL_BEPOS',
  LABEL_CE = 'LABEL_CE',
  LABEL_HE = 'LABEL_HE',
  LABEL_HE_EFFINERGIE = 'LABEL_HE_EFFINERGIE',
  LABEL_HPE = 'LABEL_HPE',
  LABEL_HPE_ENR = 'LABEL_HPE_ENR',
  LABEL_HQE = 'LABEL_HQE',
  LABEL_ISO9001 = 'LABEL_ISO9001',
  LABEL_ISO9002 = 'LABEL_ISO9002',
  LABEL_ISO9003 = 'LABEL_ISO9003',
  LABEL_MAISON_DE_CONFIANCE = 'LABEL_MAISON_DE_CONFIANCE',
  LABEL_MAISON_DE_QUALITE = 'LABEL_MAISON_DE_QUALITE',
  LABEL_NF_MAISON_INDIVIDUELLE = 'LABEL_NF_MAISON_INDIVIDUELLE',
  LABEL_PROMOTELEC = 'LABEL_PROMOTELEC',
  LABEL_QUALIBAT = 'LABEL_QUALIBAT',
  LABEL_QUALITEL = 'LABEL_QUALITEL',
  LABEL_THPE = 'LABEL_THPE',
  LABEL_THPE_ENR = 'LABEL_THPE_ENR',
  RE_2020 = 'RE_2020',
  RT_2012 = 'RT_2012',
}

export enum DiffusionStatus {
  PUBLISHED = 'PUBLISHED',
  PENDING = 'PENDING',
  UNPUBLISHED = 'UNPUBLISHED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export interface Unit {
  id: ID;
  reference: string;
}

export enum Advantages {
  ACCES_HANDICAPES = 'ACCES_HANDICAPES',
  ALARME_HABITATION = 'ALARME_HABITATION',
  ASCENSEUR = 'ASCENSEUR',
  AVEC_JARDIN = 'AVEC_JARDIN',
  BALCON = 'BALCON',
  BELLE_VUE = 'BELLE_VUE',
  CAVE = 'CAVE',
  CHAUFFAGE_CENTRAL = 'CHAUFFAGE_CENTRAL',
  CHEMINEE = 'CHEMINEE',
  CLIMATISE = 'CLIMATISE',
  DIGICODE = 'DIGICODE',
  GARAGE = 'GARAGE',
  GARDIEN = 'GARDIEN',
  PISCINE = 'PISCINE',
  POSSEDE_PARKING = 'POSSEDE_PARKING',
  TERRASSE = 'TERRASSE',
  AVEC_VIS_A_VIS = 'AVEC_VIS_A_VIS',
  BUANDERIE = 'BUANDERIE',
  CUISINE_AMENAGEE = 'CUISINE_AMENAGEE',
  CUISINE_EQUIPEE = 'CUISINE_EQUIPEE',
  PLACARDS_INTEGRES = 'PLACARDS_INTEGRES',
  POSSEDE_BOX = 'POSSEDE_BOX',
  SALLE_DE_SPORT = 'SALLE_DE_SPORT',
  ANIMAUX_ACCEPTES = 'ANIMAUX_ACCEPTES',
  AIRE_DE_JEU = 'AIRE_DE_JEU',
}
