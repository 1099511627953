import dayjs from 'dayjs';
import faker from 'faker';
import { v4 } from 'uuid';

import { SHOWCASE_IDENTIFIER } from '~/constants';

import { DateRange, ShowcaseLead } from './types';
import { pickCampaign } from './utils';

function randRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export class Leads {
  public readonly leads: Array<ShowcaseLead> = [];

  create(amount: number, dateRange: DateRange) {
    for (let i = 0; i < amount; i++) {
      this.leads.push(this.createRandomLead(dateRange));
    }
  }

  private createRandomLead(dateRange: DateRange): ShowcaseLead {
    const fName = faker.name.firstName();
    const lName = faker.name.lastName();
    return {
      id: v4(),
      content: faker.lorem.lines(randRange(1, 6)),
      project: 'Insights Demo',
      recipient: [faker.internet.email()],
      createdAt: dayjs(faker.date.between(dateRange.start.toDate(), dateRange.end.toDate())),
      updatedAt: dayjs(faker.date.between(dateRange.start.toDate(), dateRange.end.toDate())),
      email: faker.internet.email(fName.toLowerCase(), lName.toLowerCase()),
      name: `${fName} ${lName}`,
      phone: faker.phone.phoneNumber('+3# ### ## ## ##'),
      projectSlug: SHOWCASE_IDENTIFIER,
      source: faker.random.arrayElement(['revo', 'website', 'landing']),
      campaignToken: pickCampaign(),
    };
  }
}
