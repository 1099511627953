import { Dot, Flex, FlexItem, ListTile, Margin, Shade, Size, Text } from '@drawbotics/react-drylus';
import React from 'react';

import { HexColor } from '~/types';

interface LegendItem {
  color: HexColor;
  label: string;
}

export const HorizontalLegend = ({ items }: { items: Array<LegendItem> }) => {
  return (
    <Flex wrap>
      {items.map((item) => (
        <FlexItem key={item.label}>
          <Margin size={{ right: Size.DEFAULT }}>
            <ListTile
              leading={<Dot size={Size.LARGE} color={item.color} />}
              title={<Text shade={Shade.LIGHT}>{item.label}</Text>}
            />
          </Margin>
        </FlexItem>
      ))}
    </Flex>
  );
};
