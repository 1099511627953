import { useEffect, useState } from 'react';

interface State {
  isLoading?: boolean;
  data?: any;
  error?: any;
}

export function useFetchData<Transform extends (data: any) => any>(
  url: string,
  {
    config,
    transform,
    pause,
  }: { config?: RequestInit; transform?: Transform; pause?: boolean } = {},
) {
  // Use single state to avoid unncessary re-renders
  const [state, setState] = useState({ isLoading: true, data: null, error: null } as State);
  const updateState = (newState: State) => setState((state) => ({ ...state, ...newState }));

  const fetchData = async () => {
    updateState({ isLoading: true });
    const response = await fetch(url, config);

    if (response.ok) {
      const data = await response.json();
      updateState({ isLoading: false, data: transform != null ? transform(data) : data });
    } else {
      updateState({ isLoading: false, error: { status: response.status } });
    }
  };

  useEffect(() => {
    if (!pause) {
      fetchData();
    }
  }, [url, JSON.stringify(config), pause]);

  const { isLoading, data, error } = state;
  return [isLoading, data, error];
}
