import { Category, Padding, Size, Tile } from '@drawbotics/react-drylus';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { useLoadSortedUnitsByVisits } from '~/pods/insights/utils';
import { createTranslate } from '~/utils/translation';

import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';
import { Row } from './Row';

const t = createTranslate('pods.insights_reports.components.charts.most_visited_units');

interface MostVisitedUnitsProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const MostVisitedUnits = ({ units, dateRange }: MostVisitedUnitsProps) => {
  const unitsByVisits = useLoadSortedUnitsByVisits(units, dateRange);
  const topUnitsByVisits = unitsByVisits.slice(0, 5);
  const totalVisits = unitsByVisits.reduce((memo, u) => memo + u.visits.length, 0);

  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{t('title')}</ChartTitle>
      <Padding size={{ top: Size.SMALL }}>
        {topUnitsByVisits.map((u, index) => {
          return (
            <Row
              key={u.id}
              left={<Text>{u.name}</Text>}
              right={
                <Text bold category={Category.SUCCESS}>
                  {Math.ceil((u.visits.length / totalVisits) * 100)}%
                </Text>
              }
              showSeparator={index !== topUnitsByVisits.length - 1}
            />
          );
        })}
      </Padding>
    </Tile>
  );
};
