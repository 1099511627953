import { Text } from '@drawbotics/react-drylus';
import React from 'react';

import efficiencyPlaceholder from '~/pods/product-stats/images/efficiency-placeholder.svg';
import { createTranslate } from '~/utils';

import { EmptyTile } from './EmptyTile';

const tt = createTranslate('pods.product_stats.components.meeting_efficiency_tile');

export const MeetingEfficiencyTile = () => {
  return (
    <EmptyTile
      lockRatio={false}
      title={tt('meeting_efficiency')}
      comingSoon
      image={efficiencyPlaceholder}
      text={<Text>{tt('track_meetings')}</Text>}
    />
  );
};
