import { OperationContext } from 'urql';

import { useMosaicNavigation } from './use-mosaic-navigation';

export function useOrgHeader(): Partial<OperationContext> {
  const { organisationToken } = useMosaicNavigation();

  if (organisationToken == null) return {};

  return {
    fetchOptions: {
      headers: {
        'X-Organisation-Public-Token': organisationToken,
      },
    },
  };
}
