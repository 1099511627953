import { getJwt } from '@drawbotics/auth';
import { gqlFetch } from '@tokamakjs/common';
import { Injectable } from '@tokamakjs/react';
import urlJoin from 'url-join';

import { Project } from './domain';
import { ProjectQuery } from './queries';

@Injectable()
export class ProjectApi {
  public async fetchProject(slug: string): Promise<Project> {
    const response = await gqlFetch({
      url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${getJwt()}` },
      query: ProjectQuery,
      variables: { projectSlug: slug },
    });

    if (!response.ok) {
      throw new Error();
    }

    const body = await response.json();

    if (body.errors != null) {
      console.error(body.errors[0]);
      throw new Error(body.errors[0]);
    }

    return Project.fromJson(body.data.project);
  }
}
