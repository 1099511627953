import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  capitalFirst: css`
    display: inline-block;
    &::first-letter {
      text-transform: capitalize;
    }
  `,
};

export const CapitalFirst = ({ children }: { children: ReactNode }) => {
  return <div className={styles.capitalFirst}>{children}</div>;
};
