import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Paragraph,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.participants_modal');

interface ParticipantsModalProps {
  visible: boolean;
  participants: Array<string>;
  onClickClose: VoidFunction;
  onClickEdit: VoidFunction;
  readOnly: boolean;
}

export const ParticipantsModal = ({
  visible,
  participants,
  onClickClose,
  onClickEdit,
  readOnly,
}: ParticipantsModalProps) => {
  return (
    <Modal
      visible={visible}
      title={tt('additional_participants')}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          {readOnly ? null : (
            <FlexItem>
              <Button onClick={onClickEdit} tier={Tier.TERTIARY}>
                {tt('edit_list')}
              </Button>
            </FlexItem>
          )}
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button category={Category.INFO} onClick={onClickClose}>
              {tt('ok')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      {participants.map((email) => (
        <Paragraph key={email}>{email}</Paragraph>
      ))}
    </Modal>
  );
};
