import { Attachment, ID, UrlString } from '~/types';

import { Organisation } from './organisation';

export enum CategoryType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  IFRAME = 'IFRAME',
  LISTING = 'LISTING',
}

export interface Asset {
  id: string;
  url: UrlString;
}

export interface AttachmentAsset extends Asset {
  filename: string;
}

export type IFrameCategorySlug =
  | 'revo'
  | 'virtual_tour'
  | 'video'
  | 'map'
  | 'configurator'
  | 'panorama'
  | 'fiscal_simulation'
  | 'pinel'
  | 'loan_simulation';
export type ImageCategorySlug =
  | 'access'
  | 'city'
  | 'interior_3d'
  | 'shoebox'
  | 'exterior_3d'
  | 'site_plan';
export type PDFCategorySlug = 'brochure' | 'finitions';
export type ListingCategorySlug = 'listing';
export type CategorySlug =
  | IFrameCategorySlug
  | ImageCategorySlug
  | PDFCategorySlug
  | ListingCategorySlug;

export interface Category {
  id: string;
  slug?: CategorySlug;
  name?: string;
  type: CategoryType;
  position: number;
  assets?: Array<Asset | AttachmentAsset>;
}

export type SectionSlug = 'program' | 'units' | 'neighborhood' | 'finance';

export interface Section {
  id: string;
  slug?: SectionSlug;
  name?: string;
  position: number;
  hidden?: boolean;
  categories: Array<Category>;
  possibleCategories: Array<CategorySlug>;
}

export type MinimalSection = Omit<Section, 'categories' | 'possibleCategories'>;

export enum PresentationStatus {
  INITIATED = 'INITIATED',
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export type PresentationAddress = {
  id: ID;
  countryCode: string;
  region: string;
  city: string;
};

export interface Presentation {
  id: string;
  presentationUrl: string;
  name: string;
  tagline?: string;
  description?: string;
  address: PresentationAddress;
  status: PresentationStatus;
  coverPicture?: Attachment;
  organisation: Organisation;
  sections?: Array<Section>;
}
