import gql from 'fraql';

import { Currency, ErrorType, SurfaceUnit } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

import { BaseUnit, Unit } from '../types';

export interface EstateQueryData {
  estate: {
    currency: Currency;
    surfaceUnit: SurfaceUnit;
    units: Array<BaseUnit>;
  };
}

export interface EstateQueryVariables {
  publicToken: string;
}

function _estateToUnits(estate: EstateQueryData['estate']): Array<Unit> {
  const { units, currency, surfaceUnit } = estate;

  return units.map((unit: BaseUnit) => ({
    ...unit,
    terrace: unit.terraces != null,
    price: {
      value: unit.price,
      currency: currency,
    },
    surface: {
      value: unit.surface,
      unit: surfaceUnit,
    },
  }));
}

const EstateQuery = gql`
  query WebsiteBuilderEstateQuery($publicToken: ID!) {
    listing(publicToken: $publicToken) {
      currency
      surfaceUnit
      units {
        id
        reference
        surface
        price
        availability
        floorplan
        bedrooms
        terraces
      }
    }
  }
`;

export function useFetchListing(publicToken: string): {
  isLoading: boolean;
  units: Array<Unit>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { publicToken },
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    units: res.data?.estate != null ? _estateToUnits(res.data.estate) : [],
  };
}
