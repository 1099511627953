import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import chunk from 'lodash/chunk';

import { DateRange } from '../types';

export * from './chart-theme';
export * from './get-data-in-range';
export * from './hooks';
export * from './status-colors';
export * from './get-y-grid-lines';
export * from './append-token-to-url';
export * from './get-granurality';
export * from './generate-bar-chart-data';

export function getEmptyDates(
  { start, end }: DateRange,
  precision: OpUnitType,
  format: string,
): Record<string, number> {
  const totalSteps = end.diff(start, precision) + 1 || 1;

  return new Array(totalSteps).fill(0).reduce((memo, _, index) => {
    const newKey = start.add(index, precision).startOf(precision).format(format);

    memo[newKey] = 0;
    return memo;
  }, {});
}

export function toDataPoints(values: Record<string, number>): Array<{ x: string; y: number }> {
  return Object.entries(values)
    .map(([x, y]) => ({ x, y }))
    .sort((a, b) => dayjs(a.x, 'YYYY-M-DD').diff(dayjs(b.x, 'YYYY-M-DD')));
}

export function isDateInRange(date: Dayjs | null, range: DateRange) {
  if (date == null) return false;
  const { start, end } = range;
  // '[]' means start and end days inclusive
  return date.isBetween(start, end, 'day', '[]');
}

export function compressToNPoints(dataPoints: Array<{ x: string | number; y: number }>, n: number) {
  const chunkSize = Math.ceil(dataPoints.length / n);
  const chunked = chunk(dataPoints, chunkSize);
  return chunked
    .map((chunk) => chunk.reduce((memo, point) => memo + point.y, 0))
    .map((value, i) => ({ x: i, y: value }));
}
