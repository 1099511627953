import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';
import immer from 'immer';

import { Project } from '../api/domain';

export interface ProjectState {
  project?: Project;
}

@Injectable()
@StoreConfig({ name: 'project', resettable: true, producerFn: immer })
export class ProjectStore extends Store<ProjectState> {
  constructor() {
    super({});
  }
}
