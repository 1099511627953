import { ResponsiveLine } from '@nivo/line';
import React from 'react';

interface FlushChartProps {
  data: Array<{
    id: string;
    color: string;
    data: Array<{ x: string | number; y: number }>;
  }>;
}

export const FlushChart = ({ data }: FlushChartProps) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 2, bottom: 2 }}
      // @ts-ignore Incorrectly typed
      areaBaselineValue={-5}
      curve="monotoneX"
      enableArea={true}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      isInteractive={false}
      colors={{ datum: 'color' }}
    />
  );
};
