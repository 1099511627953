import gql from 'fraql';
import { omit } from 'lodash';

import { Portal } from '~/pods/multidiffusion/domain';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

import { Organisation } from '../../types/organisation';

interface OrganisationQueryData {
  organisation: Organisation;
}

const OrganisationQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      name
      currency
      isAgency: agency
      portals: activePortals {
        name
        slug
      }
      steps: funnelSteps {
        id
        name
        slug
        position
        color
        type
        requirements
      }
      leads {
        typologies
      }
      projects {
        id
        publicToken
        name
        slug
        leadNotificationEmails
        leadNotificationSetting
        assignedAgencies {
          id
          name
        }
      }
    }
  }
`;

export function useFetchOrganisation(): {
  isLoading: boolean;
  organisation?: Organisation;
  error?: ErrorType;
} {
  const res = useMosaicQuery<OrganisationQueryData, null>({
    query: OrganisationQuery,
  });

  const portals = (res.data?.organisation?.portals ?? []).map((p) => {
    return Portal.fromData(p);
  });

  const tempOrg = res.data?.organisation;

  const finalOrg =
    tempOrg == null
      ? undefined
      : {
          ...omit(tempOrg, 'portals'),
          portals: portals ?? [],
        };

  return {
    isLoading: res.isLoading,
    organisation: finalOrg,
    error: buildError(res.error),
  };
}
