import { useNavigate, useParams } from 'react-router';
import urlJoin from 'url-join';

import { ID, UrlString } from '~/types';

import { useAuth } from './use-auth';

enum NavigationContext {
  Organisation,
  Project,
  Other,
}
interface UseMosaicNavigationOutput {
  organisationToken?: ID;
  projectToken?: ID;

  // Organisation
  getUrlInOrg: (...path: Array<string>) => UrlString;
  getUrlToOrg: (newOrgToken: ID, ...path: Array<string>) => UrlString;
  navigateInOrg: (...path: Array<string>) => void;
  navigateToOrg: (newOrgToken: ID, ...path: Array<string>) => void;

  // Project
  getUrlInProject: (...path: Array<string>) => UrlString;
  getUrlToProject: (newProjectToken: ID, ...path: Array<string>) => UrlString;
  navigateInProject: (...path: Array<string>) => void;
  navigateToProject: (newProjectToken: ID, ...path: Array<string>) => void;
  switchProject: (newProjectToken: ID) => void;

  // Project & Organisation
  getUrlToProjectInOrg: (newOrgToken: ID, newProjectToken: ID, ...path: Array<string>) => UrlString;
  navigateToProjectInOrg: (newOrgToken: ID, newProjectToken: ID, ...path: Array<string>) => void;

  // Specific
  getAddressEditUrl: () => UrlString;
  getHomepageUrl: () => UrlString;
  navigateToHome: () => void;

  // Flags
  navigationContext: NavigationContext;
  isOrganisationContext: Boolean;
  isProjectContext: Boolean;
}

export function useMosaicNavigation(): UseMosaicNavigationOutput {
  // projectSlug is the legacy name of that parameter, should be projectToken going forwards
  const { organisationToken: maybeOrganisationToken, projectSlug: projectToken } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const organisationToken = maybeOrganisationToken ?? user?.organisation?.publicToken;

  /*
   * Organisation
   */
  const getUrlInOrg = (...path: Array<string>) => {
    if (organisationToken == null) {
      console.error('No organisationToken was found in the URL');
      return '#';
    }

    return urlJoin('/organisation', organisationToken, ...path);
  };

  const getUrlToOrg = (orgToken: ID, ...path: Array<string>) => {
    return urlJoin('/organisation', orgToken, ...path);
  };

  const navigateInOrg = (...path: Array<string>) => {
    navigate(getUrlInOrg(...path));
  };

  const navigateToOrg = (orgToken: ID, ...path: Array<string>) => {
    navigate(getUrlToOrg(orgToken, ...path));
  };

  /*
   * Project
   */
  const getUrlInProject = (...path: Array<string>) => {
    if (organisationToken == null) {
      console.error('No organisationToken was found in the URL');
      return '#';
    } else if (projectToken == null) {
      console.error('No projectId was found in the URL');
      return '#';
    }

    return urlJoin('/organisation', organisationToken, '/project', projectToken, ...path);
  };

  const getUrlToProject = (newProjectToken: ID, ...path: Array<string>) => {
    if (organisationToken == null) {
      console.error('No organisationToken was found in the URL');
      return '#';
    }

    return urlJoin('/organisation', organisationToken, '/project', newProjectToken, ...path);
  };

  const navigateInProject = (...path: Array<string>) => {
    navigate(getUrlInProject(...path));
  };

  const navigateToProject = (newProjectToken: ID, ...path: Array<string>) => {
    navigate(getUrlToProject(newProjectToken, ...path));
  };

  const switchProject = (newProjectToken: ID) => {
    if (projectToken == null) {
      console.error(
        'Invariant violated: switchProject called in a context with no current project.',
      );
      return;
    }

    const newUrl = location.pathname.replace(projectToken, newProjectToken);
    navigate(newUrl);
  };

  /*
   * Organisation & Project
   */
  const getUrlToProjectInOrg = (newOrgToken: ID, newProjectToken: ID, ...path: Array<string>) => {
    return urlJoin('/organisation', newOrgToken, '/project', newProjectToken, ...path);
  };

  const navigateToProjectInOrg = (newOrgToken: ID, newProjectToken: ID, ...path: Array<string>) => {
    navigate(getUrlToProjectInOrg(newOrgToken, newProjectToken, ...path));
  };

  /*
   * Specific
   */
  const getAddressEditUrl = () => {
    return (() => {
      if (user == null || organisationToken == null) return '#';
      else if (user.isAgent) return '/agency';
      else return getUrlToOrg(organisationToken, '/settings/basic-info');
    })();
  };

  const getHomepageUrl = () => {
    if (user?.isAgent) {
      const lastVisitedOrg = localStorage.getItem(`mosaic-${user.id}-last-visited-org`);
      if (lastVisitedOrg != null) return getUrlToOrg(lastVisitedOrg);
      else return '/agency';
    } else return getUrlInOrg();
  };

  const navigateToHome = () => {
    navigate(getHomepageUrl());
  };

  /*
   * Flags
   */
  const navigationContext = (() => {
    if (projectToken != null) return NavigationContext.Project;
    else if (organisationToken != null) return NavigationContext.Organisation;
    else return NavigationContext.Other;
  })();
  const isOrganisationContext = navigationContext === NavigationContext.Organisation;
  const isProjectContext = navigationContext === NavigationContext.Project;

  return {
    organisationToken,
    projectToken,
    navigateToOrg,
    getUrlInOrg,
    getUrlToOrg,
    getUrlToProject,
    navigateInOrg,
    getUrlInProject,
    navigateInProject,
    navigateToProject,
    switchProject,
    getUrlToProjectInOrg,
    navigateToProjectInOrg,
    getAddressEditUrl,
    getHomepageUrl,
    navigateToHome,
    navigationContext,
    isOrganisationContext,
    isProjectContext,
  };
}
