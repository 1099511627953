import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Button,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Padding,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  Paragraph,
  SearchInput,
  Shade,
  Size,
  Spinner,
  Text,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment, useEffect, useState } from 'react';

import { MosaicPageTitle, NewProjectModal } from '~/components';
import listing from '~/images/placeholders/listing.svg';
import { useFetchProjectCount, useFetchProjects } from '~/pods/dashboard/utils';
import { createTranslate, useAuth, useDebounce } from '~/utils';

import { ProjectsTable } from './ProjectsTable';

const ttDashboard = createTranslate('pods.insights.routes.dashboard');
const tt = createTranslate('pods.dashboard.routes.dashboard.components.projects_panel');

const styles = {
  footer: css`
    padding-top: ${sv.paddingExtraSmall};
    padding-bottom: ${sv.paddingExtraSmall};
  `,
};

const PROJECTS_PER_PAGE = 10;

const GetStartedPlaceholder = () => {
  return (
    <Flex direction={FlexDirection.VERTICAL}>
      <FlexItem>
        <img src={listing} width={150} />
      </FlexItem>
      <FlexItem>
        <Paragraph style={{ maxWidth: 500 }} align={Align.CENTER}>
          <Text shade={Shade.LIGHT}>{tt('head_over')}</Text>
        </Paragraph>
      </FlexItem>
    </Flex>
  );
};

export const ProjectsPanel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { user } = useAuth();

  const { isLoading: isProjectsLoading, projects } = useFetchProjects(
    {
      name: debouncedSearchTerm,
    },
    { offset: (page - 1) * PROJECTS_PER_PAGE, limit: PROJECTS_PER_PAGE - 1 },
  );
  const { isLoading: isProjectCountLoading, count } = useFetchProjectCount({
    name: debouncedSearchTerm,
  });

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchTerm]);

  const isSearching = debouncedSearchTerm !== '' && (isProjectsLoading || isProjectCountLoading);
  const noProjects = projects != null && projects.length === 0 && debouncedSearchTerm === '';
  const totalPages = Math.ceil((count ?? PROJECTS_PER_PAGE) / PROJECTS_PER_PAGE);

  return (
    <Fragment>
      <Flex justify={FlexJustify.SPACE_BETWEEN}>
        <FlexItem>
          <MosaicPageTitle>{ttDashboard('company_dashboard')}</MosaicPageTitle>
        </FlexItem>
        <FlexItem>
          <Flex>
            {isSearching ? (
              <FlexItem>
                <Spinner />
              </FlexItem>
            ) : null}
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <SearchInput
                minimal
                value={searchTerm}
                placeholder={tt('search')}
                onChange={setSearchTerm}
              />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            {user?.organisation?.isAgency ? null : (
              <Fragment>
                <FlexItem>
                  <Button
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                    style={{ width: '100%' }}
                    color={Color.BLUE}
                    leading={<Icon name="plus" />}>
                    {tt('new_project')}
                  </Button>
                  <NewProjectModal
                    isVisible={isModalVisible}
                    onClickClose={() => setIsModalVisible(false)}
                  />
                </FlexItem>
              </Fragment>
            )}
          </Flex>
        </FlexItem>
      </Flex>
      <Panel
        style={{ boxShadow: sv.elevation1, borderRadius: 0 }}
        body={
          <PanelBody noPadding>
            {noProjects ? (
              <Padding size={Size.LARGE}>
                <GetStartedPlaceholder />
              </Padding>
            ) : (
              <ProjectsTable
                onNewProject={() => {
                  setIsModalVisible(true);
                }}
                isSearching={debouncedSearchTerm !== ''}
                projects={projects}
                isLoading={isProjectsLoading || isProjectCountLoading}
              />
            )}
          </PanelBody>
        }
        footer={
          noProjects ? undefined : (
            <PanelFooter className={styles.footer}>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  <Pagination
                    prevLabel={tt('previous')}
                    nextLabel={tt('next')}
                    pages={totalPages}
                    onChange={setPage}
                    value={page}
                  />
                </FlexItem>
              </Flex>
            </PanelFooter>
          )
        }
      />
    </Fragment>
  );
};
