import sv from '@drawbotics/drylus-style-vars';
import { CircularProgress, Color } from '@drawbotics/react-drylus';
import {
  Align,
  Button,
  Category,
  Map as DrylusMap,
  Flex,
  FlexAlign,
  FlexItem,
  FlexSpacer,
  Icon,
  InlineEdit,
  Input,
  Margin,
  Shade,
  Size,
  TextArea,
  Tier,
  UploadHelper,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import { css, cx } from 'emotion';
import React, { Fragment, useState } from 'react';

import { AssetData, UpdateLinkData, Website } from '~/pods/website-builder/api/domain';
import { ID } from '~/types';
import {
  UploadedFile,
  createTranslate,
  createTranslateWithLocale,
  run,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { AnyWebsiteSectionData, MapSection } from '../../../api/domain';
import { LoremIpsum } from '../../../utils';
import { AddressInputModal } from '../AddressInputModal';
import { EditableElementWrapper } from '../EditableElementWrapper';
import { LinkModal } from '../LinkModal';
import { Themed } from '../Themed';

const BASE_TRANSLATE_KEY = 'pods.website_builder.routes.website_builder';

const tt = createTranslate(BASE_TRANSLATE_KEY);

const MARKER_SIZE = 100;

const styles = {
  mapTile: css`
    position: relative;
    outline: 2px dashed ${sv.blue};
    outline-offset: ${sv.marginExtraSmall};
    width: calc(100% - ${sv.marginSmall});
    height: calc(100% - ${sv.marginSmall});
    padding: ${sv.paddingExtraSmall};
    display: flex;
    align-items: center;
    justify-content: center;

    > [data-element='upload-overlay'] {
      opacity: 0;
    }

    &:hover {
      > i {
        display: none;
      }

      > [data-element='upload-overlay'] {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  empty: css`
    background: ${sv.neutralLight};
    width: calc(100% - ${sv.marginSmall});
    height: calc(100% - ${sv.marginSmall});
    margin-left: ${sv.marginExtraSmall};
    margin-top: ${sv.marginExtraSmall};
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${sv.darkOverlay};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: ${sv.transitionShort};
    color: ${sv.colorPrimaryInverse};
    z-index: 3;
  `,
  mapSection: css`
    padding-left: ${sv.paddingSmall};
  `,
  container: css`
    background: ${sv.neutralLighter};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${sv.paddingHuge};
    width: 450px;

    [class*='Hint__root'] {
      text-align: left;
      text-transform: capitalize;
    }
  `,
  textarea: css`
    textarea {
      height: 120px;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  `,
  marker: css`
    position: relative;
    border-radius: ${sv.borderRadiusSmall};
    background-color: ${sv.white};
    filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.15));
    padding: ${sv.paddingSmall};
    width: ${MARKER_SIZE}px;
    height: ${MARKER_SIZE}px;

    transform: translate(0px, ${-MARKER_SIZE / 2}px);

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-left: ${sv.marginExtraSmall} solid transparent;
      border-right: ${sv.marginExtraSmall} solid transparent;
      border-top: ${sv.marginExtraSmall} solid ${sv.white};
      border-bottom: 0;
    }
  `,
};

interface MapProps {
  map: MapSection;
  website: Website;
  onChangeSection: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeWebsite: (data: Partial<Website>) => void;
  onChangeAsset: (sectionId: string, data: AssetData) => void;
  onChangeLink: (sectionId: string, data: UpdateLinkData) => void;
}

export const Map = ({
  map,
  website,
  onChangeSection,
  onChangeWebsite,
  onChangeAsset,
  onChangeLink,
}: MapProps) => {
  const { logo, localeCode: language, latitude, longitude, address } = website;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ctaEditOpen, setCtaEditOpen] = useState(false);
  const form = useForm({ title: map.title ?? '', description: map.description ?? '' });
  const { showAlert } = useAlert();
  const { uploadFiles, info, isLoading: isUploading } = useFileUpload(useMosaicMutation);
  const tl = createTranslateWithLocale(BASE_TRANSLATE_KEY, language);

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (sectionId: ID, data: Pick<MapSection, 'title' | 'description'>) => {
    if (data.title != null) {
      data.title.length <= 200 ? onChangeSection(sectionId, data) : _handleTextLengthError();
    }
    if (data.description != null) {
      data.description.length <= 800 ? onChangeSection(sectionId, data) : _handleTextLengthError();
    }
  };

  const _handleOnUpload = async (fileList: FileList): Promise<void> => {
    const res = await uploadFiles(fileList);
    const uploadedFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    if (uploadedFiles.length > 0) {
      const assetsFromFiles: Array<AssetData> = uploadedFiles.map((f) => ({
        signedBlobId: f.signedBlobId,
        url: f.url,
        filename: f.originalFile.name,
      }));
      onChangeAsset(map.id, {
        assetId: map.assets[0]?.assetId,
        signedBlobId: assetsFromFiles[0].signedBlobId,
      });
    }
  };

  const _handleUpdateAddress = (data: Partial<Website>) => {
    onChangeWebsite(data);
    if (map.assets[0] != null) {
      onChangeAsset(map.id, {
        assetId: map.assets[0].assetId,
        destroy: true,
      });
    }
  };

  return (
    <Fragment>
      <div className={styles.mapSection}>
        <Flex align={FlexAlign.STRETCH}>
          <FlexItem
            flex
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div
              className={cx(styles.mapTile, {
                [styles.empty]: address == null && map.assets.length === 0,
              })}>
              {run(() => {
                if (isUploading) {
                  return (
                    <CircularProgress
                      size={Size.SMALL}
                      percentage={info[0].progress ?? 0}
                      color={Color.GREEN}
                    />
                  );
                }
                if (map.assets.length > 0) {
                  return <img src={map.assets[0].url} />;
                }
                return latitude == null || longitude == null ? (
                  <Icon shade={Shade.MEDIUM} name="image" />
                ) : (
                  <DrylusMap
                    minZoom={13}
                    maxZoom={13}
                    style={{ height: '100%', width: '100%' }}
                    accessToken={process.env.MAPBOX_ACCESS_TOKEN ?? ''}
                    markers={[
                      {
                        marker: (
                          <div className={styles.marker}>
                            <img
                              src={
                                logo?.url ??
                                'https://iconape.com/wp-content/png_logo_vector/fake-studio-logo.png'
                              }
                            />
                          </div>
                        ),
                        coordinates: {
                          lat: Number(latitude),
                          lng: Number(longitude),
                        },
                      },
                    ]}
                  />
                );
              })}
              <div data-element="upload-overlay" className={styles.overlay}>
                <UploadHelper
                  allowedFileFormats=".jpg, .jpeg, .png, .svg"
                  onUploadFiles={(files) => _handleOnUpload(files)}>
                  <Button
                    tier={Tier.SECONDARY}
                    inversed
                    leading={<Icon name="upload" />}
                    disabled={isUploading}>
                    {tt('upload_map_image')}
                  </Button>
                </UploadHelper>
                <Margin size={{ vertical: Size.SMALL }} />
                <Button
                  disabled={isUploading}
                  leading={<Icon name="link" />}
                  onClick={() => setIsModalVisible(true)}
                  inversed
                  tier={Tier.SECONDARY}>
                  {tt('add_address')}
                </Button>
              </div>
            </div>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <div className={styles.container}>
              <InlineEdit
                onCancel={() => form.set(map.title ?? '', 'title')}
                onClickConfirm={() => _handleUpdateSection(map.id, { title: form.get('title') })}
                edit={
                  <Input
                    style={{ fontSize: '1rem' }}
                    name="mapTitle"
                    placeholder={tl('title_placeholder')}
                    value={form.get('title')}
                    onChange={(v) => form.set(v.toString(), 'title')}
                    error={form.get('title').length > 200 && tl('max_200_characters_error')}
                  />
                }>
                <Themed.Title
                  size={2}
                  align={Align.CENTER}
                  style={{
                    opacity: map.title != null && map.title !== '' ? '1' : '0.5',
                  }}>
                  {map.title != null && map.title !== '' ? map.title : LoremIpsum.MEDIUM}
                </Themed.Title>
              </InlineEdit>
              <InlineEdit
                onCancel={() => form.set(map.description ?? '', 'description')}
                onClickConfirm={() =>
                  _handleUpdateSection(map.id, { description: form.get('description') })
                }
                edit={
                  <TextArea
                    className={styles.textarea}
                    name="mapDescription"
                    placeholder={tl('project_intro_placeholder')}
                    value={form.get('description')}
                    onChange={(v) => form.set(v.toString(), 'description')}
                    error={form.get('description').length > 800 && tl('max_800_characters_error')}
                  />
                }>
                <Themed.Paragraph
                  align={Align.CENTER}
                  style={{
                    opacity: map.description != null && map.description !== '' ? '1' : '0.5',
                  }}>
                  {map.description != null && map.description !== ''
                    ? map.description
                    : LoremIpsum.LONG}
                </Themed.Paragraph>
              </InlineEdit>
              <Margin size={{ top: Size.DEFAULT }}>
                <EditableElementWrapper onClick={() => setCtaEditOpen(true)}>
                  <Themed.Button>{map.link.label}</Themed.Button>
                </EditableElementWrapper>
              </Margin>
            </div>
          </FlexItem>
        </Flex>
      </div>
      <LinkModal
        sections={website.sections}
        visible={ctaEditOpen}
        onClickClose={() => setCtaEditOpen(false)}
        link={map.link}
        onChange={(data) => onChangeLink(map.id, data)}
      />
      <AddressInputModal
        website={website}
        isVisible={isModalVisible}
        onClickClose={() => setIsModalVisible(false)}
        onChange={(data) => _handleUpdateAddress(data)}
      />
    </Fragment>
  );
};
