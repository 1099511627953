import gql from 'fraql';

import { Project } from '~/pods/dashboard/types';
import { useMosaicQuery } from '~/utils';

interface ProjectsQueryData {
  projects: Array<Project>;
}

interface ProjectsQueryFilters {
  name?: string;
}

interface ProjectsQueryPagination {
  limit: number;
  offset: number;
}

interface ProjectsQueryVariables {
  filters: ProjectsQueryFilters;
  pagination?: ProjectsQueryPagination;
}

const ProjectsQuery = gql`
  query ContextSwitcherProjectsQuery(
    $filters: ProjectFilterInput!
    $pagination: PaginationFilterInput
  ) {
    projects(filter: $filters, pagination: $pagination) {
      id
      name
      publicToken
    }
  }
`;

export function useFetchProjects(
  filters: ProjectsQueryFilters,
  pagination?: ProjectsQueryPagination,
  options?: { pause?: boolean },
): { isLoading: boolean; projects: Array<Project>; refetchProjects: VoidFunction } {
  const { refetch, isLoading, data } = useMosaicQuery<ProjectsQueryData, ProjectsQueryVariables>({
    query: ProjectsQuery,
    variables: { filters, pagination },
    pause: options?.pause,
  });

  // In dev and staging, we append a fake project to the list that takes the developer to the Insights demo
  if (
    ['development', 'staging'].includes(process.env.APP_ENV) &&
    'showcase project'.includes(filters.name?.toLowerCase() ?? '')
  ) {
    return {
      isLoading: isLoading,
      projects: [
        ...(data?.projects ?? []),
        { id: '1', name: 'Showcase Project', publicToken: 'showcase' },
      ],
      refetchProjects: () => refetch({ requestPolicy: 'network-only' }),
    };
  }

  return {
    isLoading: isLoading,
    projects: data?.projects ?? [],
    refetchProjects: () => refetch({ requestPolicy: 'network-only' }),
  };
}
