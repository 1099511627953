import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Content,
  Icon,
  Icons,
  Layout,
  Margin,
  Padding,
  Position,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { createTranslate, useMosaicNavigation } from '~/utils';

import { LeadDetails, LogList } from './components';

const styles = {
  leadDetails: css`
    width: 500px;
    background: ${sv.backgroundColor};
  `,
  layout: css`
    [data-element='layout-bar'] {
      background: ${sv.backgroundColor};
    }
  `,
};

const tt = createTranslate('pods.crm.routes.lead');

export const Lead = () => {
  const { leadId } = useParams();
  const [key, setKey] = useState(Math.random());
  const { projectToken, navigateInProject, navigateInOrg } = useMosaicNavigation();

  const handleRefresh = () => setKey(Math.random());

  const handleClickBack = () => {
    /*
      We dont use history.back() here because
      when creating a new lead, you will be directed to the created lead's details page.
      On pressing "Back" on this page, you will be brought back to the New Lead page
      instead of the CRM table.

      Even with an imperative navigation, the filters will be restored from local storage anyway
      fulfilling the need for having the CRM table filled with the same results as if you pressed back on the browser
    */
    projectToken != null ? navigateInProject('/crm') : navigateInOrg('/crm');
  };

  return (
    <Layout
      className={styles.layout}
      bar={
        <Content fullHeight>
          <div className={styles.leadDetails}>
            <Padding
              size={{
                vertical: Size.LARGE,
                horizontal: Size.EXTRA_LARGE,
              }}>
              <Margin size={{ bottom: Size.DEFAULT }}>
                <Button
                  onClick={handleClickBack}
                  tier={Tier.SECONDARY}
                  leading={<Icon name={Icons.arrowLeft} />}>
                  {tt('back_button')}
                </Button>
              </Margin>
              <LeadDetails reloadLogs={handleRefresh} leadId={leadId!} />
            </Padding>
          </div>
        </Content>
      }
      position={Position.LEFT}>
      <LogList refreshKey={key} leadId={leadId!} />
    </Layout>
  );
};
