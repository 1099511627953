import dayjs from 'dayjs';

import { DateRange, InsightsEstate } from '../../types';
import { isDateInRange } from '..';

export function useLoadSortedUnitsByVisits(units: InsightsEstate['units'], range: DateRange) {
  return units
    .map((u) => ({
      ...u,
      visits: u.visits.filter((v) => isDateInRange(dayjs(v), range)),
    }))
    .sort((a, b) => b.visits.length - a.visits.length);
}
