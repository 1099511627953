import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CircularProgress,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Icon,
  Input,
  Label,
  Panel,
  PanelBody,
  PanelFooter,
  Shade,
  Size,
  Spinner,
  Tier,
  UploadHelper,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React, { useEffect } from 'react';

import { SettingsSection } from '~/components';
import { Attachment } from '~/types';
import {
  UploadedFile,
  createTranslate,
  generateColorFromString,
  isAttachment,
  run,
  translate as t,
  useAuth,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { User } from '../types';
import { useFetchUser } from '../utils';

const tt = createTranslate('pods.settings.routes.profile');
const ttProfile = createTranslate('pods.settings.routes.basic_info');

const styles = {
  header: css`
    background: ${sv.neutralDarkest};
    height: 100px;
    padding-left: 160px;
    position: relative;
  `,
  spacer: css`
    height: 100px;
  `,
  uploaderContainer: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  pictureContainer: css`
    height: 160px;
    width: 160px;
    border-radius: 1000px;
    background: ${sv.white};
    z-index: 2;
    border: 1px solid ${sv.neutralLight};
    padding: ${sv.paddingExtraSmall};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  picture: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: ${sv.white};
    font-size: 4em;
    font-weight: 300;
    border-radius: 1000px;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  uploadArea: css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${sv.defaultTransition};

    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.8);
    }
  `,
};

interface UpdateProfileVariables {
  user: {
    firstName?: string;
    lastName?: string;
    profilePicture?: string; // Signed blob id
  };
}

interface UpdateProfileResult {
  updateProfile: {
    user: User;
  };
}

const updateProfileMutation = gql`
  mutation UpdateProfile($user: UpdateProfileInput!) {
    updateProfile(input: $user) {
      user {
        id
        firstName
        lastName
        fullName @computed(type: User)
        profilePicture {
          id
          url
        }
      }
    }
  }
`;

interface ProfileForm {
  firstName: string;
  lastName: string;
  profilePicture: UploadedFile | Attachment;
}

export const ProfileDetails = () => {
  const { user, isLoading: isUserLoading } = useFetchUser();
  const { refreshUser } = useAuth();
  const { showAlert } = useAlert();
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);

  const { res: updateProfileResult, executeMutation: updateProfile } = useMosaicMutation<
    UpdateProfileResult,
    UpdateProfileVariables
  >(updateProfileMutation);

  const profileForm = useForm<ProfileForm>({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    profilePicture: user?.profilePicture,
  });

  const resetFormValues = () => {
    if (user != null) {
      profileForm.set(user?.firstName, 'firstName');
      profileForm.set(user?.lastName, 'lastName');
      profileForm.set(user?.profilePicture, 'profilePicture');
    }
  };

  const handleUpload = async (files: FileList) => {
    if (files.length > 0) {
      const res = await uploadFiles(files);
      const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
      const profilePicture = validFiles[0];

      const { error } = await updateProfile({
        user: {
          profilePicture:
            profilePicture != null && !isAttachment(profilePicture)
              ? profilePicture.signedBlobId
              : undefined,
        },
      });
      if (error != null) {
        showAlert({
          text: tt('update_error'),
          category: Category.DANGER,
        });
      } else {
        refreshUser(); // get new token to get new user details in currentUser
      }
    }
  };

  const handleSaveChanges = async () => {
    const { error } = await updateProfile({
      user: {
        ...profileForm.values,
        profilePicture: undefined,
      },
    });
    if (error != null) {
      showAlert({
        text: tt('update_error'),
        category: Category.DANGER,
      });
    } else {
      refreshUser(); // get new token to get new user details in currentUser
    }
  };

  useEffect(() => {
    if (user != null) {
      profileForm.set(user.firstName, 'firstName');
      profileForm.set(user.lastName, 'lastName');
      if (user.profilePicture != null) {
        profileForm.set(user.profilePicture, 'profilePicture');
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: t('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  const { profilePicture, firstName, lastName } = profileForm.values;

  const disableSaveButton =
    isUserLoading ||
    isFileUploading ||
    user == null ||
    (firstName === user?.firstName && lastName === user?.lastName) ||
    updateProfileResult.fetching;

  return (
    <SettingsSection
      left={
        <div className={styles.uploaderContainer}>
          <div className={styles.pictureContainer}>
            {run(() => {
              if (isUserLoading) {
                return <Spinner />;
              } else if (user != null) {
                return (
                  <div
                    className={styles.picture}
                    style={{
                      background:
                        profilePicture == null ? generateColorFromString(user.email) : undefined,
                    }}>
                    {profilePicture == null ? user.firstName[0] : <img src={profilePicture?.url} />}
                    <UploadHelper allowedFileFormats=".jpg,.png,.gif" onUploadFiles={handleUpload}>
                      <div className={styles.uploadArea}>
                        {isFileUploading && info.length > 0 ? (
                          <CircularProgress
                            size={Size.SMALL}
                            percentage={info[0].progress ?? 0}
                            color={Color.GREEN}
                          />
                        ) : (
                          <Icon name="photo-shooting" style={{ color: sv.neutralDarkest }} />
                        )}
                      </div>
                    </UploadHelper>
                  </div>
                );
              } else {
                return <Icon name="x-circle" shade={Shade.LIGHT} />;
              }
            })}
          </div>
        </div>
      }
      right={
        <Panel
          body={
            <PanelBody>
              <Grid columns={2} hGutters={Size.DEFAULT} vGutters={Size.DEFAULT}>
                <GridItem>
                  <FormGroup
                    label={<Label>{tt('first_name')}</Label>}
                    input={
                      <Input
                        loading={isUserLoading}
                        name="firstName"
                        value={profileForm.get}
                        onChange={profileForm.set}
                      />
                    }
                  />
                </GridItem>
                <GridItem>
                  <FormGroup
                    label={<Label>{tt('last_name')}</Label>}
                    input={
                      <Input
                        loading={isUserLoading}
                        name="lastName"
                        value={profileForm.get}
                        onChange={profileForm.set}
                      />
                    }
                  />
                </GridItem>
              </Grid>
            </PanelBody>
          }
          footer={
            <PanelFooter>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  <Button category={Category.INFO} tier={Tier.TERTIARY} onClick={resetFormValues}>
                    {ttProfile('clear')}
                  </Button>
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Button
                    trailing={
                      updateProfileResult.fetching ? <Spinner size={Size.SMALL} inversed /> : null
                    }
                    disabled={disableSaveButton}
                    onClick={handleSaveChanges}
                    category={Category.INFO}>
                    {tt('update_profile')}
                  </Button>
                </FlexItem>
              </Flex>
            </PanelFooter>
          }
        />
      }
    />
  );
};
