import { HexColor, Id } from '~/types';

import { Attachment } from './attachment';
import { Currency } from './currency';

export enum OrganisationRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  AGENCY_SALES_AGENT = 'AGENCY_SALES_AGENT',
  AGENCY_SALES_MANAGER = 'AGENCY_SALES_MANAGER',
}

export enum Subscription {
  REACH = 'reach',
  GROWTH = 'growth',
  ESSENTIALS = 'essentials',
  REVO = 'revo',
}

export enum TypologyFormat {
  FRENCH = 'FRENCH',
  BEDROOMS = 'BEDROOMS',
}

export interface Organisation {
  id: Id;
  publicToken: Id;
  name: string;
  currency?: Currency;
  isAgency: boolean;
  subscription?: Subscription;
  typologyFormat?: any;
  logo?: Attachment;
  primaryColor?: HexColor;
  secondaryColor?: HexColor;
}
