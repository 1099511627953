import { SearchInput, SearchInputProps, Size } from '@drawbotics/react-drylus';
import React, { useEffect, useState } from 'react';

import { Address } from '~/pods/crm/types';
import { createTranslate } from '~/utils';
import { useDebounce } from '~/utils/hooks';
import { GeocoderResult, useGeocoder } from '~/utils/hooks/use-geocoder';

const tt = createTranslate('pods.crm.routes.lead.components.lead_details.address_search');

interface AddressSearchProps<T> {
  name?: T;
  value?: Address;
  onChange?: (value: string, name?: T) => void;
  size?: SearchInputProps<T>['size'];
  error?: string;
  onPickAddress: (value: Address, name?: T) => void;
}

export const AddressSearch = <T extends string>({
  name,
  value,
  onChange,
  onPickAddress,
  error,
  size = Size.DEFAULT,
}: AddressSearchProps<T>) => {
  const [search, setSearch] = useState<string>(value?.fullAddress ?? '');
  const [results, setResults] = useState<Array<GeocoderResult>>();
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const { query } = useGeocoder();

  const handleQuery = async () => {
    setIsSearching(true);
    const queryResult = await query(debouncedSearchTerm);
    setResults(queryResult);
    setIsSearching(false);
  };

  const handleOnChange = (v: string) => {
    onChange?.(v, name);
    setSearch(v);
  };

  const handlePickResult = (value?: GeocoderResult) => {
    if (value != null) {
      onPickAddress(value, name);
      setSearch(value.fullAddress);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      handleQuery();
    } else {
      setResults(undefined);
    }
  }, [debouncedSearchTerm]);

  return (
    <SearchInput
      error={error}
      hint={tt('hint')}
      name={name}
      loading={isSearching}
      placeholder={tt('placeholder')}
      value={search}
      onChange={handleOnChange}
      options={results?.map((res: GeocoderResult, i: number) => ({
        value: `${i}`,
        label: res.fullAddress,
      }))}
      size={size}
      onClickResult={(index: string) => handlePickResult(results?.[Number(index)])}
    />
  );
};
