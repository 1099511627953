import sv from '@drawbotics/drylus-style-vars';
import { Align, Margin, Padding, Panel, PanelBody, Size, Title } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  panel: css`
    width: 500px;
    height: 500px;

    @media ${sv.screenL} {
      width: 75vw;
      height: initial;
    }
  `,
};

interface ActionPanelProps {
  children: ReactNode;
  title: string;
  image: string;
}

export const ActionPanel = ({ children, title, image }: ActionPanelProps) => {
  return (
    <Panel
      className={styles.panel}
      body={
        <PanelBody>
          <Padding
            size={{ horizontal: Size.HUGE, vertical: Size.EXTRA_LARGE }}
            responsive={{ L: { size: { horizontal: Size.LARGE, vertical: Size.DEFAULT } } }}>
            <Title noMargin align={Align.CENTER} size={4}>
              {title}
            </Title>
            <Margin size={{ vertical: Size.DEFAULT }}>
              <img width="100%" src={image} />
            </Margin>
            {children}
          </Padding>
        </PanelBody>
      }
    />
  );
};
