import humps from 'humps';
import urljoin from 'url-join';

import { useFetchData } from '~/utils';

import { FiscalSimulatorClient } from '../types';

export function useFetchClientData(token: string) {
  const { data, isLoading, error } = useFetchData<FiscalSimulatorClient>(
    urljoin(process.env.MOSAIC_HOST, `/projects/${token}/cost_simulator`),
  );

  const transformedData =
    data != null ? (humps.camelizeKeys(data) as FiscalSimulatorClient) : undefined;

  return { isLoading, error, data: transformedData };
}
