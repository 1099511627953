import { DateRange, InsightsSession, LineData } from '../../types';
import { extractVisits } from '../extract-visits';
import { getDataInRange } from '../get-data-in-range';

export function useLoadNumberOfVisitsOverRange(
  sessions: Array<InsightsSession>,
  range: DateRange,
): { sessionsData: LineData; visitsData: LineData } {
  const sessionsData = getDataInRange(range, sessions, 'createdAt');

  const visits = extractVisits(sessions);
  const visitsData = getDataInRange(range, visits, 'timestamp');

  return { sessionsData, visitsData };
}
