import { CheckboxFilter } from '@drawbotics/react-drylus';
import { cx } from 'emotion';
import startCase from 'lodash/startCase';
import React from 'react';

import { LeadsFilterTypes, Step } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

import { filterStyles } from './filter-styles';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');
const ts = createTranslate('pods.crm.status');

interface StatusFilterProps {
  steps: Array<Step>;
  filter: LeadsFilterTypes['status'];
}

export const StatusFilter = ({ steps, filter }: StatusFilterProps) => {
  const options = steps.map((step) => ({
    value: step.id,
    label: startCase(ts(step.slug)),
  }));

  const values: string[] = filter.values
    ? filter.values.filter((value): value is string => typeof value === 'string')
    : [];

  return (
    <CheckboxFilter
      label={tt('titles.status')}
      className={cx(filterStyles.filter)}
      options={options}
      values={values}
      onChange={filter.set}
      onClear={() => filter.set(null)}
    />
  );
};
