import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { MeetingStatus } from '../../types/meeting';

interface MeetingsCountQueryFilters {
  leadFilter?: string;
  statuses?: Array<MeetingStatus>;
  userIds?: Array<string>;
}

export interface MeetingsCountQueryResult {
  meetingsCount: {
    count: number;
  };
}

export interface MeetingsCountQueryVariables {
  filter?: MeetingsCountQueryFilters;
}

const MeetingsCountQuery = gql`
  query MeetingsCountQuery($filter: MeetingFilterInput!) {
    meetingsCount(filter: $filter) {
      count
    }
  }
`;

export function useFetchMeetingCount(filters: MeetingsCountQueryFilters, options?: { pause?: boolean }): {
  isLoading: boolean;
  count?: number;
  error?: ErrorType;
} {
  const res = useMosaicQuery<MeetingsCountQueryResult, MeetingsCountQueryVariables>({
    query: MeetingsCountQuery,
    variables: { filter: filters },
    pause: options?.pause
  });

  return {
    isLoading: res.isLoading,
    count: res.data?.meetingsCount.count,
    error: buildError(res.error),
  };
}
