import sv from '@drawbotics/drylus-style-vars';
import { Button, Category, Icon, useAlert } from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import { css } from 'emotion';
import React from 'react';
import urlJoin from 'url-join';

import { Id, Organisation, UserLocale } from '~/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.availabilities');

const styles = {
  container: css`
    align-items: center;
    background-color: ${sv.blueLighter};
    border-radius: ${sv.defaultBorderRadius};
    display: flex;
    justify-content: space-between;
    margin: ${sv.marginExtraSmall} 0;
    padding: ${sv.paddingExtraSmall};
    width: 600px;

    & > div {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
    }
  `,
  logoContainer: css`
    height: 40px;
    width: 40px;

    & > * {
      border-radius: ${sv.defaultBorderRadius};
      height: inherit;
    }

    img {
      object-fit: cover;
    }

    & > div {
      display: flex;
      align-items: center;
      font-size: 22px;
      justify-content: center;
    }
  `,
  icon: css`
    padding: 0 ${sv.paddingExtraSmall};
  `,
};

interface PersonalSchedulingProps {
  locale: UserLocale;
  schedulingToken?: Id;
  organisation?: Organisation;
}

export const PersonalScheduling = ({
  locale,
  schedulingToken,
  organisation,
}: PersonalSchedulingProps) => {
  const { showAlert } = useAlert();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logoContainer}>
          {!organisation?.logo?.url ? (
            <img src={organisation?.logo?.url} />
          ) : (
            <div
              style={{
                backgroundColor: organisation?.primaryColor,
                color: organisation?.secondaryColor,
              }}>
              {organisation?.name?.charAt(0)}
            </div>
          )}
        </div>
        <Icon name="link-2" className={styles.icon} />
        <p>
          <strong>{organisation?.name}</strong>: {tt('personal_scheduling_message')}
        </p>
      </div>
      <Button
        onClick={() => {
          copyToClipboard(
            urlJoin(
              process.env.MOSAIC_HOST,
              '/meetings',
              locale.toLowerCase(),
              '/schedule/me',
              schedulingToken!,
            ),
          );
          showAlert({
            text: tt('copy_link_success'),
            category: Category.SUCCESS,
          });
        }}
        category={Category.INFO}>
        {tt('personal_scheduling_copy_link')}
      </Button>
    </div>
  );
};
