import sv from '@drawbotics/drylus-style-vars';
import { Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { ShowroomAddress } from '~/types';

import { AddressBoxDisplay } from './AddressBoxDisplay';

interface AddressBoxProps {
  address: ShowroomAddress;
  onClickEdit: VoidFunction;
  onClickDelete: VoidFunction;
}

export const AddressBox = ({ onClickEdit, onClickDelete, address }: AddressBoxProps) => {
  return (
    <div style={{ borderRadius: sv.borderRadiusLarge, border: `1px solid ${sv.grey300}` }}>
      <Padding size={Size.SMALL}>
        <AddressBoxDisplay
          address={address}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
        />
      </Padding>
    </div>
  );
};
