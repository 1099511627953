import {
  Content,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { MosaicPageTitle } from '~/components';
import { OrganisationRole } from '~/types';
import { createTranslate, run, useAuth } from '~/utils';

import { OverviewStatusButton } from '../../components';
import { useFetchOrganisationBranding, useFetchOrganisationOverview } from '../../utils';
import { CoverAsset, EditorLoader, Gallery, InfoBanner, Overview } from './components';
import { KeyFigures } from './components/KeyFigures';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

export const CompanyOverview = () => {
  const {
    isLoading: isOrganisationLoading,
    organisationOverview,
    refetch,
  } = useFetchOrganisationOverview();
  const { isLoading: isOrganisationBrandingLoading, organisation } = useFetchOrganisationBranding();
  const { user } = useAuth();
  const readOnly = user?.role === OrganisationRole.MEMBER;

  const isLoading = isOrganisationLoading || isOrganisationBrandingLoading;

  return (
    <Content fullHeight fullWidth>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{tt('company_overview')}</MosaicPageTitle>
        <Margin size={{ vertical: Size.DEFAULT }}>
          <InfoBanner />
        </Margin>
        <Panel
          header={
            <PanelHeader>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Title size={4} noMargin>
                    {tt('update_overview')}
                  </Title>
                </FlexItem>
                {organisationOverview != null ? (
                  <FlexItem>
                    <div style={{ width: 160 }}>
                      <OverviewStatusButton overview={organisationOverview} disabled={readOnly} />
                    </div>
                  </FlexItem>
                ) : null}
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody style={readOnly ? { cursor: 'not-allowed' } : undefined}>
              <Margin size={{ bottom: Size.DEFAULT }}>
                <Text>{readOnly ? tt('read_only_content') : tt('edit_content')}</Text>
              </Margin>
              <Flex>
                <FlexItem style={{ maxWidth: 700 }} flex>
                  {run(() => {
                    if (isLoading) {
                      return <EditorLoader />;
                    } else if (organisationOverview != null) {
                      return (
                        <Fragment>
                          <Margin size={{ vertical: Size.EXTRA_LARGE }}>
                            <Overview
                              primaryColor={organisation?.primaryColor}
                              overview={organisationOverview}
                              readOnly={readOnly}
                            />
                          </Margin>
                          <Margin size={{ vertical: Size.EXTRA_LARGE }}>
                            <KeyFigures
                              primaryColor={organisation?.primaryColor}
                              refetchFigures={refetch}
                              visible={organisationOverview.showFigures}
                              title={organisationOverview.figuresTitle}
                              figures={organisationOverview.figures}
                              readOnly={readOnly}
                            />
                          </Margin>
                          <Margin size={{ top: Size.EXTRA_LARGE, bottom: Size.DEFAULT }}>
                            <div style={{ height: 300, display: 'flex' }}>
                              <CoverAsset
                                asset={organisationOverview.coverAsset}
                                readOnly={readOnly}
                              />
                            </div>
                          </Margin>
                          <Gallery
                            assets={organisationOverview.assets}
                            showAssets={organisationOverview.showAssets}
                            readOnly={readOnly}
                          />
                        </Fragment>
                      );
                    } else {
                      return (
                        <EmptyState
                          variation={EmptyStateVariation.FAILED}
                          title={tt('could_not_load_overview')}
                          description={tt('something_went_wrong')}
                        />
                      );
                    }
                  })}
                </FlexItem>
              </Flex>
            </PanelBody>
          }
        />
      </Padding>
    </Content>
  );
};
