import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { SectionIdentifier } from '../../../types';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const CoverSectionSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  identifier: z.literal(SectionIdentifier.COVER),
  tagline: ze.optional(z.string()),
  title: ze.optional(z.string()),
  description: ze.optional(z.string()),
  active: ze.defaults(z.boolean(), false),
  position: z.number(),
});

export type CoverSectionData = z.infer<typeof CoverSectionSchema>;

export class CoverSection extends ze.ClassFrom(CoverSectionSchema) {
  public static fromData(data: CoverSectionData): CoverSection {
    return ze.validate(data, CoverSection);
  }

  get isActive() {
    return this.active;
  }

  public toData(): CoverSectionData {
    return JSON.parse(JSON.stringify(this));
  }
}
