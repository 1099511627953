import gql from 'fraql';

import { Organisation } from '~/pods/settings/types';
import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

interface OrganisationQueryData {
  organisation: Organisation;
}

const OrganisationQuery = gql`
  query OrganisationQuery {
    organisation {
      id
      name
      primaryColor
      secondaryColor
      logo {
        id
        url
      }
    }
  }
`;

export function useFetchOrganisationBranding(): {
  isLoading: boolean;
  organisation?: Organisation;
  refetch: VoidFunction;
  error?: ErrorType;
} {
  const res = useMosaicQuery<OrganisationQueryData, null>({
    query: OrganisationQuery,
  });

  return {
    isLoading: res.isLoading,
    organisation: res.data?.organisation,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
