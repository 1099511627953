import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, isNullOrEmpty } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

import { Organisation } from '../../types';
import { LeadsQueryFilters } from './use-fetch-leads';

export interface LeadCountQueryData {
  leadCount: {
    ownedLeadCount: number;
    totalLeadCount: number;
  };
}

export interface LeadCountQueryVariables {
  filter?: LeadsQueryFilters;
}

const LeadCountQuery = gql`
  query LeadCountQuery($filter: LeadFilterInput!) {
    leadCount(filter: $filter) {
      ownedLeadCount
      # Absolute total count, not affected by the filters or pagination
      totalLeadCount
    }
  }
`;

export function useFetchLeadCount(
  filters: LeadsQueryFilters,
  steps: Organisation['steps'],
): {
  isLoading: boolean;
  leadCount?: { ownedLeadCount: number; totalLeadCount: number; unassignedCount?: number };
  error?: ErrorType;
  refetchLeadsCount: () => void
} {
  // TODO remove default {} once query is updated on backend
  // Provides the total number of leads for the org and the number of leads matching
  // provided filters, pagination aside
  const filteredCountResult = useMosaicQuery<LeadCountQueryData, LeadCountQueryVariables>({
    query: LeadCountQuery,
    variables: { filter: filters ?? {} },
    requestPolicy: 'cache-and-network',
  });

  // The id of the not_assigned funnelStep (aka status) is different for each company, so we
  // retrieve it manually here./
  const notAssignedStepId = steps?.find((step) => step.slug === 'not_assigned')?.id;

  const unassignedCountResult = useMosaicQuery<LeadCountQueryData, LeadCountQueryVariables>({
    query: LeadCountQuery,
    variables: { filter: { funnelStepIds: [notAssignedStepId!] } },
    requestPolicy: 'cache-and-network',
    pause: isNullOrEmpty(notAssignedStepId),
  });

  return {
    isLoading: filteredCountResult.isLoading || unassignedCountResult.isLoading,
    leadCount:
      filteredCountResult.data != null && unassignedCountResult.data != null
        ? {
          ...filteredCountResult.data.leadCount,
          unassignedCount: unassignedCountResult.data?.leadCount.ownedLeadCount,
        }
        : undefined,
    error: buildError(filteredCountResult.error),
    refetchLeadsCount: () => {
      filteredCountResult.refetch()
      unassignedCountResult.refetch()
    }
  };
}
