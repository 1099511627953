import {
  AttachmentBox,
  Button,
  Category,
  Checkbox,
  Color,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Select,
  Size,
  Text,
  Toggle,
} from '@drawbotics/react-drylus';
import { UploadHelper } from '@drawbotics/react-drylus';
import { Form } from '@drawbotics/use-form';
import React from 'react';

import {
  LeadEmailActionType,
  LeadEmailActionsForm,
  UpdateLeadEmailActionFormErrors,
} from '~/pods/project-settings/types';
import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

const tt = createTranslate('pods.project_settings.project_settings.lead_email_settings');

export const LeadEmailAction = (props: {
  actionPriority: 'primary' | 'secondary';
  form: Form<LeadEmailActionsForm>;
  licenseSupportsScheduling: boolean;
  errors: undefined | UpdateLeadEmailActionFormErrors;
  onLinkToChange: (linkTo: LeadEmailActionType) => void;
}) => {
  const { uploadFiles, info } = useFileUpload(useMosaicMutation);

  const formEmailActionValue = props.form.values[props.actionPriority];

  return (
    <div>
      <Flex align={FlexAlign.CENTER} justify={FlexJustify.START}>
        <FlexItem>
          <Toggle
            disabled={props.actionPriority === 'secondary' && !props.form.values.primary}
            name={props.actionPriority}
            value={Boolean(formEmailActionValue)}
            onChange={(v) => {
              if (v) {
                props.form.set({}, props.actionPriority);
              } else {
                props.form.set(undefined, props.actionPriority);

                if (props.actionPriority === 'primary') {
                  props.form.set(undefined, 'secondary');
                }
              }
            }}
          />
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <Text>{tt(`display_${props.actionPriority}_action`)}</Text>
        </FlexItem>
      </Flex>
      {formEmailActionValue ? (
        <div>
          <Margin size={{ top: Size.SMALL }}>
            <FormGroup
              label={<Label>{tt('button_label')}</Label>}
              input={
                <Input
                  error={props.errors?.buttonLabelIsRequired ? tt('required_field') : undefined}
                  value={formEmailActionValue?.buttonLabel || ''}
                  placeholder={tt('button_label_placeholder')}
                  onChange={(v) => {
                    props.form.set(
                      {
                        ...formEmailActionValue,
                        buttonLabel: v,
                      },
                      props.actionPriority,
                    );
                  }}
                />
              }
            />
          </Margin>
          <Margin size={{ top: Size.SMALL }}>
            <FormGroup
              label={<Label>{tt('link_to')}</Label>}
              input={
                <Select
                  placeholder={tt('link_to_placeholder')}
                  hint={
                    formEmailActionValue.scheduling &&
                    !props.licenseSupportsScheduling &&
                    !props.errors?.licenseDoesNotSupportScheduling
                      ? tt('link_to_subscription_message')
                      : undefined
                  }
                  error={
                    props.errors?.licenseDoesNotSupportScheduling
                      ? tt('link_to_subscription_message')
                      : props.errors?.linkToIsRequired
                      ? tt('required_field')
                      : undefined
                  }
                  onChange={props.onLinkToChange}
                  options={[
                    LeadEmailActionType.SCHEDULING,
                    LeadEmailActionType.EXTERNAL_LINK,
                    LeadEmailActionType.DOWNLOAD,
                  ].map((type) => {
                    return {
                      label: tt(type.toLowerCase()),
                      value: type,
                    };
                  })}
                  value={(() => {
                    if (formEmailActionValue.scheduling) {
                      return LeadEmailActionType.SCHEDULING;
                    } else if (formEmailActionValue.externalLink) {
                      return LeadEmailActionType.EXTERNAL_LINK;
                    } else if (formEmailActionValue.download) {
                      return LeadEmailActionType.DOWNLOAD;
                    }
                  })()}
                />
              }
            />
          </Margin>
          {formEmailActionValue.scheduling ? (
            <Margin size={{ top: Size.EXTRA_SMALL }}>
              <Checkbox
                onChange={(v) => {
                  props.form.set(
                    {
                      ...formEmailActionValue,
                      scheduling: {
                        includeInterests: v,
                      },
                    },
                    props.actionPriority,
                  );
                }}
                value={formEmailActionValue.scheduling.includeInterests}>
                {tt('include_interests_filters')}
              </Checkbox>
            </Margin>
          ) : null}
          {formEmailActionValue.externalLink ? (
            <Margin size={{ top: Size.SMALL }}>
              <FormGroup
                label={<Label>{tt('external_link')}</Label>}
                input={
                  <Input
                    placeholder={tt('external_link_placeholder')}
                    value={formEmailActionValue.externalLink.url || ''}
                    onChange={(v) => {
                      props.form.set(
                        {
                          ...formEmailActionValue,
                          externalLink: {
                            url: v,
                          },
                        },
                        props.actionPriority,
                      );
                    }}
                    error={
                      props.errors?.externalLinkUrlRequired
                        ? tt('required_field')
                        : props.errors?.externalLinkUrlWrongFormat
                        ? tt('invalid_url')
                        : undefined
                    }
                  />
                }
              />
            </Margin>
          ) : null}
          {formEmailActionValue.download ? (
            <Margin size={{ top: Size.SMALL }}>
              <Flex>
                <FlexItem>
                  <UploadHelper
                    onUploadFiles={async (fileList) => {
                      const res = await uploadFiles(fileList);

                      const validFiles = res.data.filter(
                        (file): file is UploadedFile => file != null,
                      );

                      const validFile = validFiles[0];

                      props.form.set(
                        {
                          ...formEmailActionValue,
                          download: {
                            file: validFile
                              ? {
                                  filename: validFile.originalFile.name,
                                  signedBlobId: validFile.signedBlobId,
                                  url: validFile.url,
                                }
                              : formEmailActionValue.download?.file,
                          },
                        },
                        props.actionPriority,
                      );
                    }}>
                    <Button category={Category.INFO} leading={<Icon name="upload" />}>
                      {tt('upload')}
                    </Button>
                  </UploadHelper>
                </FlexItem>
                <FlexSpacer size={Size.EXTRA_SMALL} />
                <FlexItem flex={1}>
                  {formEmailActionValue.download.file ? (
                    <AttachmentBox
                      fileName={formEmailActionValue.download.file.filename}
                      onClickClose={() => {
                        props.form.set(
                          {
                            ...formEmailActionValue,
                            download: {
                              file: undefined,
                            },
                          },
                          props.actionPriority,
                        );
                      }}
                    />
                  ) : info[0] && info[0].progress != null ? (
                    <AttachmentBox fileName={info[0].filename} progress={info[0].progress} />
                  ) : null}
                </FlexItem>
              </Flex>
              <Margin size={{ top: Size.EXTRA_SMALL }}>
                {props.errors?.downloadFileIsRequired ? (
                  <Text color={Color.RED}>{tt('required_file')}</Text>
                ) : undefined}
              </Margin>
            </Margin>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
