import dayjs from 'dayjs';
import gql from 'fraql';

const Meeting = {
  typeName: 'Meeting',
  fields: {
    leadFullName: {
      dependencies: gql`
        fragment _ on Meeting {
          leadFirstName
          leadLastName
        }
      `,
      resolver: (meeting: any) => {
        if (meeting.leadFirstName != null && meeting.leadLastName != null) {
          return `${meeting.leadFirstName} ${meeting.leadLastName}`;
        }
        return meeting.leadFirstName ?? meeting.leadFirstName;
      },
    },
    dateObject: {
      dependencies: gql`
        fragment _ on Meeting {
          datetime
        }
      `,
      resolver: (meeting: any) => {
        return dayjs(meeting.datetime);
      },
    },
  },
};

export default Meeting;
