import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexJustify, Padding, Size } from '@drawbotics/react-drylus';
import { ResponsiveBar } from '@nivo/bar';
import { css } from 'emotion';
import React from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import {
  getColorForStatus,
  getLabelColorForStatus,
  useLoadRevenueBreakdown,
} from '~/pods/insights/utils';
import { createTranslate } from '~/utils/translation';

import { ChartTile } from './ChartTile';
import { ChartTitle } from './ChartTitle';
import { LineLabel } from '..';

const t = createTranslate('pods.insights_reports.components.charts');

const styles = {
  chartContainer: css`
    width: 100%;
    height: calc(100% - 20px);
  `,
};

interface RevenueBreakdownPerTypeProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
}

export const RevenueBreakdownPerType = ({ units, dateRange }: RevenueBreakdownPerTypeProps) => {
  const { data, dataKeys } = useLoadRevenueBreakdown(units, dateRange);

  return (
    <ChartTile>
      <ChartTitle>{t('revenue_breakdown.title')}</ChartTitle>
      <Padding size={{ top: Size.EXTRA_SMALL }}>
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <LineLabel circular color={sv.green} label={t('sold')} />
          </FlexItem>
          <FlexItem>
            <LineLabel circular color={sv.blue} label={t('option')} />
          </FlexItem>
          <FlexItem>
            <LineLabel circular color={sv.neutral} label={t('for_sale')} />
          </FlexItem>
        </Flex>
      </Padding>
      <Padding size={{ vertical: Size.SMALL }} style={{ width: '100%', height: '100%' }}>
        <div className={styles.chartContainer}>
          <ResponsiveBar
            data={data}
            keys={dataKeys}
            indexBy="typology"
            margin={{ bottom: 30, left: 25, top: 5, right: 25 }}
            padding={0.5}
            layout="horizontal"
            isInteractive={false}
            colors={({ id }) => getColorForStatus(id)}
            axisBottom={{ tickSize: 0, tickRotation: -45, tickPadding: 8, format: '~s' }}
            axisLeft={{ tickSize: 0, tickPadding: 10 }}
            labelFormat=".3~s"
            labelSkipWidth={40}
            labelTextColor={({ data }) => getLabelColorForStatus((data as any).id)}
            enableGridY={false}
            enableGridX
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: sv.colorTertiary,
                  },
                  text: {
                    fontSize: '7pt',
                    fontWeight: 'lighter',
                    fill: sv.colorTertiary,
                  },
                },
              },
            }}
          />
        </div>
      </Padding>
    </ChartTile>
  );
};
