import { clearJwt, clearRefreshToken, getRefreshToken } from '@drawbotics/auth';
import Cookies from 'js-cookie';

import { demodeskLogout } from '~/pods/meetings/utils';

import { getRootDomain } from './utils';

// demodesk specific
export const AUTH_TOKEN_CACHE_KEY = 'demodesk.authTokenCache';

export function clearMisc(): void {
  // demodesk
  demodeskLogout();

  Cookies.remove('uid', { domain: getRootDomain() });
  Cookies.remove('auth_token_default', { domain: getRootDomain() });
  localStorage.removeItem(AUTH_TOKEN_CACHE_KEY);
}

export async function clearTokens(): Promise<void> {
  clearJwt();
  clearRefreshToken();
  clearMisc();
}

export function isImpersonating() {
  const refreshToken = getRefreshToken();

  return refreshToken === 'impersonation-placeholder';
}
