import {
  Button,
  Category,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Icon,
  Input,
  Label,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  PanelFooter,
  Position,
  Select,
  Shade,
  Size,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect } from 'react';

import { Organisation } from '~/pods/settings/types';
import { useFetchOrganisation } from '~/pods/settings/utils';
import { Currency, CurrencyCode, OrganisationRole, TypologyFormat } from '~/types';
import {
  createTranslate,
  getCurrencyDetails,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  run,
  useAuth,
  useMosaicMutation,
} from '~/utils';

const tt = createTranslate('pods.settings.routes.basic_info');

const styles = {
  labelContainer: css`
    position: relative;
    width: fit-content;
  `,
  // Position the hint icon absolutely to prevent it from taking up space and causing misalignment
  // with the other FormGroup labels.
  hintFix: css`
    position: absolute;
    right: -25px;
    top: 0px;
  `,
};
interface UpdateOrganisationVariables {
  organisation: {
    id: string;
    currency?: Currency;
    name?: string;
    typologyFormat?: TypologyFormat;
  };
}

interface UpdateOrganisationResult {
  updateOrganisation: {
    organisation: Organisation;
  };
}

const updateOrganisationMutation = gql`
  mutation UpdateOrganisation($organisation: UpdateOrganisationInput!) {
    updateOrganisation(input: $organisation) {
      organisation {
        currency
        name
        typologyFormat
      }
    }
  }
`;

const CompanyDetailsPlaceholder = () => {
  return (
    <Grid hGutters={Size.LARGE} vGutters={Size.DEFAULT} columns={3}>
      <GridItem>
        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
          <LoadingPlaceholder width="50%" height={15} />
        </Margin>
        <LoadingPlaceholder width="100%" height={40} />
      </GridItem>
      <GridItem>
        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
          <LoadingPlaceholder width="50%" height={15} />
        </Margin>
        <LoadingPlaceholder width="100%" height={40} />
      </GridItem>
      <GridItem>
        <Margin size={{ bottom: Size.EXTRA_SMALL }}>
          <LoadingPlaceholder width="50%" height={15} />
        </Margin>
        <LoadingPlaceholder width="100%" height={40} />
      </GridItem>
    </Grid>
  );
};

interface CompanyDetailsForm {
  name: string;
  currency: Currency;
  typologyFormat: TypologyFormat;
}

export const CompanyDetails = () => {
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const { isLoading, organisation, error: organisationError } = useFetchOrganisation();
  const {
    executeMutation: updateOrganisation,
    res: { fetching: isUpdating },
  } = useMosaicMutation<UpdateOrganisationResult, UpdateOrganisationVariables>(
    updateOrganisationMutation,
  );
  const basicInfoForm = useForm<CompanyDetailsForm>({
    name: organisation?.name ?? '',
    currency: CurrencyCode.EUR,
  });

  const isUserMember = user?.role === OrganisationRole.MEMBER;

  const handleClickSave = async () => {
    if (organisation != null) {
      const { error } = await updateOrganisation({
        organisation: {
          ...basicInfoForm.values,
          id: organisation.id,
        },
      });

      if (error != null) {
        showAlert({
          text: tt('update_error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('update_success'),
          category: Category.SUCCESS,
        });
      }
    }
  };

  const resetFormValues = () => {
    if (organisation != null) {
      basicInfoForm.set(organisation.name, 'name');
      basicInfoForm.set(organisation.currency, 'currency');
      basicInfoForm.set(organisation.typologyFormat, 'typologyFormat');
    }
  };

  useEffect(() => {
    resetFormValues();
  }, [isLoading]);

  const disableSaveButton =
    isLoading ||
    isUpdating ||
    organisation == null ||
    isUserMember ||
    (basicInfoForm.values.name === organisation?.name &&
      basicInfoForm.values.currency === organisation?.currency &&
      basicInfoForm.values.typologyFormat === organisation?.typologyFormat);

  if (organisationError != null) {
    return (
      <EmptyState
        description={getMessageFromErrorType(organisationError)}
        variation={getEmptyStateVariationFromErrorType(organisationError)}
      />
    );
  }

  return (
    <Panel
      body={
        <PanelBody>
          {run(() => {
            if (isLoading) {
              return <CompanyDetailsPlaceholder />;
            } else if (organisation != null) {
              return (
                <Fragment>
                  <Grid columns={2} vGutters={Size.DEFAULT} hGutters={Size.DEFAULT}>
                    <GridItem>
                      <FormGroup
                        input={
                          <Input
                            name="name"
                            value={basicInfoForm.get}
                            onChange={basicInfoForm.set}
                            disabled={isUserMember}
                          />
                        }
                        label={<Label>{tt('company_name')}</Label>}
                      />
                    </GridItem>
                    <GridItem>
                      <FormGroup
                        input={
                          <Select
                            name="currency"
                            value={basicInfoForm.get}
                            disabled={isUserMember}
                            options={Object.values(CurrencyCode).map((c) => ({
                              value: c,
                              label:
                                tt(`currency_details.` + getCurrencyDetails(c).text) +
                                ` (${getCurrencyDetails(c).symbol})`,
                            }))}
                            onChange={basicInfoForm.set}
                          />
                        }
                        label={
                          <div className={styles.labelContainer}>
                            <Label>{tt('company_currency')}</Label>
                            <div className={styles.hintFix}>
                              <Tooltip side={Position.RIGHT} content={tt('company_currency_hint')}>
                                <Icon name="info" shade={Shade.LIGHT} />
                              </Tooltip>
                            </div>
                          </div>
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <FormGroup
                        input={
                          <Select
                            name="typologyFormat"
                            value={basicInfoForm.get}
                            disabled={isUserMember}
                            options={Object.values(TypologyFormat).map((c) => ({
                              value: c,
                              label: tt(`typology_format.${c.toLocaleLowerCase()}`),
                            }))}
                            onChange={basicInfoForm.set}
                          />
                        }
                        label={<Label>{tt('typology_format_label')}</Label>}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              );
            }
          })}
        </PanelBody>
      }
      footer={
        <PanelFooter>
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button category={Category.INFO} tier={Tier.TERTIARY} onClick={resetFormValues}>
                {tt('clear')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                trailing={isUpdating}
                disabled={disableSaveButton}
                category={Category.INFO}
                onClick={handleClickSave}>
                {tt('update_details')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelFooter>
      }
    />
  );
};
