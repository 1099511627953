import { Label } from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface ChartTitleProps {
  children: ReactNode;
}

export const ChartTitle = ({ children }: Readonly<ChartTitleProps>) => {
  return <Label>{children}</Label>;
};
