import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { FiscalSimulator } from './FiscalSimulator';

export const Routes = () => {
  return (
    <RoutesWithNotFound>
      <Route path="/" element={<FiscalSimulator />} />
    </RoutesWithNotFound>
  );
};
