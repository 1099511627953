import sv from '@drawbotics/drylus-style-vars';
import { Content, Padding, Panel, PanelBody, Size } from '@drawbotics/react-drylus';
import { ThemeProvider } from '@emotion/react';
import { run } from '@tokamakjs/common';
import React from 'react';

import { ID } from '~/types';

import { AnyWebsiteSectionData, AssetData, UpdateLinkData, Website } from '../api/domain';
import { Theme } from '../types';
import { Luxurious, Minimalist, Traditional } from './templates';

export const WebsiteContext = React.createContext<Website | undefined>(undefined);
interface WebsiteBuilderProps {
  website: Website;
  onChange: (data: Partial<Website>) => void;
  onChangeSection: (sectionId: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAssets: (sectionId: string, data: Array<AssetData>) => void;
  onChangeLink: (sectionId: string, data: UpdateLinkData) => void;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const WebsiteDesignPanel = ({
  website,
  onChange,
  onChangeSection,
  onChangeAssets,
  onChangeLink,
  onIncrement,
  onDecrement,
}: WebsiteBuilderProps) => {
  return (
    <WebsiteContext.Provider value={website}>
      <Content fullWidth style={{ background: sv.neutral, height: '100%' }}>
        <Padding style={{ height: '100%' }} size={Size.LARGE}>
          <Panel
            style={{ height: '100%', overflow: 'scroll', borderRadius: sv.borderRadiusLarge }}
            body={
              <PanelBody style={{ padding: 0 }}>
                <ThemeProvider theme={website.theme}>
                  {run(() => {
                    if (website.template === Theme.TRADITIONAL) {
                      return (
                        <Traditional
                          website={website}
                          onChange={onChange}
                          onChangeLink={onChangeLink}
                          onChangeSection={onChangeSection}
                          onChangeAssets={onChangeAssets}
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}
                        />
                      );
                    } else if (website.template === Theme.MINIMALIST) {
                      return (
                        <Minimalist
                          website={website}
                          onChange={onChange}
                          onChangeLink={onChangeLink}
                          onChangeSection={onChangeSection}
                          onChangeAssets={onChangeAssets}
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}
                        />
                      );
                    } else if (website.template === Theme.LUXURIOUS) {
                      return (
                        <Luxurious
                          website={website}
                          onChange={onChange}
                          onChangeLink={onChangeLink}
                          onChangeSection={onChangeSection}
                          onChangeAssets={onChangeAssets}
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}
                        />
                      );
                    }
                  })}
                </ThemeProvider>
              </PanelBody>
            }
          />
        </Padding>
      </Content>
    </WebsiteContext.Provider>
  );
};
