import {
  Dot,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  ListTile,
  Tier,
  Tile,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import React, { Fragment } from 'react';

import { DateRange, InsightsEstate } from '~/pods/insights/types';
import { useLoadPercentageByTypology } from '~/pods/insights/utils/hooks/use-load-percentage-by-typology';
import { createTranslate } from '~/utils';

import { PieChart } from '../../../../components/PieChart';
import { Text } from '../Text';
import { ChartTitle } from './ChartTitle';
import { EmptyChart } from './EmptyChart';

const tt = createTranslate('pods.insights_reports.components.charts');

interface VisitedTypologiesProps {
  units: InsightsEstate['units'];
  dateRange: DateRange;
  chartHeight: number;
  title: string;
}

export const VisitedTypologies = ({
  units,
  dateRange,
  chartHeight,
  title,
}: VisitedTypologiesProps) => {
  const percentageByTypology = useLoadPercentageByTypology(units, dateRange);
  return (
    <Tile style={{ width: '100%', height: '100%' }}>
      <ChartTitle>{title}</ChartTitle>
      <Flex
        style={{ height: '100%' }}
        direction={FlexDirection.VERTICAL}
        align={FlexAlign.CENTER}
        justify={FlexJustify.SPACE_AROUND}>
        {run(() => {
          if (percentageByTypology == null) {
            return <EmptyChart message={tt('not_enough_data')} />;
          } else {
            return (
              <Fragment>
                <FlexItem>
                  <div style={{ width: `${chartHeight}px`, height: `${chartHeight}px` }}>
                    <PieChart data={percentageByTypology!} />
                  </div>
                </FlexItem>
                <FlexItem style={{ width: '100%', height: '60px' }}>
                  <Flex
                    direction={FlexDirection.VERTICAL}
                    justify={FlexJustify.SPACE_BETWEEN}
                    align={FlexAlign.STRETCH}
                    wrap
                    style={{ width: '100%', height: '100%' }}>
                    {percentageByTypology!.map((typology) => {
                      return (
                        <FlexItem key={typology.id}>
                          <ListTile
                            leading={<Dot style={{ backgroundColor: typology.color }} />}
                            title={
                              <Text light tier={Tier.SECONDARY}>
                                {typology.label} ({Math.round(typology.value * 100)}%)
                              </Text>
                            }
                          />
                        </FlexItem>
                      );
                    })}
                  </Flex>
                </FlexItem>
              </Fragment>
            );
          }
        })}
      </Flex>
    </Tile>
  );
};
