import { Margin, Size } from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { ID } from '~/types';

import {
  AnyWebsiteSectionData,
  AssetData,
  CoverSection,
  GallerySection,
  HeroSection,
  ListingSection,
  MainSection,
  MapSection,
  TextImageSection,
  UpdateLinkData,
  Website,
} from '../../../api/domain';
import { SectionIdentifier } from '../../../types';
import { Listing } from '../Listing';
import { Cover } from './Cover';
import { Footer } from './Footer';
import { Gallery } from './Gallery';
import { Hero } from './Hero';
import { Main } from './Main';
import { MainTitle } from './MainTitle';
import { Map } from './Map';
import { Navbar } from './Navbar';
import { TextImage } from './TextImage';

interface TraditionalProps {
  website: Website;
  onChange: (data: Partial<Website>) => void;
  onChangeSection: (sectionId: string, data: Partial<AnyWebsiteSectionData>) => void;
  onChangeAssets: (sectionId: string, data: Array<AssetData>) => void;
  onChangeLink: (sectionId: string, data: UpdateLinkData) => void;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Traditional = ({
  website,
  onChange,
  onChangeSection,
  onChangeAssets,
  onChangeLink,
  onIncrement,
  onDecrement,
}: TraditionalProps) => {
  return (
    <div>
      <Navbar
        logoUrl={website.logo?.url}
        language={website.localeCode}
        sections={website.sections}
      />
      {website.sections.map((section) => {
        const component = (() => {
          switch (section.identifier) {
            case SectionIdentifier.HERO:
              return (
                <Hero
                  hero={section as HeroSection}
                  sections={website.sections}
                  language={website.localeCode}
                  onChangeSection={onChangeSection}
                  onChangeLink={onChangeLink}
                  onChangeAsset={(id, d) => onChangeAssets(id, [d])}
                />
              );
            case SectionIdentifier.COVER:
              return (
                <Cover
                  language={website.localeCode}
                  cover={section as CoverSection}
                  onChange={onChangeSection}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                />
              );
            case SectionIdentifier.MAIN:
              return (
                <Fragment>
                  <MainTitle
                    language={website.localeCode}
                    main={section as MainSection}
                    onChange={onChangeSection}
                  />
                  <Main
                    main={section as MainSection}
                    onChange={onChangeSection}
                    onChangeAsset={(id, d) => onChangeAssets(id, [d])}
                    onIncrement={onIncrement}
                    onDecrement={onDecrement}
                  />
                </Fragment>
              );
            case SectionIdentifier.GALLERY:
              return (
                <Gallery
                  gallery={section as GallerySection}
                  onChange={onChangeSection}
                  onChangeAssets={onChangeAssets}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                />
              );
            case SectionIdentifier.MAP:
              return (
                <Map
                  onChangeLink={onChangeLink}
                  onChangeAsset={(id, d) => onChangeAssets(id, [d])}
                  map={section as MapSection}
                  website={website}
                  onChangeSection={onChangeSection}
                  onChangeWebsite={onChange}
                />
              );
            case SectionIdentifier.LISTING:
              return (
                <Listing
                  website={website}
                  language={website.localeCode}
                  onChangeWebsite={onChange}
                  onChangeSection={onChangeSection}
                  listing={section as ListingSection}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                />
              );
            case SectionIdentifier.TEXT_IMAGE:
              return (
                <TextImage
                  key={section.id}
                  language={website.localeCode}
                  sections={website.sections}
                  textImage={section as TextImageSection}
                  onChangeSection={onChangeSection}
                  onChangeAssets={onChangeAssets}
                  onIncrement={onIncrement}
                  onDecrement={onDecrement}
                />
              );
          }
        })();

        return (
          <Fragment key={section.id}>
            {component}
            <Margin size={Size.EXTRA_LARGE} />
          </Fragment>
        );
      })}
      <Footer website={website} onChange={onChange} />
    </div>
  );
};
