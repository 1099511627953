import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { Agency } from './Agency';

export const AgencyRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route path="/" element={<Agency />} />
    </RoutesWithNotFound>
  );
};
