import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  Label,
  Margin,
  Position,
  Size,
  Tooltip,
} from '@drawbotics/react-drylus';
import React, { Fragment, ReactNode } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.crm.routes.lead.components.lead_details');

interface SectionProps {
  label: string;
  children: ReactNode;
  onClickEdit: VoidFunction;
}

export const Section = ({ label, children, onClickEdit }: SectionProps) => {
  return (
    <Fragment>
      <Margin size={{ bottom: Size.SMALL }}>
        <Flex justify={FlexJustify.SPACE_BETWEEN}>
          <FlexItem>
            <Label>{label}</Label>
          </FlexItem>
          <FlexItem>
            <Tooltip content={tt('edit_section')} side={Position.BOTTOM}>
              <Icon style={{ color: sv.colorSecondary }} name="edit-3" onClick={onClickEdit} />
            </Tooltip>
          </FlexItem>
        </Flex>
      </Margin>
      {children}
    </Fragment>
  );
};
