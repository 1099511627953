import { injectGlobal } from 'emotion';

import { Theme, ThemeDefinition } from '../../types';

injectGlobal`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;800&display=swap');
`;

const fonts = {
  primary: 'Raleway',
  secondary: 'Raleway',
  extraLarge: '35px',
  large: '29px',
  small: '15px',
  extraSmall: '11px',
  extraLargeSpacing: '52px',
  largeSpacing: '30px',
  smallSpacing: '28px',
  extraSmallSpacing: '28px',
};

const colors = {
  primary: '#C0968B',
  secondary: '#D16E54',
  neutral1: '#000000',
  neutral2: '#FFFFFF',
  neutral3: '#EBEBEB',
  neutral4: '#F5F5F5',
  neutral5: '#424242',
};

export const minimalist: ThemeDefinition = { id: Theme.MINIMALIST, fonts, colors };
