import {
  Button,
  Flex,
  FlexItem,
  FlexSpacer,
  Input,
  Label,
  Margin,
  Select,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { Fragment, useState } from 'react';

import { CategorySlug, CategoryType } from '~/pods/presentation/types';
import { OrganisationRole } from '~/types';
import { createTranslate, translate as t, useAuth } from '~/utils';

const tt = createTranslate('pods.presentation.routes.presentation.components.category_selector');

export interface CustomCategory {
  name: string;
  categoryType: CategoryType;
}

interface CategorySelectorProps {
  categorySlugs: Array<CategorySlug>;
  onAddCategory: (category: CategorySlug | CustomCategory) => Promise<void>;
  isLoading?: boolean;
}

export const CategorySelector = ({
  categorySlugs,
  onAddCategory,
  isLoading,
}: CategorySelectorProps) => {
  const [selectedCategory, setSelectedCategory] = useState<CategorySlug | 'custom' | undefined>(
    categorySlugs == null ? 'custom' : undefined,
  );
  const customCategoryForm = useForm<CustomCategory>({ name: '' });
  const { user } = useAuth();

  const isUserMember = user?.role === OrganisationRole.MEMBER;

  const canAddCustomCategory =
    customCategoryForm.values.name != '' && customCategoryForm.values.categoryType != null;
  const optionsSelect = (
    <Select
      placeholder={tt('select_category')}
      options={[
        ...categorySlugs.map((slug) => ({
          label: t(`pods.presentation.categories.${slug}`),
          value: slug,
        })),
        { label: tt('custom'), value: 'custom' },
      ]}
      value={selectedCategory}
      onChange={setSelectedCategory}
      disabled={isUserMember}
    />
  );

  if (selectedCategory === 'custom') {
    return (
      <Fragment>
        <Margin size={{ bottom: Size.SMALL }}>{optionsSelect}</Margin>
        <Label>{tt('category_name')}</Label>
        <Margin size={{ vertical: Size.EXTRA_SMALL }}>
          <Input
            name="name"
            value={customCategoryForm.get}
            placeholder={tt('example_1')}
            onChange={customCategoryForm.set}
            disabled={isUserMember}
          />
        </Margin>
        <Flex>
          <FlexItem flex>
            <Select
              name="categoryType"
              onChange={customCategoryForm.set}
              value={customCategoryForm.get}
              placeholder={tt('select_element')}
              disabled={isUserMember}
              options={Object.values(CategoryType).map((type) => ({
                label: tt(`elements.${type}`),
                value: type,
              }))}
            />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              onClick={async () => {
                await onAddCategory({
                  name: customCategoryForm.values.name!,
                  categoryType: customCategoryForm.values.categoryType!,
                });
                customCategoryForm.reset();
                setSelectedCategory(undefined);
              }}
              disabled={!canAddCustomCategory || isUserMember}
              tier={Tier.SECONDARY}>
              {tt('add_element')}
            </Button>
          </FlexItem>
        </Flex>
      </Fragment>
    );
  } else {
    return (
      <Flex>
        <FlexItem flex>{optionsSelect}</FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <Button
            trailing={isLoading ? <Spinner size={Size.SMALL} /> : null}
            onClick={() => onAddCategory(selectedCategory!)}
            disabled={selectedCategory == null || isLoading || isUserMember}
            tier={Tier.SECONDARY}>
            {tt('add_element')}
          </Button>
        </FlexItem>
      </Flex>
    );
  }
};
