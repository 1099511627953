import {
  Checkbox,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  Margin,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { LeadsFilterTypes } from '~/pods/crm/types';

interface SourceFilterProps {
  filter: LeadsFilterTypes['marketingCampaign'];
  campaigns: Array<{ token: string; name: string }>;
}

export const MarketingCampaignFilter = ({ filter, campaigns }: SourceFilterProps) => {
  const toggleSelection = (value: string) => {
    if (filter.value == null) {
      filter.set([value]);
    } else if (Array.isArray(filter.value)) {
      if (filter.value.includes(value)) {
        filter.set(filter.value.filter((userId) => userId !== value));
      } else {
        filter.set([...filter.value, value]);
      }
    } else {
      if (value === filter.value) {
        filter.set(null);
      } else {
        filter.set([filter.value, value]);
      }
    }
  };

  return (
    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.START}>
      {campaigns.map((campaign) => (
        <FlexItem key={campaign.token}>
          <Margin size={{ vertical: Size.EXTRA_SMALL }}>
            <Checkbox
              onChange={() => toggleSelection(campaign.token)}
              value={
                Array.isArray(filter.value)
                  ? filter.value?.includes(campaign.token)
                  : String(filter.value) === campaign.token
              }>
              {campaign.name}
            </Checkbox>
          </Margin>
        </FlexItem>
      ))}
    </Flex>
  );
};
