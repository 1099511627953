import {
  Flex,
  FlexItem,
  FlexJustify,
  Grid,
  GridItem,
  Margin,
  SegmentedControl,
  SegmentedControlItem,
  Size,
} from '@drawbotics/react-drylus';
import dayjs, { Dayjs } from 'dayjs';
import gql from 'fraql';
import React, { Fragment, useState } from 'react';

import { MosaicPageTitle } from '~/components';
import { InsightsLoadingError } from '~/pods/insights/components';
import { Timescale, timescaleToDays } from '~/pods/insights/constants';
import {
  Availability,
  InsightsEstate,
  InsightsLead,
  InsightsSession,
  Project,
} from '~/pods/insights/types';
import { Subscription } from '~/types';
import { run, useAuth, useBackofficeQuery } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { shouldUseShowcaseData } from '../../showcase';
import { useFetchOrganisation } from '../../utils';
import { CampaignPlaceholder, CampaignsGuardCard, DashboardLoader } from './components';
import {
  DealsCard,
  LeadsCard,
  MarketingCampaignCard,
  TractionCard,
  VisitsPerDayCard,
} from './components/cards';

const tt = createTranslate('pods.insights.routes.dashboard');

function _getEarliestDate(
  units: InsightsEstate['units'],
  sessions: Array<InsightsSession>,
  leads: Array<InsightsLead>,
) {
  const soldUnits = units.filter((u) => {
    return u.availability === Availability.BOOKED && u.soldAt != null;
  });

  const allDates = [
    ...soldUnits.map((u) => u.soldAt!),
    ...sessions.map((s) => s.createdAt),
    ...leads.map((l) => l.createdAt),
  ];

  // If there are no sold units, no leads and no sessions yet, use a placeholder date
  if (allDates.length === 0) {
    return dayjs().subtract(1, 'month');
  }

  return dayjs.min(allDates);
}

function _calcRangeFromTimescale(timescale: string, earliestDate: Dayjs) {
  const end = dayjs().startOf('day');
  const start =
    timescale === Timescale.ALL_TIME
      ? earliestDate
      : end.subtract(timescaleToDays[timescale] - 1, 'day');

  return { start, end };
}

const BackofficeCampaignsQuery = gql`
  query CampaignsQuery($projectId: ID!) {
    campaigns(projectId: $projectId) {
      token
    }
  }
`;

interface DashboardProps {
  estate?: InsightsEstate;
  project?: Project;
  leads?: Array<InsightsLead>;
  sessions?: Array<InsightsSession>;
  isLoading: boolean;
}

export const Dashboard = ({ estate, leads, sessions, isLoading, project }: DashboardProps) => {
  const [timescale, setTimescale] = useState(Timescale.WEEK);
  const { user } = useAuth();

  const { isLoading: isOrganisationLoading, organisation } = useFetchOrganisation(project?.id);

  const { data: campaignsData } = useBackofficeQuery<
    { campaigns: Array<{ token: string }> },
    { projectId: string }
  >({
    query: BackofficeCampaignsQuery,
    pause: project?.id == null || shouldUseShowcaseData(project?.id ?? ''),
    variables: { projectId: project?.id ?? '' },
  });
  const campaigns = campaignsData?.campaigns ?? [];

  return (
    <Fragment>
      <MosaicPageTitle>{tt('dashboard')}</MosaicPageTitle>
      {run(() => {
        if (isLoading || isOrganisationLoading) {
          return <DashboardLoader />;
        } else if (
          estate == null ||
          project == null ||
          sessions == null ||
          leads == null ||
          organisation == null
        ) {
          console.error('One or more datasets were null although loading is completed', {
            estate,
            project,
            sessions,
            leads,
            organisation,
          });
          return <InsightsLoadingError />;
        }

        const { currency: organisationCurrency } = organisation;
        const { slug: estateSlug, units } = estate;
        const listingCurrency = units[0]?.price.currency;

        const daysAmount = timescaleToDays[timescale];

        const earliestDate = _getEarliestDate(units, sessions, leads);
        const range = _calcRangeFromTimescale(timescale, earliestDate);

        return (
          <Fragment>
            <Margin size={{ bottom: Size.SMALL }}>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <SegmentedControl>
                    <SegmentedControlItem
                      text={tt('last_week')}
                      onClick={() => setTimescale(Timescale.WEEK)}
                      active={timescale === Timescale.WEEK}></SegmentedControlItem>
                    <SegmentedControlItem
                      text={tt('last_month')}
                      onClick={() => setTimescale(Timescale.MONTH)}
                      active={timescale === Timescale.MONTH}></SegmentedControlItem>
                    <SegmentedControlItem
                      text={tt('last_three_months')}
                      onClick={() => setTimescale(Timescale.TRIMESTER)}
                      active={timescale === Timescale.TRIMESTER}></SegmentedControlItem>
                    <SegmentedControlItem
                      text={tt('all_time')}
                      onClick={() => setTimescale(Timescale.ALL_TIME)}
                      active={timescale === Timescale.ALL_TIME}></SegmentedControlItem>
                  </SegmentedControl>
                </FlexItem>
              </Flex>
            </Margin>
            <Grid columns={3} vGutters={Size.DEFAULT} hGutters={Size.DEFAULT}>
              <GridItem>
                <TractionCard
                  estateSlug={estateSlug}
                  sessions={sessions}
                  range={range}
                  timescale={timescale}
                />
              </GridItem>
              <GridItem>
                <LeadsCard range={range} leads={leads} sessions={sessions} />
              </GridItem>
              <GridItem>
                <DealsCard
                  units={units}
                  range={range}
                  currency={listingCurrency ?? organisationCurrency}
                />
              </GridItem>
              <GridItem span={2}>
                <VisitsPerDayCard
                  sessions={sessions}
                  range={range}
                  daysAmount={daysAmount}
                  timescale={timescale}
                />
              </GridItem>
              <GridItem
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                {run(() => {
                  if (user?.organisation?.subscription === Subscription.ESSENTIALS) {
                    return <CampaignsGuardCard />;
                  } else if (campaigns.length > 0) {
                    return <MarketingCampaignCard range={range} sessions={sessions} />;
                  } else {
                    return <CampaignPlaceholder />;
                  }
                })}
              </GridItem>
            </Grid>
          </Fragment>
        );
      })}
    </Fragment>
  );
};
