import { Shade, Text } from '@drawbotics/react-drylus';
import React from 'react';

interface TextWithFallbackProps {
  fallback: string;
  value?: string;
}

export const TextWithFallback = ({ fallback, value }: TextWithFallbackProps) => {
  return <Text shade={value != null ? undefined : Shade.MEDIUM}>{value ?? fallback}</Text>;
};
