import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Paragraph,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

import { Diffusion } from '../domain';
import { useUnpublish } from '../hooks/use-unpublish';

const tt = createTranslate('pods.multidiffusion.components.unpublish_modal');

interface UnpublishModalProps {
  diffusion?: Diffusion;
  onClickClose: VoidFunction;
}

export const UnpublishModal = ({ diffusion, onClickClose }: UnpublishModalProps) => {
  const { isLoading, unpublish } = useUnpublish();

  const _handleOnClickUnpublish = async () => {
    if (diffusion != null) {
      await unpublish(diffusion);
    }
    onClickClose();
  };

  return (
    <Modal
      title={tt('title')}
      visible={diffusion != null}
      style={{ width: 500 }}
      onClickClose={onClickClose}
      footer={
        <Flex align={FlexAlign.END} justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} category={Category.INFO} onClick={onClickClose}>
              {tt('buttons.cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              leading={isLoading ? <Spinner /> : null}
              category={Category.DANGER}
              onClick={_handleOnClickUnpublish}>
              {tt('buttons.unpublish')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Paragraph>{tt('disclaimer')}</Paragraph>
    </Modal>
  );
};
