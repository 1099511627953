import { Attachment } from '~/types';

import { User } from './user';

export enum PresentationStatus {
  INITIATED = 'INITIATED',
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export interface Presentation {
  id: string;
  status: PresentationStatus;
  name: string;
  slug: string;
  createdAt: string;
  coverPicture?: Attachment;
  listingDisplay: boolean;
  presentationUrl: string;
  assignedUsers: Array<User>;
}
