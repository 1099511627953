import sv from '@drawbotics/drylus-style-vars';
import { Paragraph, Shade, Size, Text, Title } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import metaImage from '../images/meta-image.svg';
import { LoremIpsum } from '../utils';

// Compute the aspect ratio for the OG image preview box, based on
// the recommended width and height
const OG_IMAGE_ASPECT_RATIO = 1200 / 630;

const styles = {
  container: css`
    padding: ${sv.paddingSmall};
    border-radius: ${sv.defaultBorderRadius};
    border: 1px solid ${sv.neutral};
    background: ${sv.neutralLighter};
  `,
  image: css`
    overflow: hidden;
    border-radius: ${sv.defaultBorderRadius};
    object-fit: cover;
    aspect-ratio: ${OG_IMAGE_ASPECT_RATIO};
    width: 100%;
  `,
};

interface OpenGraphPreviewProps {
  title?: string;
  description?: string;
  domain?: string;
  image?: string;
}

export const OpenGraphPreview = ({
  title,
  description,
  domain,
  image = metaImage,
}: OpenGraphPreviewProps) => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={image} />
      <Title style={{ opacity: title == null ? 0.5 : undefined }} size={4}>
        {title ?? LoremIpsum.SHORT}
      </Title>
      <Paragraph>
        <Text
          style={{ opacity: title == null ? 0.5 : undefined }}
          size={Size.SMALL}
          shade={Shade.LIGHT}>
          {description ?? LoremIpsum.MEDIUM}
        </Text>
      </Paragraph>
      <Text style={{ opacity: title == null ? 0.5 : undefined }} shade={Shade.MEDIUM}>
        {domain ?? 'www.lorem.ipsum'}
      </Text>
    </div>
  );
};
