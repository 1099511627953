import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Button,
  Flex,
  FlexDirection,
  FlexItem,
  Label,
  ListTile,
  Padding,
  Panel,
  PanelBody,
  Paragraph,
  RoundIcon,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import marketingCampaign from '~/pods/insights/images/placeholders/marketing-campaign-small.svg';
import { useIntercom, createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.dashboard');

export const CampaignsGuardCard = () => {
  const { showIntercom } = useIntercom();
  return (
    <Panel
      style={{ height: 340 }}
      body={
        <PanelBody>
          <ListTile
            leading={
              <RoundIcon
                name="media-kit"
                size={50}
                style={{ backgroundColor: sv.purpleLighter, color: sv.purple }}
              />
            }
            title={<Label>{tt('marketing_campaigns')}</Label>}
          />
          <Padding size={{ vertical: Size.SMALL }}>
            <Flex direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <img width="120" src={marketingCampaign} />
              </FlexItem>
              <FlexItem flex>
                <Paragraph align={Align.CENTER}>
                  <Text>{tt('start_tracking')}</Text>
                </Paragraph>
              </FlexItem>
              <FlexItem>
                <Button tier={Tier.SECONDARY} onClick={showIntercom}>
                  {tt('contact_us_2')}
                </Button>
              </FlexItem>
            </Flex>
          </Padding>
        </PanelBody>
      }
    />
  );
};
