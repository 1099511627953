import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import {
  AttachmentBox,
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  Margin,
  Modal,
  Separator,
  Shade,
  Size,
  Text,
  Tier,
  UploadBox,
} from '@drawbotics/react-drylus';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';

import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

import { AssetData } from '../../api/domain';
import { GalleryModalItem } from '../GalleryModalItem';

const tt = createTranslate('pods.website_builder.routes.website_builder');

// Sorts assets based on position and putting the ones marked for
// deletion at the end. Also updates the new positions as well.
//
// If reset is set to true, it will ignore existing positions and will
// use array index instead.
function _sortAssets(assets: Array<AssetData>, reset = false): Array<AssetData> {
  const assetsToKeep = assets
    .filter((a) => !a.destroy)
    .sort((a, b) => (reset ? 0 : a.position! - b.position!));
  const assetsToDelete = assets.filter((a) => a.destroy);
  // NOTE: Order needs to start at 1 (hence i + 1), otherwise back-end will complain
  return [...assetsToKeep, ...assetsToDelete].map((a, i) => ({ ...a, position: i + 1 }));
}

interface UploadGalleryModalProps {
  visible: boolean;
  assets: Array<AssetData>;
  onClickClose: VoidFunction;
  onChangeAssets: (assets: Array<AssetData>) => void;
}

export const UploadGalleryModal = ({
  visible,
  onClickClose,
  assets,
  onChangeAssets,
}: UploadGalleryModalProps) => {
  const [tempAssets, setTempAssets] = useState<Array<AssetData>>(assets);
  const [assetsToDelete, setAssetsToDelete] = useState<Array<AssetData>>([]);

  // If the visibility changes, restore the original assets
  useEffect(() => {
    setTempAssets(assets);
    setAssetsToDelete([]);
  }, [visible]);

  const { uploadFiles, info, isLoading: isUploading } = useFileUpload(useMosaicMutation);

  const _updateAsset = (asset: AssetData, data: Partial<AssetData>): void => {
    const updatedAssets = tempAssets.map((a) => (a.url === asset.url ? { ...a, ...data } : a));
    setTempAssets(updatedAssets);
  };

  const _deleteAsset = (asset: AssetData): void => {
    const assetToDelete = tempAssets.find((a) => a.url === asset.url);

    // It was stored in the server, so add it to the "assetsToDelete"
    // Otherwise, just remove it from "tempAssets"
    if (assetToDelete?.assetId != null) {
      setAssetsToDelete([...assetsToDelete, { ...assetToDelete, destroy: true }]);
    }

    setTempAssets(_sortAssets(tempAssets.filter((a) => a.url !== assetToDelete?.url)));
  };

  const _handleOnSave = async (): Promise<void> => {
    // If an asset is marked for deletion, only send it to the server if it already existed
    const finalAssets = tempAssets
      .filter((a) => a.assetId != null || !a.destroy)
      .map((a) => omit(a, 'url')); // exclude URL to make sure we're always working with AttachmentAsset (back-end requirements)

    // TODO handle errors
    onChangeAssets([..._sortAssets(finalAssets), ...assetsToDelete]);
    onClickClose();
  };

  const _handleOnUpload = async (fileList: FileList): Promise<void> => {
    const res = await uploadFiles(fileList);
    const uploadedFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;

    if (uploadedFiles.length > 0) {
      const assetsFromFiles: Array<AssetData> = uploadedFiles.map((f) => ({
        signedBlobId: f.signedBlobId,
        url: f.url,
        filename: f.originalFile.name,
      }));

      setTempAssets(_sortAssets([...tempAssets, ...assetsFromFiles]));
    }
  };

  const _handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over != null && active.id !== over.id) {
      // REMEMBER: We told the drag&drop system that the urls are the ids
      const oldIndex = tempAssets.findIndex((a) => a.url === active.id);
      const newIndex = tempAssets.findIndex((a) => a.url === over.id);
      const updatedAssets = arrayMove(tempAssets, oldIndex, newIndex);
      setTempAssets(_sortAssets(updatedAssets, true));
    }
  };

  return (
    <Modal
      title={tt('upload_modal_title')}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              disabled={tempAssets.length < 4}
              onClick={_handleOnSave}
              category={Category.INFO}>
              {tt('save')}
            </Button>
          </FlexItem>
        </Flex>
      }
      visible={visible}
      onClickClose={onClickClose}>
      <Margin size={{ bottom: Size.EXTRA_SMALL }}>
        <Label>{tt('upload_images')}</Label>
      </Margin>
      <Margin size={{ bottom: Size.SMALL }}>
        <UploadBox
          allowedFileFormats=".png,.jpg,.jpeg"
          multiple
          onUploadFiles={_handleOnUpload}
          style={{ maxWidth: '100%' }}
        />
      </Margin>
      <Margin size={{ bottom: Size.SMALL }}>
        {isUploading
          ? info.map((uploadInfo) => (
              <Margin key={uploadInfo.filename} size={{ vertical: Size.EXTRA_SMALL }}>
                <AttachmentBox fileName={uploadInfo.filename} progress={uploadInfo.progress} />
              </Margin>
            ))
          : null}
      </Margin>
      <Margin size={{ bottom: Size.SMALL }}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={_handleDragEnd}>
          <SortableContext items={tempAssets.map((a) => a.url!)}>
            {tempAssets
              .slice()
              .sort((a, b) => a.position! - b.position!) // if position is undefined, they'll be pushed to the end
              .map((asset) => (
                <Margin key={asset.url} size={{ vertical: Size.EXTRA_SMALL }}>
                  <GalleryModalItem
                    // Use the url as id because it's more consistent. Since only
                    // assets that have been saved in the back-end will have a real id.
                    id={asset.url!}
                    onChangeAlt={(alt) => _updateAsset(asset, { alt })}
                    alt={asset.alt}
                    imageUrl={asset.url!}
                    onDelete={() => _deleteAsset(asset)}
                  />
                </Margin>
              ))}
          </SortableContext>
        </DndContext>
      </Margin>
      {tempAssets.length < 4 ? (
        <Flex>
          <FlexItem flex>
            <Separator />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Text shade={Shade.LIGHT} light>
              {tt('minimum_upload')}
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem flex>
            <Separator />
          </FlexItem>
        </Flex>
      ) : null}
    </Modal>
  );
};
