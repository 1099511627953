import dayjs, { Dayjs } from 'dayjs';
import { last } from 'lodash';

import { Meeting, User } from '~/pods/product-stats/types';
import { median } from '~/utils';

import { StatsData, UserMeetingsOverTime } from './use-load-stats';

export const DATE_FORMAT = 'MM-YYYY';

function _generateMonthlyDateRange(a: Dayjs, b: Dayjs): Array<Dayjs> {
  const difference = Math.abs(b.diff(a, 'month'));
  return [...Array(difference)].map((_, i) => a.add(i, 'month'));
}

export function computeStatsData(meetings: Array<Meeting>, users: Array<User>): StatsData {
  // Data for meetingsPerUser is recorded until 6 months ago, per month
  // All other stats are based on the last month of recorded data

  const halfAYearAgo = dayjs().subtract(6, 'month').date(1); // get first day of the month 6 months ago
  const lastMonth = dayjs().subtract(1, 'month');
  const today = dayjs();

  const lastMonthsMeetings: Array<Meeting> = [];

  // Reduce returns meetings per user ID, but within we build the last month's meetings
  // to avoid iterating over all meetings next
  const meetingsPerUserId = meetings.reduce((memo, meeting: Meeting) => {
    if (meeting.date.isBefore(halfAYearAgo, 'day') || meeting.date.isAfter(today, 'day')) {
      return memo;
    }
    if (meeting.date.isAfter(lastMonth, 'day')) {
      lastMonthsMeetings.push(meeting);
    }
    const userId = meeting.user.id;
    const meetingMonth = meeting.date.format(DATE_FORMAT); // formatted as 10-2020
    const userMeetingMonths = memo[userId] ?? {};
    const monthMeetings = userMeetingMonths[meetingMonth] ?? 0;
    return {
      ...memo,
      [userId]: { ...userMeetingMonths, [meetingMonth]: monthMeetings + 1 },
    };
  }, {} as Record<string, Record<string, number>>);

  const meetingsPerUser: Array<UserMeetingsOverTime> = Object.keys(meetingsPerUserId).map((id) => ({
    user: users.find((user) => user.id === id)!,
    meetings: Object.keys(meetingsPerUserId[id])
      .map((formattedDate) => dayjs(formattedDate, DATE_FORMAT))
      .sort((a, b) => (a.isBefore(b) ? -1 : 1))
      // fill missing months with 0 (since we had 0 meetings in those months)
      .reduce((memo, month: Dayjs) => {
        const previousMonth = last(memo)?.month;
        const generateMissingMonths =
          previousMonth != null && !previousMonth.isSame(month.subtract(1, 'month'));
        const missingMonths = generateMissingMonths
          ? _generateMonthlyDateRange(previousMonth!.add(1, 'month'), month)
          : [];
        return [
          ...memo,
          ...missingMonths.map((month) => ({ month: month, count: 0 })),
          {
            month,
            count: meetingsPerUserId[id][month.format(DATE_FORMAT)],
          },
        ];
      }, [] as Array<{ month: Dayjs; count: number }>),
  }));

  const topPresentations: Record<string, number> = lastMonthsMeetings.reduce(
    (memo, meeting: Meeting) => {
      const { presentations } = meeting;
      for (const presentation of presentations) {
        const count = memo[presentation.name] ?? 0;
        memo[presentation.name] = count + 1;
      }
      return memo;
    },
    {} as Record<string, number>,
  );
  const topPresentation: StatsData['topPresentation'] = Object.keys(topPresentations)
    .map((name) => ({ name, count: topPresentations[name] }))
    .sort((a, b) => b.count - a.count)[0];

  const medianMeetingDuration: number = median(
    lastMonthsMeetings.map((meeting) => meeting.realDuration ?? 0),
  );

  return {
    meetingsPerUser,
    topPresentation,
    medianMeetingDuration,
  };
}
