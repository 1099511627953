import gql from 'fraql';

import { ErrorType, ID, Id } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

export type Licenses = Record<Id, number>;
type LicensesFromServer = Array<{ organisationPublicToken: ID; availableLicenseCount: number }>;

const LicensesQuery = gql`
  query partnerLicensesCount {
    partnerLicensesCount {
      organisationPublicToken
      availableLicenseCount
    }
  }
`;

interface FetchOrganisationFromCore {
  isLoadingLicenses: boolean;
  licenses: Record<ID, number>;
  error?: ErrorType;
  refreshLicenses: VoidFunction;
}

export function useLoadLicenses(): FetchOrganisationFromCore {
  const organisationResult = useMosaicQuery<{ partnerLicensesCount: LicensesFromServer }, null>({
    query: LicensesQuery,
  });

  const res =
    organisationResult.data?.partnerLicensesCount == null
      ? {}
      : organisationResult.data?.partnerLicensesCount.map((obj) => [
          obj.organisationPublicToken,
          obj.availableLicenseCount,
        ]);

  return {
    isLoadingLicenses: organisationResult.isLoading,
    licenses: res,
    error: buildError(organisationResult.error),
    refreshLicenses: () => organisationResult.refetch({ requestPolicy: 'network-only' }),
  };
}
