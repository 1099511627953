import sv from '@drawbotics/drylus-style-vars';
import { useEffect, useLayoutEffect, useState } from 'react';

import { User } from '~/types';

import { useAuth } from './use-auth';

const IS_DEV = process.env.NODE_ENV === 'development';
const INTERCOM_LAUNCHER_ID = 'intercom-trigger';
const INTERCOM_APP_ID = IS_DEV ? 'qcop09hj' : 'txmuxzbr';

function getIntercomSettingsForUser(user: User | undefined): Intercom_.IntercomSettings {
  return {
    app_id: INTERCOM_APP_ID,
    hide_default_launcher: true,
    background_color: sv.neutralDarkest,
    action_color: sv.blue,
    user_hash: user?.intercomHash,
    ...(user
      ? {
          user_id: user.id,
          email: user.email,
          user_hash: user.intercomHash,
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.profilePicture
            ? {
                type: 'avatar',
                image_url: user.profilePicture.url,
              }
            : undefined,
          company: {
            id: user.organisation?.id ?? 'No organisation yet',
            name: user.organisation?.name ?? 'No organisation yet',
            plan: user.organisation?.subscription,
          },
        }
      : {}),
  };
}

export function useIntercom(defaultUser?: User): {
  showIntercom: VoidFunction;
  hideIntercom: VoidFunction;
} {
  const [intercomOpen, setIntercomOpen] = useState(false);
  const [booted, setBooted] = useState(false);
  const [outletElement, setOutletElement] = useState<HTMLElement>();
  const { user } = useAuth() ?? { user: defaultUser };

  const showIntercom = () => {
    if (outletElement != null && !intercomOpen) {
      setIntercomOpen(true);
      outletElement.click();
    }
  };

  const hideIntercom = () => {
    if (outletElement != null && intercomOpen) {
      setIntercomOpen(false);
      outletElement.click();
    }
  };

  useLayoutEffect(() => {
    if (window.Intercom != null) {
      window.Intercom('boot', getIntercomSettingsForUser(user));
      window.Intercom('onHide', () => setIntercomOpen(false));
      setBooted(true);
    }
  }, [user?.id]);

  useEffect(() => {
    if (user != null) {
      window.intercomSettings = getIntercomSettingsForUser(user);
    }
  }, [user?.id]);

  useEffect(() => {
    if (booted) {
      window.Intercom('update', { custom_launcher_selector: `#${INTERCOM_LAUNCHER_ID}` });
    }
  }, [booted, outletElement]);

  useEffect(() => {
    const outlet = document.getElementById(INTERCOM_LAUNCHER_ID);

    if (outlet == null) {
      const intercomTrigger = document.createElement('a');
      intercomTrigger.id = INTERCOM_LAUNCHER_ID;
      document.body.appendChild(intercomTrigger);
      setOutletElement(intercomTrigger);
    } else {
      setOutletElement(outlet);
    }
  }, []);

  return {
    showIntercom,
    hideIntercom,
  };
}
