/**
 * Util functions related to managing countries and addresses
 */

import countries from 'i18n-iso-countries';
import { omit } from 'lodash';

import { createTranslate, getCountryName } from '~/utils';

const tt = createTranslate('pods.presentation.routes.presentation.components.presentation_details');

// The countries our customers are most likely to originate from. If adding to this list,
// also update the country -> regions mapping below
export const PRIORITY_COUNTRY_CODES = ['FR', 'BE', 'NL', 'LU', 'GB', 'US', 'CA'];

// Wrapped in a function to make sure the translation function runs every time
export function getRegionsForCountry(): Record<
  typeof PRIORITY_COUNTRY_CODES[number],
  Array<string>
> {
  return {
    // Regions after 2016 territorial reform, 13
    FR: [
      'Auvergne-Rhône-Alpes',
      'Bourgogne-Franche-Comté',
      'Bretagne',
      'Centre-Val de Loire',
      'Corse',
      'Grand Est',
      'Hauts-de-France',
      'Île-de-France',
      'Normandie',
      'Nouvelle-Aquitaine',
      'Occitanie',
      'Pays de la Loire',
      "Provence-Alpes-Côte d'Azur",
    ],
    // Provinces, 3
    BE: [tt('wallonia'), tt('flanders'), tt('brussels')],
    // Provinces, 12
    NL: [
      'Drenthe',
      'Flevoland',
      'Friesland',
      'Gelderland',
      'Groningen',
      'Limburg',
      'Noord-Brabant',
      'Overijssel',
      'Zuid-Holland',
      'Utrecht',
      'Zeeland',
    ],
    // Cantons, 12
    LU: [
      'Klierf',
      'Dikrech',
      'Réiden',
      'Veianen',
      'Wolz',
      'Iechternach',
      'Gréiwemaacher',
      'Capellen',
      'Esch-Uelzecht',
      'Lëtzebuerg ',
      'Miersch',
      'Réimech',
    ],
    // 9
    GB: [
      'South East',
      'London',
      'North West',
      'East of England',
      'West Midlands',
      'South West',
      'Yorkshire and the Humber',
      'East Midlands',
    ],
    // States, 50
    US: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
    // provinces, 10 and territories, 3
    CA: [
      'Ontario',
      'Quebec',
      'Nova Scotia',
      'New Brunswick',
      'Manitoba',
      'British Columbia',
      'Prince Edward Island',
      'Saskatchewan',
      'Alberta',
      'Newfoundland and Labrador',
      'Northwest Territories',
      'Yukon',
      'Nunavut',
    ],
  };
}

export function getRegionOptionsForCountry(countryCode?: string) {
  if (countryCode == null || !PRIORITY_COUNTRY_CODES.includes(countryCode)) {
    return [];
  }

  return getRegionsForCountry()[countryCode].map((region) => ({ label: region, value: region }));
}

export function isPriorityCountry(code?: string) {
  return code != null && PRIORITY_COUNTRY_CODES.includes(code);
}

function _countryCodeToSelectOption(code: string) {
  return {
    label: getCountryName(code),
    value: code,
  };
}

// Creates an array of all countries for use as the options array of a select element
export function getCountryOptions() {
  const nonPriorityCountryCodes = Object.keys(
    omit(countries.getAlpha2Codes(), PRIORITY_COUNTRY_CODES),
  );

  return [
    ...PRIORITY_COUNTRY_CODES.map(_countryCodeToSelectOption),
    {
      label: '──────────',
      value: '_separator',
      disabled: true,
    },
    ...nonPriorityCountryCodes
      .map(_countryCodeToSelectOption)
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
}
