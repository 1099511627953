import sv from '@drawbotics/drylus-style-vars';
import {
  Dot,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  ListTile,
  Margin,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';

import { PieChart } from '~/pods/insights/components';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.dashboard');

interface CampaignsChartProps {
  total: number;
  campaigns: number;
}

export const CampaignsChart = ({ total, campaigns }: CampaignsChartProps) => {
  return (
    <Flex justify={FlexJustify.SPACE_EVENLY}>
      <FlexItem>
        <div style={{ width: '120px', height: '120px' }}>
          <PieChart
            data={[
              {
                id: 'campaignViews',
                label: tt('marketing_campaigns'),
                value: campaigns,
                color: sv.purple,
              },
              {
                id: 'organicSearch',
                label: tt('organic_search'),
                value: total - campaigns,
                color: sv.neutralLight,
              },
            ]}
          />
        </div>
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem>
        <Margin size={{ bottom: Size.SMALL }}>
          <ListTile
            leading={<Dot size={Size.LARGE} color={sv.purple} />}
            title={<Text tier={Tier.SECONDARY}>{tt('marketing_campaigns')}</Text>}
          />
        </Margin>
        <ListTile
          leading={<Dot size={Size.LARGE} color={sv.neutralLight} />}
          title={<Text tier={Tier.SECONDARY}>{tt('organic_search')}</Text>}
        />
      </FlexItem>
    </Flex>
  );
};
