import {
  Content,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Margin,
  Padding,
  Panel,
  PanelBody,
  Size,
  Spinner,
} from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

export const SectionsLoader = () => {
  return (
    <Fragment>
      <LoadingPlaceholder width="100%" height={100} />
      <Margin size={{ top: Size.LARGE }} />
      <LoadingPlaceholder width="100%" height={100} />
      <Margin size={{ top: Size.LARGE }} />
      <LoadingPlaceholder width="100%" height={100} />
      <Margin size={{ top: Size.LARGE }} />
      <LoadingPlaceholder width="100%" height={100} />
      <Margin size={{ top: Size.LARGE }} />
      <LoadingPlaceholder width="100%" height={100} />
    </Fragment>
  );
};

export const LoadingPresentationPlaceholder = () => {
  return (
    <Content fullHeight>
      <Padding size={Size.LARGE}>
        <Flex justify={FlexJustify.START}>
          <FlexItem>
            <LoadingPlaceholder height={28} width={26} />
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <LoadingPlaceholder height={28} width={100} />
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <LoadingPlaceholder height={28} width={130} />
          </FlexItem>
        </Flex>
        <Margin size={{ vertical: Size.LARGE }}>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem flex>
              <LoadingPlaceholder height={40} width={250} />
            </FlexItem>
            <FlexItem>
              <LoadingPlaceholder height={40} width={150} />
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <LoadingPlaceholder height={40} width={150} />
            </FlexItem>
          </Flex>
        </Margin>
        <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.START}>
          <FlexItem flex>
            <Panel
              body={
                <PanelBody>
                  <Flex style={{ height: '50vh' }}>
                    <FlexItem>
                      <Spinner size={Size.LARGE} />
                    </FlexItem>
                  </Flex>
                </PanelBody>
              }
            />
          </FlexItem>
          <FlexSpacer size={Size.LARGE} />
          <FlexItem flex>
            <SectionsLoader />
          </FlexItem>
        </Flex>
      </Padding>
    </Content>
  );
};
