import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  Label,
  ListTile,
  LoadingPlaceholder,
  Margin,
  Panel,
  PanelBody,
  RoundIcon,
  Size,
} from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';
import { Fragment } from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { createTranslate } from '~/utils';

import { PanelWithTitle } from './PanelWithTitle';

const tt = createTranslate('pods.insights.routes.dashboard');

const TractionIcon = () => (
  <RoundIcon
    name="bar-chart"
    size={50}
    style={{ backgroundColor: sv.greenLighter, color: sv.green }}
  />
);

const LeadsIcon = () => (
  <RoundIcon
    name="users"
    size={50}
    style={{ backgroundColor: sv.orangeLighter, color: sv.orange }}
  />
);

const DealsIcon = () => (
  <RoundIcon name="award" size={50} style={{ backgroundColor: sv.blueLighter, color: sv.blue }} />
);

const CampaignIcon = () => (
  <RoundIcon
    name="media-kit"
    size={50}
    style={{ backgroundColor: sv.purpleLighter, color: sv.purple }}
  />
);

interface PlaceholderPanelProps {
  icon: ReactNode;
  title: string;
}

const PlaceholderPanel = ({ title, icon }: PlaceholderPanelProps) => {
  return (
    <Panel
      body={
        <PanelBody>
          <LoadingCardHeader icon={icon} title={title} />
          <Margin size={{ top: Size.DEFAULT }}>
            <Flex>
              <FlexItem>
                <LoadingPlaceholder height={56} width={100} />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem flex>
                <Margin size={{ bottom: Size.EXTRA_SMALL }}>
                  <LoadingPlaceholder width="100%" />
                </Margin>
                <LoadingPlaceholder width="70%" />
              </FlexItem>
            </Flex>
            <Margin size={{ top: Size.EXTRA_LARGE }}>
              <LoadingPlaceholder width="100%" />
            </Margin>
          </Margin>
        </PanelBody>
      }
    />
  );
};

interface LoadingCardHeaderProps {
  icon: ReactNode;
  title: string;
}

const LoadingCardHeader = ({ icon, title }: LoadingCardHeaderProps) => {
  return (
    <Margin size={{ bottom: Size.DEFAULT }}>
      <Flex justify={FlexJustify.SPACE_BETWEEN}>
        <FlexItem>
          <ListTile leading={icon} title={<Label>{title}</Label>} />
        </FlexItem>
        <FlexItem>
          <LoadingPlaceholder width={140} />
        </FlexItem>
      </Flex>
    </Margin>
  );
};

export const DashboardLoader = () => {
  return (
    <Fragment>
      <Margin size={{ bottom: Size.SMALL }}>
        <Flex justify={FlexJustify.START}>
          <FlexItem>
            <LoadingPlaceholder width={512} height={40} />
          </FlexItem>
          <FlexItem>
            <Margin size={{ left: Size.DEFAULT }}>
              <LoadingPlaceholder width={154} height={40} />
            </Margin>
          </FlexItem>
        </Flex>
      </Margin>
      <Grid columns={3} vGutters={Size.DEFAULT}>
        <GridItem>
          <PlaceholderPanel icon={<TractionIcon />} title={tt('traction')} />
        </GridItem>
        <GridItem>
          <PlaceholderPanel icon={<LeadsIcon />} title={tt('leads')} />
        </GridItem>
        <GridItem>
          <PlaceholderPanel icon={<DealsIcon />} title={tt('deals')} />
        </GridItem>
        <GridItem span={2}>
          <PanelWithTitle
            title={tt('how_much_traction')}
            body={
              <PanelBody>
                <Margin size={{ bottom: Size.SMALL }}>
                  <Flex justify={FlexJustify.SPACE_BETWEEN}>
                    <FlexItem>
                      <Label>{tt('visits_per_day')}</Label>
                    </FlexItem>
                  </Flex>
                </Margin>
                <LineChartPlaceholder height={238} message={tt('loading')} />
              </PanelBody>
            }
          />
        </GridItem>
        <GridItem style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Panel
            body={
              <PanelBody>
                <ListTile
                  leading={<CampaignIcon />}
                  title={<Label>{tt('marketing_campaigns')}</Label>}
                />
                <Margin size={{ top: Size.LARGE, bottom: Size.EXTRA_LARGE }}>
                  <Flex align={FlexAlign.START}>
                    <FlexItem flex>
                      <LoadingPlaceholder width="100%" height={150} />
                    </FlexItem>
                    <FlexSpacer size={Size.SMALL} />
                    <FlexItem flex>
                      <Margin size={{ bottom: Size.DEFAULT }}>
                        <LoadingPlaceholder width="100%" height={sv.defaultMargin} />
                      </Margin>
                      <Margin size={{ bottom: Size.DEFAULT }}>
                        <LoadingPlaceholder width="60%" height={sv.defaultMargin} />
                      </Margin>
                      <LoadingPlaceholder width="80%" height={sv.defaultMargin} />
                    </FlexItem>
                  </Flex>
                </Margin>
              </PanelBody>
            }
          />
        </GridItem>
      </Grid>
    </Fragment>
  );
};
