import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { LinkType } from '../../types';

// @ts-ignore Type instantiation is excessively deep and possibly infinite.
export const LinkSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  linkType: z.nativeEnum(LinkType),
  label: ze.optional(z.string()),
  newTab: ze.optional(z.boolean()),
  attachment: ze.optional(
    z.object({
      id: z.string(),
      url: z.string(),
      filename: z.string(),
    }),
  ),
  targetWebsiteSection: ze.optional(z.object({ id: z.string(), identifier: z.string() })), // Avoid error from recursion
  url: ze.optional(z.string()),
});

export type LinkData = z.infer<typeof LinkSchema>;

export interface UpdateLinkData {
  linkType?: LinkType;
  targetSectionId?: string;
  label?: string;
  newTab?: boolean;
  url?: string;
  signedBlobId?: string;
}
