import sv from '@drawbotics/drylus-style-vars';
import {
  CheckboxFilter,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Label,
  Margin,
  PanelSection,
  Shade,
  Size,
  Text,
  Tooltip,
} from '@drawbotics/react-drylus';
import { BarExtendedDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { css } from 'emotion';
import React, { Fragment, useEffect, useState } from 'react';

import { Campaign } from '~/pods/insights/types';
import { getYGridLines } from '~/pods/insights/utils/get-y-grid-lines';
import { createTranslate } from '~/utils';

import { HorizontalLegend } from './HorizontalLegend';

const tt = createTranslate('pods.insights.routes.campaigns');

// Magic values for the bar chart padding that force the bars to take up 32 pixels in width
function _getBarsPadding(barsAmount: number): number {
  switch (barsAmount) {
    case 1:
      return 0.82;
    case 2:
      return 0.735;
    case 3:
      return 0.64;
    case 4:
      return 0.555;
    default:
      return 0.46;
  }
}

const styles = {
  chartContainer: css`
    width: 100%;
    height: 340px;
  `,
};

interface CustomTooltipProps {
  metric: 'visits' | 'leads' | 'conversionRate';
  point: BarExtendedDatum;
}

const CustomTooltip = ({ point, metric }: CustomTooltipProps) => {
  const value = point.data[metric === 'conversionRate' ? 'hConversionRate' : `${metric}Count`];
  return (
    <div
      style={{
        borderRadius: sv.defaultBorderRadius,
        padding: sv.paddingExtraSmall,
        background: sv.neutralDarkest,
      }}>
      <Margin size={{ bottom: Size.EXTRA_SMALL }}>
        <Text inversed>{point.data.name}</Text>
      </Margin>
      <Text inversed size={Size.SMALL} shade={Shade.LIGHT}>{`${value} ${tt(`keys.${metric}`, {
        count: value,
      })}`}</Text>
    </div>
  );
};

const StyledBarChart = ({
  data,
  keys,
  tickValues,
  withRate,
  ...rest
}: BarSvgProps & { tickValues?: Array<number>; withRate?: boolean }) => {
  return (
    <ResponsiveBar
      colors={({ data: { color } }) => color}
      padding={_getBarsPadding(data.length)}
      margin={{ top: 35, bottom: 30, right: 25, left: 40 }}
      enableLabel={false}
      indexBy="name"
      borderRadius={4}
      maxValue={20}
      axisBottom={{
        tickSize: 0,
        format: () => '',
      }}
      axisLeft={{
        tickSize: 0,
        tickValues: tickValues,
        format: (v) => (withRate === true ? `${v}%` : `${v}`),
      }}
      data={data}
      keys={keys}
      theme={{
        tooltip: {
          container: {
            background: 'none',
            padding: 0,
          },
        },
        axis: {
          ticks: {
            line: {
              stroke: sv.colorTertiary,
            },
            text: {
              marginTop: -10,
              fontSize: '10pt',
              fontFamily: 'Rubik',
              fontWeight: 400,
              fill: sv.colorSecondary,
            },
          },
        },
        grid: {
          line: {
            strokeDasharray: '4 4',
          },
        },
      }}
      {...rest}
    />
  );
};

interface CampaignsOverviewProps {
  campaigns: Array<Campaign>;
}

export const CampaignsOverview = ({ campaigns }: CampaignsOverviewProps) => {
  const [selectedCampaignsIds, setSelectedCampaignsIds] = useState(campaigns.map((c) => c.token));
  const selectedCampaigns = campaigns.filter((c) => selectedCampaignsIds.includes(c.token));

  // If a campaign is added, it should be selected by default, but only if it's not in the list yet
  useEffect(() => {
    const lastCampaignToken = campaigns[campaigns.length - 1]?.token;

    if (lastCampaignToken != null && !selectedCampaignsIds.includes(lastCampaignToken)) {
      setSelectedCampaignsIds([...selectedCampaignsIds, lastCampaignToken]);
    }
  }, [campaigns.length]);

  const visitsGridValues = getYGridLines(
    selectedCampaigns.map((c) => Math.max(c.visitsCount, 20)),
    5,
  );

  const leadsGridValues = getYGridLines(
    selectedCampaigns.map((c) => Math.max(c.leadsCount, 20)),
    5,
  );

  const conversionGridValues = getYGridLines(
    selectedCampaigns.map((c) => Math.max(c.conversionRate, 20)),
    5,
  );

  const maxSessionsValue = Math.max(
    Math.max(...selectedCampaigns.map((c) => c.visitsCount)) + 10,
    20,
  );
  const maxLeadsValue = Math.max(Math.max(...selectedCampaigns.map((c) => c.leadsCount)), 20);

  return (
    <Fragment>
      <Flex style={{ width: '100%' }} justify={FlexJustify.END}>
        <FlexItem>
          <CheckboxFilter
            clearLabel={tt('select_all')}
            onClear={() => setSelectedCampaignsIds(campaigns.map((campaign) => campaign.token))}
            label={tt('campaigns')}
            onChange={(values) => (values.length !== 0 ? setSelectedCampaignsIds(values) : null)}
            options={campaigns.map((campaign) => ({
              label: campaign.name,
              value: campaign.token,
            }))}
            values={selectedCampaignsIds}
          />
        </FlexItem>
      </Flex>
      <Margin size={{ bottom: Size.DEFAULT }} />
      <Flex style={{ width: '100%' }}>
        <FlexItem flex>
          <Flex justify={FlexJustify.START}>
            <FlexItem>
              <Label>{tt('visitors')}</Label>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Tooltip content={tt('visitors_tooltip')}>
                <Icon shade={Shade.MEDIUM} name="info" />
              </Tooltip>
            </FlexItem>
          </Flex>
          <PanelSection>
            <div className={styles.chartContainer}>
              <StyledBarChart
                data={selectedCampaigns}
                keys={['visitsCount']}
                tickValues={visitsGridValues}
                gridYValues={visitsGridValues}
                maxValue={maxSessionsValue}
                tooltip={(point) => <CustomTooltip point={point} metric="visits" />}
              />
            </div>
          </PanelSection>
        </FlexItem>
        <FlexItem flex>
          <Flex justify={FlexJustify.START}>
            <FlexItem>
              <Label>{tt('leads')}</Label>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Tooltip content={tt('leads_tooltip')}>
                <Icon shade={Shade.MEDIUM} name="info" />
              </Tooltip>
            </FlexItem>
          </Flex>
          <PanelSection>
            <div className={styles.chartContainer}>
              <StyledBarChart
                data={selectedCampaigns}
                keys={['leadsCount']}
                tickValues={leadsGridValues}
                gridYValues={leadsGridValues}
                maxValue={maxLeadsValue}
                tooltip={(point) => <CustomTooltip point={point} metric="leads" />}
              />
            </div>
          </PanelSection>
        </FlexItem>
        <FlexItem flex>
          <Flex justify={FlexJustify.START}>
            <FlexItem>
              <Label>{`${tt('conversion_rate')} (%)`}</Label>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Tooltip content={tt('conversion_rate_tooltip')}>
                <Icon shade={Shade.MEDIUM} name="info" />
              </Tooltip>
            </FlexItem>
          </Flex>
          <PanelSection>
            <div className={styles.chartContainer}>
              <StyledBarChart
                data={selectedCampaigns.map(({ name, conversionRate, hConversionRate, color }) => ({
                  name,
                  conversionRate: conversionRate * 100,
                  hConversionRate: hConversionRate,
                  color,
                }))}
                withRate={true}
                tooltip={(point) => <CustomTooltip point={point} metric="conversionRate" />}
                keys={['conversionRate']}
                tickValues={conversionGridValues}
                gridYValues={conversionGridValues}
              />
            </div>
          </PanelSection>
        </FlexItem>
      </Flex>
      <HorizontalLegend
        items={selectedCampaigns.map(({ name: label, color }) => ({ label, color }))}
      />
    </Fragment>
  );
};
