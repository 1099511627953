import { TargetDomain, getLatestJwt } from '@drawbotics/auth';
import join from 'url-join';

import { clearTokens } from '../tokens';
import { useAuth } from './use-auth';

type UseLogoutOutput = () => void;

export async function logout() {
  await fetch(join(process.env.AUTH_HOST, '/v1/logout'), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getLatestJwt(process.env.AUTH_HOST, TargetDomain.MOSAIC)}`,
      'Target-Domain': TargetDomain.MOSAIC,
    },
  });
}

export function useLogout(): UseLogoutOutput {
  const { user } = useAuth();
  return async () => {
    // The logout call invalidates the current jwt.
    // We don't call it when impersonating as that would force the actual
    // user to log in again on his next visit for no apparent reason.
    if (user?.impersonation == null) {
      await logout();
    }
    clearTokens();
    window.location.replace('/auth/login');
  };
}
