import sv from '@drawbotics/drylus-style-vars';
import {
  Category,
  Color,
  Dropdown,
  DropdownOption,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  IconType,
  Icons,
  LinkUnderlined,
  LoadingPlaceholder,
  Margin,
  Padding,
  Position,
  RoundIcon,
  Shade,
  ShowDateTime,
  Size,
  Tag,
  Text,
  TextLink,
  Tooltip,
  formatDate,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import capitalize from 'lodash/capitalize';
import React, { Fragment, ReactElement } from 'react';

import { CapitalFirst } from '~/components';
import { Status } from '~/pods/crm/components';
import { Log, LogType, MeetingLocation } from '~/pods/crm/types';
import { isLogWithProject, useFetchMeeting } from '~/pods/crm/utils';
import { HexColor } from '~/types';
import { createTranslate, run } from '~/utils';

const styles = {
  logCard: css`
    box-shadow: ${sv.elevation1};
    background: ${sv.white};
    border-radius: ${sv.borderRadiusSmall};
  `,
  pinned: css`
    border-left: 4px solid ${sv.blue};
    box-shadow: ${sv.elevation3};
  `,
  actionIcon: css`
    color: ${sv.colorSecondary};
    display: flex;

    &:hover {
      color: ${sv.colorPrimary};
    }
  `,
  active: css`
    color: ${sv.blue};
  `,
  content: css`
    white-space: pre-line;
  `,
};

const te = createTranslate('pods.crm');
const tt = createTranslate('pods.crm.routes.lead.components.log_card');

export const LogCardPlaceholder = () => {
  return (
    <Margin size={{ bottom: Size.LARGE }}>
      <div className={styles.logCard}>
        <Padding size={Size.SMALL}>
          <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.START}>
            <FlexItem flex>
              <LoadingPlaceholder width="40%" height={24} />
              <Margin size={{ bottom: Size.SMALL }} />
              <LoadingPlaceholder width="30%" height={24} />
            </FlexItem>
            <FlexItem>
              <LoadingPlaceholder width={60} height={24} />
            </FlexItem>
          </Flex>
        </Padding>
      </div>
    </Margin>
  );
};

function _getIconSettingsForType(type: LogType): { icon: IconType; color?: HexColor } {
  switch (type) {
    case LogType.MAIL:
      return { icon: Icons.mail, color: Color.RED };
    case LogType.NOTE:
      return { icon: Icons.edit3, color: Color.BLUE };
    case LogType.LEAD_ASSIGNMENT:
      return { icon: Icons.userCheck, color: Color.GREEN };
    case LogType.LEAD_ARRIVAL:
      return { icon: Icons.userPlus, color: '#643791' };
    case LogType.LEAD_ADDITION:
      return { icon: Icons.userPlus, color: '#643791' };
    case LogType.PHONE_CALL:
      return { icon: Icons.phone, color: Color.BRAND };
    case LogType.STATUS_CHANGE:
      return { icon: Icons.tag, color: '#e3c01b' };
    case LogType.DOCUMENT:
      return { icon: Icons.filePlus, color: '#C28520' };
    case LogType.MEETING:
      return { icon: Icons.calendar, color: '#852785' };
    case LogType.FIRST_LEAD_ASSIGNMENT:
      return { icon: Icons.userCheck, color: Color.GREEN };
    case LogType.REACH_MEETING:
      return { icon: Icons.presentation, color: '#BB36BB' };
    case LogType.REACH_CANCELLED_MEETING:
      return { icon: Icons.slash, color: Color.RED };
    default:
      console.warn(`${type} is not a supported type for logs`);
      return { icon: Icons.clipboard };
  }
}

function _getContentForLog(log: Log): ReactElement | undefined {
  const shade = log.pinned ? undefined : Shade.MEDIUM;
  switch (log.type) {
    case LogType.FIRST_LEAD_ASSIGNMENT:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.first_assignment', {
                author_name: log.author.fullName,
                salesperson: log.salesperson.fullName,
              })
            : tt('content.first_assignment_no_author', {
                salesperson: log.salesperson.fullName,
              })}
        </Text>
      );
    case LogType.STATUS_CHANGE:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.status_change_from', { author_name: log.author.fullName })
            : tt('content.status_change_from_no_author')}{' '}
          <Status step={log.previousStep} /> {tt('content.status_change_to')}{' '}
          <Status step={log.nextStep} />
        </Text>
      );
    case LogType.LEAD_ASSIGNMENT:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.lead_assignment', {
                author_name: log.author.fullName,
                salesperson: log.previousSalesperson.fullName,
                new_salesperson: log.nextSalesperson.fullName,
              })
            : tt('content.lead_assignment_no_author', {
                salesperson: log.previousSalesperson.fullName,
                new_salesperson: log.nextSalesperson.fullName,
              })}
        </Text>
      );
    case LogType.DOCUMENT:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.document', { author: log.author.fullName })
            : tt('content.document_no_author')}
          {log.offer.attachments.map((attachment, i) => (
            <Fragment key={attachment.id}>
              <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                <TextLink>{attachment.filename}</TextLink>
              </a>
              {i < log.offer.attachments.length - 1 ? ', ' : null}
            </Fragment>
          ))}
        </Text>
      );
    case LogType.MEETING:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.meeting', {
                author_name: log.author.fullName,
                lead_name: log.lead.fullName,
              })
            : tt('content.meeting_no_author', {
                lead_name: log.lead.fullName,
              })}
          <br />
          <Margin size={{ top: Size.EXTRA_SMALL }}>
            {log.content != null ? <Text>{log.content}</Text> : null}
          </Margin>
        </Text>
      );
    case LogType.PHONE_CALL:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.call', {
                author_name: log.author.fullName,
                lead_name: log.lead.fullName,
              })
            : tt('content.call_no_author', {
                lead_name: log.lead.fullName,
              })}{' '}
          <Margin size={{ top: Size.EXTRA_SMALL }}>
            {log.content != null ? <Text>{log.content}</Text> : null}
          </Margin>
        </Text>
      );
    case LogType.MAIL:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.email', {
                author_name: log.author.fullName,
                lead_name: log.lead.fullName,
              })
            : tt('content.email_no_author', {
                lead_name: log.lead.fullName,
              })}{' '}
          <br />
          <Margin size={{ top: Size.EXTRA_SMALL }}>
            {log.content != null ? <Text>{log.content}</Text> : null}
          </Margin>
        </Text>
      );
    case LogType.LEAD_ADDITION:
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.added', {
                author_name: log.author.fullName,
                lead_name: log.lead.fullName,
              })
            : tt('content.added_no_author', {
                lead_name: log.lead.fullName,
              })}{' '}
        </Text>
      );
    case LogType.REACH_MEETING: {
      const { isLoading, meeting, error } = useFetchMeeting(log.meeting.id);
      if (isLoading) {
        return (
          <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.START}>
            <FlexItem flex>
              <LoadingPlaceholder width="45%" />
              <Margin size={{ bottom: Size.EXTRA_SMALL }} />
              <LoadingPlaceholder width="30%" />
            </FlexItem>
          </Flex>
        );
      } else if (error != null || meeting == null) {
        return <Text category={Category.DANGER}>{tt('something_went_wrong')}</Text>;
      } else {
        return (
          <Fragment>
            <Text shade={shade}>
              {log.author
                ? tt('meeting_with_lead', {
                    author: log.author.fullName,
                    lead_fullName: meeting.lead.fullName,
                    date: formatDate({
                      date: new Date(meeting.date),
                      options: { showTime: ShowDateTime.ALWAYS },
                    }),
                  })
                : tt('meeting_with_lead_no_author', {
                    lead_fullName: meeting.lead.fullName,
                    date: formatDate({
                      date: new Date(meeting.date),
                      options: { showTime: ShowDateTime.ALWAYS },
                    }),
                  })}
            </Text>
            &nbsp;
            {meeting.additionalParticipants != null && meeting.additionalParticipants.length > 0 ? (
              <Tooltip side={Position.BOTTOM} content={meeting.additionalParticipants.join(', ')}>
                <TextLink shade={shade} underlined={LinkUnderlined.ALWAYS}>
                  {tt('with_additional_participants', {
                    participant_number: meeting.additionalParticipants.length,
                  })}
                </TextLink>
              </Tooltip>
            ) : null}
            <br />
            <Margin size={{ top: Size.EXTRA_SMALL }}>
              {run(() => {
                if (meeting.location === MeetingLocation.PHYSICAL) {
                  return (
                    <Text shade={shade}>
                      {tt('physical_meeting', { address: meeting.address! })}
                    </Text>
                  );
                } else if (meeting.location === MeetingLocation.REMOTE) {
                  return (
                    <Text shade={shade}>
                      {tt('join_the_meeting')}
                      <a href={meeting.meetingLink}>
                        <TextLink> {meeting.meetingLink}</TextLink>
                      </a>
                    </Text>
                  );
                }
              })}
            </Margin>
          </Fragment>
        );
      }
    }
    case LogType.REACH_CANCELLED_MEETING: {
      return (
        <Text shade={shade}>
          {log.author
            ? tt('content.cancelled_meeting', {
                author_name: log.author.fullName,
                lead_name: log.lead.fullName,
              })
            : tt('content.cancelled_meeting_no_author', {
                lead_name: log.lead.fullName,
              })}{' '}
          <Margin size={{ top: Size.EXTRA_SMALL }}>
            {log.content != null ? <Text>{log.content}</Text> : null}
          </Margin>
        </Text>
      );
    }

    default:
      if (log.content != null) {
        return <Text>{log.content}</Text>;
      }
  }
}

interface LogCardProps {
  log: Log;
  onClickEdit?: VoidFunction;
  onClickDelete?: VoidFunction;
  onClickPin: VoidFunction;
}
export const LogCard = ({ log, onClickEdit, onClickDelete, onClickPin }: LogCardProps) => {
  const iconSettings = _getIconSettingsForType(log.type);
  const content = _getContentForLog(log);

  return (
    <div className={cx(styles.logCard, { [styles.pinned]: log.pinned })}>
      <Padding size={Size.SMALL}>
        <Flex>
          <FlexItem>
            <RoundIcon color={iconSettings.color} size={Size.LARGE} name={iconSettings.icon} />
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem flex>
            <Flex>
              <FlexItem>
                <Text>{capitalize(te(`log_type.${log.type.toLowerCase()}`))}</Text>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem flex>
                {isLogWithProject(log) && log.projects.length > 0 ? (
                  <Flex justify={FlexJustify.START}>
                    {log.projects.map((project) => (
                      <FlexItem key={project.id}>
                        <Margin size={{ left: Size.EXTRA_SMALL }}>
                          <Tag style={{ background: sv.backgroundColor }}>{project.name}</Tag>
                        </Margin>
                      </FlexItem>
                    ))}
                  </Flex>
                ) : null}
              </FlexItem>
              <FlexItem>
                <CapitalFirst>
                  <Text shade={Shade.MEDIUM} dateOptions={{ showTime: ShowDateTime.NEVER }}>
                    {new Date(log.date)}
                  </Text>
                </CapitalFirst>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <Tooltip
                  content={log.pinned ? tt('unpin_log') : tt('pin_log')}
                  side={Position.BOTTOM}>
                  <div className={cx(styles.actionIcon, { [styles.active]: log.pinned })}>
                    <Icon onClick={onClickPin} name="pin" />
                  </div>
                </Tooltip>
              </FlexItem>
              {log.pinned ? (
                <FlexItem>
                  <Margin size={{ left: Size.EXTRA_SMALL }}>
                    <Tag color={Color.BLUE} inversed>
                      {tt('pinned')}
                    </Tag>
                  </Margin>
                </FlexItem>
              ) : null}
              {onClickDelete != null && onClickEdit != null ? (
                <FlexItem>
                  <Margin style={{ display: 'flex' }} size={{ left: Size.EXTRA_SMALL }}>
                    <Dropdown
                      side={Position.LEFT}
                      trigger={
                        <div className={styles.actionIcon}>
                          <Icon name="more-vertical" />
                        </div>
                      }>
                      <DropdownOption text="Edit" onClick={onClickEdit} />
                      <DropdownOption
                        text={tt('delete_log')}
                        onClick={onClickDelete}
                        category={Category.DANGER}
                      />
                    </Dropdown>
                  </Margin>
                </FlexItem>
              ) : null}
            </Flex>
          </FlexItem>
        </Flex>
        <div className={styles.content}>
          {content != null ? (
            <Margin size={{ top: Size.EXTRA_SMALL, left: Size.EXTRA_LARGE }}>{content}</Margin>
          ) : null}
        </div>
      </Padding>
    </div>
  );
};
