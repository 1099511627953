import { Text as _Text } from '@drawbotics/react-drylus';
import { Category, Size, Tier } from '@drawbotics/react-drylus';
import React, { CSSProperties, ReactNode } from 'react';

interface TextProps {
  readonly bold?: boolean;
  readonly category?: Category;
  readonly children?: ReactNode;
  readonly disabled?: boolean;
  readonly inversed?: boolean;
  readonly light?: boolean;
  readonly responsive?: any;
  readonly size?: Size;
  readonly style?: CSSProperties;
  readonly tier?: Tier;
}

export const Text = (props: TextProps) => {
  return <_Text {...(props as any)} />;
};
