import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Address } from '../../types/address';

const AddressesQuery = gql`
  query AddressesQuery {
    addresses {
      id
      countryCode
      city
      zipCode
      street
    }
  }
`;

export function useFetchAddresses(): {
  isLoading: boolean;
  error?: ErrorType;
  addresses: Array<Address>;
} {
  const { data, isLoading, error } = useMosaicQuery<{ addresses: Array<Address> }, null>({
    query: AddressesQuery,
  });

  return {
    isLoading,
    error: buildError(error),
    addresses: data?.addresses ?? [],
  };
}
