import {
  Button,
  Color,
  Content,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  ListTile,
  Margin,
  Padding,
  Size,
  Tag,
  Tier,
} from '@drawbotics/react-drylus';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';

import { MosaicPageTitle } from '~/components';
import { OrganisationRole } from '~/types';
import { createTranslate, useAuth } from '~/utils';

import { CreateAndEditMeetingModal } from '../../components';
import { getColorForMeeting, isMeetingUnactionable, useFetchMeeting } from '../../utils';
import { CancelMeetingModal, OverviewPanel, PresentationsPanel } from './components';

const tt = createTranslate('pods.marketing_suite.routes.meeting');
const ta = createTranslate('pods.marketing_suite');

export const Meeting = () => {
  const params = useParams();
  const [cancelMeetingModalOpen, setCancelMeetingModalOpen] = useState(false);
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);

  const { isLoading: isMeetingLoading, meeting } = useFetchMeeting(params.meetingId!);
  const { user } = useAuth();

  const isUserAdmin =
    user?.role === OrganisationRole.ADMIN || user?.role === OrganisationRole.AGENCY_SALES_MANAGER;
  const isUserOwner = meeting?.user.email === user?.email;

  const meetingIsUnactionable = meeting != null ? isMeetingUnactionable(meeting) : false;
  const isReadOnly = !isUserOwner && !isUserAdmin;

  return (
    <Content fullHeight fullWidth>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <Flex>
          <FlexItem flex>
            <ListTile
              title={<MosaicPageTitle>{ta('meeting_details')}</MosaicPageTitle>}
              trailing={
                meeting != null ? (
                  <Tag color={getColorForMeeting(meeting)}>{tt(meeting.status)}</Tag>
                ) : undefined
              }
            />
          </FlexItem>
          <FlexItem>
            <Button
              onClick={() => setCancelMeetingModalOpen(true)}
              disabled={meetingIsUnactionable || isReadOnly}
              tier={Tier.SECONDARY}>
              {tt('cancel_meeting')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button
              leading={<Icon name="edit" />}
              onClick={() => setIsEditMeetingModalOpen(true)}
              disabled={meetingIsUnactionable || isReadOnly}
              color={Color.BLUE}>
              {tt('edit_meeting')}
            </Button>
          </FlexItem>
        </Flex>
        <OverviewPanel isLoading={isMeetingLoading} meeting={meeting} />
        <Margin />
        <PresentationsPanel loading={isMeetingLoading} meeting={meeting} isReadOnly={isReadOnly} />
      </Padding>
      {meeting != null ? (
        <Fragment>
          <CancelMeetingModal
            meetingId={meeting.id}
            visible={cancelMeetingModalOpen}
            onClickClose={() => setCancelMeetingModalOpen(false)}
            onClickConfirm={() => setCancelMeetingModalOpen(false)}
          />
          <CreateAndEditMeetingModal
            isVisible={isEditMeetingModalOpen}
            onClose={() => setIsEditMeetingModalOpen(false)}
            meeting={meeting}
          />
        </Fragment>
      ) : null}
    </Content>
  );
};
