import urlJoin from 'url-join';
import { OperationContext } from 'urql';

export const BackofficeClient: Partial<OperationContext> = {
  url: urlJoin(process.env.BACKOFFICE_HOST, '/api/frontoffice/v1/graphql'),
};

export const MosaicClient: Partial<OperationContext> = {
  url: urlJoin(process.env.MOSAIC_HOST, '/api/v1/graphql'),
};
