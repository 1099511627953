import {
  AttachmentBox,
  Button,
  Category,
  Checkbox,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Modal,
  Select,
  Size,
  Spinner,
  Text,
  Tier,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React, { Fragment, useEffect, useState } from 'react';

import { Attachment } from '~/types';
import { UploadedFile, createTranslate, useFileUpload, useMosaicMutation } from '~/utils';

import {
  AnyWebsiteSectionData,
  HeroSectionData,
  LinkData,
  MapSectionData,
  UpdateLinkData,
  Website,
} from '../../api/domain';
import { LinkType } from '../../types';

function _isUploadedFile(attachment: UploadedFile | Attachment): attachment is UploadedFile {
  return 'signedBlobId' in attachment;
}

const tt = createTranslate('pods.website_builder.routes.website_builder');

interface LinkModalProps {
  link: LinkData;
  sections: Website['sections'];
  visible: boolean;
  onClickClose: VoidFunction;
  onChange: (options: Omit<UpdateLinkData, 'sectionId'>) => void;
}

export const LinkModal = ({ visible, onClickClose, onChange, link, sections }: LinkModalProps) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | Attachment | undefined>(
    link.attachment,
  );
  const linkForm = useForm<UpdateLinkData>({
    linkType: link.linkType,
    label: link.label ?? '',
    url: link.url ?? '',
    targetSectionId: link.targetWebsiteSection?.id,
  });
  const [errors, setErrors] = useState<
    Partial<Record<keyof Omit<UpdateLinkData, 'newTab' | 'linkType'>, boolean>>
  >({});

  const { uploadFiles, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);

  const handleUpload = async (files: FileList) => {
    if (files.length > 0) {
      const res = await uploadFiles(files);
      const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
      setUploadedFile(validFiles[0]);
      linkForm.set(validFiles[0].signedBlobId, 'signedBlobId');
    }
  };

  const handleChangeType = (v: string, n?: keyof UpdateLinkData) => {
    const { label } = linkForm.values;
    linkForm.reset();
    linkForm.set(label, 'label');
    setUploadedFile(undefined);
    linkForm.set(v, n);
  };

  const validateForm = (): boolean => {
    const { linkType, label, url, targetSectionId } = linkForm.values;
    const errors = {
      signedBlobId: linkType === LinkType.DOWNLOAD && uploadedFile == null,
      url: linkType === LinkType.URL && url == '',
      targetSectionId: linkType === LinkType.SECTION && targetSectionId == null,
      label: label == '',
    };
    setErrors(errors);
    return Object.values(errors).every((e) => e !== true);
  };

  const handleSave = () => {
    const isValid = validateForm();

    if (isValid) {
      onChange({ ...linkForm.values });
      onClickClose();
    }
  };

  useEffect(() => {
    setErrors({});
  }, [linkForm.values]);

  return (
    <Modal
      style={{ maxWidth: 550 }}
      visible={visible}
      title={tt('button_settings')}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Button onClick={handleSave} category={Category.INFO}>
              {tt('save')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <FormGroup
        label={<Label>{tt('button_label')}*</Label>}
        input={
          <Input
            error={errors.label ? tt('this_field_is_required') : undefined}
            name="label"
            value={linkForm.get}
            onChange={linkForm.set}
          />
        }
      />
      <Margin size={{ top: Size.DEFAULT }} />
      <FormGroup
        label={<Label>{tt('link_to')}</Label>}
        input={
          <Flex align={FlexAlign.START}>
            <FlexItem flex>
              <Select
                name="linkType"
                value={linkForm.get}
                onChange={handleChangeType}
                options={[
                  {
                    value: LinkType.CONTACT,
                    label: tt('contact_form2'),
                  },
                  {
                    value: LinkType.SECTION,
                    label: tt('section'),
                  },
                  {
                    value: LinkType.DOWNLOAD,
                    label: tt('download'),
                  },
                  {
                    value: LinkType.URL,
                    label: tt('external_link'),
                  },
                ]}
              />
            </FlexItem>
            <FlexItem flex>
              <Margin size={{ left: Size.SMALL }}>
                {linkForm.values.linkType === LinkType.URL ? (
                  <Input
                    placeholder={tt('type_external_link')}
                    name="url"
                    type="url"
                    error={errors.url ? tt('this_field_is_required') : undefined}
                    value={linkForm.get}
                    onChange={linkForm.set}
                  />
                ) : null}
                {linkForm.values.linkType === LinkType.SECTION ? (
                  <Select
                    name="targetSectionId"
                    error={errors.targetSectionId ? tt('this_field_is_required') : undefined}
                    value={linkForm.get}
                    onChange={linkForm.set}
                    options={Object.values(sections)
                      .filter((section) => {
                        // Filter out back and forth
                        if (Array.isArray(section)) {
                          return false;
                        }
                        const sectionWithActive = section as Exclude<
                          AnyWebsiteSectionData,
                          HeroSectionData | MapSectionData
                        >;
                        return sectionWithActive.active === true || !('active' in section);
                      })
                      .map((section) => ({
                        label: tt(`${(section as AnyWebsiteSectionData).identifier.toLowerCase()}`), // TODO translate Identifier
                        value: (section as AnyWebsiteSectionData).id,
                      }))}
                  />
                ) : null}
                {linkForm.values.linkType === LinkType.DOWNLOAD ? (
                  <Fragment>
                    <UploadHelper onUploadFiles={handleUpload}>
                      <Button
                        leading={
                          isFileUploading ? (
                            <Spinner size={Size.SMALL} inversed />
                          ) : (
                            <Icon name="upload" />
                          )
                        }
                        category={Category.INFO}>
                        {tt('upload_file')}
                      </Button>
                    </UploadHelper>
                    {errors.signedBlobId ? (
                      <Margin size={{ top: Size.EXTRA_SMALL }}>
                        <Text category={Category.DANGER} size={Size.SMALL}>
                          {tt('please_upload_file')}
                        </Text>
                      </Margin>
                    ) : null}
                  </Fragment>
                ) : null}
              </Margin>
            </FlexItem>
          </Flex>
        }
      />
      {linkForm.values.linkType === LinkType.URL ? (
        <Margin size={{ top: Size.DEFAULT }}>
          <Checkbox name="newTab" value={linkForm.get} onChange={linkForm.set}>
            {tt('open_in_new_tab')}
          </Checkbox>
        </Margin>
      ) : null}
      {linkForm.values.linkType === LinkType.DOWNLOAD && uploadedFile != null ? (
        <Margin size={{ top: Size.SMALL }}>
          <AttachmentBox
            style={{ width: '50%' }}
            fileName={
              _isUploadedFile(uploadedFile) ? uploadedFile.originalFile.name : uploadedFile.filename
            }
            onClickDownload={() => {
              if (_isUploadedFile(uploadedFile)) {
                let image = new Image();
                image.src = uploadedFile.url;
                let w = window.open('');
                w?.document.write(image.outerHTML);
              } else {
                window.open(uploadedFile.url, '_blank');
              }
            }}
          />
        </Margin>
      ) : null}
    </Modal>
  );
};
