/* eslint-disable */
import { CombinedError } from 'urql';

import { AUTH_TOKEN_CACHE_KEY } from '~/utils';

export const MIN_TOKEN_EXPIRY_GRACE_DURATION = 7 * 24 * 60 * 60; // 7 days (max validity is 14 days)
const LOG_LEVEL = process.env.APP_ENV === 'development' ? 2 : 2; // 2: debug, 1: info, 0: off

// function getLastAuthTokenExpiry() {
//   const cachedToken = getCachedAuthToken();
//   const parsedToken = parseAuthToken(cachedToken!); // We don't call this function unless we have one
//   return parsedToken['expiry'];
// }

// function getCachedAuthToken(): string | null {
//   return localStorage.getItem(AUTH_TOKEN_CACHE_KEY);
// }

function setCachedAuthToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_CACHE_KEY, authToken);
  if (LOG_LEVEL >= 2) console.log('New auth token stored.');
}

function parseAuthToken(authToken: string) {
  const decodedString = atob(authToken);
  return JSON.parse(decodedString);
}

function generateIframe(): HTMLIFrameElement {
  const frameStyle: Partial<CSSStyleDeclaration> = {
    display: 'none',
    position: 'absolute',
    top: '-999px',
    left: '-999px',
  };

  const frame = window.document.createElement('iframe');
  frame.id = 'demodesk-auth-iframe';

  for (const key in frameStyle) {
    (frame.style as Partial<CSSStyleDeclaration>)[key] = frameStyle[key];
  }

  return frame;
}

async function removeDemodeskCookies() {
  const frame = generateIframe();

  window.document.body.appendChild(frame);
  frame.src = process.env.DEMODESK_HOST + '/manage/static-user/remote-logout.html';

  return new Promise<void>((resolve) => {
    frame.onload = () => {
      resolve();

      setTimeout(() => {
        frame.remove();
      }, 200);
    };
  });
}

// function isLastAuthTokenValid() {
//   const currentTime = Math.floor(Date.now() / 1000);
//   if (LOG_LEVEL >= 2) console.log(`Current time: ${currentTime}`);
//   const tokenTime = getLastAuthTokenExpiry();
//   if (LOG_LEVEL >= 2) console.log(`Token time: ${tokenTime}`);

//   const remainingTokenDuration = tokenTime - currentTime;
//   if (LOG_LEVEL >= 2) console.log(`Remaining token duration: ${remainingTokenDuration}`);

//   return remainingTokenDuration >= MIN_TOKEN_EXPIRY_GRACE_DURATION;
// }

async function writeDemodeskCookies({ bearerToken }: { bearerToken: { [key: string]: any } }) {
  const frame = generateIframe();

  window.document.body.appendChild(frame);

  frame.src =
    process.env.DEMODESK_HOST +
    '/manage/static-user/remote-login.html?' +
    'access_token=' +
    bearerToken['access_token'] +
    '&client=' +
    bearerToken['client'] +
    '&expiry=' +
    bearerToken['expiry'] +
    '&token_type=' +
    bearerToken['token_type'] +
    '&uid=' +
    encodeURIComponent(bearerToken['uid']) +
    '&requested_at=2030-06-15T07:42:37Z' +
    '&viewer_access=true';

  console.log('iframe src', frame.src);
  return new Promise((resolve) => {
    frame.onload = resolve;
  });
}

async function writeFreshAuthToken({
  fetchAuthToken,
}: {
  fetchAuthToken: () => Promise<{ token?: string; error?: CombinedError }>;
}): Promise<{ error?: CombinedError }> {
  const { error, token } = await fetchAuthToken();
  if (error == null && token != null) {
    const bearerToken = parseAuthToken(token);
    await writeDemodeskCookies({ bearerToken });
    setCachedAuthToken(token);
  }
  return { error };
}

export async function demodeskLogin({
  fetchAuthToken,
}: {
  fetchAuthToken: () => Promise<{ token?: string; error?: CombinedError }>;
}): Promise<{ error?: CombinedError } | undefined> {
  // if (getCachedAuthToken() != null && isLastAuthTokenValid()) {
  //   if (LOG_LEVEL >= 2) console.log('Last auth token is still valid. Doing nothing.');
  // } else {
  // if (LOG_LEVEL >= 2) console.log('Last auth token is expired. Fetching fresh one.');
  console.log('Getting a new token');
  return await writeFreshAuthToken({ fetchAuthToken });
  // }
}

export function demodeskLogout() {
  removeDemodeskCookies();
}
