import {
  Button,
  Category,
  DateObject,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Size,
  Spinner,
  Tier,
  dateToObject,
  objectToDate,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import gql from 'fraql';
import React, { useState } from 'react';

import { InterestType, Lead, Log, OfferOptions } from '~/pods/crm/types';
import { UploadedFile, createTranslate } from '~/utils';
import { run } from '~/utils';
import { useMosaicMutation } from '~/utils/hooks';

import { Congratulations } from './Congratulations';
import { DocumentForm } from './DocumentForm';

const tt = createTranslate('pods.crm.routes.lead.components.sales_agreement_modal');

interface CreateOfferVariables {
  offer: {
    leadId: string;
    date?: string;
    description?: string;
    units: InterestType['unitInterests'];
    projectId: string;
    options: Array<OfferOptions>;
    signedBlobIds: Array<string>;
  };
}

interface CreateOfferPayload {
  createOffer: {
    log: Log;
  };
}

const createOfferMutation = gql`
  mutation CreateOfferMutation($offer: CreateOfferInput!) {
    createOffer(input: $offer) {
      offer {
        id
      }
    }
  }
`;

export interface OfferForm {
  date: DateObject;
  description: string;
  options: Array<OfferOptions>;
  document: UploadedFile;
  projectId: string;
  units: InterestType['unitInterests'];
}

interface SalesAgreementModalProps {
  lead: Lead;
  visible: boolean;
  onClickClose: VoidFunction;
  onCreateOffer: VoidFunction;
}

export const SalesAgreementModal = ({
  lead,
  onClickClose,
  visible,
  onCreateOffer,
}: SalesAgreementModalProps) => {
  const [isDocumentCreated, setIsDocumentCreated] = useState(false);
  const offerForm = useForm<OfferForm>({
    date: dateToObject(new Date()),
    units: [],
    options: [],
  });
  const [documents, setDocuments] = useState<Array<UploadedFile>>([]);
  const { showAlert } = useAlert();

  const {
    res: { fetching: isCreatingOffer },
    executeMutation: createOffer,
  } = useMosaicMutation<CreateOfferPayload, CreateOfferVariables>(createOfferMutation);

  const handleClickClose = () => {
    onClickClose();
    setTimeout(() => {
      offerForm.reset();
    }, 300);
  };

  const handleClickSave = async () => {
    const { projectId, units, options = [], date, description } = offerForm.values;

    if (projectId != null && units != null) {
      const { error } = await createOffer({
        offer: {
          leadId: lead.id,
          options,
          units,
          projectId,
          date: objectToDate(date!).toISOString(),
          description,
          signedBlobIds: documents.map((document) => document.signedBlobId!),
        },
      });

      if (error != null) {
        showAlert({ text: tt('create.error'), category: Category.DANGER });
      } else {
        setIsDocumentCreated(true);
      }
    }
  };

  const isFormValid =
    documents.length > 0 &&
    offerForm.values.projectId != null &&
    offerForm.values.units!.length > 0;

  return (
    <Modal
      style={{ position: 'relative' }}
      footer={
        !isDocumentCreated ? (
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button onClick={handleClickClose} tier={Tier.TERTIARY}>
                {tt('create.cancel_button')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <Button
                disabled={isCreatingOffer || !isFormValid}
                leading={isCreatingOffer ? <Spinner size={Size.SMALL} inversed /> : null}
                category={Category.INFO}
                onClick={handleClickSave}>
                {tt('create.create_button')}
              </Button>
            </FlexItem>
          </Flex>
        ) : null
      }
      title={!isDocumentCreated ? tt('title') : undefined}
      visible={visible}
      onClickClose={handleClickClose}>
      {run(() => {
        if (!isDocumentCreated) {
          return <DocumentForm setFiles={setDocuments} files={documents} form={offerForm} />;
        } else {
          return (
            <Congratulations
              onClickClose={() => {
                onCreateOffer();
                handleClickClose();
              }}
            />
          );
        }
      })}
    </Modal>
  );
};
