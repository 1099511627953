import gql from 'fraql';

export const unitFragment = gql`
  fragment _ on Unit {
    id
    reference
    availability
    price
    surface
    rooms
    bedrooms
    bathrooms
    orientation
    floorplan
    terraceSurface
    locked
    soldAt
  }
`;
