export class Position {
  private constructor(
    public readonly top: number,
    public readonly left: number,
    public readonly height: number,
    public readonly width: number,
  ) {}

  static fromSquare(top: number, left: number, width: number, height: number) {
    return new Position(top, left, height, width);
  }

  copyWith({
    top,
    left,
    width,
    height,
  }: {
    top?: number;
    left?: number;
    height?: number;
    width?: number;
  }) {
    return new Position(
      top ?? this.top,
      left ?? this.left,
      height ?? this.height,
      width ?? this.width,
    );
  }

  getTop(unit = 1) {
    return this.top * unit;
  }

  getLeft(unit = 1) {
    return this.left * unit;
  }

  getWidth(unit = 1) {
    return this.width * unit;
  }

  getHeight(unit = 1) {
    return this.height * unit;
  }
}
