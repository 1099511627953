import sv from '@drawbotics/drylus-style-vars';
import {
  Avatar,
  Button,
  ButtonLink,
  Category,
  Checkbox,
  Color,
  Dropdown,
  EmptyState,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  Padding,
  Position,
  Size,
  Spinner,
  Table,
  Text,
  TextLink,
  Tier,
  formatDate,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CapitalFirst, Thumbnail } from '~/components';
import { StatusButton } from '~/pods/meetings/components';
import { Presentation, PresentationStatus, User } from '~/pods/meetings/types';
import { useFetchLicensedUsers } from '~/pods/meetings/utils';
import { ErrorType, OrganisationRole } from '~/types';
import {
  createTranslate,
  getEmptyStateVariationFromErrorType,
  getMessageFromErrorType,
  run,
  useAuth,
  useIntercom,
  useMosaicMutation,
  useMosaicNavigation,
} from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.management.components.presentations_table');
const ta = createTranslate('pods.marketing_suite');

interface AssignUsersToPresentationVariables {
  assignedPresentation: {
    id: string;
    userIds: Array<string>;
  };
}

interface UpdatePresentationVariables {
  presentation: {
    id: string;
    status?: PresentationStatus;
  };
}

interface UpdatePresentationResult {
  updatePresentation: {
    presentation: Presentation;
  };
}

const updatePresentationMutation = gql`
  mutation UpdatePresentation($presentation: UpdatePresentationInput!) {
    updatePresentation(input: $presentation) {
      presentation {
        id
        status
      }
    }
  }
`;

const assignUsersToPresentationMutation = gql`
  mutation UpdatePresentationAssignedUsers(
    $assignedPresentation: UpdatePresentationAssignedUsersInput!
  ) {
    updatePresentationAssignedUsers(input: $assignedPresentation) {
      presentation {
        id
        status
      }
    }
  }
`;

interface AssignedUsersAvatarsProps {
  users: Array<User>;
}

const AssignedUsersAvatars = ({ users }: AssignedUsersAvatarsProps) => {
  return (
    <FlexItem>
      <Flex>
        {users.slice(0, 3).map((user, index, slicedUsers) => (
          <FlexItem
            key={user.id}
            style={{ position: 'relative', zIndex: slicedUsers.length - index }}>
            <Avatar
              style={{ marginLeft: -10, border: '1px solid white' }}
              text={user.firstName[0] + user.lastName[0]}
              hint={user.fullName}
              backgroundColor={'#B0BAC9'}
            />
          </FlexItem>
        ))}
        {users.length > 3 ? (
          <FlexItem style={{ position: 'relative', zIndex: 0 }}>
            <Text style={{ color: sv.neutralDarker, marginLeft: '4px' }}>{`+${
              users.length - 3
            }`}</Text>
          </FlexItem>
        ) : null}
      </Flex>
    </FlexItem>
  );
};

interface AssignUserToPresentationProps {
  isLoading: boolean;
  isUserMember: boolean;
  presentation: Presentation;
  licensedUsers: Array<User>;
}

const AssignUserToPresentation = ({
  isLoading,
  isUserMember,
  licensedUsers,
  presentation,
}: AssignUserToPresentationProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<Array<string>>([
    ...presentation.assignedUsers.map((user) => user.id),
  ]);
  const { res: assignUsersToPresentationResult, executeMutation: assignUsersToPresentation } =
    useMosaicMutation<UpdatePresentationResult, AssignUsersToPresentationVariables>(
      assignUsersToPresentationMutation,
    );
  const { showAlert } = useAlert();

  const toggleSelection = (userId: string) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds([...selectedUserIds.filter((id) => id !== userId)]);
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const handleAssignUsers = async () => {
    const { error } = await assignUsersToPresentation({
      assignedPresentation: {
        id: presentation.id,
        userIds: selectedUserIds,
      },
    });

    if (error != null) {
      showAlert({
        text: tt('assign_user.error'),
        category: Category.DANGER,
      });
    } else {
      showAlert({
        text: tt('assign_user.success'),
        category: Category.SUCCESS,
      });
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Dropdown
      trigger={
        <Button
          size={Size.SMALL}
          disabled={isUserMember}
          leading={<Icon name="chevron-down" />}
          tier={Tier.SECONDARY}
        />
      }
      side={Position.RIGHT}
      style={isUserMember ? { pointerEvents: 'none' } : undefined}>
      <div style={{ minWidth: 200, whiteSpace: 'nowrap' }}>
        <Padding size={{ horizontal: Size.SMALL }}>
          <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
            {licensedUsers.map((user: User) => (
              <FlexItem key={user.id}>
                <Margin size={{ vertical: Size.EXTRA_SMALL }}>
                  <Checkbox
                    onChange={() => toggleSelection(user.id)}
                    value={selectedUserIds.includes(user.id)}>
                    {user.fullName}
                  </Checkbox>
                </Margin>
              </FlexItem>
            ))}
          </Flex>
          <Margin size={{ vertical: Size.EXTRA_SMALL }}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <div onClick={() => setSelectedUserIds([])}>
                  <TextLink>{tt('clear')}</TextLink>
                </div>
              </FlexItem>
              <FlexItem>
                <Button
                  fullWidth
                  color={Color.BLUE}
                  size={Size.SMALL}
                  onClick={handleAssignUsers}
                  disabled={isUserMember}
                  trailing={
                    assignUsersToPresentationResult.fetching ? (
                      <Spinner size={Size.SMALL} inversed />
                    ) : null
                  }>
                  Save
                </Button>
              </FlexItem>
            </Flex>
          </Margin>
        </Padding>
      </div>
    </Dropdown>
  );
};

interface StatusUpdateButtonProps {
  presentation: Presentation;
  disabled: boolean;
}

const StatusUpdateButton = ({ presentation, disabled }: StatusUpdateButtonProps) => {
  const { res: updatePresentationResult, executeMutation: updatePresentation } = useMosaicMutation<
    UpdatePresentationResult,
    UpdatePresentationVariables
  >(updatePresentationMutation);

  const { showAlert } = useAlert();

  const { id, status } = presentation;

  const handleUpdatePresentation = async () => {
    if (status != null) {
      const { error } = await updatePresentation({
        presentation: {
          id,
          status:
            status === PresentationStatus.PUBLISHED
              ? PresentationStatus.UNPUBLISHED
              : PresentationStatus.PUBLISHED,
        },
      });
      if (error != null) {
        showAlert({
          text: tt('update_presentation.error'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('update_presentation.success'),
          category: Category.SUCCESS,
        });
      }
    }
  };

  return (
    <StatusButton
      title={ta(`presentation_status.${status.toLowerCase()}`)}
      isPublished={presentation.status === PresentationStatus.PUBLISHED}
      onClick={handleUpdatePresentation}
      loading={updatePresentationResult.fetching}
      disabled={disabled}
    />
  );
};

interface PresentationsTableProps {
  isLoading: boolean;
  presentations: Array<Presentation>;
  error?: ErrorType;
}

export const PresentationsTable = ({
  isLoading,
  presentations,
  error,
}: PresentationsTableProps) => {
  const { getUrlInOrg } = useMosaicNavigation();
  const showEmptyState = !isLoading && presentations.length === 0;
  const { showIntercom } = useIntercom();

  const { isLoading: isLoadingLicensedUsers, users: licensedUsers = [] } = useFetchLicensedUsers();

  const { user } = useAuth();
  const isUserMember = user?.role === OrganisationRole.MEMBER;

  const tableData = presentations.map((presentation) => ({
    id: presentation.id,
    name: (
      <Flex justify={FlexJustify.START}>
        <FlexItem>
          <Thumbnail image={presentation.coverPicture?.url} />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Text>{presentation.name}</Text>
        </FlexItem>
      </Flex>
    ),
    status:
      presentation.status !== PresentationStatus.ARCHIVED ? (
        <div style={{ width: 160 }}>
          <StatusUpdateButton presentation={presentation} disabled={isUserMember} />
        </div>
      ) : null,
    assignedTo: (
      <Flex justify={FlexJustify.START}>
        <FlexItem>
          <AssignUserToPresentation
            isLoading={isLoadingLicensedUsers}
            isUserMember={isUserMember}
            licensedUsers={licensedUsers}
            presentation={presentation}
          />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <AssignedUsersAvatars users={presentation.assignedUsers} />
        </FlexItem>
      </Flex>
    ),
    edit: (
      <Flex justify={FlexJustify.START}>
        <FlexItem style={isUserMember ? { pointerEvents: 'none' } : undefined}>
          <Link to={getUrlInOrg('/project', presentation.slug, 'presentation')}>
            <ButtonLink
              size={Size.SMALL}
              leading={<Icon name="edit-2" />}
              tier={Tier.SECONDARY}
              disabled={isUserMember}
            />
          </Link>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <a href={presentation.presentationUrl} target="_blank" rel="noopener noreferrer">
            <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY}>
              {tt('preview')}
            </ButtonLink>
          </a>
        </FlexItem>
      </Flex>
    ),
    createdAt: (
      <CapitalFirst>{formatDate({ date: new Date(presentation.createdAt) })}</CapitalFirst>
    ),
  }));

  return (
    <Table
      isLoading={isLoading}
      loadingRows={8}
      emptyContent={run(() => {
        if (error != null) {
          return (
            <EmptyState
              variation={getEmptyStateVariationFromErrorType(error)}
              description={getMessageFromErrorType(error)}
            />
          );
        } else if (showEmptyState) {
          return (
            <EmptyState title={tt('no_presentations')} description={tt('create_presentations')}>
              <Button onClick={showIntercom} category={Category.INFO}>
                {tt('contact_us')}
              </Button>
            </EmptyState>
          );
        }
      })}
      data={tableData}
      header={[
        { label: tt('name'), value: 'name' },
        { label: tt('status'), value: 'status' },
        { label: tt('assigned_to'), value: 'assignedTo' },
        { label: tt('edit'), value: 'edit' },
        { label: tt('created'), value: 'createdAt' },
      ]}
    />
  );
};
