import { Button, Category, Icon, Input, InputProps } from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import React, { useState } from 'react';

import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.booklet_panel');

export const TextToClipboard = ({ text, ...props }: Partial<InputProps> & { text: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Input
      placeholder={tt('loading')}
      value={text}
      // Should stay like this for style reasons
      onChange={() => {}}
      {...props}
      trailing={
        <Button
          onClick={() => {
            if (text != null) {
              setIsCopied(true);
              copyToClipboard(text);
            }
          }}
          category={isCopied ? Category.SUCCESS : Category.INFO}
          leading={<Icon name={isCopied ? 'check' : 'copy'} />}>
          {isCopied ? tt('copied') : tt('copy')}
        </Button>
      }
    />
  );
};
