import {
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Padding,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { isEqual, omit } from 'lodash';
import React, { useEffect, useState } from 'react';

import { EditableUnitFieldsSchema } from '~/pods/listing-manager/api/validation';
import { UnitAvailability } from '~/pods/listing-manager/types';
import { EditableUnitFields, Listing, Unit } from '~/pods/listing-manager/types';
import { ID } from '~/types';
import { createTranslate, run } from '~/utils';

import checkmark from '../../../images/checkmark.svg';
import { UnitEditor } from './UnitEditor';

const tt = createTranslate('pods.listing_manager.routes');

interface UnitAddedScreenProps {
  onClickAgain: VoidFunction;
  onClickDone: VoidFunction;
}

const UnitAddedScreen = ({ onClickAgain, onClickDone }: UnitAddedScreenProps) => {
  return (
    <Padding size={{ vertical: Size.EXTRA_HUGE }}>
      <Flex
        justify={FlexJustify.CENTER}
        align={FlexAlign.CENTER}
        direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={checkmark} />
        </FlexItem>
        <FlexItem>
          <Title size={4}>{tt('unit_added')}</Title>
        </FlexItem>
        <FlexSpacer />
        <FlexItem>
          <Text style={{ textAlign: 'center' }} shade={Shade.MEDIUM}>
            {tt('unit_added_hint')}
          </Text>
        </FlexItem>
        <FlexSpacer />
        <FlexItem>
          <Button onClick={onClickAgain} category={Category.INFO}>
            {tt('add_other_unit')}
          </Button>
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Button onClick={onClickDone} tier={Tier.SECONDARY}>
            {tt('done')}
          </Button>
        </FlexItem>
      </Flex>
    </Padding>
  );
};

const EMPTY_FORM_STATE: EditableUnitFields = {
  reference: '',
  availability: UnitAvailability.AVAILABLE,
  soldAt: undefined,
  price: '',
  surface: '',
  terraceSurface: 0,
  rooms: '',
  bedrooms: '',
  bathrooms: '',
  orientation: undefined,
  floorplan: undefined,
};

function _isFormValid(values: Partial<EditableUnitFields>): boolean {
  if (values.reference == null || values.reference == '') return false;
  if (typeof values.bedrooms !== 'number') return false;
  if (typeof values.rooms !== 'number') return false;
  if (typeof values.bathrooms !== 'number') return false;
  if (typeof values.price !== 'number') return false;
  if (typeof values.surface !== 'number') return false;
  if (typeof values.terraceSurface !== 'number') return false;
  return true;
}

interface CreateAndEditUnitModalProps {
  isVisible: boolean;
  activeUnit?: Unit;
  onClickClose: VoidFunction;
  listing: Listing;
  isProcessing: boolean;
  isAgent?: boolean;
  onUpdateUnit: (unitId: ID, values: EditableUnitFields) => Promise<Unit>;
  onCreateUnit: (values: EditableUnitFields) => Promise<Unit>;
}

export const CreateAndEditUnitModal = ({
  isVisible,
  onClickClose,
  activeUnit,
  listing,
  onUpdateUnit,
  onCreateUnit,
  isProcessing,
  isAgent = false,
}: CreateAndEditUnitModalProps) => {
  const isEditingMode = activeUnit != null;
  const [showUnitAddedScreen, setShowUnitAddedScreen] = useState(false);
  const form = useForm<EditableUnitFields>(EMPTY_FORM_STATE);

  useEffect(() => {
    if (activeUnit != null) {
      Object.entries(activeUnit).forEach(([key, value]) => {
        if (key in form.values && key !== 'floorplan') {
          form.set(value, key as keyof EditableUnitFields);
        }
      });
    }
  }, [JSON.stringify(activeUnit)]);

  useEffect(() => {
    if (!isVisible) {
      form.reset();
      setShowUnitAddedScreen(false);
    }
  }, [isVisible]);

  const _handleClose = (): void => {
    onClickClose();
    form.reset();
  };

  const onClickSave = async (): Promise<void> => {
    if (isEditingMode) {
      await onUpdateUnit(activeUnit.id, EditableUnitFieldsSchema.parse(form.values));
      _handleClose();
    } else {
      await onCreateUnit(EditableUnitFieldsSchema.parse(form.values));
      setShowUnitAddedScreen(true);
      form.reset();
    }
  };

  let saveButtonEnabled = isEditingMode
    ? !isEqual(omit(activeUnit, ['id', 'locked']), form.values) && _isFormValid(form.values)
    : _isFormValid(form.values);

  const title = run(() => {
    if (showUnitAddedScreen) {
      return tt('unit_added_short');
    } else if (isEditingMode) {
      return tt('edit_unit');
    } else {
      return tt('add_unit');
    }
  });

  return (
    <Modal
      title={title}
      footer={
        !showUnitAddedScreen ? (
          <Flex justify={FlexJustify.END} style={{ width: '100%' }}>
            <FlexItem>
              <Button onClick={_handleClose} tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <Button
                disabled={!saveButtonEnabled}
                leading={isProcessing ? <Spinner /> : null}
                onClick={onClickSave}
                category={Category.INFO}>
                {isEditingMode ? tt('save_unit') : tt('add_unit')}
              </Button>
            </FlexItem>
          </Flex>
        ) : null
      }
      visible={isVisible}
      onClickClose={_handleClose}>
      {!isEditingMode && showUnitAddedScreen ? (
        <UnitAddedScreen
          onClickAgain={() => setShowUnitAddedScreen(false)}
          onClickDone={() => {
            setShowUnitAddedScreen(false);
            _handleClose();
          }}
        />
      ) : (
        <UnitEditor
          form={form}
          listing={listing}
          floorplanUrl={activeUnit?.floorplan}
          isAgent={isAgent}
        />
      )}
    </Modal>
  );
};
