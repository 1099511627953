import gql from 'fraql';

import { Id } from '~/types';
import { useMosaicMutation } from '~/utils';

const AssignProjectMutation = gql`
  mutation AssignProject($agencyId: ID!, $projectId: ID!) {
    assignAgencyToProject(input: { agencyId: $agencyId, projectId: $projectId }) {
      agency {
        id
      }
    }
  }
`;

export function useAssignProject() {
  const {
    res: { fetching },
    executeMutation,
  } = useMosaicMutation<{}, { agencyId: Id; projectId: Id }>(AssignProjectMutation);

  return {
    assignProject: executeMutation,
    isAssignmentFetching: fetching,
  };
}
