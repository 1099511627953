import { lighten } from '@drawbotics/drylus-style-vars';

import { HexColor } from '~/types';

import { Theme, ThemeDefinition } from '../../types';
import { luxurious, minimalist, traditional } from './index';

function _mergeColors(
  colors: ThemeDefinition['colors'],
  primary?: HexColor,
  secondary?: HexColor,
): ThemeDefinition['colors'] {
  return {
    ...colors,
    primary: primary ?? colors.primary,
    primaryLight:
      colors.primaryLight != null && primary != null ? lighten(primary, 20) : colors.primaryLight,
    secondary: secondary ?? colors.secondary,
  };
}

export function getThemeValues(
  theme: Theme,
  { primaryColor, secondaryColor }: { primaryColor?: HexColor; secondaryColor?: HexColor },
): ThemeDefinition {
  if (theme === Theme.TRADITIONAL) {
    return {
      ...traditional,
      colors: _mergeColors(traditional.colors, primaryColor, secondaryColor),
    };
  } else if (theme === Theme.LUXURIOUS) {
    return { ...luxurious, colors: _mergeColors(luxurious.colors, primaryColor, secondaryColor) };
  } else {
    return { ...minimalist, colors: _mergeColors(minimalist.colors, primaryColor, secondaryColor) };
  }
}
