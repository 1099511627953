import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Content,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Label,
  Margin,
  Padding,
  Page,
  Panel,
  PanelBody,
  Paragraph,
  Shade,
  Size,
  Spinner,
  Text,
  Title,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router';

import { createTranslate, run } from '~/utils';

import { CostsCalculator } from '../components';
import { useFetchClientData } from '../utils';

const tt = createTranslate('pods.fiscal_simulator.routes.fiscal_simulator_client');

const styles = {
  roundImage: css`
    width: 160px;
    height: 160px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 45px;
    background: white;
    padding: ${sv.paddingSmall};
    border-radius: 1000px;
    overflow: hidden;

    img {
      border-radius: 1000px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
};

export const FiscalSimulatorClient = () => {
  const { token } = useParams();
  const { data, isLoading, error } = useFetchClientData(token!);

  useLayoutEffect(() => {
    document.title = tt('fiscal_simulator');
  }, []);

  return (
    <Page style={{ display: 'flex', flex: 1, height: 'auto' }}>
      <Content fullHeight fullWidth>
        {run(() => {
          if (isLoading) {
            return <Spinner fullSize />;
          } else if (data == null || error != null) {
            return (
              <EmptyState
                title={tt('something_went_wrong')}
                variation={EmptyStateVariation.FAILED}
              />
            );
          } else {
            return (
              <Padding
                size={{
                  horizontal: Size.EXTRA_LARGE,
                  vertical: data.coverImage == null ? Size.HUGE : Size.MASSIVE,
                }}
                responsive={{ L: { size: Size.DEFAULT } }}>
                <Flex>
                  <FlexItem>
                    <Panel
                      body={
                        <PanelBody noPadding>
                          {data.coverImage != null ? (
                            <div className={styles.roundImage}>
                              <img src="https://baloom.co/wp-content/uploads/2013/03/render_hq.jpg" />
                            </div>
                          ) : null}
                          <Padding
                            size={{
                              top: data.coverImage == null ? Size.EXTRA_LARGE : Size.EXTRA_HUGE,
                              left: Size.MASSIVE,
                              right: Size.MASSIVE,
                              bottom: Size.EXTRA_LARGE,
                            }}
                            responsive={{ L: { size: Size.DEFAULT } }}>
                            <Flex direction={FlexDirection.VERTICAL}>
                              <FlexItem>
                                <img
                                  style={{ maxWidth: 170, maxHeight: 100 }}
                                  src={data.organisation.logo}
                                />
                              </FlexItem>
                              <FlexSpacer />
                              <FlexItem>
                                <Label>
                                  {tt('want_to_invest', { projectName: data.projectName })}
                                </Label>
                              </FlexItem>
                              <FlexSpacer size={Size.EXTRA_SMALL} />
                              <FlexItem>
                                <Title noMargin size={3}>
                                  {tt('estimate_costs')}
                                </Title>
                              </FlexItem>
                              <FlexSpacer />
                              <FlexItem responsive={{ L: { style: { width: '100%' } } }}>
                                <CostsCalculator
                                  color={data.organisation.primaryColor}
                                  currency={data.organisation.currency}
                                  taxes={data.taxes}
                                  additionalFees={data.additionalFees}
                                />
                              </FlexItem>
                              {data.costSimulatorDisclaimer != null ? (
                                <FlexItem>
                                  <Margin size={{ top: Size.DEFAULT }}>
                                    <Paragraph align={Align.CENTER}>
                                      <Text size={Size.SMALL} shade={Shade.MEDIUM}>
                                        *{data.costSimulatorDisclaimer}
                                      </Text>
                                    </Paragraph>
                                  </Margin>
                                </FlexItem>
                              ) : null}
                            </Flex>
                          </Padding>
                        </PanelBody>
                      }
                    />
                  </FlexItem>
                </Flex>
              </Padding>
            );
          }
        })}
      </Content>
    </Page>
  );
};
