import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { Agencies } from './Agencies';
import { BasicInfo } from './BasicInfo';
import { Branding } from './Branding';
import { CompanyTeam } from './CompanyTeam';

export const SettingsRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route path="/branding" element={<Branding />} />
      <Route path="/company-team" element={<CompanyTeam />} />
      <Route path="/basic-info" element={<BasicInfo />} />
      <Route path="/agencies" element={<Agencies />} />
    </RoutesWithNotFound>
  );
};
