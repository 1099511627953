import { ze } from '@tokamakjs/common';
import gql from 'fraql';
import { pick } from 'lodash';
import { useState } from 'react';
import { z } from 'zod';

import { ID } from '~/types';
import { useMosaicMutation } from '~/utils';

import { AdvertData } from '../domain';

const UpdateAdvertMutation = gql`
  mutation UpdateAdvertMutation($advert: UpdateAdvertInput!) {
    updateAdvert(input: $advert) {
      advert {
        id
        images {
          alt
          contentType
          filename
          id
          position
          url
        }
        documents {
          alt
          contentType
          filename
          id
          position
          url
        }
      }
    }
  }
`;

interface AttachmentInput {
  alt?: string;
  attachmentId?: ID;
  destroy?: boolean;
  position?: number;
  signedBlobId?: string;
}

interface AdvertInput
  extends Omit<
    AdvertData,
    'documents' | 'images' | 'id' | 'qualityCertificates' | 'eligibilityCertificates' | 'advantages'
  > {
  documents?: Array<AttachmentInput>;
  images?: Array<AttachmentInput>;
  qualityCertificates?: Array<string>;
  eligibilityCertificates?: Array<string>;
  advantages?: Array<string>;
}

const UpdateAdvertMutationDataSchema = z.object({
  updateAdvert: z.object({
    advert: z.object({
      id: ze.id(),
      documents: ze.defaults(
        z.array(
          z.object({
            alt: ze.optional(z.string()),
            contentType: ze.optional(z.string()),
            filename: z
              .string()
              .optional()
              .nullable()
              .transform((v?: string | null) => v ?? ''),
            id: ze.id(),
            position: ze.optional(z.number()),
            url: ze.defaults(z.string(), ''),
          }),
        ),
        [],
      ),
      images: ze.defaults(
        z.array(
          z.object({
            alt: ze.optional(z.string()),
            contentType: ze.optional(z.string()),
            filename: z
              .string()
              .optional()
              .nullable()
              .transform((v?: string | null) => v ?? ''),
            id: ze.id(),
            position: ze.optional(z.number()),
            url: ze.defaults(z.string(), ''),
          }),
        ),
        [],
      ),
    }),
  }),
});

type UpdateAdvertMutationData = z.infer<typeof UpdateAdvertMutationDataSchema>;

interface UseUpdateAdvertReturnType {
  isUpdatingAdvert: boolean;
  updateAdvert: (
    projectPublicToken: string,
    data: Partial<AdvertInput>,
  ) => Promise<UpdateAdvertMutationData>;
}

export function useUpdateAdvert(): UseUpdateAdvertReturnType {
  const [isLoading, setIsLoading] = useState(false);
  const { executeMutation } = useMosaicMutation(UpdateAdvertMutation);

  const updateAdvert = async (
    projectPublicToken: string,
    data: Partial<AdvertInput>,
  ): Promise<UpdateAdvertMutationData> => {
    const advert: AdvertInput = {
      ...data,
      projectPublicToken,
      documents: data.documents?.map((d) => {
        const data = pick({ ...d, attachmentId: d.attachmentId ?? (d as any).id }, [
          'alt',
          'attachmentId',
          'destroy',
          'position',
          'signedBlobId',
        ]);

        if (data.destroy) {
          delete data.signedBlobId;
        }

        return data;
      }),
      images: data.images?.map((d, i) => {
        const data = pick({ ...d, attachmentId: d.attachmentId ?? (d as any).id }, [
          'alt',
          'attachmentId',
          'destroy',
          'position',
          'signedBlobId',
        ]);

        if (data.destroy) {
          delete data.signedBlobId;
        }

        return { ...data, position: i + 1 };
      }),
      qualityCertificates: data.qualityCertificates,
      eligibilityCertificates: data.eligibilityCertificates,
      advantages: data.advantages,
    };

    delete (advert as any).id;

    setIsLoading(true);
    const res = await executeMutation({ advert });
    setIsLoading(false);

    if (res.error != null) {
      throw res.error;
    }

    return UpdateAdvertMutationDataSchema.parse(res.data);
  };

  return { isUpdatingAdvert: isLoading, updateAdvert };
}
