import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import { range } from 'lodash';
import React from 'react';

import { CHART_THEME } from './MeetingsOverTimeTile';

export const ChartPlaceholder = () => {
  const yValues = range(0, 10);
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ResponsiveLine
        data={[
          {
            id: 'line',
            data: range(0, 6).map((month) => ({
              y: 10,
              x: dayjs()
                .set('month', month as number)
                .locale(window.i18n.locale)
                .format('MMM'),
            })),
          },
        ]}
        markers={[
          {
            axis: 'x',
            value: 'Jan',
          },
          {
            axis: 'y',
            value: 0,
          },
        ]}
        axisLeft={{
          tickSize: 0,
          tickValues: yValues.length,
          // @ts-ignore
          format: (e) => (e === 0 ? undefined : e),
        }}
        axisBottom={{
          tickSize: 0,
        }}
        enableGridX={false}
        gridYValues={yValues}
        lineWidth={0}
        enablePoints={false}
        theme={CHART_THEME}
        margin={{
          top: 24,
          bottom: 24,
          left: 24,
          right: 24,
        }}
      />
    </div>
  );
};
