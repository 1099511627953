import { Category, Grid, GridItem, Size, useAlert } from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { useState } from 'react';

import { AssetTile } from '~/components';
import { OrganisationOverview } from '~/pods/meetings/types';
import { Asset, AttachmentAsset, UrlAsset } from '~/types';
import { UploadedFile, createTranslate, useMosaicMutation } from '~/utils';

import { HideShowControl } from './HideShowControl';

const tt = createTranslate('pods.marketing_suite.routes.company_overview');

interface UpdateAssetsVariables {
  assets?: Array<{
    assetId?: string;
    signedBlobId?: string;
    url?: string;
    position?: number;
  }>;
  showAssets?: boolean;
}

interface UpdateAssetsResult {
  updateOverview: {
    overview: OrganisationOverview;
  };
}

const updateAssetsMutation = gql`
  mutation updateAssets($assets: [AssetInput!], $showAssets: Boolean) {
    updateOverview(input: { assets: $assets, showAssets: $showAssets }) {
      overview {
        showAssets
        assets {
          id
          url
          position
          ... on AttachmentAsset {
            filename
          }
        }
      }
    }
  }
`;

interface GalleryProps {
  assets: Array<AttachmentAsset | UrlAsset>;
  showAssets: boolean;
  readOnly: boolean;
}

export const Gallery = ({ assets, showAssets, readOnly }: GalleryProps) => {
  const [assetsVisible, setAssetsVisible] = useState(showAssets);
  const { showAlert } = useAlert();

  const { res: updateAssetsResult, executeMutation: updateAssets } = useMosaicMutation<
    UpdateAssetsResult,
    UpdateAssetsVariables
  >(updateAssetsMutation);

  const handleUpdateAssetsVisible = async () => {
    setAssetsVisible(!assetsVisible);
    const res = await updateAssets({
      showAssets: !assetsVisible,
    });
    if (res.error != null) {
      showAlert({ text: tt('could_not_update_asset'), category: Category.DANGER });
      setAssetsVisible(assetsVisible);
    }
  };

  const handleFinishUpload = async (
    uploadedFile: UploadedFile,
    position: number,
    asset?: Asset,
  ) => {
    const res = await updateAssets({
      assets: [{ signedBlobId: uploadedFile.signedBlobId, assetId: asset?.id, position }],
    });
    if (res.error != null) {
      showAlert({ text: tt('could_not_save_image'), category: Category.DANGER });
    }
    return res.error == null;
  };

  const handleSaveUrl = async (url: string, position: number, asset?: Asset) => {
    const res = await updateAssets({
      assets: [{ url, assetId: asset?.id, position }],
    });
    if (res.error != null) {
      showAlert({ text: tt('could_not_save_url'), category: Category.DANGER });
    }
    return res.error == null;
  };

  const leftAsset = assets.find((a) => a.position === 1);
  const rightAsset = assets.find((a) => a.position === 2);

  return (
    <div style={{ position: 'relative' }}>
      {readOnly ? null : (
        <HideShowControl
          loading={updateAssetsResult.fetching}
          visible={assetsVisible && !readOnly}
          onClick={handleUpdateAssetsVisible}
        />
      )}
      <div style={{ opacity: assetsVisible ? 1 : 0.4 }}>
        <Grid
          columns={2}
          hGutters={Size.DEFAULT}
          vGutters={Size.DEFAULT}
          style={{ position: 'relative' }}>
          <GridItem style={{ height: 300 }}>
            <AssetTile
              asset={leftAsset}
              readOnly={readOnly}
              onSaveUrl={(url) => handleSaveUrl(url, 1, leftAsset)}
              onFinishUpload={(attachment) => handleFinishUpload(attachment, 1, leftAsset)}
            />
          </GridItem>
          <GridItem style={{ height: 300 }}>
            <AssetTile
              asset={rightAsset}
              readOnly={readOnly}
              onSaveUrl={(url) => handleSaveUrl(url, 2, rightAsset)}
              onFinishUpload={(attachment) => handleFinishUpload(attachment, 2, rightAsset)}
            />
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};
