import { Subscription, User } from '~/types';

let pendoIsInitialized = false;

export function identifyUserInPendo(user: User | undefined) {
  if (window.pendo != null && user != null) {
    const pendoIdentity: pendo.Identity = {
      visitor: {
        id: user?.id,
        email: user?.email,
        full_name: `${user.firstName} ${user.lastName}`,
        role: user.role,
      },
      account: {
        id: user.organisation?.id ?? 'orgless',
        name: user.organisation?.name ?? 'orgless',
        is_paying: user.organisation?.subscription
          ? [Subscription.GROWTH, Subscription.ESSENTIALS].includes(user.organisation?.subscription)
          : false,
        planLevel: user.organisation?.subscription as string,
      },
    };

    if (pendoIsInitialized) {
      window.pendo.updateOptions(pendoIdentity);
    } else {
      window.pendo.initialize(pendoIdentity);
      pendoIsInitialized = true;
    }
  }
}
