import {
  Button,
  Dropdown,
  DropdownOption,
  EmptyState,
  Flex,
  FlexItem,
  Icon,
  Panel,
  PanelBody,
  PanelHeader,
  SelectFilter,
  ShowDateTime,
  Table,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { useFilters } from '@drawbotics/use-filters';
import { useLocation, useNavigate } from '@tokamakjs/react';
import React from 'react';

import { createTranslate } from '~/utils';

import { Diffusion } from '../domain';
import { DiffusionStatus } from '../types';
import { DiffusionLeadsCount } from './DiffusionLeadsCount';
import { StatusTag } from './StatusTag';

const tt = createTranslate('pods.multidiffusion.components.diffusion_panel');

interface DiffusionPanelProps {
  diffusions: Array<Diffusion>;
  onClickEdit: (diffusion: Diffusion) => void;
  onClickUnpublish: (diffusion: Diffusion) => void;
  projectId: string;
}

export const DiffusionPanel = ({
  diffusions,
  onClickEdit,
  onClickUnpublish,
  projectId,
}: DiffusionPanelProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const _filters = useFilters<'status' | 'sortBy'>(location, navigate, ['status', 'sortBy']);
  const filters = {
    status: {
      ..._filters.status,
      value: _filters.status.value as DiffusionStatus,
    },
    sortBy: {
      ..._filters.sortBy,
      value: _filters.sortBy.values as ['portal' | 'startingDate' | 'endingDate', 'asc' | 'desc'],
    },
  };

  const tableData = diffusions
    .filter((d) => {
      if (filters.status.value == null) {
        return true;
      }

      return filters.status.value === d.status;
    })
    .sort((a, b) => {
      if (filters.sortBy.value == null) return 0;

      [a, b] = filters.sortBy.value[1] === 'asc' ? [b, a] : [a, b];

      if (filters.sortBy.value[0] === 'portal') {
        return a.portal.name.localeCompare(b.portal.name, undefined, { numeric: true });
      }

      return a[filters.sortBy.value[0]]?.localeCompare(b[filters.sortBy.value[0]], undefined, {
        numeric: true,
      });
    })
    .map((d) => ({
      key: d.id,
      portal: d.portal.name,
      startingDate: (
        <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>{new Date(d.startingDate)}</Text>
      ),
      endingDate: (
        <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>{new Date(d.endingDate)}</Text>
      ),
      leads: <DiffusionLeadsCount projectId={projectId} portalSlug={d.portal.slug} />,
      status: <StatusTag status={d.status} />,
      actions: (
        <Dropdown trigger={<Button tier={Tier.TERTIARY} leading={<Icon name="more-vertical" />} />}>
          <DropdownOption text={tt('actions.edit')} onClick={() => onClickEdit(d)} />
          <DropdownOption text={tt('actions.unpublish')} onClick={() => onClickUnpublish(d)} />
        </Dropdown>
      ),
    }));

  return (
    <Panel
      header={
        <PanelHeader>
          <Flex>
            <FlexItem flex>
              <Title size={4} noMargin>
                {tt('title')}
              </Title>
            </FlexItem>
            <FlexItem>
              <SelectFilter
                label={tt('status.label')}
                value={filters.status.value}
                options={[
                  { label: tt('status.pending_publication'), value: DiffusionStatus.PENDING },
                  { label: tt('status.published'), value: DiffusionStatus.PUBLISHED },
                  { label: tt('status.unpublished'), value: DiffusionStatus.UNPUBLISHED },
                ]}
                onChange={(v) => filters.status.set(v)}
                onClear={() => filters.status.set(null)}
              />
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody noPadding>
          <Table
            header={[
              { label: tt('table.portal'), value: 'portal' },
              { label: tt('table.starting_date'), value: 'startingDate' },
              { label: tt('table.ending_date'), value: 'endingDate' },
              { label: tt('table.leads'), value: 'leads' },
              { label: tt('table.status'), value: 'status' },
              { label: '', value: 'actions' },
            ]}
            activeHeader={
              filters.sortBy.value != null
                ? {
                    key: filters.sortBy.value[0],
                    direction: filters.sortBy.value[1],
                  }
                : undefined
            }
            sortableBy={['portal', 'startingDate', 'endingDate']}
            onClickHeader={(v) =>
              filters.sortBy.set([
                v as 'portal' | 'startingDate' | 'endingDate',
                filters.sortBy.value?.[0] === v
                  ? filters.sortBy.value[1] === 'asc' // flip direction
                    ? 'desc'
                    : 'asc'
                  : 'asc',
              ])
            }
            data={tableData}
            emptyContent={
              tableData.length === 0 ? <EmptyState description={tt('empty.description')} /> : null
            }
          />
        </PanelBody>
      }
    />
  );
};
