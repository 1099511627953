import dayjs from 'dayjs';
import produce from 'immer';

import { DateRange, InsightsEstate } from '../../types';
import { filterByRange } from '../get-data-in-range';

function _getEmptyMonths(range: DateRange): { [key: string]: InsightsEstate['units'] } {
  return new Array(12).fill(0).reduce((memo, _, i) => {
    return {
      ...memo,
      [range.start.add(i, 'month').format('YYYY-MM')]: [],
    };
  }, {});
}

function _getRevenueByTypology(units: InsightsEstate['units']): { [key: string]: number } {
  return units.reduce((memo, u) => {
    return produce(memo, (draft) => {
      draft[u.typology] =
        draft[u.typology] != null ? draft[u.typology] + u.price.value : u.price.value;
    });
  }, {} as { [key: string]: number });
}

function _extractKeys(data: Array<{ [key: string]: number | string }>): Array<string> {
  const keys = new Set<string>();

  for (let month of data) {
    Object.keys(month)
      .filter((k) => k != 'month')
      .forEach((v) => keys.add(v));
  }

  return [...keys];
}

export function useLoadRevenuePerType(
  units: InsightsEstate['units'],
  range: DateRange,
): { data: Array<Record<string, number | string>>; dataKeys: Array<string> } {
  const yearRange = {
    start: range.end.subtract(11, 'month').startOf('month'),
    end: range.end.endOf('month'),
  };

  const emptyMonths = _getEmptyMonths(yearRange);
  const unitsInRange = filterByRange(yearRange, units, 'soldAt');

  const unitsSoldByMonth = unitsInRange.reduce((memo, u) => {
    return produce(memo, (draft) => {
      if (u.soldAt == null) return;
      const month = u.soldAt.format('YYYY-MM');
      draft[month] = draft[month] != null ? [...draft[month], u] : [u];
    });
  }, emptyMonths);

  const data: Array<Record<string, number | string>> = Object.entries(unitsSoldByMonth)
    .sort((e1, e2) => e1[0].localeCompare(e2[0], undefined, { numeric: true }))
    .map(([date, units]) => {
      return {
        ..._getRevenueByTypology(units),
        month: dayjs(date, 'YYYY-MM').format('MMM'),
      };
    });

  return { data, dataKeys: _extractKeys(data) };
}
