import { HookService, onDidMount, state } from '@tokamakjs/react';
import { Subscription } from 'rxjs';

import { ID } from '~/types';
import { useTriggerNotFound } from '~/utils';

import {
  AnyWebsiteSectionData,
  AssetData,
  Project,
  UpdateLinkData,
  Website,
} from '../../api/domain';
import { ProjectService, RouterService, WebsiteService } from '../../services';

@HookService()
export class EditController {
  private readonly _sub = new Subscription();
  private readonly _triggerNotFound = useTriggerNotFound();

  @state private _isLoading = true;
  @state private _isSaving = false;
  @state private _website?: Website;
  @state private _project?: Project;

  get isLoading() {
    return this._isLoading;
  }

  get isSaving() {
    return this._isSaving;
  }

  get website() {
    return this._website;
  }

  get project() {
    return this._project;
  }

  constructor(
    private readonly _router: RouterService,
    private readonly _projectService: ProjectService,
    private readonly _websiteService: WebsiteService,
  ) {}

  @onDidMount()
  protected async loadWebsite(): Promise<void> {
    this._isLoading = true;

    const slug = this._router.getParam('projectSlug');

    if (slug == null) {
      this._triggerNotFound();
      return;
    }

    const p = await this._projectService.loadProject(slug);

    if (p == null || !p.isWebsiteEnabled) {
      this._triggerNotFound();
      return;
    }

    await this._websiteService.loadWebsite(p.id);

    this._isLoading = false;
  }

  @onDidMount()
  protected async subscribeToServices(): Promise<VoidFunction> {
    this._sub.add(this._projectService.project$.subscribe((v) => (this._project = v)));
    this._sub.add(this._websiteService.website$.subscribe((v) => (this._website = v)));

    return () => {
      this._sub.unsubscribe();
    };
  }

  public async updateSection(id: string, data: Partial<AnyWebsiteSectionData>): Promise<void> {
    this._isSaving = true;
    await this._websiteService.updateSection(id, data);
    this._isSaving = false;
  }

  public async updateSectionAssets(sectionId: string, data: Array<AssetData>): Promise<void> {
    this._isSaving = true;
    await this._websiteService.updateSectionAssets(sectionId, data);
    this._isSaving = false;
  }

  public async incrementSection(id: ID): Promise<void> {
    this._isSaving = true;
    await this._websiteService.incrementSection(id);
    this._isSaving = false;
  }

  public async decrementSection(id: ID): Promise<void> {
    this._isSaving = true;
    await this._websiteService.decrementSection(id);
    this._isSaving = false;
  }

  public async updateLink(id: string, data: UpdateLinkData): Promise<void> {
    this._isSaving = true;
    await this._websiteService.updateLink(id, data);
    this._isSaving = false;
  }

  public async updateWebsiteLogo(signedBlobId: string): Promise<void> {
    if (this.website == null) return;
    this._isSaving = true;
    await this._websiteService.updateWebsiteLogo(this.website.id, signedBlobId);
    this._isSaving = false;
  }

  public async updateWebsite(data: Partial<Website>): Promise<void> {
    if (this.website == null) return;
    this._isSaving = true;
    await this._websiteService.updateWebsite(this.website.id, data);
    this._isSaving = false;
  }
}
