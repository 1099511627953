import sv from '@drawbotics/drylus-style-vars';
import { Margin, Separator, Size } from '@drawbotics/react-drylus';
import React from 'react';

export const SpacedSeparator = () => {
  return (
    <Margin size={{ vertical: Size.LARGE }}>
      <Separator style={{ background: sv.neutral }} />
    </Margin>
  );
};
