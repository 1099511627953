import { AttachmentAsset, UrlAsset } from '~/types';

export interface Figure {
  id: string;
  value: string;
  superscript?: string;
  subtext: string;
}

export enum OrganisationOverviewStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
}

export interface OrganisationOverview {
  id: string;
  status: OrganisationOverviewStatus;
  coverAsset?: AttachmentAsset | UrlAsset;
  mainAsset?: AttachmentAsset;
  updatedAt?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  showFigures: boolean;
  showAssets: boolean;
  assets: Array<AttachmentAsset | UrlAsset>;
  figuresTitle?: string;
  figures: Array<Figure>;
}
