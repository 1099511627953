import gql from 'fraql';

import { Id } from '~/types';
import { useMosaicMutation } from '~/utils';

interface UpdateOrganisationMutationVariables {
  input: {
    id: Id,
    leadNotificationEmails: string[]
  }
}

interface UpdateOrganisationMutationData { }

const UpdateOrganisationMutation = gql`
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      organisation {
        leadNotificationEmails
      }
    }
  }
`;

export function useUpdateOrganisation() {
  const {
    res: { fetching },
    executeMutation,
  } = useMosaicMutation<UpdateOrganisationMutationData, UpdateOrganisationMutationVariables>(UpdateOrganisationMutation);

  return {
    updateOrganisation: executeMutation,
    isUpdatingOrganisation: fetching,
  };
}
