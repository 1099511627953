import gql from 'fraql';
import { OperationResult } from 'urql';

import { ID } from '~/types';
import { useMosaicMutation } from '~/utils';

import { CustomDomain } from '../types';

interface CreateCustomDomainPayload {
  customDomain: CustomDomain;
}

interface CreateCustomDomainVariables {
  input: {
    websiteId: ID;
    hostname: string;
  };
}

interface DeleteCustomDomainPayload {
  customDomain: CustomDomain;
}

interface DeleteCustomDomainVariables {
  input: {
    websiteId: ID;
  };
}

const createCustomDomainMutation = gql`
  mutation createCustomDomain($input: CreateCustomDomainInput!) {
    createCustomDomain(input: $input) {
      customDomain {
        cname
        hostname
        status
        id
      }
    }
  }
`;

const deleteCustomDomainMutation = gql`
  mutation DeleteCustomDomain($input: DeleteCustomDomainInput!) {
    deleteCustomDomain(input: $input) {
      customDomain {
        cname
        hostname
        status
      }
    }
  }
`;

// TODO: Move to its own service
export function useCreateCustomDomain(websiteId: ID): {
  isLoading: boolean;
  createCustomDomain: (hostname: string) => Promise<OperationResult>;
} {
  const { res, executeMutation: createCustomDomain } = useMosaicMutation<
    CreateCustomDomainPayload,
    CreateCustomDomainVariables
  >(createCustomDomainMutation);

  return {
    isLoading: res.fetching,
    createCustomDomain: (hostname) => createCustomDomain({ input: { websiteId, hostname } }),
  };
}

export function useDeleteCustomDomain(websiteId: ID): {
  isLoading: boolean;
  deleteCustomDomain: () => Promise<OperationResult>;
} {
  const { res, executeMutation: deleteCustomDomain } = useMosaicMutation<
    DeleteCustomDomainPayload,
    DeleteCustomDomainVariables
  >(deleteCustomDomainMutation);
  return {
    isLoading: res.fetching,
    deleteCustomDomain: () => deleteCustomDomain({ input: { websiteId } }),
  };
}
