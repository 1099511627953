import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { MinimalSection, Section } from '../../types';

interface PresentationQueryData {
  presentation: {
    sections: Array<Section>;
  };
}

interface PresentationQueryVariables {
  projectId: string;
}

const PresentationQuery = gql`
  query PresentationQuery($projectId: ID) {
    presentation(projectId: $projectId) {
      sections {
        id
        name
        slug
        position
        hidden
      }
    }
  }
`;

export function useFetchSections(projectId: string): {
  isLoading: boolean;
  sections?: Array<MinimalSection>;
  error?: ErrorType;
  refetch: VoidFunction;
} {
  const res = useMosaicQuery<PresentationQueryData, PresentationQueryVariables>({
    query: PresentationQuery,
    variables: { projectId },
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    sections: res.data?.presentation.sections,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
