import { injectGlobal } from 'emotion';

import { Theme, ThemeDefinition } from '../../types';

injectGlobal`
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Source+Sans+Pro:wght@300;400;600&display=swap');
`;

const fonts = {
  primary: 'Playfair Display',
  secondary: 'Source Sans Pro',
  extraLarge: '29px',
  large: '26px',
  small: '15px',
  extraSmall: '11px',
  extraLargeSpacing: '40px',
  largeSpacing: '34px',
  smallSpacing: '28px',
  extraSmallSpacing: '24px',
};

const colors = {
  primary: '#2F4E73',
  secondary: '#CAAE6F',
  neutral1: '#000000',
  neutral2: '#212121',
  neutral3: '#F2F2F2',
  neutral4: '#FFFFFF',
  neutral5: '#4F4F4F',
};

export const luxurious: ThemeDefinition = { id: Theme.LUXURIOUS, colors, fonts };
