import {
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  Margin,
  Panel,
  PanelBody,
  PanelHeader,
  Separator,
  Size,
  Spinner,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { Fragment, useEffect } from 'react';

import { Meeting } from '~/pods/meetings/types';
import { createTranslate, run, useMosaicMutation } from '~/utils';

import { DisplayOptions, Options as DisplayOptionsType } from './DisplayOptions';
import { PresentationRow } from './PresentationRow';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.presentations_panel');

interface UpdateMeetingVariables {
  meeting: {
    id: string;
    presentationIds?: Array<string>;
    displayListingRooms?: boolean;
    displayListingBedrooms?: boolean;
    displayListingPlans?: boolean;
    displayListingPrice?: boolean;
    displayListingStatus?: boolean;
    displayListingSurface?: boolean;
    allowListingPlansDownload?: boolean;
    hideSoldUnits?: boolean;
  };
}

interface UpdateMeetingResult {
  editMeeting: {
    meeting: Meeting;
  };
}

const updateMeetingMutation = gql`
  mutation updateMeeting($meeting: UpdateMeetingInput!) {
    updateMeeting(input: $meeting) {
      meeting {
        id
        displayListingPlans
        displayListingPrice
        displayListingStatus
        displayListingSurface
        displayListingRooms
        displayListingBedrooms
        allowListingPlansDownload
        hideSoldUnits
        presentations {
          id
        }
      }
    }
  }
`;

interface UpdateMeetingPresentationListingVariables {
  meetingId: string;
  presentationId: string;
  showListing: boolean;
}

interface UpdateMeetingPresentationListingResult {
  updateMeetingPresentationListing: {
    meeting: Meeting;
  };
}

const updateMeetingPresentationListingMutation = gql`
  mutation updateMeetingPresentationListing(
    $meetingId: ID!
    $presentationId: ID!
    $showListing: Boolean!
  ) {
    updateMeetingPresentationListing(
      input: { meetingId: $meetingId, presentationId: $presentationId, showListing: $showListing }
    ) {
      meeting {
        id
        presentations {
          id
          listingDisplay(meetingId: $meetingId)
        }
      }
    }
  }
`;

const RowSeparator = () => {
  return (
    <Margin size={{ vertical: Size.SMALL }}>
      <Separator />
    </Margin>
  );
};

interface PresentationPanelProps {
  meeting?: Meeting;
  loading: boolean;
  isReadOnly: boolean;
}

export const PresentationsPanel = ({ meeting, loading, isReadOnly }: PresentationPanelProps) => {
  const { showAlert } = useAlert();

  const { executeMutation: updateMeeting, res: updateMeetingResult } = useMosaicMutation<
    UpdateMeetingResult,
    UpdateMeetingVariables
  >(updateMeetingMutation);
  const { executeMutation: updateMeetingPresentationListing } = useMosaicMutation<
    UpdateMeetingPresentationListingResult,
    UpdateMeetingPresentationListingVariables
  >(updateMeetingPresentationListingMutation);

  const meetingPresentations = meeting?.presentations ?? [];

  const handleModifySettings = (options: DisplayOptionsType) => {
    updateMeeting({
      meeting: {
        id: meeting!.id,
        ...options,
      },
    });
  };

  const handleToggleListing = async (id: string, value: boolean) => {
    if (meeting != null) {
      const { error } = await updateMeetingPresentationListing({
        meetingId: meeting.id,
        presentationId: id,
        showListing: value,
      });
      if (error != null) {
        showAlert({
          text: tt('something_went_wrong'),
          category: Category.DANGER,
        });
      } else {
        showAlert({
          text: tt('update_success'),
          category: Category.SUCCESS,
        });
      }
    }
  };

  useEffect(() => {
    if (updateMeetingResult.error != null) {
      showAlert({
        text: tt('something_went_wrong'),
        category: Category.DANGER,
      });
    } else if (updateMeetingResult.data != null) {
      showAlert({
        text: tt('update_success'),
        category: Category.SUCCESS,
      });
    }
  }, [updateMeetingResult.error, updateMeetingResult.data]);

  return (
    <Panel
      header={
        <PanelHeader>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Title size={4} noMargin>
                {tt('presentations')}
              </Title>
            </FlexItem>
            <FlexItem>
              {run(() => {
                if (loading) {
                  return <Spinner />;
                } else if (meeting != null && !isReadOnly) {
                  return (
                    <DisplayOptions
                      onChange={handleModifySettings}
                      options={{
                        displayListingRooms: meeting.displayListingRooms,
                        displayListingBedrooms: meeting.displayListingBedrooms,
                        displayListingPlans: meeting.displayListingPlans,
                        displayListingPrice: meeting.displayListingPrice,
                        displayListingStatus: meeting.displayListingStatus,
                        displayListingSurface: meeting.displayListingSurface,
                        allowListingPlansDownload: meeting.allowListingPlansDownload,
                        hideSoldUnits: meeting.hideSoldUnits,
                      }}
                    />
                  );
                }
              })}
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          {meetingPresentations.map((presentation, i) => (
            <Fragment key={i}>
              <PresentationRow
                key={presentation.id}
                isReadOnly={isReadOnly}
                presentation={presentation}
                onClickToggleListing={(v: boolean) => handleToggleListing(presentation.id, v)}
              />
              {i < meetingPresentations.length - 1 ? <RowSeparator /> : null}
            </Fragment>
          ))}
        </PanelBody>
      }
    />
  );
};
