import {
  Button,
  Category,
  CheckboxFilter,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  LoadingPlaceholder,
  Panel,
  PanelHeader,
  SearchInput,
  Size,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import React, { Fragment, ReactElement, ReactNode, useState } from 'react';

import { InviteModal } from '~/components';
import { OrganisationFromCore } from '~/pods/agency/types';
import { Licenses } from '~/pods/agency/utils/hooks';
import { Member, TeamTable } from '~/pods/settings/routes/CompanyTeam/components';
import { InvitationStatus, OrganisationRole } from '~/types';
import { createTranslate, useAuth } from '~/utils';

const tt = createTranslate('pods.agency.routes');
const ttCompanyTeam = createTranslate('pods.settings.routes.company_team.company_team');

enum UserStatus {
  ACTIVE = 'ACTIVE',
  AWAITING_VALIDATION = 'AWAITING_VALIDATION',
  DISABLED = 'DISABLED',
}

interface AgencyTeamLayoutProps {
  table: ReactElement;
  headerElements: Array<ReactNode>;
}

const AgencyTeamLayout = ({ table, headerElements }: AgencyTeamLayoutProps) => {
  return (
    <Panel
      header={
        <PanelHeader>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem flex>
              <Title noMargin size={4}>
                Team
              </Title>
            </FlexItem>
            <FlexItem>
              <Flex>
                {headerElements?.map((element, index) => {
                  return (
                    <Fragment key={index}>
                      <FlexItem>{element}</FlexItem>
                      {index !== headerElements.length - 1 ? (
                        <FlexSpacer direction={FlexDirection.HORIZONTAL} size={Size.EXTRA_SMALL} />
                      ) : null}
                    </Fragment>
                  );
                })}
              </Flex>
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={table}
    />
  );
};
interface AgencyTeamProps {
  isOrganisationLoading: boolean;
  organisation?: OrganisationFromCore;
  refetchOrganisation: () => void;
  licenses: Licenses;
}

export const AgencyTeam = ({
  organisation,
  refetchOrganisation,
  licenses,
  isOrganisationLoading,
}: AgencyTeamProps) => {
  const { user } = useAuth();

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [statuses, setStatuses] = useState<UserStatus[]>([]);

  const modalTitle = tt('invite_modal_title');

  const invitedMembers: Array<Member> = (organisation?.invitations ?? [])
    .filter((invitation) => invitation.status !== InvitationStatus.ACCEPTED)
    .map((invitation) => ({
      ...invitation,
      fullName: `${invitation.firstName} ${invitation.lastName}`,
      uniqueId: `${invitation.id}-inv`,
      type: 'invitation',
      invitedOn: invitation.emailSentAt,
    }));

  const members: Array<Member> = (organisation?.allUsers ?? []).map((user) => ({
    ...user,
    uniqueId: `${user.id}-usr`,
    type: 'member',
    memberSince: user.createdAt,
    assignedProjects: user.assignedProjects,
    deactivatedAt: user.deactivatedAt,
  }));

  const allMembers = [...members, ...invitedMembers];
  const filteredMembers = allMembers
    .filter((member) => {
      if (searchTerm) {
        return (
          member.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        return true;
      }
    })
    .filter((member) => {
      if (statuses.length === 0) {
        return true;
      }

      let matchesFilter = false;

      if (statuses.includes(UserStatus.DISABLED)) {
        matchesFilter = Boolean(member.deactivatedAt);
      }

      if (statuses.includes(UserStatus.AWAITING_VALIDATION) && !matchesFilter) {
        matchesFilter = (
          member.status === InvitationStatus.SENT || member.status === InvitationStatus.INITIATED
        );
      }

      if (statuses.includes(UserStatus.ACTIVE) && !matchesFilter) {
        matchesFilter = !member.deactivatedAt && !(member.status === InvitationStatus.SENT || member.status === InvitationStatus.INITIATED);
      }

      return matchesFilter
    });

  if (isOrganisationLoading) {
    return (
      <AgencyTeamLayout
        table={<TeamTable isLoading />}
        headerElements={[
          <LoadingPlaceholder key={1} width={200} height={40} />,
          <LoadingPlaceholder key={2} width={200} height={40} />,
        ]}
      />
    );
  }

  return (
    <Fragment>
      <InviteModal
        isInvitingForAgency
        title={modalTitle}
        visible={isInviteModalVisible}
        onClickInvite={refetchOrganisation}
        onClickClose={() => setIsInviteModalVisible(false)}
      />
      <AgencyTeamLayout
        table={
          <TeamTable
            isAgency
            licenses={licenses}
            members={filteredMembers}
            projects={organisation?.assignedProjects}
            onSuccessfulMutation={refetchOrganisation}
          />
        }
        headerElements={[
          <SearchInput
            key={1}
            minimal
            value={searchTerm}
            placeholder={ttCompanyTeam('search')}
            onChange={setSearchTerm}
          />,
          <CheckboxFilter
            key={2}
            options={[
              {
                value: UserStatus.ACTIVE,
                label: ttCompanyTeam(
                  `status_filter.option_labels.${UserStatus.ACTIVE.toLowerCase()}`,
                ),
              },
              {
                value: UserStatus.AWAITING_VALIDATION,
                label: ttCompanyTeam(
                  `status_filter.option_labels.${UserStatus.AWAITING_VALIDATION.toLowerCase()}`,
                ),
              },
              {
                value: UserStatus.DISABLED,
                label: ttCompanyTeam(
                  `status_filter.option_labels.${UserStatus.DISABLED.toLowerCase()}`,
                ),
              },
            ]}
            onChange={setStatuses}
            values={statuses}
            clearLabel={ttCompanyTeam('status_filter.clear')}
            onClear={() => setStatuses([])}
            label={ttCompanyTeam('status_filter.label')}
          />,
          ...(user && user.role === OrganisationRole.AGENCY_SALES_MANAGER
            ? [
              <Button
                key={3}
                onClick={() => setIsInviteModalVisible(true)}
                category={Category.INFO}
                tier={Tier.PRIMARY}>
                {modalTitle}
              </Button>,
            ]
            : []),
        ]}
      />
    </Fragment>
  );
};
