import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  CircularProgress,
  Color,
  Icon,
  LoadingPlaceholder,
  Size,
  Tier,
  UploadHelper,
  useAlert,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { gql } from 'graphql-tag';
import React, { useEffect } from 'react';

import { OrganisationFromCore } from '~/pods/agency/types';
import { OrganisationRole } from '~/types';
import {
  UploadedFile,
  generateColorFromString,
  translate,
  useAuth,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

const UPLOADER_SIZE = 160;

const styles = {
  pictureContainer: css`
    height: ${UPLOADER_SIZE}px;
    width: ${UPLOADER_SIZE}px;
    border-radius: 50%;
    background: ${sv.white};
    border: 1px solid ${sv.neutralLight};
    padding: ${sv.paddingExtraSmall};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  picture: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: ${sv.white};
    font-size: 4em;
    font-weight: 300;
    border-radius: 1000px;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  uploadArea: css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${sv.defaultTransition};

    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.8);
    }
  `,
  smallIcon: css`
    font-size: initial !important;
  `,
  circularProgress: css`
    height: 50px;
  `,
};

interface UpdateAgencyLogoVariables {
  organisation: {
    id?: string;
    logo?: string; // Signed blob id
  };
}

interface UpdateAgencyLogoResult {
  updateOrganisation: {
    organisation: {
      id?: string;
      logo?: {
        id: string;
        url: string;
      };
    };
  };
}

const updateAgencyLogoMutation = gql`
  mutation UpdateOrganisation($organisation: UpdateOrganisationInput!) {
    updateOrganisation(input: $organisation) {
      organisation {
        logo {
          id
          url
        }
      }
    }
  }
`;

interface AgencyAvatarUploaderProps {
  organisation: OrganisationFromCore;
  refreshOrganisation: () => void;
}

export const AgencyLogoUploader = ({
  organisation,
  refreshOrganisation,
}: AgencyAvatarUploaderProps) => {
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const { data, uploadFiles, info, isLoading: isFileUploading } = useFileUpload(useMosaicMutation);
  const {
    res: { fetching: isUpdatingOrganisation },
    executeMutation: updateAgencyLogo,
  } = useMosaicMutation<UpdateAgencyLogoResult, UpdateAgencyLogoVariables>(
    updateAgencyLogoMutation,
  );

  useEffect(() => {
    if (!isFileUploading && data.length > 0) {
      const errors = info
        .filter((fileInfo) => fileInfo.error != null)
        .map((fileInfo) => fileInfo.filename);
      if (errors.length > 0) {
        showAlert({
          text: translate('upload_error', { files: errors.join(', ') }),
          category: Category.DANGER,
        });
      }
    }
  }, [isFileUploading]);

  const handleSaveChanges = async (logo: UploadedFile) => {
    const { error } = await updateAgencyLogo({
      organisation: {
        id: organisation.id,
        logo: logo.signedBlobId,
      },
    });
    if (error != null) {
      showAlert({
        text: 'Error while updating the agency logo',
        category: Category.DANGER,
      });
    } else {
      refreshOrganisation();
    }
  };

  const handleUpload = async (files: FileList) => {
    if (files.length > 0) {
      const res = await uploadFiles(files);

      const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;

      if (validFiles[0]) {
        handleSaveChanges(validFiles[0]);
      }
    }
  };

  return (
    <div className={styles.pictureContainer}>
      <div
        className={styles.picture}
        style={{
          background: generateColorFromString(organisation.publicToken),
        }}>
        {organisation.logo ? <img src={organisation.logo.url} /> : organisation.name?.[0]}
        {user && user.role === OrganisationRole.AGENCY_SALES_MANAGER ? (
          <UploadHelper allowedFileFormats=".jpg,.png,.gif" onUploadFiles={handleUpload}>
            <div className={styles.uploadArea}>
              {(isFileUploading || isUpdatingOrganisation) && info.length > 0 ? (
                <CircularProgress
                  className={cx(styles.circularProgress)}
                  size={Size.SMALL}
                  percentage={info[0].progress ?? 0}
                  color={Color.GREEN}
                />
              ) : (
                <Button
                  size={Size.SMALL}
                  trailing={<Icon className={cx(styles.smallIcon)} name="photo-shooting" />}
                  tier={Tier.SECONDARY}
                />
              )}
            </div>
          </UploadHelper>
        ) : null}
      </div>
    </div>
  );
};

AgencyLogoUploader.Loading = () => (
  <LoadingPlaceholder width={UPLOADER_SIZE} height={UPLOADER_SIZE} />
);
