import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { OrganisationOverview } from '../../types';

interface OrganisationOverviewQueryData {
  overview?: OrganisationOverview;
}

interface OrganisationOverviewQueryVariables {
  organisationId: string;
}

const OrganisationOverviewQuery = gql`
  query OrganisationOverviewQuery {
    overview {
      id
      status
      updatedAt: lastPublishedAt
      coverAsset {
        id
        url
        ... on AttachmentAsset {
          filename
        }
      }
      mainAsset {
        id
        url
        ... on AttachmentAsset {
          filename
        }
      }
      title
      description
      subtitle
      showFigures
      showAssets
      assets {
        id
        url
        position
        ... on AttachmentAsset {
          filename
        }
      }
      figuresTitle
      figures {
        id
        value
        superscript
        subtext
      }
    }
  }
`;

export function useFetchOrganisationOverview(): {
  isLoading: boolean;
  organisationOverview?: OrganisationOverview;
  error?: ErrorType;
  refetch: VoidFunction;
} {
  const res = useMosaicQuery<OrganisationOverviewQueryData, OrganisationOverviewQueryVariables>({
    query: OrganisationOverviewQuery,
  });

  const isLoading = res.data?.overview == null && res.error == null ? res.isLoading : false;

  return {
    error: buildError(res.error),
    isLoading,
    organisationOverview: res.data?.overview,
    refetch: () => res.refetch({ requestPolicy: 'network-only' }),
  };
}
