import React, { useState } from 'react';

import { LeadEmailNotificationSettingsPanel as LeadNotificationSettingsPanelInput } from '~/components/LeadEmailNotificationSettingsPanel';
import { Project } from '~/pods/crm/types';
import { useUpdateProject } from '~/pods/project-settings/utils/hooks';
import { OrganisationRole } from '~/types';
import { useAuth } from '~/utils';

interface EmailNotificationPanelProps {
  project: Project;
}

export const LeadEmailNotificationPanel = ({ project }: EmailNotificationPanelProps) => {
  const [leadNotificationEmails, setLeadNotificationEmails] = useState(
    project.leadNotificationEmails ?? [],
  );

  const { isLoading: isUpdating, updateProject } = useUpdateProject();
  const { user } = useAuth();

  const isUserMember = user?.role === OrganisationRole.MEMBER;

  return (
    <LeadNotificationSettingsPanelInput
      emails={leadNotificationEmails}
      onChange={async (emails) => {
        setLeadNotificationEmails(emails)

        await updateProject({
          id: project.id,
          leadNotificationEmails: emails,
        });
      }}
      disabled={isUserMember}
      isSaving={isUpdating}
    />
  );
};
