import { useEffect, useState } from 'react';

interface Error {
  status: number;
}

interface State<T, K> {
  isLoading: boolean;
  data?: T;
  error?: K | Error;
  success?: boolean;
}

export function useFetchData<T = any, K = any>(
  url: string,
  { config, transform, waitFor = true, shouldExecute = true }: any = {},
) {
  // Use single state to avoid unnecessary re-renders
  const [state, setState] = useState({ isLoading: true } as State<T, K>);
  const updateState = (newState: State<T, K>) => setState((state) => ({ ...state, ...newState }));

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url, config);
      if (response.ok) {
        const data = await response.json();
        updateState({
          isLoading: false,
          data: transform != null ? transform(data) : data,
          success: true,
        });
      } else {
        updateState({ isLoading: false, error: { status: response.status }, success: false });
      }
    };

    if (waitFor && shouldExecute && state.success == null) {
      fetchData();
    }
  }, [url, transform, JSON.stringify(config), waitFor, shouldExecute]);

  return state;
}
