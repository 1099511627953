import sv from '@drawbotics/drylus-style-vars';
import {
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  ListTile,
  LoadingPlaceholder,
  Margin,
  Size,
  Text,
  formatPrice,
} from '@drawbotics/react-drylus';
//@ts-ignore LineCustomLayerProps missing from type definitions
import { LineCustomLayerProps, LineSvgProps, ResponsiveLine, Serie } from '@nivo/line';
import dayjs, { Dayjs } from 'dayjs';
import { zip } from 'lodash';
import React, { Fragment } from 'react';

import { DashedLine } from '~/pods/insights/components';
import coin from '~/pods/insights/images/coin.svg';
import { Availability, InsightsEstate, Project } from '~/pods/insights/types';
import { getEmptyDates, isDateInRange } from '~/pods/insights/utils';
import { colorGenerator, createTranslate, currencyToSymbol } from '~/utils';

import { CustomLineTooltip } from '../CustomLineTooltip';
import { ChartPanel } from './ChartPanel';

const tt = createTranslate('pods.insights.routes.deals');

function _getMonthsTillToday(start: Dayjs) {
  const today = dayjs();
  let months: Array<Dayjs> = [];
  let currentDate = dayjs(start).locale(window.i18n.locale);

  while (!currentDate.isSame(today, 'month')) {
    months.push(currentDate);
    currentDate = currentDate.add(1, 'month');
  }

  months.push(today);

  return months;
}

function _getDaysTillToday(start: Dayjs) {
  const today = dayjs();
  let days: Array<Dayjs> = [];
  let currentDate = dayjs(start);

  while (!currentDate.isSame(today, 'day')) {
    days.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  days.push(today);

  return days;
}

function _getStepSize(amountOfDays: number) {
  if (amountOfDays < 16) {
    return 1;
  } else if (amountOfDays < 31) {
    return 2;
  } else {
    return 7;
  }
}

function _takeNthElements(array: Array<unknown>, n: number) {
  if (n === 1) {
    return array;
  }

  let res = [];
  for (let i = 0; i < array.length; i += n) {
    res.push(array[i]);
  }

  if (array.length % n === 0) {
    res.push(array[array.length - 1]);
  }
  return res;
}

const PlaceholderChartRow = () => {
  return (
    <Flex style={{ height: '24px' }}>
      <FlexItem>
        <LoadingPlaceholder width={24} height={16} />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
      <FlexItem flex>
        <DashedLine horizontal />
      </FlexItem>
    </Flex>
  );
};

const LoadingPlaceholderChart = () => {
  return (
    <Fragment>
      <Flex direction={FlexDirection.VERTICAL}>
        {[...Array(7)].map((_, i) => (
          <Fragment key={i}>
            <FlexItem style={{ width: '100%' }}>
              <PlaceholderChartRow />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
          </Fragment>
        ))}
      </Flex>
      <Flex style={{ width: '100%' }} justify={FlexJustify.SPACE_EVENLY}>
        {[...Array(12)].map((_, i) => (
          <FlexItem key={i}>
            <LoadingPlaceholder width={40} height={16} />
          </FlexItem>
        ))}
      </Flex>
    </Fragment>
  );
};

const SoldOverTimeChart = (props: LineSvgProps) => {
  return (
    <ResponsiveLine
      {...props}
      pointSize={8}
      pointBorderWidth={3}
      pointColor="white"
      pointBorderColor={{ from: 'serieColor' }}
      enableArea
      axisBottom={{
        tickRotation: 30,
        tickPadding: 10,
      }}
      areaOpacity={1}
      enableGridX={false}
      debugMesh
      isInteractive
      enableSlices="x"
      colors={{ datum: 'color' }}
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: sv.colorTertiary,
            },
            text: {
              marginTop: -10,
              fontSize: '1em',
              fontFamily: 'Rubik',
              fontWeight: 400,
              fill: sv.colorSecondary,
            },
          },
        },
        grid: {
          line: {
            strokeDasharray: '2 2',
          },
        },
        markers: {
          textColor: sv.blue,
          text: {
            fontSize: '1em',
            fontFamily: 'serif',
            fontWeight: 400,
          },
        },
      }}
    />
  );
};

function createProjectionLayer(points: Array<{ x: string; y: number }>) {
  return ({ xScale, yScale, lineGenerator }: LineCustomLayerProps) => {
    return (
      <g>
        <path
          d={lineGenerator(
            points.map((p) => ({
              x: xScale(p.x),
              y: yScale(p.y),
            })),
          )}
          fill="none"
          stroke={sv.neutralDark}
          style={{
            strokeDasharray: '3, 3',
            strokeWidth: 2,
          }}
        />
      </g>
    );
  };
}

interface SoldOverTimeProps {
  units: InsightsEstate['units'];
  availableTypologies: Array<string>;
  project: Project;
}

const UnitsSoldOverTime = ({ units, availableTypologies, project }: SoldOverTimeProps) => {
  const colors = colorGenerator();

  const soldUnits = units.filter((u) => u.availability === Availability.BOOKED);
  const earliestDate = dayjs.min(soldUnits.map((u) => u.soldAt!));
  const { dealsGoalStartDate: goalStartDate, dealsGoalEndDate: goalEndDate } = project;
  const showProjectionLine = goalStartDate != null && goalEndDate != null;

  const dates = Object.keys(
    getEmptyDates({ start: earliestDate, end: dayjs() }, 'day', 'DD-MMM-YYYY'),
  );

  const scaleIsMonth = dates.length > 90;

  let lines: Array<Serie> = [];
  if (scaleIsMonth) {
    const months = _getMonthsTillToday(earliestDate);
    lines = availableTypologies
      .slice()
      .reverse()
      .map((typology) => {
        const unitsInTypology = soldUnits.filter((u) => u.typology === typology);
        const color = colors.next().value;

        const data = months.reduce((memo, month, index) => {
          const salesPreviousMonth = index === 0 ? 0 : memo[index - 1].y;

          const salesForThisMonth = unitsInTypology.filter((u) =>
            u.soldAt?.isSame(month, 'month'),
          ).length;

          return [
            ...memo,
            { x: month.format('MMM YY'), y: salesPreviousMonth + salesForThisMonth },
          ];
        }, [] as Array<{ x: string; y: number }>);

        return {
          data,
          id: typology.replace(' ', ''),
          color: color.base,
          areaColor: color.lighter,
        };
      });
  } else {
    const step = _getStepSize(dates.length);
    const days = _getDaysTillToday(earliestDate);
    lines = availableTypologies
      .slice()
      .reverse()
      .map((typology) => {
        const unitsInTypology = soldUnits.filter((u) => u.typology === typology);
        const color = colors.next().value;

        const cumulative = days.reduce((memo, day, index) => {
          const previousDayValue = index === 0 ? 0 : memo[index - 1];
          const currentValue = unitsInTypology.filter((u) => u.soldAt?.isSame(day, 'day')).length;
          return [...memo, previousDayValue + currentValue];
        }, [] as Array<number>);

        const data = zip(_takeNthElements(cumulative, step), _takeNthElements(days, step)).map(
          ([value, day]) => ({ x: (day as Dayjs).format('MMM D'), y: value as number }),
        );

        return {
          data,
          id: typology.replace(' ', ''),
          color: color.base,
          areaColor: color.lighter,
        };
      });
  }

  const dateRange = {
    start: earliestDate,
    end: dayjs(),
  };

  let projectionLayerPoints: Array<{ x: string; y: number }> = [];

  if (showProjectionLine) {
    let point1;
    if (isDateInRange(goalStartDate!, dateRange)) {
      point1 = {
        x: goalStartDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: 0,
      };
    } else {
      const fullProjectionRange = goalEndDate!.diff(goalStartDate!, 'day') + 1;
      const step = units.length / fullProjectionRange;
      const daysFromStartToEarliest = earliestDate!.diff(goalStartDate!, 'day') + 1;
      const yValueAtEarliestDate = Math.round(step * daysFromStartToEarliest);

      point1 = {
        x: earliestDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: yValueAtEarliestDate,
      };
    }

    let point2;
    if (isDateInRange(goalEndDate!, dateRange)) {
      point2 = {
        x: goalEndDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: units.length,
      };
    } else {
      const fullProjectionRange = goalEndDate!.diff(goalStartDate!, 'day') + 1;
      const step = units.length / fullProjectionRange;
      const daysFromStartToToday = dayjs().diff(goalStartDate, 'day') + 1;
      const yValueAtCurrentDate = Math.round(step * daysFromStartToToday);

      point2 = {
        x: dayjs().format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: yValueAtCurrentDate,
      };
    }
    projectionLayerPoints = [point1, point2];
  }

  return (
    <ChartPanel
      title={tt('units_sold_over_time')}
      legend={
        <Flex>
          <FlexItem>
            <ListTile
              leading={
                <div style={{ height: '2px', width: '24px', borderTop: `2px dashed ${sv.blue}` }} />
              }
              title={<Text>{tt('sales_target')}</Text>}
            />
          </FlexItem>
          {showProjectionLine ? (
            <FlexItem>
              <Margin size={{ left: Size.SMALL }}>
                <ListTile
                  leading={
                    <div
                      style={{
                        height: '2px',
                        width: '27px',
                        borderTop: `3px dotted ${sv.neutralDark}`,
                      }}
                    />
                  }
                  title={<Text>{tt('projection')}</Text>}
                />
              </Margin>
            </FlexItem>
          ) : null}
        </Flex>
      }
      chart={
        <SoldOverTimeChart
          data={lines}
          yScale={{ type: 'linear', stacked: true, max: units.length + 10 }}
          margin={{
            left: 40,
            top: 20,
            right: 45,
            bottom: 60,
          }}
          sliceTooltip={(props) => <CustomLineTooltip {...props} />}
          markers={[
            {
              axis: 'y',
              value: units.length,
              lineStyle: { stroke: sv.blue, strokeWidth: 2, strokeDasharray: '4 4' },
              legend: units.length.toString(),
            },
          ]}
          defs={availableTypologies.map((typology) => {
            const typology_without_spaces = typology.replace(' ', '');
            const color = lines.find((line) => line.id === typology_without_spaces)?.areaColor;
            return {
              id: typology_without_spaces,
              type: 'linearGradient',
              colors: [
                {
                  offset: 0,
                  color: color,
                },
              ],
            };
          })}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'lines',
            'points',
            'slices',
            createProjectionLayer(projectionLayerPoints),
            'crosshair',
          ]}
          fill={availableTypologies.map((typology) => {
            const typology_without_spaces = typology.replace(' ', '');
            return {
              match: { id: typology_without_spaces },
              id: typology_without_spaces,
            };
          })}
        />
      }
    />
  );
};

const RevenueSoldOverTime = ({ units, availableTypologies, project }: SoldOverTimeProps) => {
  const colors = colorGenerator();

  const soldUnits = units.filter((u) => u.availability === Availability.BOOKED);
  const totalPrice = units.reduce((memo, unit) => memo + unit.price.value, 0);
  const earliestDate = dayjs.min(soldUnits.map((u) => u.soldAt!));
  const { dealsGoalStartDate: goalStartDate, dealsGoalEndDate: goalEndDate } = project;
  const showProjectionLine = goalStartDate != null && goalEndDate != null;

  const dates = Object.keys(
    getEmptyDates({ start: earliestDate, end: dayjs() }, 'day', 'DD-MMM-YYYY'),
  );

  const scaleIsMonth = dates.length > 90;

  let lines: Array<Serie> = [];
  if (scaleIsMonth) {
    const months = _getMonthsTillToday(earliestDate);
    lines = availableTypologies
      .slice()
      .reverse()
      .map((typology) => {
        const unitsInTypology = soldUnits.filter((u) => u.typology === typology);
        const color = colors.next().value;

        const data = months.reduce((memo, month, index) => {
          const revenuePreviousMonth = index === 0 ? 0 : memo[index - 1].y;

          const revenueForThisMonth = unitsInTypology.reduce(
            (memo, unit) => (unit.soldAt?.isSame(month, 'month') ? memo + unit.price.value : memo),
            0,
          );

          return [
            ...memo,
            { x: month.format('MMM YY'), y: revenuePreviousMonth + revenueForThisMonth },
          ];
        }, [] as Array<{ x: string; y: number }>);

        return {
          data,
          id: typology,
          color: color.base,
          areaColor: color.lighter,
        };
      });
  } else {
    const step = _getStepSize(dates.length);
    const days = _getDaysTillToday(earliestDate);

    lines = availableTypologies
      .slice()
      .reverse()
      .map((typology) => {
        const unitsInTypology = soldUnits.filter((u) => u.typology === typology);
        const color = colors.next().value;

        const cumulative = days.reduce((memo, day, index) => {
          const previousDayValue = index === 0 ? 0 : memo[index - 1];
          const currentValue = unitsInTypology.reduce((memo, unit) => {
            return unit.soldAt?.isSame(day, 'day') ? memo + unit.price.value : memo;
          }, 0);
          return [...memo, previousDayValue + currentValue];
        }, [] as Array<number>);

        const data = zip(_takeNthElements(cumulative, step), _takeNthElements(days, step)).map(
          ([value, day]) => ({ x: (day as Dayjs).format('MMM D'), y: value as number }),
        );

        return {
          data,
          id: typology,
          color: color.base,
          areaColor: color.lighter,
        };
      });
  }

  const dateRange = {
    start: earliestDate,
    end: dayjs(),
  };

  let projectionLayerPoints: Array<{ x: string; y: number }> = [];

  if (showProjectionLine) {
    let point1;
    if (isDateInRange(goalStartDate!, dateRange)) {
      point1 = {
        x: goalStartDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: 0,
      };
    } else {
      const fullProjectionRange = goalEndDate!.diff(goalStartDate!, 'day') + 1;
      const step = totalPrice / fullProjectionRange;
      const daysFromStartToEarliest = earliestDate!.diff(goalStartDate!, 'day') + 1;
      const yValueAtEarliestDate = Math.round(step * daysFromStartToEarliest);

      point1 = {
        x: earliestDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: yValueAtEarliestDate,
      };
    }

    let point2;
    if (isDateInRange(goalEndDate!, dateRange)) {
      point2 = {
        x: goalEndDate!.format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: totalPrice,
      };
    } else {
      const fullProjectionRange = goalEndDate!.diff(goalStartDate!, 'day') + 1;
      const step = totalPrice / fullProjectionRange;
      const daysFromStartToToday = dayjs().diff(goalStartDate, 'day') + 1;
      const yValueAtCurrentDate = Math.round(step * daysFromStartToToday);

      point2 = {
        x: dayjs().format(scaleIsMonth ? 'MMM YY' : 'MMM D'),
        y: yValueAtCurrentDate,
      };
    }
    projectionLayerPoints = [point1, point2];
  }

  const totalRevenue = units.reduce((memo, u) => memo + u.price.value, 0);

  return (
    <ChartPanel
      title={tt('sales_over_time')}
      legend={
        <Flex>
          <FlexItem>
            <ListTile
              leading={
                <div style={{ height: '2px', width: '24px', borderTop: `2px dashed ${sv.blue}` }} />
              }
              title={<Text>{tt('sales_target')}</Text>}
            />
          </FlexItem>
          {showProjectionLine ? (
            <FlexItem>
              <Margin size={{ left: Size.SMALL }}>
                <ListTile
                  leading={
                    <div
                      style={{
                        height: '2px',
                        width: '27px',
                        borderTop: `3px dotted ${sv.neutralDark}`,
                      }}
                    />
                  }
                  title={<Text>{tt('projection')}</Text>}
                />
              </Margin>
            </FlexItem>
          ) : null}
        </Flex>
      }
      chart={
        <SoldOverTimeChart
          data={lines}
          yScale={{ type: 'linear', stacked: true, max: totalRevenue + totalRevenue * 0.05 }}
          margin={{
            left: 80,
            top: 20,
            right: 30,
            bottom: 60,
          }}
          sliceTooltip={(props) => (
            <CustomLineTooltip currency={soldUnits[0].price.currency} {...props} />
          )}
          axisLeft={{
            format: (val) =>
              val === 0
                ? ''
                : `${(Number(val) / 1000).toLocaleString()}k${
                    currencyToSymbol[soldUnits[0].price.currency]
                  }`,
          }}
          markers={[
            {
              axis: 'y',
              value: totalRevenue,
              lineStyle: { stroke: sv.blue, strokeWidth: 2, strokeDasharray: '4 4' },
              legend: formatPrice({
                price: { value: totalRevenue, currency: soldUnits[0].price.currency },
                options: { notation: 'compact' },
              }),
            },
          ]}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'lines',
            'points',
            'slices',
            createProjectionLayer(projectionLayerPoints),
            'crosshair',
          ]}
          defs={availableTypologies.map((typology) => ({
            id: typology,
            type: 'linearGradient',
            colors: [
              {
                offset: 0,
                color: lines.find((line) => line.id === typology)?.areaColor,
              },
            ],
          }))}
          fill={availableTypologies.map((typology) => ({ match: { id: typology }, id: typology }))}
        />
      }
    />
  );
};

interface SoldOverTimePanelProps {
  isLoading?: boolean;
  units?: InsightsEstate['units'];
  metric?: 'units' | 'revenue';
  availableTypologies?: Array<string>;
  project?: Project;
}

export const SoldOverTimePanel = ({
  isLoading,
  units,
  metric,
  project,
  availableTypologies = [],
}: SoldOverTimePanelProps) => {
  if (isLoading) {
    return <ChartPanel title={'Units sold over time'} chart={<LoadingPlaceholderChart />} />;
  }

  if (units == null || project == null) {
    console.error(
      'Invariant violated: `units` or `project` is null even though loading has completed',
    );
    return null;
  }

  if (units.length === 0) {
    return null;
  }

  if (units.filter((u) => u.availability === Availability.BOOKED).length === 0) {
    return (
      <ChartPanel
        title={'Sales over time'}
        chart={
          <Flex direction={FlexDirection.VERTICAL}>
            <FlexSpacer size={Size.EXTRA_LARGE} />
            <FlexItem>
              <img src={coin} />
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Text>{tt('no_units_sold_yet')}</Text>
            </FlexItem>
          </Flex>
        }
      />
    );
  }

  if (metric === 'units') {
    return (
      <UnitsSoldOverTime
        units={units}
        availableTypologies={availableTypologies}
        project={project}
      />
    );
  } else {
    return (
      <RevenueSoldOverTime
        units={units}
        availableTypologies={availableTypologies}
        project={project}
      />
    );
  }
};
