import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexDirection, FlexItem, Margin, Separator, Size } from '@drawbotics/react-drylus';
import React, { Fragment } from 'react';

import { useFetchLead, useFetchOrganisation } from '~/pods/crm/utils';
import { run } from '~/utils';

import { About, AboutPlaceHolder } from './About';
import { Contact, ContactPlaceholder } from './Contact';
import { InterestedIn, InterestedInPlaceHolder } from './InterestedIn';
import { LeadProfile, LeadProfilePlaceHolder } from './LeadProfile';
import { Projects, ProjectsPlaceholder } from './Projects';
import { PlaceholderWheel } from './StatusWheel';

interface LeadDetailsProps {
  leadId: string;
  reloadLogs: VoidFunction;
}

export const LeadDetails = ({ leadId, reloadLogs }: LeadDetailsProps) => {
  const {
    isLoading: isLeadLoading,
    lead,
    refetch: refetchLead,
    error: leadError,
  } = useFetchLead(leadId);

  const {
    isLoading: isOrganisationLoading,
    organisation,
    error: organisationError,
  } = useFetchOrganisation();

  return (
    <Fragment>
      {run(() => {
        const dataIsLoaded = lead != null && organisation != null;

        if ((isLeadLoading || isOrganisationLoading) && !dataIsLoaded) {
          return (
            <Fragment>
              <LeadProfilePlaceHolder />
              <Margin size={{ bottom: Size.SMALL, top: Size.DEFAULT }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <ContactPlaceholder />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <AboutPlaceHolder />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <InterestedInPlaceHolder />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <ProjectsPlaceholder />
            </Fragment>
          );
        } else if (leadError != null || organisationError != null || !dataIsLoaded) {
          return (
            <Flex direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <PlaceholderWheel />
              </FlexItem>
            </Flex>
          );
        } else {
          return (
            <Fragment>
              <LeadProfile
                reloadLead={refetchLead}
                reloadLogs={reloadLogs}
                steps={organisation.steps ?? []}
                lead={lead}
                organisation={organisation}
              />
              <Margin size={{ bottom: Size.SMALL, top: Size.DEFAULT }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <Contact lead={lead} />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <About lead={lead} />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <InterestedIn lead={lead} currency={organisation.currency} />
              <Margin size={{ vertical: Size.SMALL }}>
                <Separator style={{ background: sv.neutral }} />
              </Margin>
              <Projects lead={lead} onClickUpdate={refetchLead} />
            </Fragment>
          );
        }
      })}
    </Fragment>
  );
};
