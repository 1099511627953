import { css } from 'emotion';
import React, { CSSProperties, ReactNode } from 'react';

import { Position } from '../../../types';

const styles = {
  pageGridItem: css`
    position: absolute;
  `,
};

export interface PageGridItemProps {
  children?: ReactNode;
  position: Position;
  style?: CSSProperties;
}

export const PageGridItem = ({ children, style }: PageGridItemProps) => {
  return (
    <div className={styles.pageGridItem} style={style}>
      {children}
    </div>
  );
};
