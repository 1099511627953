import {
  Flex,
  FlexItem,
  FlexSpacer,
  ListTile,
  Size,
  TextLink,
  Toggle,
} from '@drawbotics/react-drylus';
import React, { useEffect, useState } from 'react';

import { Thumbnail } from '~/components/Thumbnail';
import { Presentation } from '~/pods/meetings/types';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.marketing_suite.routes.meeting.components.presentation_row');

interface PresentationRowProps {
  presentation: Presentation;
  onClickToggleListing: (value: boolean) => void;
  isReadOnly: boolean;
}

export const PresentationRow = ({
  presentation,
  onClickToggleListing,
  isReadOnly,
}: PresentationRowProps) => {
  const [listingEnabled, setListingEnabled] = useState(presentation.listingDisplay);

  const handleToggleListing = () => {
    const value = !presentation.listingDisplay;
    setListingEnabled(value);
    onClickToggleListing(value);
  };

  useEffect(() => {
    if (listingEnabled !== presentation.listingDisplay) {
      setListingEnabled(presentation.listingDisplay);
    }
  }, [presentation.listingDisplay]);

  return (
    <Flex>
      <FlexItem>
        <Thumbnail image={presentation.coverPicture?.url} />
      </FlexItem>
      <FlexSpacer size={Size.EXTRA_SMALL} />
      <FlexItem flex>
        <a href={presentation.presentationUrl} rel="noreferrer noopener" target="_blank">
          <TextLink>{presentation.name}</TextLink>
        </a>
      </FlexItem>
      <FlexItem>
        <ListTile
          title={tt('show_listing')}
          leading={
            <Toggle disabled={isReadOnly} onChange={handleToggleListing} value={listingEnabled} />
          }
        />
      </FlexItem>
      <FlexSpacer size={Size.SMALL} />
    </Flex>
  );
};
