import sv from '@drawbotics/drylus-style-vars';
import { Padding, Size, Text } from '@drawbotics/react-drylus';
import { ResponsiveBar } from '@nivo/bar';
import { css } from 'emotion';
import { groupBy } from 'lodash';
import React from 'react';

import { LineChartPlaceholder } from '~/pods/insights/components';
import { DateRange, InsightsSession } from '~/pods/insights/types';
import { LineTheme } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { ChartHeader } from './ChartHeader';

const tt = createTranslate('pods.insights.routes.traction.visit_times_chart');

const styles = {
  dealsChartContainer: css`
    width: 100%;
    height: 210px;
  `,
  tooltipBox: css`
    background: ${sv.neutralDarkest};
    border-radius: ${sv.defaultBorderRadius};
    box-shadow: ${sv.elevation3};
  `,
};

function _getYGridLines(data: Array<number>): Array<number> {
  const steps = [
    0,
    Math.floor(data.length * 0.25),
    Math.floor(data.length * 0.5),
    Math.floor(data.length * 0.75),
    data.length - 1,
  ];

  if (data.length < 11) {
    return data;
  } else {
    return data.filter((_d, i) => steps.includes(i));
  }
}

interface VisitTimesChartProps {
  visitors: Array<InsightsSession>;
  range: DateRange;
}

export const VisitTimesChart = ({ visitors }: VisitTimesChartProps) => {
  const visitTimes = groupBy(visitors.map((v) => v.createdAt.get('hour')));
  const yGridLines = _getYGridLines(Object.keys(visitTimes).map((k) => Number(k)));
  const chartData = Object.keys(visitTimes).map((k) => ({
    visitTime: k,
    visitCount: visitTimes[k].length,
    color: sv.blue,
  }));

  return (
    <Padding size={Size.EXTRA_SMALL}>
      <ChartHeader label={tt('popular_visit_times')} />
      <div className={styles.dealsChartContainer}>
        {chartData.length === 0 ? (
          <LineChartPlaceholder height={210} message={tt('no_data')} />
        ) : (
          <ResponsiveBar
            data={chartData}
            colors={({ data: { color } }) => color}
            keys={['visitCount']}
            indexBy="visitTime"
            margin={{ top: 5, bottom: 30, right: 20, left: 30 }}
            padding={0.7}
            borderRadius={5}
            enableLabel={false}
            axisBottom={{
              tickSize: 0,
              tickValues: yGridLines,
              format: (d) => `${d}:00`,
            }}
            axisLeft={{
              tickSize: 0,
              tickValues: 5,
            }}
            tooltip={({ value, indexValue }) => (
              <div className={styles.tooltipBox}>
                <Text inversed>
                  {`${indexValue}:00`} : {value} {value === 1 ? 'visitor' : 'visitors'}
                </Text>
              </div>
            )}
            theme={{
              ...LineTheme,
              tooltip: {
                container: {
                  background: sv.neutralDarkest,
                },
              },
            }}
          />
        )}
      </div>
    </Padding>
  );
};
