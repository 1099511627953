import { Dropdown, DropdownOption, Position, Text } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import gql from 'fraql';
import React from 'react';
import { useLocation } from 'react-router';

import { LocaleCode, createTranslate, useMosaicMutation } from '~/utils';

const tt = createTranslate('components.basic_navbar');

const styles = {
  // There's no "TopRight" position so we ask the dropdown to display itself on the right and shift it up
  dropdownPositionFix: css`
    > div:nth-child(2) {
      transform: translateY(-85px);
    }
  `,
};

interface UpdateLocaleInput {
  user: {
    locale: string;
  };
}

interface UpdateLocalePayload {
  updateProfile: {
    id: string;
    locale: LocaleCode;
  };
}

const updateProfileMutation = gql`
  mutation updateProfile($user: UpdateProfileInput!) {
    updateProfile(input: $user) {
      user {
        id
        locale
      }
    }
  }
`;

export const LangSwitcher = ({ reloadOnChange }: { reloadOnChange?: boolean }) => {
  const { pathname } = useLocation();
  const { executeMutation: updateLocale } = useMosaicMutation<
    UpdateLocalePayload,
    UpdateLocaleInput
  >(updateProfileMutation);

  const handleChangeLocale = (locale: LocaleCode) => {
    const changeLocale = window.i18n.changeLocale;
    changeLocale(locale);

    if (!pathname.includes('auth')) {
      updateLocale({ user: { locale: (locale as string).toUpperCase() } });
    }

    if (reloadOnChange) {
      window.location.reload();
    }
  };

  return (
    <Dropdown
      side={Position.RIGHT}
      className={styles.dropdownPositionFix}
      trigger={<Text inversed>{window.i18n.locale.toUpperCase()}</Text>}>
      <DropdownOption text={tt('en')} onClick={() => handleChangeLocale('en')} />
      <DropdownOption text={tt('fr')} onClick={() => handleChangeLocale('fr')} />
      <DropdownOption text={tt('nl')} onClick={() => handleChangeLocale('nl')} />
    </Dropdown>
  );
};
