import {
  Align,
  ButtonLink,
  Flex,
  FlexDirection,
  FlexItem,
  Padding,
  Panel,
  PanelBody,
  Paragraph,
  Size,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import traction2Placeholder from '~/images/placeholders/traction-2.svg';
import { createTranslate, useMosaicNavigation } from '~/utils';

const tt = createTranslate('pods.insights.routes.dashboard');

export const CampaignPlaceholder = () => {
  const { getUrlInProject } = useMosaicNavigation();

  return (
    <Panel
      style={{ height: 340 }}
      body={
        <PanelBody>
          <Padding size={{ vertical: Size.LARGE }}>
            <Flex direction={FlexDirection.VERTICAL}>
              <FlexItem>
                <img src={traction2Placeholder} />
              </FlexItem>
              <FlexItem flex>
                <Paragraph align={Align.CENTER}>
                  <Text size={Size.LARGE}>{tt('campaign_placeholder_text')}</Text>
                </Paragraph>
              </FlexItem>
              <FlexItem>
                <Link to={getUrlInProject('/analytics/campaigns')}>
                  <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY}>
                    {tt('campaign_placeholder_cta')}
                  </ButtonLink>
                </Link>
              </FlexItem>
            </Flex>
          </Padding>
        </PanelBody>
      }
    />
  );
};
