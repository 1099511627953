import gql from 'fraql';

import { unitFragment } from '../fragments';

export const DestroyUnitMutation = gql`
  mutation DestroyUnit($unit: DeleteUnitInput!) {
    deletedUnit: deleteUnit(input: $unit) {
      unit {
        ${unitFragment}
      }
    }
  }
`;
