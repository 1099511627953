import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';

type Size = { width: number; height: number };

export function useNodeSize<T extends HTMLElement>(): {
  nodeRef: MutableRefObject<T | null>;
  size: Size;
} {
  const nodeRef = useRef<T>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const handleSetSize = () => {
      if (nodeRef.current == null) {
        return;
      }

      const { width, height } = nodeRef.current.getBoundingClientRect();

      if (width !== size.width && height !== size.height) {
        setSize({ width, height });
      }
    };

    handleSetSize();

    window.addEventListener('resize', handleSetSize);

    return () => {
      window.removeEventListener('resize', handleSetSize);
    };
  });

  return { nodeRef, size };
}
