import sv from '@drawbotics/drylus-style-vars';
import { ButtonLink, Category, Text, TextLink } from '@drawbotics/react-drylus';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import noLineData from '~/pods/product-stats/images/line-chart-placeholder.svg';
import { StatsData } from '~/pods/product-stats/utils';
import { OrganisationRole } from '~/types';
import { createTranslate, generateColorFromString, useAuth, useMosaicNavigation } from '~/utils';

import { EmptyTile } from '../EmptyTile';
import { ErrorTile } from '../ErrorTile';
import { StatTile } from '../StatTile';
import { ChartPlaceholder } from './ChartPlaceholder';
import { CustomLine } from './CustomLine';
import { CustomTooltip } from './CustomTooltip';
import { LoadingPlaceholderTile } from './LoadingPlaceholderTile';

const tt = createTranslate('pods.product_stats.components.meetings_over_time_tile');

export const CHART_THEME = {
  axis: {
    ticks: { text: { fontFamily: sv.defaultFontFamily, fill: sv.colorSecondary } },
  },
  grid: { line: { stroke: sv.neutralLight, strokeDasharray: '4 4' } },
  markers: { lineColor: sv.neutral, lineStrokeWidth: 2 },
  crosshair: {
    line: {
      stroke: sv.neutralLight,
      strokeWidth: 2,
      strokeDasharray: '0',
    },
  },
};

interface MeetingsOverTimeTileProps {
  isLoading: boolean;
  data?: StatsData['meetingsPerUser'];
}

export const MeetingsOverTimeTile = ({ isLoading, data }: MeetingsOverTimeTileProps) => {
  const { getUrlInOrg } = useMosaicNavigation();
  const { user } = useAuth();
  const isUserAdmin = user?.role === OrganisationRole.ADMIN;

  if (isLoading) {
    return <LoadingPlaceholderTile />;
  }
  if (data == null) {
    return <ErrorTile message={tt('could_not_load')} background={<ChartPlaceholder />} />;
  }

  if (data.length === 0) {
    return (
      <EmptyTile
        image={noLineData}
        cta={
          <Link to={getUrlInOrg('/meetings', isUserAdmin ? 'management' : '')}>
            <ButtonLink category={Category.INFO}>
              {isUserAdmin ? tt('add_team_members') : tt('schedule_meeting')}
            </ButtonLink>
          </Link>
        }
        text={
          <Text>
            {!isUserAdmin ? tt('track_team_members') : tt('see_member_meetings')}&nbsp;
            <a href="https://drawbotics.com/reach" target="_blank" rel="noopener noreferrer">
              <TextLink>Meetings</TextLink>
            </a>
            &nbsp;{tt('meetings_per_week')}
          </Text>
        }
      />
    );
  }

  let maxValue = 0;
  const chartLine = data.map((userData) => ({
    id: userData.user.fullName,
    color: generateColorFromString(userData.user.fullName),
    data: userData.meetings.map((obj) => {
      if (obj.count != null && obj.count > maxValue) {
        maxValue = obj.count;
      }
      return { x: obj.month.toDate(), y: obj.count };
    }),
  }));

  const sixMonthsAgo = dayjs().subtract(7, 'month').date(28).toDate();
  const thisMonth = dayjs().date(1).toDate();
  const maxYValue = Math.ceil((maxValue + 5) / 5) * 5;

  return (
    <StatTile title={tt('number_of_meetings')}>
      <ResponsiveLine
        data={chartLine}
        margin={{
          top: 24,
          bottom: 24,
          left: 24,
          right: 24,
        }}
        layers={['grid', 'markers', 'axes', 'crosshair', CustomLine, 'slices', 'points', 'mesh']}
        markers={[
          {
            axis: 'x',
            value: sixMonthsAgo,
          },
          {
            axis: 'y',
            value: 0,
          },
        ]}
        theme={CHART_THEME}
        colors={{ datum: 'color' }}
        animate={false}
        pointBorderWidth={3}
        pointColor={sv.white}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        enableGridX={false}
        lineWidth={3}
        pointSize={7}
        isInteractive={true}
        enableSlices="x"
        sliceTooltip={CustomTooltip}
        axisBottom={{
          tickSize: 0,
          tickValues: 'every month',
          format: (date) => dayjs(date).locale(window.i18n.locale).format('MMM'),
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: maxYValue / 5,
          // @ts-ignore
          format: (e) => (Math.floor(e as number) !== e || e === 0 ? undefined : e),
        }}
        gridYValues={maxYValue / 5}
        yScale={{
          type: 'linear',
          max: maxYValue,
          min: 0,
        }}
        xScale={{
          type: 'time',
          format: 'native',
          useUTC: false,
          precision: 'month',
          max: thisMonth,
          min: sixMonthsAgo,
        }}
      />
    </StatTile>
  );
};
