import { Dayjs, OpUnitType } from 'dayjs';

import { DateRange } from '../types';
import { LineData } from '../types';
import { getEmptyDates, isDateInRange, toDataPoints } from '../utils';

export type KeyOfType<ObjectType, ValueType> = {
  [Key in keyof ObjectType]: ObjectType[Key] extends ValueType ? Key : never;
}[keyof ObjectType];

export type WithDayjs<T = Record<string, any>> = {
  [K in keyof T]: T[K] extends Dayjs ? Dayjs : any;
};

export function filterByRange<T extends WithDayjs<T>>(
  range: DateRange,
  dataset: Array<T>,
  key: KeyOfType<T, Dayjs | null>,
): Array<T> {
  return dataset.filter((d) => isDateInRange(d[key], range));
}

export function getDataInRange<T extends WithDayjs<T>>(
  range: DateRange,
  dataset: Array<T>,
  key: KeyOfType<T, Dayjs | null>,
  granularity: OpUnitType = 'day',
): LineData {
  const emptyDates = getEmptyDates(range, granularity, 'YYYY-MM-DD');
  const dataByDay = dataset
    .filter((d) => isDateInRange(d[key], range))
    .reduce((memo, d) => {
      const date = d[key].startOf(granularity).format('YYYY-MM-DD');
      return { ...memo, [date]: (memo[date] ?? 0) + 1 };
    }, emptyDates);

  return toDataPoints(dataByDay);
}
