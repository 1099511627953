import gql from 'fraql';

import { ErrorType } from '~/types';
import { buildError, useMosaicQuery } from '~/utils';

import { Presentation, PresentationStatus } from '../../types';

export interface PresentationsQueryResult {
  presentations: Array<Presentation>;
}

export interface PresentationsQueryVariables {
  filter: {
    status?: PresentationStatus;
    presentationName?: string;
  };
}

const PresentationsQuery = gql`
  query PresentationsQuery($filter: PresentationFilterInput!) {
    presentations(filter: $filter) {
      id
      name
      slug
      status
      createdAt
      coverPicture {
        url
      }
      presentationUrl
      assignedUsers {
        id
        firstName
        lastName
        fullName @computed(type: User)
        email
      }
    }
  }
`;

export function useFetchPresentations(filters: { status?: PresentationStatus; name?: string }): {
  isLoading: boolean;
  presentations?: Array<Presentation>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<PresentationsQueryResult, PresentationsQueryVariables>({
    query: PresentationsQuery,
    variables: { filter: { ...filters, presentationName: filters.name } },
  });

  return {
    isLoading: res.isLoading,
    presentations: res.data?.presentations,
    error: buildError(res.error),
  };
}
