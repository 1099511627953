import React from 'react';
import { Route } from 'react-router-dom';

import { RoutesWithNotFound } from '~/components';

import { CompanyOverview } from './CompanyOverview';
import { Management } from './Management';
import { Meeting } from './Meeting';
import { Meetings } from './Meetings';
import { Presentations } from './Presentations';

export const MeetingsRoute = () => {
  return (
    <RoutesWithNotFound>
      <Route index element={<Meetings />} />
      <Route path="/:meetingId" element={<Meeting />} />
      <Route path="/management" element={<Management />} />
      <Route path="/presentations" element={<Presentations />} />
      <Route path="/company-overview" element={<CompanyOverview />} />
    </RoutesWithNotFound>
  );
};
