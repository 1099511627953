import {
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Grid,
  GridItem,
  Icon,
  Label,
  Margin,
  Padding,
  Shade,
  Size,
  Title,
  Tooltip,
} from '@drawbotics/react-drylus';
import React, { ReactElement } from 'react';

import { TractionBannerImage } from '~/pods/insights/images/TractionBannerImage';
import { createTranslate } from '~/utils';

const tt = createTranslate('pods.insights.routes.traction.banner');

interface BannerFiguresProps {
  label: string;
  tooltip: string;
  mainFigure: number | string;
  delta?: ReactElement;
}

const BannerFigures = ({ label, tooltip, mainFigure, delta }: BannerFiguresProps) => {
  return (
    <Flex direction={FlexDirection.VERTICAL} justify={FlexJustify.START}>
      <FlexItem>
        <Flex>
          <FlexItem>
            <Label>{label}</Label>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            <Tooltip content={tooltip}>
              <Icon shade={Shade.MEDIUM} name="info" />
            </Tooltip>
          </FlexItem>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Margin size={{ vertical: Size.SMALL }}>
          <Title style={{ margin: '0px', fontWeight: 400 }}>{mainFigure}</Title>
        </Margin>
      </FlexItem>
      {delta != null ? <FlexItem>{delta}</FlexItem> : null}
    </Flex>
  );
};

interface TractionBannerProps {
  visitors: number;
  visitorsDelta?: ReactElement;
  leads: number;
  leadsDelta?: ReactElement;
  conversionRate: number;
  conversionRateDelta?: ReactElement;
}

export const TractionBanner = ({
  visitors,
  visitorsDelta,
  leads,
  leadsDelta,
  conversionRate,
  conversionRateDelta,
}: TractionBannerProps) => {
  return (
    <Grid columns={4}>
      <GridItem style={{ alignSelf: 'start' }}>
        <Padding size={Size.SMALL}>
          <BannerFigures
            label={tt('visitors')}
            tooltip={tt('visitors_tooltip')}
            mainFigure={visitors}
            delta={visitorsDelta}
          />
        </Padding>
      </GridItem>
      <GridItem style={{ alignSelf: 'start' }}>
        <Padding size={Size.SMALL}>
          <BannerFigures
            label={tt('leads')}
            tooltip={tt('leads_tooltip')}
            mainFigure={leads}
            delta={leadsDelta}
          />
        </Padding>
      </GridItem>
      <GridItem style={{ alignSelf: 'start' }}>
        <Padding size={Size.SMALL}>
          <BannerFigures
            label={tt('traction_conversion_rate')}
            tooltip={tt('traction_conversion_rate_tooltip')}
            mainFigure={`${conversionRate === Infinity ? 0 : (conversionRate * 100).toFixed(2)}%`}
            delta={conversionRateDelta}
          />
        </Padding>
      </GridItem>
      <GridItem>
        <TractionBannerImage />
      </GridItem>
    </Grid>
  );
};
