import sv from '@drawbotics/drylus-style-vars';
import {
  ButtonLink,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Label,
  ListTile,
  Margin,
  Panel,
  PanelBody,
  RoundIcon,
  Size,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { memoize } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { DateRange, InsightsSession } from '~/pods/insights/types';
import { isDateInRange } from '~/pods/insights/utils';
import { createTranslate, useMosaicNavigation } from '~/utils';

import { CampaignsChart } from '../CampaignsChart';

const tt = createTranslate('pods.insights.routes.dashboard');

function _calcTotalVisitsData(range: DateRange, sessions: Array<InsightsSession>) {
  const totalVisits = sessions.filter((session) => isDateInRange(session.createdAt, range));
  const campaignsVisits = totalVisits.filter(
    (session) => session.campaignToken !== '' && session.campaignToken != null,
  );

  return {
    totalVisits: totalVisits.length,
    campaignsVisits: campaignsVisits.length,
  };
}

const _memoCalcTotalVisitsData = memoize(_calcTotalVisitsData, (range) => JSON.stringify(range));

interface MarketingCampaignCardProps {
  sessions: Array<InsightsSession>;
  range: DateRange;
}

export const MarketingCampaignCard = ({ range, sessions }: MarketingCampaignCardProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const { totalVisits, campaignsVisits } = _memoCalcTotalVisitsData(range, sessions);

  return (
    <Panel
      style={{ height: 340 }}
      body={
        <PanelBody style={{ position: 'relative' }}>
          <ListTile
            leading={
              <RoundIcon
                name="media-kit"
                size={50}
                style={{ backgroundColor: sv.purpleLighter, color: sv.purple }}
              />
            }
            title={<Label>{tt('marketing_campaigns')}</Label>}
          />
          <Margin size={{ vertical: Size.SMALL }}>
            <Flex direction={FlexDirection.VERTICAL}>
              <FlexItem style={{ width: '100%' }}>
                <ListTile
                  leading={
                    <Title size={1} noMargin>
                      {Math.round((totalVisits > 0 ? campaignsVisits / totalVisits : 0) * 100)}%
                    </Title>
                  }
                  title={
                    <Text size={Size.LARGE}>
                      {tt('campaigns_over', { visits: campaignsVisits })}
                    </Text>
                  }
                  subtitle={
                    <Text size={Size.LARGE}>{tt('total_visits', { visits: totalVisits })}</Text>
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <CampaignsChart total={totalVisits} campaigns={campaignsVisits} />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <Link to={getUrlInProject('/analytics/campaigns')}>
                  <ButtonLink tier={Tier.SECONDARY} size={Size.SMALL}>
                    {tt('see_detailed_view')}
                  </ButtonLink>
                </Link>
              </FlexItem>
            </Flex>
          </Margin>
        </PanelBody>
      }
    />
  );
};
