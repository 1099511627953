import sv, { lighten } from '@drawbotics/drylus-style-vars';
import randomColor from 'randomcolor';

import { HexColor } from '~/types';

interface ColorWithVariants {
  base: HexColor;
  light: HexColor;
  lighter: HexColor;
}
const BASE_COLORS: Array<ColorWithVariants> = [
  { base: sv.orange, light: sv.orangeLight, lighter: sv.orangeLighter },
  { base: sv.yellow, light: sv.yellowLight, lighter: sv.yellowLighter },
  { base: sv.green, light: sv.greenLight, lighter: sv.greenLighter },
  { base: sv.pink, light: sv.pinkLight, lighter: sv.pinkLighter },
  { base: sv.violet, light: sv.violetLight, lighter: sv.violetLighter },
  { base: sv.blue, light: sv.blueLight, lighter: sv.blueLighter },
  { base: sv.purple, light: sv.purpleLight, lighter: sv.purpleLighter },
  { base: sv.red, light: sv.redLight, lighter: sv.redLighter },
  { base: sv.brand, light: sv.brandLight, lighter: sv.brandLighter },
];

/**
 * This generator will first return our base product colors, then infinitely generate random ones.
 */
export function* colorGenerator(): Generator<ColorWithVariants, ColorWithVariants, undefined> {
  yield* BASE_COLORS;
  while (true) {
    const nextColor = randomColor();
    yield {
      base: nextColor,
      light: lighten(nextColor, 15),
      lighter: lighten(nextColor, 30),
    };
  }
}
