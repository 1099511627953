import sv from '@drawbotics/drylus-style-vars';
import { Flex, FlexItem, FlexSpacer, Size, Text } from '@drawbotics/react-drylus';
import { SliceTooltipProps } from '@nivo/line';
import dayjs from 'dayjs';
import React from 'react';

export const CustomTooltip = ({ slice }: SliceTooltipProps) => {
  const month = dayjs(slice.points[0].data.x).locale(window.i18n.locale).format('MMMM');
  return (
    <div
      style={{
        borderRadius: sv.defaultBorderRadius,
        padding: sv.paddingExtraSmall,
        background: sv.neutralDarkest,
      }}>
      <Text inversed size={Size.SMALL} style={{ textTransform: 'capitalize' }}>
        {month}
      </Text>
      {slice.points
        .sort((a, b) => Number(b.data.y) - Number(a.data.y))
        .map((point) => (
          <div key={point.id} style={{ marginTop: 4 }}>
            <Flex>
              <FlexItem>
                <div
                  style={{
                    height: 3,
                    width: 12,
                    borderRadius: 12,
                    backgroundColor: point.serieColor,
                  }}
                />
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem flex>
                <Text light inversed size={Size.SMALL}>
                  {point.serieId}
                </Text>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_LARGE} />
              <FlexItem>
                <Text light inversed size={Size.SMALL}>
                  {point.data.y}
                </Text>
              </FlexItem>
            </Flex>
          </div>
        ))}
    </div>
  );
};
