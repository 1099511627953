import {
  Button,
  Callout,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { run } from '@drawbotics/react-drylus/lib/utils';
import gql from 'fraql';
import React, { useEffect, useState } from 'react';

import { Invitation, User } from '~/pods/meetings/types';
import { useFetchTeammates } from '~/pods/meetings/utils';
import { Subscription } from '~/types';
import { createTranslate, useAuth, useMosaicMutation } from '~/utils';

import { isUser } from '../../utils';
import { AddPeople } from './AddPeople';
import { Recap } from './Recap';

const tt = createTranslate('pods.marketing_suite.routes.management.components.add_teammates_modal');

function _sortIdsByTeammateType(ids: Array<string>, teammates: Array<User | Invitation>) {
  return ids.reduce(
    (memo, id) => {
      const teammate = teammates.find((teammate) => teammate.id === id)!;
      if (isUser(teammate)) {
        return {
          ...memo,
          userIds: [...memo.userIds, id],
        };
      } else {
        return {
          ...memo,
          invitationIds: [...memo.invitationIds, id],
        };
      }
    },
    { userIds: [] as Array<string>, invitationIds: [] as Array<string> },
  );
}

interface AddTeammatesVariables {
  teammates: {
    userIds?: Array<string>;
  };
}

interface AddTeammateResult {
  teammates: Array<{ id: string }>;
}

const addTeammatesMutation = gql`
  mutation addTeammatesMutation($teammates: AddTeammatesInput!) {
    addTeammates(input: $teammates) {
      teammates {
        ... on User {
          id
        }
      }
    }
  }
`;

interface AddTeammatesModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  availableLicenseCount: number;
  refetchTeammates: VoidFunction;
  refetchLicenses: VoidFunction;
}

export const AddTeammatesModal = ({
  visible,
  onClickClose,
  availableLicenseCount,
  refetchTeammates,
  refetchLicenses,
}: AddTeammatesModalProps) => {
  const {
    teammates,
    error,
    refetch: refetchUnlicensedTeammates,
  } = useFetchTeammates({
    licensed: false,
  });
  const { executeMutation: addTeammates, res: addTeammatesResult } = useMosaicMutation<
    AddTeammateResult,
    AddTeammatesVariables
  >(addTeammatesMutation);
  const { showAlert } = useAlert();
  const [selectedUserIds, setSelectedUserIds] = useState<Array<string>>([]);
  const [page, setPage] = useState(1);
  const { user } = useAuth();

  useEffect(() => refetchUnlicensedTeammates(), [visible]);

  const licensesRemaining = availableLicenseCount - selectedUserIds.length;
  const limitedLiences = user?.organisation?.subscription === Subscription.REACH;

  const handleCloseModal = () => {
    onClickClose();

    setTimeout(() => {
      setSelectedUserIds([]);
      setPage(1);
    }, 300);
  };

  const handleAddTeammates = async () => {
    const sortedIds = _sortIdsByTeammateType(selectedUserIds, teammates!);
    const { error } = await addTeammates({ teammates: sortedIds });
    if (error != null) {
      showAlert({
        text: tt('something_went_wrong'),
        category: Category.DANGER,
      });
    } else {
      refetchTeammates();
      refetchLicenses();
      handleCloseModal();
    }
  };

  if (error != null) {
    showAlert({
      text: tt('something_went_wrong_fetching'),
      category: Category.DANGER,
    });
    onClickClose();
  }

  return (
    <Modal
      onClickClose={handleCloseModal}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            {page === 1 ? (
              <Button onClick={handleCloseModal} tier={Tier.TERTIARY}>
                {tt('cancel')}
              </Button>
            ) : (
              <Button onClick={() => setPage(1)} tier={Tier.TERTIARY}>
                {tt('back')}
              </Button>
            )}
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          <FlexItem>
            {page === 1 ? (
              <Button
                disabled={selectedUserIds.length === 0}
                onClick={() => setPage(2)}
                category={Category.INFO}>
                {tt('next')}
              </Button>
            ) : (
              <Button
                trailing={
                  addTeammatesResult.fetching ? <Spinner inversed size={Size.SMALL} /> : null
                }
                onClick={handleAddTeammates}
                disabled={addTeammatesResult.fetching}
                category={Category.INFO}>
                {tt('add_teammates')}
              </Button>
            )}
          </FlexItem>
        </Flex>
      }
      visible={visible}
      title={page === 1 ? tt('add_teammates') : tt('selection')}>
      {limitedLiences ? (
        <Callout category={licensesRemaining > 0 ? Category.INFO : Category.WARNING}>
          {tt('seats_available', { amount: licensesRemaining })}
        </Callout>
      ) : null}
      {run(() => {
        if (visible && page === 1) {
          return (
            <AddPeople
              disableAddingUsers={limitedLiences && licensesRemaining === 0}
              teammates={teammates}
              selectedUserIds={selectedUserIds}
              onRemoveUser={(id) => {
                setSelectedUserIds(selectedUserIds.filter((userId) => userId !== id));
              }}
              onSelectUser={(userId) => setSelectedUserIds([...selectedUserIds, userId])}
            />
          );
        } else if (visible && page === 2) {
          return (
            <Recap
              teammates={teammates!}
              userIds={selectedUserIds}
              handleAddTeammates={handleAddTeammates}
            />
          );
        }
      })}
    </Modal>
  );
};
