import { Flex, FlexItem, Padding, Size, Tier } from '@drawbotics/react-drylus';
import { run } from '~/utils';
import React from 'react';

import { Text } from './Text';

interface LineLabelProps {
  label: string;
  color: string;
  squared?: boolean;
  circular?: boolean;
}

export const LineLabel = ({ label, color, squared = false, circular = false }: LineLabelProps) => {
  return (
    <Flex>
      <FlexItem>
        {run(() => {
          if (squared || circular) {
            return (
              <div
                style={{
                  width: 10,
                  height: 10,
                  background: color,
                  borderRadius: circular ? 10 : undefined,
                }}
              />
            );
          } else {
            return <div style={{ width: 20, height: 3, background: color }} />;
          }
        })}
      </FlexItem>
      <FlexItem>
        <Padding size={{ horizontal: Size.EXTRA_SMALL }}>
          <Text light tier={Tier.TERTIARY}>
            {label}
          </Text>
        </Padding>
      </FlexItem>
    </Flex>
  );
};
