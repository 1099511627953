import { Size, Text, Tier } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import emptyStateChart from '../../../../images/placeholders/LineChartPlaceholder.svg';

const styles = {
  emptyChart: css`
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
};

interface EmptyChartProps {
  message?: string;
  small?: boolean;
}

export const EmptyChart = ({ message, small }: EmptyChartProps) => {
  return (
    <div className={styles.emptyChart}>
      <img
        style={{ height: small ? '30px' : '70px', paddingBottom: small ? '2px' : '12px' }}
        src={emptyStateChart}
      />
      <Text tier={Tier.TERTIARY} light size={Size.SMALL}>
        {message}
      </Text>
    </div>
  );
};
