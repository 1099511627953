import {
  Flex,
  FlexItem,
  FlexJustify,
  Padding,
  SegmentedControl,
  SegmentedControlItem,
  Size,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils';

import { TractionMetricEnum, TractionMetricType } from './VisitorsChart';

const tt = createTranslate('pods.insights.routes.traction.visitors_chart');

interface VisitorsTopRowProps {
  selectedMetric: TractionMetricType;
  onChangeSelectedMetric: (value: TractionMetricType) => void;
}

export const VisitorsTopRow = ({ selectedMetric, onChangeSelectedMetric }: VisitorsTopRowProps) => {
  return (
    <Flex justify={FlexJustify.END}>
      <FlexItem>
        <Padding size={{ right: Size.DEFAULT }}>
          <SegmentedControl>
            <SegmentedControlItem
              text={tt('visitors')}
              onClick={() => onChangeSelectedMetric(TractionMetricEnum.VISITORS)}
              active={selectedMetric === TractionMetricEnum.VISITORS}></SegmentedControlItem>
            <SegmentedControlItem
              text={tt('leads')}
              onClick={() => onChangeSelectedMetric(TractionMetricEnum.LEADS)}
              active={selectedMetric === TractionMetricEnum.LEADS}></SegmentedControlItem>
          </SegmentedControl>
        </Padding>
      </FlexItem>
    </Flex>
  );
};
