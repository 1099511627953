import MobileDetect from 'mobile-detect';
import React from 'react';

import { InsightsSession } from '~/pods/insights/types';
import { generateBarChartData } from '~/pods/insights/utils';
import { createTranslate } from '~/utils';

import { BarSizes, HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction');

enum DeviceTypes {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

function _getDeviceType(userAgentString: string): DeviceTypes {
  const detectedDevice = new MobileDetect(userAgentString);
  if (detectedDevice.mobile() == null) {
    return DeviceTypes.DESKTOP;
  } else {
    return detectedDevice.phone() == null ? DeviceTypes.TABLET : DeviceTypes.MOBILE;
  }
}

interface DevicesChartProps {
  sessions: Array<InsightsSession>;
}

export const DevicesChart = ({ sessions }: DevicesChartProps) => {
  const filtereData = sessions.filter((s) => s.userAgent != null && s.userAgent.string !== '');
  const deviceData = generateBarChartData(
    filtereData.map((s) => ({
      deviceType: tt(`device_types.${_getDeviceType(s.userAgent.string)}`),
    })),
    'deviceType',
  );

  return (
    <HorizontalTractionBarChart label={tt('devices')} data={deviceData} barSize={BarSizes.THIRD} />
  );
};
