import dayjs from 'dayjs';
import gql from 'fraql';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';

import { shouldUseShowcaseData, showcaseCampaignsData } from '~/pods/insights/showcase';
import { Campaign, CampaignFromServer, InsightsLead, InsightsSession } from '~/pods/insights/types';
import { urlWithToken } from '~/pods/insights/utils';
import { ErrorType } from '~/types';
import { buildError, useBackofficeQuery } from '~/utils';
import { colorGenerator } from '~/utils';

const CampaignsQuery = gql`
  query CampaignsQuery($projectId: ID!) {
    campaigns(projectId: $projectId) {
      launchedAt: createdAt
      name
      source
      destinationUrl
      token
    }
  }
`;

interface LoadCampaignVariables {
  projectId: string;
}

interface LoadCampaignsPayload {
  campaigns: Array<CampaignFromServer>;
}

function _processCampaigns(
  leads?: Array<InsightsLead>,
  sessions?: Array<InsightsSession>,
  campaignsFromServer?: Array<CampaignFromServer>,
): Array<Campaign> {
  if (campaignsFromServer == null || sessions == null || leads == null) {
    return [];
  }

  const leadsByCampaign = groupBy(leads, 'campaignToken');
  const sessionsByCampaign = groupBy(sessions, 'campaignToken');

  const colors = colorGenerator();

  return campaignsFromServer
    .sort((a, b) => (dayjs(a.launchedAt).isAfter(dayjs(b.launchedAt)) ? 1 : -1))
    .map((c) => {
      const leadsForCampaign = (leadsByCampaign[c.token] ?? []).map((lead) => lead.createdAt);
      const sessionsForCampaign = (sessionsByCampaign[c.token] ?? []).map(
        (session) => session.createdAt,
      );

      let conversionRate;
      if (sessionsForCampaign.length < leadsForCampaign.length) {
        conversionRate = 1;
      } else {
        conversionRate =
          sessionsForCampaign.length === 0
            ? 0
            : leadsForCampaign.length / sessionsForCampaign.length;
      }

      const destUrl = urlWithToken(c.destinationUrl, c.token);

      return {
        ...c,
        launchedAt: dayjs(c.launchedAt),
        destinationUrl: destUrl,
        leads: leadsForCampaign,
        leadsCount: leadsForCampaign.length,
        visits: sessionsForCampaign,
        visitsCount: sessionsForCampaign.length,
        conversionRate: conversionRate,
        hConversionRate: `${(conversionRate * 100).toFixed(2)}%`,
        color: colors.next().value.base,
      };
    });
}

export function useLoadCampaigns(
  leads?: Array<InsightsLead>,
  sessions?: Array<InsightsSession>,
  projectId?: string,
): {
  campaigns: Array<Campaign>;
  refetchCampaigns: Function;
  isLoading: boolean;
  error?: ErrorType;
} {
  if (shouldUseShowcaseData(projectId ?? ''))
    return {
      ...showcaseCampaignsData,
      campaigns: _processCampaigns(leads, sessions, showcaseCampaignsData.campaigns),
    };

  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const res = useBackofficeQuery<LoadCampaignsPayload, LoadCampaignVariables>({
    query: CampaignsQuery,
    variables: { projectId: projectId ?? '' },
    pause: projectId == null,
  });

  useEffect(() => {
    setCampaigns(_processCampaigns(leads, sessions, res.data?.campaigns));
    if ((res.data?.campaigns != null && leads != null && sessions != null) || res.error != null) {
      setIsLoading(false);
    }
  }, [res.data == null, res.error == null, leads?.length, sessions != null, res.isLoading]);

  return {
    campaigns,
    isLoading: isLoading,
    refetchCampaigns: () => res.refetch({ requestPolicy: 'network-only' }),
    error: buildError(res.error),
  };
}
