import { Align, CheckboxFilter } from '@drawbotics/react-drylus';
import React from 'react';

import { LogType } from '~/pods/crm/types';
import { createTranslate } from '~/utils';

interface LogsFilterProps {
  value?: Array<LogType>;
  onChange: (types: Array<LogType>) => void;
  onClear: VoidFunction;
}

const tt = createTranslate('pods.crm.routes.lead.components.log_types');

export const LogsFilter = ({ value, onChange, onClear }: LogsFilterProps) => {
  return (
    <CheckboxFilter
      style={{ whiteSpace: 'nowrap' }}
      align={Align.LEFT}
      label={tt('filter_button')}
      onChange={onChange}
      values={value}
      onClear={onClear}
      options={[
        {
          label: tt('status_change'),
          value: LogType.STATUS_CHANGE,
        },
        {
          label: tt('assignment_change'),
          value: LogType.LEAD_ASSIGNMENT,
        },
        {
          label: tt('first_assignment'),
          value: LogType.FIRST_LEAD_ASSIGNMENT,
        },
        {
          label: tt('phone_call'),
          value: LogType.PHONE_CALL,
        },
        {
          label: tt('email'),
          value: LogType.MAIL,
        },
        {
          label: tt('meeting'),
          value: LogType.MEETING,
        },
        {
          label: tt('note'),
          value: LogType.NOTE,
        },
        {
          label: tt('document'),
          value: LogType.DOCUMENT,
        },
      ]}
    />
  );
};
