import { Content, Margin, Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';

import { MosaicPageTitle } from '~/components';
import { createTranslate } from '~/utils';

import { PresentationsPanel } from './components';

const ta = createTranslate('pods.marketing_suite');

export const Presentations = () => {
  return (
    <Content fullHeight fullWidth style={{ overflow: 'auto' }}>
      <Padding size={{ bottom: Size.DEFAULT, left: Size.DEFAULT, right: Size.DEFAULT }}>
        <MosaicPageTitle>{ta('company_presentations')}</MosaicPageTitle>
        <Margin size={{ top: Size.DEFAULT }} />
        <PresentationsPanel />
      </Padding>
    </Content>
  );
};
