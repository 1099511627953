import sv, { neutralDark } from '@drawbotics/drylus-style-vars';
import {
  Avatar,
  ButtonLink,
  Color,
  Dropdown,
  DropdownOption,
  DropdownSeparator,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Icon,
  LoadingPlaceholder,
  Position,
  Separator,
  Size,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { upperFirst } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import logo from '~/images/mosaic-color.svg';
import {
  createTranslate,
  generateColorFromString,
  getHelpSeen,
  setHelpSeen,
  useAuth,
  useIntercom,
  useLoadAgentResources,
  useLogout,
  useMosaicNavigation,
} from '~/utils';

import { LangSwitcher } from './LangSwitcher';

const tt = createTranslate('components.navbar');

export const NAVBAR_WIDTH = 72;

const styles = {
  navbar: css`
    width: ${NAVBAR_WIDTH}px;
    height: 100%;
    background: ${sv.neutralDarkest};
    padding-bottom: ${sv.defaultPadding};
  `,
  // There's no "TopRight" position so we ask the dropdown to display itself on the right and shift it up
  avatarDropdownPositionFix: css`
    > div:nth-child(2) {
      transform: translateY(-150px);
    }
  `,
  orgLogo: css`
    /* Not a standard component so drylus vars didn't work here */
    --margin-h: 14px;
    --margin-v: 20px;

    background-color: ${neutralDark};
    width: calc(${NAVBAR_WIDTH}px - var(--margin-h) * 2);
    height: calc(${NAVBAR_WIDTH}px - var(--margin-h) * 2);
    margin: 0px var(--margin-h);
    margin-top: var(--margin-v);
    border-radius: ${sv.defaultBorderRadius};

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20pt;
      color: ${sv.white};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${sv.defaultBorderRadius};
    }
  `,
  activeOrg: css`
    outline: 4px solid ${sv.blue};
    outline-offset: 3px;
  `,
};

export const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const logout = useLogout();
  const { showIntercom } = useIntercom();
  const helpSeen = getHelpSeen();
  const { clientOrgs, isLoadingAgentResources } = useLoadAgentResources();
  const { getUrlToOrg, getHomepageUrl } = useMosaicNavigation();
  const { pathname } = useLocation();

  const avatarColor = user != null ? generateColorFromString(user.email) : undefined;
  const hasProfilePicture = user?.profilePicture != null;

  useEffect(() => {
    if (!helpSeen) {
      setTimeout(() => {
        setHelpSeen();
      }, 5000);
    }
  }, []);

  return (
    <div className={styles.navbar}>
      <Flex direction={FlexDirection.VERTICAL} style={{ height: '100%' }}>
        <FlexItem style={{ height: NAVBAR_WIDTH }}>
          <Link to={getHomepageUrl()}>
            <Flex style={{ height: '100%', width: '100%' }}>
              <FlexItem>
                <img src={logo} height="35" />
              </FlexItem>
            </Flex>
          </Link>
        </FlexItem>
        <FlexItem>
          <Separator style={{ width: `${NAVBAR_WIDTH}px`, opacity: 0.3 }} />
        </FlexItem>
        {isLoadingAgentResources
          ? [...Array(3).keys()].map((idx) => (
              <LoadingPlaceholder width="none" height="none" key={idx} />
            ))
          : clientOrgs.map((org) => (
              <Tooltip key={org.id} content={upperFirst(org.name)} side={Position.RIGHT}>
                <Link
                  onClick={() =>
                    localStorage.setItem(`mosaic-${user?.id}-last-visited-org`, org.publicToken)
                  }
                  to={getUrlToOrg(org.publicToken, 'crm')}>
                  <div
                    key={org.publicToken}
                    className={cx(styles.orgLogo, {
                      [styles.activeOrg]: pathname.includes(org.publicToken),
                    })}>
                    {org.logo?.url ? (
                      <img src={org.logo?.url} />
                    ) : (
                      <div>{org.name[0].toUpperCase()}</div>
                    )}
                  </div>
                </Link>
              </Tooltip>
            ))}
        <FlexSpacer flex />
        <FlexItem>
          <LangSwitcher />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        {process.env.APP_ENV === 'development' ? (
          <Fragment>
            <Link to="/dev">
              <ButtonLink size={Size.SMALL} leading={<Icon name="sliders" />} />
            </Link>
            <FlexSpacer size={Size.DEFAULT} />
          </Fragment>
        ) : null}
        <FlexItem>
          <Dropdown
            className={styles.avatarDropdownPositionFix}
            side={Position.RIGHT}
            trigger={
              <Avatar
                image={user?.profilePicture?.url}
                text={user?.firstName[0]}
                backgroundColor={hasProfilePicture ? undefined : avatarColor}
              />
            }>
            <DropdownOption
              text={tt('profile')}
              leading={<Icon name="user" color={Color.BLUE} />}
              onClick={() => navigate('/profile')}
            />
            {user?.organisation?.isAgency ? (
              <DropdownOption
                text={tt('my_agency')}
                leading={<Icon name="house" color={Color.BLUE} />}
                onClick={() => navigate('/agency')}
              />
            ) : null}
            <DropdownSeparator />
            <DropdownOption
              text={tt('terms')}
              onClick={() => window.open('https://www.trymosaic.com/terms-of-use', '_blank')}
            />
            <DropdownOption text={tt('help')} onClick={showIntercom} />
            <DropdownSeparator />
            <DropdownOption
              style={{ paddingRight: sv.paddingHuge }}
              text={tt('logout')}
              leading={<Icon name="log-out" color={Color.BLUE} />}
              onClick={logout}
            />
          </Dropdown>
        </FlexItem>
      </Flex>
    </div>
  );
};
