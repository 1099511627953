import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Color,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Grid,
  GridItem,
  Hint,
  Icon,
  Input,
  Label,
  ListTile,
  Margin,
  Modal,
  Paragraph,
  RoundIcon,
  Separator,
  Size,
  Spinner,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import copyToClipboard from 'copy-to-clipboard';
import { css } from 'emotion';
import React, { Fragment, useState } from 'react';

import { createTranslate, run } from '~/utils';

import { Website } from '../api/domain';
import { useCreateCustomDomain, useDeleteCustomDomain } from '../hooks';
import { CustomDomain, DomainStatus } from '../types';
import { DomainStatusInfo } from './DomainStatusInfo';

const tt = createTranslate('pods.website_builder.routes.website_builder');

const styles = {
  domainBox: css`
    padding: 4px;
    border-radius: ${sv.defaultMargin};
    background: ${sv.backgroundColor};
  `,
  customTable: css`
    background: ${sv.backgroundColor};
    padding: ${sv.paddingSmall} ${sv.paddingExtraLarge};
    border-radius: ${sv.defaultBorderRadius};
    text-align: center;
  `,
};

interface CustomDomainModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  customDomain?: CustomDomain;
  website: Website;
  onChange: (data: { customDomain?: Website['customDomain'] }) => void;
}

export const CustomDomainModal = ({
  website,
  onChange,
  visible,
  onClickClose,
  customDomain,
}: CustomDomainModalProps) => {
  const { createCustomDomain } = useCreateCustomDomain(website.id);
  const { deleteCustomDomain } = useDeleteCustomDomain(website.id);
  const [error, setError] = useState<string | undefined>();
  const [isHostnameValid, setIsHostnameValid] = useState<boolean>(false);
  const [tempCustomDomain, setTempCustomDomain] = useState<CustomDomain | undefined>(customDomain);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const domainStatus = tempCustomDomain?.status;
  const hostname = tempCustomDomain?.hostname;
  const form = useForm<{ hostname: string }>({ hostname: hostname });
  const { showAlert } = useAlert();

  const handleCreateCustomDomain = async (hostname: string) => {
    setIsLoading(true);

    const { data, error } = await createCustomDomain(hostname);

    if (error != null) {
      if (error.graphQLErrors[0].message.match(/currently in use/)) {
        setError(tt('domain_in_use'));
      } else {
        showAlert({
          text: tt('domain_not_updated'),
          category: Category.DANGER,
        });
      }
    } else {
      const createdCustomDomain: CustomDomain = data.createCustomDomain?.customDomain;
      setTempCustomDomain(createdCustomDomain);
      onChange({ customDomain: createdCustomDomain });
    }

    setIsLoading(false);
  };

  const handleDeleteCustomDomain = async () => {
    setIsLoading(true);

    const { error } = await deleteCustomDomain();

    if (error != null) {
      showAlert({
        text: tt('domain_not_deleted'),
        category: Category.DANGER,
      });
    }

    onChange({ customDomain: undefined });
    setIsLoading(false);
    onClickClose();
  };

  const handleClose = () => {
    onClickClose();
  };

  const checkIsHostnameValid = (hostname: string) => {
    setIsHostnameValid(
      hostname?.match(
        /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
      ) != null,
    );
  };

  const handleFormChange = (value: string | number) => {
    checkIsHostnameValid(value.toString());
    setIsFormTouched(true);
    form.set(value, 'hostname');
    setError(undefined);
  };

  return (
    <Modal
      style={{ width: 600 }}
      title={run(() => {
        if (deleteMode) {
          return tt('modal_delete_domain');
        } else if (hostname == null) {
          return tt('modal_add_domain');
        } else {
          return tt('modal_your_domain');
        }
      })}
      onClickClose={handleClose}
      visible={visible}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={handleClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            {run(() => {
              if (deleteMode) {
                return (
                  <Button
                    disabled={isLoading}
                    trailing={isLoading ? <Spinner /> : null}
                    category={Category.DANGER}
                    onClick={handleDeleteCustomDomain}>
                    {tt('domain_confirm_delete')}
                  </Button>
                );
              } else if (hostname == null) {
                return (
                  <Button
                    category={Category.BRAND}
                    disabled={!isHostnameValid || isLoading}
                    trailing={isLoading ? <Spinner /> : null}
                    onClick={() => handleCreateCustomDomain(form.get('hostname'))}>
                    {tt('domain_confirm_add')}
                  </Button>
                );
              } else if (domainStatus !== DomainStatus.VERIFIED) {
                return (
                  <Button category={Category.BRAND} onClick={handleClose}>
                    {tt('domain_modal_acknowledge')}
                  </Button>
                );
              } else {
                return (
                  <Button category={Category.BRAND} onClick={handleClose}>
                    {tt('domain_ok')}
                  </Button>
                );
              }
            })}
          </FlexItem>
        </Flex>
      }>
      {run(() => {
        if (deleteMode) {
          return <Paragraph>{tt('domain_delete_check')}</Paragraph>;
        }
        if (hostname == null) {
          return (
            <Fragment>
              <Paragraph>{tt('domain_add_desc')}</Paragraph>
              <FormGroup
                label={<Label>{tt('domain_input_label')}</Label>}
                input={
                  <Input
                    error={run(() => {
                      if (error) {
                        return error;
                      } else if (!isHostnameValid && isFormTouched) {
                        return tt('enter_valid_domain');
                      }
                    })}
                    name="hostname"
                    placeholder="mydomain.com"
                    onChange={handleFormChange}
                    value={form.get}
                  />
                }
              />
            </Fragment>
          );
        }
        return (
          <Fragment>
            <div className={styles.domainBox}>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Margin style={{ display: 'flex' }} size={{ left: Size.EXTRA_SMALL }}>
                    <ListTile leading={<Icon name="monitor" />} title={hostname} />
                  </Margin>
                </FlexItem>
                <FlexItem>
                  <ListTile
                    leading={
                      domainStatus === DomainStatus.VERIFIED ? (
                        <RoundIcon inversed name="check" size={Size.SMALL} color={Color.GREEN} />
                      ) : null
                    }
                    trailing={
                      <Button
                        onClick={() => setDeleteMode(true)}
                        size={Size.SMALL}
                        leading={<Icon name="trash" />}
                        tier={Tier.SECONDARY}
                      />
                    }
                    title={
                      tempCustomDomain?.status != null && (
                        <DomainStatusInfo status={tempCustomDomain.status} />
                      )
                    }
                  />
                </FlexItem>
              </Flex>
            </div>
            <Fragment>
              <Paragraph>{tt('domain_settings_instruction')}</Paragraph>
              <div className={styles.customTable}>
                <Grid columns={12} hGutters={Size.SMALL} vGutters={Size.SMALL}>
                  <GridItem span={2}>
                    <Label>{tt('domain_settings_type')}</Label>
                  </GridItem>
                  <GridItem span={6}>
                    <Label>{tt('domain_settings_name')} </Label>
                  </GridItem>
                  <GridItem span={4}>
                    <Label>{tt('domain_settings_value')} </Label>
                  </GridItem>
                  <GridItem span={12}>
                    <Separator style={{ background: sv.neutral }} />
                  </GridItem>
                  <GridItem span={2}>{tt('domain_settings_cname')}</GridItem>
                  <GridItem span={6}>{tempCustomDomain?.hostname}</GridItem>
                  <GridItem span={4}>
                    <Flex align={FlexAlign.CENTER}>
                      <FlexItem>{tempCustomDomain?.cname.slice(0, 10).concat('...')}</FlexItem>
                      <FlexItem>
                        <Icon
                          color={copied ? Color.GREEN : Color.BLUE}
                          name="copy"
                          onClick={() => {
                            if (tempCustomDomain?.cname != null) {
                              setCopied(true);
                              copyToClipboard(tempCustomDomain.cname);
                            }
                          }}
                        />
                      </FlexItem>
                    </Flex>
                  </GridItem>
                </Grid>
              </div>
              <Hint>{tt('domain_settings_hint')}</Hint>
            </Fragment>
          </Fragment>
        );
      })}
    </Modal>
  );
};
