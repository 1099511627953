import { Category, Flex, FlexItem, InlineEdit, Input, useAlert } from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import React from 'react';

import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { AnyWebsiteSectionData, MainSection } from '../../../api/domain';
import { LoremIpsum } from '../../../utils';
import { Themed } from '../Themed';

interface MainTitleProps {
  language: LocaleCode;
  main: MainSection;
  onChange: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
}

export const MainTitle = ({ main, onChange, language }: MainTitleProps) => {
  const form = useForm({ title: main.title ?? '' });
  const { showAlert } = useAlert();

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  const _handleTextLengthError = () => {
    showAlert({
      text: tl('update_error'),
      category: Category.DANGER,
    });
  };

  const _handleUpdateSection = (sectionId: ID, data: Pick<MainSection, 'title'>) => {
    if (data.title != null) {
      data.title.length <= 200 ? onChange(sectionId, data) : _handleTextLengthError();
    }
  };

  return (
    <Flex>
      <FlexItem style={{ width: '60%', textAlign: 'center' }}>
        <InlineEdit
          onCancel={() => form.set(main.title ?? '', 'title')}
          onClickConfirm={() => _handleUpdateSection(main.id, { title: form.get('title') })}
          edit={
            <Input
              style={{ fontSize: '1rem' }}
              name="mainTitle"
              placeholder={tl('title_placeholder')}
              value={form.get('title')}
              onChange={(v) => form.set(v.toString(), 'title')}
              error={form.get('title').length > 200 && tl('max_200_characters_error')}
            />
          }>
          <Themed.Title
            size={2}
            style={{ opacity: main.title != null && main.title !== '' ? '1' : '0.5' }}>
            {main.title != null && main.title !== '' ? main.title : LoremIpsum.SHORT}
          </Themed.Title>
        </InlineEdit>
      </FlexItem>
    </Flex>
  );
};
