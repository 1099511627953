import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  InlineEdit,
  Input,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  Position,
  Size,
  Spinner,
  Text,
  Tier,
  Title,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import arrayMove from 'array-move';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';

import {
  CategorySlug,
  Category as CategoryType,
  CategoryType as CategoryTypeEnum,
  MinimalSection,
  Section as SectionType,
} from '~/pods/presentation/types';
import { useFetchSection } from '~/pods/presentation/utils';
import { OrganisationRole } from '~/types';
import { createTranslate, run, translate as t, useAuth, useMosaicMutation } from '~/utils';

import { CategoryEditor } from './CategoryEditor';
import { CategorySelector, CustomCategory } from './CategorySelector';
import { DeleteSectionModal } from './DeleteSectionModal';

const tt = createTranslate('pods.presentation.routes.presentation.components.section');

const disabledBackground = 'rgb(239, 242, 245)';

const styles = {
  section: css`
    position: relative;
    z-index: 2;
  `,
  flap: css`
    position: absolute;
    top: ${sv.marginSmall};
    right: calc(${sv.defaultMargin} * -1);
    background: ${sv.white};
    padding: 12px 3px;
    padding-left: ${sv.paddingExtraSmall};
    border-bottom-right-radius: ${sv.borderRadiusLarge};
    border-top-right-radius: ${sv.borderRadiusLarge};
    box-shadow: ${sv.elevation1};
    color: ${sv.colorDisabled};
    z-index: 1;
    transition: all ${sv.transitionTimeShort} ${sv.bouncyTransitionCurve};

    > i {
      margin-top: 1px;
    }

    &:hover {
      cursor: pointer;
      color: ${sv.colorTertiary};
      right: calc((${sv.defaultMargin} + 4px) * -1);
    }
  `,
  overlay: css`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: calc(100% - 50px);
    top: 50px;
    background: ${disabledBackground};
    opacity: 0.5;
    border-radius: ${sv.defaultBorderRadius};
  `,
  headerOverlay: css`
    position: absolute;
    z-index: 99;
    width: calc(100% - 50px);
    height: 50px;
    background: ${disabledBackground};
    opacity: 0.5;
    border-radius: ${sv.defaultBorderRadius};
  `,
};

interface UpdateSectionVariables {
  hidden?: boolean;
  name?: string;
  id: string;
}

interface UpdateSectionResult {
  updateSection: {
    section: SectionType;
  };
}

const updateSectionMudation = gql`
  mutation updateSection($hidden: Boolean, $id: ID!, $name: String) {
    updateSection(input: { hidden: $hidden, id: $id, name: $name }) {
      section {
        id
        name
        hidden
      }
    }
  }
`;

interface CreateCategoryVariables {
  category: {
    sectionId: string;
    name?: string;
    slug?: string;
    categoryType?: CategoryTypeEnum;
  };
}

interface CreateCategoryResult {
  createCategory: {
    category: CategoryType;
  };
}

const createCategoryMutation = gql`
  mutation createCategory($category: CreateCategoryInput!) {
    createCategory(input: $category) {
      category {
        id
        slug
      }
    }
  }
`;

interface UpdateCategoryVariables {
  position: number;
  id: string;
}

interface UpdateCategoryResult {
  updateCategory: {
    category: CategoryType;
  };
}

const updateCategoryMutation = gql`
  mutation updateCategory($position: Int, $id: ID!) {
    updateCategory(input: { position: $position, id: $id }) {
      category {
        id
        position
      }
    }
  }
`;

function _isCustomCategory(category: CustomCategory | CategorySlug): category is CustomCategory {
  return typeof category !== 'string';
}

interface SectionProps {
  section: MinimalSection;
  onClickDelete: VoidFunction;
}

export const Section = ({ section: minimalSection, onClickDelete }: SectionProps) => {
  const [categories, setCategories] = useState<Array<CategoryType>>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const {
    isLoading,
    section: loadedSection,
    refetch: refetchSection,
    error: sectionError,
  } = useFetchSection(minimalSection.id);
  const loadedCategories = loadedSection?.categories;
  const section: MinimalSection | SectionType = loadedSection ?? minimalSection;
  const [sectionName, setSectionName] = useState(section.name ?? '');
  const { showAlert } = useAlert();

  const isCustomSection = section.slug == null && section.name != null;

  const { executeMutation: updateSection, res: updateSectionResult } = useMosaicMutation<
    UpdateSectionResult,
    UpdateSectionVariables
  >(updateSectionMudation);

  const { executeMutation: createCategory, res: createCategoryResult } = useMosaicMutation<
    CreateCategoryResult,
    CreateCategoryVariables
  >(createCategoryMutation);

  const { executeMutation: updateCategory, res: updateCategoryResult } = useMosaicMutation<
    UpdateCategoryResult,
    UpdateCategoryVariables
  >(updateCategoryMutation);

  const { user } = useAuth();
  const isUserAdmin = user?.role === OrganisationRole.ADMIN;

  const handleMoveCategory = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (categories != null) {
      const sortedCategories = arrayMove(categories, oldIndex, newIndex);
      const updatedCategories = sortedCategories.map((category, i) => ({
        ...category,
        position: i + 1,
      }));
      setCategories(updatedCategories);
      updateCategory({ id: updatedCategories[newIndex].id, position: newIndex + 1 });
    }
  };

  const handleCreateCategory = async (category: CategorySlug | CustomCategory) => {
    if (_isCustomCategory(category)) {
      const { error } = await createCategory({ category: { sectionId: section.id, ...category } });
      if (error != null) {
        showAlert({
          text: tt('could_not_create_custom', { name: category.name }),
          category: Category.DANGER,
        });
      }
    } else {
      const { error } = await createCategory({
        category: { sectionId: section.id, slug: category },
      });
      if (error != null) {
        showAlert({
          text: tt('could_not_create_custom', {
            name: t(`pods.presentation.categories.${category}`),
          }),
          category: Category.DANGER,
        });
      }
    }
    refetchSection();
  };

  useEffect(() => {
    if (loadedCategories != null) {
      setCategories(loadedCategories);
    }
  }, [isLoading, loadedCategories?.length]);

  useEffect(() => {
    if (section.name != null) {
      setSectionName(section.name);
    }
  }, [section.name]);

  useEffect(() => {
    if (!updateCategoryResult.fetching && updateCategoryResult.error != null) {
      showAlert({
        text: tt('category_update_error'),
        category: Category.DANGER,
      });
    }
    if (!updateSectionResult.fetching && updateSectionResult.error != null) {
      showAlert({
        text: tt('section_update_error'),
        category: Category.DANGER,
      });
    }
  }, [updateCategoryResult.fetching, updateSectionResult.fetching]);

  return (
    <div className={styles.section}>
      {section.hidden ? (
        <Fragment>
          <div className={styles.overlay} />
          <div className={styles.headerOverlay} />
        </Fragment>
      ) : null}
      <Panel
        style={{
          position: 'relative',
          zIndex: 2,
          background: section.hidden ? disabledBackground : undefined,
        }}
        header={
          <PanelHeader>
            <Flex>
              <FlexItem flex>
                {isCustomSection && isUserAdmin ? (
                  <InlineEdit
                    edit={<Input value={sectionName} onChange={(v) => setSectionName(String(v))} />}
                    onClickConfirm={async () => {
                      if (sectionName !== '') {
                        const res = await updateSection({
                          id: section.id,
                          name: sectionName,
                        });
                        if (res.error != null) {
                          setSectionName(res.data?.updateSection.section.name!);
                        }
                      }
                    }}
                    onCancel={() => setSectionName(section.name!)}>
                    <Title noMargin style={{ color: sv.colorTertiary }} size={4}>
                      {sectionName}
                    </Title>
                  </InlineEdit>
                ) : (
                  <Title noMargin style={{ color: sv.colorTertiary }} size={4}>
                    # {t(`pods.presentation.sections.${section.slug}`)}
                  </Title>
                )}
              </FlexItem>
              <FlexSpacer size={Size.LARGE} />
              {section.slug == null && isUserAdmin ? (
                <FlexItem>
                  <Margin size={{ right: Size.EXTRA_SMALL }}>
                    <Button
                      tier={Tier.TERTIARY}
                      size={Size.SMALL}
                      category={Category.DANGER}
                      onClick={() => setDeleteModalVisible(true)}
                      style={{ margin: '-5px 0' }}>
                      Remove
                    </Button>
                  </Margin>
                </FlexItem>
              ) : null}
              <FlexItem>
                {updateSectionResult.fetching ? (
                  <Spinner size={Size.SMALL} />
                ) : (
                  <Tooltip
                    content={section.hidden ? tt('hidden_section') : tt('visible_section')}
                    side={Position.LEFT}>
                    <div
                      onClick={
                        isUserAdmin
                          ? () => updateSection({ id: section.id, hidden: !section.hidden })
                          : undefined
                      }
                      style={
                        isUserAdmin
                          ? { cursor: 'pointer', color: sv.colorPrimary }
                          : { pointerEvents: 'none', cursor: 'not-allowed', color: sv.colorPrimary }
                      }>
                      <Icon name={section.hidden ? 'eye-off' : 'eye'} />
                    </div>
                  </Tooltip>
                )}
              </FlexItem>
            </Flex>
          </PanelHeader>
        }
        body={
          <PanelBody noPadding>
            {run(() => {
              if (categories == null || categories.length === 0) {
                if (isLoading) {
                  return (
                    <Padding>
                      <Spinner fullSize />
                    </Padding>
                  );
                }
                if (sectionError != null) {
                  return (
                    <Padding size={{ horizontal: Size.DEFAULT, vertical: Size.SMALL }}>
                      <Text category={Category.DANGER}>{tt('could_not_load')}</Text>
                    </Padding>
                  );
                } else {
                  return <div style={{ marginTop: -1 }}></div>;
                }
              } else {
                return (
                  <FlipMove
                    duration={300}
                    easing={sv.bouncyTransitionCurve}
                    leaveAnimation="none"
                    enterAnimation="none">
                    {categories
                      .sort((a, b) => a.position - b.position)
                      .map((category, i) => (
                        <CategoryEditor
                          key={category.id}
                          withSeparator={i !== 0}
                          category={category}
                          reloadCategory={refetchSection}
                          onClickMoveDown={
                            i !== categories.length - 1
                              ? () => handleMoveCategory({ oldIndex: i, newIndex: i + 1 })
                              : undefined
                          }
                          onClickMoveUp={
                            i !== 0
                              ? () => handleMoveCategory({ oldIndex: i, newIndex: i - 1 })
                              : undefined
                          }
                        />
                      ))}
                  </FlipMove>
                );
              }
            })}
          </PanelBody>
        }
        footer={
          loadedSection == null ? undefined : (
            <PanelFooter>
              <CategorySelector
                isLoading={createCategoryResult.fetching}
                onAddCategory={handleCreateCategory}
                categorySlugs={loadedSection.possibleCategories}
              />
            </PanelFooter>
          )
        }
      />
      <DeleteSectionModal
        visible={deleteModalVisible}
        onClickClose={() => setDeleteModalVisible(false)}
        onClickConfirm={() => {
          setDeleteModalVisible(false);
          setTimeout(() => {
            onClickDelete();
          }, 350);
        }}
      />
    </div>
  );
};
