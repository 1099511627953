import { CheckboxFilter } from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import { uniqBy } from 'lodash';
import React from 'react';

import { LeadsFilterTypes, User } from '~/pods/crm/types';
import { Project } from '~/pods/settings/types/project';
import { createTranslate, useAuth } from '~/utils';

import { filterStyles } from './filter-styles';

const tt = createTranslate('pods.crm.routes.leads.components.leads_filter_bar.filters');

const styles = {
  clipText: css`
    [data-element='trigger'] [data-element='label'] {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
    }
  `,
};

interface AssignedToFilterProps {
  users: Array<User>;
  projects: Array<Project>;
  membersFilter: LeadsFilterTypes['assignedTo'];
  organisationsFilter: LeadsFilterTypes['organisations'];
}

export const AssignedToFilter = ({
  users,
  projects,
  membersFilter,
  organisationsFilter,
}: AssignedToFilterProps) => {
  const { user } = useAuth();

  const memberOptions = users
    .map((user) => ({
      value: `member-${user.id}`,
      label: user.fullName,
    }))
    .sort(function (a, b) {
      const lowerCasedA = a.label.toLowerCase();
      const lowerCasedB = b.label.toLowerCase();

      if (lowerCasedA < lowerCasedB) {
        return -1;
      }
      if (lowerCasedA > lowerCasedB) {
        return 1;
      }
      return 0;
    });

  let agencyOptions: Array<{ label: string; value: string }> = [];

  for (const project of projects) {
    agencyOptions = agencyOptions.concat(
      project.assignedAgencies.map((a) => ({ label: a.name, value: `agency-${a.id}` })),
    );
  }

  agencyOptions = uniqBy(agencyOptions, (a) => a.value);

  const agenciesValues: string[] = organisationsFilter.values
    ? organisationsFilter.values.map((v) => `agency-${v}`)
    : [];

  const membersValues: string[] = membersFilter.values
    ? membersFilter.values.map((v) => `member-${v}`)
    : [];

  const values = [...agenciesValues, ...membersValues];

  return (
    <CheckboxFilter
      label={tt('titles.assigned_to')}
      className={cx(filterStyles.filter, styles.clipText)}
      options={[
        ...(user?.organisation?.isAgency
          ? []
          : [
              { delimiter: true, label: tt('assigned_to.agencies') } as const,
              ...agencyOptions,

              { delimiter: true, label: tt('assigned_to.company_members') } as const,
            ]),

        ...memberOptions,
      ]}
      values={values}
      enableSearch
      searchPlaceholder={tt('assigned_to.search')}
      onChange={(values) => {
        const aValues = values
          .filter((v) => v.startsWith('agency-'))
          .map((v) => v.replace('agency-', ''));
        const mValues = values
          .filter((v) => v.startsWith('member-'))
          .map((v) => v.replace('member-', ''));

        organisationsFilter.set(aValues);
        membersFilter.set(mValues);
      }}
      onClear={() => {
        organisationsFilter.set(null);
        membersFilter.set(null);
      }}
    />
  );
};
