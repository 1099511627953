import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  Category,
  Dot,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Label,
  Map,
  Margin,
  Modal,
  SearchInput,
  Size,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@tokamakjs/common';
import React, { useEffect, useState } from 'react';

import { createTranslate } from '~/utils';
import { GeocoderResult, useGeocoder } from '~/utils/hooks';

import { Website } from '../../api/domain';

const tt = createTranslate('pods.website_builder.routes.website_builder');

interface AddressInputModalProps {
  isVisible: boolean;
  website: Website;
  onClickClose: VoidFunction;
  onChange: (data: Partial<Website>) => void;
}

export const AddressInputModal = ({
  website,
  isVisible,
  onClickClose,
  onChange,
}: AddressInputModalProps) => {
  const [suggestions, setSuggestions] = useState<Array<GeocoderResult>>([]);
  const { query } = useGeocoder();
  const form = useForm<{ address?: string; latitude?: number; longitude?: number }>({
    address: website.address,
    latitude: website.latitude,
    longitude: website.longitude,
  });

  useEffect(() => {
    const getSuggestions = async () => {
      if (form.values.address != null) {
        const suggestions = await query(form.values.address);
        setSuggestions(suggestions);
      }
    };

    getSuggestions();
  }, [form.values.address]);

  const handleSelectAddress = (id: string) => {
    const newAddress = suggestions.find((addr) => addr.id === id);

    form.set(newAddress?.fullAddress, 'address');
    form.set(newAddress?.coordinates?.lat, 'latitude');
    form.set(newAddress?.coordinates?.lng, 'longitude');
  };

  const handleSave = () => {
    onChange({
      address: form.values.address,
      latitude: form.values.latitude,
      longitude: form.values.longitude,
    });
    onClickClose();
  };

  const handleCancel = () => {
    onClickClose();
  };

  const searchOptions = suggestions.map((suggestion) => ({
    label: suggestion.fullAddress,
    value: suggestion.id,
  }));

  return (
    <Modal
      visible={isVisible}
      onClickClose={handleCancel}
      title={tt('link_location')}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={handleCancel} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button category={Category.INFO} onClick={handleSave}>
              {tt('save')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <div style={{ width: 500 }}>
        <FormGroup
          input={
            <SearchInput
              placeholder={tt('search_placeholder')}
              name="address"
              value={form.values.address ?? ''}
              onChange={form.set}
              onClickResult={handleSelectAddress}
              options={searchOptions}
            />
          }
          label={<Label>{tt('project_address')}</Label>}
        />
        <Margin size={Size.DEFAULT} />
        {form.values.latitude != null && form.values.longitude != null ? (
          <Map
            minZoom={13}
            maxZoom={13}
            accessToken={process.env.MAPBOX_ACCESS_TOKEN ?? ''}
            markers={[
              {
                label: <Dot color={sv.white} />,
                coordinates: {
                  lat: Number(form.values.latitude),
                  lng: Number(form.values.longitude),
                },
              },
            ]}
          />
        ) : null}
      </div>
    </Modal>
  );
};
