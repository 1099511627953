import SparkMD5 from 'spark-md5';

export function sanitizeFilename(filename: string) {
  return filename.replace(/[^a-z0-9.]/gi, '_');
}

function _readAsArrayBuffer(file: Blob): Promise<Buffer> {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (e: ProgressEvent<any>) => {
      resolve(e.target.result);
    };
    reader.readAsArrayBuffer(file);
  });
}

export function readAsDataURL(file: Blob): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (e: ProgressEvent<any>) => {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });
}

export async function getFileChecksum(file: File) {
  const CHUNK_SIZE = 2097152; // Read in chunks of 2MB
  const md5 = new SparkMD5.ArrayBuffer();

  let start = 0;
  while (start < file.size) {
    const end = start + CHUNK_SIZE >= file.size ? file.size : start + CHUNK_SIZE;
    const fileBuffer = await _readAsArrayBuffer(file.slice(start, end));
    md5.append(fileBuffer);
    start = end;
  }

  return md5.end(true);
}

export interface Headers {
  [key: string]: string;
}

export async function request(
  method: string,
  url: string,
  data: any,
  extra: { onProgress?: (e: ProgressEvent) => void; headers?: Headers } = {},
) {
  const { headers = {}, onProgress } = extra;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);

    if (headers != null) {
      Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
    }

    xhr.addEventListener('load', (e: ProgressEvent<any>) => {
      let data = null;
      try {
        data = JSON.parse(e.target?.responseText);
      } catch (err) {
        data = e.target.responseText;
      }
      return resolve(data);
    });
    xhr.addEventListener('error', (err) => reject(err));
    xhr.upload.addEventListener('progress', onProgress as any);

    if (headers['Content-Type'] === 'application/json') {
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send(data);
    }
  });
}

export function progressToPercentage(progress: ProgressEvent): number {
  return progress.loaded / progress.total;
}
