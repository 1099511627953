import { Portal } from '~/pods/multidiffusion/domain';
import { Currency, HexColor, ID } from '~/types';

import { Lead, Project, User } from './';

export enum Requirement {
  ASSIGNATION = 'ASSIGNATION',
  OFFER = 'OFFER',
}

export enum StepType {
  INITIAL = 'INITIAL_STEP',
  ASSIGNED = 'ASSIGNED_LEAD_STEP',
  LOST = 'LOST_LEAD_STEP',
  OFFER = 'OFFER_STEP',
}

export interface Organisation {
  id: ID;
  name?: string;
  currency: Currency;
  steps?: Array<Step>;
  projects: Array<Project>;
  portals: Array<Portal>;
  users?: Array<User>;
  leads?: Array<Lead>;
  isAgency: boolean;
  logo?: {
    url: string;
  };
}

export interface Step {
  id: ID;
  name: string;
  slug: ID;
  organisation: Organisation;
  leads: Array<Lead>;
  requirements?: Array<Requirement>;
  color: HexColor;
  position: number;
  type?: StepType;
}
