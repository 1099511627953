import gql from 'fraql';

import { Unit } from '~/pods/crm/types';
import { ErrorType } from '~/types';
import { buildError } from '~/utils';
import { useMosaicQuery } from '~/utils/hooks';

const ListingQuery = gql`
  query ListingQuery($publicToken: ID!) {
    listing(publicToken: $publicToken) {
      units {
        id
        reference
      }
    }
  }
`;

export function useFetchBasicListing(publicToken: string): {
  isLoading: boolean;
  units?: Array<Unit>;
  error?: ErrorType;
} {
  const res = useMosaicQuery<{ listing: { units: Array<Unit> } }, { publicToken: string }>({
    query: ListingQuery,
    variables: { publicToken },
  });

  return {
    error: buildError(res.error),
    isLoading: res.isLoading,
    units: res.data?.listing.units ?? [],
  };
}
