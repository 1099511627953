import { useTheme } from '@emotion/react';
import { css } from 'emotion';
import React from 'react';

import { ThemeDefinition } from '../../../types';
import { BaseFooter, BaseFooterProps } from '../BaseFooter';

const styles = {
  footer: (theme: ThemeDefinition) => css`
    background-color: ${theme.colors.neutral2};
  `,
};

interface FooterProps extends BaseFooterProps {}

export const Footer = (props: FooterProps) => {
  const theme = useTheme<ThemeDefinition>();
  return (
    <div className={styles.footer(theme)}>
      <BaseFooter {...props} />
    </div>
  );
};
