import sv from '@drawbotics/drylus-style-vars';
import {
  BigCheckbox,
  Button,
  Category,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  ListTile,
  Margin,
  Modal,
  Paragraph,
  Shade,
  Size,
  Spinner,
  Text,
  Tier,
  Toggle,
  useAlert,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment, useEffect, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { gql } from 'urql';

import { createTranslate, useMosaicMutation } from '~/utils';

import googleCalendar from '../images/google-calendar.svg';
import microsoftCalendar from '../images/microsoft-calendar.svg';
import { CalendarProvider } from '../types';
import { useCalendarSync, useFetchAvailabilities } from '../utils';

const tt = createTranslate('pods.marketing_suite.routes.availabilities');

const styles = {
  title: css`
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: ${sv.marginSmall};
  `,
};

function _getLinkCalendarLabel(calendarStatus: CalendarProvider | undefined) {
  switch (calendarStatus) {
    case CalendarProvider.GOOGLE:
      return tt('synced_calendar', { provider: 'Google' });
    case CalendarProvider.MICROSOFT:
      return tt('synced_calendar', { provider: 'Microsoft' });
    default:
      return tt('link_calendar');
  }
}

const unlinkCalendarMutation = gql`
  mutation unlinkCalendar {
    unlinkCalendar(input: {}) {
      syncrCalendar
    }
  }
`;

export function SSOCalendarLink() {
  const { showAlert } = useAlert();
  const { googleCalendarSync, microsoftCalendarSync } = useCalendarSync();

  const [showSsoModal, setShowSsoModal] = useState(false);
  const [showUnlinkConfirmationModal, setUnlinkConfirmationModal] = useState(false);

  const [selectedSso, setSelectedSso] = useState<'microsoft' | 'google'>('google');

  const [isLinkingCalendar, setIsLinkingCalendar] = useState(false);
  const {
    isLoading: isLoadingAvailabilities,
    calendarStatus,
    refetchCalendarStatus,
    error,
  } = useFetchAvailabilities();

  const [isToggleTrue, setIsToggleTrue] = useState(!!calendarStatus);

  useEffect(() => {
    if (isToggleTrue !== !!calendarStatus) setIsToggleTrue(!!calendarStatus);
  }, [calendarStatus]);

  const {
    res: { fetching: isUnlinkingCalendar },
    executeMutation: unlinkCalendar,
  } = useMosaicMutation<unknown, void>(unlinkCalendarMutation);

  // We call this hook to initialise the Google API library for us.
  // We later access it directly ourselves to pass extra arguments.
  useGoogleLogin({
    clientId: process.env.GOOGLE_CLIENT_ID,
    accessType: 'offline',
    responseType: 'code',
    cookiePolicy: 'single_host_origin',
  });

  const handleUnlinkCalendar = async () => {
    const { error } = await unlinkCalendar();

    setUnlinkConfirmationModal(false);

    let provider;
    if (calendarStatus === CalendarProvider.GOOGLE) {
      provider = 'Google';
    } else if (calendarStatus === CalendarProvider.MICROSOFT) {
      provider = 'Microsoft';
    } else {
      provider = '';
    }

    if (error != null) {
      showAlert({
        text: tt('unlink_error', { provider }),
        category: Category.DANGER,
      });
    } else {
      showAlert({
        text: tt('unlink_success', { provider }),
        category: Category.SUCCESS,
      });
    }

    refetchCalendarStatus();
  };

  const handleLinkCalendar = async () => {
    setIsLinkingCalendar(true);

    if (selectedSso === 'google') {
      const googleAuth = window.gapi.auth2.getAuthInstance();
      try {
        const googleAuthResult = await googleAuth.grantOfflineAccess({
          scope:
            'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
        });
        await googleCalendarSync(googleAuthResult);
        setShowSsoModal(false);
        showAlert({ text: tt('link_success', { provider: 'Google' }), category: Category.SUCCESS });
      } catch (e) {
        console.error(e);
        showAlert({
          text: tt('link_error', { provider: 'Google' }),
          category: Category.DANGER,
        });
      }
    } else {
      try {
        const res = await microsoftCalendarSync();
        if (res.status === 404) {
          showAlert({
            text: tt('same_provider'),
            category: Category.DANGER,
          });
          return;
        }
        setShowSsoModal(false);
        showAlert({
          text: tt('link_success', { provider: 'Microsoft' }),
          category: Category.SUCCESS,
        });
      } catch (e) {
        console.error(e);
        showAlert({
          text: tt('link_error', { provider: 'Microsoft' }),
          category: Category.DANGER,
        });
      }
    }

    refetchCalendarStatus();

    setIsLinkingCalendar(false);
  };

  return (
    <Fragment>
      <Text className={styles.title} shade={Shade.MEDIUM}>
        {tt('link_calendar')}
      </Text>
      <ListTile
        title={_getLinkCalendarLabel(calendarStatus)}
        leading={
          <Toggle
            disabled={
              error != null || isLoadingAvailabilities || isLinkingCalendar || isUnlinkingCalendar
            }
            onChange={() => {
              setIsToggleTrue(!isToggleTrue);
              if (calendarStatus == null) {
                setShowSsoModal(true);
              } else {
                setUnlinkConfirmationModal(true);
              }
            }}
            value={isToggleTrue}
          />
        }
      />
      {/* LINKING MODAL */}
      <Modal
        title={tt('modal.title')}
        visible={showSsoModal}
        onClickClose={() => {
          setIsToggleTrue(false);
          setShowSsoModal(false);
        }}
        footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button
                onClick={() => {
                  setIsToggleTrue(false);
                  setShowSsoModal(false);
                }}
                tier={Tier.TERTIARY}>
                {tt('modal.cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Button
                trailing={isLinkingCalendar ? <Spinner size={Size.SMALL} inversed /> : null}
                disabled={isLinkingCalendar}
                onClick={handleLinkCalendar}
                color={Color.BLUE}>
                {tt('modal.link')}
              </Button>
            </FlexItem>
          </Flex>
        }>
        <Margin size={{ bottom: Size.SMALL }}>
          <Text>{tt('modal.body')}</Text>
        </Margin>
        <Flex>
          <FlexItem>
            <BigCheckbox
              style={{ width: '220px', height: '160px' }}
              onChange={() => setSelectedSso('google')}
              value={selectedSso === 'google'}
              label={'Google Calendar'}>
              <Flex>
                <FlexItem>
                  <Margin size={Size.EXTRA_SMALL}>
                    <img src={googleCalendar} />
                  </Margin>
                </FlexItem>
              </Flex>
            </BigCheckbox>
          </FlexItem>
          <FlexSpacer size={Size.DEFAULT} />
          <FlexItem>
            <BigCheckbox
              style={{ width: '220px', height: '160px' }}
              onChange={() => setSelectedSso('microsoft')}
              value={selectedSso === 'microsoft'}
              label={'Microsoft Calendar'}>
              <Flex>
                <FlexItem>
                  <img src={microsoftCalendar} />
                </FlexItem>
              </Flex>
            </BigCheckbox>
          </FlexItem>
        </Flex>
      </Modal>
      {/* UNLINKING CONFIRMATION MODAL */}
      <Modal
        title={tt('unlinking_confirmation_modal.title')}
        visible={showUnlinkConfirmationModal}
        onClickClose={() => {
          setIsToggleTrue(!isToggleTrue);
          setUnlinkConfirmationModal(false);
        }}
        footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Margin size={{ right: Size.EXTRA_SMALL }}>
                <Button
                  onClick={() => {
                    setIsToggleTrue(!isToggleTrue);
                    setUnlinkConfirmationModal(false);
                  }}
                  tier={Tier.TERTIARY}>
                  {tt('modal.cancel')}
                </Button>
              </Margin>
            </FlexItem>
            <FlexItem>
              <Button
                trailing={isUnlinkingCalendar ? <Spinner size={Size.SMALL} inversed /> : null}
                disabled={isUnlinkingCalendar}
                onClick={() => handleUnlinkCalendar()}
                color={Color.RED}>
                {tt('unlinking_confirmation_modal.unlink')}
              </Button>
            </FlexItem>
          </Flex>
        }>
        <Paragraph>{tt('unlinking_confirmation_modal.body')}</Paragraph>
      </Modal>
    </Fragment>
  );
}
