import {
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Grid,
  GridItem,
  Padding,
  Panel,
  PanelBody,
  Size,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { Fragment } from 'react';

import { InsightsSession, InsightsUnit } from '~/pods/insights/types';
import { generateBarChartData } from '~/pods/insights/utils';
import { extractVisits } from '~/pods/insights/utils/extract-visits';
import { createTranslate } from '~/utils';

import { TypologiesChartPlaceholder } from '../images';
import { ChartHeader } from './ChartHeader';
import { BarSizes, HorizontalTractionBarChart } from './HorizontalTractionBarChart';

const tt = createTranslate('pods.insights.routes.traction.typologies_chart');

const styles = {
  chartContainer: css`
    width: 100%;
    height: 210px;
    text-align: center;
  `,
};

interface TypologiesChartsProps {
  visitors: Array<InsightsSession>;
  units: Array<InsightsUnit>;
}

export const TypologiesCharts = ({ visitors, units }: TypologiesChartsProps) => {
  const unitVisits = extractVisits(visitors);
  const data = unitVisits
    .map((v) => {
      const unit = units.find((u) => u.id === v.id);
      return {
        name: unit?.name,
        typology: unit?.typology,
      };
    })
    .filter((v) => v.typology != null);

  const typologiesData = generateBarChartData(data, 'typology');
  const unitsData = generateBarChartData(data, 'name');

  return (
    <Grid columns={2} vGutters={Size.SMALL}>
      {typologiesData.length === 0 || unitsData.length === 0 ? (
        <GridItem span={2}>
          <Panel
            body={
              <PanelBody noPadding>
                <Padding size={Size.EXTRA_SMALL}>
                  <ChartHeader label={tt('typologies_and_units')} />
                  <div className={styles.chartContainer}>
                    <Flex direction={FlexDirection.VERTICAL}>
                      <FlexItem>
                        <TypologiesChartPlaceholder />
                      </FlexItem>
                      <FlexSpacer size={Size.EXTRA_SMALL} />
                      <FlexItem>{tt('typologies_only_through_revo')}</FlexItem>
                    </Flex>
                  </div>
                </Padding>
              </PanelBody>
            }
          />
        </GridItem>
      ) : (
        <Fragment>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalTractionBarChart
                    label={tt('typologies')}
                    data={typologiesData}
                    tooltip={tt('typologies_tooltip')}
                    barSize={BarSizes.HALF}
                  />
                </PanelBody>
              }
            />
          </GridItem>
          <GridItem>
            <Panel
              body={
                <PanelBody noPadding>
                  <HorizontalTractionBarChart
                    label={tt('units')}
                    data={unitsData}
                    tooltip={tt('units_tooltip')}
                    barSize={BarSizes.HALF}
                  />
                </PanelBody>
              }
            />
          </GridItem>
        </Fragment>
      )}
    </Grid>
  );
};
