import {
  Button,
  ButtonLink,
  Color,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  ListTile,
  LoadingPlaceholder,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  PanelSection,
  Shade,
  Size,
  Table,
  Tier,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { Listing, Unit } from '~/pods/listing-manager/types';
import { ID, OrganisationRole } from '~/types';
import { displaySurface, run, useAuth } from '~/utils';
import { createTranslate, translatePrice } from '~/utils/translation';

import { AvailabilityTag } from './AvailabilityTag';
import { TableRowActions } from './TableRowActions';
import { SortDescription, UnitTableObject } from './types';
import { sortUnits } from './utils';

const styles = {
  table: css`
    white-space: nowrap;

    table > tbody > tr > td:last-of-type {
      width: 60px;
    }
  `,
};

const tt = createTranslate('pods.listing_manager.components.listing_table');
const t = createTranslate('pods.listing_manager.components.unit_editor');
const tt2 = createTranslate('pods.listing_manager.routes');

function _generateTableHeader(isUserAdmin: boolean) {
  const basicHeaders: Array<{ label: string; value: string }> = [
    {
      label: tt('unit'),
      value: 'reference',
    },
    {
      label: tt('bedrooms'),
      value: 'bedrooms',
    },
    {
      label: tt('rooms'),
      value: 'rooms',
    },
    {
      label: tt('bathrooms'),
      value: 'bathrooms',
    },
    {
      label: tt('terrace'),
      value: 'terraceSurface',
    },
    {
      label: tt('price'),
      value: 'price',
    },
    {
      label: tt('surface'),
      value: 'surface',
    },
    {
      label: tt('orientation'),
      value: 'orientation',
    },
    {
      label: tt('floorplan'),
      value: 'floorplan',
    },
    {
      label: tt('availability'),
      value: 'availability',
    },
  ];

  return isUserAdmin
    ? [
        ...basicHeaders,
        {
          label: '',
          value: 'actions',
        },
      ]
    : basicHeaders;
}

const headerSortMap: Record<string, string> = {
  unit: 'reference',
  bedrooms: 'bedrooms',
  rooms: 'rooms',
  price: 'price',
  surface: 'surface',
  availability: 'availability',
};

const ROWS_PER_PAGE = 25;

interface ListingTableProps {
  units: Array<Unit>;
  activeUnit?: Unit;
  page: number;
  listing: Listing;
  isReadonly: boolean;
  isLoading?: boolean;
  onClickRow: (id?: ID) => void;
  onChangePage: (page: number) => void;
  onClickDeleteUnit: (id: ID) => void;
  disableDelete?: boolean;
  onClickAddUnits: VoidFunction;
}

export const ListingTable = ({
  units,
  onClickRow,
  activeUnit,
  page,
  isReadonly,
  onChangePage,
  isLoading,
  listing,
  onClickDeleteUnit,
  disableDelete = false,
  onClickAddUnits,
}: ListingTableProps) => {
  const [sortedBy, setSortedBy] = useState<SortDescription>();
  const { user } = useAuth();

  const isUserAdmin = user?.role === OrganisationRole.ADMIN;

  // todo: sortUnits is not working
  const sortedUnits: Array<Unit> = sortUnits(units, sortedBy);

  const unitsToDisplay: Array<Unit> = sortedUnits.slice(
    (page - 1) * ROWS_PER_PAGE,
    ROWS_PER_PAGE * page,
  );

  const handleClickHeader = (key: string) => {
    setSortedBy({
      key,
      path: headerSortMap[key],
      direction: sortedBy != null && sortedBy.direction === 'desc' ? 'asc' : 'desc',
    });
  };

  const transformedUnits: Array<UnitTableObject> = unitsToDisplay.map((unit: Unit) => ({
    id: unit.id,
    reference: (
      <ListTile
        title={unit.reference}
        leading={run(() => {
          if (isReadonly) {
            <Tooltip content={tt('locked_provider')}>
              <Icon shade={Shade.MEDIUM} name="lock" />
            </Tooltip>;
          } else if (unit.isLocked) {
            <Tooltip content={tt('locked_revo')}>
              <Icon shade={Shade.MEDIUM} name="lock" />
            </Tooltip>;
          } else {
            return null;
          }
        })}
      />
    ),
    bedrooms: String(unit.bedrooms),
    rooms: unit.rooms,
    bathrooms: unit.bathrooms,
    surface: displaySurface(unit.surface, listing.surfaceUnit),
    terraceSurface: displaySurface(unit.terraceSurface, listing.surfaceUnit),
    price: translatePrice(unit.price, listing.currency),
    orientation: unit.orientation != null ? t(unit.orientation.toLowerCase()) : '',
    floorplan: (
      <a
        href={unit.floorplan}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}>
        <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY} disabled={unit.floorplan == null}>
          {tt('floorplan')}
        </ButtonLink>
      </a>
    ),
    availability: <AvailabilityTag availability={unit.availability} soldAt={unit.soldAt} />,
    actions: isUserAdmin ? (
      <TableRowActions
        isReadonly={isReadonly}
        onClickDelete={() => onClickDeleteUnit(unit.id)}
        nonDeletable={unit.isLocked || disableDelete}
      />
    ) : undefined,
  }));

  return (
    <Panel
      body={
        <PanelBody noPadding>
          <PanelSection>
            <div className={styles.table}>
              <Table
                scrollable
                emptyContent={(() => {
                  if (isEmpty(units)) {
                    return (
                      <EmptyState description={tt2('no_units_yet')}>
                        <Button color={Color.BLUE} onClick={onClickAddUnits}>
                          {tt2('add_units')}
                        </Button>
                      </EmptyState>
                    );
                  } else if (isEmpty(transformedUnits)) {
                    return <EmptyState description={tt('no_units_match')} />;
                  }
                })()}
                highlighted={!isReadonly}
                clickable={!isReadonly}
                onClickRow={
                  isReadonly
                    ? undefined
                    : (tableEntry) =>
                        onClickRow(
                          activeUnit?.id === tableEntry.id ? undefined : String(tableEntry.id),
                        )
                }
                activeHeader={sortedBy}
                sortableBy={_generateTableHeader(isUserAdmin)
                  .map((column) => column.value)
                  // todo: This doesn't seem to remove the sorting arrows
                  .filter((value) => !['actions, isLocked'].includes(value))}
                onClickHeader={handleClickHeader}
                activeRow={activeUnit?.id}
                isLoading={isLoading}
                header={_generateTableHeader(isUserAdmin)}
                data={transformedUnits}
              />
            </div>
          </PanelSection>
        </PanelBody>
      }
      footer={
        <PanelFooter>
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              {run(() => {
                if (isLoading) {
                  return <LoadingPlaceholder height={30} width={200} />;
                } else {
                  return (
                    <Pagination
                      value={Number(page)}
                      pages={Math.ceil(units.length / ROWS_PER_PAGE)}
                      onChange={onChangePage}
                    />
                  );
                }
              })}
            </FlexItem>
          </Flex>
        </PanelFooter>
      }
    />
  );
};
