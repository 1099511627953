import gql from 'fraql';

import { getCountryName, nonEmptyJoin } from '~/utils/';

const Address = {
  typeName: 'Address',
  fields: {
    countryName: {
      dependencies: gql`
        fragment _ on Address {
          id
          countryCode
        }
      `,
      resolver: (address: any) => getCountryName(address.countryCode),
    },
    fullAddress: {
      dependencies: gql`
        fragment _ on Address {
          id
          street
          countryCode
          city
          zipCode
        }
      `,
      resolver: (address: any) =>
        nonEmptyJoin(
          [address.street, address.city, address.zipCode, getCountryName(address.countryCode)],
          ', ',
        ),
    },
  },
};

export default Address;
