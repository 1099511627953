import LogRocket from 'logrocket';

const request = new XMLHttpRequest();
request.open('GET', '/version.json', false); // `false` makes the request synchronous
request.send(null);

let version: string | undefined;

if (request.status === 200) {
  try {
    version = JSON.parse(request.responseText).version;
  } catch {
    version = undefined;
  }
} else {
  version = undefined;
}

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('kpprsc/drawbotics-software', {
    release: version,
    network: {
      requestSanitizer: (request) => {
        if (
          request.body != null &&
          typeof request.body.includes === 'function' &&
          request.body.includes('password')
        ) {
          return null;
        }
        return request;
      },
    },
  });
}
