import {
  ButtonLink,
  Category,
  Color,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Size,
  Tag,
  Text,
  TextLink,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link } from 'react-router-dom';

import presentationPlaceholder from '~/pods/product-stats/images/best-presentation-placeholder.svg';
import bestPresentation from '~/pods/product-stats/images/best-presentation.svg';
import { StatsData } from '~/pods/product-stats/utils';
import { ErrorType } from '~/types';
import { createTranslate, useMosaicNavigation } from '~/utils';

import { EmptyTile } from '../EmptyTile';
import { ErrorTile } from '../ErrorTile';
import { StatTile } from '../StatTile';
import { LoadingPlaceholderTile } from './LoadingPlaceholderTile';

const tt = createTranslate('pods.product_stats.components.top_presentation_tile');

interface TopPresentationProps {
  isLoading: boolean;
  data?: StatsData['topPresentation'];
  error?: ErrorType;
}

export const TopPresentationTile = ({ data, isLoading, error }: TopPresentationProps) => {
  const { getUrlInOrg } = useMosaicNavigation();

  if (isLoading) {
    return <LoadingPlaceholderTile />;
  }
  if (error != null) {
    return <ErrorTile message={tt('could_not_load')} />;
  }
  if (data == null) {
    return (
      <EmptyTile
        image={presentationPlaceholder}
        cta={
          <Link to={getUrlInOrg('/meetings/presentations')}>
            <ButtonLink category={Category.INFO}>{tt('see_presentations')}</ButtonLink>
          </Link>
        }
        text={
          <Text>
            {tt('use')}&nbsp;
            <a href="https://drawbotics.com/reach" target="_blank" rel="noopener noreferrer">
              <TextLink>Meetings</TextLink>
            </a>
            &nbsp;{tt('to_create_visuals')}
          </Text>
        }
      />
    );
  }
  return (
    <StatTile>
      <Flex style={{ height: '100%' }} direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={bestPresentation} width={150} />
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          <Text>{tt('last_months_presentation')}:</Text>
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem>
          <Text style={{ fontStyle: 'italic' }} bold>{`"${data.name}"`}</Text>
        </FlexItem>
        <FlexSpacer size={Size.SMALL} />
        <FlexItem>
          {/* // todo: Never translated */}
          <Tag color={Color.BLUE}>{`Presented ${data.count} times`}</Tag>
        </FlexItem>
      </Flex>
    </StatTile>
  );
};
