import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  AttachmentBox,
  Button,
  Category,
  Checkbox,
  Color,
  DateInput,
  Dot,
  Flex,
  FlexAlign,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  Label,
  List,
  ListItem,
  Modal,
  MultiSelect,
  Padding,
  Paragraph,
  Select,
  Size,
  SortableGrid,
  SortableItem,
  Spinner,
  Text,
  TextArea,
  Tier,
  Toggle,
  UploadBox,
  UploadHelper,
  dateToObject,
  objectToDate,
  useAlert,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { css } from 'emotion';
import { differenceBy, set } from 'lodash';
import React, { Fragment, useState } from 'react';

import { Attachment } from '~/types';
import {
  UploadedFile,
  createTranslate,
  getCountryOptions,
  isAttachment,
  isNullOrEmpty,
  useFileUpload,
  useMosaicMutation,
} from '~/utils';

import { Advert } from '../domain';
import { useUpdateAdvert } from '../hooks';
import {
  Advantages,
  EligibilityCertificate,
  ProgressStatus,
  QualityCertificate,
  Unit,
} from '../types';

const tt = createTranslate('pods.multidiffusion.components.create_advert_modal');
const to = createTranslate('pods.multidiffusion.components.create_advert_modal.steps.overview');
const tm = createTranslate('pods.multidiffusion.components.create_advert_modal.steps.media');
const td = createTranslate('pods.multidiffusion.components.create_advert_modal.steps.details');
const tps = createTranslate('pods.multidiffusion.progress_status');
const tqc = createTranslate('pods.multidiffusion.quality_certificate');
const tec = createTranslate('pods.multidiffusion.eligibility_certificate');
const ta = createTranslate('pods.multidiffusion.advantages');

const styles = {
  createAdvertModal: {
    stepsContainer: css``,
    step: css``,
  },
  stepsOverview: {
    root: css`
      width: 100%;
      display: flex;
    `,
    step: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    overview: css`
      position: relative;
      margin-bottom: ${sv.marginExtraSmall};

      > div {
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 7px;
          left: 20px;
          width: 100px;
          height: 1px;
          background: ${sv.neutral};
        }
      }
    `,
    media: css`
      position: relative;
      margin-bottom: ${sv.marginExtraSmall};

      > div {
        &::before {
          content: ' ';
          display: block;
          position: absolute;
          top: 7px;
          left: -70px;
          width: 66px;
          height: 1px;
          background: ${sv.neutral};
        }

        &::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 7px;
          left: 20px;
          width: 100px;
          height: 1px;
          background: ${sv.neutral};
        }
      }
    `,
    details: css`
      position: relative;
      margin-bottom: ${sv.marginExtraSmall};

      > div {
        &::before {
          content: ' ';
          display: block;
          position: absolute;
          top: 7px;
          left: -70px;
          width: 66px;
          height: 1px;
          background: ${sv.neutral};
        }
      }
    `,
    label: css``,
  },
  confirmCancel: {
    root: css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    container: css`
      width: 300px;
    `,
  },
};

interface StepsOverviewProps {
  activeStep: 0 | 1 | 2;
}

const StepsOverview = ({ activeStep }: StepsOverviewProps) => {
  return (
    <div className={styles.stepsOverview.root}>
      <div className={styles.stepsOverview.step}>
        <div className={styles.stepsOverview.overview}>
          <Dot size={Size.EXTRA_LARGE} color={activeStep >= 0 ? Color.BLUE : undefined} />
        </div>
        <div className={styles.stepsOverview.label}>{to('title')}</div>
      </div>
      <div className={styles.stepsOverview.step}>
        <div className={styles.stepsOverview.media}>
          <Dot size={Size.EXTRA_LARGE} color={activeStep >= 1 ? Color.BLUE : undefined} />
        </div>
        <div className={styles.stepsOverview.label}>{tm('title')}</div>
      </div>
      <div className={styles.stepsOverview.step}>
        <div className={styles.stepsOverview.details}>
          <Dot size={Size.EXTRA_LARGE} color={activeStep >= 2 ? Color.BLUE : undefined} />
        </div>
        <div className={styles.stepsOverview.label}>{td('title')}</div>
      </div>
    </div>
  );
};

interface CreateAdvertFormErrors {
  title?: string;
  location?: string;
  description?: string;
}

interface CreateAdvertModalProps {
  isVisible: boolean;
  projectId: string;
  advert?: Advert;
  onClickClose: VoidFunction;
  units: Array<Unit>;
  refresh?: () => Promise<void>;
}

export const CreateAdvertModal = ({
  isVisible,
  projectId,
  advert,
  units,
  onClickClose,
  refresh,
}: CreateAdvertModalProps) => {
  const [edited, setEdited] = useState(false);
  const form = useForm<Partial<Advert> & { selectAllUnits: boolean }>(advert?.toData());
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const { isUpdatingAdvert, updateAdvert } = useUpdateAdvert();
  const { showAlert } = useAlert();
  const [errors, setErrors] = useState<CreateAdvertFormErrors>({});
  const {
    uploadFiles: uploadDocuments,
    info: documentsInfo,
    isLoading: isDocumentUploading,
  } = useFileUpload(useMosaicMutation);
  const {
    uploadFiles: uploadImages,
    info: imagesInfo,
    isLoading: isImageUploading,
  } = useFileUpload(useMosaicMutation);

  const _formSet = (...args: Parameters<typeof form.set>) => {
    form.set(...args);
    setEdited(true);
  };

  const [documents, setDocuments] = useState<Array<UploadedFile | Attachment>>(
    advert?.documents ?? [],
  );
  const [images, setImages] = useState<Array<UploadedFile | Attachment>>(advert?.images ?? []);

  const _getDocsChange = () => {
    const newFiles = documents.filter((file) => !isAttachment(file)) as Array<UploadedFile>;
    const oldFiles = documents.filter((file) => isAttachment(file)) as Array<Attachment>;
    const deletedFiles = differenceBy(advert?.documents, oldFiles, 'id') as Array<Attachment>;
    return [
      ...newFiles.map((f) => ({ signedBlobId: f.signedBlobId })),
      ...deletedFiles.map((f) => ({ attachmentId: f.id, destroy: true })),
    ];
  };

  const _getImagesChange = () => {
    const newFiles = images.filter((file) => !isAttachment(file)) as Array<UploadedFile>;
    const oldFiles = images.filter((file) => isAttachment(file)) as Array<Attachment>;
    const deletedFiles = differenceBy(advert?.images, oldFiles, 'id') as Array<Attachment>;
    return [
      ...newFiles.map((f) => ({ signedBlobId: f.signedBlobId })),
      ...deletedFiles.map((f) => ({ attachmentId: f.id, destroy: true })),
      ...(oldFiles.filter((f) => !deletedFiles.some((f2) => f2.id === f.id)) as any),
    ];
  };

  const _handleSaveFiles = async (): Promise<void> => {
    const docChanges = _getDocsChange();
    const imgChanges = _getImagesChange();
    const data = await updateAdvert(projectId, { documents: docChanges, images: imgChanges });
    setImages(data.updateAdvert.advert.images);
    setDocuments(data.updateAdvert.advert.documents);
  };

  const _goNext = async (): Promise<void> => {
    try {
      if (currentStep === 0) {
        if (!edited) {
          return setCurrentStep(1);
        }

        // validate first step
        let isValid = true;
        const e: CreateAdvertFormErrors = {
          title: undefined,
          location: undefined,
          description: undefined,
        };

        if (isNullOrEmpty(form.get('title'))) {
          set(e, 'title', to('form.title.errors.required'));
          isValid = false;
        }

        if (form.get('title').length > 255) {
          set(e, 'title', to('form.title.errors.length'));
          isValid = false;
        }

        if (
          isNullOrEmpty(form.get('countryCode')) ||
          isNullOrEmpty(form.get('city')) ||
          isNullOrEmpty(form.get('zipCode'))
        ) {
          set(e, 'location', to('form.location.errors.required'));
          isValid = false;
        }

        if (form.get('description') == null || form.get('description').length < 10) {
          set(e, 'description', to('form.description.errors.length'));
          isValid = false;
        }

        if (isValid) {
          if (edited) {
            await updateAdvert(projectId, form.values);
            setEdited(false);
          }
          return setCurrentStep(1);
        } else {
          return setErrors(e);
        }
      }

      if (currentStep === 1) {
        if (edited) {
          await updateAdvert(projectId, Advert.fromData(projectId, form.values).toData());
          setEdited(true);
        }
        await _handleSaveFiles();
        return setCurrentStep(2);
      }

      if (currentStep === 2) {
        if (edited) {
          await updateAdvert(projectId, Advert.fromData(projectId, form.values).toData());
          await refresh?.();
          setEdited(false);
        }
        setCurrentStep(0);
        return onClickClose();
      }

      throw new Error(`You're not supposed to get here.`);
    } catch {
      showAlert({ category: Category.DANGER, text: 'Unknown error' });
    }
  };

  const _goBack = () => setCurrentStep(Math.max(0, currentStep - 1) as 0 | 1 | 2);

  const _handleOnUploadDocuments = async (fileList: FileList) => {
    const res = await uploadDocuments(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    setDocuments([...documents, ...validFiles]);
  };

  const _handleOnUploadImages = async (fileList: FileList) => {
    const res = await uploadImages(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;
    setImages([...images, ...validFiles]);
  };

  const _handleRemoveDocument = (file: Attachment | UploadedFile) => {
    if (isAttachment(file)) {
      setDocuments(documents.filter((f) => (isAttachment(f) ? f.id !== file.id : true)));
    } else {
      setDocuments(
        documents.filter((f) =>
          isAttachment(f) ? true : f.originalFile.name !== file.originalFile.name,
        ),
      );
    }
  };

  const _handleRemoveImage = (file: Attachment | UploadedFile) => {
    if (isAttachment(file)) {
      setImages(images.filter((f) => (isAttachment(f) ? f.id !== file.id : true)));
    } else {
      setImages(
        images.filter((f) =>
          isAttachment(f) ? true : f.originalFile.name !== file.originalFile.name,
        ),
      );
    }
  };

  const _handleSortImages = async (order: Array<{ id: string }>) => {
    const imgs = images.slice().sort((a, b) => {
      const aId = isAttachment(a) ? a.id : a.signedBlobId ?? a.url;
      const bId = isAttachment(b) ? b.id : b.signedBlobId ?? b.url;
      const aIdx = order.findIndex((x) => x.id === aId);
      const bIdx = order.findIndex((x) => x.id === bId);
      return aIdx - bIdx;
    });
    setImages(imgs);
  };

  const documentsUploading = isDocumentUploading ? documentsInfo : [];
  const imagesUploading = isImageUploading ? imagesInfo : [];

  const listingAttributes = Object.entries(form.get('listingAttributes') ?? {})
    .filter(([, v]) => Boolean(v))
    .map(([k]) => k);

  return (
    <Modal
      visible={isVisible}
      title={tt('title')}
      onClickClose={onClickClose}
      style={{ width: 500, position: 'relative' }}
      footer={
        <Flex align={FlexAlign.START}>
          <FlexItem flex>
            {currentStep === 0 ? (
              <Text size={Size.SMALL} tier={Tier.SECONDARY}>
                {tt('footer.mandatory')}
              </Text>
            ) : null}
          </FlexItem>
          <FlexItem flex>
            <Flex>
              <FlexItem>
                <Button
                  disabled={currentStep === 0}
                  tier={Tier.SECONDARY}
                  leading={<Icon name="arrow-left" />}
                  onClick={() => _goBack()}>
                  {tt('footer.back')}
                </Button>
              </FlexItem>
              <FlexSpacer size={Size.EXTRA_SMALL} />
              <FlexItem>
                <Button
                  leading={isUpdatingAdvert ? <Spinner /> : undefined}
                  trailing={currentStep === 2 ? undefined : <Icon name="arrow-right" />}
                  onClick={() => _goNext()}
                  category={Category.INFO}>
                  {currentStep === 2 ? tt('footer.save') : tt('footer.next')}
                </Button>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      }>
      <Padding size={{ bottom: Size.DEFAULT }}>
        <StepsOverview activeStep={currentStep} />
      </Padding>
      <div className={styles.createAdvertModal.stepsContainer}>
        {currentStep === 0 ? (
          <div className={styles.createAdvertModal.step}>
            <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
              <FlexItem>
                <FormGroup
                  label={<Label>{to('form.title.label')}*</Label>}
                  input={
                    <Input
                      name="title"
                      value={form.get}
                      onChange={_formSet}
                      placeholder={to('form.title.placeholder')}
                      error={errors.title}
                    />
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{to('form.location.label')}*</Label>}
                  input={
                    <InputGroup horizontal error={errors.location}>
                      <Select
                        name="countryCode"
                        value={form.get}
                        onChange={_formSet}
                        placeholder={to('form.location.country')}
                        options={getCountryOptions()}
                      />
                      <Input
                        name="city"
                        value={form.get}
                        onChange={_formSet}
                        placeholder={to('form.location.city')}
                      />
                      <Input
                        name="zipCode"
                        value={form.get}
                        onChange={_formSet}
                        placeholder={to('form.location.zip_code')}
                      />
                    </InputGroup>
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{to('form.address.label')}</Label>}
                  input={
                    <Flex>
                      <FlexItem flex={1 / 3}>
                        <Input
                          name="streetNumber"
                          value={form.get}
                          onChange={_formSet}
                          placeholder={to('form.address.number')}
                        />
                      </FlexItem>
                      <FlexSpacer size={Size.EXTRA_SMALL} />
                      <FlexItem flex={2 / 3}>
                        <Input
                          name="street"
                          value={form.get}
                          onChange={_formSet}
                          placeholder={to('form.address.street')}
                        />
                      </FlexItem>
                    </Flex>
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{to('form.description.label')}*</Label>}
                  input={
                    <TextArea
                      name="description"
                      value={form.get}
                      onChange={_formSet}
                      placeholder={to('form.description.placeholder')}
                      rows={4}
                      error={errors.description}
                    />
                  }
                />
              </FlexItem>
            </Flex>
          </div>
        ) : null}
        {currentStep === 1 ? (
          <div className={styles.createAdvertModal.step}>
            <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
              <FlexItem>
                <FormGroup
                  label={<Label>{tm('form.video_url.label')}</Label>}
                  input={
                    <Input
                      name="videoUrl"
                      value={form.get}
                      onChange={_formSet}
                      placeholder={tm('form.video_url.placeholder')}
                    />
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{tm('form.documents.label')}</Label>}
                  input={
                    <Fragment>
                      {documents.length === 0 ? (
                        <Padding size={{ bottom: Size.EXTRA_SMALL }}>
                          <UploadBox
                            multiple
                            allowedFileFormats=".pdf"
                            fullWidth
                            onUploadFiles={_handleOnUploadDocuments}
                          />
                        </Padding>
                      ) : null}
                      {documents.map((file) => (
                        <Padding
                          size={{ bottom: Size.EXTRA_SMALL }}
                          key={isAttachment(file) ? file.id : file.originalFile.name}>
                          <AttachmentBox
                            fileName={isAttachment(file) ? file.filename : file.originalFile.name}
                            onClickClose={() => _handleRemoveDocument(file)}
                            onClickDownload={() => window.open(file.url, '_blank')}
                          />
                        </Padding>
                      ))}
                      {documentsUploading.map((fileUpload) => (
                        <Padding size={{ bottom: Size.EXTRA_SMALL }} key={fileUpload.filename}>
                          <AttachmentBox
                            progress={fileUpload.progress}
                            fileName={fileUpload.filename}
                          />
                        </Padding>
                      ))}
                      {documents.length > 0 ? (
                        <UploadHelper onUploadFiles={_handleOnUploadDocuments}>
                          <Button
                            size={Size.SMALL}
                            category={Category.INFO}
                            leading={<Icon name="upload" />}>
                            Upload documents
                          </Button>
                        </UploadHelper>
                      ) : null}
                    </Fragment>
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{tm('form.images.label')}</Label>}
                  input={
                    <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
                      <FlexItem>
                        <div style={{ background: '#EAEFF4', padding: 16, borderRadius: 8 }}>
                          <List>
                            <ListItem>
                              <Text size={Size.SMALL}>{tm('form.images.info.first_image')}</Text>
                            </ListItem>
                            <ListItem>
                              <Text size={Size.SMALL}>{tm('form.images.info.order')}</Text>
                            </ListItem>
                            <ListItem>
                              <Text size={Size.SMALL}>{tm('form.images.info.maximum')}</Text>
                            </ListItem>
                          </List>
                        </div>
                      </FlexItem>
                      {images.length > 0 ? <FlexSpacer size={Size.DEFAULT} /> : null}
                      <FlexItem>
                        <SortableGrid
                          minimal
                          items={images.map((img) => ({
                            id: isAttachment(img) ? img.id : img.signedBlobId ?? img.url,
                          }))}
                          onSortEnd={_handleSortImages}
                          hGutters={Size.EXTRA_SMALL}
                          vGutters={Size.EXTRA_SMALL}
                          columns={4}>
                          {images.map((img, i) => (
                            <SortableItem
                              label=""
                              key={isAttachment(img) ? img.id : img.signedBlobId ?? img.url}
                              id={isAttachment(img) ? img.id : img.signedBlobId ?? img.url}
                              onClickClose={() => _handleRemoveImage(images[i])}>
                              <img
                                src={img.url}
                                style={{
                                  width: '100%',
                                  objectFit: 'cover',
                                  aspectRatio: '1 / 1',
                                }}
                              />
                            </SortableItem>
                          ))}
                        </SortableGrid>
                      </FlexItem>
                      <FlexSpacer size={Size.SMALL} />
                      <FlexItem>
                        <Fragment>
                          <Padding size={{ bottom: Size.EXTRA_SMALL }}>
                            <UploadBox
                              multiple
                              allowedFileFormats=".jpg, .jpeg, .png, .gif"
                              fullWidth
                              onUploadFiles={_handleOnUploadImages}
                            />
                          </Padding>
                          {imagesUploading.map((fileUpload) => (
                            <Padding size={{ bottom: Size.EXTRA_SMALL }} key={fileUpload.filename}>
                              <AttachmentBox
                                progress={fileUpload.progress}
                                fileName={fileUpload.filename}
                              />
                            </Padding>
                          ))}
                        </Fragment>
                      </FlexItem>
                    </Flex>
                  }
                />
              </FlexItem>
            </Flex>
          </div>
        ) : null}
        {currentStep === 2 ? (
          <div className={styles.createAdvertModal.step}>
            <Flex direction={FlexDirection.VERTICAL} align={FlexAlign.STRETCH}>
              <FlexItem>
                <Flex>
                  <FlexItem flex>
                    <FormGroup
                      label={<Label>{td('form.delivery_date.label')}</Label>}
                      input={
                        <DateInput
                          name="deliveryDate"
                          value={
                            form.get('deliveryDate') != null
                              ? dateToObject(new Date(form.get('deliveryDate')))
                              : ''
                          }
                          onChange={(v) => _formSet(objectToDate(v).toISOString(), 'deliveryDate')}
                          placeholder={td('form.delivery_date.placeholder')}
                          align={Align.LEFT}
                        />
                      }
                    />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem flex>
                    <FormGroup
                      label={<Label>{td('form.progress_status.label')}</Label>}
                      input={
                        <Select
                          name="progressStatus"
                          value={form.get}
                          onChange={_formSet}
                          placeholder={td('form.progress_status.placeholder')}
                          options={[
                            {
                              label: tps(ProgressStatus.AVAILABLE_IMMEDIATELY),
                              value: ProgressStatus.AVAILABLE_IMMEDIATELY,
                            },
                            {
                              label: tps(ProgressStatus.WORKS_IN_PROGRESS),
                              value: ProgressStatus.WORKS_IN_PROGRESS,
                            },
                          ]}
                        />
                      }
                    />
                  </FlexItem>
                </Flex>
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{td('form.eligibility.label')}</Label>}
                  input={
                    <MultiSelect
                      name="eligibilityCertificates"
                      onChange={_formSet}
                      values={form.get('eligibilityCertificates') ?? []}
                      placeholder={td('form.eligibility.placeholder')}
                      options={Object.keys(EligibilityCertificate).map((v) => ({
                        label: tec(v),
                        value: v,
                      }))}
                    />
                  }
                />
              </FlexItem>

              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{td('form.certificates.label')}</Label>}
                  input={
                    <MultiSelect
                      name="qualityCertificates"
                      onChange={_formSet}
                      values={form.get('qualityCertificates') ?? []}
                      placeholder={td('form.certificates.placeholder')}
                      options={Object.keys(QualityCertificate).map((v) => ({
                        label: tqc(v),
                        value: v,
                      }))}
                    />
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{td('form.advantages.label')}</Label>}
                  input={
                    <MultiSelect
                      name="advantages"
                      onChange={_formSet}
                      values={form.get('advantages') ?? []}
                      placeholder={td('form.advantages.placeholder')}
                      options={Object.values(Advantages)
                        .map((v) => ({
                          label: ta(v),
                          value: v,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    />
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{td('form.units.label')}</Label>}
                  input={
                    <Fragment>
                      <Padding size={{ bottom: Size.EXTRA_SMALL }}>
                        <Checkbox
                          name="selectAllUnits"
                          value={form.get}
                          onChange={() => {
                            form.set(!form.values.selectAllUnits, 'selectAllUnits');
                            form.values.selectAllUnits
                              ? _formSet([], 'unitIds')
                              : _formSet(
                                  advert?.units.map((u) => u.id),
                                  'unitIds',
                                );
                          }}>
                          {td('form.display_all_units.label')}
                        </Checkbox>
                      </Padding>
                      <MultiSelect
                        disabled={form.values.selectAllUnits}
                        name="unitIds"
                        onChange={_formSet}
                        values={form.get('unitIds') ?? []}
                        placeholder={td('form.units.placeholder')}
                        options={units
                          .filter((u) => !!u.reference)
                          .map((u) => ({ label: u.reference, value: u.id }))}
                      />
                    </Fragment>
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <FormGroup
                  label={<Label>{td('form.listing.label')}</Label>}
                  input={
                    <MultiSelect
                      disabled={form.get('unitIds') == null || form.get('unitIds').length === 0}
                      name="listingAttributes"
                      onChange={(v) => {
                        const la = v.reduce((memo, k) => ({ ...memo, [k]: true }), {});
                        _formSet(la, 'listingAttributes');
                      }}
                      values={listingAttributes}
                      placeholder={td('form.listing.placeholder')}
                      options={[
                        { label: 'Availability', value: 'availability' },
                        { label: 'Bathrooms', value: 'bathrooms' },
                        { label: 'Bedrooms', value: 'bedrooms' },
                        { label: 'Rooms', value: 'rooms' },
                        { label: 'Surface', value: 'surface' },
                        { label: 'Terrace surface', value: 'terraceSurface' },
                      ]}
                    />
                  }
                />
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Flex align={FlexAlign.CENTER} justify={FlexJustify.START}>
                  <FlexItem>
                    <Toggle
                      name="displaySoldUnits"
                      value={form.get('displaySoldUnits')}
                      onChange={(v) => _formSet(v, 'displaySoldUnits')}
                    />
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  <FlexItem>Keep sold units published on portals</FlexItem>
                </Flex>
              </FlexItem>
            </Flex>
          </div>
        ) : null}
      </div>
      {confirmCancelVisible ? (
        <div className={styles.confirmCancel.root}>
          <div className={styles.confirmCancel.container}>
            <Paragraph align={Align.CENTER}>{tt('close_overlay.text')}</Paragraph>
            <Flex>
              <FlexItem>
                <Button onClick={() => setConfirmCancelVisible(false)}>
                  {tt('close_overlay.cancel')}
                </Button>
              </FlexItem>
              <FlexSpacer size={Size.SMALL} />
              <FlexItem>
                <Button category={Category.DANGER}>{tt('close_overlay.close')}</Button>
              </FlexItem>
            </Flex>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};
