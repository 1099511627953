import sv from '@drawbotics/drylus-style-vars';
import {
  Button,
  ButtonLink,
  Category,
  Checkbox,
  Color,
  Content,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Hint,
  Icon,
  Input,
  ListTile,
  Margin,
  Modal,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Paragraph,
  RoundIcon,
  Select,
  Separator,
  Shade,
  Size,
  Spinner,
  Tag,
  Text,
  TextLink,
  Tier,
  Title,
  UploadHelper,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { useState } from 'react';
import urlJoin from 'url-join';

import { MosaicPageTitle, Thumbnail } from '~/components';
import { OrganisationRole, Subscription } from '~/types';
import { createTranslate, useAuth, useFileUpload, useIntercom, useMosaicNavigation } from '~/utils';
import { UploadedFile, run, useMosaicMutation } from '~/utils';

import { Website } from '../api/domain';
import contactFormInfo from '../images/contact-form-info.jpg';
import { DomainStatus, WebsiteLanguage } from '../types';
import { CustomDomainModal } from './CustomDomainModal';
import { DomainStatusInfo } from './DomainStatusInfo';
import { OpenGraphPanel } from './OpenGraphPanel';

const MOSAIC_HOST = process.env.MOSAIC_HOST;

const tt = createTranslate('pods.website_builder.routes.website_builder');

const styles = {
  domainBox: css`
    padding: ${sv.paddingSmall};
    border-radius: ${sv.borderRadiusSmall};
    background: ${sv.backgroundColor};
  `,
};

interface WebsiteSettingsProps {
  website: Website;
  onChange: (website: Partial<Website>) => void | Promise<void>;
  onChangeFavicon: (signedBlobId: string) => void;
  onChangeAttachment: (signedBlobId: string) => void;
  isSaving: boolean;
}

export const WebsiteSettings = ({
  website,
  onChange,
  onChangeFavicon,
  onChangeAttachment,
  isSaving,
}: WebsiteSettingsProps) => {
  const { getUrlInProject } = useMosaicNavigation();
  const [isCustomDomainModalVisible, setIsCustomDomainModalVisible] = useState(false);
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);
  const [activeUploads, setCurrentUpload] = useState({ logo: false, favicon: false });
  const [identifierInput, setIdentifierInput] = useState(website.identifier);

  const { showIntercom } = useIntercom();
  const { user } = useAuth();

  const { uploadFiles } = useFileUpload(useMosaicMutation);

  const _handleUpload = async (fileList: FileList) => {
    setCurrentUpload({ ...activeUploads, favicon: true });
    const res = await uploadFiles(fileList);
    const validFiles = res.data.filter((file) => file != null) as Array<UploadedFile>;

    if (validFiles.length > 0) {
      const { signedBlobId } = validFiles[0];

      if (signedBlobId == null) return;

      // TODO handle errors
      onChangeFavicon(signedBlobId);
      setCurrentUpload({ ...activeUploads, favicon: false });
    }
  };

  const schedulingFormsDisabled = user?.organisation?.subscription === Subscription.ESSENTIALS;
  const isUserAdmin = user?.role === OrganisationRole.ADMIN;

  return (
    <Content fullHeight>
      <Padding size={Size.DEFAULT}>
        <Margin size={{ bottom: Size.SMALL }}>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <MosaicPageTitle>{tt('project_website')}</MosaicPageTitle>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem flex>
              {isSaving ? (
                <ListTile
                  title={<Tag color={Color.ORANGE}>{tt('saving')}</Tag>}
                  trailing={<Spinner size={Size.SMALL} />}
                />
              ) : (
                <Tag color={Color.GREEN}>{tt('saved')}</Tag>
              )}
            </FlexItem>
            <FlexItem>
              <span onClick={showIntercom}>
                <TextLink>{tt('hire_professional')}</TextLink>
              </span>
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <a
                href={urlJoin(MOSAIC_HOST, 'websites/projects', website.identifier)}
                target="_blank"
                rel="noopener noreferrer">
                <ButtonLink tier={Tier.SECONDARY}>{tt('preview_site')}</ButtonLink>
              </a>
            </FlexItem>
            <FlexSpacer size={Size.EXTRA_SMALL} />
            <FlexItem>
              <a rel="noopener noreferrer" target="_blank" href={getUrlInProject('website/edit')}>
                <ButtonLink tier={Tier.PRIMARY} color={Color.BLUE}>
                  {tt('edit_site')}
                </ButtonLink>
              </a>
            </FlexItem>
          </Flex>
        </Margin>
        <Panel
          header={
            <PanelHeader>
              <Title size={4} noMargin>
                {tt('settings')}
              </Title>
            </PanelHeader>
          }
          body={
            <PanelBody>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>1. {tt('domain')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  {run(() => {
                    if (website.customDomain != null) {
                      return (
                        <div className={styles.domainBox}>
                          <Flex justify={FlexJustify.SPACE_BETWEEN}>
                            <FlexItem>
                              <Margin style={{ display: 'flex' }} size={{ left: Size.EXTRA_SMALL }}>
                                <ListTile title={website.customDomain?.hostname} />
                              </Margin>
                            </FlexItem>
                            <FlexItem>
                              <ListTile
                                leading={
                                  website.customDomain?.status === DomainStatus.VERIFIED ? (
                                    <RoundIcon
                                      inversed
                                      name="check"
                                      size={Size.SMALL}
                                      color={Color.GREEN}
                                    />
                                  ) : null
                                }
                                trailing={<Icon shade={Shade.MEDIUM} name="lock" />}
                                title={<DomainStatusInfo status={website.customDomain.status} />}
                              />
                            </FlexItem>
                          </Flex>
                        </div>
                      );
                    } else
                      return (
                        <Input
                          leading={
                            <Text shade={Shade.LIGHT}>
                              {urlJoin(MOSAIC_HOST, 'projects/').replace('https://', '')}
                            </Text>
                          }
                          name="identifier"
                          onChange={(val) => setIdentifierInput(String(val))}
                          onBlur={() => onChange({ identifier: identifierInput })}
                          value={identifierInput ?? ''}
                        />
                      );
                  })}
                  {isUserAdmin && (
                    <Hint>
                      <span onClick={() => setIsCustomDomainModalVisible(true)}>
                        <TextLink>
                          {website.customDomain != null
                            ? tt('view_or_delete_domain')
                            : tt('connect_domain')}
                        </TextLink>
                      </span>
                    </Hint>
                  )}
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.DEFAULT }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
                <FlexItem>
                  <Paragraph style={{ margin: 0 }}>
                    <Text shade={Shade.LIGHT}>{tt('domain_info')}</Text>
                  </Paragraph>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>2. {tt('choose_language')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  <Select
                    placeholder={tt('select_language')}
                    value={website.language}
                    options={[
                      { label: tt('french'), value: WebsiteLanguage.FR },
                      { label: tt('english'), value: WebsiteLanguage.EN },
                      { label: tt('dutch'), value: WebsiteLanguage.NL },
                    ]}
                    onChange={(val) => onChange({ language: val })}
                  />
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.DEFAULT }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
                <FlexItem>
                  <Paragraph style={{ margin: 0 }}>
                    <Text shade={Shade.LIGHT}>{tt('language_info')}</Text>
                  </Paragraph>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>3. {tt('upload_favicon')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.START}>
                <FlexItem>
                  <Thumbnail image={website.favicon?.url} style={{ width: 80, height: 80 }} />
                </FlexItem>
                <FlexSpacer size={Size.DEFAULT} />
                <FlexItem>
                  <ListTile
                    title={
                      <UploadHelper
                        allowedFileFormats=".jpg, .jpeg, .png, .ico, .svg"
                        onUploadFiles={(files) => _handleUpload(files)}>
                        <Button
                          category={Category.INFO}
                          leading={<Icon name="upload" />}
                          size={Size.SMALL}>
                          {tt('upload')}
                        </Button>
                      </UploadHelper>
                    }
                    trailing={activeUploads.favicon ? <Spinner size={Size.SMALL} /> : null}
                  />
                  <Hint>{tt('favicon_hint')}</Hint>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>4. {tt('og_fill')}</Text>
              </Margin>
              <OpenGraphPanel
                website={website}
                onChange={onChange}
                onChangeAttachment={onChangeAttachment}
              />
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ bottom: Size.SMALL }}>
                <Text size={Size.LARGE}>5. {tt('contact_form_customize')}</Text>
              </Margin>
              <Flex justify={FlexJustify.START} align={FlexAlign.STRETCH}>
                <FlexItem flex={0.55}>
                  <Checkbox
                    disabled={schedulingFormsDisabled}
                    value={website.contactFormFilters}
                    onChange={() => {
                      onChange({ contactFormFilters: !website.contactFormFilters });
                    }}>
                    {tt('include_filters')}
                  </Checkbox>
                  <Margin size={Size.EXTRA_SMALL} />
                  <Checkbox
                    disabled={schedulingFormsDisabled}
                    value={website.contactFormScheduling}
                    onChange={() => {
                      onChange({
                        contactFormScheduling: !website.contactFormScheduling,
                      });
                    }}>
                    {tt('include_scheduling')}
                  </Checkbox>
                  {schedulingFormsDisabled ? (
                    <Hint>
                      <span onClick={showIntercom}>
                        <TextLink>{tt('contact_us')}</TextLink>
                      </span>{' '}
                      {tt('to_upgrade')}
                    </Hint>
                  ) : null}
                </FlexItem>
                <FlexItem>
                  <Padding size={{ horizontal: Size.SMALL }} style={{ height: '100%' }}>
                    <Separator vertical />
                  </Padding>
                </FlexItem>
                <FlexItem>
                  <Paragraph style={{ margin: 0 }}>
                    <Text shade={Shade.LIGHT}>{tt('contact_form_description')}</Text>
                    <span onClick={() => setIsTooltipModalOpen(true)}>
                      &nbsp;
                      <TextLink>{tt('contact_form_illustration')}</TextLink>
                    </span>
                  </Paragraph>
                </FlexItem>
              </Flex>

              {isCustomDomainModalVisible && (
                <CustomDomainModal
                  website={website}
                  onChange={({ customDomain }) => onChange({ customDomain })}
                  customDomain={website.customDomain}
                  visible={isCustomDomainModalVisible}
                  onClickClose={() => setIsCustomDomainModalVisible(false)}
                />
              )}
              <Modal
                style={{ width: '450px' }}
                onClickClose={() => setIsTooltipModalOpen(false)}
                visible={isTooltipModalOpen}
                title={tt('contact_form')}>
                <Paragraph>{tt('contact_form_description')}</Paragraph>
                <Margin size={Size.DEFAULT} />
                <div style={{ overflow: 'scroll', height: 500, width: '100%' }}>
                  <img style={{ width: '100%' }} src={contactFormInfo} />
                </div>
              </Modal>
            </PanelBody>
          }
        />
      </Padding>
    </Content>
  );
};
