import { Store, StoreConfig, arrayRemove } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';
import immer from 'immer';

import { CurrencyCode, ID, SurfaceUnitEnum } from '~/types';

import { Listing, Unit } from '../types';

export interface ListingState {
  listing: Listing;
}

@Injectable()
@StoreConfig({ name: 'listing', resettable: true, producerFn: immer })
export class ListingStore extends Store<ListingState> {
  constructor() {
    super({
      listing: {
        providerName: undefined,
        id: '0',
        name: '',
        currency: CurrencyCode.EUR,
        surfaceUnit: SurfaceUnitEnum.SQM,
        units: [],
      },
    });
  }

  public updateUnit(newUnit: Unit): void {
    this.update((draft) => {
      const unitIndex = draft.listing.units.findIndex((u) => u.id === newUnit.id);
      draft.listing.units[unitIndex] = newUnit;
    });
  }

  public addUnit(newUnit: Unit): void {
    this.update((draft) => {
      draft.listing.units.push(newUnit);
    });
  }

  public destroyUnit(unitId: ID): void {
    this.update((draft) => {
      draft.listing.units = arrayRemove(draft.listing.units, unitId);
    });
  }
}
