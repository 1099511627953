import sv from '@drawbotics/drylus-style-vars';
import { Icon, InlineEdit, Input } from '@drawbotics/react-drylus';
import { useTheme } from '@emotion/react';
import { useForm } from '@tokamakjs/common';
import { css, cx } from 'emotion';
import React, { useState } from 'react';

import { ThemeDefinition } from '~/pods/website-builder/types';
import { LoremIpsum } from '~/pods/website-builder/utils';
import { ID } from '~/types';
import { LocaleCode, createTranslateWithLocale } from '~/utils';

import { AnyWebsiteSectionData, ListingSection, Website } from '../../api/domain';
import { ControlBracket } from './ControlBracket';
import { ListingModal } from './ListingModal';
import { Themed } from './Themed';

const styles = {
  container: css`
    padding: 0 ${sv.marginMassive};
    position: relative;
  `,
  listing: css`
    outline: 2px dashed ${sv.blue};
    padding: ${sv.paddingExtraSmall};
  `,
  inactive: css`
    opacity: 0.4;
  `,
  controls: css`
    position: absolute;
    height: 100%;
    right: ${sv.defaultMargin};
    top: 0;
  `,
  title: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  placeholderRow: css`
    background-color: ${sv.grey100};
    height: 64px;
    padding: ${sv.paddingSmall} ${sv.defaultPadding};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${sv.marginSmall};
    align-items: center;
  `,
  placeholderRowTitle: (theme: ThemeDefinition) => css`
    font-family: ${theme.fonts.secondary};
  `,
  placeholderRowButton: css`
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    background-color: ${sv.neutralDark};
    color: ${sv.white};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

interface UnitsProps {
  website: Website;
  language: LocaleCode;
  onChangeWebsite: (data: Partial<Website>) => void;
  onChangeSection: (id: string, data: Partial<AnyWebsiteSectionData>) => void;
  listing: ListingSection;
  onIncrement: (id: ID) => void;
  onDecrement: (id: ID) => void;
}

export const Listing = ({
  website,
  language,
  onChangeWebsite,
  onChangeSection,
  listing,
  onIncrement,
  onDecrement,
}: UnitsProps) => {
  const theme = useTheme<ThemeDefinition>();
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const form = useForm({ title: listing.title ?? '' });

  const tl = createTranslateWithLocale('pods.website_builder.routes.website_builder', language);

  return (
    <div className={styles.container}>
      <div className={cx(styles.listing, { [styles.inactive]: !listing.active })}>
        <div className={styles.title}>
          <InlineEdit
            onCancel={() => form.set(listing.title ?? '', 'title')}
            onClickConfirm={() => onChangeSection(listing.id, { title: form.values.title })}
            edit={
              <Input
                style={{ fontSize: '1rem' }}
                name="mainTitle"
                placeholder={tl('title_placeholder')}
                value={form.get('title')}
                onChange={(v) => form.set(v.toString(), 'title')}
                error={form.get('title').length > 200 && tl('max_200_characters_error')}
              />
            }>
            <Themed.Title
              size={2}
              style={{
                opacity: listing.title != null && listing.title !== '' ? '1' : '0.5',
              }}>
              {listing.title != null && listing.title !== '' ? listing.title : LoremIpsum.SHORT}
            </Themed.Title>
          </InlineEdit>
        </div>
        {[
          tl('studio'),
          tl('bedroom_appt', { count: 1 }),
          tl('bedroom_appt', { count: 2 }),
          tl('bedroom_appt', { count: 3 }),
          tl('bedroom_appt', { count: 4 }),
        ].map((title) => (
          <div className={styles.placeholderRow} key={title}>
            <div className={styles.placeholderRowTitle(theme)}>{title}</div>
            <div className={styles.placeholderRowButton}>
              <Icon name="plus" />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.controls}>
        <ControlBracket
          sectionId={listing.id}
          onClickEdit={() => setListingModalOpen(true)}
          isActive={listing.active}
          onToggleVisibility={() => onChangeSection(listing.id, { active: !listing.active })}
          onIncrement={() => onIncrement(listing.id)}
          onDecrement={() => onDecrement(listing.id)}
        />
      </div>
      <ListingModal
        listingSettings={website.listingSettings}
        visible={listingModalOpen}
        onClickClose={() => {
          setListingModalOpen(false);
        }}
        onChange={(listingSettings) => onChangeWebsite({ listingSettings })}
      />
    </div>
  );
};
